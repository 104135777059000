import { IconNavTasks, TabList, Tabs } from '@repo/design-system-kit';
import type { ReactElement } from 'react';
import type { Key } from 'react-aria-components';
import { useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import React, { useState, useEffect, useCallback } from 'react';
import { RIBA_ROUTES, RIBA_TABS } from 'qonto/constants/riba';

export function RibaTabs(): ReactElement {
  const { formatMessage } = useIntl();
  // eslint-disable-next-line @qonto/no-use-ember-service-router -- Will need to be removed when RIBA_ROUTES will not use Ember routes anymore
  const router = useEmberService('router');

  const getActiveId = useCallback((): string => {
    switch (router.currentRouteName) {
      case RIBA_ROUTES.PROCESSING:
        return RIBA_TABS.PROCESSING;
      case RIBA_ROUTES.COMPLETED:
        return RIBA_TABS.COMPLETED;
      case RIBA_ROUTES.TASKS:
        return RIBA_TABS.TASKS;
      default:
        return RIBA_TABS.TASKS;
    }
  }, [router.currentRouteName]);

  const [activeId, setActiveId] = useState(getActiveId);

  useEffect(() => {
    setActiveId(getActiveId());
    const onRouteChange = (): void => {
      setActiveId(getActiveId());
    };

    router.on('routeDidChange', onRouteChange);

    return () => {
      router.off('routeDidChange', onRouteChange);
    };
  }, [router, getActiveId]);

  const handleChangeTab = (key: Key): void => {
    const tabKey = String(key);
    setActiveId(tabKey);
    void router.transitionTo(RIBA_ROUTES[tabKey as keyof typeof RIBA_ROUTES]);
  };

  const tabs = [
    {
      id: 'TASKS',
      title: formatMessage({ id: 'riba.tabs.tasks.title' }),
      'data-test-tabs-nav-item': 0,
      'data-test-tabs-nav-item-value': 'tasks',
      icon: <IconNavTasks />,
      hasDivider: true,
    },
    {
      id: 'PROCESSING',
      title: formatMessage({ id: 'riba.tabs.processing.title' }),
      'data-test-tabs-nav-item': 1,
      'data-test-tabs-nav-item-value': 'processing',
    },
    {
      id: 'COMPLETED',
      title: formatMessage({
        id: 'riba.tabs.completed.title',
      }),
      'data-test-tabs-nav-item': 2,
      'data-test-tabs-nav-item-value': 'completed',
    },
  ];

  return (
    <Tabs activeId={activeId} changeTab={handleChangeTab}>
      <TabList tabs={tabs} />
    </Tabs>
  );
}
