import { type ReactElement, type HTMLAttributes, useMemo, useState } from 'react';
import { LottiePlayer, SearchField, Selector } from '@repo/design-system-kit';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import cx from 'clsx';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import type BankAccount from 'qonto/models/bank-account';
import styles from './select-step.strict-module.css';

interface BankAccountsSelectStepProps extends HTMLAttributes<HTMLDivElement> {
  isEnabledFn?: (account: BankAccount) => boolean;
  setAccount: (account: BankAccount) => void;
  subtitle?: string;
  title?: string;
  transitionToNext: () => void;
}

export function BankAccountsSelectStep({
  isEnabledFn,
  setAccount,
  subtitle,
  title,
  transitionToNext,
  ...attributes
}: BankAccountsSelectStepProps): ReactElement {
  const { formatMessage, formatNumber } = useIntl();
  const {
    organization: { activeSortedQontoAccounts },
  } = useOrganizationManager();
  const abilities = useEmberService('abilities');

  const [searchValue, setSearchValue] = useState('');
  const [animationReady, setAnimationReady] = useState(false);

  const listOptions = activeSortedQontoAccounts
    .filter(({ isRemunerated }: BankAccount) => !isRemunerated)
    .map((account: BankAccount) => {
      return {
        account,
        isEnabled: isEnabledFn?.(account) ?? true,
      };
    });

  const filteredListOptions = useMemo(() => {
    if (searchValue === '') {
      return listOptions;
    }

    return listOptions.filter(({ account: { name } }) =>
      name?.toLowerCase().includes(searchValue.trim().toLowerCase())
    );
  }, [listOptions, searchValue]);

  return (
    <div className={styles['step-container']} {...attributes}>
      <div className={styles.content}>
        {title ? (
          <h1 className="title-1 mb-16" data-test-choose-account-title>
            {title}
          </h1>
        ) : null}

        {subtitle ? (
          <p className="body-1 mb-32" data-test-choose-account-subtitle>
            {subtitle}
          </p>
        ) : null}

        {listOptions.length > 6 ? (
          <div className="mb-24">
            <SearchField
              data-test-choose-account-search-input
              onChange={setSearchValue}
              placeholder={formatMessage({ id: 'bank_accounts.select-step.placeholder' })}
              value={searchValue}
            />
          </div>
        ) : null}

        {filteredListOptions.length > 0 ? (
          filteredListOptions.map(({ account, isEnabled }) => (
            <Selector
              className={styles.selector}
              data-test-choose-account-item={account.name}
              isDisabled={!isEnabled}
              key={account.id}
              onPress={() => {
                if (isEnabled) {
                  setAccount(account);
                  transitionToNext();
                }
              }}
              subtitle={
                abilities.can('see balance bankAccount')
                  ? formatNumber(account.authorizedBalance ?? 0, {
                      style: 'currency',
                      currency: account.balanceCurrency ?? 'EUR',
                    })
                  : ''
              }
              title={account.name ?? ''}
            />
          ))
        ) : (
          <>
            <LottiePlayer
              className={cx(styles['lottie-illustration'], !animationReady && 'hidden')}
              onDataReady={() => {
                setAnimationReady(true);
              }}
              path="/lotties/empty-state/search-no-result.json"
            />
            <div className={styles['info-text-1']} data-test-no-account-found-text>
              <FormattedMessage id="bank_accounts.select-step.no-account-found" />
            </div>
            <div className={styles['info-text-2']}>
              <FormattedMessage id="bank_accounts.select-step.enter-different-account" />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
