import { type ReactNode } from 'react';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import styles from './header.strict-module.css';

interface HeaderProps {
  isSidepanelOpen?: boolean;
  shouldAnimateColumns?: boolean;
}

export function Header({
  isSidepanelOpen,
  shouldAnimateColumns,
  ...props
}: HeaderProps): ReactNode {
  const { formatMessage } = useIntl();

  return (
    <tr
      className={cx(
        isSidepanelOpen && styles['row-sidebar'],
        shouldAnimateColumns && styles.animated
      )}
      data-test-approval-workflow-table-header
      {...props}
    >
      <th aria-hidden="true" className={cx(styles.empty, styles['header-cell'])} scope="col" />
      <th
        className={cx(styles['header-content'], styles['header-cell'], styles['header-type'])}
        data-test-table-header-approval-workflow-type
        scope="col"
      >
        {formatMessage({ id: 'approval-workflows.table.headers.workflow' })}
      </th>
      <th
        className={cx(styles['header-content'], styles['header-cell'], styles['header-rules'])}
        data-test-table-header-approval-workflow-rules
        scope="col"
      >
        {formatMessage({ id: 'approval-workflows.table.headers.rules' })}
      </th>
      {!isSidepanelOpen && (
        <th
          className={cx(styles['header-content'], styles['header-cell'], styles['header-date'])}
          data-test-table-header-approval-workflow-date
          scope="col"
        >
          {formatMessage({ id: 'approval-workflows.table.headers.update-date' })}
        </th>
      )}
      <th
        aria-hidden="true"
        className={cx(styles['header-action'], styles['header-cell'])}
        data-test-approval-workflow-table-approver-header-cell-button
        scope="col"
      />
      <th
        aria-hidden="true"
        className={cx(styles.empty, styles['header-cell'])}
        data-test-approval-workflow-table-approver-header-cell-empty
        scope="col"
      />
    </tr>
  );
}
