import type { ReactNode } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import cx from 'clsx';
import { IconCheckmarkOutlined } from '@repo/monochrome-icons';
import styles from './marketing-features-table.strict-module.css';

export function MarketingFeaturesTable(): ReactNode {
  const intl = useIntl();

  const FEATURES = [
    {
      message: intl.formatMessage({ id: 'qonto-invoicing.upsell-page.level3.table.item1' }),
      free: true,
    },
    {
      message: intl.formatMessage({ id: 'qonto-invoicing.upsell-page.level3.table.item2' }),
      free: true,
    },
    {
      message: intl.formatMessage({ id: 'qonto-invoicing.upsell-page.level3.table.item3' }),
      free: false,
    },
    {
      message: intl.formatMessage({ id: 'qonto-invoicing.upsell-page.level3.table.item4' }),
      free: false,
    },
    {
      message: intl.formatMessage({ id: 'qonto-invoicing.upsell-page.level3.table.item5' }),
      free: false,
    },
    {
      message: intl.formatMessage({ id: 'qonto-invoicing.upsell-page.level3.table.item6' }),
      free: false,
    },
  ];

  return (
    <table className={styles.marketingFeaturesTable} data-test-marketing-features-table>
      <thead>
        <tr>
          <th />
          <th className={cx(styles.tableTitle, 'caption-bold')}>
            <FormattedMessage id="qonto-invoicing.upsell-page.level3.table.free.title" />
          </th>
          <th className={cx(styles.tableTitle, styles.paid, 'caption-bold')}>
            <FormattedMessage id="qonto-invoicing.upsell-page.level3.table.paid.title" />
          </th>
        </tr>
      </thead>
      <tbody>
        {FEATURES.map(feature => (
          <tr key={feature.message}>
            <td className="body-1">{feature.message}</td>
            <td className={styles.checkmark}>{feature.free ? <IconCheckmarkOutlined /> : null}</td>
            <td className={styles.checkmark}>
              <IconCheckmarkOutlined />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
