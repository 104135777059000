import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './expense-report.strict-module.css';

export function ExpenseReport(): ReactNode {
  return (
    <div className={styles['cell-content']} data-test-request-cell-expense-report>
      <StaticThemedAsset
        alt=""
        aria-hidden="true"
        assetPath="/illustrations/request-expense-report.svg"
        className={styles['expense-report-icon']}
        data-test-request-cell-expense-report-icon
      />
      <p data-test-request-cell-expense-report-title>
        <FormattedMessage id="labels.reimbursement" />
      </p>
    </div>
  );
}
