import type { ReactNode } from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './placeholder.strict-module.css';

export function Placeholder(props: React.HTMLAttributes<HTMLDivElement>): ReactNode {
  return (
    <div aria-hidden="true" {...props}>
      <div className={styles['aside-section']} data-test-vat-placeholder>
        <SkeletonLoader.Line className={styles.title} width="120px" />

        <div className="mb-32" data-test-placeholder-block>
          <div className={styles['wrapper-line']}>
            <div className={styles.input}>
              <div className="mb-4">
                <SkeletonLoader.Line className={styles.label} width="80px" />
              </div>
              <SkeletonLoader.Block borderRadius="4px" height="40px" width="123px" />
            </div>

            <div className={styles.input}>
              <div className="mb-4">
                <SkeletonLoader.Line className={styles.label} width="60px" />
              </div>
              <SkeletonLoader.Block borderRadius="4px" height="40px" width="95px" />
            </div>

            <div className={styles.input}>
              <div className="mb-4">
                <SkeletonLoader.Line className={styles.label} width="80px" />
              </div>
              <SkeletonLoader.Block borderRadius="4px" height="40px" width="123px" />
            </div>

            <div className="mb-4">
              <SkeletonLoader.Block borderRadius="4px" height="32px" width="32px" />
            </div>
          </div>
        </div>
        <SkeletonLoader.Line className="mb-8" width="120px" />
      </div>

      <div className={styles['aside-section']} data-test-accounting-entries-placeholder>
        <SkeletonLoader.Line className={styles.title} width="120px" />

        <div className="mb-24" data-test-placeholder-block>
          <div className={styles['wrapper-line']}>
            <div className={styles.input}>
              <div className="mb-4">
                <SkeletonLoader.Line className={styles.label} width="80px" />
              </div>
              <SkeletonLoader.Block
                borderRadius="4px"
                className="mb-16"
                height="40px"
                width="139px"
              />
              <SkeletonLoader.Block borderRadius="4px" height="40px" width="139px" />
            </div>
            <div className={styles.input}>
              <div className="mb-4">
                <SkeletonLoader.Line className={styles.label} width="60px" />
              </div>
              <SkeletonLoader.Block
                borderRadius="4px"
                className="mb-16"
                height="40px"
                width="100px"
              />
              <SkeletonLoader.Block borderRadius="4px" height="40px" width="100px" />
            </div>
            <div className={styles.input}>
              <div className="mb-4">
                <SkeletonLoader.Line className={styles.label} width="80px" />
              </div>
              <SkeletonLoader.Block
                borderRadius="4px"
                className="mb-16"
                height="40px"
                width="100px"
              />
              <SkeletonLoader.Block borderRadius="4px" height="40px" width="100px" />
            </div>
            <div>
              <SkeletonLoader.Block
                borderRadius="4px"
                className="mb-24"
                height="32px"
                width="32px"
              />
              <SkeletonLoader.Block
                borderRadius="4px"
                className="mb-4"
                height="32px"
                width="32px"
              />
            </div>
          </div>
        </div>
        <SkeletonLoader.Line className="mb-8" width="120px" />
      </div>

      <div className={styles['aside-section']} data-test-notes-placeholder>
        <SkeletonLoader.Line className={styles.title} width="120px" />
        <SkeletonLoader.Block borderRadius="4px" height="40px" width="407px" />
      </div>
    </div>
  );
}
