import type { ReactElement } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Selector } from '@repo/design-system-kit';
import type {
  EarlyRepaymentOption,
  RepaymentOption,
} from 'qonto/react/api/models/repayment-option';

interface NextInstallmentProps {
  option: RepaymentOption;
  onSelect: (option: EarlyRepaymentOption) => void;
}

export function NextInstallment({ option, onSelect }: NextInstallmentProps): ReactElement {
  return (
    <Selector
      data-testid="next-installment-selector"
      icon={
        <img
          alt=""
          data-testid="next-installment-icon"
          src="/icon/financing/pay-later/pay-later.svg"
        />
      }
      onPress={() => {
        onSelect(option.earlyRepaymentOption);
      }}
      subtitle={
        <FormattedMessage id="financing.pay-later.early-repayment.options.next-installment.subtitle" />
      }
      title={
        <FormattedNumber
          currency={option.totalAmount.currency}
          maximumFractionDigits={2}
          minimumFractionDigits={2}
          style="currency"
          value={Number(option.totalAmount.value)}
        />
      }
    />
  );
}
