import { Dialog } from 'react-aria-components';
import { SuggestedTransactionCard } from '@repo/domain-kit/accounts-payable';
import type { Transaction } from 'qonto/react/models/transaction';
import { SuggestedTransactionsHeader } from '../header/suggested-transactions-header';
import { ViewAllTransactionsButton } from '../button/view-all-transactions-button';
import styles from './suggested-transactions-content.strict-module.css';

interface SuggestedTransactionsPopoverProps {
  transaction?: Transaction;
  isLoading: boolean;
  handleDismissSuggestion: () => void;
  handleMatchTransaction: () => void;
  handleViewAllTransactions: () => void;
}

export function SuggestedTransactionsPopoverContent({
  transaction,
  isLoading,
  handleDismissSuggestion,
  handleMatchTransaction,
  handleViewAllTransactions,
}: SuggestedTransactionsPopoverProps): React.JSX.Element {
  return (
    <Dialog className={styles['popover-section']}>
      <SuggestedTransactionsHeader />
      <SuggestedTransactionCard
        handleDismissSuggestion={handleDismissSuggestion}
        handleMatchTransaction={handleMatchTransaction}
        isLoading={isLoading}
        transaction={transaction}
      />
      <ViewAllTransactionsButton handleViewAllTransactions={handleViewAllTransactions} />
    </Dialog>
  );
}
