import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { Button, type BaseButtonProps } from '@repo/design-system-kit';
import { AddOnDetail } from '@repo/domain-kit/pricing';
import type { ReactElement } from 'react';
import { Addons } from 'qonto/constants/addons';
import styles from './styles.strict-module.css';

export interface AddonCardsProps {
  addons: Addon[];
  recurrence: SubscriptionRecurrences;
  onConfirm: (addon: Addon) => void;
  onToggleChange: (addonCode: string) => void;
  loadingAddonCode: Addons;
}

interface Trial {
  monthDuration: number;
}

interface Trait {
  title: string;
  name: 'active' | 'free_trial';
}

enum SubscriptionRecurrences {
  MONTHLY = 'monthly',
  ANNUAL = 'annual',
}

interface Price {
  value: string;
  currency: string;
}

interface BenefitGroup {
  title: string;
  badge: string | null;
  tags?: 'coming_soon'[];
  benefits: {
    description: string;
    description_has_decoration: boolean;
    is_included: boolean;
  }[];
}

interface Addon {
  name: string;
  code: Addons;
  type: string;
  brand_name: string;
  description: string;
  description_has_decoration: boolean;
  sub_description: string | null;
  monthly_price: {
    prefix: string | null;
    per_month_amount: Price;
    per_year_amount: Price;
    period: string;
    billing_recurrence: string;
    annual_benefit: string;
  };
  annual_price: {
    prefix: string | null;
    per_month_amount: Price;
    per_year_amount: Price;
    period: string;
    billing_recurrence: string;
    annual_benefit: string;
  };
  benefit_groups: [BenefitGroup];
}

export function AddonCards({
  addons,
  recurrence,
  onToggleChange,
  onConfirm,
  loadingAddonCode,
}: AddonCardsProps): ReactElement {
  const subscriptionManager = useEmberService('subscription-manager');
  const { formatMessage, formatNumber } = useIntl();

  const addonsInfo = addons.map(addon => {
    const {
      brand_name: brandName,
      code,
      sub_description: subDescription,
      benefit_groups: benefitGroups,
      monthly_price: monthlyPrice,
      annual_price: annualPrice,
    } = addon;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- key is set
    const benefitsInfo = benefitGroups.find(({ tags }) => !tags || tags.length === 0)!;
    const comingSoonInfo = benefitGroups.find(({ tags }) => {
      return Boolean(tags?.includes('coming_soon'));
    });

    const priceObjectByRecurrence =
      recurrence === SubscriptionRecurrences.MONTHLY ? monthlyPrice : annualPrice;

    let cfmSubDescription;
    if (code === Addons.CashFlowManagement) {
      const trialProduct = subscriptionManager.hasAvailableTrialProduct(code, recurrence);
      const trial = trialProduct as Trial | undefined;

      if (trial && trial.monthDuration > 1) {
        cfmSubDescription = formatMessage(
          { id: 'subscription.change.free-trial.duration' },
          { duration: trial.monthDuration }
        );
      }
    }

    const priceMonthly = formatNumber(Number(monthlyPrice.per_month_amount.value), {
      currency: monthlyPrice.per_month_amount.currency,
      style: 'currency',
      currencyDisplay: 'symbol',
      notation: 'compact',
    });

    const priceObject = priceObjectByRecurrence.per_month_amount;
    const price = formatNumber(Number(priceObject.value), {
      currency: priceObject.currency,
      style: 'currency',
      currencyDisplay: 'symbol',
    });

    const ctaProps: { title: string; variant: BaseButtonProps['variant'] } = {
      title: formatMessage({ id: 'subscription.change.addons.summary.choose-addon' }),
      variant: 'primary',
    };
    if (subscriptionManager.currentSubscription?.hasInitialTrial) {
      ctaProps.title = formatMessage({
        id: 'subscription.change.addons.cta.try-for-free',
      });
    }

    if (subscriptionManager.hasAvailableTrialProduct(code, recurrence)) {
      ctaProps.title = formatMessage({
        id: 'subscription.change.addons.cta.free-trial',
      });
    }

    if (subscriptionManager.getProduct(code)) {
      if (subscriptionManager.hasProductAndRecurrence(code, recurrence)) {
        ctaProps.title = formatMessage({
          id: 'subscription.change.addons.summary.remove-addon',
        });
        ctaProps.variant = 'secondary';
      } else {
        ctaProps.title =
          recurrence === SubscriptionRecurrences.MONTHLY
            ? formatMessage({ id: 'subscription.change.addons.summary.monthly' })
            : formatMessage({ id: 'subscription.change.addons.summary.annual' });
      }
    }

    const traits: Trait[] = [];
    if (subscriptionManager.hasProductAndRecurrence(code, recurrence)) {
      traits.push({
        title: formatMessage({ id: 'subscription.change.addons.badges.added' }),
        name: 'active',
      });
    }

    if (
      subscriptionManager.currentSubscription?.hasInitialTrial ||
      subscriptionManager.hasAvailableTrialProduct(code, recurrence)
    ) {
      traits.push({
        title: formatMessage({ id: 'subscription.change.addons.badges.free-trial' }),
        name: 'free_trial',
      });
    }

    const prefix = priceObjectByRecurrence.prefix;
    let annualSwitch = null;
    const isAddedAnnually = Boolean(
      subscriptionManager.hasProductAndRecurrence(code, SubscriptionRecurrences.ANNUAL)
    );
    if (recurrence === SubscriptionRecurrences.MONTHLY && isAddedAnnually) {
      annualSwitch = (
        <span
          className={cx(styles.recurrence, 'caption')}
          data-test-addon-detail-card-billing-recurrence
        >
          {annualPrice.annual_benefit}
        </span>
      );
    } else if (recurrence === SubscriptionRecurrences.MONTHLY && !isAddedAnnually) {
      annualSwitch = (
        <Button
          className="caption"
          data-test-addon-detail-card-billing-recurrence-button
          onPress={() => {
            onToggleChange(code);
          }}
          variant="tertiary"
        >
          {monthlyPrice.annual_benefit}
        </Button>
      );
    }

    return {
      name: brandName,
      code,
      recurrence,
      price,
      priceMonthly,
      pricePrefix: prefix ?? undefined,
      annualSwitch,
      pricePeriod: priceObjectByRecurrence.period,
      benefitForAnnualPrice: annualPrice.annual_benefit,
      comingSoonFeatures: comingSoonInfo?.benefits.map(benefit => benefit.description),
      comingSoonFeaturesTitle: comingSoonInfo?.title,
      features: benefitsInfo.benefits.map(benefit => benefit.description),
      featuresTitle: benefitsInfo.title,
      traits,
      subtitle: (cfmSubDescription || subDescription) ?? undefined,
      cta: (
        <Button
          data-test-cta
          isLoading={loadingAddonCode === code}
          onPress={() => {
            onConfirm(addon);
          }}
          stretch
          variant={ctaProps.variant}
        >
          {ctaProps.title}
        </Button>
      ),
    };
  });

  return (
    <>
      {addonsInfo.map(addon => (
        <AddOnDetail
          annualSwitch={addon.annualSwitch}
          billingRecurrence={addon.benefitForAnnualPrice}
          comingSoonFeatures={addon.comingSoonFeatures}
          comingSoonFeaturesTitle={addon.comingSoonFeaturesTitle}
          features={addon.features}
          featuresTitle={addon.featuresTitle}
          key={addon.code}
          mainButton={addon.cta}
          name={addon.name}
          price={addon.price}
          priceMonthly={addon.priceMonthly}
          pricePeriod={addon.pricePeriod}
          pricePrefix={addon.pricePrefix}
          recurrence={recurrence}
          subtitle={addon.subtitle}
          traits={addon.traits}
        />
      ))}
    </>
  );
}
