import type { ReactNode } from 'react';
import cx from 'clsx';
import styles from './gradient-overlay.strict-module.css';

interface OverlayGradientProps {
  numberOfColumns: number;
  children: ReactNode;
}

export function OverlayGradient({ children, numberOfColumns }: OverlayGradientProps): ReactNode {
  return (
    <div
      className={cx(styles.baseOverlay, styles[`overlay-${numberOfColumns}-col`])}
      data-testid="chart-overlay"
    >
      <div className={styles.overlayGradient}>{children}</div>
    </div>
  );
}
