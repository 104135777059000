import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, LottiePlayer } from '@repo/design-system-kit';
import styles from './error-state.strict-module.css';

interface ErrorStateProps {
  description: string;
  retry: () => void;
}

export function ErrorState({ description, retry }: ErrorStateProps): ReactElement {
  return (
    <div className={styles.container}>
      <LottiePlayer
        className={styles.illustration}
        data-test-error-illustration=""
        path="/lotties/error-404.json"
      />

      <h2 className="title-2 mb-8" data-test-error-title>
        <FormattedMessage id="financing.error-state.title" />
      </h2>

      <p className="body-2 mb-32" data-test-error-description>
        {description}
      </p>

      <Button data-test-retry-button onPress={retry} variant="secondary">
        <FormattedMessage id="financing.error-state.retry" />
      </Button>
    </div>
  );
}
