import type { ReactNode } from 'react';
import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';
import { Timeline as DSTimeline, type TimelineItemProps } from '@repo/design-system-kit';
import { IconCrossRoundedOutlined } from '@repo/monochrome-icons';
import type RibaPaymentModel from 'qonto/models/riba-payment';
import { ArrowBottomRoundedFilled } from 'qonto/react/assets/icons/arrow-bottom-rounded-filled';
import { ClockOutlined } from 'qonto/react/assets/icons/clock-outlined';
import { EuroOutlined } from 'qonto/react/assets/icons/euro-outlined';
import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { DetailsList } from 'qonto/react/components/sidebar/details-list';
import { ArrowBottomRoundedOutlined } from 'qonto/react/assets/icons/arrow-bottom-rounded-outlined';
import { CheckmarkRoundedFilled } from 'qonto/react/assets/icons/checkmark-rounded-filled';
import { CheckmarkRoundedOutlined } from 'qonto/react/assets/icons/checkmark-rounded-outlined';
import { CrossRoundedFilled } from 'qonto/react/assets/icons/cross-rounded-filled';
import { RIBA_STATUS } from 'qonto/constants/riba';
import { ReverseApproval } from 'qonto/react/components/riba/sidebar/reverse-approval';
import styles from './styles.strict-module.css';

interface TimelineProps {
  payment: RibaPaymentModel;
  onApprove?: () => void;
  onReject?: () => void;
}

function getTimelineItems(payment: RibaPaymentModel, intl: IntlShape): TimelineItemProps[] {
  const items: TimelineItemProps[] = [];

  items.push({
    id: 'received',
    lineVariant: payment.status === RIBA_STATUS.PENDING ? 'dashed' : ('solid' as const),
    slots: {
      media:
        payment.status === RIBA_STATUS.PENDING ? (
          <ArrowBottomRoundedFilled />
        ) : (
          <ArrowBottomRoundedOutlined />
        ),
      title: intl.formatMessage({ id: 'riba.sidebar.timeline.received.title' }),
      content: dateToken({
        date: payment.receivedAt.toISOString(),
        locale: intl.locale,
        token: DATE_FORMAT_TOKENS.DATE_TIME_S,
      }),
    },
  });

  const VALID_FAILURE_REASONS = new Set(['insufficient_funds', 'other']);

  const isApprovedStatus = [
    RIBA_STATUS.SCHEDULED,
    RIBA_STATUS.COMPLETED,
    RIBA_STATUS.CANCELED,
  ].includes(payment.status);

  const isPendingStatus = payment.status === RIBA_STATUS.PENDING;

  const isDeclinedWithValidReason =
    payment.status === RIBA_STATUS.DECLINED && VALID_FAILURE_REASONS.has(payment.failureReason);

  const needsApprovedAt = !isPendingStatus && payment.status !== RIBA_STATUS.SCHEDULED;
  const hasApprovedAt = Boolean(payment.approvedAt);

  const shouldShowApproved =
    isPendingStatus ||
    (payment.status === RIBA_STATUS.CANCELED ? hasApprovedAt : isApprovedStatus) ||
    isDeclinedWithValidReason;

  if (shouldShowApproved && (!needsApprovedAt || hasApprovedAt)) {
    let approvedMedia = <CheckmarkRoundedOutlined />;
    if (isPendingStatus) {
      approvedMedia = <ClockOutlined />;
    } else if (payment.status === 'scheduled') {
      approvedMedia = <CheckmarkRoundedFilled />;
    }

    items.push({
      id: 'approved',
      lineVariant: [RIBA_STATUS.PENDING, RIBA_STATUS.SCHEDULED].includes(payment.status)
        ? 'dashed'
        : ('solid' as const),
      slots: {
        media: approvedMedia,
        title: intl.formatMessage({ id: 'riba.sidebar.timeline.approved.title' }),
        content:
          payment.status === RIBA_STATUS.PENDING
            ? intl.formatMessage(
                { id: 'riba.sidebar.timeline.approved.due-date' },
                {
                  dueDate: dateToken({
                    date: payment.executionDate.toISOString(),
                    locale: intl.locale,
                    token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
                  }),
                }
              )
            : dateToken({
                date: payment.approvedAt.toISOString(),
                locale: intl.locale,
                token: DATE_FORMAT_TOKENS.DATE_TIME_S,
              }),
      },
    });
  }

  if (Boolean(payment.rejectedAt) && payment.status === RIBA_STATUS.CANCELED) {
    items.push({
      id: 'rejected',
      lineVariant: 'solid' as const,
      slots: {
        media: <IconCrossRoundedOutlined style={{ color: 'var(--content-secondary)' }} />,
        title: intl.formatMessage({ id: 'riba.sidebar.timeline.rejected.title' }),
        content: dateToken({
          date: payment.rejectedAt.toISOString(),
          locale: intl.locale,
          token: DATE_FORMAT_TOKENS.DATE_TIME_S,
        }),
      },
    });
  }

  if (payment.status === RIBA_STATUS.PENDING) {
    items.push({
      id: 'pending',
      lineVariant: 'dashed' as const,
      slots: {
        media: <EuroOutlined />,
        title: intl.formatMessage({ id: 'riba.sidebar.timeline.debited.title' }),
      },
    });
  }

  if (payment.status === RIBA_STATUS.COMPLETED) {
    items.push({
      id: 'debited',
      lineVariant: 'solid' as const,
      slots: {
        media: <CheckmarkRoundedFilled />,
        title: intl.formatMessage({ id: 'riba.sidebar.timeline.debited.title' }),
        content: dateToken({
          date: payment.debitedAt.toISOString(),
          locale: intl.locale,
          token: DATE_FORMAT_TOKENS.DATE_TIME_S,
        }),
      },
    });
  } else if (payment.status === RIBA_STATUS.SCHEDULED) {
    items.push({
      id: 'debited',
      lineVariant: 'dashed' as const,
      slots: {
        media: <EuroOutlined />,
        title: intl.formatMessage({ id: 'riba.sidebar.timeline.debited.title' }),
        content: intl.formatMessage(
          { id: 'riba.sidebar.timeline.debited.due-date' },
          {
            dueDate: dateToken({
              date: payment.executionDate.toISOString(),
              locale: intl.locale,
              token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
            }),
          }
        ),
      },
    });
  }

  if (payment.status === RIBA_STATUS.CANCELED) {
    items.push({
      id: 'canceled',
      lineVariant: 'solid' as const,
      slots: {
        media: <CrossRoundedFilled />,
        title: intl.formatMessage({ id: 'riba.sidebar.timeline.canceled.title' }),
        content: dateToken({
          date: payment.canceledAt.toISOString(),
          locale: intl.locale,
          token: DATE_FORMAT_TOKENS.DATE_TIME_S,
        }),
      },
    });
  }

  if (payment.status === RIBA_STATUS.REJECTED) {
    items.push({
      id: 'rejected',
      lineVariant: 'solid' as const,
      slots: {
        media: <CrossRoundedFilled />,
        title: intl.formatMessage({ id: 'riba.sidebar.timeline.rejected.title' }),
        content: dateToken({
          date: payment.rejectedAt.toISOString(),
          locale: intl.locale,
          token: DATE_FORMAT_TOKENS.DATE_TIME_S,
        }),
      },
    });
  }

  if (payment.status === RIBA_STATUS.DECLINED) {
    items.push({
      id: 'declined',
      lineVariant: 'solid' as const,
      slots: {
        media: <CrossRoundedFilled />,
        title: intl.formatMessage({ id: 'riba.sidebar.timeline.declined.title' }),
        content: dateToken({
          date: payment.declinedAt.toISOString(),
          locale: intl.locale,
          token: DATE_FORMAT_TOKENS.DATE_TIME_S,
        }),
      },
    });
  }

  return items;
}

export function Timeline({ payment, onApprove, onReject }: TimelineProps): ReactNode {
  const intl = useIntl();
  return (
    <div className={styles.timeline}>
      <DetailsList
        items={[
          {
            label: intl.formatMessage({ id: 'riba.sidebar.timeline.title' }),
            text: '',
          },
        ]}
      />
      <DSTimeline list={getTimelineItems(payment, intl)} />
      <ReverseApproval onApprove={onApprove} onReject={onReject} payment={payment} />
    </div>
  );
}
