import type { ReactNode } from 'react';
import { Placeholder } from '@repo/design-system-kit';
import styles from './placeholder.strict-module.css';

export function ChecksTablePlaceholder(): ReactNode {
  return (
    <>
      <td aria-hidden="true" />
      <td className={styles.cell}>
        <div className={styles['cell-content']}>
          <Placeholder.Block borderRadius="32%" className={styles.icon} />
          <Placeholder.Header lines={1} smallLinesWidth="112px" width="168px" />
        </div>
      </td>
      <td className={styles.cell}>
        <Placeholder.Line width="96px" />
      </td>
      <td className={styles.cell}>
        <Placeholder.Line width="96px" />
      </td>
      <td className={styles.cell}>
        <div className={styles['cell-content']}>
          <Placeholder.Line className="mr-8" width="64px" />
        </div>
      </td>
      <td aria-hidden="true" />
    </>
  );
}
