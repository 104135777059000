import React from 'react';
import { useIntl } from 'react-intl';
import { IconPlusOutlined } from '@repo/monochrome-icons';
import { Button } from '@repo/design-system-kit';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import styles from './forecast-entry.strict-module.css';

interface ForecastEntryEmptyProps {
  onCreateForecastEntry: () => void;
  isReadOnly: boolean;
}

export function ForecastEntryEmpty({
  isReadOnly,
  onCreateForecastEntry,
}: ForecastEntryEmptyProps): React.ReactNode {
  const { formatNumber, formatMessage } = useIntl();
  const abilities = useEmberService('abilities');

  const noAmountDisplay = formatNumber(0, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const canCreate = !isReadOnly && abilities.can('edit forecast cash-flow');

  return (
    <section className={styles['forecast-entry']} data-testid="forecast-entry-empty">
      <div className={styles['forecast-entry-amount-container']}>
        <span
          className={styles['forecast-entry-amount-value']}
          data-testid="forecast-entry-amount-value"
        >
          {noAmountDisplay}
        </span>
        <span
          className={styles['forecast-entry-amount-source']}
          data-testid="forecast-entry-amount-source"
        >
          {formatMessage({ id: 'cashflow.side-panel.forecast.summary.label.empty' })}
        </span>
      </div>
      {canCreate ? (
        <div className={styles['forecast-entry-aside-container']}>
          <Button
            className={styles['forecast-entry-aside-button']}
            data-testid="forecast-entry-create-button"
            onPress={onCreateForecastEntry}
            variant="secondary"
          >
            <IconPlusOutlined className="mr-8" />
            {formatMessage({ id: 'cashflow.side-panel.forecast.summary.button' })}
          </Button>
        </div>
      ) : null}
    </section>
  );
}
