import React, { useState } from 'react';
import type { ReactNode } from 'react';
import {
  Button,
  Checkbox,
  Flag,
  Select,
  SelectOption,
  type CountryCode,
} from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import { LOCALES_DEFINITION } from '@repo/shared-config/constants/locales';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import styles from './certificate-download.strict-module.css';

export function CertificateDownload(): ReactNode {
  const { formatMessage } = useIntl();
  const [withBalance, setWithBalance] = useState(false);

  const { organization } = useOrganizationManager();

  const getLocaleName = (localeCode: string): string => {
    const localeObj = Object.values(LOCALES_DEFINITION).find(l => l.code === localeCode);
    return localeObj?.name || localeCode;
  };

  return (
    <div className="mb-16" data-test-custom-section-checkbox-container>
      <h4 className="title-4 mb-8">{formatMessage({ id: 'bank_accounts.certificate.title' })}</h4>
      <p className="mb-16">{formatMessage({ id: 'bank_accounts.certificate.subtitle' })}</p>

      <Select
        className="mb-16"
        label={formatMessage(
          { id: 'bank_accounts.download.selector-title' },
          { defaultMessage: 'Document Language' }
        )}
        placeholder={formatMessage(
          { id: 'bank_accounts.download.selector-placeholder' },
          { defaultMessage: 'Select a Language' }
        )}
      >
        <SelectOption id="en" key="en">
          <div className={styles.optionContent}>
            <Flag className={styles.flagIcon} code="GB" size="small" variant="square" />
            <span className={styles.optionText}>{getLocaleName('en')}</span>
          </div>
        </SelectOption>
        <SelectOption
          id={organization.legalCountry.toLowerCase()}
          key={organization.legalCountry.toLowerCase()}
        >
          <div className={styles.optionContent}>
            <Flag
              className={styles.flagIcon}
              code={organization.legalCountry as CountryCode}
              size="small"
              variant="square"
            />
            <span className={styles.optionText}>
              {getLocaleName(organization.legalCountry.toLowerCase())}
            </span>
          </div>
        </SelectOption>
      </Select>

      <Checkbox className="mb-24" isSelected={withBalance} onChange={setWithBalance}>
        {formatMessage({ id: 'bank_accounts.certificate.checkbox' })}
      </Checkbox>
      <Button
        className="mb-16"
        data-test-certificate-download
        isDisabled={false}
        isLoading={false}
        size="medium"
        variant="secondary"
      >
        {formatMessage(
          { id: 'bank_accounts.share.download.cta' },
          { defaultMessage: 'Download (PDF)' }
        )}
      </Button>
    </div>
  );
}
