import { type ReactElement, type ReactNode, type ComponentType } from 'react';
import { Avatar, type AvatarSize } from '@repo/design-system-kit';
import cx from 'clsx';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { CategoryAvatar } from 'qonto/react/components/transactions/sidebar/category/cashflow-category/components/category-avatar';
import type { CashflowTransactionCategory } from 'qonto/react/models/cash-flow-category';
import styles from './styles.strict-module.css';
import { ICON_PATHS } from './internal/icon-map';

export type IconSize = '16' | '24';
interface CategoryAvatarDetails {
  isEnabled: boolean;
  icon: string;
  color: string;
}

function useCategoryAvatar(
  src?: string,
  categoryAssignment?: CashflowTransactionCategory | null
): CategoryAvatarDetails {
  const abilities = useEmberService('abilities');
  const categoriesManager = useEmberService('categoriesManager');

  const hasEnrichedLogo = src?.startsWith('https');
  const hasCategoryAssignment = categoryAssignment !== undefined;
  const isEnabled = abilities.can('assign category') && !hasEnrichedLogo && hasCategoryAssignment;

  const cashflowCategory = categoriesManager.flatCategories.find(
    ({ id }) => id === categoryAssignment?.id
  );

  return {
    isEnabled,
    icon: cashflowCategory?.iconKey ?? 'icon_money_clip',
    color: cashflowCategory?.colorKey ?? '--category-gray',
  };
}

interface AvatarRendererProps {
  avatar: 'base' | 'custom';
  content?: ReactElement | ComponentType;
  name?: string;
  dataTestId?: string;
  alt?: string;
  isDisabled?: boolean;
  isLazyLoaded?: boolean;
  size?: AvatarSize;
  src?: string;
  hasBorder?: boolean;
  categoryAssignment?: CashflowTransactionCategory | null;
}

function AvatarRenderer(props: AvatarRendererProps): ReactElement {
  const { avatar, categoryAssignment, src } = props;

  const {
    isEnabled: isCategoryEnabled,
    color: categoryColor,
    icon: categoryIcon,
  } = useCategoryAvatar(src, categoryAssignment);

  if (isCategoryEnabled) {
    return <CategoryAvatar color={categoryColor} icon={categoryIcon} />;
  }

  if (avatar === 'custom') {
    return <Avatar data-testid={props.dataTestId} name={props.name} size={props.size} />;
  }

  let content: ReactElement | undefined;
  if (props.content) {
    if (typeof props.content === 'function') {
      content = <props.content />;
    } else {
      content = props.content;
    }
  }

  return (
    <Avatar
      alt={props.alt}
      className={cx(styles.avatar, props.isDisabled && styles.disabled)}
      content={content}
      data-test-status-avatar-image
      data-testid={props.dataTestId}
      loading={props.isLazyLoaded ? 'lazy' : 'eager'}
      size={props.size}
      src={props.src}
      withBorder={props.hasBorder}
    />
  );
}

interface StatusAvatarProps {
  avatar?: 'base' | 'custom';
  content?: ReactElement;
  src?: string;
  name?: string;
  icon?: ReactElement;
  alt?: string;
  hasBorder?: boolean;
  isDisabled?: boolean;
  isLazyLoaded?: boolean;
  dataTestId?: string;
  className?: string;
  size?: AvatarSize;
  iconSize?: IconSize;
  iconName?: keyof typeof ICON_PATHS;
  iconAltValue?: string;
  categoryAssignment?: CashflowTransactionCategory | null;
}

export function StatusAvatar({
  avatar = 'base',
  content,
  icon,
  hasBorder = false,
  isDisabled = false,
  isLazyLoaded = false,
  alt,
  src,
  name = '',
  dataTestId,
  size,
  iconSize = '16',
  iconName,
  iconAltValue,
  className,
  categoryAssignment,
  ...props
}: StatusAvatarProps): ReactNode {
  let iconElement = icon;
  if (!iconElement && iconName) {
    iconElement = <img alt="" src={ICON_PATHS[iconName]} />;
  }

  return (
    <div className={cx(styles.container, className)} {...props}>
      <AvatarRenderer
        alt={alt}
        avatar={avatar}
        categoryAssignment={categoryAssignment}
        content={content}
        dataTestId={dataTestId}
        hasBorder={hasBorder}
        isDisabled={isDisabled}
        isLazyLoaded={isLazyLoaded}
        name={name}
        size={size}
        src={src}
      />

      {iconElement ? (
        <div
          aria-hidden={iconAltValue ? 'false' : 'true'}
          className={cx(styles.icon, styles[`status-icon-${iconSize}`])}
          data-test-status-avatar-icon={iconName || ''}
          title={iconAltValue}
        >
          {iconElement}
        </div>
      ) : null}
    </div>
  );
}
