import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { FormattedMessage } from 'react-intl';
import { useEffect, useRef, type ReactElement } from 'react';
import cx from 'clsx';
import styles from './styles.strict-module.css';

interface BenefitsListProps {
  children: React.ReactNode;
  context: {
    tracking?: {
      addon_code: string;
      addon_recurrence: string;
    };
  };
  transitionToNext: () => void;
  restartFlow: () => void;
}

export function BenefitsList(props: BenefitsListProps): ReactElement {
  const segment = useEmberService('segment');
  const hasTracked = useRef(false);

  useEffect(() => {
    if (!hasTracked.current) {
      segment.track('remove_addon_lose_benefits_displayed', {
        addon_code: props.context.tracking?.addon_code,
        addon_recurrence: props.context.tracking?.addon_recurrence,
      });
      hasTracked.current = true;
    }
  }, [segment, props.context.tracking]);

  const transitionToNext = (): void => {
    segment.track('remove_addon_lose_benefits_clicked', {
      addon_code: props.context.tracking?.addon_code,
      addon_recurrence: props.context.tracking?.addon_recurrence,
    });
    props.transitionToNext();
  };

  const onKeepCurrentAddon = (): void => {
    segment.track('remove_addon_keep_benefits_clicked', {
      addon_code: props.context.tracking?.addon_code,
      addon_recurrence: props.context.tracking?.addon_recurrence,
    });
    props.restartFlow();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles['benefits-list']}>
        <h1 className="title-1 mb-8" data-test-addon-benefits-list-title>
          <FormattedMessage id="subscription.change.cancellation.addon.benefits.title" />
        </h1>
        <h2 className="body-1 mb-24" data-test-addon-benefits-list-subtitle>
          <FormattedMessage id="subscription.change.cancellation.addon.benefits.subtitle" />
        </h2>

        {props.children}

        <button
          className="btn btn--primary btn--large btn--stretch mb-16"
          data-test-benefits-list-cta-keep
          onClick={onKeepCurrentAddon}
          type="button"
        >
          <FormattedMessage id="subscription.change.cancellation.addon.benefits.keep-addon" />
        </button>

        <button
          className={cx(styles['btn-keep-current'], 'btn btn--tertiary')}
          data-test-benefits-list-cta-continue
          onClick={transitionToNext}
          type="button"
        >
          <FormattedMessage id="subscription.change.cancellation.addon.benefits.remove-addon" />
        </button>
      </div>
    </div>
  );
}
