import type { HTMLAttributes, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'clsx';
import { CheckImage } from 'qonto/react/assets/illustrations/checks/check-image';
import styles from './styles.strict-module.css';

interface ChecksImageProps extends HTMLAttributes<HTMLDivElement> {
  highlightEmitterName?: boolean;
  highlightEmittedDate?: boolean;
  highlightAmount?: boolean;
  highlightCheckId?: boolean;
}

export function ChecksImage({
  highlightEmitterName,
  highlightEmittedDate,
  highlightAmount,
  highlightCheckId,
  ...attributes
}: ChecksImageProps): ReactNode {
  return (
    <div className={styles.wrapper} {...attributes}>
      <div
        className={cx(
          styles['check-image'],
          highlightEmitterName && styles['emitter-name-highlighted'],
          highlightEmittedDate && styles['emitted-date-highlighted'],
          highlightAmount && styles['amount-highlighted'],
          highlightCheckId && styles['check-id-highlighted']
        )}
      >
        {highlightAmount ? (
          <div className={cx('body-2', styles.tooltip)}>
            <FormattedMessage id="checks.details.amount_tooltip" />
          </div>
        ) : null}

        <CheckImage />
      </div>
    </div>
  );
}
