import { useIntl } from 'react-intl';
import { useCallback, type ReactElement } from 'react';
import { Button } from '@repo/design-system-kit';
import { StickyPanel } from 'qonto/react/components/sticky-panel';
import { STATUS as REQUEST_STATUS } from 'qonto/constants/requests';
import { RequestSuccess } from '../request-success/request-success';
import styles from '../styles.strict-module.css';

interface RequestTransferProps {
  requestTransfer: {
    status: string;
  };
  onClickButtonPrimary: (action: string, data: { transfer: Record<string, unknown> }) => void;
  onClickButtonSecondary: (action: string) => void;
}

export function RequestTransfer({
  requestTransfer,
  onClickButtonPrimary,
  onClickButtonSecondary,
}: RequestTransferProps): ReactElement {
  const intl = useIntl();

  const handlePrimaryClick = useCallback(() => {
    onClickButtonPrimary('see-request-details', { transfer: requestTransfer });
  }, [onClickButtonPrimary, requestTransfer]);

  const handleSecondaryClick = useCallback(() => {
    onClickButtonSecondary('close');
  }, [onClickButtonSecondary]);

  return (
    <>
      <RequestSuccess />

      <StickyPanel className={styles['sticky-panel']}>
        {requestTransfer.status === REQUEST_STATUS.PENDING && (
          <Button data-test-sepa-transfer-request-details-button onPress={handlePrimaryClick}>
            {intl.formatMessage({ id: 'requests.transfers.modals.requester.success.btn' })}
          </Button>
        )}

        <Button
          data-test-sepa-transfer-close-button
          onPress={handleSecondaryClick}
          variant="tertiary"
        >
          {intl.formatMessage({ id: 'btn.close' })}
        </Button>
      </StickyPanel>
    </>
  );
}
