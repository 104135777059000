import React from 'react';
import { useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import {
  CashflowForecastEntrySource,
  type CashflowForecastEntry,
} from 'qonto/react/models/cash-flow-forecast-entry';
import { CashflowPeriodRate } from 'qonto/react/models/cash-flow-period';
import { getFormulaBoundDateString } from 'qonto/react/models/cash-flow-forecast-formula';
import styles from './forecast-entry.strict-module.css';
import { ForecastEntryMenu } from './forecast-entry/forecast-entry-menu';

interface ForecastEntryProps {
  entry: CashflowForecastEntry;
  frequency: CashflowPeriodRate;
  isReadOnly: boolean;
  onEditForecastEntry: () => void;
  onDeleteForecastEntry: () => void;
  isFlowSelected: boolean;
}

export function ForecastEntry({
  entry,
  frequency,
  isReadOnly,
  onEditForecastEntry,
  onDeleteForecastEntry,
  isFlowSelected,
}: ForecastEntryProps): React.ReactNode {
  const { formatNumber, formatMessage } = useIntl();
  const abilities = useEmberService('abilities');
  const amountValue = Number(entry.amount.value);

  const amountDisplay = formatNumber(amountValue, {
    style: 'currency',
    currency: entry.amount.currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    signDisplay: 'never',
  });

  const sourceDisplayRecord = {
    [CashflowForecastEntrySource.Manual]: formatMessage({
      id: 'cashflow.side-panel.forecast.summary.label.manual',
    }),
    [CashflowForecastEntrySource.Auto]: formatMessage({
      id: 'cashflow.side-panel.forecast.summary.label.manual',
    }),
    [CashflowForecastEntrySource.Formula]: formatMessage({
      id: 'cashflow.side-panel.forecast.summary.label.formula',
    }),
    [CashflowForecastEntrySource.Aggregated]: formatMessage({
      id: 'cashflow.side-panel.forecast.summary.label.aggregated',
    }),
  };

  const sourceDisplayLabel = ({ isFlashForecast, source }: CashflowForecastEntry): string => {
    if (source === CashflowForecastEntrySource.Manual && isFlashForecast) {
      return formatMessage({
        id: 'cash-flow.side-panel.forecast.set-with-ai.label',
      });
    }

    return sourceDisplayRecord[source];
  };

  const sourceDisplay =
    frequency === CashflowPeriodRate.Monthly && !isFlowSelected
      ? sourceDisplayLabel(entry)
      : sourceDisplayRecord[CashflowForecastEntrySource.Aggregated];

  const canEdit =
    !isReadOnly &&
    abilities.can('edit forecast cash-flow') &&
    entry.source !== CashflowForecastEntrySource.Aggregated;

  return (
    <section className={styles['forecast-entry']}>
      <div className={styles['forecast-entry-amount-container']}>
        <span
          className={styles['forecast-entry-amount-value']}
          data-testid="forecast-entry-amount-value"
        >
          {amountDisplay}
        </span>
        <span
          className={styles['forecast-entry-amount-source']}
          data-testid="forecast-entry-amount-source"
        >
          {sourceDisplay}
        </span>
      </div>
      {frequency === CashflowPeriodRate.Monthly ? (
        <div className={styles['forecast-entry-aside-container']}>
          {entry.source === CashflowForecastEntrySource.Formula && entry.formula ? (
            <span className={styles['forecast-entry-range']} data-testid="forecast-entry-range">
              {getFormulaBoundDateString(entry.formula)}
            </span>
          ) : null}
          {canEdit ? (
            <ForecastEntryMenu
              onDeleteClick={onDeleteForecastEntry}
              onEditClick={onEditForecastEntry}
            />
          ) : null}
        </div>
      ) : null}
    </section>
  );
}
