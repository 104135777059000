import { type ReactElement, type ReactNode } from 'react';
import cx from 'clsx';
import styles from './sidebar-info-box.strict-module.css';

interface SidebarInfoBoxProps {
  children: ReactNode;
  className?: string;
}

export function SidebarInfoBox({
  children,
  className,
  ...props
}: SidebarInfoBoxProps): ReactElement {
  return (
    <div className={cx(styles.infobox, className)} {...props}>
      {children}
    </div>
  );
}
