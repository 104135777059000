import type { ReactNode } from 'react';
import { Button } from '@repo/design-system-kit';
import { IconCrossOutlined } from '@repo/monochrome-icons';
import { useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { cashFlowSidePanelManager } from 'qonto/react/contexts/cash-flow-sidepanel-context';
import type { LabelTableInterval } from '../../../models/labels-cashflow-display';
import type { CategoriesTableRow } from '../../../models/categories-table-display';
import styles from './header.strict-module.css';
import { DefaultHeader } from './default-header';
import { ForecastGenerationHeader } from './forecast-generation-header';

export interface SidePanelHeaderProps {
  selectedInterval: LabelTableInterval | undefined;
  frequencyFormat: string;
  selectedCategories: CategoriesTableRow[];
  onCloseSidepanel: () => void;
  isFlowSelected: boolean;
  periodNavigation: {
    onNextMonth?: () => void;
    isLastPeriod?: boolean;
    onPreviousMonth?: () => void;
    isFirstPeriod?: boolean;
  };
}

export function SidepanelHeader(props: SidePanelHeaderProps): ReactNode {
  const { formatMessage } = useIntl();
  const segment = useEmberService('segment');
  const { mode } = cashFlowSidePanelManager.useCashFlowSidePanel();

  const trackCloseSidepanel = (): void => {
    segment.track('cash-flow_sidepanel_close');
  };

  return (
    <header className={styles.container} data-testid="cash-flow-sidepanel-header">
      {mode === 'default' ? <DefaultHeader {...props} /> : <ForecastGenerationHeader mode={mode} />}
      <span>
        <Button
          aria-label={formatMessage({ id: 'a11y.buttons.close-aria-label' })}
          data-testid="cash-flow-sidepanel-header-close"
          iconOnly
          onPress={() => {
            trackCloseSidepanel();
            props.onCloseSidepanel();
          }}
          size="medium"
          variant="tertiary"
        >
          <IconCrossOutlined />
        </Button>
      </span>
    </header>
  );
}
