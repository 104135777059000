import { Button, InfoIcon, TextField, Tooltip } from '@repo/design-system-kit';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useIntl } from 'react-intl';
import { useCopyToClipboard } from 'usehooks-ts';
import { Copy } from 'qonto/react/assets/icons/copy';
import styles from './styles.strict-module.css';

export interface EInvoicingAddressProps {
  address: string;
  trackEventName?: string;
}

export function EInvoicingAddress({
  address,
  trackEventName,
}: EInvoicingAddressProps): JSX.Element {
  const { formatMessage } = useIntl();
  const [_, copy] = useCopyToClipboard();
  const segment = useEmberService('segment');
  const toastFlashMessages = useEmberService('toast-flash-messages');

  const handleCopy = async (): Promise<void> => {
    await copy(address);

    toastFlashMessages.toastInfo(
      formatMessage({ id: 'settings.einvoicing.receive-status.address.success-toast' })
    );

    if (trackEventName) {
      segment.track(trackEventName);
    }
  };

  const onClickHandler = (): void => {
    void handleCopy();
  };

  return (
    <div className={styles.container} data-testid="e-invoicing-address-field">
      <div className={styles.input}>
        <div className="flex align-center mb-4">
          <label className="body-1 mr-8" htmlFor="e-invoicing-address">
            {formatMessage({ id: 'settings.einvoicing.receive-status.address.label' })}
          </label>
          <Tooltip
            label={formatMessage({ id: 'settings.einvoicing.receive-status.address.tooltip' })}
            needsButton
            placement="top"
            tooltipTestId="info-tooltip"
          >
            <InfoIcon height={12} width={12} />
          </Tooltip>
        </div>
        <TextField
          data-testid="e-invoicing-address-value"
          id="e-invoicing-address"
          isReadOnly
          label=""
          placeholder=""
          value={address}
        />
      </div>
      <Button
        aria-label={formatMessage({ id: 'a11y.buttons.copy-aria-label' })}
        data-testid="e-invoicing-address-copy-button"
        iconOnly
        onPress={onClickHandler}
        type="button"
        variant="secondary"
      >
        <Copy />
      </Button>
    </div>
  );
}
