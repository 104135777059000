import * as React from 'react';
import { useIntl } from 'react-intl';
import {
  RadioButtonBoolean,
  RadioGroupBoolean,
  type RadioGroupBooleanProps,
} from '@repo/design-system-kit';

export interface WithUbo {
  ubo: boolean;
}

export interface UboRadioGroupProps
  extends Omit<RadioGroupBooleanProps, 'onChange' | 'value' | 'label' | 'labelClassName' | 'name'> {
  entity: WithUbo;
  showValidations: boolean;
}

export function UboRadioGroup({
  entity,
  errorMessage,
  showValidations,
  ...props
}: UboRadioGroupProps): React.ReactElement {
  const { formatMessage } = useIntl();

  return (
    <RadioGroupBoolean
      errorMessage={errorMessage}
      isInvalid={Boolean(errorMessage && showValidations)}
      label={formatMessage({
        id: 'organizations.profile.owner-form.role.label-ubo',
      })}
      labelClassName="body-1"
      name="ubo"
      onChange={value => {
        entity.ubo = value;
      }}
      tooltipText={formatMessage({
        id: 'organizations.profile.owner-form.tooltip.ubo',
      })}
      value={entity.ubo}
      {...props}
    >
      <RadioButtonBoolean data-test-ubo-radio-true="" value>
        {formatMessage({ id: 'organizations.profile.owner-form.role.radio-button-yes' })}
      </RadioButtonBoolean>
      <RadioButtonBoolean data-test-ubo-radio-false="" value={false}>
        {formatMessage({ id: 'organizations.profile.owner-form.role.radio-button-no' })}
      </RadioButtonBoolean>
    </RadioGroupBoolean>
  );
}
