import type { ReactNode } from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './placeholder.strict-module.css';

export function Placeholder({ ...props }): ReactNode {
  return (
    <div className={styles.placeholder} {...props}>
      <SkeletonLoader.Block borderRadius="50%" className="mr-16" height="48px" width="48px" />
      <div className={styles['placeholder-text']}>
        <SkeletonLoader.Line width="90px" />
        <SkeletonLoader.Line width="100px" />
      </div>
    </div>
  );
}
