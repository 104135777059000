import type { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  FACILE_INSURANCE_PRODUCT_LEGAL_TYPES_SUPERSCRIPT,
  // @ts-expect-error - file not yet migrated
} from 'qonto/constants/insurance-hub';
import styles from './styles.strict-module.css';

interface ProductCardProps {
  description: string;
  logo: {
    alt: string;
    url: string;
  } | null;
  name: string;
  price: number;
  product: string;
  risks: string[];
  uid?: string;
  showLegalNoticeSuperscript?: string | null;
}

const legalTypes = FACILE_INSURANCE_PRODUCT_LEGAL_TYPES_SUPERSCRIPT as Record<string, number>;

export function ProductCard({
  description,
  logo,
  name,
  price,
  risks,
  showLegalNoticeSuperscript,
  uid,
  ...rest
}: ProductCardProps): ReactNode {
  const { formatNumber } = useIntl();

  const getKeyFor = (risk: string): string => {
    return risk.replace(/\W+/g, '-');
  };

  const superScript: number = showLegalNoticeSuperscript ? (legalTypes[uid ?? ''] ?? 0) : 0;

  return (
    <div className={styles.container} data-test-insurance-hub-product-card {...rest}>
      <header>
        <img
          alt={logo?.alt}
          className={styles.logo}
          data-test-insurance-hub-product-card-logo
          loading="lazy"
          src={logo?.url}
        />
        <h3 className={styles.name} data-test-insurance-hub-product-card-name>
          {name}
        </h3>
        <p className={styles.description} data-test-insurance-hub-product-card-description>
          {description}
        </p>
      </header>
      <div className={styles.metadata}>
        <div className={styles['risks-container']}>
          <p className={styles.label}>
            <FormattedMessage id="insurance-hub.offer-card.label.risks" />
          </p>
          <div data-test-insurance-hub-product-card-risks>
            <ul className={styles.risks}>
              {risks.map(risk => (
                <li className={styles.tag} key={getKeyFor(risk)}>
                  {risk}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div>
          <p className={styles.label}>
            <FormattedMessage id="insurance-hub.offer-card.label.price" />
          </p>
          <p className="body-1" data-test-insurance-hub-product-card-price>
            <FormattedMessage
              id="insurance-hub.offer-card.amount.price"
              values={{
                price: formatNumber(price, {
                  style: 'currency',
                  currency: 'EUR',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }),
              }}
            />
            {superScript ? (
              <sup data-test-insurance-hub-product-card-price-sup>{superScript}</sup>
            ) : null}
          </p>
        </div>
      </div>
    </div>
  );
}
