import type { ReactNode } from 'react';
import { useState } from 'react';
import { cashFlowSidePanelManager } from 'qonto/react/contexts/cash-flow-sidepanel-context';
import { ForecastAILoading } from '../forecast-ai-loading';
import { ImportForecastView } from './import-forecast-view';

const IMPORT_FORECAST_VIEWS = {
  IMPORT: 'import',
  LOADING: 'loading',
} as const;

export type ImportForecastViewType =
  (typeof IMPORT_FORECAST_VIEWS)[keyof typeof IMPORT_FORECAST_VIEWS];

export function ImportForecast(): ReactNode {
  const [view, setView] = useState<ImportForecastViewType>(IMPORT_FORECAST_VIEWS.IMPORT);
  const { closeSidepanel } = cashFlowSidePanelManager.useCashFlowSidePanel();

  switch (view) {
    case IMPORT_FORECAST_VIEWS.LOADING:
      return <ForecastAILoading onLoadingCompleted={closeSidepanel} />;
    case IMPORT_FORECAST_VIEWS.IMPORT:
    default:
      return (
        <ImportForecastView
          next={() => {
            setView(IMPORT_FORECAST_VIEWS.LOADING);
          }}
        />
      );
  }
}
