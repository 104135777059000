import React from 'react';
import { type ReactNode } from 'react';
import cx from 'clsx';
import styles from './styles.strict-module.css';

interface AvatarProps {
  name?: string;
}

export function Avatar({ name }: AvatarProps): ReactNode {
  const firstLetter: string | undefined = name?.match(/\w/)?.[0];

  return (
    <div
      className={cx(styles['supplier-avatar'], !firstLetter && styles['gray-background'])}
      data-test-supplier-avatar-wrapper
    >
      <p className="body-1" data-test-supplier-avatar>
        {firstLetter}
      </p>
    </div>
  );
}
