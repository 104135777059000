import { type PropsWithChildren, type ReactNode } from 'react';
import cx from 'clsx';
import styles from './styles.strict-module.css';
import { Info, type InfoProps } from './info';

interface Beneficiary {
  name: string;
  currency: string;
  displayAccountNumber: string;
  trusted: boolean;
  qontoBankAccount: boolean;
}

interface BeneficiaryCardProps extends PropsWithChildren {
  beneficiary: Beneficiary;
  onSelect?: (beneficiary: Beneficiary) => void;
}

export function BeneficiaryCard({
  beneficiary,
  onSelect,
  children,
  ...props
}: BeneficiaryCardProps): ReactNode {
  const infoProps: InfoProps = {
    name: beneficiary.name,
    currency: beneficiary.currency,
    displayAccountNumber: beneficiary.displayAccountNumber,
    isTrusted: beneficiary.trusted,
    isQontoBankAccount: beneficiary.qontoBankAccount,
  };

  return (
    <section
      className={cx(
        styles.wrapper,
        Boolean(onSelect) && styles['with-hover'],
        Boolean(children) && styles['with-actions']
      )}
      {...props}
    >
      {onSelect ? (
        <button
          className={styles['beneficiary-card-button']}
          data-test-beneficiary-card-button
          onClick={() => {
            onSelect(beneficiary);
          }}
          type="button"
        >
          <Info {...infoProps} />
        </button>
      ) : (
        <Info {...infoProps} />
      )}

      {children}
    </section>
  );
}
