import { FormattedMessage, useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { EmptyStatesDiscover } from '@repo/domain-kit/pricing';
import { importAsset } from 'qonto/helpers/import-asset';

interface NotEligibleStateProps {
  isOrgOlderThan6Months: boolean;
  onPrimaryCtaClick: () => void;
  onSecondaryCtaClick: () => void;
}

export function NotEligibleState({
  isOrgOlderThan6Months,
  onPrimaryCtaClick,
  onSecondaryCtaClick,
}: NotEligibleStateProps): JSX.Element {
  const { formatMessage } = useIntl();
  const segment = useEmberService('segment');
  const zendeskLocalization = useEmberService('zendesk-localization');

  const title = isOrgOlderThan6Months
    ? formatMessage({
        id: 'financing.pay-later.cockpit.empty-state.a2.not-eligible.title',
      })
    : formatMessage({
        id: 'financing.pay-later.cockpit.empty-state.a2.not-eligible-below-6-months.title',
      });

  const handleFaqClick = (): void => {
    segment.track('pay-later-faq_clicked', {
      origin: 'cockpit_noneligible',
    });
  };

  const subtitle = isOrgOlderThan6Months ? (
    <p className="body-2 subtitle">
      <FormattedMessage
        id="financing.pay-later.cockpit.empty-state.a2.not-eligible-more-6-months.subtitle"
        values={{
          link: (
            <a
              className="body-link"
              data-testid="pay-later-not-eligible-state-faq-link"
              href={zendeskLocalization.getLocalizedArticle(8271688)}
              key="subtitle-link"
              onClick={handleFaqClick}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FormattedMessage id="financing.pay-later.cockpit.empty-state.a2.not-eligible-more-6-months.subtitle-link" />
            </a>
          ),
        }}
      />
    </p>
  ) : (
    <FormattedMessage id="financing.pay-later.cockpit.empty-state.a2.not-eligible-below-6-months.subtitle" />
  );

  const primaryCtaText = isOrgOlderThan6Months
    ? formatMessage({
        id: 'financing.pay-later.cockpit.empty-state.a2.not-eligible-more-6-months.primary-cta',
      })
    : formatMessage({
        id: 'financing.pay-later.cockpit.empty-state.a2.not-eligible-below-6-months.primary-cta',
      });

  const secondaryCtaText = isOrgOlderThan6Months
    ? formatMessage({
        id: 'financing.pay-later.cockpit.empty-state.a2.not-eligible-more-6-months.secondary-cta',
      })
    : formatMessage({
        id: 'financing.pay-later.cockpit.empty-state.a2.not-eligible-below-6-months.secondary-cta',
      });

  return (
    <EmptyStatesDiscover
      align="center"
      badgeText={formatMessage({ id: 'empty-states.system.engage.a2.badge' })}
      badgeType="notAvailable"
      ctaText={primaryCtaText}
      data-testid="pay-later-not-eligible-state"
      illuSrc={importAsset(['/illustrations/ill-cp-pay-later-not-eligible.svg'])}
      onCtaClick={onPrimaryCtaClick}
      onSecondaryCtaClick={onSecondaryCtaClick}
      secondaryCtaText={secondaryCtaText}
      subtitle={subtitle}
      title={title}
    />
  );
}
