/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { cellContextManager } from 'qonto/react/contexts/cell-context';
import { Status } from 'qonto/react/graphql';
import { BaseCell } from '../base-cell';
import { CopyButton } from '../../buttons';
import styles from './styles.strict-module.css';

export function CaptionCell(): ReactNode {
  const transaction = cellContextManager.useCellContext();
  const { description: caption, status, subjectType, financingInstallment } = transaction;
  const isDeclined = status === Status.Declined || status === Status.Reversed;
  const isFinancingInstallment = (subjectType as string) === 'FinancingInstallment';
  const { formatMessage } = useIntl();

  let displayCaption = caption;

  if (
    isFinancingInstallment &&
    financingInstallment?.currentInstallmentNumber &&
    financingInstallment.totalInstallmentsNumber
  ) {
    displayCaption = formatMessage(
      {
        id: 'transactions.sidebar.header.financing-installment.reference',
      },
      {
        current: financingInstallment.currentInstallmentNumber,
        total: financingInstallment.totalInstallmentsNumber,
      }
    );
  }

  if (isDeclined || !displayCaption) {
    return (
      <BaseCell>
        <span data-testid="empty">-</span>
      </BaseCell>
    );
  }

  return (
    <BaseCell
      actionSlot={
        <CopyButton text={displayCaption} trackingEventSource="reference_cell_copy_reference" />
      }
      actionSlotAlignment="right"
    >
      <span className={cx(styles.ellipsis, 'body-2')} data-testid="caption-cell">
        {displayCaption}
      </span>
    </BaseCell>
  );
}
