import { BadgeStatus, type StatusType } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import { INVOICE_STATUSES, TABLE_STATUS_TAG_LEVELS } from 'qonto/constants/supplier-invoice.ts';

interface StatusTagProps {
  status: (typeof INVOICE_STATUSES)[keyof typeof INVOICE_STATUSES];
}

interface StatusValues {
  level: StatusType;
  text: string;
}

const useStatusValues = ({ status }: StatusTagProps): StatusValues => {
  const { formatMessage } = useIntl();

  switch (status) {
    case INVOICE_STATUSES.scheduled:
      return {
        text: formatMessage({
          id: 'supplier-list.sidebar.supplier-information.upcoming-invoices.scheduled.status',
        }),
        level: TABLE_STATUS_TAG_LEVELS.scheduled,
      };
    case INVOICE_STATUSES.pending:
      return {
        text: formatMessage({ id: 'supplier-invoices.preview.status.pending-request' }),
        level: TABLE_STATUS_TAG_LEVELS.pending,
      };
    case INVOICE_STATUSES.archived:
      return {
        text: formatMessage({ id: 'supplier-invoices.preview.status.archived' }),
        level: TABLE_STATUS_TAG_LEVELS.archived,
      };
    case INVOICE_STATUSES.paid:
      return {
        text: formatMessage({ id: 'supplier-invoices.preview.status.paid' }),
        level: TABLE_STATUS_TAG_LEVELS.paid,
      };
    case INVOICE_STATUSES.awaitingPayment:
      return {
        text: formatMessage({ id: 'supplier-invoices.preview.status.to-pay' }),
        level: TABLE_STATUS_TAG_LEVELS.awaitingPayment,
      };
    case INVOICE_STATUSES.toReview:
      return {
        text: formatMessage({ id: 'supplier-invoices.preview.status.imported' }),
        level: TABLE_STATUS_TAG_LEVELS.toReview,
      };
    case INVOICE_STATUSES.toPay:
      return {
        text: formatMessage({ id: 'supplier-invoices.preview.status.to-pay' }),
        level: TABLE_STATUS_TAG_LEVELS.inbox,
      };
    case INVOICE_STATUSES.toApprove:
      return {
        text: formatMessage({ id: 'supplier-invoices.preview.status.to-approve' }),
        level: TABLE_STATUS_TAG_LEVELS.toApprove,
      };
    case INVOICE_STATUSES.rejected:
      return {
        text: formatMessage({ id: 'supplier-invoices.preview.status.rejected' }),
        level: TABLE_STATUS_TAG_LEVELS.rejected,
      };
    default:
      return {
        text: formatMessage({ id: 'supplier-invoices.section.tab.inbox' }),
        level: TABLE_STATUS_TAG_LEVELS.inbox,
      };
  }
};

export function StatusTag(props: StatusTagProps): JSX.Element {
  const { text, level } = useStatusValues(props);

  return <BadgeStatus data-test-status="" level={level} text={text} />;
}
