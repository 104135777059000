import React, { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { ForecastStatusBadge } from '@repo/domain-kit/cashflow';
import { ClockOutlined } from 'qonto/react/assets/icons/clock-outlined';
import type { Amount } from 'qonto/react/models/amount';
import styles from './projected-cell.strict-module.css';

interface ProjectedCellProps {
  projectedAmount: Amount<string> | undefined;
  projectedPercentage?: string;
  flowType?: 'inflows' | 'outflows';
}
export function ProjectedCell({
  projectedAmount,
  projectedPercentage,
  flowType = 'inflows',
}: ProjectedCellProps): ReactNode {
  if (!projectedAmount?.value) {
    return (
      // eslint-disable-next-line formatjs/no-literal-string-in-jsx -- no copy needed
      <span className={styles.projectedAmount} data-testid="projected-cell-empty">
        -
      </span>
    );
  }

  return (
    <div className={styles.projectedCellContainer} data-testid="projected-cell">
      <span className={styles.projectedAmount} data-testid="projected-cell-amount">
        {Number(projectedAmount.value).toFixed()}
      </span>
      <ForecastStatusBadge actualPercentage={projectedPercentage} flowType={flowType} />
    </div>
  );
}

export function ProjectedCellHeader(): ReactNode {
  const { formatMessage } = useIntl();

  return (
    <div className={cx(styles.cellWrapper, styles.header)} data-testid="projected-cell-header">
      <div className={styles.iconContainer} data-testid="projected-cell-header-icon">
        <ClockOutlined />
      </div>
      <span className={styles.label} data-testid="projected-cell-header-label">
        {formatMessage({
          id: 'cash-flow.graph.period-tooltip.projected',
        })}
      </span>
    </div>
  );
}
