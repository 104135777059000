import cx from 'clsx';
import { type ReactNode } from 'react';
import { ToggleButton } from 'react-aria-components';
import styles from './toggle-filter.strict-module.css';

interface ToggleFilterProps {
  children: ReactNode;
  isSelected: boolean;
  onClick: () => void;
}

export function ToggleFilter({ isSelected, children, onClick }: ToggleFilterProps): ReactNode {
  return (
    <ToggleButton
      className={cx(styles.filter, 'btn-filter', isSelected && 'btn--active')}
      data-test-toggle-button
      isSelected={isSelected}
      onChange={onClick}
    >
      {children}
    </ToggleButton>
  );
}
