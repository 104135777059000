import { type ReactNode } from 'react';
import { IconCertifiedFilled } from '@repo/monochrome-icons';
import { QontoFlower } from 'qonto/react/assets/icons';
import styles from './styles.strict-module.css';

interface InfoProps {
  /** The ID of the beneficiary */
  id: string;
  /** The name of the beneficiary */
  name: string;
  /** The beneficiary's IBAN as a display value */
  displayAccountNumber: string;
  /** Whether the beneficiary is marked as trusted */
  isTrusted?: boolean;
  /** Whether the beneficiary's bank account is a Qonto account */
  isQontoBankAccount?: boolean;
}

export function Info({
  id,
  name,
  displayAccountNumber,
  isTrusted = false,
  isQontoBankAccount = false,
}: InfoProps): ReactNode {
  return (
    <div className={styles.container} data-test-select-beneficiary-item={id}>
      <p className={styles.name}>
        <span className="body1" data-test-beneficiary-name>
          {name}
        </span>
        {isTrusted ? <IconCertifiedFilled aria-hidden data-test-beneficiary-trusted /> : null}
      </p>

      <p className={styles.account}>
        {isQontoBankAccount ? <QontoFlower aria-hidden data-test-beneficiary-account-icon /> : null}
        <span className="body2" data-test-beneficiary-account-number>
          {displayAccountNumber}
        </span>
      </p>
    </div>
  );
}
