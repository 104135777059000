import { type ReactNode } from 'react';
import cx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { SortIcon } from 'qonto/react/components/paginated-table';
import styles from './styles.strict-module.css';

export function Header(): ReactNode {
  return (
    <>
      <th aria-hidden="true" className={cx(styles.headerCell, styles.empty)} scope="col" />

      <th className={cx(styles.headerCell, styles.supplier)} data-test-supplier-column scope="col">
        <div className={styles.headerContent}>
          <FormattedMessage id="financing.pay-later.cockpit.table.header.supplier" />
        </div>
      </th>

      <th
        className={cx(styles.headerCell, styles.completedOn)}
        data-test-completed-on-column
        scope="col"
      >
        <div className={cx(styles.headerContent, styles.active)}>
          <FormattedMessage id="financing.pay-later.cockpit.table.header.completed-on" />
          <SortIcon isActive isAscending />
        </div>
      </th>

      <th className={styles.headerCell} data-test-financed-amount-column scope="col">
        <div className={styles.headerContent}>
          <FormattedMessage id="financing.pay-later.cockpit.table.header.financed-amount" />
        </div>
      </th>

      <th
        className={cx(styles.headerCell, styles.totalRepayment)}
        data-test-total-repayment-column
        scope="col"
      >
        <div className={styles.headerContent}>
          <FormattedMessage id="financing.pay-later.cockpit.table.header.total-repayment" />
        </div>
      </th>

      <th aria-hidden="true" className={cx(styles.headerCell, styles.empty)} scope="col" />
    </>
  );
}
