import type { SVGProps, ReactNode } from 'react';

export function ArrowBottomRoundedOutlined(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_888_23413)">
        <path
          d="M7.5 4.5C7.5 4.22386 7.72386 4 8 4C8.27614 4 8.5 4.22386 8.5 4.5V10.2929L11.1464 7.64645C11.3417 7.45118 11.6583 7.45118 11.8536 7.64645C12.0488 7.84171 12.0488 8.15829 11.8536 8.35355L8.35355 11.8536C8.15829 12.0488 7.84171 12.0488 7.64645 11.8536L4.14645 8.35355C3.95118 8.15829 3.95118 7.84171 4.14645 7.64645C4.34171 7.45118 4.65829 7.45118 4.85355 7.64645L7.5 10.2929V4.5Z"
          fill="#636360"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 8C0 3.58 3.58 0 8 0C12.42 0 16 3.58 16 8C16 12.42 12.42 16 8 16C3.58 16 0 12.42 0 8ZM1 8C1 11.86 4.14 15 8 15C11.86 15 15 11.86 15 8C15 4.14 11.86 1 8 1C4.14 1 1 4.14 1 8Z"
          fill="#636360"
        />
      </g>
      <defs>
        <clipPath id="clip0_888_23413">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
