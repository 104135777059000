import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { AiLoadingState } from 'qonto/react/components/cash-flow/components/first-time-experience/ai-loading-state';
import ENV from 'qonto/config/environment';

interface ForecastAiLoadingProps {
  onLoadingCompleted: () => void;
}

const isTesting = (ENV as { environment: string }).environment === 'test';

const DELAY_MS = isTesting ? 1 : 2000;
const STEP_INTERVAL_MS = isTesting ? 1 : 500;

export function ForecastAILoading({ onLoadingCompleted }: ForecastAiLoadingProps): ReactNode {
  const { formatMessage } = useIntl();
  const steps = [
    {
      label: formatMessage({ id: 'cash-flow.first-time-experience.sidepanel.loading.check-1' }),
      stepInterval: STEP_INTERVAL_MS,
    },
    {
      label: formatMessage({ id: 'cash-flow.first-time-experience.sidepanel.loading.check-2' }),
      stepInterval: STEP_INTERVAL_MS,
    },
    {
      label: formatMessage({ id: 'cash-flow.first-time-experience.sidepanel.loading.check-3' }),
      stepInterval: STEP_INTERVAL_MS,
    },
  ];
  return (
    <AiLoadingState
      data-testid="forecast-loading"
      onLoadingCompleted={onLoadingCompleted}
      onLoadingCompletedDelay={DELAY_MS}
      progressBarLabel={formatMessage({
        id: 'cash-flow.first-time-experience.sidepanel.flash.loading.label',
      })}
      steps={steps}
    />
  );
}
