import React, { type HTMLAttributes, type ReactNode } from 'react';
import styles from './styles.strict-module.css';

interface BaseProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

interface TypeProps {
  children: ReactNode;
}

interface ContentProps {
  children: ReactNode;
}

function Type({ children }: TypeProps): React.ReactElement {
  return (
    <div className={styles.type} data-test-header-base-type>
      {children}
    </div>
  );
}

function Content({ children }: ContentProps): React.ReactElement {
  return (
    <div className={styles.content} data-test-header-base-content>
      {children}
    </div>
  );
}

function Base({ children, ...props }: BaseProps): React.ReactElement {
  return <div {...props}>{children}</div>;
}

Base.Type = Type;
Base.Content = Content;

export { Base };
