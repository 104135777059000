import type { ReactNode } from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './created.strict-module.css';

export function Created({ ...props }): ReactNode {
  return (
    <div className={styles['placeholder-sidebar']} {...props} data-test-sidebar-placeholder>
      <section className={styles.details}>
        <div className={styles['details-header']}>
          <SkeletonLoader.Line className="mb-20" width="160px" />
          <SkeletonLoader.Line width="80px" />
        </div>
        <div className={styles['details-content']}>
          <SkeletonLoader.Line className="mb-24" width="160px" />
          <SkeletonLoader.Line className="mb-24" width="120px" />
          <SkeletonLoader.Line width="200px" />
        </div>
        <div className={styles['details-footer']}>
          <SkeletonLoader.Line width="120px" />
        </div>
      </section>
    </div>
  );
}
