import cx from 'clsx';
import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import styles from './styles.strict-module.css';

interface ReferralListItemProps {
  referral: {
    referreeName: string;
    rewardedAt?: string;
    rewardAmount: number;
    rewardAmountCurrency: string;
  };
  status: string;
  index?: number;
}

export function ReferralListItem({ referral, status, index }: ReferralListItemProps): ReactNode {
  const { formatNumber, locale, formatMessage } = useIntl();

  const formattedDate = referral.rewardedAt
    ? dateToken({
        date: referral.rewardedAt,
        locale,
        token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
      })
    : '';

  const formattedAmount = formatNumber(Math.abs(referral.rewardAmount), {
    style: 'currency',
    currency: referral.rewardAmountCurrency,
    signDisplay: 'always',
  });

  return (
    <div className={styles.wrapper} data-test-referral-item={index}>
      <div className={cx('body-2', styles['referral-item'])}>
        <div>
          <p
            className={cx(styles.title, status === 'rewarded' && styles.rewarded)}
            data-test-referral-title
          >
            {referral.referreeName}
          </p>
          {status === 'rewarded' && (
            <p className={styles.date}>
              {formatMessage(
                { id: 'referral.tiered-program.sidebar.earn.label' },
                { date: formattedDate }
              )}
            </p>
          )}
        </div>
        <p className={styles.amount}>{formattedAmount}</p>
      </div>
    </div>
  );
}
