import { TabList, Tabs } from '@repo/design-system-kit';
import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import styles from './tabs.strict-module.css';

export const PAYMENT_MODAL_TABS = {
  INVOICE: 'invoice',
  PAYMENT_LINK: 'payment-link',
};

type TabId = keyof typeof PAYMENT_MODAL_TABS;

interface PaymentModalTabsProps {
  activeId: TabId;
  changeTab: (id: TabId) => void;
}

export function PaymentModalTabs({ activeId, changeTab }: PaymentModalTabsProps): ReactNode {
  const { formatMessage } = useIntl();

  const tabs = [
    {
      id: 'invoice',
      title: formatMessage({ id: 'receivable-invoices.payment-link-creation.preview.invoice' }),
      'data-test-tabs-nav-item': 0,
      'data-test-tabs-nav-item-value': 'invoice',
    },
    {
      id: 'payment-link',
      title: formatMessage({
        id: 'receivable-invoices.payment-link-creation.preview.payment-link',
      }),
      'data-test-tabs-nav-item': 1,
      'data-test-tabs-nav-item-value': 'payment-link',
    },
  ];

  return (
    <Tabs
      activeId={activeId}
      changeTab={key => {
        changeTab(key as TabId);
      }}
    >
      <TabList className={styles.list} tabs={tabs} />
    </Tabs>
  );
}
