import { useIntl } from 'react-intl';
import { useMemo, type ReactNode } from 'react';
import { CashflowPeriodRate } from 'qonto/react/models/cash-flow-period';
import type { BalanceTableType } from './balance-table';

interface AttributeCellProps {
  type: BalanceTableType;
  frequency?: CashflowPeriodRate;
}

export function AttributeCell(
  { type, frequency }: AttributeCellProps = { type: 'start', frequency: CashflowPeriodRate.Monthly }
): ReactNode {
  const { formatMessage } = useIntl();
  const startHeaderTitles: Partial<Record<CashflowPeriodRate, string>> = useMemo(
    () => ({
      [CashflowPeriodRate.Monthly]: formatMessage({
        id: 'cash-flow.table.header-column.start-of-month',
      }),
      [CashflowPeriodRate.Quarterly]: formatMessage({
        id: 'cash-flow.table.header-column.start-of-quarter',
      }),
      [CashflowPeriodRate.Yearly]: formatMessage({
        id: 'cash-flow.table.header-column.start-of-year',
      }),
    }),
    [formatMessage]
  );

  const endHeaderTitles: Partial<Record<CashflowPeriodRate, string>> = useMemo(
    () => ({
      [CashflowPeriodRate.Monthly]: formatMessage({
        id: 'cash-flow.table.header-column.end-of-month',
      }),
      [CashflowPeriodRate.Quarterly]: formatMessage({
        id: 'cash-flow.table.header-column.end-of-quarter',
      }),
      [CashflowPeriodRate.Yearly]: formatMessage({
        id: 'cash-flow.table.header-column.end-of-year',
      }),
    }),
    [formatMessage]
  );

  const headerTitle = useMemo(() => {
    const headerTitles = type === 'start' ? startHeaderTitles : endHeaderTitles;
    return frequency ? headerTitles[frequency] : headerTitles[CashflowPeriodRate.Monthly];
  }, [frequency, type, startHeaderTitles, endHeaderTitles]);

  return <span>{headerTitle}</span>;
}
