/* eslint-disable @qonto/use-composable-popup -- Temporarily disabled the rule until migrating the popup */
import { useState, type ReactNode } from 'react';
import type { Uppy } from '@uppy/core';
import { useIntl } from 'react-intl';
import { Popup } from '@repo/design-system-kit';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { Popover } from 'qonto/react/components/table-v2/popover';
import type { DataWithIconProps } from 'qonto/react/components/table-v2/cells/data-with-icon-cell/data-with-icon-cell';
import { cellContextManager } from 'qonto/react/contexts/cell-context';
import { useUnlinkTransactionAttachment } from 'qonto/react/hooks/mutations/use-unlink-attachment';
import type { Body, Meta } from 'qonto/react/hooks/use-attachments-uploader';
import styles from './styles.strict-module.css';
import { ActionsPopoverSection } from './actions-popover-section';
import { DisclaimerPopoverSection } from './disclaimer-popover-section';
import { AttachmentListSection } from './attachment-list-section';

interface AttachmentCellPopoverProps {
  title?: string;
  subtitle?: string | null;
  icon?: ReactNode;
  type?: DataWithIconProps['type'];
  uppy: Uppy<Meta, Body>;
  isUploading: boolean;
}

export function AttachmentCellPopover({
  uppy,
  isUploading,
}: AttachmentCellPopoverProps): ReactNode {
  const { formatMessage } = useIntl();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [attachmentToDelete, setAttachmentToDelete] = useState<string | null>(null);
  const transaction = cellContextManager.useCellContext();
  const { id: transactionId, automaticallyAddedAttachmentIds } = transaction;
  const { mutate: deleteAttachment, isPending: isDeleteAttachmentPending } =
    useUnlinkTransactionAttachment(transactionId);
  const abilities = useEmberService('abilities');
  const hasAutoAttachments =
    abilities.can('access email forward attachment') && automaticallyAddedAttachmentIds.length;

  return (
    <>
      <Popover className={styles['popover-container']}>
        <DisclaimerPopoverSection uppy={uppy} />
        <AttachmentListSection
          isUploading={isUploading}
          setAttachmentToDelete={setAttachmentToDelete}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          uppy={uppy}
        />
        {!hasAutoAttachments && <ActionsPopoverSection isUploading={isUploading} uppy={uppy} />}
      </Popover>

      <Popup
        cancelText={formatMessage({
          id: 'btn.cancel',
        })}
        confirmActionLoading={isDeleteAttachmentPending}
        confirmText={formatMessage({
          id: 'transactions.modals.delete-attachment.delete-attachment',
        })}
        isOpen={isDeleteModalOpen}
        onCancel={() => {
          setIsDeleteModalOpen(false);
        }}
        onConfirm={() => {
          if (attachmentToDelete) {
            deleteAttachment(attachmentToDelete, {
              onSuccess: () => {
                setIsDeleteModalOpen(false);
              },
            });
          }
        }}
        onOpenChange={setIsDeleteModalOpen}
        subtitle={formatMessage({
          id: 'transactions.modals.delete-attachment.description',
        })}
        title={formatMessage({
          id: 'transactions.modals.delete-attachment.title',
        })}
        type="destructive"
      />
    </>
  );
}
