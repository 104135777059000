import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function EuroOutlined(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_815_11283)">
        <path
          d="M10.53 10.845C10.31 11.16 10.02 11.41 9.68 11.585C9.305 11.785 8.865 11.89 8.38 11.89C7.975 11.89 7.605 11.82 7.275 11.68C6.95 11.545 6.67 11.35 6.435 11.11C6.2 10.87 6 10.56 5.84 10.19C5.705 9.875 5.595 9.475 5.525 9.005H4.67V8.25H5.455V7.625H4.67V6.87H5.56C5.65 6.425 5.775 6.04 5.935 5.74C6.115 5.39 6.33 5.09 6.575 4.855C6.82 4.615 7.11 4.43 7.425 4.305C7.75 4.175 8.095 4.11 8.455 4.11C8.94 4.11 9.355 4.2 9.69 4.385C9.99 4.55 10.255 4.77 10.465 5.035L10.07 5.47C10.07 5.47 10.045 5.5 9.975 5.55C9.975 5.55 9.96 5.565 9.905 5.565C9.89 5.565 9.87 5.56 9.86 5.555L9.725 5.445L9.545 5.3C9.475 5.24 9.395 5.19 9.305 5.14C9.22 5.1 9.11 5.06 8.965 5.02C8.845 4.985 8.695 4.97 8.51 4.97C8.04 4.97 7.64 5.135 7.32 5.455C7.005 5.765 6.78 6.235 6.65 6.845V6.87H9.555V7.29C9.555 7.375 9.52 7.45 9.455 7.52C9.38 7.59 9.29 7.625 9.18 7.625H6.56L6.55 7.815V8.135L6.56 8.25H9.05V8.665C9.05 8.75 9.02 8.83 8.955 8.895C8.88 8.965 8.79 9.005 8.68 9.005H6.625V9.025C6.735 9.69 6.945 10.19 7.25 10.515C7.56 10.855 7.965 11.025 8.445 11.025C8.72 11.025 8.955 10.985 9.14 10.905C9.315 10.835 9.46 10.745 9.57 10.645L9.825 10.4C9.855 10.37 9.9 10.335 9.94 10.335C9.94 10.335 9.96 10.335 9.99 10.345C9.99 10.345 10.005 10.355 10.035 10.385L10.53 10.845Z"
          fill="#636360"
        />
        <path
          d="M8 1C11.86 1 15 4.14 15 8C15 11.86 11.86 15 8 15C4.14 15 1 11.86 1 8C1 4.14 4.14 1 8 1ZM8 0C3.58 0 0 3.58 0 8C0 12.42 3.58 16 8 16C12.42 16 16 12.42 16 8C16 3.58 12.42 0 8 0Z"
          fill="#636360"
        />
      </g>
      <defs>
        <clipPath id="clip0_815_11283">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
