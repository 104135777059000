import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import type { ReactNode } from 'react';
import cx from 'clsx';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import styles from './discounted-card-pricing.strict-module.css';

interface DiscountedCardPricingProps {
  monthlyCost: number;
  discountedMonthlyCost: number;
  discountFinishDate: string;
}

export function DiscountedCardPricing({
  monthlyCost,
  discountedMonthlyCost,
  discountFinishDate,
}: DiscountedCardPricingProps): ReactNode {
  const { locale } = useIntl();

  return (
    <div>
      <p className="sr-only" data-test-card-pricing-discount-sr-description>
        {discountedMonthlyCost === 0 ? (
          <FormattedMessage
            id="cards.steps.choose-card.discount-included-description"
            values={{
              date: dateToken({ date: discountFinishDate, locale, token: 'date-year-l' }),
              amount: (
                <FormattedNumber
                  currency="EUR"
                  minimumFractionDigits={0}
                  style="currency"
                  value={monthlyCost}
                />
              ),
            }}
          />
        ) : (
          <FormattedMessage
            id="cards.steps.choose-card.discount-description"
            values={{
              discountedAmount: (
                <FormattedNumber
                  currency="EUR"
                  minimumFractionDigits={0}
                  style="currency"
                  value={discountedMonthlyCost}
                />
              ),
              date: dateToken({ date: discountFinishDate, locale, token: 'date-year-l' }),
              amount: (
                <FormattedNumber
                  currency="EUR"
                  minimumFractionDigits={0}
                  style="currency"
                  value={monthlyCost}
                />
              ),
            }}
          />
        )}
      </p>

      <p aria-hidden="true" className={styles.discount} data-test-card-pricing-discount>
        <span className={styles['discounted-price']}>
          <span className={cx('body-1', styles.stroke)}>
            <FormattedNumber
              currency="EUR"
              minimumFractionDigits={0}
              style="currency"
              value={monthlyCost}
            />
          </span>

          {discountedMonthlyCost === 0 ? (
            <span className={cx('title-4', styles.highlighted)}>
              <FormattedMessage id="cards.steps.choose-card.included" />
            </span>
          ) : (
            <span className={cx('body-1', styles.pricing)}>
              <FormattedMessage
                id="cards.steps.choose-card.pricing-with-period"
                values={{
                  amount: (
                    <span className={cx('title-4', styles.highlighted)}>
                      <FormattedNumber
                        currency="EUR"
                        minimumFractionDigits={0}
                        style="currency"
                        value={discountedMonthlyCost}
                      />
                    </span>
                  ),
                }}
              />
            </span>
          )}
        </span>

        <span className="caption">
          <FormattedMessage
            id="cards.steps.discount-date"
            values={{
              date: (
                <span className={styles.highlighted}>
                  {dateToken({ date: discountFinishDate, locale, token: 'date-year-s' })}
                </span>
              ),
            }}
          />
        </span>
      </p>
    </div>
  );
}
