import { useEffect, type ReactNode, useCallback } from 'react';
import { ActionScheduler } from '@repo/domain-kit/cashflow';
import { useSegment } from '@repo/poly-hooks/segment';
import dayjs from 'dayjs';
import type { UpcomingTransaction } from 'qonto/react/hooks/use-upcoming-transactions';
import { useUpcomingTransactionsContext } from '../upcoming-transactions-context';
import { useRescheduleTransfer } from './hooks/actions-handlers/use-reschedule-transfer';
import { useScheduleSupplierInvoice } from './hooks/actions-handlers/use-schedule-supplier-invoice';
import { useScheduleClientInvoice } from './hooks/actions-handlers/use-schedule-client-invoice-handler';

interface SchedulerProps {
  upcomingTransaction: UpcomingTransaction | null;
  updatedDateForPreviewLine: (
    date: string | null,
    side?: string | null,
    amount?: string | null,
    originalDate?: string | null
  ) => void;
}

export function Scheduler({
  upcomingTransaction,
  updatedDateForPreviewLine,
}: SchedulerProps): ReactNode {
  const segment = useSegment();
  const { setScheduledTransactionPreview, setActiveScheduledTransactionId } =
    useUpcomingTransactionsContext();
  const { updateTransfer } = useRescheduleTransfer();
  const { updateSupplierInvoice } = useScheduleSupplierInvoice();
  const { updateClientInvoice } = useScheduleClientInvoice();

  const type = upcomingTransaction?.provider_object_type;
  const transactionId = upcomingTransaction?.provider_object_id ?? '';
  const counterpartyName = upcomingTransaction?.counterparty_name ?? '';
  const dueDate = upcomingTransaction?.expected_date ?? upcomingTransaction?.due_date;

  const isOverdueTransaction = dayjs(dueDate).isBefore(dayjs().startOf('day'));

  const date =
    type === 'receivable_invoice' || isOverdueTransaction
      ? dayjs().add(1, 'day').format('YYYY-MM-DD')
      : dayjs(dueDate).format('YYYY-MM-DD');

  const isSameDate = dayjs(date).isSame(dayjs(dueDate), 'day');

  const handleDateChange = (newDate: string | null): void => {
    const isNewDateSameDate = dayjs(newDate).isSame(dayjs(dueDate), 'day');

    updatedDateForPreviewLine(newDate?.toString() ?? null);
    setScheduledTransactionPreview({
      newDate: newDate ?? '',
      amount: isNewDateSameDate ? '0' : (upcomingTransaction?.amount.value ?? ''),
      type: upcomingTransaction?.side === 'credit' ? 'inflows' : 'outflows',
      counterparty_name: upcomingTransaction?.counterparty_name,
      metadata: {
        logo: upcomingTransaction?.metadata?.logo_url,
      },
    });
  };

  const handleSubmit = (newDate: string | null): void => {
    updatedDateForPreviewLine(null);
    setActiveScheduledTransactionId(null);
    setScheduledTransactionPreview(null);

    if (!upcomingTransaction || !newDate) return;

    if (type === 'transfer') {
      void updateTransfer({ type, transferId: transactionId, newDate, counterpartyName });
    }

    if (type === 'supplier_invoice') {
      updateSupplierInvoice({
        type,
        supplierInvoiceId: transactionId,
        newDate,
        counterpartyName,
      });
    }

    if (type === 'receivable_invoice') {
      updateClientInvoice({
        type,
        upcomingTransactionId: upcomingTransaction.id,
        newDate,
        counterpartyName,
      });
    }
  };

  const handleCancel = (): void => {
    updatedDateForPreviewLine(null);
    segment.track('compass_action_clicked', {
      action: 'reschedule_action_cancel',
      type,
    });
    setScheduledTransactionPreview(null);
    setActiveScheduledTransactionId(null);
  };

  const showInitialState = useCallback((): void => {
    updatedDateForPreviewLine(date);
    setScheduledTransactionPreview({
      newDate: date,
      amount: isSameDate ? '0' : (upcomingTransaction?.amount.value ?? ''),
      type: upcomingTransaction?.side === 'credit' ? 'inflows' : 'outflows',
      counterparty_name: upcomingTransaction?.counterparty_name,
      metadata: {
        logo: upcomingTransaction?.metadata?.logo_url,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- adding updatedDateForPreviewLine triggers unwanted re-renders
  }, [date, upcomingTransaction, setScheduledTransactionPreview]);

  useEffect(() => {
    showInitialState();
  }, [showInitialState]);

  return (
    <ActionScheduler
      date={date}
      onCancel={handleCancel}
      onDateChange={handleDateChange}
      onSubmit={handleSubmit}
    />
  );
}
