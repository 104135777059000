import { type ReactNode } from 'react';
import type { CardLevel } from '@repo/cards-kit/constants';
import cx from 'clsx';
import type { ChooseCardLayoutProps } from 'qonto/react/cards/components/flows/choose-card-layout/internal/choose-card-layout-props.interface';
import styles from './styles.strict-module.css';
import { Asset, type CoreAssetProps } from './internal/asset';
import { Action, type ActionProps } from './internal/action';
import { Features, type Feature } from './internal/features';
import { Header, type CoreHeaderProps } from './internal/header';

export interface ChooseCardProps {
  assetProps?: CoreAssetProps & {
    handleMouseEnter?: () => Promise<void>;
    handleMouseLeave?: () => Promise<void>;
  };
  cardLevel: CardLevel;
  className?: string;
  ctaProps: ActionProps;
  features: Feature[];
  headerProps: CoreHeaderProps;
  layoutProps?: ChooseCardLayoutProps;
}

export function ChooseCard({
  assetProps,
  cardLevel,
  className,
  ctaProps,
  features,
  headerProps,
  layoutProps,
  ...props
}: ChooseCardProps): ReactNode {
  return (
    <li className={styles.card} data-test-choose-card={cardLevel} {...props}>
      <Asset
        cardLevel={cardLevel}
        className={cx(assetProps?.className, styles.asset)}
        data-test-card-asset={cardLevel}
        id={assetProps?.id}
        isImageOnly={assetProps?.isImageOnly}
        isVideoPlayingOnError={assetProps?.isVideoPlayingOnError}
        setIsLoaded={assetProps?.setIsLoaded}
        shouldAnimateCards={assetProps?.shouldAnimateCards}
      />

      <div
        className={cx(styles.container, className)}
        onMouseEnter={assetProps?.handleMouseEnter}
        onMouseLeave={assetProps?.handleMouseLeave}
      >
        <Header
          badge={headerProps.badge}
          cardEstimates={headerProps.cardEstimates}
          cardLevel={cardLevel}
          data-test-choose-card-header={cardLevel}
          layoutHeaderProps={layoutProps?.header}
          layoutSubtitleProps={layoutProps?.subtitle}
          optionPrice={headerProps.optionPrice}
          shouldHidePricing={headerProps.shouldHidePricing}
          subtitle={headerProps.subtitle}
        />

        <Action
          data-test-card-selector-button
          data-test-choose-card-cta={cardLevel}
          handleClick={ctaProps.handleClick}
          text={ctaProps.text}
        />

        <Features
          data-test-card-selector-features
          data-test-choose-card-features={cardLevel}
          features={features}
          layoutFeaturesProps={layoutProps?.features}
        />
      </div>
    </li>
  );
}
