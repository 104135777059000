import { type ReactNode, useRef, useState } from 'react';
import { Button, Collection, Menu, MenuItem, MenuTrigger, Popover } from 'react-aria-components';
import cx from 'clsx';
import { IconChevronBottomOutlined } from '@repo/monochrome-icons';
import styles from './forecast-entry-variance-dropdown.strict-module.css';

export type ForecastEntryVarianceDropdownValue = 'amount' | 'percentage';

interface ForecastEntryVarianceDropdownProps {
  value: ForecastEntryVarianceDropdownValue;
  onSelect: (value: ForecastEntryVarianceDropdownValue) => void;
  currencyCode?: string;
}

interface ForecastEntryVarianceDropdownItem {
  label: string;
  value: ForecastEntryVarianceDropdownValue;
}

export function ForecastEntryVarianceDropdown({
  value,
  onSelect,
  currencyCode = 'EUR',
}: ForecastEntryVarianceDropdownProps): ReactNode {
  const triggerButtonRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const items: ForecastEntryVarianceDropdownItem[] = [
    { label: '%', value: 'percentage' },
    { label: currencyCode, value: 'amount' },
  ];

  return (
    <div className={cx('body-1', styles.container)} data-testid="forecast-entry-variance-dropdown">
      <MenuTrigger onOpenChange={setIsOpen}>
        <Button
          className={cx(styles.triggerButton, isOpen && styles.triggerButtonOpen)}
          data-testid="forecast-entry-variance-dropdown-trigger"
          ref={triggerButtonRef}
        >
          <div className={styles.buttonContent}>
            {items.find(item => item.value === value)?.label}
          </div>
          <IconChevronBottomOutlined className={styles.triggerButtonIcon} />
        </Button>

        <Popover
          className={cx('body-2', styles.popover)}
          offset={4}
          placement="bottom end"
          triggerRef={triggerButtonRef}
        >
          <Menu className={styles.menu}>
            <Collection items={items}>
              {item => (
                <MenuItem
                  className={styles.menuItem}
                  data-testid={`forecast-entry-variance-dropdown-item-${item.value}`}
                  id={item.value}
                  key={item.value}
                  onAction={() => {
                    onSelect(item.value);
                    setIsOpen(false);
                  }}
                >
                  {item.label}
                </MenuItem>
              )}
            </Collection>
          </Menu>
        </Popover>
      </MenuTrigger>
    </div>
  );
}
