import { type ReactNode } from 'react';
import React from 'react';
import { DropdownSelector } from '@repo/design-system-kit';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';

const periodSelectorItems = [
  {
    key: '30',
    labelId: 'cash-flow.upcoming-transactions.header.30-days',
    dropdownId: 'cash-flow.upcoming-transactions.header.dropdown.30-days',
  },
  {
    key: '90',
    labelId: 'cash-flow.upcoming-transactions.header.90-days',
    dropdownId: 'cash-flow.upcoming-transactions.header.dropdown.90-days',
  },
];

export function PeriodSelector({
  onSelectionChange,
  selectedKey,
}: {
  onSelectionChange: (key: string) => void;
  selectedKey: string;
}): ReactNode {
  const segment = useEmberService('segment');
  const handleOpenChange = (isOpen: boolean): void => {
    if (isOpen) {
      segment.track('compass_period-switcher_opened');
    }
  };
  const handleSelectionChange = (key: string): void => {
    segment.track('compass_period_selected', { period: `${key}_days` });
    onSelectionChange(key);
  };

  return (
    <DropdownSelector
      items={periodSelectorItems}
      onOpenChange={handleOpenChange}
      onSelectionChange={handleSelectionChange}
      selectedKey={selectedKey}
    />
  );
}
