import React, { type ReactNode } from 'react';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './status-box.strict-module.css';

interface StatusBoxProps {
  icon: string;
  status?: string;
  statusIcon?: string;
  children?: ReactNode;
}

export function StatusBox({
  icon,
  status,
  statusIcon,
  children,
  ...props
}: StatusBoxProps): ReactNode {
  return (
    <div className={styles.container} {...props}>
      <div className={styles.picto} data-test-f24-picto-icon={icon}>
        <StaticThemedAsset alt="" assetPath={`/icon/category/${icon}.svg`} />
        {status === 'completed' && (
          <div className={styles['picto-status']} data-test-f24-status-icon={statusIcon}>
            <StaticThemedAsset alt="" assetPath={`/icon/status/${statusIcon}.svg`} />
          </div>
        )}
      </div>
      {children}
    </div>
  );
}
