import { SkeletonLoader } from '@repo/design-system-kit';
import type { HTMLAttributes, ReactElement } from 'react';
import cx from 'clsx';
import styles from './styles.strict-module.css';

export function TransactionPlaceholder({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>): ReactElement {
  return (
    <div
      className={cx(styles.transactionPlaceholder, className)}
      data-test-modal-transaction-placeholder=""
      {...props}
    >
      <div>
        <div className={styles.transactionPlaceholderLine}>
          <SkeletonLoader.Line data-test-transaction-placeholder-line="" standalone width="160px" />
        </div>
        <div className={styles.transactionPlaceholderLine}>
          <SkeletonLoader.Line data-test-transaction-placeholder-line="" standalone width="240px" />
        </div>
        <div className={styles.transactionPlaceholderLine}>
          <SkeletonLoader.Line data-test-transaction-placeholder-line="" standalone width="80px" />
        </div>
      </div>

      <div className={styles.transactionPlaceholderLine}>
        <SkeletonLoader.Line data-test-transaction-placeholder-line="" standalone width="80px" />
      </div>
    </div>
  );
}
