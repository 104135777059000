import React, { type HTMLAttributes } from 'react';
import { type AvatarSize } from '@repo/design-system-kit';
import { StatusAvatar } from 'qonto/react/components/avatar/status-avatar';
import type { CashflowTransactionCategory } from 'qonto/react/models/cash-flow-category';
import type { ICON_PATHS } from 'qonto/react/components/avatar/internal/icon-map';
import styles from './styles.strict-module.css';

interface PictoProps extends HTMLAttributes<HTMLDivElement> {
  avatar?: string;
  hasBorder?: boolean;
  icon?: React.ReactElement;
  categoryAssignment: CashflowTransactionCategory | null;
  iconName?: keyof typeof ICON_PATHS;
  size?: AvatarSize;
}

export function Picto({
  avatar,
  hasBorder,
  iconName,
  categoryAssignment,
  size = '64',
  ...props
}: PictoProps): React.ReactElement {
  return (
    <div className={styles.wrapper} {...props}>
      <StatusAvatar
        categoryAssignment={categoryAssignment}
        data-test-picto-avatar
        hasBorder={hasBorder}
        iconName={iconName}
        size={size}
        src={avatar}
      />
    </div>
  );
}
