import { type ChangeEvent, type FocusEvent, type MouseEvent, type ReactNode } from 'react';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import cx from 'clsx';
import { Input } from 'react-aria-components';
import styles from './styles.strict-module.css';

interface ItemSearchTriggerProps {
  defaultValue: string;
  placeholder: string;
  select?: { actions: { open: () => void }; options: { id: string }[] };
  disabled: boolean;
  maxLength: number;
  error: string;
  onChange?: (value: string) => void;
  onBlur: () => void;
}

export function ItemSearchTrigger({
  defaultValue = '',
  placeholder,
  select,
  disabled,
  maxLength,
  error,
  onChange,
  onBlur,
}: ItemSearchTriggerProps): ReactNode {
  const segment = useEmberService('segment');

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    onChange?.(event.target.value);
  };

  const handleFocus = (): void => {
    select?.actions.open();
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>): void => {
    const optionsArray = select?.options;
    if (
      Boolean(event.relatedTarget) &&
      optionsArray?.find(option => option.id === event.relatedTarget?.id)
    ) {
      return;
    }
    segment.track('invoice_item-title_filled');

    event.currentTarget.blur();
    onBlur();
  };

  const handleMouseDown = (event: MouseEvent<HTMLInputElement>): void => {
    event.stopPropagation();
    select?.actions.open();
  };

  return (
    <Input
      className={`input-field ${cx(styles['search-input'], error && styles['search-input--error'])}`}
      data-test-item-search-trigger
      data-test-use-ember-fill-in
      disabled={disabled}
      maxLength={maxLength}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onInput={handleChange}
      onMouseDown={handleMouseDown}
      placeholder={placeholder}
      value={defaultValue}
    />
  );
}
