/* eslint-disable @qonto/use-composable-popup -- Temporarily disabled the rule until migrating the popup */
import type { ReactNode } from 'react';
import { Popup } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';

type CashflowMembers = 'category' | 'subcategory';

interface ConfirmDeletionPopupProps {
  isOpen: boolean;
  onPopupCancel: () => void;
  onPopupConfirm: () => void;
  type: CashflowMembers;
}

export function ConfirmDeletionPopup({
  isOpen,
  onPopupCancel,
  onPopupConfirm,
  type,
}: ConfirmDeletionPopupProps): ReactNode {
  const { formatMessage } = useIntl();

  const cancelText =
    type === 'subcategory'
      ? formatMessage({ id: 'categories-management.delete-subcategory.modal.cancel.cta' })
      : formatMessage({ id: 'categories-management.delete-category.modal.cancel.cta' });
  const confirmText =
    type === 'subcategory'
      ? formatMessage({ id: 'categories-management.delete-subcategory.modal.confirm.cta' })
      : formatMessage({ id: 'categories-management.delete-category.modal.confirm.cta' });
  const content =
    type === 'subcategory'
      ? formatMessage({ id: 'categories-management.delete-subcategory.modal.subtitle' })
      : formatMessage({ id: 'categories-management.delete-category.modal.subtitle' });
  const title =
    type === 'subcategory'
      ? formatMessage({ id: 'categories-management.delete-subcategory.modal.title' })
      : formatMessage({ id: 'categories-management.delete-category.modal.title' });

  return (
    <Popup
      cancelText={cancelText}
      confirmText={confirmText}
      data-test-id={`confirm-${type}-deletion-modal`}
      isOpen={isOpen}
      onCancel={onPopupCancel}
      onConfirm={onPopupConfirm}
      title={title}
      type="destructive"
    >
      <section className="body-1">
        <p data-test-id={`confirm-${type}-deletion-modal-subtitle`}>{content}</p>
      </section>
    </Popup>
  );
}
