/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import { Button, Popup } from '@repo/design-system-kit';
import type { ReactElement } from 'react';
import type { PopupRootProps } from '@repo/design-system-kit';

interface PopupConfirmationProps extends PopupRootProps {
  title: string;
  subtitle?: string;
  confirmText: string;
  cancelText: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export function PopupConfirmation({
  title,
  subtitle,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  ...props
}: PopupConfirmationProps): ReactElement {
  return (
    <Popup.Root {...props}>
      <Popup.CloseButton onPress={onCancel} />
      <Popup.Header>
        <Popup.Title>{title}</Popup.Title>
        {Boolean(subtitle) && <Popup.Subtitle>{subtitle}</Popup.Subtitle>}
      </Popup.Header>
      <Popup.Footer>
        <Button
          aria-label="Cancel"
          data-test-id="modal-cancel-button"
          onPress={onCancel}
          variant="secondary"
        >
          {cancelText}
        </Button>
        <Button
          aria-label="Confirm"
          data-test-id="modal-confirm-button"
          onPress={onConfirm}
          variant="primary"
        >
          {confirmText}
        </Button>
      </Popup.Footer>
    </Popup.Root>
  );
}
