import React from 'react';
import { IconChevronBottomOutlined } from '@repo/monochrome-icons';
import { useIntl } from 'react-intl';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './activity-tag.strict-module.css';

export function F24ActivityTag(props: React.HTMLAttributes<HTMLDivElement>): React.ReactElement {
  const { formatMessage } = useIntl();
  return (
    <div className={styles['activity-tag-container']} {...props}>
      <StaticThemedAsset alt="" assetPath="/icon/category/tax-xs.svg" className={styles.icon} />
      <span className={styles.label} data-test-activity-tag-option="tax">
        {formatMessage({ id: 'activities.tax' })}
      </span>

      <IconChevronBottomOutlined />
    </div>
  );
}
