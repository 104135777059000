import { type ReactNode } from 'react';
import { F24_STATUS } from 'qonto/constants/f24';
import { F24SidebarFooter } from 'qonto/react/f24/components/sidebar/footer';
import { F24SidebarContainerHeader } from 'qonto/react/f24/components/sidebar/container/sidebar-header';
import type { F24Order } from 'qonto/react/f24/types';
import styles from './sidebar.strict-module.css';

interface F24SidebarProps {
  order: F24Order;
  closeSidebar: () => void;
}

export function F24Sidebar({ order, closeSidebar }: F24SidebarProps): ReactNode {
  const shouldDisplayFooter =
    (order.status === F24_STATUS.COMPLETED && order.receiptUrl) ||
    (order.status === F24_STATUS.SCHEDULED && order.isCancelable);

  return (
    <aside
      className={styles['sidebar-content']}
      data-test-f24-sidebar-container
      data-test-f24-sidebar-order={order.id}
    >
      <div className="scroll">
        <header className={styles.header} data-test-f24-sidebar-container-header>
          <F24SidebarContainerHeader closeSidebar={closeSidebar} order={order} />
        </header>
      </div>
      {shouldDisplayFooter ? (
        <footer className={styles.footer} data-test-f24-sidebar-container-footer>
          <F24SidebarFooter closeSidebar={closeSidebar} order={order} />
        </footer>
      ) : null}
    </aside>
  );
}
