import { useState, useCallback, type FormEvent, type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Button, DatePicker } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { parseDate, type CalendarDate } from '@internationalized/date';
import { IconChevronLeftOutlined } from '@repo/monochrome-icons';
import type { DateValue } from 'react-aria-components';
import styles from './custom-period.strict-module.css';

interface CustomPeriodProps {
  startDate?: string | null;
  endDate?: string | null;
  maxDate?: string | null;
  handleBack: () => void;
  onCancel: () => void;
  onUpdate: (dates: { startDate: string; endDate: string }) => void;
}

const toDateValue = (date: CalendarDate | null): DateValue | null => {
  // CalendarDate and DateValue are compatible but because they are imported from different packages ts doesn't recognize it
  return date as unknown as DateValue;
};

const parseDateToValue = (dateString: string | null | undefined): DateValue | null => {
  if (!dateString) return null;
  return toDateValue(parseDate(dateString));
};

export function CustomPeriod({
  startDate: initialStartDate,
  endDate: initialEndDate,
  maxDate: maxDateProp,
  handleBack,
  onCancel,
  onUpdate,
}: CustomPeriodProps): ReactNode {
  const intl = useIntl();
  const [startDate, setStartDate] = useState<DateValue | null>(parseDateToValue(initialStartDate));
  const [endDate, setEndDate] = useState<DateValue | null>(parseDateToValue(initialEndDate));

  const maxDate: DateValue | null | undefined = maxDateProp
    ? parseDateToValue(dayjs(maxDateProp).format('YYYY-MM-DD'))
    : null;

  const updateStartDate = useCallback(
    (newValue: DateValue | null): void => {
      const newStartDate = newValue ? parseDate(newValue.toString()) : null;
      setStartDate(toDateValue(newStartDate));

      // Adjust end date if necessary
      if (newStartDate && endDate && !dayjs(newStartDate.toString()).isBefore(endDate.toString())) {
        const newEndDate = dayjs(newStartDate.toString()).add(1, 'day').format('YYYY-MM-DD');
        setEndDate(toDateValue(parseDate(newEndDate)));
      }
    },
    [endDate]
  );

  const updateEndDate = useCallback((newValue: DateValue | null): void => {
    setEndDate(newValue ? toDateValue(parseDate(newValue.toString())) : null);
  }, []);

  const handleSubmit = useCallback(
    (e: FormEvent): void => {
      e.preventDefault();
      if (startDate && endDate) {
        onUpdate({
          startDate: startDate.toString(),
          endDate: endDate.toString(),
        });
      }
    },
    [startDate, endDate, onUpdate]
  );

  const canSubmit =
    startDate &&
    endDate &&
    !(startDate.toString() === initialStartDate && endDate.toString() === initialEndDate);

  const minEndDate: DateValue | null = startDate
    ? (parseDate(
        dayjs(startDate.toString()).add(1, 'day').format('YYYY-MM-DD')
      ) as unknown as DateValue)
    : null;

  return (
    <div className={styles['custom-period']} data-test-custom-period="">
      <div className={styles.header}>
        <button
          className={styles['back-button']}
          data-test-custom-period-back-button
          onClick={handleBack}
          type="button"
        >
          <IconChevronLeftOutlined className={styles['back-button-icon']} />

          {intl.formatMessage({ id: 'bookkeeping.transactions.filters.option.period.custom.back' })}
        </button>
      </div>

      <form onSubmit={handleSubmit}>
        <div className={styles['form-inputs']}>
          <DatePicker
            data-test-custom-period-start-date=""
            label={intl.formatMessage({
              id: 'bookkeeping.transactions.filters.option.period.custom.from',
            })}
            maxValue={maxDate}
            onChange={updateStartDate}
            portalContainerId="custom-period-selector-dropdown"
            value={startDate}
          />
          <DatePicker
            data-test-custom-period-end-date=""
            label={intl.formatMessage({
              id: 'bookkeeping.transactions.filters.option.period.custom.to',
            })}
            maxValue={maxDate}
            minValue={minEndDate}
            onChange={updateEndDate}
            portalContainerId="custom-period-selector-dropdown"
            value={endDate}
          />
        </div>

        <div className={styles['form-actions']}>
          <Button data-test-custom-period-cancel-button onPress={onCancel} variant="secondary">
            {intl.formatMessage({
              id: 'bookkeeping.transactions.filters.option.period.custom.cancel',
            })}
          </Button>
          <Button
            data-test-custom-period-submit-button
            isDisabled={!canSubmit}
            type="submit"
            variant="primary"
          >
            {intl.formatMessage({
              id: 'bookkeeping.transactions.filters.option.period.custom.apply',
            })}
          </Button>
        </div>
      </form>
    </div>
  );
}
