import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from '@qonto/react-migration-toolkit/react/components';
import { useSegment } from '@repo/poly-hooks/segment';
import type { BaseWidgetInvoicesProps } from './widget-invoices';
import styles from './styles.strict-module.css';

interface InvoiceCTAButtonProps {
  invoiceType: BaseWidgetInvoicesProps['invoiceType'];
  invoicingActivationStatus: BaseWidgetInvoicesProps['invoicingActivationStatus'];
  organizationSlug: string;
}

export function WidgetInvoicesCtaBtn({
  invoiceType,
  invoicingActivationStatus,
  organizationSlug,
}: InvoiceCTAButtonProps): ReactNode {
  const segment = useSegment();

  const handleInvoiceCTAClick: () => void = () => {
    if (invoiceType === 'receivable') {
      segment.track('overview_widget-client-invoices_create-invoice_clicked');
    } else {
      segment.track('overview_widget-supplier-invoices_create-invoice_clicked');
    }
  };

  const to =
    invoiceType === 'receivable'
      ? `/organizations/${organizationSlug}/receivable-invoices/new?origin=overview`
      : `/organizations/${organizationSlug}/supplier-invoices/list?openInvoicesModal=true`;
  return (
    <Link
      className={`btn btn--secondary btn--small ${styles['cta-btn']}`}
      data-test-cta-btn
      onClick={handleInvoiceCTAClick}
      to={to}
    >
      {invoicingActivationStatus === 'preactivated' ? (
        <FormattedMessage
          id={
            invoiceType === 'receivable'
              ? 'qonto-invoicing.overview.widgets.client-invoice.empty-state.activation.create-invoice-cta'
              : 'qonto-invoicing.overview.widgets.supplier-invoice.empty-state.activation.import-invoices-cta'
          }
        />
      ) : (
        <FormattedMessage
          id={
            invoiceType === 'receivable'
              ? 'qonto-invoicing.overview.widgets.client-invoice.empty-state.no-upcoming-payments.create-invoice-cta'
              : 'qonto-invoicing.overview.widgets.supplier-invoice.empty-state.no-upcoming-payments.import-invoices-cta'
          }
        />
      )}
    </Link>
  );
}
