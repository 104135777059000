import { type ReactElement, type CSSProperties } from 'react';
import cx from 'clsx';
import {
  Button,
  Input,
  Label,
  ListBox,
  Popover,
  Select,
  SelectValue,
  type SelectProps,
} from 'react-aria-components';
import { IconChevronBottomOutlined } from '@repo/monochrome-icons';
import { SearchOutlined } from 'qonto/react/assets/icons';
import styles from './styles.strict-module.css';

interface DropdownProps<T extends object> extends Omit<SelectProps<T>, 'children'> {
  items: Iterable<T>;
  children: React.ReactNode | ((item: T) => React.ReactNode);
  filter?: (value: string) => void;
  label: string;
  popoverStyles?: CSSProperties;
}

export function Dropdown<T extends object>({
  children,
  items,
  filter,
  label,
  popoverStyles,
  ...props
}: DropdownProps<T>): ReactElement {
  const ppvStyles = {
    width: '360px',
    height: '230px',
    overflow: 'auto',
    'background-color': 'var(--elevation-high)',
    'border-radius': '4px',
    'box-shadow': 'var(--shadow-high)',
    ...popoverStyles,
  };

  return (
    <Select className={styles.select} {...props} data-testid="dropdown">
      <Label className={styles.title} data-testid="title">
        {label}
      </Label>
      <Button className={cx('body-1', 'overlay', styles.trigger)} data-testid="trigger">
        <SelectValue />
        <IconChevronBottomOutlined aria-hidden="true" className={styles['arrow-icon']} />
      </Button>
      <Popover style={{ ...ppvStyles }}>
        {filter ? (
          <form className={styles.search} role="search">
            <SearchOutlined aria-hidden="true" />
            <Input
              className={styles.input}
              data-testid="search-input"
              onChange={e => {
                filter(e.target.value);
              }}
              placeholder="Search"
            />
          </form>
        ) : null}
        <ListBox items={items}>{children}</ListBox>
      </Popover>
    </Select>
  );
}
