import { useState, type ReactNode } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { createPortal } from 'react-dom';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { CashflowCategorySelector } from 'qonto/react/components/transactions/sidebar/category/cashflow-category/components/category-selector';
import type { CashflowCategories } from 'qonto/react/models/cash-flow-category';
import { useAssignTransactionsCategory } from 'qonto/react/hooks/use-assign-transactions-category';
import { CashFlowCategoryMemorizeWidget } from 'qonto/react/components/transactions/sidebar/category/cashflow-category/components/memorize-widget';
import type TransactionModel from 'qonto/models/transaction';
import type { MemorizeCategoriesRule } from 'qonto/react/models/memorize-category';
import { useCreateCategorizationRules } from 'qonto/react/hooks/use-create-categorization-rules';
import { useCashflowCategories } from 'qonto/react/hooks/use-cashflow-categories';
import type { CategoriesTableRow } from 'qonto/react/components/cash-flow/models/categories-table-display';
import styles from './category-cell.strict-module.css';

interface CategoryCellProps {
  activeCategoryId: string | null;
  categories: CashflowCategories | undefined;
  selectedCategories: CategoriesTableRow[];
  popoverPortalContainer?: Element;
  transactionId: string;
  transactionDetails: {
    counterpartyName: TransactionModel['counterpartyName'];
    rawCounterpartyName: TransactionModel['rawCounterpartyName'];
  };
  rowIndex: number;
  refreshChart: () => void;
}

export function CategoryCell({
  activeCategoryId,
  categories,
  selectedCategories: selectedCellCategories,
  transactionId,
  transactionDetails,
  rowIndex,
  refreshChart,
}: CategoryCellProps): ReactNode {
  const queryClient = useQueryClient();
  const sentry = useEmberService('sentry');
  const { mutate: assignTransactionsCategory } = useAssignTransactionsCategory();
  const { mutate: createCategorizationRules } = useCreateCategorizationRules();
  const { getCategoryById } = useCashflowCategories();

  const [showMemorizeWidget, setShowMemorizeWidget] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(activeCategoryId);

  const handleSelectionChange = (newCategoryId: string | null): void => {
    const newCategory = getCategoryById(newCategoryId ?? '');
    const selectedCellFlowSide = selectedCellCategories[0]?.flowType.slice(0, -1); // TODO: reconcile CategorySide types
    const hasChangedFlowType = selectedCellFlowSide !== newCategory?.side;

    setSelectedCategory(newCategoryId);
    assignTransactionsCategory(
      {
        transactionIds: [transactionId],
        categoryId: newCategoryId,
      },
      {
        onSuccess: () => {
          setShowMemorizeWidget(true);
          setTimeout(() => {
            queryClient
              .refetchQueries({ queryKey: ['cashflow-timeseries'], exact: false })
              .then(() => {
                if (hasChangedFlowType) {
                  refreshChart();
                }
              })
              .catch(error => {
                sentry.captureException(error);
              });
          }, 1000);
        },
      }
    );
  };

  const onCloseMemorizeWidget = (): void => {
    setShowMemorizeWidget(false);
  };

  const onGenerateRules = (rules: MemorizeCategoriesRule[]): void => {
    setShowMemorizeWidget(false);
    createCategorizationRules(rules);
  };

  const widgetNode = document.querySelector(`[data-row-memorize-widget="${rowIndex}"] > td`);

  return (
    <div className={styles['category-cell']} data-testid="category-cell">
      <CashflowCategorySelector
        activeCategoryId={selectedCategory}
        categories={categories}
        isIntegratedInSidepanel
        onSelectionChange={handleSelectionChange}
        popoverWidth="352px"
        showCategoriesMenu={false}
        showLabel={false}
      />
      {widgetNode && showMemorizeWidget
        ? createPortal(
            <CashFlowCategoryMemorizeWidget
              categoryId={selectedCategory}
              isIntegratedInSidepanel
              onClose={onCloseMemorizeWidget}
              onGenerateRules={onGenerateRules}
              transactions={[
                {
                  counterpartyName: transactionDetails.counterpartyName,
                  rawCounterpartyName: transactionDetails.rawCounterpartyName,
                },
              ]}
            />,
            widgetNode,
            rowIndex
          )
        : null}
    </div>
  );
}
