/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import { type ReactNode } from 'react';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './item.strict-module.css';

interface QualityItemProps {
  domain: string;
  dataFlow: 'in' | 'out';
  qualities: string[];
}

export function QualityItem({ domain, dataFlow, qualities }: QualityItemProps): ReactNode {
  const { formatMessage } = useIntl();

  const dataFlowIcon =
    dataFlow === 'in'
      ? '/icon/in-context-integrations/icon-download-outlined.svg'
      : '/icon/in-context-integrations/icon-upload-outlined.svg';
  const dataFlowText = formatMessage({
    id: `in-context-integrations.side-drawer.integration.accordion.data-flow.${
      dataFlow === 'in' ? 'import' : 'export'
    }`,
  });

  return (
    <>
      <StaticThemedAsset
        assetPath={dataFlowIcon}
        className={styles['dataflow-icon']}
        data-test-data-flow-icon={dataFlow}
      />
      <div className={styles['text-container']}>
        <span className={cx('caption', styles.domain)} data-test-domain>
          {domain}
        </span>
        <div className={cx('caption', styles.qualities)} data-test-qualities>
          <span data-test-data-flow-text>{dataFlowText}</span>
          {qualities.map((quality, index) => (
            <span key={quality}>
              {quality}
              {index < qualities.length - 1 ? ', ' : ''}
            </span>
          ))}
        </div>
      </div>
    </>
  );
}
