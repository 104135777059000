import { useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useSegment } from '@repo/poly-hooks/segment';
import type { UpcomingTransaction } from 'qonto/react/hooks/use-upcoming-transactions';
import { PopupDestructive } from 'qonto/react/components/popups/destructive';
import { useDeleteUpcomingTransaction } from 'qonto/react/components/cash-flow/hooks/use-delete-upcoming-transaction';
import { getDestructiveModalProps } from '../../utils/get-destructive-modal-props';

export const useDeleteHandler = (
  upcomingTransaction: UpcomingTransaction | null
): (() => Promise<void>) => {
  const reactPopup = useEmberService('reactPopup');
  const { formatMessage } = useIntl();
  const segment = useSegment();
  const deleteUpcomingTransaction = useDeleteUpcomingTransaction();
  const type = upcomingTransaction?.provider_object_type;

  return async (): Promise<void> => {
    segment.track('compass_action_clicked', {
      action: 'remove',
      type,
    });

    if (!upcomingTransaction) {
      return;
    }
    const { titleId, subtitleId, confirmTextId, cancelTextId } =
      getDestructiveModalProps(upcomingTransaction);
    const isRecurring = upcomingTransaction.metadata?.recurrence !== undefined;

    const result = await reactPopup.open(PopupDestructive, {
      title: formatMessage({ id: titleId }),
      subtitle: formatMessage({ id: subtitleId }),
      confirmText: formatMessage({ id: confirmTextId }),
      cancelText: formatMessage({ id: cancelTextId }),
    });

    if (isRecurring) {
      if (result === 'cancel') {
        deleteUpcomingTransaction.mutate({
          upcomingTransactionId: upcomingTransaction.id,
          cascade: false,
        });
        return;
      }
      if (result === 'confirm') {
        deleteUpcomingTransaction.mutate({
          upcomingTransactionId: upcomingTransaction.id,
          cascade: true,
        });
        return;
      }
    }
    if (!isRecurring) {
      if (result === 'confirm') {
        deleteUpcomingTransaction.mutate({
          upcomingTransactionId: upcomingTransaction.id,
          cascade: true,
        });
      }
    }
  };
};
