import type { ReactElement, ReactNode } from 'react';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import { IconCrossOutlined } from '@repo/monochrome-icons';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { InfoList } from '@repo/domain-kit/shared';
import type { Check } from 'qonto/react/models/check';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import { StatusAvatar } from 'qonto/react/components/avatar/status-avatar';
import { IconStatusProcessing } from 'qonto/react/assets/icons/status/status-processing';
import { IconStatusApproved } from 'qonto/react/assets/icons/status/status-approved';
import { IconStatusCancelled } from 'qonto/react/assets/icons/status/status-cancelled';
import { formatMoney } from 'qonto/helpers/format/money';
import cmc7Splitter from 'qonto/utils/cmc7-splitter';
import styles from './styles.strict-module.css';

interface CheckSidebarHeaderProps {
  check: Check;
  sidebarCloseId: string;
  closeSidebar: () => void;
}

const getStatusIcon = (iconName: string): ReactElement => {
  switch (iconName) {
    case 'status_processing':
      return <IconStatusProcessing data-test-status-avatar-icon={iconName} />;
    case 'status_approved':
      return <IconStatusApproved data-test-status-avatar-icon={iconName} />;
    case 'status_cancelled':
    default:
      return <IconStatusCancelled data-test-status-avatar-icon={iconName} />;
  }
};

export function CheckSidebarHeader({
  check,
  sidebarCloseId,
  closeSidebar,
}: CheckSidebarHeaderProps): ReactNode {
  const { formatMessage, locale } = useIntl();
  const emberIntl = useEmberService('intl');

  const onClickCloseSidebar = (): void => {
    closeSidebar();
    const checkRowElement = document.querySelector(`[data-item-id="${check.id}"]`);
    if (checkRowElement instanceof HTMLElement) {
      checkRowElement.focus();
    }
  };

  const showAccountName =
    check.bankAccount?.name && check.bankAccount.organization.hasMultipleBankAccounts;

  const checkCmc7 = cmc7Splitter(check.cmc7, ' ');

  return (
    <div
      aria-live="polite"
      className={styles['check-sidebar-header']}
      data-test-check-sidebar={check.id}
    >
      <div className={styles.type}>
        <h2
          className={cx(
            'body-1',
            styles.title,
            check.status === 'validated' && styles.validated,
            check.status === 'pending' && styles.pending,
            check.status === 'received' && styles.received,
            check.status === 'canceled' && styles.canceled,
            check.status === 'declined' && styles.declined,
            check.status === 'refunded' && styles.refunded
          )}
        >
          {formatMessage({
            id: `checks.sidebar.title.${check.status}`,
          })}
        </h2>

        <button
          aria-label={formatMessage({ id: 'btn.close' })}
          className={cx(styles.close, 'btn btn--icon-only btn--tertiary')}
          data-test-close-button
          id={sidebarCloseId}
          onClick={onClickCloseSidebar}
          type="button"
        >
          <IconCrossOutlined />
        </button>
      </div>

      <div className={cx(styles['column-layout'], styles.container)}>
        <div className={styles.picto}>
          <StatusAvatar
            className={styles.avatar}
            data-test-check-sidebar-header-avatar
            dataTestId="check-sidebar-header-avatar"
            hasBorder={check.enrichmentData.logo}
            icon={getStatusIcon(check.avatarInfo.icon)}
            size="64"
            src={check.avatarInfo.mediumLogo}
          />
        </div>

        <div className={cx(styles['column-layout'], styles.general)}>
          <div className={styles.amount}>
            <p className="title-3 currency mb-4" data-test-check-amount>
              {formatMoney(check.amount, {
                intl: emberIntl,
                currency: 'EUR',
                signus: '+',
              })}
            </p>
          </div>

          <p className={cx(styles.counterparty, 'body-2 mb-4')} data-test-check-emitter-name>
            {check.emitterName}
          </p>

          <p className={cx(styles.date, 'caption')} data-test-check-created-at>
            {dateToken({ date: check.createdAt, locale, token: 'date-time-s' })}
          </p>
        </div>

        {showAccountName ? (
          <InfoList className={styles['account-infobox']}>
            <InfoList.Row
              content={check.bankAccount?.name}
              data-test-account-name
              label={formatMessage({ id: 'labels.account_to' })}
            />
          </InfoList>
        ) : null}

        <InfoList className={styles.infobox}>
          <InfoList.Row
            content={checkCmc7}
            data-test-check-sidebar-checkid
            label={formatMessage({ id: 'checks.sidebar.check_id' })}
          />

          <InfoList.Row
            content={check.rlmc}
            data-test-check-sidebar-rlmckey
            label={formatMessage({ id: 'checks.sidebar.rlmc_key' })}
          />

          <InfoList.Row
            content={dateToken({ date: check.emittedDate, locale, token: 'date-year-s' })}
            data-test-check-sidebar-emittedat
            label={formatMessage({ id: 'checks.sidebar.emitted_at' })}
          />
        </InfoList>
      </div>
    </div>
  );
}
