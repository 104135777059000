import { type ReactElement } from 'react';
import cx from 'clsx';
import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './styles.strict-module.css';

interface ProductCardLoadingProps {
  className?: string;
}

export function ProductCardLoading({ className }: ProductCardLoadingProps): ReactElement {
  return (
    <div className={cx(styles.container, className)} data-test-insurance-hub-product-card-loading>
      <div>
        <SkeletonLoader.Block
          borderRadius="100%"
          className="mb-24"
          data-test-insurance-hub-product-card-loading-logo
          height="56px"
          width="56px"
        />
        <SkeletonLoader.Line
          className="mb-16"
          data-test-insurance-hub-product-card-loading-title
          width="104px"
        />
        <div data-test-insurance-hub-product-card-loading-description>
          <SkeletonLoader.Line className="mb-8" width="343px" />
          <SkeletonLoader.Line width="129px" />
        </div>
      </div>
      <div className={styles.metadata}>
        <div
          className={styles['risks-container']}
          data-test-insurance-hub-product-card-loading-risks
        >
          <SkeletonLoader.Line className="mb-8" width="95px" />
          <div className={styles.risks}>
            <SkeletonLoader.Block borderRadius="4px" height="24px" width="112px" />
            <SkeletonLoader.Block borderRadius="4px" height="24px" width="112px" />
            <SkeletonLoader.Block borderRadius="4px" height="24px" width="112px" />
          </div>
        </div>
        <div data-test-insurance-hub-product-card-loading-item="price">
          <SkeletonLoader.Line className="mb-8" width="56px" />
          <SkeletonLoader.Line width="96px" />
        </div>
      </div>
    </div>
  );
}
