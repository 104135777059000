import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import type { SidepanelMode } from 'qonto/react/contexts/cash-flow-sidepanel-context';
import styles from './forecast-generation-header.strict-module.css';

export function ForecastGenerationHeader({ mode }: { mode: SidepanelMode }): ReactNode {
  const { formatMessage } = useIntl();

  return (
    <p className={styles.header} data-testid="forecast-generation-header">
      {mode === 'flashForecast'
        ? formatMessage({ id: 'cash-flow.first-time-experience.sidepanel.flash.header' })
        : formatMessage({ id: 'cash-flow.first-time-experience.sidepanel.import.header' })}
    </p>
  );
}
