import cx from 'clsx';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, LottiePlayer } from '@repo/design-system-kit';
import { Link } from '@qonto/react-migration-toolkit/react/components';
import { useThemedAssetPath } from 'qonto/react/hooks/use-themed-asset-path';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import styles from './error.strict-module.css';

interface ErrorProps {
  onRetry?: () => void;
  route?: string;
}

export function Error({ onRetry, route }: ErrorProps): ReactNode {
  const { organization } = useOrganizationManager();

  const displayButton = (): ReactNode => {
    if (onRetry) {
      return (
        <Button data-test-error-retry-button onPress={onRetry} value="secondary">
          <FormattedMessage id="financing.pay-later.cockpit.table.error-state.cta" />
        </Button>
      );
    } else if (route) {
      return (
        <Link
          className="btn btn--secondary"
          data-test-error-retry-link
          to={`/organizations/${organization.slug}/${route}`}
        >
          <FormattedMessage id="financing.pay-later.cockpit.table.error-state.cta" />
        </Link>
      );
    }
  };
  return (
    <div className={styles.errorContent}>
      <LottiePlayer
        className={cx(styles.illustration, 'mb-32')}
        data-test-error-illustration
        loop
        path={useThemedAssetPath('/lotties/error-404.json')}
      />
      <h2 className="title-2 mb-8" data-test-error-title>
        <FormattedMessage id="financing.pay-later.cockpit.table.error-state.title" />
      </h2>
      <p className="body-2 mb-32" data-test-error-description>
        <FormattedMessage id="financing.pay-later.cockpit.table.error-state.subtitle" />
      </p>
      {displayButton()}
    </div>
  );
}
