import type { ReactNode } from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './styles.strict-module.css';

export function PlaceholderRow(): ReactNode {
  return (
    <>
      <div className={styles['row-content']}>
        <SkeletonLoader.Block
          borderRadius="50%"
          data-test-skeleton-block=""
          height="32px"
          width="32px"
        />
      </div>
      <SkeletonLoader.Header
        data-test-skeleton-header=""
        lines={1}
        smallLinesWidth="127px"
        width="191px"
      />
    </>
  );
}
