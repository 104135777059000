import React, { type ReactNode } from 'react';
import { Button } from '@repo/design-system-kit';
import { IconLightningOutlined } from '@repo/monochrome-icons';
import { FormattedMessage } from 'react-intl';
import styles from './styles.strict-module.css';

interface CtaProp {
  copy: string;
  action: (includePastTransactions: boolean) => void;
}

interface CategorizationWidgetProps {
  title: string;
  subtitle: ReactNode;
  pastFutureCta: CtaProp;
  futureCta: CtaProp;
  onClose: () => void;
}

export function CategorizationWidget({
  title,
  subtitle,
  pastFutureCta,
  futureCta,
  onClose,
  ...props
}: CategorizationWidgetProps): ReactNode {
  return (
    <article className={styles['categorization-card']} {...props}>
      <p className={styles['categorization-card-title']} data-testid="title">
        <IconLightningOutlined aria-hidden className="mr-8" />
        {title}
      </p>
      {subtitle}
      <footer className={styles['categorization-actions']}>
        <Button
          data-testid="cta-cancel"
          onPress={() => {
            onClose();
          }}
          size="small"
          stretch
          variant="primary"
        >
          <FormattedMessage id="transactions.sidepanel.cash-flow.categories.auto-categorize-widget.button.this-one" />
        </Button>
        <Button
          data-testid="cta-past-future"
          onPress={() => {
            pastFutureCta.action(true);
          }}
          size="small"
          stretch
          variant="secondary"
        >
          {pastFutureCta.copy}
        </Button>
        <Button
          data-testid="cta-future"
          onPress={() => {
            futureCta.action(false);
          }}
          size="small"
          stretch
          variant="secondary"
        >
          {futureCta.copy}
        </Button>
      </footer>
    </article>
  );
}
