import { type ReactNode } from 'react';
import cx from 'clsx';
import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './placeholder.strict-module.css';

interface PlaceholderProps {
  isSidepanelOpen?: boolean;
  className?: string;
}

export function Placeholder({ isSidepanelOpen, className, ...rest }: PlaceholderProps): ReactNode {
  return (
    <tr className={cx(styles.row, className)} {...rest}>
      <td aria-hidden="true" />

      <td
        className={cx(styles.col, isSidepanelOpen ? styles['col-12'] : styles['col-9'])}
        data-test-first-col
      >
        <div className={styles['debitor-container']}>
          <SkeletonLoader.Avatar className={cx(styles.avatar, 'mr-16')} />
          <SkeletonLoader.Header lines={1} smallLinesWidth="80px" width="176px" />
        </div>
      </td>

      <td className={styles.col}>
        <SkeletonLoader.Line width="80px" />
      </td>

      <td className={styles.col}>
        <SkeletonLoader.Line width="90px" />
      </td>

      <td className={styles.col}>
        <SkeletonLoader.Block borderRadius="4px" height="24px" width="79px" />
      </td>

      <td align="right" className={styles.col}>
        <SkeletonLoader.Line width="85px" />
      </td>
    </tr>
  );
}
