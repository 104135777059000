import { type ReactNode } from 'react';
import { CardLevel, type PhysicalCardLevel } from '@repo/cards-kit/constants';
import type { Task } from 'ember-concurrency';
import cx from 'clsx';
import { ChooseCardLayout } from 'qonto/react/cards/components/flows/choose-card-layout';
import type { Estimates } from 'qonto/react/cards/types/estimates';
import type { CardLimits } from 'qonto/react/cards/types/limits';
import type { OptionPrices } from 'qonto/react/cards/types/option-price';
import { MetalCard } from './internal/metal-card';
import { PlusCard } from './internal/plus-card';
import { StandardCard } from './internal/standard-card';
import styles from './styels.strict-module.css';

interface ChooseCardPhysicalLayoutProps {
  assetProps: {
    classNames: Record<PhysicalCardLevel, string>;
    handleSelectorMouseenter: Task<void, [PhysicalCardLevel]>;
    handleSelectorMouseleave: () => Promise<void>;
    ids: Record<PhysicalCardLevel, string>;
    isVideoPlayingOnErrorProps: Record<PhysicalCardLevel, boolean>;
    setIsLoaded: (cardLevel: PhysicalCardLevel) => void;
    shouldAnimateCards: boolean;
  };
  cardEstimates: Estimates;
  cardsMaxLimits: CardLimits;
  handleClick: (cardLevel: PhysicalCardLevel) => Promise<void>;
  optionPrices?: OptionPrices; // Only relevant for Standard card tooltip
}

export function ChooseCardPhysicalLayout({
  assetProps: {
    classNames,
    handleSelectorMouseenter,
    handleSelectorMouseleave,
    ids,
    isVideoPlayingOnErrorProps,
    setIsLoaded,
    shouldAnimateCards,
  },
  cardEstimates,
  cardsMaxLimits,
  handleClick,
  optionPrices,
}: ChooseCardPhysicalLayoutProps): ReactNode {
  return (
    <ul className={cx(styles['cards-list'], 'mb-16')}>
      <ChooseCardLayout>
        <MetalCard
          assetProps={{
            className: classNames.metal,
            handleMouseEnter: async () => {
              await handleSelectorMouseenter.perform(CardLevel.Metal);
            },
            handleMouseLeave: handleSelectorMouseleave,
            id: ids.metal,
            isVideoPlayingOnError: isVideoPlayingOnErrorProps.metal,
            setIsLoaded,
            shouldAnimateCards,
          }}
          cardEstimates={cardEstimates[CardLevel.Metal]}
          cardsMaxLimits={cardsMaxLimits[CardLevel.Metal]}
          ctaProps={{
            handleClick: async () => {
              await handleClick(CardLevel.Metal);
            },
          }}
        />
        <PlusCard
          assetProps={{
            className: classNames.plus,
            handleMouseEnter: async () => {
              await handleSelectorMouseenter.perform(CardLevel.Plus);
            },
            handleMouseLeave: handleSelectorMouseleave,
            id: ids.plus,
            isVideoPlayingOnError: isVideoPlayingOnErrorProps.plus,
            setIsLoaded,
            shouldAnimateCards,
          }}
          cardEstimates={cardEstimates[CardLevel.Plus]}
          cardsMaxLimits={cardsMaxLimits[CardLevel.Plus]}
          ctaProps={{
            handleClick: async () => {
              await handleClick(CardLevel.Plus);
            },
          }}
        />
        <StandardCard
          assetProps={{
            className: classNames.standard,
            handleMouseEnter: async () => {
              await handleSelectorMouseenter.perform(CardLevel.Standard);
            },
            handleMouseLeave: handleSelectorMouseleave,
            id: ids.standard,
            isVideoPlayingOnError: isVideoPlayingOnErrorProps.standard,
            setIsLoaded,
            shouldAnimateCards,
          }}
          cardEstimates={cardEstimates[CardLevel.Standard]}
          cardsMaxLimits={cardsMaxLimits[CardLevel.Standard]}
          ctaProps={{
            handleClick: async () => {
              await handleClick(CardLevel.Standard);
            },
          }}
          optionPrice={optionPrices?.[CardLevel.Standard]}
        />
      </ChooseCardLayout>
    </ul>
  );
}
