import { useSegment } from '@repo/poly-hooks/segment';
import { useUpdateUpcomingTransaction } from 'qonto/react/components/cash-flow/hooks/use-update-upcoming-transaction';
import type { UpcomingTransaction } from 'qonto/react/hooks/use-upcoming-transactions';

export const useScheduleClientInvoiceHandler = (
  upcomingTransaction: UpcomingTransaction | null
): (() => void) => {
  const segment = useSegment();
  const type = upcomingTransaction?.provider_object_type;

  return () => {
    segment.track('compass_action_clicked', {
      action: 'schedule_client_invoice',
      type,
    });
  };
};

export const useScheduleClientInvoice = (): {
  updateClientInvoice: (params: {
    type: string;
    upcomingTransactionId: string;
    newDate: string;
    counterpartyName: string;
  }) => void;
} => {
  const segment = useSegment();
  const { mutate } = useUpdateUpcomingTransaction();

  const updateClientInvoice = ({
    type,
    upcomingTransactionId,
    newDate,
  }: {
    type: string;
    upcomingTransactionId: string;
    newDate: string;
    counterpartyName: string;
  }): void => {
    segment.track('compass_action_clicked', {
      action: 'schedule_client_invoice_submit',
      type,
    });

    mutate({
      upcomingTransactionId,
      payload: { expected_date: newDate },
    });
  };

  return { updateClientInvoice };
};
