import { useIntl } from 'react-intl';
import { SubscriptionSuccess } from '@repo/domain-kit/pricing';
import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import { useSegment } from '@repo/poly-hooks/segment';
// @ts-expect-error -- cannot fix the error
import { variation } from 'ember-launch-darkly';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import type { ContextArg } from '../data-context.type';
import styles from './styles.strict-module.css';

type SuccessProps = ContextArg;

export function Success({ transitionToNext, context }: SuccessProps): ReactElement {
  const segment = useSegment();
  const { formatMessage } = useIntl();
  const { organization, membership } = useOrganizationManager();
  const toastFlashMessages = useEmberService('toast-flash-messages');
  const targetAddonCode = context.targetAddon?.code;

  const safeVariation = variation as (feature: string) => boolean;
  const hasNewFeedbackStyle =
    safeVariation('feature--boolean-improved-feedback-on-success') && Boolean(context.isRemoval);

  const [feedback, setFeedback] = useState('');

  useEffect(() => {
    segment.track('checkout_confirmation_success', { ...context.tracking });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run once
  }, []);

  return (
    <div className={styles.container} data-test-addon-change-success-step>
      <SubscriptionSuccess
        ctaText={
          hasNewFeedbackStyle
            ? formatMessage({ id: 'subscription.change.success.feedback-cta' })
            : formatMessage({ id: 'btn.close' })
        }
        hasNewFeedbackStyle={hasNewFeedbackStyle}
        onClick={() => {
          if (hasNewFeedbackStyle) {
            if (feedback) {
              segment.track('checkout_confirmation_success_feedback_clicked', {
                membership_id: membership.id,
                input_content: feedback,
                addon_code: targetAddonCode,
              });
              toastFlashMessages.toastInfo(
                formatMessage({ id: 'subscription.change.success.feedback-submitted' })
              );
            }
          } else {
            segment.track('checkout_confirmation_success_closed_clicked');
          }
          transitionToNext();
        }}
        onClickShareFeedback={() => {
          segment.track('checkout_confirmation_success_feedback_clicked', {
            addon_code: targetAddonCode,
          });
        }}
        onUpdateTextArea={(value: string) => {
          setFeedback(value);
        }}
        shareFeedbackLink={`${formatMessage({ id: 'subscription.change.addons.success.feedback-linkUrl' })}#membership_id=${organization.id}&addon_code=${targetAddonCode}`}
        shareFeedbackSubtitle={formatMessage(
          {
            id: 'subscription.change.addons.success.subtitle-feedback',
          },
          { addonName: context.targetAddon?.brand_name }
        )}
        shareFeedbackText={
          context.isRemoval
            ? formatMessage({ id: 'subscription.change.addons.success.feedback-cta' })
            : ''
        }
        subtitle={formatMessage({ id: 'subscription.change.addons.success.subtitle' })}
        textAreaLabel={formatMessage(
          {
            id: 'subscription.change.addons.success.subtitle-feedback',
          },
          { addonName: context.targetAddon?.brand_name }
        )}
        textAreaPlaceholder={formatMessage({
          id: 'subscription.change.addons.success.input.feedback',
        })}
        textAreaValue={feedback}
        title={formatMessage({ id: 'subscription.change.addons.success.title' })}
      />
    </div>
  );
}
