import { type ReactNode } from 'react';
import { ListBox, Popover, Collection, Header, ListBoxSection } from 'react-aria-components';
import type { Placement } from 'react-aria';
import { useIntl } from 'react-intl';
import type {
  CashflowActiveCategoryId,
  CashflowCategories,
} from 'qonto/react/models/cash-flow-category';
import { ParentCategory } from '../parent-category';
import styles from './category-selector-popover.strict-module.css';

interface CategorySelectorPopoverProps {
  categories: CashflowCategories;
  popoverWidth?: string;
  triggerWidth: string;
  placement?: Placement;
  portalContainer: Element;
  activeCategoryId?: CashflowActiveCategoryId;
  onAction?: (selectedCategoryId: string) => void;
  offset?: number;
  popoverStyles?: Record<string, string>;
}

export function CategorySelectorPopover({
  categories,
  popoverWidth,
  triggerWidth = 'auto',
  placement = 'bottom start',
  portalContainer,
  activeCategoryId,
  popoverStyles,
  onAction,
  ...props
}: CategorySelectorPopoverProps): ReactNode {
  const { formatMessage } = useIntl();
  const { inflow, outflow } = categories;

  const categoriesData = [
    {
      id: 'inflow',
      name: formatMessage({ id: 'cash-flow-categories.group-title.inflows' }),
      categories: inflow,
    },
    {
      id: 'outflow',
      name: formatMessage({ id: 'cash-flow-categories.group-title.outflows' }),
      categories: outflow,
    },
  ].filter(category => category.categories.length > 0);

  return (
    <Popover
      UNSTABLE_portalContainer={portalContainer}
      placement={placement}
      style={{ width: popoverWidth ?? triggerWidth, ...popoverStyles }}
      {...props}
    >
      <div
        className={styles.popover}
        data-testid="cash-flow-category-popover"
        id="cash-flow-category-popover"
      >
        <ListBox className={styles['list-box']} items={categoriesData}>
          {inOutFlow => {
            if (!inOutFlow.categories.length) return null;
            return (
              <ListBoxSection className={styles.section} data-testid="section" id={inOutFlow.name}>
                <Header className={styles['section-title']} data-testid="section-title">
                  {inOutFlow.name}
                </Header>
                <Collection dependencies={[activeCategoryId]} items={inOutFlow.categories}>
                  {item => {
                    return (
                      <ParentCategory
                        activeCategoryId={activeCategoryId}
                        category={item}
                        onAction={onAction}
                      />
                    );
                  }}
                </Collection>
              </ListBoxSection>
            );
          }}
        </ListBox>
      </div>
    </Popover>
  );
}
