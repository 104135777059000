/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import { useState, type ReactNode } from 'react';
import { DataObjectToggleButton, Timeline, type TimelineItemProps } from '@repo/design-system-kit';
import {
  IconClockOutlined,
  IconCheckmarkRoundedFilled,
  IconWarningSignFilled,
  IconErrorFilled,
} from '@repo/design-system-kit/assets/icons';
import cx from 'clsx';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { dateToken, DATE_FORMAT_TOKENS } from '@qonto/ui-kit/utils/date-token';
import { InstallmentStatus, type InstallmentModel } from 'qonto/react/financing/api';
import styles from './styles.strict-module.css';

function timelineItemMedia(installment: InstallmentModel): ReactNode {
  if (installment.status === InstallmentStatus.Paid) {
    return (
      <IconCheckmarkRoundedFilled
        className={cx(styles.icon, styles.paid)}
        data-testid="icon-paid"
      />
    );
  }

  return (
    <IconClockOutlined
      className={cx(styles.icon, styles['not-paid'])}
      data-testid="icon-not-paid"
    />
  );
}

function timelineItemTitle(installment: InstallmentModel): ReactNode {
  return (
    <span className={styles.title} data-testid="title">
      {'-\xA0'}
      <FormattedNumber
        currency={installment.totalAmount.currency}
        maximumFractionDigits={2}
        minimumFractionDigits={2}
        style="currency"
        value={Number(installment.totalAmount.value)}
      />
      {installment.status === InstallmentStatus.Late && (
        <IconErrorFilled className={styles.overdue} data-testid="icon-overdue" />
      )}
      {installment.status === InstallmentStatus.AtRisk && (
        <IconWarningSignFilled className={styles['at-risk']} data-testid="icon-at-risk" />
      )}
    </span>
  );
}

function timelineItemContent(installment: InstallmentModel, locale: string): ReactNode {
  const formattedDate = dateToken({
    date: installment.date,
    locale,
    token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
  });

  const isInstallmentExposed =
    installment.status === InstallmentStatus.Late ||
    installment.status === InstallmentStatus.AtRisk;

  return (
    <span
      className={isInstallmentExposed ? styles['content-tertiary'] : ''}
      data-testid={isInstallmentExposed ? 'content-exposed' : 'content'}
    >
      {formattedDate}
    </span>
  );
}

function processLongTimeline(list: InstallmentModel[], locale: string): TimelineItemProps[] {
  const paidInstallments = list.filter(
    installment => installment.status === InstallmentStatus.Paid
  );

  if (paidInstallments.length === 0 || paidInstallments.length === list.length) {
    const slicedList = list.slice(0, 3);
    return slicedList.map((installment, index) =>
      transformInstallment(installment, index.toString(), locale)
    );
  }

  const shortList: TimelineItemProps[] = [];

  shortList.push({
    id: '0',
    lineVariant: 'solid',
    slots: {
      media: (
        <IconCheckmarkRoundedFilled
          className={cx(styles.icon, styles.paid)}
          data-testid="icon-paid"
        />
      ),
      title: (
        <span data-testid="title">
          <FormattedMessage
            id="financing.pay-later.cockpit.details.timeline.item.title"
            values={{ count: paidInstallments.length, current: paidInstallments.length }}
          />
        </span>
      ),
      content: null,
    },
  });

  const unpaidInstallments = list.filter(
    installment => installment.status !== InstallmentStatus.Paid
  );

  const slicedUnpaidInstallments = unpaidInstallments.slice(0, 2);

  slicedUnpaidInstallments.forEach((installment, index) => {
    shortList.push(transformInstallment(installment, (index + 1).toString(), locale));
  });

  return shortList;
}

function transformInstallment(
  installment: InstallmentModel,
  id: string,
  locale: string
): TimelineItemProps {
  return {
    id,
    lineVariant: installment.status === InstallmentStatus.Paid ? 'solid' : 'dashed',
    slots: {
      media: timelineItemMedia(installment),
      title: timelineItemTitle(installment),
      content: timelineItemContent(installment, locale),
    },
  };
}

function hasMoreItemsToShow(list: InstallmentModel[]): boolean {
  const paidInstallments = list.filter(
    installment => installment.status === InstallmentStatus.Paid
  );

  if (paidInstallments.length === list.length) {
    // All installments are paid, there are always more items to show
    return true;
  }

  if (paidInstallments.length > 0) {
    const unpaidInstallments = list.filter(
      installment => installment.status !== InstallmentStatus.Paid
    );
    // If the grouped paid installments (1) + unpaid installments > 3, there are more items to show
    return unpaidInstallments.length + 1 > 3;
  }

  return true;
}

export function InstallmentTimeline({ list }: { list: InstallmentModel[] }): ReactNode {
  const { locale } = useIntl();
  const [isExpanded, setIsExpanded] = useState(false);

  let installmentList: TimelineItemProps[] = [];

  function handleToggleExpand(): void {
    setIsExpanded(!isExpanded);
  }

  const canExpand = list.length > 3;

  if (canExpand && !isExpanded) {
    installmentList = processLongTimeline(list, locale);
  } else {
    installmentList = list.map((installment, index) =>
      transformInstallment(installment, index.toString(), locale)
    );
  }

  const showLastLine = canExpand && hasMoreItemsToShow(list) && !isExpanded;

  return (
    <div className={styles.container}>
      <Timeline list={installmentList} showLastLine={showLastLine} />
      {Boolean(canExpand) && (
        <DataObjectToggleButton data-testid="toggle-button" onPress={handleToggleExpand}>
          <FormattedMessage
            id={
              isExpanded
                ? 'financing.pay-later.cockpit.details.collapse-button.show-less'
                : 'financing.pay-later.cockpit.details.collapse-button.show-more'
            }
          />
        </DataObjectToggleButton>
      )}
    </div>
  );
}
