import { useRouter } from '@qonto/react-migration-toolkit/react/hooks';
import { useSegment } from '@repo/poly-hooks/segment';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import type { UpcomingTransaction } from 'qonto/react/hooks/use-upcoming-transactions';

export const useSeeSupplierInvoice = (
  upcomingTransaction: UpcomingTransaction | null
): (() => void) => {
  const segment = useSegment();
  const router = useRouter();
  const { organization } = useOrganizationManager();
  const type = upcomingTransaction?.provider_object_type;

  return (): void => {
    if (type === 'supplier_invoice') {
      const supplierInvoiceId = upcomingTransaction?.provider_object_id;
      const path = `/organizations/${organization.slug}/supplier-invoices/${supplierInvoiceId}`;

      void router.push(path);
    }

    segment.track('compass_action_clicked', {
      action: 'see_invoice',
      type,
    });
  };
};
