import type { PropsWithChildren, ReactNode } from 'react';
import cx from 'clsx';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import type { OptionPrice } from 'qonto/react/cards/types/option-price';
import type { Estimate } from 'qonto/react/cards/types/estimates';
import styles from './styles.strict-module.css';
import { FreeCardPricing } from './internal/free-card-pricing';
import { DiscountedCardPricing } from './internal/discounted-card-pricing';

interface PricingProps {
  cardEstimates: Estimate | null;
  optionPrice?: OptionPrice;
}

export function Pricing({
  cardEstimates,
  optionPrice,
}: PropsWithChildren<PricingProps>): ReactNode {
  if (cardEstimates) {
    const { monthly_cost: monthlyCost } = cardEstimates;

    if (monthlyCost === 0) {
      return <FreeCardPricing optionPrice={optionPrice} />;
    }

    const {
      discount_finish_date: discountFinishDate,
      discounted_monthly_cost: discountedMonthlyCost,
    } = cardEstimates;

    if (discountFinishDate && monthlyCost !== discountedMonthlyCost) {
      return (
        <DiscountedCardPricing
          discountFinishDate={discountFinishDate}
          discountedMonthlyCost={discountedMonthlyCost}
          monthlyCost={monthlyCost}
        />
      );
    }

    return (
      <p className={cx('body-1', styles.pricing)} data-test-card-pricing>
        <FormattedMessage
          id="cards.steps.choose-card.pricing-with-period"
          values={{
            amount: (
              <span className={cx('title-4', styles.amount)}>
                <FormattedNumber
                  currency="EUR"
                  minimumFractionDigits={0}
                  style="currency"
                  value={monthlyCost}
                />
              </span>
            ),
          }}
        />
      </p>
    );
  }

  return null;
}
