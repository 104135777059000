import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function StatusApprovedXS(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="currentColor"
      {...props}
    >
      <rect width="12" height="12" rx="6" fill="white" />
      <circle cx="6" cy="6" r="6" fill="#45B184" />
      <path
        d="M4.87484 8.50008C4.9488 8.50034 5.02207 8.4858 5.09034 8.45733C5.15861 8.42887 5.2205 8.38705 5.27237 8.33432L8.89065 4.64629C8.96074 4.57478 9 4.47864 9 4.37852C9 4.27839 8.96074 4.18225 8.89065 4.11074C8.85598 4.07566 8.81469 4.04781 8.76918 4.0288C8.72366 4.00979 8.67483 4 8.6255 4C8.57618 4 8.52735 4.00979 8.48183 4.0288C8.43632 4.04781 8.39503 4.07566 8.36036 4.11074L4.87484 7.66452L3.64024 6.36092C3.60557 6.32584 3.56428 6.29798 3.51877 6.27897C3.47326 6.25996 3.42442 6.25017 3.3751 6.25017C3.32577 6.25017 3.27694 6.25996 3.23143 6.27897C3.18591 6.29798 3.14462 6.32584 3.10995 6.36092C3.03951 6.43224 3 6.52844 3 6.62869C3 6.72894 3.03951 6.82514 3.10995 6.89646L4.47731 8.33507C4.58382 8.44158 4.72483 8.50083 4.87484 8.50083V8.50008Z"
        fill="white"
      />
    </svg>
  );
}
