import { type ReactElement } from 'react';
import { QState } from '@repo/design-system-kit';
import { FormattedMessage } from 'react-intl';
import styles from './error.strict-module.css';

interface FlowsCheckErrorProps {
  backToSummary: () => void;
}

export function FlowsCheckError({ backToSummary }: FlowsCheckErrorProps): ReactElement {
  return (
    <div className={styles.error} data-test-check-error-step>
      <div className={styles.container}>
        <QState.Illustration
          data-test-check-error-state-illustration
          lottiePath="/lotties/error-404.json"
          size="small"
        />
        <QState.Title>
          <h1 className="title-2 mb-16" data-test-check-error-state-title>
            <FormattedMessage id="checks.error-state.title" />
          </h1>
        </QState.Title>
        <QState.Legend>
          <p className="mb-32" data-test-check-error-state-description>
            <FormattedMessage id="checks.error-state.description" />
          </p>
        </QState.Legend>

        <button
          className="btn btn--primary"
          data-test-check-error-state-cta
          onClick={backToSummary}
          type="button"
        >
          <FormattedMessage id="checks.error-state.cta" />
        </button>
      </div>
    </div>
  );
}
