import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function StatusCancelledXS(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="currentColor"
      {...props}
    >
      <rect width="12" height="12" rx="6" fill="white" />
      <circle cx="6" cy="6" r="6" fill="#F95656" />
      <path
        d="M6 9C5.20435 9 4.44129 8.68393 3.87868 8.12132C3.31607 7.55871 3 6.79565 3 6C3 5.20435 3.31607 4.44129 3.87868 3.87868C4.44129 3.31607 5.20435 3 6 3C6.79565 3 7.55871 3.31607 8.12132 3.87868C8.68393 4.44129 9 5.20435 9 6C9 6.79565 8.68393 7.55871 8.12132 8.12132C7.55871 8.68393 6.79565 9 6 9ZM8.25 6C8.25 5.53333 8.10333 5.07867 7.83067 4.7L4.7 7.83C5.03585 8.07007 5.43139 8.21287 5.84314 8.2427C6.25489 8.27253 6.66689 8.18824 7.03384 7.99908C7.40078 7.80992 7.70845 7.52324 7.92301 7.17055C8.13757 6.81786 8.25072 6.41283 8.25 6ZM3.75 6C3.75 6.46667 3.89667 6.92133 4.16933 7.3L7.30067 4.16933C6.96477 3.92904 6.5691 3.78608 6.15718 3.75618C5.74527 3.72627 5.3331 3.81059 4.96602 3.99985C4.59894 4.18911 4.29119 4.47597 4.07665 4.82887C3.8621 5.18176 3.74907 5.58701 3.75 6Z"
        fill="white"
      />
    </svg>
  );
}
