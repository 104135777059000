import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function UploadOutlined(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} fill="currentColor" {...props}>
      <g fill="#636360" clipPath="url(#a)">
        <path
          fill="currentColor"
          d="M3.921 11.72C2.006 11.72.5 10.28.5 8c0-2.03 1.167-3.59 2.783-3.94C3.333 1.81 5.557 0 8.291 0c2.214 0 4.13 1.18 4.768 2.9 2.015.43 3.441 2.33 3.441 4.57 0 2.45-1.746 4.24-4.15 4.24a.495.495 0 0 1-.498-.5c0-.28.22-.5.498-.5 1.856 0 3.152-1.33 3.152-3.24 0-1.91-1.236-3.45-2.872-3.64-.22 0-.37-.15-.43-.36C11.803 2.04 10.167 1 8.29 1 6.087 1 4.28 2.41 4.28 4.15c0 .11 0 .21.02.31a.49.49 0 0 1-.12.37.53.53 0 0 1-.348.17c-1.327.06-2.325 1.3-2.325 3s1.068 2.72 2.424 2.72c.28 0 .5.22.5.5s-.22.5-.5.5h-.01Z"
        />
        <path
          fillRule="evenodd"
          d="M6.176 8.81c-.2-.2-.2-.51 0-.71L8.1 6.17c.09-.11.23-.17.379-.17.13 0 .26.05.36.15l1.954 1.96c.2.2.2.51 0 .71-.2.2-.508.2-.708 0L8.979 7.71v7.79c0 .28-.22.5-.499.5a.495.495 0 0 1-.499-.5V7.71l-1.097 1.1c-.2.2-.509.2-.708 0Z"
          clipRule="evenodd"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.5 0h16v16H.5z" />
        </clipPath>
      </defs>
    </svg>
  );
}
