import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import cx from 'clsx';
import type { Estimates } from 'qonto/react/cards/types/estimates';
import styles from './footer.strict-module.css';

interface FooterProps {
  estimates: Estimates;
}

export function Footer({ estimates }: FooterProps): ReactNode {
  const abilities = useEmberService('abilities');

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Some tests are no initialized correctly, typing shouldn't be compromised because of that
  const isVirtualPaid = Number(estimates?.virtual?.monthly_cost) > 0;
  if (isVirtualPaid) {
    return (
      <p
        className={cx('caption-bold', styles['eligibility-disclaimer'])}
        data-test-choose-card-disclaimer
      >
        <FormattedMessage id="cards.steps.choose-card.excluded-vat" />
      </p>
    );
  }

  if (abilities.can('view advertising card')) {
    return (
      <p
        className={cx('caption-bold', styles['eligibility-disclaimer'])}
        data-test-choose-card-disclaimer
      >
        <FormattedMessage id="cards.steps.choose-card.eligibility" />
      </p>
    );
  }

  return null;
}
