import { type ReactNode } from 'react';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { DataWithIconCell } from 'qonto/react/components/table-v2/cells/data-with-icon-cell';
import { BaseCell } from 'qonto/react/components/table-v2/cells/base-cell';
import { getFlatCategories } from 'qonto/react/components/transactions/sidebar/category/cashflow-category/utils/cash-flow-categories';
import { CATEGORY_CELL_CLICK_EVENT_NAME } from 'qonto/react/constants';
import { useCashflowCategories } from 'qonto/react/hooks/use-cashflow-categories';
import type { CashflowCategories } from 'qonto/react/models/cash-flow-category';
import type { Transaction } from 'qonto/react/graphql';
import { useCategoryAttrs } from './category-map';
import { CashflowCategoryCellPopover, CategoryCellPopover } from './popover';
import { CashflowCategorySelected } from './category-selected';

interface CategoryCellProps {
  transaction: Transaction;
}

interface CashflowCategoryCellProps extends CategoryCellProps {
  categories?: CashflowCategories;
}

interface ActivityTagCategoryCellProps {
  category?: string | null;
  transactionId: string;
}

export function CategoryCell({ transaction }: CategoryCellProps): ReactNode {
  const abilities = useEmberService('abilities');
  const { getCategories } = useCashflowCategories();
  const categories = getCategories();

  if (abilities.can('view cashflow categories category')) {
    return <CashflowCategoryCell categories={categories} transaction={transaction} />;
  }

  const category = transaction.activityTag?.toLowerCase();
  const transactionId = transaction.id;

  return <ActivityTagCategoryCell category={category} transactionId={transactionId} />;
}

export function CashflowCategoryCell({
  transaction,
  categories,
}: CashflowCategoryCellProps): ReactNode {
  const segment = useEmberService('segment');
  const flatCategories = categories ? getFlatCategories(categories) : [];

  const trackCellOpen = (isOpen: boolean): void => {
    if (isOpen) {
      segment.track(CATEGORY_CELL_CLICK_EVENT_NAME);
    }
  };

  return (
    <BaseCell
      data-testid="category-cell"
      onOpenChange={trackCellOpen}
      popoverSlot={
        <CashflowCategoryCellPopover
          activeCategoryId={transaction.categoryAssignment?.id}
          categories={categories}
          transactionId={transaction.id}
        />
      }
    >
      <CashflowCategorySelected categories={flatCategories} transaction={transaction} />
    </BaseCell>
  );
}

export function ActivityTagCategoryCell({
  category,
  transactionId,
}: ActivityTagCategoryCellProps): ReactNode {
  const segment = useEmberService('segment');

  const { title, icon } = useCategoryAttrs(category);

  const trackCellOpen = (isOpen: boolean): void => {
    if (isOpen) {
      segment.track(CATEGORY_CELL_CLICK_EVENT_NAME);
    }
  };

  return (
    <BaseCell
      data-testid="category-cell"
      onOpenChange={trackCellOpen}
      popoverSlot={<CategoryCellPopover category={category} transactionId={transactionId} />}
    >
      <DataWithIconCell icon={icon} title={title} />
    </BaseCell>
  );
}
