import type { ReactNode } from 'react';
import { Button } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import type RibaPaymentModel from 'qonto/models/riba-payment';
import styles from './styles.strict-module.css';

interface ProofOfPaymentProps {
  payment: RibaPaymentModel;
}

export function ProofOfPayment({ payment }: ProofOfPaymentProps): ReactNode {
  const intl = useIntl();

  if (!payment.receiptUrl) {
    return null;
  }

  return (
    <Button
      className={styles.proofOfPaymentButton}
      data-test-riba-proof-of-payment-button
      onPress={() => {
        window.open(payment.receiptUrl, '_blank');
      }}
      size="small"
      variant="secondary"
    >
      {intl.formatMessage({ id: 'btn.download_proof' })}
    </Button>
  );
}
