import { SkeletonLoader } from '@repo/design-system-kit';
import React from 'react';
import styles from './sidepanel-tabs.strict-module.css';

export function SidePanelTabsLoading(): React.ReactNode {
  return (
    <div
      className={styles['sidepanel-tabs-loading-wrapper']}
      data-testid="cashflow-sidepanel-tabs-loading"
    >
      <SkeletonLoader.Line height="12px" standalone width="80px" />
      <SkeletonLoader.Line height="12px" standalone width="80px" />
    </div>
  );
}
