import { forwardRef } from 'react';
import type { ReactElement, HTMLProps, ReactNode, Ref } from 'react';
import { Button, TextAreaField, TextField } from '@repo/design-system-kit';
import { IconPlusOutlined } from '@repo/monochrome-icons';
import { SortableList } from '@repo/domain-kit/shared';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import type ReceivableInvoiceModel from 'qonto/models/receivable-invoice.ts';
import type QuoteModel from 'qonto/models/quote.ts';
import type InvoiceSubscriptionModel from 'qonto/models/invoice-subscription.ts';
import type { ReceivableInvoiceItemModel } from 'qonto/react/receivable-invoices/api/models/receivable-invoices-item.ts';
import type ReceivableInvoiceSectionModel from 'qonto/models/receivable-invoice/section.ts';
import { type FormErrors, useForm } from 'qonto/react/hooks/use-form.ts';
import { Item } from '../item';
import styles from './styles.strict-module.css';

const MAX_TITLE_LENGTH = 50;
const MAX_DESCRIPTION_LENGTH = 500;

interface SectionProps extends HTMLProps<HTMLDivElement> {
  document: ReceivableInvoiceModel | QuoteModel | InvoiceSubscriptionModel;
  currency: string;
  section: ReceivableInvoiceSectionModel;
  items: ReceivableInvoiceItemModel[];
  errors?: FormErrors<ReceivableInvoiceSectionModel>;
  index: number;
  isCreditNote?: boolean;
  shouldDisplayInternationalClientVatDisclaimer?: boolean;
  isDefault?: boolean;
  isDisabled?: boolean;
  hasMultipleItems?: boolean;
  hideUnit?: boolean;
  hideDiscount?: boolean;
  forcePositive?: boolean;
  onAddItem: (section: ReceivableInvoiceSectionModel) => void;
  onDeleteItem: (item: ReceivableInvoiceItemModel) => void;
  onDeleteSection: (section: ReceivableInvoiceSectionModel) => void;
  onSortItems: (section: ReceivableInvoiceSectionModel, sortedIds: string[]) => void;
  onVatResetDisclaimerDismiss?: (dismiss: boolean) => void;
}

export const Section = forwardRef(SectionWithoutRef) as (
  p: SectionProps & { ref?: Ref<HTMLDivElement> }
) => ReactElement;

function SectionWithoutRef(
  {
    className,
    document,
    currency,
    section,
    errors,
    isCreditNote,
    shouldDisplayInternationalClientVatDisclaimer,
    isDefault,
    isDisabled,
    items,
    hasMultipleItems,
    hideUnit,
    hideDiscount,
    forcePositive,
    onAddItem,
    onDeleteItem,
    onDeleteSection,
    onSortItems,
    onVatResetDisclaimerDismiss,
    ...props
  }: SectionProps,
  ref: Ref<HTMLDivElement>
): ReactNode {
  const { formatMessage } = useIntl();
  const { getValue, setValue } = useForm<ReceivableInvoiceSectionModel>({
    defaultValues: section,
    errors,
    onUpdateValue(attribute, value): void {
      section[attribute] = value;
    },
  });

  function handleTitleChange(title: string): void {
    setValue('title', title);
  }

  function handleDescriptionBlur(): void {
    setValue('description', section.description?.replace(/\n{1,}/g, '\n'));
  }

  function handleDescriptionChange(value: string): void {
    section.updateDescription(value);
    setValue('description', section.description);
  }

  function handleAddItem(): void {
    onAddItem(section);
  }

  function handleDeleteItem(item: ReceivableInvoiceItemModel): void {
    onDeleteItem(item);
  }

  function handleDeleteSection(): void {
    onDeleteSection(section);
  }

  function handleSort(sortedIds: string[]): void {
    onSortItems(section, sortedIds);
  }

  const sortedIds: string[] = items.map((item: ReceivableInvoiceItemModel) => item.uuid);

  const itemsContent =
    !isDisabled && hasMultipleItems ? (
      <SortableList items={sortedIds} onSort={handleSort}>
        {(itemId, draggableProps, index) => {
          const item = items.find(itm => itm.uuid === itemId);

          if (item) {
            return (
              <Item
                currency={currency}
                data-test-item={item.id}
                document={document}
                // @ts-expect-error -- Ember models
                errors={item.errors.content}
                forcePositive={forcePositive}
                hideDiscount={hideDiscount}
                hideUnit={hideUnit}
                index={index}
                isCreditNote={isCreditNote}
                isDraggable
                item={item}
                key={item.uuid}
                onDeleteItem={handleDeleteItem}
                onVatResetDisclaimerDismiss={onVatResetDisclaimerDismiss}
                shouldDisplayInternationalClientVatDisclaimer={
                  shouldDisplayInternationalClientVatDisclaimer
                }
                {...draggableProps}
              />
            );
          }

          return null;
        }}
      </SortableList>
    ) : (
      <>
        {items.map((item: ReceivableInvoiceItemModel, index: number) => (
          <Item
            currency={currency}
            data-test-item={item.id}
            document={document}
            // @ts-expect-error -- Ember models
            errors={item.errors.content}
            forcePositive={forcePositive}
            hideDiscount={hideDiscount}
            hideUnit={hideUnit}
            index={index}
            isCreditNote={isCreditNote}
            isDisabled={isDisabled}
            item={item}
            key={item.uuid}
            onDeleteItem={hasMultipleItems ? handleDeleteItem : undefined}
            onVatResetDisclaimerDismiss={onVatResetDisclaimerDismiss}
            shouldDisplayInternationalClientVatDisclaimer={
              shouldDisplayInternationalClientVatDisclaimer
            }
          />
        ))}
      </>
    );

  if (isDefault) {
    return (
      <div
        className={cx(styles['default-section'], className)}
        data-test-section={section.id}
        {...props}
        ref={ref}
      >
        {itemsContent}
      </div>
    );
  }

  return (
    <div
      className={cx(styles.section, className)}
      data-test-section={section.id}
      {...props}
      ref={ref}
    >
      <TextField
        data-test-section-title-input={section.id}
        isDisabled={isDisabled}
        label={formatMessage({
          id: 'receivable-invoices.invoice-creation.sections.title.label',
        })}
        maxLength={MAX_TITLE_LENGTH}
        name="section_title"
        onChange={handleTitleChange}
        placeholder={formatMessage({
          id: 'receivable-invoices.invoice-creation.sections.title.placeholder',
        })}
        value={getValue('title')}
      />
      <TextAreaField
        data-test-section-description-input={section.id}
        isDisabled={isDisabled}
        isOptional
        label={formatMessage({
          id: 'receivable-invoices.invoice-creation.sections.description.label',
        })}
        maxLength={MAX_DESCRIPTION_LENGTH}
        name="section_description"
        onBlur={handleDescriptionBlur}
        onChange={handleDescriptionChange}
        placeholder={formatMessage({
          id: 'receivable-invoices.invoice-creation.sections.description.placeholder',
        })}
        value={getValue('description')}
      />
      {itemsContent}
      {!isDisabled ? (
        <div className={styles.actions}>
          <Button data-test-add-item-cta={section.id} onPress={handleAddItem} variant="tertiary">
            <IconPlusOutlined className="mr-8" height={16} width={16} />
            {formatMessage({ id: 'receivable-invoices.invoice-creation.add-item.cta' })}
          </Button>
          <Button
            data-test-remove-section-cta={section.id}
            onPress={handleDeleteSection}
            variant="tertiary"
          >
            {formatMessage({ id: 'receivable-invoices.invoice-creation.remove-section.cta' })}
          </Button>
        </div>
      ) : null}
    </div>
  );
}
