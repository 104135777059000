import { type ReactNode, type ReactElement, type Key, useState } from 'react';
import { Button, Select, SelectOption } from '@repo/design-system-kit';
import { FormattedNumber, useIntl } from 'react-intl';
import { useSegment } from '@repo/poly-hooks/segment';
import type { BankAccount } from 'qonto/react/components/riba/sidebar/sidebar';
import styles from './styles.strict-module.css';

interface FooterProps {
  activeAccounts?: BankAccount[];
  defaultAccountId?: string;
  onApprove: (bankAccountId: string) => void;
  onReject: (bankAccountId: string) => void;
  isApproveRunning?: boolean;
  isRejectRunning?: boolean;
}

function formatCurrencyAmount(value: number, currency: string): ReactElement {
  return (
    <FormattedNumber
      currency={currency}
      maximumFractionDigits={2}
      minimumFractionDigits={2}
      style="currency"
      value={value}
    />
  );
}

export function Footer({
  activeAccounts,
  defaultAccountId,
  onApprove,
  onReject,
  isApproveRunning,
  isRejectRunning,
}: FooterProps): ReactNode {
  const intl = useIntl();

  const segment = useSegment();

  const [selectedAccountId, setSelectedAccountId] = useState(defaultAccountId);

  if (!activeAccounts || !defaultAccountId) {
    return null;
  }

  const hasSingleAccount = activeAccounts.length === 1;
  const isActionRunning = isApproveRunning || isRejectRunning;

  const handleApprove = (): void => {
    segment.track('riba_pending_payment_approved');
    if (selectedAccountId) {
      onApprove(selectedAccountId);
    }
  };

  const handleReject = (): void => {
    segment.track('riba_pending_payment_rejected');
    if (selectedAccountId) {
      onReject(selectedAccountId);
    }
  };

  return (
    <div className={styles.footer}>
      <div className={styles.footerSelector}>
        <Select
          data-test-riba-sidebar-footer-select
          isDisabled={isActionRunning}
          isReadOnly={hasSingleAccount}
          label={intl.formatMessage({ id: 'bank_accounts.additional_details.account' })}
          onSelectionChange={(key: Key) => {
            setSelectedAccountId(key as string);
          }}
          placeholder={intl.formatMessage({ id: 'bank_accounts.additional_details.account' })}
          selectedKey={selectedAccountId}
        >
          {activeAccounts.map(bankAccount => (
            <SelectOption className={styles.selectOption} id={bankAccount.id} key={bankAccount.id}>
              <span>{bankAccount.name}</span>
              <span className={styles.bankAccountBalance}>
                {formatCurrencyAmount(bankAccount.balance, bankAccount.balanceCurrency)}
              </span>
            </SelectOption>
          ))}
        </Select>
      </div>
      <div className={styles.footerButtons}>
        <Button
          data-test-riba-sidebar-reject-button
          isDisabled={isActionRunning}
          isLoading={isRejectRunning}
          onPress={handleReject}
          size="small"
          variant="danger"
        >
          {intl.formatMessage({ id: 'riba.sidebar.approval.reject-btn' })}
        </Button>
        <Button
          className={styles.approveButton}
          data-test-riba-sidebar-approve-button
          isDisabled={isActionRunning}
          isLoading={isApproveRunning}
          onPress={handleApprove}
          size="small"
          variant="primary"
        >
          {intl.formatMessage({ id: 'riba.sidebar.approval.approve-btn' })}
        </Button>
      </div>
    </div>
  );
}
