import { type ReactNode } from 'react';
import { Button } from '@repo/design-system-kit';
import { FormattedMessage } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { bulkTransactionsManager } from 'qonto/react/contexts/bulk-transactions-context';
import { useUpdateBulkActions } from 'qonto/react/hooks/use-update-bulk-actions';
import { BULK_PANEL_SUBMIT_CLICK_EVENT_NAME } from 'qonto/react/constants';
import styles from './styles.strict-module.css';

interface FooterProps {
  onClose: () => void;
}

export function Footer({ onClose }: FooterProps): ReactNode {
  const segment = useEmberService('segment');

  const {
    labels: { aggregatedUpdatedLabels },
    verificationStatus: { verificationStatusChanged },
    category: { categoryChanged },
    attachmentStatus: { attachmentStatusChanged },
    requestAttachment: { requestAttachment },
    cashflowCategory: { cashflowCategoryChanged },
    refreshState,
  } = bulkTransactionsManager.useBulkTransactions();
  const { mutate: mutateChangedFields, isPending } = useUpdateBulkActions();
  const labelsChanged = aggregatedUpdatedLabels.length > 0;

  const handleSubmit = (): void => {
    mutateChangedFields(void 0, {
      onSuccess: data => {
        const fieldsToRefresh = data.filter(item => item.success).flatMap(item => item.fields);
        refreshState(fieldsToRefresh);
      },
    });
    segment.track(BULK_PANEL_SUBMIT_CLICK_EVENT_NAME);
  };

  return (
    <div className={styles.footer} data-testid="footer">
      <Button data-testid="cancel-button" onPress={onClose} stretch variant="secondary">
        <FormattedMessage id="btn.discard" />
      </Button>
      <Button
        data-testid="submit-button"
        isDisabled={
          !labelsChanged &&
          !verificationStatusChanged &&
          !categoryChanged &&
          !attachmentStatusChanged &&
          !cashflowCategoryChanged &&
          !requestAttachment
        }
        isLoading={isPending}
        onPress={handleSubmit}
        stretch
      >
        <FormattedMessage id="btn.apply" />
      </Button>
    </div>
  );
}
