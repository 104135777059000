import { ProductBadge } from '@repo/domain-kit/pricing';
import { useSegment } from '@repo/poly-hooks/segment';
import { safeLocalStorage } from '@repo/shared-config/app/helper/safe-local-storage';
import { clsx } from 'clsx';
import type { ReactElement } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Addons } from 'qonto/constants/addons';
import { ADDONS_DISCOVERABILITY_STORAGE_KEY } from 'qonto/constants/price-plan';
import { InstructionalTooltip } from 'qonto/react/components/product-discovery/instructional-tooltip';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './styles.strict-module.css';

interface BillingRecurrenceTranslationsParams {
  recurrence: string;
  monthlyPrice: string;
  annualPrice: string;
}

interface MiniAddonCardProps {
  name: string;
  badgeTitle: string;
  billingRecurrence: string;
  nextBillingDate: string;
  trackingId: string;
  onClick: (trackingId: string) => void;
}

interface AddonSubscription {
  product: {
    localName: string;
    code: Addons;
    monthlyPrice: {
      value: number;
      currency: string;
    };
    annualPrice: {
      value: number;
      currency: string;
    };
  };
  recurrence: string;
  nextSubscriptionBillingDate: string;
}

interface HeaderAddonsOverviewProps {
  addonSubscriptions?: AddonSubscription[];
  onClick: (trackingId: string) => void;
  isLoading: boolean;
}

function MiniAddonCard({
  name,
  trackingId,
  badgeTitle,
  billingRecurrence,
  nextBillingDate,
  onClick,
}: MiniAddonCardProps): React.JSX.Element {
  function handleClick(): void {
    onClick(trackingId);
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>): void {
    if (event.key === 'Enter' || event.key === ' ') {
      handleClick();
    }
  }

  return (
    <div
      className={styles.container}
      data-test-addon-card={name}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
    >
      <div className="mb-8">
        <ProductBadge traits={[{ name: 'active', title: badgeTitle }]} />
      </div>

      <h3 className={clsx(styles.name, 'body-1')} data-test-addon-name>
        {name}
      </h3>

      <div>
        <div className={clsx(styles['color-secondary'], 'caption')} data-test-billing-recurrence>
          {billingRecurrence}
        </div>
        <div className={clsx(styles['color-secondary'], 'caption')} data-test-next-billing-date>
          {nextBillingDate}
        </div>
      </div>
    </div>
  );
}

function EmptyAddonCard({
  onClick,
  shouldDisplayInstructionalTooltip,
}: {
  onClick: (trackingId: string) => void;
  shouldDisplayInstructionalTooltip: boolean;
}): React.JSX.Element {
  const [isDismissed, setIsDismissed] = useState(!shouldDisplayInstructionalTooltip);
  const segment = useSegment();
  const hasTracked = useRef(false);

  const { formatMessage } = useIntl();
  function handleClick(): void {
    onClick('main_card');
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>): void {
    if (event.key === 'Enter' || event.key === ' ') {
      handleClick();
    }
  }

  function handleClose(): void {
    safeLocalStorage.setItem(ADDONS_DISCOVERABILITY_STORAGE_KEY, 'true');
    setIsDismissed(true);
    segment.track('settings_billing_addon-management-tooltip_dismissed');
  }

  useEffect(() => {
    if (!hasTracked.current && shouldDisplayInstructionalTooltip) {
      hasTracked.current = true;
      segment.track('settings_billing_addon-management-tooltip_displayed');
    }
  }, [shouldDisplayInstructionalTooltip, segment]);

  return (
    <div className={clsx(styles.container, styles.empty)}>
      <div
        className={styles.wrapper}
        data-test-empty-card
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
      >
        <StaticThemedAsset
          alt=""
          assetPath="/illustrations/ill-sspot-pricing-addon-management.svg"
          className={styles.illustration}
        />
        <h3 className="body-1" data-test-empty-card-description>
          <FormattedMessage id="subscription.consumption-table.addon-overview.empty-state.title" />
        </h3>
      </div>
      <InstructionalTooltip
        crossOffset={40}
        description={formatMessage({
          id: 'subscription.consumption-table.addon-overview.instructional-tooltip.subtitle',
        })}
        isDismissed={isDismissed}
        isNew
        lottieSrc="/lotties/addon.json"
        onClose={handleClose}
        placement="lower-right"
        title={formatMessage({
          id: 'subscription.consumption-table.addon-overview.instructional-tooltip.title',
        })}
      />
    </div>
  );
}

export function HeaderAddonsOverview({
  addonSubscriptions = [],
  onClick,
  isLoading,
}: HeaderAddonsOverviewProps): ReactElement {
  const { formatMessage, formatNumber, locale } = useIntl();

  function billingRecurrenceTranslations({
    recurrence,
    monthlyPrice,
    annualPrice,
  }: BillingRecurrenceTranslationsParams): string {
    return recurrence === 'monthly'
      ? formatMessage(
          { id: 'subscription.consumption-table.addon-overview.addon-list.monthly-billing' },
          {
            amount: monthlyPrice,
          }
        )
      : formatMessage(
          { id: 'subscription.consumption-table.addon-overview.addon-list.annual-billing' },
          {
            amount: annualPrice,
          }
        );
  }

  function formatPrice(price: number): string {
    return formatNumber(price, {
      currency: 'EUR',
      style: 'currency',
    });
  }

  function formatDate(date: string): string {
    return dateToken({
      date,
      locale,
      token: 'date-year-s',
    });
  }

  const trackingAddonsId = {
    [Addons.AccountsPayable]: 'ap_card',
    [Addons.AccountsReceivable]: 'ar_card',
    [Addons.CashFlowManagement]: 'cfm_card',
    [Addons.ExpenseSpendManagement]: 'e&sm_card',
  };

  const addonsData: MiniAddonCardProps[] = addonSubscriptions.map(
    (addonSubscription: AddonSubscription) => ({
      name: addonSubscription.product.localName,
      trackingId: trackingAddonsId[addonSubscription.product.code],
      billingRecurrence: billingRecurrenceTranslations({
        recurrence: addonSubscription.recurrence,
        monthlyPrice: formatPrice(addonSubscription.product.monthlyPrice.value),
        annualPrice: formatPrice(addonSubscription.product.annualPrice.value),
      }),
      nextBillingDate: formatMessage(
        { id: 'subscription.consumption-table.addon-overview.addon-list.billing-date' },
        {
          date: formatDate(addonSubscription.nextSubscriptionBillingDate),
        }
      ),
      badgeTitle: formatMessage({ id: 'subscription.consumption-table.addon-overview.badge' }),
    })
  ) as MiniAddonCardProps[];

  const shouldDisplayInstructionalTooltip =
    addonSubscriptions.length === 0 &&
    safeLocalStorage.getItem(ADDONS_DISCOVERABILITY_STORAGE_KEY) !== 'true' &&
    !isLoading;

  return (
    <div className={styles.scroll}>
      {addonsData.map(addon => (
        <div key={addon.name}>
          <MiniAddonCard
            badgeTitle={addon.badgeTitle}
            billingRecurrence={addon.billingRecurrence}
            name={addon.name}
            nextBillingDate={addon.nextBillingDate}
            onClick={onClick}
            trackingId={addon.trackingId}
          />
        </div>
      ))}
      <EmptyAddonCard
        onClick={onClick}
        shouldDisplayInstructionalTooltip={shouldDisplayInstructionalTooltip}
      />
    </div>
  );
}
