import { Button } from '@repo/design-system-kit';
import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { IconLightningOutlined } from '@repo/monochrome-icons';
import cx from 'clsx';
import { cashFlowSidePanelManager } from 'qonto/react/contexts/cash-flow-sidepanel-context.tsx';
import styles from './forecast-selector.strict-module.css';

export function ForecastSelector(): ReactNode {
  const { formatMessage } = useIntl();
  const { updateSidepanelMode } = cashFlowSidePanelManager.useCashFlowSidePanel();

  const handleFlashForecastClick = (): void => {
    updateSidepanelMode('flashForecast');
  };

  const handleImportForecastClick = (): void => {
    updateSidepanelMode('importForecast');
  };

  return (
    <div className={styles.wrapper} data-testid="forecast-choice">
      <h4 className="title-4" data-testid="title">
        {formatMessage({ id: 'cash-flow.first-time-experience.sidepanel.forecast-choice.title' })}
      </h4>
      <p className={styles.subheading} data-testid="subtitle">
        {formatMessage({
          id: 'cash-flow.first-time-experience.sidepanel.forecast-choice.subheading',
        })}
      </p>
      <Button
        className={cx(styles.button, 'mb-12')}
        data-testid="flash-forecast-cta"
        onPress={handleFlashForecastClick}
        variant="primary"
      >
        <IconLightningOutlined className={styles.lightningIcon} />
        {formatMessage({
          id: 'cash-flow.first-time-experience.sidepanel.forecast-choice.flash-forecast-cta',
        })}
      </Button>
      <Button
        className={styles.button}
        data-testid="import-forecast-cta"
        onPress={handleImportForecastClick}
        variant="secondary"
      >
        {formatMessage({
          id: 'cash-flow.first-time-experience.sidepanel.forecast-choice.import-forecast-cta',
        })}
      </Button>
    </div>
  );
}
