import { useIntl } from 'react-intl';
import type { ReactElement } from 'react';
import { PriceBreakdown } from '@repo/domain-kit/pricing';
import { Button } from '@repo/design-system-kit';
import type { DataContext } from '../data-context.type';
import { ConfirmDisclaimers } from '../confirm-disclaimers';
import styles from './styles.strict-module.css';

interface ConfirmTotalProps {
  context: DataContext;
  onConfirm: () => void;
  isLoading: boolean;
  isAddonOnFreeTrial: boolean;
}

export function ConfirmTotal({
  context,
  onConfirm,
  isLoading,
  isAddonOnFreeTrial,
}: ConfirmTotalProps): ReactElement {
  const { formatMessage, formatNumber, formatDate } = useIntl();
  const {
    total_estimate: totalEstimate,
    targetAddon,
    targetPricePlan,
    hasInsufficientFunds,
    isAddonBlocked,
    errors,
    warnings,
  } = context;

  const products: { title: string; price: string }[] = [
    ...(totalEstimate.discount_amount.value !== '0'
      ? [
          {
            title: formatMessage({ id: 'subscription.change.confirmation.discount' }),
            price: formatNumber(Number(totalEstimate.discount_amount.value), {
              style: 'currency',
              currency: totalEstimate.discount_amount.currency,
            }),
          },
        ]
      : []),
    {
      title: formatMessage({ id: 'subscription.change.confirmation.vat' }),
      price: formatNumber(Number(totalEstimate.vat_amount.value), {
        style: 'currency',
        currency: totalEstimate.vat_amount.currency,
      }),
    },
  ];

  return (
    <div className={styles.total}>
      <section data-test-addon-total>
        <PriceBreakdown
          billing={formatMessage(
            {
              id: 'subscription.change.confirmation.summary.upsizing.subtitle',
            },
            {
              date: formatDate(totalEstimate.next_billing_date, {
                format: 'long',
              }),
            }
          )}
          price={formatNumber(Number(totalEstimate.vat_included_amount.value), {
            style: 'currency',
            currency: totalEstimate.vat_included_amount.currency,
          })}
          products={products}
          total={formatMessage({ id: 'subscription.change.confirmation.charge' })}
        >
          <ConfirmDisclaimers
            addonName={targetAddon?.productName}
            errors={errors}
            hasInsufficientFunds={hasInsufficientFunds}
            planName={targetPricePlan?.productName}
            warnings={warnings}
          />
        </PriceBreakdown>
      </section>

      <Button
        data-test-addon-change-confirm-next-step
        isDisabled={hasInsufficientFunds || isAddonBlocked || isLoading}
        isLoading={isLoading}
        onPress={onConfirm}
        stretch
        type="button"
        variant="primary"
      >
        {isAddonOnFreeTrial
          ? formatMessage({ id: 'subscription.change.addons.cta.free-trial' })
          : formatMessage({ id: 'btn.confirm' })}
      </Button>
    </div>
  );
}
