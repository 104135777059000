import { type ReactNode } from 'react';
import { Button, Popup } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import { FormattedMessage } from '@repo/domain-kit/shared';
import ENV from 'qonto/config/environment';
import styles from './flash-forecast-confirmation-popup.strict-module.css';

const isTest = ENV.environment === 'test';

interface FlashForecastConfirmationPopupProps {
  isOpen: boolean;
  onPopupCancel: () => void;
  onPopupConfirm: () => void;
}

export function FlashForecastConfirmationPopup({
  isOpen,
  onPopupCancel,
  onPopupConfirm,
}: FlashForecastConfirmationPopupProps): ReactNode {
  const { formatMessage } = useIntl();

  const TEST_PORTAL_CONTAINER =
    document.getElementById('ember-testing') ?? document.querySelector('main') ?? undefined;

  return (
    <Popup.Root
      className={styles['flash-forecast-confirmation-popup']}
      data-testid="flash-forecast-confirmation-popup"
      isOpen={isOpen}
      portalContainer={isTest ? TEST_PORTAL_CONTAINER : undefined}
    >
      <Popup.CloseButton
        data-testid="flash-forecast-confirmation-popup-close-button"
        onPress={onPopupCancel}
      />
      <Popup.Header>
        <Popup.Title data-testid="flash-forecast-confirmation-popup-title">
          <FormattedMessage id="cash-flow.side-panel.forecast.generate.modal.title" />
        </Popup.Title>
        <Popup.Subtitle data-testid="flash-forecast-confirmation-popup-subtitle">
          {formatMessage({ id: 'cash-flow.side-panel.forecast.generate.modal.body' })}
        </Popup.Subtitle>
      </Popup.Header>
      <Popup.Footer>
        <Button
          aria-label={formatMessage({ id: 'a11y.buttons.close-aria-label' })}
          data-testid="flash-forecast-confirmation-popup-cancel-button"
          onPress={onPopupCancel}
          variant="secondary"
        >
          <FormattedMessage id="cash-flow.side-panel.forecast.generate.modal.cancel.cta" />
        </Button>
        <Button
          aria-label={formatMessage({ id: 'a11y.buttons.save-aria-label' })}
          data-testid="flash-forecast-confirmation-popup-confirm-button"
          onPress={onPopupConfirm}
          variant="primary"
        >
          <FormattedMessage id="cash-flow.side-panel.forecast.generate.modal.confirm.cta" />
        </Button>
      </Popup.Footer>
    </Popup.Root>
  );
}
