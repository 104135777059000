import cx from 'clsx';
import React, { useState, type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Disclaimer } from '@repo/design-system-kit';
import { useEmberService, useRouter } from '@qonto/react-migration-toolkit/react/hooks';
import type { TransactionModel } from 'qonto/react/models/transaction';
import { useAssignTransactionsCategory } from 'qonto/react/hooks/use-assign-transactions-category';
import { useFetchCashflowCategories } from 'qonto/react/hooks/use-fetch-cashflow-categories';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import {
  useCategorizationRulesManager,
  useReactCategorizationRulesManager,
} from 'qonto/react/hooks/use-categorization-rules-manager';
import type {
  CashflowActiveCategoryId,
  CashflowCategoryAssignment,
} from 'qonto/react/models/cash-flow-category';
import type { MemorizeCategoriesRule } from 'qonto/react/models/memorize-category';
import { CashflowCategorySelector } from './category-selector';
import styles from './styles.strict-module.css';
import { CashFlowCategoryMemorizeWidget } from './memorize-widget';

/**
 * Two main differences between CashflowCategoryManagerMt (used in the Modular table bulk sidepanel)
 * and CashflowCategoryManager (elsewhere in the ember-data powered sidepanels):
 *
 * 1. Not need the Memorize widget (prop: shouldUseMemorizeWidget)
 * 2. No need to handle optimistic updates in ember-data (prop: renderedInReactOnlyContext)
 *
 * The goal is to keep the components linked to avoid duplicating changes in the future.
 */
interface BaseCashflowCategoryManagerProps {
  activeCategoryId: CashflowActiveCategoryId;
  transactionIds: string[];
  onBulkMutate?: ({
    categoryId,
    callback,
  }: {
    categoryId: string | null;
    callback: () => void;
  }) => void;
  transactions?: TransactionModel[];
  renderedInReactOnlyContext?: boolean;
  shouldUseMemorizeWidget?: boolean;
}

function BaseCashflowCategoryManager({
  activeCategoryId = null,
  transactionIds,
  onBulkMutate,
  renderedInReactOnlyContext = false,
  shouldUseMemorizeWidget = true,
  transactions,
}: BaseCashflowCategoryManagerProps): ReactNode {
  const { formatMessage } = useIntl();
  const router = useRouter();

  const abilities = useEmberService('abilities');
  const disableCategoriesQuery = abilities.cannot('access category');
  const segment = useEmberService('segment');
  const { organization } = useOrganizationManager();
  const categorizationManager = useCategorizationRulesManager();
  const reactCategorizationManager = useReactCategorizationRulesManager();
  const { data: categories, isPending } = useFetchCashflowCategories(disableCategoriesQuery);
  const { mutate } = useAssignTransactionsCategory();

  const canManageCashFlowCategories = abilities.can('manage category');

  const [showMemorizeWidget, setShowMemorizeWidget] = useState(false);
  const isMemorizeWidgetActive =
    canManageCashFlowCategories && showMemorizeWidget && shouldUseMemorizeWidget;

  const isAutoCategorized = (): boolean => {
    if (!transactions || transactions.length > 1) return false;
    return Boolean(
      transactions[0]?.categoryAssignment?.source &&
        !['manual', 'rule'].includes(transactions[0].categoryAssignment.source)
    );
  };

  const onMutate = (categoryId: CashflowActiveCategoryId): void => {
    const assignment: CashflowCategoryAssignment = {
      transactionIds,
      categoryId,
    };

    if (!renderedInReactOnlyContext) {
      // Ember store optimistic update
      categorizationManager.categorizeTransactions(assignment);
    }

    // React query optimistic update
    reactCategorizationManager.categorizeTransactions(assignment);
    if (shouldUseMemorizeWidget) setShowMemorizeWidget(true);
  };

  const handleCategoryChange = (categoryId: CashflowActiveCategoryId): void => {
    const callback = (): void => {
      mutate({ transactionIds, categoryId, onMutate });
    };
    onBulkMutate ? onBulkMutate({ categoryId, callback }) : callback();
  };

  const onCloseMemorizeWidget = (): void => {
    setShowMemorizeWidget(false);
  };

  const onGenerateRules = async (rules: MemorizeCategoriesRule[]): Promise<void> => {
    setShowMemorizeWidget(false);

    // Rule creation & ember store optimistic update
    const storeTransactionIds: string[] =
      await categorizationManager.createCashFlowCategoryRules(rules);

    // React query optimistic update
    reactCategorizationManager.applyRules(rules, storeTransactionIds);
  };

  const handleRedirectToCashflow = (): void => {
    segment.track('transaction_cash-flow-promotion_clicked');
    void router.push(`/organizations/${organization.slug}/forecast`);
  };

  const selectableCategories = categories ?? { inflow: [], outflow: [] };

  return (
    <>
      <CashflowCategorySelector
        activeCategoryId={activeCategoryId}
        categories={selectableCategories}
        isAutoCategorized={isAutoCategorized()}
        isLoading={isPending}
        onSelectionChange={handleCategoryChange}
      />
      {!activeCategoryId ? (
        <Disclaimer.Inline
          className={styles.disclaimer}
          data-testid="cash-flow-category-manager-disclaimer"
        >
          {formatMessage(
            { id: 'transactions.sidebar.cash-flow-categories.disclaimer.text' },
            {
              cashFlow: (
                <button
                  className={cx('body-link', styles['disclaimer-link'])}
                  data-testid="cash-flow-category-manager-link"
                  key="cash-flow-category-manager-link"
                  onClick={handleRedirectToCashflow}
                  type="button"
                >
                  {formatMessage({
                    id: 'transactions.sidebar.cash-flow-categories.disclaimer.link',
                  })}
                </button>
              ),
            }
          )}
        </Disclaimer.Inline>
      ) : null}
      {isMemorizeWidgetActive && transactions ? (
        <CashFlowCategoryMemorizeWidget
          categoryId={activeCategoryId}
          data-testid="cash-flow-category-memorize-widget"
          onClose={onCloseMemorizeWidget}
          onGenerateRules={onGenerateRules}
          transactions={transactions}
        />
      ) : null}
    </>
  );
}

interface CashflowCategoryManagerProps {
  activeCategoryId: CashflowActiveCategoryId;
  transactions: TransactionModel[];
  onBulkMutate?: ({
    categoryId,
    callback,
  }: {
    categoryId: string | null;
    callback: () => void;
  }) => void;
}

export function CashflowCategoryManager({
  activeCategoryId,
  transactions,
  onBulkMutate,
}: CashflowCategoryManagerProps): ReactNode {
  const transactionIds = transactions.map(transaction => transaction.id);
  return (
    <BaseCashflowCategoryManager
      activeCategoryId={activeCategoryId}
      onBulkMutate={onBulkMutate}
      transactionIds={transactionIds}
      transactions={transactions}
    />
  );
}

interface CashflowCategoryManagerMtProps {
  activeCategoryId: CashflowActiveCategoryId;
  transactionIds: string[];
  onBulkMutate?: ({
    categoryId,
    callback,
  }: {
    categoryId: string | null;
    callback: () => void;
  }) => void;
}

export function CashflowCategoryManagerMt({
  activeCategoryId,
  transactionIds,
  onBulkMutate,
}: CashflowCategoryManagerMtProps): ReactNode {
  return (
    <BaseCashflowCategoryManager
      activeCategoryId={activeCategoryId}
      onBulkMutate={onBulkMutate}
      renderedInReactOnlyContext
      shouldUseMemorizeWidget={false}
      transactionIds={transactionIds}
    />
  );
}
