import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useSegment } from '@repo/poly-hooks/segment';
import { useQueryClient } from '@tanstack/react-query';
import type { UpcomingTransaction } from 'qonto/react/hooks/use-upcoming-transactions';
import { useFetchTransfer } from 'qonto/react/hooks/use-fetch-transfer';

export const useRescheduleTransferHandler = (
  upcomingTransaction: UpcomingTransaction | null
): (() => Promise<void>) => {
  const segment = useSegment();
  const type = upcomingTransaction?.provider_object_type;
  const transferId = upcomingTransaction?.provider_object_id;

  const { prefetchTransfer } = useFetchTransfer(transferId ?? '');

  return async () => {
    segment.track('compass_action_clicked', {
      action: 'reschedule_transfer',
      type,
    });
    await prefetchTransfer();
  };
};

export const useRescheduleTransfer = (): {
  updateTransfer: (params: {
    transferId: string;
    newDate: string | null;
    type: string;
    counterpartyName: string;
  }) => Promise<void>;
} => {
  const segment = useSegment();
  const queryClient = useQueryClient();
  const upcomingTransactionsManager = useEmberService('upcoming-transactions-manager');

  const updateTransfer = async ({
    transferId,
    newDate,
    type,
    counterpartyName,
  }: {
    transferId: string;
    newDate: string | null;
    type: string;
    counterpartyName: string;
  }): Promise<void> => {
    segment.track('compass_action_clicked', {
      action: 'reschedule_transfer_submit',
      type,
    });

    const transfer = queryClient.getQueryData(['transfer', transferId]);

    if (transfer) {
      try {
        await upcomingTransactionsManager.handleUpdateSensitiveTransfer.perform(transfer, {
          scheduled_date: newDate,
          name: counterpartyName,
        });

        await queryClient.invalidateQueries({ queryKey: ['upcoming-transactions'] });
      } catch (error) {
        throw Error(`Transfer ${transferId} could not be updated`);
      }
    }
  };

  return { updateTransfer };
};
