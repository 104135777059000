import type { ReactNode } from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import cx from 'clsx';
import styles from './styles.strict-module.css';

export function MemberPlaceholder(): ReactNode {
  return (
    <div className={cx(styles.details, styles['details--ff'])}>
      <div className={styles.headerContainer}>
        <SkeletonLoader.Block
          borderRadius="50%"
          className={styles.icon}
          data-test-member-loading-block=""
          height="48px"
          width="48px"
        />
        <SkeletonLoader.Header
          data-test-member-loading-header=""
          lines={1}
          smallLinesWidth="381px"
          width="268px"
        />
      </div>

      <div className={styles.lineContainer}>
        <SkeletonLoader.Line data-test-member-loading-line="" standalone width="168px" />
      </div>

      <div>
        <SkeletonLoader.Line
          className={styles.infoLine}
          data-test-member-loading-line=""
          width="448px"
        />
        <SkeletonLoader.Line
          className={styles.infoLine}
          data-test-member-loading-line=""
          width="390px"
        />
        <SkeletonLoader.Line
          className={styles.infoLine}
          data-test-member-loading-line=""
          width="390px"
        />
        <SkeletonLoader.Line
          className={styles.infoLine}
          data-test-member-loading-line=""
          width="390px"
        />
      </div>
    </div>
  );
}
