import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Counterparty } from '@repo/domain-kit/shared';
import type { CellContext } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { type UpcomingTransaction } from 'qonto/react/hooks/use-upcoming-transactions';

export function TransactionCounterpartyCell({
  info,
}: {
  info: CellContext<UpcomingTransaction, unknown>;
}): ReactNode {
  const { formatMessage } = useIntl();
  const type = info.row.original.provider_object_type;
  const transactionTypeMapping: Record<string, string> = {
    supplier_invoice: 'transactions.operation-types.supplier-invoice',
    receivable_invoice: 'transactions.operation-types.client-invoice',
    subscription: 'transactions.operation-types.subscription',
    transfer: 'transactions.operation-types.transfer',
    direct_debit: 'transactions.operation-types.direct-debit',
    check: 'transactions.operation-types.cheque',
    fee: 'transactions.operation-types.biller',
    taxes: 'transactions.operation-types.tax',
    manual_entry: 'transactions.operation-types.manual-entry',
  };

  let subtitle = '';

  // Check if transaction has supplier_invoice_id in metadata
  if (info.row.original.metadata?.supplier_invoice_id && type === 'transfer') {
    subtitle =
      formatMessage({ id: 'transactions.operation-types.transfer' }) +
      formatMessage({ id: 'transactions.operation-types.from-supplier' });
  } else {
    subtitle = transactionTypeMapping[type]
      ? formatMessage({ id: transactionTypeMapping[type] })
      : type;
  }

  const imageUrl = info.row.original.metadata?.logo_url;
  const scheduledTransactionTypes = new Set(['transfer', 'direct_debit', 'credit_transfer']);
  const isScheduled = scheduledTransactionTypes.has(type);

  // Create a formatted date that will match what we get from the chart
  const formattedDueDate = dayjs(info.row.original.due_date).format('YYYY-MM-DD');

  return (
    <Counterparty
      data-due-date={formattedDueDate}
      imageUrl={imageUrl}
      isScheduled={isScheduled}
      subtitle={subtitle}
      title={info.row.original.counterparty_name}
    />
  );
}
