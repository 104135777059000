import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { CardLevel } from '@repo/cards-kit/constants';
import { Badges } from '@repo/cards-kit/components';
import { ExternalLink } from '@repo/design-system-kit';
import { CARD_FEES, PLUS_CARD_WITHDRAWALS_INCLUDED } from 'qonto/constants/cards';
import { ChooseCard } from 'qonto/react/cards/components/flows/choose-card/choose-card';
import styles from './plus-card.strict-module.css';
import type { CardSelectorCoreProps } from './card-selector-core-props.interface';

export function PlusCard({
  assetProps,
  cardsMaxLimits,
  cardEstimates,
  ctaProps,
  layoutProps,
}: CardSelectorCoreProps): ReactNode {
  const { formatNumber, formatMessage } = useIntl();

  const features = [
    formatMessage(
      {
        id: 'cards.steps.choose-card.features.payment',
      },
      {
        amount: formatNumber(cardsMaxLimits.extended_payment_monthly_limit_maximum, {
          minimumFractionDigits: 0,
          currency: 'EUR',
          style: 'currency',
        }),
      }
    ),
    formatMessage(
      {
        id: 'cards.steps.choose-card.features.withdrawal',
      },
      {
        amount: formatNumber(cardsMaxLimits.atm_monthly_limit_maximum, {
          minimumFractionDigits: 0,
          currency: 'EUR',
          style: 'currency',
        }),
      }
    ),
    formatMessage(
      { id: 'cards.steps.choose-card.features.withdrawal-fees.plus' },
      { count: PLUS_CARD_WITHDRAWALS_INCLUDED }
    ),
    formatMessage(
      { id: 'cards.steps.choose-card.features.fx-rates.plus' },
      { percentage: formatNumber(CARD_FEES.plus.foreignFees, { style: 'percent' }) }
    ),
    formatMessage({ id: 'cards.steps.choose-card.features.print-type.plus' }),
    <ExternalLink
      className={styles['feature-link']}
      href={formatMessage({ id: 'cards.steps.choose-card.features.insurance-link' })}
      key={formatMessage({ id: 'cards.steps.choose-card.features.insurance-link' })}
      rel="noopener noreferrer"
      target="_blank"
    >
      {formatMessage({ id: 'cards.steps.choose-card.features.insurance.plus' })}
    </ExternalLink>,
  ];

  return (
    <ChooseCard
      assetProps={assetProps}
      cardLevel={CardLevel.Plus}
      ctaProps={ctaProps}
      features={features}
      headerProps={{
        badge: <Badges.Recycled data-test-recycled-badge-text="" />,
        cardEstimates,
        subtitle: formatMessage({ id: 'cards.steps.choose-card.subtitle.plus' }),
      }}
      layoutProps={layoutProps}
    />
  );
}
