import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { IconDownloadOutlined } from '@repo/monochrome-icons';
import { useSegment } from '@repo/poly-hooks/segment';
import { Button } from '@repo/design-system-kit';
import type { F24Order } from 'qonto/react/f24/types';
import styles from './footer.strict-module.css';

interface F24SidebarFooterProps {
  order: F24Order;
  closeSidebar: () => void;
}

export function F24SidebarFooter({ order, closeSidebar }: F24SidebarFooterProps): ReactNode {
  const segment = useSegment();
  const modals = useEmberService('modals');

  const handleDownload = (): void => {
    window.open(order.receiptUrl);
    segment.track('f24_details_receipt-downloaded');
  };

  const cancelPayment = (): void => {
    const f24Order = order;

    modals.open('f24/cancel-confirmation-modal', {
      f24Order,
      closeSidebar,
    });
  };

  if (order.hasReceipt)
    return (
      <Button
        className={styles['download-button']}
        data-test-f24-sidebar-footer-download-button
        onPress={handleDownload}
        stretch
        variant="secondary"
      >
        <IconDownloadOutlined className="mr-8" />
        <FormattedMessage id="f24.archive.sidepanel.download-cta" />
      </Button>
    );

  if (order.isCancelable)
    return (
      <Button
        data-test-f24-sidebar-footer-cancel-button
        onPress={cancelPayment}
        stretch
        variant="danger"
      >
        <FormattedMessage id="f24.archive.sidepanel.cancel-cta" />
      </Button>
    );

  return null;
}
