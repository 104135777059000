import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import type IntlService from 'ember-intl/services/intl';
import { Value } from '../value';

interface CountryValueProps {
  value?: string;
  warningLevel?: 'error' | 'warning';
}

interface QontoIntlService extends IntlService {
  formatCountry: (value: string) => string;
}

export function CountryValue({
  value,
  warningLevel,
  ...props
}: CountryValueProps): React.ReactNode {
  const intl = useEmberService('intl') as QontoIntlService;

  const country = value ? intl.formatCountry(value) : '';

  return (
    <Value missing={!value} warningLevel={warningLevel} {...props}>
      {country}
    </Value>
  );
}
