import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { IconCheckmarkOutlined } from '@repo/monochrome-icons';
import styles from './invoice-instructions.strict-module.css';

export function InvoiceInstructions(): ReactNode {
  const list = [
    <FormattedMessage id="financing.pay-later.upload-invoice.bullet-1" key="1" />,
    <FormattedMessage id="financing.pay-later.upload-invoice.bullet-2" key="2" />,
    <FormattedMessage id="financing.pay-later.upload-invoice.bullet-3" key="3" />,
  ];

  return (
    <section className={styles.instructions} data-test-invoice-instructions>
      <p className="body-1 mb-8" data-test-title>
        <FormattedMessage id="financing.pay-later.upload-invoice.subtitle" />
      </p>
      <ul className={styles.list} data-test-list>
        {list.map(item => (
          <li key={item.key}>
            <IconCheckmarkOutlined className={styles.icon} height={12} width={12} />
            {item}
          </li>
        ))}
      </ul>
    </section>
  );
}
