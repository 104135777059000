/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import { type ReactNode } from 'react';
import cx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { BadgeHighlight, ProgressBar } from '@repo/design-system-kit';
import { IconInfoRoundedFilled } from '@repo/monochrome-icons';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import styles from './main.strict-module.css';

interface RemuneratedAccountMainProps {
  accountDetails: {
    isTaxApplicable: boolean;
    interestRates: {
      rate: string;
      startDay: number;
      endDay: number;
    }[];
    maxMaturityEarnings: {
      value: number;
      currency: string;
    };
    startDate: string;
    endDate: string;
    maxMaturity: number;
    currentMaturity: number;
  };
  accountName: string;
}

interface InterestColumn {
  rate: string;
  startDay: number;
  endDay: number;
}

export function RemuneratedAccountMain({
  accountDetails,
  accountName,
}: RemuneratedAccountMainProps): ReactNode {
  const { formatMessage, formatNumber, locale } = useIntl();
  const isGermanCompany = accountDetails.isTaxApplicable;
  const maxMaturityRate = parseFloat(accountDetails.interestRates.at(-1)?.rate ?? '0') / 100;

  const tableData = accountDetails.interestRates
    .slice(0, 6)
    .reduce<{ left: InterestColumn; right: InterestColumn }[]>((result, current, index, array) => {
      if (index < 3) {
        const left = { ...current };
        const right = {
          rate: array[index + 3]?.rate ?? '0',
          startDay: array[index + 3]?.startDay ?? 0,
          endDay: array[index + 3]?.endDay ?? 0,
        };

        const leftRate = parseFloat(left.rate);
        left.rate = formatNumber(leftRate / 100, {
          minimumFractionDigits: leftRate === 0 ? 0 : 2,
          style: 'percent',
        });

        const rightRate = parseFloat(right.rate);
        right.rate = formatNumber(rightRate / 100, {
          minimumFractionDigits: rightRate === 0 ? 0 : 2,
          style: 'percent',
        });

        result.push({
          left,
          right,
        });
      }
      return result;
    }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.cardHeader}>
        <p className="title-3" data-test-account-name>
          {accountName}
        </p>
        <BadgeHighlight
          className={styles.badge}
          data-test-remunerated-badge-remunerated=""
          level="secondary"
          text={formatMessage({ id: 'accounts.remunerated.badge.remunerated' })}
        />
      </div>

      <div className={styles.coreInformation}>
        <div className={cx(styles.estimatedEarnings, 'mb-24')}>
          <p className="title-4" data-test-details-title>
            <FormattedMessage id="account.remunerated.details.title" />
          </p>
          <p className="title-3" data-test-details-earnings>
            {formatNumber(accountDetails.maxMaturityEarnings.value, {
              style: 'currency',
              currency: accountDetails.maxMaturityEarnings.currency,
            })}
          </p>

          <p className={cx('caption', styles.subtitleCaption)} data-test-details-subtitle>
            <FormattedMessage
              id="account.remunerated.details.subtitle"
              values={{
                maxMaturityRate: formatNumber(maxMaturityRate, {
                  style: 'percent',
                  maximumFractionDigits: 2,
                }),
                maxMaturity: accountDetails.maxMaturity,
              }}
            />
          </p>
        </div>

        <div className={cx(styles.progressBar, 'mb-16')}>
          <div className={cx(styles.progressBarText, 'mb-8')}>
            <p className="body-1" data-test-progression-text>
              <FormattedMessage id="accounts.remunerated.card.progression" />
            </p>
            <p className="body-1" data-test-progression-numbers>
              <FormattedMessage
                id="accounts.remunerated.card.progression-numbers"
                values={{
                  currentMaturity: accountDetails.currentMaturity,
                  maxMaturity: accountDetails.maxMaturity,
                }}
              />
            </p>
          </div>
          <ProgressBar
            className={cx('purple', styles.progress)}
            maxValue={accountDetails.maxMaturity}
            minValue={0}
            value={accountDetails.currentMaturity}
          />
        </div>

        <p className={cx('body-1', isGermanCompany && 'mb-24')} data-test-details-dates>
          {dateToken({
            locale,
            date: accountDetails.startDate,
            token: 'date-year-l',
          })}
          <span aria-hidden="true" className={styles.separator}>
            {' '}
            &middot;{' '}
          </span>
          {dateToken({
            locale,
            date: accountDetails.endDate,
            token: 'date-year-l',
          })}
        </p>

        {isGermanCompany ? (
          <div className={styles.germanDisclaimer}>
            <IconInfoRoundedFilled />
            <p className={cx('body-1', styles.subtitle)} data-test-details-disclaimer>
              <FormattedMessage id="account.remunerated.additional-details.german-disclaimer" />
            </p>
          </div>
        ) : null}
      </div>

      <div className={styles.secondaryInformation}>
        <p className="title-4 mb-8" data-test-additional-details-title>
          <FormattedMessage id="account.remunerated.additional-details.title" />
        </p>
        <p className={cx(styles.subtitle, 'body-1')} data-test-additional-details-subtitle>
          <FormattedMessage
            id="account.remunerated.additional-details.subtitle"
            values={{
              maxMaturity: accountDetails.maxMaturity,
            }}
          />
        </p>
      </div>

      <article className={styles.remunerationTable} data-test-remuneration-chart>
        {tableData.map((data, index) => (
          <section className={styles.remunerationLine} key={data.left.startDay}>
            <span className={styles.table}>
              <p className="body-1" data-test-details-start-end-left={index}>
                <FormattedMessage
                  id="account.remunerated.additional-details.remuneration-dates"
                  values={{
                    startDay: data.left.startDay,
                    endDay: data.left.endDay,
                  }}
                />
              </p>
              <p className="body-1" data-test-details-rate-left={index}>
                {data.left.rate}
              </p>
            </span>
            <span className={cx(styles.table, styles.tableRight)}>
              <p className="body-1" data-test-details-start-end-right={index}>
                <FormattedMessage
                  id="account.remunerated.additional-details.remuneration-dates"
                  values={{
                    startDay: data.right.startDay,
                    endDay: data.right.endDay,
                  }}
                />
              </p>
              <p className="body-1" data-test-details-rate-right={index}>
                {data.right.rate}
              </p>
            </span>
          </section>
        ))}
      </article>
    </div>
  );
}
