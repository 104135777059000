import type { ReactElement } from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './styles.strict-module.css';

interface FrenchEInvoicingStatusLoadingProps {
  higher?: boolean;
}

export function FrenchEInvoicingStatusLoading({
  higher = false,
}: FrenchEInvoicingStatusLoadingProps): ReactElement {
  return (
    <div className={styles.container} data-testid="einvoicing-settings-status-loading">
      <div className={styles.headerContainer}>
        <SkeletonLoader.Block borderRadius="36px" height="8px" width="180px" />
        <SkeletonLoader.Block borderRadius="4px" height="24px" width="79px" />
      </div>
      <div className={styles.bodyContainer}>
        <SkeletonLoader.Block borderRadius="36px" height="8px" width="100%" />
        <SkeletonLoader.Block borderRadius="36px" height="8px" width="100%" />
        {higher ? (
          <>
            <SkeletonLoader.Block borderRadius="4px" height="24px" width="100%" />
            <SkeletonLoader.Block borderRadius="4px" height="24px" width="79px" />
          </>
        ) : null}
      </div>
    </div>
  );
}
