import { type HTMLAttributes, type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { BadgeStatus, Button, type StatusType } from '@repo/design-system-kit';
import cx from 'clsx';
import type { FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS } from 'qonto/constants/french-einvoicing-settings';
import { EInvoicingAddress } from 'qonto/react/components/e-invoicing/address-field';
import styles from './styles.strict-module.css';

type StatusOption = keyof typeof FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS;

interface FrenchEinvoicingStatusProps extends HTMLAttributes<HTMLDivElement> {
  ctaData?: {
    text: string;
    type?: 'primary' | 'tertiary';
    action: () => void;
    isLoading?: boolean;
  };
  status: {
    value: StatusOption;
    level: StatusType;
    label: string;
  };
  subtitle: string;
  title: string;
  address?: string;
}

export function FrenchEinvoicingStatus({
  className,
  ctaData,
  status,
  subtitle,
  title,
  address,
  ...rest
}: FrenchEinvoicingStatusProps): ReactNode {
  const { formatMessage } = useIntl();
  const intl = useIntl();

  return (
    <div
      className={cx(styles.container, className)}
      data-testid="fr-einvicing-status-container"
      {...rest}
    >
      <div
        className={styles['header-container']}
        data-testid="fr-einvicing-status-header-container"
      >
        <p className="body-1" data-testid="fr-einvicing-status-title">
          {title}
        </p>
        <BadgeStatus level={status.level} text={formatMessage({ id: status.label })} />
      </div>
      <div className={styles['body-container']} data-testid="fr-einvicing-status-body-container">
        <p
          className="body-2"
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({ id: subtitle }),
          }}
          data-testid="fr-einvicing-status-subtitle"
        />
        {address ? (
          <EInvoicingAddress
            address={address}
            trackEventName="e-invoicing-settings_address_copied"
          />
        ) : null}
        {ctaData ? (
          <Button
            className={styles['cta-button']}
            data-testid="fr-einvicing-status-btn"
            isLoading={ctaData.isLoading}
            onPress={ctaData.action}
            variant={ctaData.type ? ctaData.type : 'primary'}
          >
            {ctaData.text}
          </Button>
        ) : null}
      </div>
    </div>
  );
}
