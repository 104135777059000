import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { Button } from '@repo/design-system-kit';
import { IconCrossOutlined } from '@repo/monochrome-icons';
import styles from './header.strict-module.css';

interface HeaderProps {
  title: string;
  isTerminatedPayment?: boolean;
  isCompletedPayment?: boolean;
  onClose: () => void;
}

export function Header({
  title,
  isTerminatedPayment,
  isCompletedPayment,
  onClose,
}: HeaderProps): ReactNode {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.header}>
      <h1
        className={cx(
          styles.title,
          isTerminatedPayment && styles.error,
          isCompletedPayment && styles.success,
          'body-1'
        )}
        data-test-nrc-payment-sidebar-header-title
      >
        {title}
      </h1>
      <Button
        aria-label={formatMessage({ id: 'a11y.buttons.close-aria-label' })}
        className={cx(styles.close, 'btn btn--icon-only btn--tertiary')}
        data-test-nrc-payment-sidebar-close
        iconOnly
        onPress={onClose}
        variant="tertiary"
      >
        <IconCrossOutlined aria-hidden="true" />
      </Button>
    </div>
  );
}
