import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { CardLevel } from '@repo/cards-kit/constants';
import { Badges } from '@repo/cards-kit/components';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks/use-ember-service';
import { ExternalLink } from '@repo/design-system-kit';
import { CARD_FEES } from 'qonto/constants/cards';
import { ChooseCard } from 'qonto/react/cards/components/flows/choose-card/choose-card';
import styles from './metal-card.strict-module.css';
import type { CardSelectorCoreProps } from './card-selector-core-props.interface';

export function MetalCard({
  assetProps,
  cardsMaxLimits,
  cardEstimates,
  ctaProps,
  layoutProps,
}: CardSelectorCoreProps): ReactNode {
  const abilities = useEmberService('abilities');
  const { formatNumber, formatMessage } = useIntl();

  const features = [
    formatMessage(
      { id: 'cards.steps.choose-card.features.payment' },
      {
        amount: formatNumber(cardsMaxLimits.extended_payment_monthly_limit_maximum, {
          minimumFractionDigits: 0,
          currency: 'EUR',
          style: 'currency',
        }),
      }
    ),
    formatMessage(
      { id: 'cards.steps.choose-card.features.withdrawal' },
      {
        amount: formatNumber(cardsMaxLimits.atm_monthly_limit_maximum, {
          minimumFractionDigits: 0,
          currency: 'EUR',
          style: 'currency',
        }),
      }
    ),
    formatMessage({ id: 'cards.steps.choose-card.features.withdrawal-fees.metal' }),
    formatMessage(
      { id: 'cards.steps.choose-card.features.fx-rates.metal' },
      {
        percentage: formatNumber(CARD_FEES.metal.foreignFees, {
          style: 'percent',
        }),
      }
    ),
    formatMessage({ id: 'cards.steps.choose-card.features.print-type.metal' }),
    <ExternalLink
      className={styles['feature-link']}
      href={formatMessage({ id: 'cards.steps.choose-card.features.insurance-link' })}
      key={formatMessage({ id: 'cards.steps.choose-card.features.insurance-link' })}
      rel="noopener noreferrer"
      target="_blank"
    >
      {formatMessage({ id: 'cards.steps.choose-card.features.insurance.metal' })}
    </ExternalLink>,
    ...(abilities.can('access during geo expansion concierge-and-lounge')
      ? [
          formatMessage({ id: 'cards.steps.choose-card.features.lounge' }),
          formatMessage({ id: 'cards.steps.choose-card.features.concierge' }),
        ]
      : []),
  ];

  return (
    <ChooseCard
      assetProps={assetProps}
      cardLevel={CardLevel.Metal}
      className={styles['metal-design']}
      ctaProps={ctaProps}
      features={features}
      headerProps={{
        badge: <Badges.Metal data-test-metal-badge="" type="light" />,
        cardEstimates,
        subtitle: formatMessage({ id: 'cards.steps.choose-card.subtitle.metal' }),
      }}
      layoutProps={layoutProps}
    />
  );
}
