import type { ReactNode } from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './imported.strict-module.css';

export function Imported({ ...props }): ReactNode {
  return (
    <div className={styles['placeholder-sidebar']} {...props} data-test-sidebar-placeholder>
      <section className={styles.details}>
        <div className={styles['section-content']}>
          <div className={`${styles.section} ${styles['header-section']}`}>
            <div className={styles['header-title']}>
              <SkeletonLoader.Line width="130px" />
            </div>
            <div className={styles['header-item']}>
              <SkeletonLoader.Line width="79px" />
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.item}>
              <SkeletonLoader.Line width="132px" />
            </div>
          </div>
          <div className={`${styles.section} ${styles['content-gap']}`}>
            <div className={styles.item}>
              <SkeletonLoader.Line width="163px" />
            </div>
            <div className={styles.item}>
              <SkeletonLoader.Line width="155px" />
            </div>
          </div>
          <div className={styles.section}>
            <div className={`${styles.item} ${styles['center-align']}`}>
              <SkeletonLoader.Line width="103px" />
            </div>
          </div>
          <div className={styles.section}>
            <div className={`${styles.item} ${styles['center-align']}`}>
              <SkeletonLoader.Line width="103px" />
            </div>
          </div>
        </div>
        <div className={styles['cta-footer']}>
          <SkeletonLoader.Block borderRadius="4px" height="32px" width="195px" />
          <SkeletonLoader.Block borderRadius="4px" height="32px" width="195px" />
        </div>
      </section>
    </div>
  );
}
