import type { ReactNode } from 'react';
import { ComboChart, type ComboChartProps } from '@repo/domain-kit/cashflow';
import styles from './styles.strict-module.css';
import { useUpcomingTransactionsContext } from './upcoming-transactions-context';

export function UpcomingTransactionsChart({
  data,
  onPeriodClick,
  isLoading = false,
  onHover,
  adjustedOutflowAmount,
  adjustedOutflowDate,
}: ComboChartProps): ReactNode {
  const { state } = useUpcomingTransactionsContext();
  const { scheduledTransactionPreview } = state;

  const highlightedIndex = data.findIndex(
    item => item.date === scheduledTransactionPreview?.newDate
  );

  return (
    <div className={styles.chartContainer}>
      <ComboChart
        adjustedOutflowAmount={adjustedOutflowAmount}
        adjustedOutflowDate={adjustedOutflowDate}
        data={data}
        highlightedIndex={highlightedIndex}
        isLoading={isLoading}
        onHover={onHover}
        onPeriodClick={onPeriodClick}
        scheduledTransactionPreview={scheduledTransactionPreview}
        visibleTicks={[0, data.length - 1]}
      />
    </div>
  );
}
