import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { FlatAssets } from '@repo/cards-kit/assets';
import styles from './styles.strict-module.css';

export function VirtualCard(): ReactNode {
  return (
    <div className={`body-1 ${styles['cell-content']}`} data-test-request-cell-virtual-card>
      <img
        alt=""
        className={styles['card-icon']}
        data-test-request-cell-virtual-card-icon
        src={FlatAssets.CardVirtualDefault2017Flat}
      />
      <FormattedMessage id="cards.fullname.virtual" />
    </div>
  );
}
