import type { ReactElement } from 'react';
import cx from 'clsx';
import { Disclaimer, LottiePlayer } from '@repo/design-system-kit';
import { FormattedMessage } from 'react-intl';
import styles from './success.strict-module.css';

interface FlowsCheckSuccessProps {
  handleNext: () => void;
  restart: () => void;
  shippingAddress: string;
}

export function FlowsCheckSuccess({
  handleNext,
  restart,
  shippingAddress,
}: FlowsCheckSuccessProps): ReactElement {
  return (
    <div className={cx(styles.success, 'mb-24')}>
      <div className={styles.container}>
        <div className={cx(styles.icon, 'mb-16')} data-test-new-check-success-lottie>
          <LottiePlayer data-test-create-icon="" loop={false} path="/lotties/check/check.json" />
        </div>

        <h2 className="mb-16 title-2" data-test-new-check-form-success-title>
          <FormattedMessage id="checks.success.title" />
        </h2>

        <p
          className={cx(styles.subtitle, 'body-2 mb-16')}
          data-test-new-check-form-success-subtitle
        >
          <FormattedMessage id="checks.success.subtitle" />
        </p>

        <address
          className={cx(styles['send-address'], 'mb-16 body-1')}
          data-test-new-check-form-success-send-address
        >
          <p className={cx(styles.instructions, 'caption mb-8')}>
            <FormattedMessage id="checks.success.instructions" />
          </p>
          <p className="body-1" data-test-shipping-address>
            {shippingAddress}
          </p>
        </address>

        <Disclaimer.Block className="mb-32" data-test-new-check-form-success-user-advice="">
          <FormattedMessage id="checks.success.user_advice" />
        </Disclaimer.Block>

        <button
          className="btn btn--primary mb-24"
          data-test-new-check-success-continue
          onClick={handleNext}
          type="button"
        >
          <FormattedMessage id="checks.success.close_btn" />
        </button>

        <button
          className="btn btn--tertiary"
          data-test-new-check-success-restart
          onClick={restart}
          type="button"
        >
          <FormattedMessage id="checks.success.cash_new_check" />
        </button>
      </div>
    </div>
  );
}
