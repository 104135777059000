import { Button } from '@repo/design-system-kit';
import { useContext, useState, type ReactNode, type RefAttributes } from 'react';
import { useSegment } from '@repo/poly-hooks/segment';
import {
  Dialog,
  DialogTrigger,
  Popover,
  Button as AriaButton,
  OverlayTriggerStateContext,
  type ButtonProps,
  type PressEvent,
} from 'react-aria-components';
import { useIntl } from 'react-intl';
import { IconDotsOutlined, IconPenOutlined } from '@repo/monochrome-icons';
import { TrashOutlined } from 'qonto/react/assets/icons/trash-outlined';
import {
  useDeleteCashFlowCategory,
  useDeleteCashFlowSubcategory,
} from 'qonto/react/hooks/use-delete-cash-flow-category';
import type { CashflowCategorySide } from 'qonto/react/models/cash-flow-category';
import { useCategoriesManagementRouting } from '../hooks/use-categories-management-routing';
import styles from './category-item.strict-module.css';
import { ConfirmDeletionPopup } from './confirm-deletion-popup';

interface CategoryItemMenuProps {
  isSubcategory: boolean;
  categoryId: string;
  parentCategoryId: string;
  type: CashflowCategorySide;
}

export function CategoryItemMenu({
  isSubcategory,
  categoryId,
  parentCategoryId,
  type,
}: CategoryItemMenuProps): ReactNode {
  const segment = useSegment();

  const { formatMessage } = useIntl();
  const { navigateToCategoryDetails } = useCategoriesManagementRouting();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { mutate: categoryDeletionMutation } = useDeleteCashFlowCategory();
  const { mutate: subcategoryDeletionMutation } = useDeleteCashFlowSubcategory();

  const editButtonText = isSubcategory
    ? formatMessage({ id: 'categories-management.edit-subcategory.tooltip' })
    : formatMessage({ id: 'categories-management.edit-category.tooltip' });

  const deleteButtonText = isSubcategory
    ? formatMessage({ id: 'categories-management.delete-subcategory.modal.confirm.cta' })
    : formatMessage({ id: 'categories-management.delete-category.modal.confirm.cta' });

  const handleEditClick = (): void => {
    navigateToCategoryDetails(categoryId);
  };

  const handleDeleteClick = (): void => {
    setIsPopupOpen(true);
  };

  const handleDeleteCancellation = (): void => {
    const isCategory = !isSubcategory;
    const eventToTrack = `cash-flow-categories_${isCategory ? 'category' : 'subcategory'}_delete_canceled`;
    segment.track(eventToTrack);
    setIsPopupOpen(false);
  };

  const handleDeleteConfirmation = (): void => {
    const isCategory = !isSubcategory;
    const eventToTrack = `cash-flow-categories_${isCategory ? 'category' : 'subcategory'}_delete_confirmed`;
    segment.track(eventToTrack);

    setIsPopupOpen(false);

    if (isCategory) {
      categoryDeletionMutation({ categoryId, type });
    } else {
      subcategoryDeletionMutation({
        categoryId: parentCategoryId,
        subcategoryId: categoryId,
        type,
      });
    }
  };

  return (
    <div>
      <DialogTrigger>
        <Button data-testid="category-menu" iconOnly size="small" variant="tertiary">
          <IconDotsOutlined height={16} width={16} />
        </Button>
        <Popover
          className={styles['menu-popover']}
          data-testid="category-menu-popover"
          placement="bottom right"
        >
          <Dialog className={styles['menu-dialog']}>
            <MenuItem
              className={styles['menu-item']}
              data-testid="category-menu-edit-button"
              onPress={handleEditClick}
            >
              <div className={styles['menu-item-content']}>
                <IconPenOutlined />
                {editButtonText}
              </div>
            </MenuItem>
          </Dialog>

          <Dialog className={styles['menu-dialog']}>
            <MenuItem
              className={styles['menu-item']}
              data-testid="category-menu-delete-button"
              onPress={handleDeleteClick}
            >
              <div className={`${styles['menu-item-content']} ${styles.delete}`}>
                <TrashOutlined />
                {deleteButtonText}
              </div>
            </MenuItem>
          </Dialog>
        </Popover>
      </DialogTrigger>
      <ConfirmDeletionPopup
        isOpen={isPopupOpen}
        onPopupCancel={handleDeleteCancellation}
        onPopupConfirm={handleDeleteConfirmation}
        type={isSubcategory ? 'subcategory' : 'category'}
      />
    </div>
  );
}

interface MenuItemProps extends ButtonProps, RefAttributes<HTMLButtonElement> {
  onPress: (e: PressEvent) => void;
}
function MenuItem({ children, onPress, ...props }: MenuItemProps): ReactNode {
  const triggerState = useContext(OverlayTriggerStateContext);
  return (
    <AriaButton
      className={styles['menu-item']}
      onPress={e => {
        triggerState?.close();
        onPress(e);
      }}
      {...props}
    >
      {children}
    </AriaButton>
  );
}
