import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { supplierInvoiceNamespace } from 'qonto/constants/hosts';
import { useFetchApi, type FetchFunction } from 'qonto/react/hooks/use-fetch-api';

export interface UpdateSupplierInvoicePayload {
  supplierInvoiceId: string;
  updatedFields: {
    due_date?: string;
  };
  counterpartyName?: string;
}

export const updateSupplierInvoice = async (
  fetchApi: FetchFunction,
  payload: UpdateSupplierInvoicePayload
): Promise<void> => {
  const { supplierInvoiceId, updatedFields } = payload;

  const response = await fetchApi(
    `${supplierInvoiceNamespace}/supplier_invoices/${supplierInvoiceId}`,
    {
      method: 'PATCH',
      body: JSON.stringify({ supplier_invoice: updatedFields }),
    }
  );

  if (!response.ok)
    throw Error(
      `[HTTP status: ${response.status}] Supplier invoice ${supplierInvoiceId} could not be updated`
    );
};

export const useUpdateSupplierInvoice = (): UseMutationResult<
  void,
  Error,
  UpdateSupplierInvoicePayload
> => {
  const fetchApi = useFetchApi();
  const { formatMessage } = useIntl();
  const sentry = useEmberService('sentry');
  const toastFlashMessages = useEmberService('toast-flash-messages');
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: request => updateSupplierInvoice(fetchApi, request),
    onSuccess: (_, { counterpartyName }) => {
      toastFlashMessages.toastSuccess(
        formatMessage(
          {
            id: 'cash-flow.upcoming-transactions.actions.reschedule-transfer.toast.success',
          },
          {
            beneficiary_name: counterpartyName,
          }
        )
      );
      void queryClient.invalidateQueries({ queryKey: ['upcoming-transactions'] });
    },
    onError: error => {
      toastFlashMessages.toastError(formatMessage({ id: 'toasts.errors.server_error' }));
      sentry.captureException(error);
    },
  });
};
