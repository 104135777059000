import { Value } from '../value';

interface SimpleValueProps {
  value?: string | number | null;
  warningLevel?: 'error' | 'warning';
}

export function SimpleValue({ value, warningLevel, ...props }: SimpleValueProps): JSX.Element {
  const missing = !value;

  return (
    <Value missing={missing} warningLevel={warningLevel} {...props}>
      {value}
    </Value>
  );
}
