import { type ReactNode } from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './styles.strict-module.css';

export function WidgetInvoicesLoading(): ReactNode {
  return (
    <div className={styles.wrapper} data-test-widget-invoices>
      <div className={`${styles.header} ${styles.loading}`}>
        <SkeletonLoader.Header height="8px" width="184px" />
        <SkeletonLoader.Header className={styles['justify-end']} height="8px" width="100px" />
      </div>
      {Array.from({ length: 3 }).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key -- static array
        <li className={`${styles['invoices-list-item']} ${styles.loading}`} key={index}>
          <SkeletonLoader.Avatar height="38px" width="38px" />
          <div className={styles['name-status']}>
            <div className={`${styles.name} ${styles.loading}`} data-test-customer-name>
              <SkeletonLoader.Line className={styles.mt0} height="12px" width="45px" />
            </div>
            <div className={styles.status} data-test-status>
              <SkeletonLoader.Line height="12px" width="95px" />
            </div>
          </div>
          <div className={styles['justify-end']}>
            <div className={styles.amount} data-test-amount>
              <SkeletonLoader.Line height="12px" width="45px" />
            </div>
            <div className={styles.date} data-test-date>
              <SkeletonLoader.Line height="12px" width="95px" />
            </div>
          </div>
        </li>
      ))}
      <div className={`${styles['cta-btn']}  ${styles.loading}`} data-test-cta-btn>
        <SkeletonLoader.Block height="32px" width="130px" />
      </div>
    </div>
  );
}
