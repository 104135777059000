import { Toggle } from '@repo/design-system-kit';
import type { ReactNode } from 'react';
import cx from 'clsx';
import styles from './styles.strict-module.css';

interface DepositInvoiceToggleProps {
  label: string;
  className?: string;
  isSelected?: boolean;
  onChange?: (isSelected: boolean) => void;
}

export function DepositInvoiceToggle({
  label,
  className,
  ...props
}: DepositInvoiceToggleProps): ReactNode {
  return (
    <Toggle
      className={cx(styles['deposit-invoice-toggle'], className)}
      data-test-deposit-invoice-toggle
      {...props}
    >
      {label}
    </Toggle>
  );
}
