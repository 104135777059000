import cx from 'clsx';
import { useIntl } from 'react-intl';
import { BadgeStatus } from '@repo/design-system-kit';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import { StatusAvatar } from 'qonto/react/components/avatar/status-avatar';
import {
  IconStatusProcessing,
  IconStatusScheduled,
  IconStatusApproved,
  IconStatusCancelled,
} from 'qonto/react/assets/icons/status';
import { formatMoney } from 'qonto/helpers/format/money';
import { RIBA_STATUS } from 'qonto/constants/riba';
import type RibaPaymentModel from 'qonto/models/riba-payment';
import styles from './styles.strict-module.css';

interface TableItemProps extends React.HTMLAttributes<HTMLTableRowElement> {
  item: RibaPaymentModel;
  openSidebar: (payment: RibaPaymentModel) => void;
  sidebarPayment?: RibaPaymentModel;
}

export function TableItem({
  item,
  openSidebar,
  sidebarPayment,
  ...props
}: TableItemProps): React.ReactNode {
  const { locale, formatMessage } = useIntl();
  const emberIntl = useEmberService('intl');

  const STATUS_CONFIG = {
    [RIBA_STATUS.PENDING]: {
      icon: IconStatusProcessing,
      badge: {
        level: 'waiting' as const,
        message: '',
      },
    },
    [RIBA_STATUS.SCHEDULED]: {
      icon: IconStatusScheduled,
      badge: {
        level: 'waiting' as const,
        message: formatMessage({ id: 'riba.status.scheduled' }),
      },
    },
    [RIBA_STATUS.COMPLETED]: {
      icon: IconStatusApproved,
      badge: {
        level: 'validated' as const,
        message: formatMessage({ id: 'riba.status.completed' }),
      },
    },
    [RIBA_STATUS.CANCELED]: {
      icon: IconStatusCancelled,
      badge: {
        level: 'error' as const,
        message: formatMessage({ id: 'riba.status.canceled' }),
      },
    },
    [RIBA_STATUS.REJECTED]: {
      icon: IconStatusCancelled,
      badge: {
        level: 'error' as const,
        message: formatMessage({ id: 'riba.status.rejected' }),
      },
    },
    [RIBA_STATUS.DECLINED]: {
      icon: IconStatusCancelled,
      badge: {
        level: 'error' as const,
        message: formatMessage({ id: 'riba.status.declined' }),
      },
    },
  } as const;

  const statusConfig = STATUS_CONFIG[item.status];

  const getStatusIcon = (): React.ReactElement => {
    const Icon = statusConfig.icon;
    return <Icon />;
  };

  const isTerminatedPayment = [
    RIBA_STATUS.CANCELED,
    RIBA_STATUS.REJECTED,
    RIBA_STATUS.DECLINED,
  ].includes(item.status);

  return (
    <tr
      className={cx(
        styles.row,
        isTerminatedPayment && styles.ghost,
        item.id === sidebarPayment?.id && styles.rowActive
      )}
      data-test-riba-table-item
      {...props}
      onClick={() => {
        openSidebar(item);
      }}
    >
      <td className={styles.cell}>
        <div className={cx(styles['cell-content'], styles.counterparty)}>
          <StatusAvatar
            dataTestId={`riba-${item.status}`}
            icon={getStatusIcon()}
            iconSize="16"
            src="/icon/riba/icon_money_clip-m.svg"
          />
          <div className={styles['counterparty-details']}>
            <div
              className={cx('body-1', styles['creditor-name'], isTerminatedPayment && styles.ghost)}
              data-test-riba-counterparty-name
            >
              {item.creditorName}
            </div>
            <div
              className={cx('body-2', styles.reference, isTerminatedPayment && styles.ghost)}
              data-test-riba-counterparty-reference
            >
              {item.reference}
            </div>
          </div>
        </div>
      </td>

      <td className={cx('body-2', styles.cell)}>
        <div className={styles['cell-content']} data-test-riba-number>
          <span>{item.ribaNumber}</span>
        </div>
      </td>

      <td className={cx('body-2', styles.cell)}>
        <div className={styles['cell-content']} data-test-riba-due-date>
          <span>
            {dateToken({ date: item.executionDate.toISOString(), locale, token: 'date-year-s' })}
          </span>
        </div>
      </td>

      <td className={cx('body-2', styles.cell)}>
        <div className={styles['cell-content']} data-test-riba-status>
          <BadgeStatus level={statusConfig.badge.level} text={statusConfig.badge.message} />
        </div>
      </td>

      <td className={cx('body-1', styles.cell)}>
        <div className={cx(styles['cell-content'], styles['amount-details'])} data-test-riba-amount>
          <span className={isTerminatedPayment ? styles.terminated : undefined}>
            {formatMoney(item.amount.value, {
              currency: item.amount.currency,
              signus: '-',
              intl: emberIntl,
            })}
          </span>
        </div>
      </td>
    </tr>
  );
}
