import { type ReactNode } from 'react';
import cx from 'clsx';
import { RadioButton } from '@repo/design-system-kit';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './theme-selector-item.strict-module.css';

interface ThemeSelectorItemProps {
  groupValue?: string;
  value: string;
  illustration: string;
  title: string;
  onCheck?: () => void;
  className?: string;
}

export function ThemeSelectorItem({
  groupValue,
  value,
  illustration,
  title,
  className,
  ...props
}: ThemeSelectorItemProps): ReactNode {
  return (
    <label
      className={cx(styles.container, groupValue === value && styles.selected, className)}
      data-test-theme-selector-item
      htmlFor={`theme-selector-${value}`}
      {...props}
    >
      <StaticThemedAsset
        alt=""
        assetPath={illustration}
        className={cx('mb-24', styles.illustration)}
        data-test-theme-selector-item-illustration
        role="none"
      />
      <div className={styles.title}>
        <RadioButton
          className={cx('mr-8', styles['radio-button'])}
          data-test-theme-selector-item-input
          id={`theme-selector-${value}`}
          value={value}
        />
        <h2 className="title-4" data-test-theme-selector-item-title>
          {title}
        </h2>
      </div>
    </label>
  );
}
