import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { CARD_LEVELS, CARD_WITHDRAW_FEE } from 'qonto/constants/cards';
import styles from './advantages.strict-module.css';

interface AdvantagesProps {
  cardLevel: string;
  foreignFees: number;
  isCardFree: boolean;
  isMetalCard: boolean;
  limit: {
    atm: number;
    payment: number;
  };
  price: number;
}

export function Advantages({
  cardLevel,
  foreignFees,
  isCardFree,
  isMetalCard,
  limit,
  price,
}: AdvantagesProps): ReactNode {
  const { formatMessage, formatNumber } = useIntl();

  const formatCurrencyWithoutDecimals = (amount: number): string => {
    return formatNumber(amount, {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const atmFee = (() => {
    switch (cardLevel) {
      case CARD_LEVELS.STANDARD: {
        const fee = formatCurrencyWithoutDecimals(CARD_WITHDRAW_FEE);

        return {
          label: formatMessage({ id: 'cards.details.advantages.atm-fee.standard.label' }),
          value: formatMessage({ id: 'cards.details.advantages.atm-fee.standard.value' }, { fee }),
        };
      }
      case CARD_LEVELS.PLUS:
        return {
          label: formatMessage({ id: 'cards.details.advantages.atm-fee.plus.label' }),
          value: formatMessage({ id: 'cards.details.advantages.atm-fee.plus.value' }),
        };
      default:
        return null;
    }
  })();

  const fxFee = (() => {
    const fee = formatNumber(foreignFees, {
      style: 'percent',
      minimumFractionDigits: 0,
    });

    switch (cardLevel) {
      case CARD_LEVELS.STANDARD:
        return {
          label: formatMessage({ id: 'cards.details.advantages.fx.standard.label' }),
          value: formatMessage({ id: 'cards.details.advantages.fx.standard.value' }, { fee }),
        };
      case CARD_LEVELS.PLUS:
        return {
          label: formatMessage({ id: 'cards.details.advantages.fx.plus.label' }),
          value: formatMessage({ id: 'cards.details.advantages.fx.plus.value' }, { fee }),
        };
      default:
        return null;
    }
  })();

  const printType = (() => {
    const label = formatMessage({ id: 'cards.details.advantages.card-details.label' });

    switch (cardLevel) {
      case CARD_LEVELS.METAL:
        return {
          label,
          value: formatMessage({ id: 'cards.details.advantages.card-details.value.metal' }),
        };
      case CARD_LEVELS.PLUS:
        return {
          label,
          value: formatMessage({ id: 'cards.details.advantages.card-details.value.plus' }),
        };
      case CARD_LEVELS.STANDARD:
        return {
          label,
          value: formatMessage({ id: 'cards.details.advantages.card-details.value.standard' }),
        };
      default:
        return null;
    }
  })();

  const insurance = (() => {
    switch (cardLevel) {
      case CARD_LEVELS.METAL:
        return {
          label: formatMessage({ id: 'cards.details.advantages.insurance-coverage.label.metal' }),
          value: formatMessage({ id: 'cards.details.advantages.insurance-coverage.value.metal' }),
        };
      case CARD_LEVELS.PLUS:
        return {
          label: formatMessage({ id: 'cards.details.advantages.insurance-coverage.label.plus' }),
          value: formatMessage({ id: 'cards.details.advantages.insurance-coverage.value.plus' }),
        };
      case CARD_LEVELS.STANDARD:
        return {
          label: formatMessage({
            id: 'cards.details.advantages.insurance-coverage.label.standard',
          }),
          value: formatMessage({
            id: 'cards.details.advantages.insurance-coverage.value.standard',
          }),
        };
      default:
        return null;
    }
  })();

  return (
    <div className="mb-80" data-test-card-details-advantages={cardLevel}>
      <h3 className="title-2 mb-32">{formatMessage({ id: 'cards.details.advantages.title' })}</h3>
      <table className={styles['advantages-table']}>
        <tbody>
          <tr>
            <td className={cx(styles['advantages-table__key'], 'body-1')}>
              {formatMessage({ id: 'cards.details.advantages.price.label' })}
            </td>
            <td
              className={cx(styles['advantages-table__value'], 'body-2')}
              data-test-card-details-price
            >
              {isCardFree
                ? formatMessage({ id: 'cards.details.advantages.price.free' })
                : formatMessage(
                    { id: 'cards.details.pricing-with-period' },
                    { amount: formatCurrencyWithoutDecimals(price) }
                  )}
            </td>
          </tr>
          <tr>
            <td
              className={cx(styles['advantages-table__key'], 'body-1')}
              data-test-card-details-payment-limit-label
            >
              {formatMessage({ id: 'cards.details.advantages.payment-limit.label' })}
            </td>
            <td
              className={cx(styles['advantages-table__value'], 'body-2')}
              data-test-card-details-payment-limit-value
            >
              {formatMessage(
                { id: 'cards.details.advantages.payment-limit.value' },
                { amount: formatCurrencyWithoutDecimals(limit.payment) }
              )}
            </td>
          </tr>
          <tr>
            <td
              className={cx(styles['advantages-table__key'], 'body-1')}
              data-test-card-details-atm-limit-label
            >
              {formatMessage({ id: 'cards.details.advantages.atm-limit.label' })}
            </td>
            <td
              className={cx(styles['advantages-table__value'], 'body-2')}
              data-test-card-details-atm-limit-value
            >
              {formatMessage(
                { id: 'cards.details.advantages.atm-limit.value' },
                { amount: formatCurrencyWithoutDecimals(limit.atm) }
              )}
            </td>
          </tr>
          {!isMetalCard && atmFee ? (
            <tr>
              <td
                className={cx(styles['advantages-table__key'], 'body-1')}
                data-test-card-details-atm-fee-label
              >
                {atmFee.label}
              </td>
              <td
                className={cx(styles['advantages-table__value'], 'body-2')}
                data-test-card-details-atm-fee-value
              >
                {atmFee.value}
              </td>
            </tr>
          ) : null}
          {!isMetalCard && fxFee ? (
            <tr>
              <td
                className={cx(styles['advantages-table__key'], 'body-1')}
                data-test-card-details-fx-label
              >
                {fxFee.label}
              </td>
              <td
                className={cx(styles['advantages-table__value'], 'body-2')}
                data-test-card-details-fx-value
              >
                {fxFee.value}
              </td>
            </tr>
          ) : null}
          {printType ? (
            <tr>
              <td
                className={cx(styles['advantages-table__key'], 'body-1')}
                data-test-card-details-print-type-label
              >
                {printType.label}
              </td>
              <td
                className={cx(styles['advantages-table__value'], 'body-2')}
                data-test-card-details-print-type-value
              >
                {printType.value}
              </td>
            </tr>
          ) : null}
          {insurance ? (
            <tr>
              <td
                className={cx(styles['advantages-table__key'], 'body-1')}
                data-test-card-details-insurance-label
              >
                {insurance.label}
              </td>
              <td
                className={cx(styles['advantages-table__value'], 'body-2')}
                data-test-card-details-insurance-value
              >
                {insurance.value}
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </div>
  );
}
