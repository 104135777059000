import { flexRender, type Cell } from '@tanstack/react-table';
import React, { type CSSProperties, type ReactNode } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import cx from 'clsx';
import styles from './styles.strict-module.css';

interface BodyCellProps<TData, TValue> {
  cell: Cell<TData, TValue>;
  cellIndex: number;
  className?: string;
}

export function BodyCell<TData, TValue>({
  cell,
  cellIndex,
  className,
}: BodyCellProps<TData, TValue>): ReactNode {
  const { isDragging, setNodeRef } = useSortable({
    id: cell.column.id,
  });

  const isPinned = cell.column.getIsPinned();
  const isFixedColumn = cellIndex === 0;

  const getPadding = (): string => {
    return '0 16px';
  };

  const getZIndex = (): number => {
    if (isPinned) {
      return 2;
    } else if (isDragging) {
      return 1;
    }
    return 0;
  };

  let minWidth: string;
  if (cell.column.columnDef.minSize) {
    minWidth = `${cell.column.columnDef.minSize}px`;
  } else if (isFixedColumn) {
    minWidth = '300px';
  } else {
    minWidth = '100px';
  }

  const dndStyle: CSSProperties = {
    whiteSpace: 'nowrap',
    zIndex: getZIndex(),
    position: isPinned ? 'sticky' : 'relative',
    width: `${cell.column.getSize().toString()}px`,
    maxWidth: `${cell.column.getSize().toString()}px`,
    left: isPinned === 'left' ? `${cell.column.getStart('left').toString()}px` : undefined,
    padding: getPadding(),
    minWidth,
  };

  return (
    <td
      className={cx(styles['table-cell'], className)}
      key={cell.id}
      ref={setNodeRef}
      role="gridcell"
      style={{ ...dndStyle }}
      tabIndex={0}
    >
      <div className={cx(styles['table-cell-content'], isFixedColumn && styles['fixed-column'])}>
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </div>
    </td>
  );
}
