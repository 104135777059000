import { useContext, type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { OverlayTriggerStateContext } from 'react-aria-components';
import { IconCheckmarkRoundedOutlined, IconCrossRoundedOutlined } from '@repo/monochrome-icons';
import {
  Popover,
  PopoverSection,
  PopoverButton,
  type OverlayTriggerState,
} from 'qonto/react/components/table-v2/popover';
import type { Transaction } from 'qonto/react/graphql';
import { getVerificationStatusInfo } from '../util';
import styles from './styles.strict-module.css';

interface PopoverProps {
  toggleVerificationStatus: (close: () => void) => void;
  status: Transaction['status'];
  qualifiedForAccounting: boolean;
  isUpdatingStatus: boolean;
}

export function VerificationStatusCellPopover({
  toggleVerificationStatus,
  status,
  qualifiedForAccounting,
  isUpdatingStatus,
}: PopoverProps): ReactNode {
  const { formatMessage } = useIntl();
  const triggerState = useContext<OverlayTriggerState | null>(OverlayTriggerStateContext);
  const statusBadgeInfo = getVerificationStatusInfo(status, qualifiedForAccounting, formatMessage);
  const handlePress = (): void => {
    if (triggerState) toggleVerificationStatus(triggerState.close);
  };

  if (!statusBadgeInfo) {
    return null;
  }

  return (
    <Popover>
      <PopoverSection className={styles.section}>
        {qualifiedForAccounting ? (
          <PopoverButton
            data-testid="cancel-verification"
            isDisabled={isUpdatingStatus}
            onPress={handlePress}
            slots={{
              icon: <IconCrossRoundedOutlined />,
              text: formatMessage({
                id: 'transactions.modular_table.bookkeeping.cancel_verification.cta',
              }),
            }}
          />
        ) : (
          <PopoverButton
            data-testid="mark-as-verified"
            isDisabled={isUpdatingStatus}
            onPress={handlePress}
            slots={{
              icon: <IconCheckmarkRoundedOutlined />,
              text: formatMessage({
                id: 'transactions.modular_table.bookkeeping.mark_as_verified.cta',
              }),
            }}
          />
        )}
      </PopoverSection>
    </Popover>
  );
}
