import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Disclaimer } from '@repo/design-system-kit';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { DetailsList, type DetailsItem } from 'qonto/react/components/sidebar/details-list';
import type RibaPaymentModel from 'qonto/models/riba-payment';
import { RIBA_STATUS } from 'qonto/constants/riba';
import styles from './styles.strict-module.css';

interface DetailsProps {
  payment: RibaPaymentModel;
}

export function Details({ payment }: DetailsProps): ReactNode {
  const intl = useIntl();
  const store = useEmberService('store');

  const bankAccount = payment.bankAccountId
    ? store.peekRecord('bank-account', payment.bankAccountId)
    : null;

  const items: DetailsItem[] = [
    {
      label: intl.formatMessage({ id: 'riba.sidebar.labels.reference' }),
      text: payment.reference,
    },
    {
      label: intl.formatMessage({ id: 'riba.sidebar.labels.riba-number' }),
      text: payment.ribaNumber,
    },
    ...(bankAccount?.name && payment.status !== RIBA_STATUS.REJECTED
      ? [
          {
            label: intl.formatMessage({ id: 'riba.sidebar.labels.account' }),
            text: bankAccount.name,
          },
        ]
      : []),
  ];

  const FAILURE_REASONS = {
    user_took_no_action: intl.formatMessage({ id: 'riba.sidebar.disclaimer.no-approval-action' }),
    insufficient_funds: intl.formatMessage({ id: 'riba.sidebar.disclaimer.balance-too-low' }),
  };
  const showDisclaimer = Object.keys(FAILURE_REASONS).includes(payment.failureReason);

  return (
    <div className={styles.container}>
      {showDisclaimer ? (
        <Disclaimer.Block level="error">
          <p>{FAILURE_REASONS[payment.failureReason as keyof typeof FAILURE_REASONS]}</p>
        </Disclaimer.Block>
      ) : null}
      <DetailsList items={items} />
    </div>
  );
}
