import { type ReactNode, useEffect, useRef } from 'react';
import cx from 'clsx';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import type { CardLevel, PhysicalCardLevel } from '@repo/cards-kit/constants';
import { CARD_LEVELS, CARD_TYPES } from 'qonto/constants/cards';
import { importAsset } from 'qonto/helpers/import-asset';
import { getImageUrl } from './get-image-url';
import styles from './asset.strict-module.css';

const VIDEO_URLS: Partial<Record<CardLevel, string>> = {
  [CARD_LEVELS.STANDARD]: '/videos/cards/card-one.webm',
  [CARD_LEVELS.PLUS]: '/videos/cards/card-plus.webm',
  [CARD_LEVELS.METAL]: '/videos/cards/card-x.webm',
};

export interface CoreAssetProps {
  className?: string;
  id?: string;
  isImageOnly?: boolean;
  isVideoPlayingOnError?: boolean;
  setIsLoaded?: (cardLevel: PhysicalCardLevel) => void;
  shouldAnimateCards?: boolean;
}

export interface AssetProps extends CoreAssetProps {
  cardLevel: CardLevel;
}

export function Asset({
  cardLevel,
  className,
  id,
  isImageOnly,
  isVideoPlayingOnError,
  setIsLoaded,
  shouldAnimateCards,
  ...props
}: AssetProps): ReactNode {
  const deviceManager = useEmberService('deviceManager');
  const localeManager = useEmberService('localeManager');

  const isPhysicalCard = CARD_TYPES.PHYSICALS.includes(cardLevel);
  const isStaticAsset =
    isImageOnly || isVideoPlayingOnError || (deviceManager.isSafariDesktop as boolean);
  const imageUrl = getImageUrl({ locale: localeManager.locale as string, cardLevel });
  const posterRef = useRef<HTMLImageElement>(null);

  const videoUrl = isPhysicalCard && VIDEO_URLS[cardLevel as PhysicalCardLevel];
  const importedVideoUrl = videoUrl ? importAsset([videoUrl]) : '';

  useEffect(() => {
    if (!isStaticAsset && isPhysicalCard && setIsLoaded && id && posterRef.current) {
      const video = document.getElementById(id) as HTMLVideoElement; // can be refactored to passed-down ref once parent component is migrated
      video.muted = true; // the video requires to be muted via js to be then played via js

      const currentPosterRef = posterRef.current;
      const posterEventHandler = (): void => {
        setIsLoaded(cardLevel as PhysicalCardLevel);
      };
      currentPosterRef.addEventListener('load', posterEventHandler, true);

      return () => {
        currentPosterRef.removeEventListener('load', posterEventHandler, true);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- This should run only once
  }, []);

  return (
    <div className={cx('asset', shouldAnimateCards && styles.animated, className)} {...props}>
      {(() => {
        if (isStaticAsset) {
          return (
            <img alt="" className={styles.fallback} data-test-card-video-fallback src={imageUrl} />
          );
        } else if (isPhysicalCard && importedVideoUrl && id) {
          return (
            <>
              <video
                aria-hidden="true"
                className={styles.video}
                data-test-card-video
                id={id}
                muted
                playsInline
                poster={imageUrl}
                preload="auto"
              >
                <source src={importedVideoUrl} type="video/webm;codecs=vp8, vorbis" />
                <img alt="" src={imageUrl} />
              </video>
              <img
                alt=""
                aria-hidden="true"
                className={styles['animation-poster']}
                data-test-card-poster
                ref={posterRef}
                src={imageUrl}
              />
            </>
          );
        }

        return null;
      })()}
    </div>
  );
}
