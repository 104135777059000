import dayjs from 'dayjs';
import type {
  UpcomingTransaction,
  UpcomingTransactionsResponse,
} from 'qonto/react/hooks/use-upcoming-transactions';

export interface ChartData {
  name: string; // Formatted date name (e.g., 'TODAY' or 'DD MMM YYYY')
  date: string; // Original date string in ISO format
  inflows: number | undefined; // Sum of inflows for the date
  outflows: number | undefined; // Sum of outflows for the date
  side: 'credit' | 'debit'; // 'credit' if net inflows >= 0, otherwise 'debit'
  predicted_balance: {
    currency: string;
    value: string;
  };
  total_amount_for_the_day: {
    currency: string;
    value: string;
  };
  inflowsTransactions: UpcomingTransaction[] | undefined; // Array of inflow transactions
  outflowsTransactions: UpcomingTransaction[] | undefined; // Array of outflow transactions
}

export function normalizeData(
  rawData: UpcomingTransactionsResponse,
  transactionType = 'all'
): ChartData[] {
  const { predicted_balances: predictedBalances, upcoming_transactions: upcomingTransactions } =
    rawData;

  if (!predictedBalances) return [];

  return predictedBalances.map(entry => {
    const date = entry.date;

    // Filter transactions based on transactionType
    let filteredTransactions = upcomingTransactions?.filter(t =>
      dayjs(t.due_date).startOf('day').isSame(date, 'day')
    );
    if (transactionType === 'inflows') {
      filteredTransactions = filteredTransactions?.filter(t => t.side === 'credit');
    } else if (transactionType === 'outflows') {
      filteredTransactions = filteredTransactions?.filter(t => t.side === 'debit');
    }

    // Sum inflows and outflows
    const inflowsTransactions = filteredTransactions?.filter(t => t.side === 'credit');
    const outflowsTransactions = filteredTransactions?.filter(t => t.side === 'debit');

    const inflows = inflowsTransactions?.reduce((sum, t) => sum + parseFloat(t.amount.value), 0);
    const outflows = outflowsTransactions?.reduce((sum, t) => sum + parseFloat(t.amount.value), 0);

    // Determine side
    const side = (inflows ?? 0) >= (outflows ?? 0) ? 'credit' : 'debit';

    return {
      name: date,
      date,
      inflows,
      outflows,
      side,
      balance: entry.predicted_balance.value,
      predicted_balance: entry.predicted_balance,
      total_amount_for_the_day: entry.total_amount_for_the_day,
      inflowsTransactions,
      outflowsTransactions,
      totalTransactions: (inflowsTransactions?.length ?? 0) + (outflowsTransactions?.length ?? 0),
    };
  });
}
