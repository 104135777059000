import { animate, motion, MotionGlobalConfig, useMotionValue, useTransform } from 'framer-motion';
import { type ReactElement, useEffect } from 'react';
import { useIntl } from 'react-intl';
import cs from 'clsx';

interface AnimatedCounterProps {
  className: string;
  currency: string;
  currentValue: number;
  delay?: number;
  previousValue?: number;
}

export function AnimatedCounter({
  className,
  currency,
  currentValue,
  delay = 0,
  previousValue = 0,
}: AnimatedCounterProps): ReactElement {
  const { formatNumber } = useIntl();
  // TODO: To be removed with Playwright tests - Set starting value according to global config value to avoid flaky tests in Ember QUnit
  const startValue = useMotionValue(
    MotionGlobalConfig.skipAnimations ? currentValue : previousValue
  );
  const formattedAmount = useTransform(() =>
    formatNumber(startValue.get(), {
      currency,
      style: 'currency',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  );

  useEffect(() => {
    const animation = animate(startValue, currentValue, {
      duration: 0.8,
      delay,
    });

    return () => {
      animation.stop();
    };
  }, [currentValue, delay, startValue]);

  return (
    <motion.div className={cs(className)} data-testid="insight-figure">
      {formattedAmount}
    </motion.div>
  );
}
