import type { ReactNode } from 'react';
import { Button } from '@repo/design-system-kit';
import styles from './overlay-cta.strict-module.css';

interface OverlayCtaProps {
  title: string;
  subtitle: string;
  ctaText: string;
  ctaAction: () => void;
}

export function OverlayCta({ title, subtitle, ctaText, ctaAction }: OverlayCtaProps): ReactNode {
  return (
    <div className={styles.overlayContent}>
      <div>
        <h4 className={styles.heading} data-testid="heading">
          {title}
        </h4>
        <p className={styles.subHeading} data-testid="subheading">
          {subtitle}
        </p>
      </div>
      <Button className={styles.cta} data-testid="cta" onPress={ctaAction} variant="primary">
        {ctaText}
      </Button>
    </div>
  );
}
