import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import type SupplierInvoiceModel from 'qonto/models/supplier-invoice';
import { useFetchTransactionsByIds } from 'qonto/react/hooks/use-fetch-transactions-by-ids';
import { Transaction } from 'qonto/react/components/supplier-invoices/transactions-modal/transaction/transaction';
import { useDisplayCounterparty } from 'qonto/react/hooks/use-display-counterparty';
import { formatMoney } from 'qonto/react/shared/utils/format-money';
import ENV from 'qonto/config/environment';

const isTesting = (ENV as { environment: string }).environment === 'test';

interface SuggestedTransactionsProps {
  invoice: SupplierInvoiceModel;
  handleClick: (transaction: { id: string; side: 'debit' | 'credit'; settledAt?: string }) => void;
}

export function SuggestedTransactions({
  invoice,
  handleClick,
}: SuggestedTransactionsProps): ReactNode {
  const { formatDate, formatMessage, locale } = useIntl();

  const transactionIds = invoice.suggestedTransactions.map(({ id }) => id);
  const { data: suggestedTransactions } = useFetchTransactionsByIds(transactionIds, {
    retry: isTesting ? false : 3,
    enabled: transactionIds.length > 0,
    throwOnError: true,
    suspense: true,
  });

  const displayCounterparty = useDisplayCounterparty();

  const formattedDate = (emittedAt: string): string =>
    formatDate(emittedAt, {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });

  const formattedAmount = (amount: number, currency: string, side: 'credit' | 'debit'): string => {
    return formatMoney(amount, {
      locale,
      currency,
      signus: side === 'credit' ? '+' : '-',
    });
  };

  if (suggestedTransactions?.length === 0) return null;

  return (
    <div data-test-suggested-transactions>
      <h3 className="caption mb-16" data-test-suggested-transactions-header>
        {formatMessage({
          id: 'supplier-invoices.match-transaction-modal.suggested-transactions.title',
        })}
      </h3>
      {suggestedTransactions?.map((transaction, index) => (
        <Transaction
          data-test-modal-transaction={index}
          formattedAmount={formattedAmount(
            transaction.amount,
            transaction.amountCurrency,
            transaction.side
          )}
          handleClick={() => {
            handleClick(transaction);
          }}
          key={transaction.id}
          methodLabel={transaction.subjectType}
          operationMethod={transaction.operationMethod}
          side={transaction.side}
          transactionDate={formattedDate(transaction.emittedAt)}
          transactionName={displayCounterparty(transaction.counterpartyName)}
        />
      ))}
    </div>
  );
}
