import type { ReactElement } from 'react';
import cx from 'clsx';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Button, dateToken, Disclaimer } from '@repo/design-system-kit';
import { useOrganizationNavigation } from 'qonto/react/shared/hooks/use-organization-navigation';
import type { Check, FnciResult as FnciResultType } from 'qonto/react/models/check';
import { FnciResult } from 'qonto/react/components/checks/fnci-result';
import { SummaryPlaceholder } from 'qonto/react/components/checks/summary-placeholder';
import styles from './summary.strict-module.css';

interface FlowsCheckSummaryProps {
  check: Check;
  createCheck: () => Promise<void>;
  createCheckLoading: boolean;
  feeText: string;
  fnciErrorMessage?: string;
  fnciResult?: FnciResultType;
  preRegisterCheckLoading: boolean;
  redirectToEmitterStep?: () => void;
  shouldDisplayAccountLabel?: boolean;
}

export function FlowsCheckSummary({
  check,
  createCheck,
  createCheckLoading,
  feeText,
  fnciErrorMessage,
  fnciResult,
  preRegisterCheckLoading,
  redirectToEmitterStep,
  shouldDisplayAccountLabel,
}: FlowsCheckSummaryProps): ReactElement {
  const { locale } = useIntl();

  const organizationNavigation = useOrganizationNavigation();

  const isFNCIresultRedOrOrange = fnciResult?.color === 'red' || fnciResult?.color === 'orange';

  const checkNumber = `${check.checkNumber} (${check.rlmc})`;

  const abandonCheck = async (): Promise<void> => {
    await organizationNavigation('/checks');
  };

  return (
    <div className={styles.checks} data-test-new-check-summary>
      <div className={styles.summary}>
        <div className={styles.container}>
          <h2 className={cx(styles.title, 'title-2 mb-8')} data-test-create-check-summary-title>
            <FormattedMessage id="checks.confirm.title" />
          </h2>

          <p className="body-2 mb-32">
            <FormattedMessage id="checks.confirm.subtitle" />
          </p>

          {preRegisterCheckLoading ? (
            <SummaryPlaceholder data-test-summary-placeholder />
          ) : (
            <>
              <table className={cx(styles['table-confirmation'], 'body-1')}>
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="checks.confirm.information-column" />
                    </th>

                    <th>
                      <button
                        className="btn btn--tertiary"
                        data-test-new-check-form-confirm-edit
                        onClick={redirectToEmitterStep}
                        type="button"
                      >
                        <FormattedMessage id="checks.confirm.edit_btn" />
                      </button>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <FormattedMessage id="checks.confirm.amount" />
                    </td>

                    <td data-test-new-check-form-confirm-amount-cents>
                      <FormattedNumber
                        currency={check.amountCurrency}
                        style="currency"
                        value={check.amount}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <FormattedMessage id="checks.confirm.emitter_name" />
                    </td>

                    <td data-test-new-check-form-confirm-emitter-name>{check.emitterName}</td>
                  </tr>

                  <tr>
                    <td>
                      <FormattedMessage id="checks.confirm.emitted_at" />
                    </td>

                    <td data-test-new-check-form-confirm-emitted-date>
                      {dateToken({ date: check.emittedDate, locale })}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <FormattedMessage id="checks.confirm.number-and-key" />
                    </td>

                    <td data-test-check-form-confirm-number-and-key>{checkNumber}</td>
                  </tr>

                  {shouldDisplayAccountLabel ? (
                    <tr>
                      <td>
                        <FormattedMessage id="navigation.main.account" />
                      </td>

                      <td data-test-new-check-form-bank-account-name>{check.bankAccount?.name}</td>
                    </tr>
                  ) : null}
                </tbody>
              </table>

              {fnciErrorMessage ? (
                <Disclaimer.Block data-test-check-summary-fnci-error-message level="error">
                  {fnciErrorMessage}
                </Disclaimer.Block>
              ) : (
                <>
                  <Disclaimer.Block data-test-new-check-form-confirm-amount-fees level="info">
                    {feeText}
                  </Disclaimer.Block>

                  {fnciResult ? (
                    <FnciResult data-test-check-summary-fnci-result result={fnciResult} />
                  ) : null}

                  <Button
                    className={styles['confirm-btn']}
                    data-test-new-check-form-confirm-continue
                    isDisabled={createCheckLoading}
                    isLoading={createCheckLoading}
                    onPress={createCheck}
                    type="button"
                    variant={!isFNCIresultRedOrOrange ? 'primary' : 'secondary'}
                  >
                    <FormattedMessage id="checks.confirm.confirm_btn" />
                  </Button>

                  {isFNCIresultRedOrOrange ? (
                    <Button data-test-check-summary-abandon onPress={abandonCheck} type="button">
                      <FormattedMessage id="checks.fnci.cta-exit" />
                    </Button>
                  ) : null}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
