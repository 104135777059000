import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { External } from 'qonto/react/assets/icons';
import styles from './view-all-transactions-button.strict-module.css';

interface ViewAllTransactionsButtonProps {
  handleViewAllTransactions: () => void;
}

export function ViewAllTransactionsButton({
  handleViewAllTransactions,
}: ViewAllTransactionsButtonProps): ReactNode {
  const { formatMessage } = useIntl();

  return (
    <button
      className={styles['view-all-transactions-button']}
      data-testid="all-transactions-button"
      onClick={handleViewAllTransactions}
      type="button"
    >
      <External />
      <span>
        {formatMessage({
          id: 'supplier-invoices.suggested-transaction.search-all-transactions.cta',
        })}
      </span>
    </button>
  );
}
