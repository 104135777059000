/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import { type ReactNode } from 'react';
import cx from 'clsx';
import { useIntl, FormattedNumber } from 'react-intl';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import { INVOICE_STATUSES } from 'qonto/constants/supplier-invoice';
import { StatusTag } from '../../supplier-invoices/status-tag';
import styles from './styles.strict-module.css';

interface InvoiceProps {
  id: string;
  date: string;
  showSignus: boolean;
  currency: string;
  invoiceNumberOrFileName?: string;
  status?: string;
  selectedInvoiceId: string;
  handleSelectInvoice: (invoiceId: string) => void;
  amount?: number | undefined;
}

export function InvoiceDetailsCard({
  id,
  currency,
  invoiceNumberOrFileName,
  status,
  showSignus,
  date,
  amount,
  selectedInvoiceId,
  handleSelectInvoice,
}: InvoiceProps): ReactNode {
  const { locale } = useIntl();
  const { formatMessage } = useIntl();

  const isSelected = selectedInvoiceId === id;

  const missingData = formatMessage({ id: 'receivable-invoices.label.missing-data' });
  const formattedDateWithStatus = getFormattedDate(
    formatMessage({ id: 'attachment-auto-matching.invoice-selection-modal.issued-on' }),
    date,
    locale,
    status
  );

  const invoiceNameValue = invoiceNumberOrFileName || missingData;

  const formattedAmount = (): JSX.Element | string => {
    if (amount === undefined) {
      return missingData;
    }

    const sign = showSignus ? '-\xA0' : '+\xA0';
    return (
      <>
        {sign}
        <FormattedNumber maximumFractionDigits={2} minimumFractionDigits={2} value={amount} />
        {'\xA0'}
        {currency}
      </>
    );
  };

  return (
    <button
      className={cx(styles.card, isSelected ? styles.selected : styles.unselected)}
      data-test-invoice-details-card-button
      key={id}
      onClick={() => {
        handleSelectInvoice(id);
      }}
      type="button"
    >
      <div className={styles.titleRow}>
        <p className={cx('body-1', styles.titleText)} data-test-invoice-details-name>
          {invoiceNameValue}
        </p>
        <p
          className={cx('body-1', !amount ? styles.greyedOut : '', styles.amount)}
          data-test-invoice-details-amount
        >
          {formattedAmount()}
        </p>
      </div>

      <div className={styles.titleRow}>
        {status ? <StatusTag status={status} /> : '-'}
        <div className={styles.date}>
          <time data-test-invoice-details-date>{formattedDateWithStatus}</time>
        </div>
      </div>
    </button>
  );
}

const getFormattedDate = (
  copyText: string,
  date: string,
  locale: string,
  status?: string
): string => {
  if (!date) {
    return '-';
  }

  const isToApprove = Boolean(status && status === INVOICE_STATUSES.toApprove);
  const baseDate = dateToken({ date, locale, token: 'date-year-s' });

  if (isToApprove) {
    return `${copyText} ${baseDate}`;
  }

  return baseDate;
};
