/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import type { ReactNode } from 'react';
import cx from 'clsx';
import { Side, Status } from 'qonto/react/graphql';
import { cellContextManager } from 'qonto/react/contexts/cell-context';
import { useFormattedAmount } from 'qonto/react/hooks/ui';
import { BaseCell } from '../base-cell';
import { CopyButton } from '../../buttons';
import styles from './styles.strict-module.css';

export function AmountCell(): ReactNode {
  const transaction = cellContextManager.useCellContext();
  const { amount, side, status, fx, localAmount } = transaction;
  const { currency, value } = amount;
  const amountNumber = getSignedAmount(value, side);
  const localAmountNumber = getSignedAmount(localAmount?.value, side);
  const { formattedAmount, formattedLocalAmount } = useFormattedAmount({
    amount: { currency, value: amountNumber },
    localAmount: localAmount
      ? { currency: localAmount.currency, value: localAmountNumber }
      : undefined,
  });

  const isTransaction = Boolean(side);
  const isDeclined = status === Status.Declined || status === Status.Reversed;

  const isNull = amountNumber === 0;
  const isNegative = amountNumber < 0 && !isNull;
  const positiveHighlight = isTransaction && !isNegative && !isNull;
  const hasLocalAmount = fx && localAmount;

  return (
    <BaseCell
      actionSlot={<CopyButton text={value} trackingEventSource="amount_cell_copy_amount" />}
      align="right"
      className={cx(hasLocalAmount && styles['with-local-amount'])}
    >
      <span
        className={cx(
          styles.amount,
          positiveHighlight && styles.positiveHighlight,
          isDeclined && styles.declined
        )}
        data-test-positive-highlight={positiveHighlight}
        data-testid="amount-cell"
      >
        {positiveHighlight ? <span className={styles['plus-sign']}>+ </span> : null}
        {formattedAmount} {currency}
      </span>

      {hasLocalAmount ? (
        <span
          className={cx(
            positiveHighlight && styles.positiveHighlight,
            isDeclined && styles.declined,
            styles['amount-foreign']
          )}
          data-test-local-amount
          data-test-positive-highlight={positiveHighlight}
        >
          {positiveHighlight ? <span className={styles['plus-sign']}>+ </span> : null}
          {formattedLocalAmount} {localAmount.currency}
        </span>
      ) : null}
    </BaseCell>
  );
}

export const getSignedAmount = (amountValue: string | undefined, side: Side): number => {
  return Number(
    `${
      side === Side.Debit && amountValue !== '0' && !amountValue?.startsWith('-') ? '-' : ''
    }${amountValue}`
  );
};
