import { type ReactElement } from 'react';
import { Button } from 'react-aria-components';
import styles from '../styles.strict-module.css';

interface DisabledCellProps {
  children: ReactElement;
}

export function DisabledCell({ children }: DisabledCellProps): ReactElement {
  return (
    <div className={styles['balance-button-container']}>
      <Button
        aria-disabled
        className={`${styles['balance-button']} ${styles['disabled-cell']}`}
        data-testid="disabled-balance-cell-button"
        id="cashflow-balance-cell"
        type="button"
      />
      <div className={styles.balanceButtonContentWrapper} data-testid="balance-cell">
        <div className={`${styles.balanceButtonContent}`}>
          <div className={`${styles.forecastContainer}`} data-test-forecast-container>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
