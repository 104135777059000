import type { ReactElement } from 'react';
import cx from 'clsx';
import type { Amount } from 'qonto/react/models/amount.ts';
import { Counter } from 'qonto/react/components/counter';
import styles from './styles.strict-module.css';

export interface CockpitTileProps {
  title: string;
  badge?: ReactElement;
  amount: Amount;
  strikeThrough?: boolean;
  tooltip?: ReactElement;
  subtitle?: ReactElement;
  amountClassName?: string;
  dataTestId?: string;
}

export function CockpitTile({
  title,
  badge,
  amount,
  strikeThrough = false,
  tooltip,
  subtitle,
  amountClassName,
  dataTestId,
}: CockpitTileProps): ReactElement {
  const { value, currency } = amount;
  const amountValue = Number(value);

  return (
    <article className={styles.card} data-test-cockpit-tile="" data-testid={dataTestId}>
      <header className={styles.cardHeader}>
        <h1 className="caption" data-test-cockpit-tile-title="">
          {title}
        </h1>
        {badge}
      </header>
      <div className={styles.row} data-test-insights-tile-content="">
        <strong
          className={cx(strikeThrough && styles.strikeThrough)}
          data-test-cockpit-tile-amount=""
        >
          <Counter className={amountClassName} currency={currency} currentValue={amountValue} />
        </strong>
        {tooltip}
      </div>
      {subtitle}
    </article>
  );
}
