/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import { type ReactNode } from 'react';
import cx from 'clsx';
import { useIntl } from 'react-intl';
// @ts-expect-error typed budget constants
import { METER_AMOUNTS_THRESHOLDS } from 'qonto/constants/budget';
import { formatMoney } from 'qonto/react/shared/utils/format-money';
import { Meter } from '../meter';
import styles from './payment-limit-bar.strict-module.css';

interface PaymentLimitBarProps {
  caption?: string;
  currentAmount?: number;
  totalAmount?: number;
  layout?: 'list';
  isDisabled?: boolean;
  className?: string;
}

export function PaymentLimitBar({
  caption,
  currentAmount = 0,
  totalAmount = 0,
  layout,
  isDisabled,
  className,
  ...props
}: PaymentLimitBarProps): ReactNode {
  const { locale } = useIntl();

  const meterHeight = layout === 'list' ? 4 : 6;

  return (
    <div className={cx(styles.container, className)} {...props}>
      <p
        className={cx(
          styles.label,
          layout === 'list' ? 'caption' : 'body-2',
          isDisabled && styles.disabled
        )}
        data-test-payment-limit-label
      >
        {caption ? (
          <span className={styles.caption} data-test-payment-limit-caption>
            {caption}
          </span>
        ) : null}
        <div
          className={cx(
            styles.amount,
            layout === 'list' ? styles.expanded : styles['right-aligned']
          )}
          data-test-payment-limit-ratio
        >
          <span className={styles['amount--current']} data-test-payment-limit-current>
            {formatMoney(Number(currentAmount), {
              locale,
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            })}
          </span>
          <span className={styles['amount--sep']}>/</span>
          <span className={styles['amount--total']} data-test-payment-limit-total>
            {formatMoney(Number(totalAmount), {
              locale,
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            })}
          </span>
        </div>
      </p>
      <Meter
        aria-hidden="true"
        disabled={isDisabled}
        height={meterHeight}
        high={(METER_AMOUNTS_THRESHOLDS as { HIGH: number }).HIGH}
        max={totalAmount}
        value={currentAmount}
      />
    </div>
  );
}
