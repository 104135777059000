import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './placeholder.strict-module.css';

export function TablePlaceholder(): React.ReactElement {
  return (
    <tr className={styles.row} data-test-table-placeholder>
      <td aria-hidden="true" className={styles.empty} />
      <td className={styles.cell} data-test-table-item="name">
        <SkeletonLoader.Line width="118px" />
      </td>
      <td className={styles.cell} data-test-table-item="contract-id">
        <SkeletonLoader.Line width="62px" />
      </td>
      <td className={styles.cell} data-test-table-item="price">
        <SkeletonLoader.Line width="62px" />
      </td>
      <td className={styles.cell} data-test-table-item="renewal-date">
        <SkeletonLoader.Line width="62px" />
      </td>
      <td className={styles.cell} data-test-table-item="status">
        <SkeletonLoader.Block borderRadius="24px" height="24px" width="80px" />
      </td>
      <td aria-hidden="true" className={styles.empty} />
    </tr>
  );
}
