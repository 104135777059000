import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Popover, PopoverSection } from 'qonto/react/components/table-v2/popover';
import type { OperationMethod, Card } from 'qonto/react/graphql';
import styles from './styles.strict-module.css';
import { CardPaymentMethodCellPopover } from './card-popover';
import { FilterByButton } from './filter-by-button';

export function PaymentMethodCellPopover({
  paymentMethod,
  card,
}: {
  paymentMethod: OperationMethod;
  card?: Card | null;
}): ReactNode {
  const { formatMessage } = useIntl();

  return card ? (
    <CardPaymentMethodCellPopover card={card} paymentMethod={paymentMethod} />
  ) : (
    <Popover data-testid="payment-method-cell-popover">
      <PopoverSection className={styles.section}>
        <FilterByButton
          data-testid="filter-by-method"
          filterQP={{ 'payment-method': paymentMethod.toLowerCase() }}
          text={formatMessage({
            id: 'transactions.modular_table.payment_method.filter.method',
          })}
        />
      </PopoverSection>
    </Popover>
  );
}
