import { useSegment } from '@repo/poly-hooks/segment';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import type { UpcomingTransaction } from 'qonto/react/hooks/use-upcoming-transactions';

interface FlowLinkManager {
  transitionTo: (options: {
    name: string;
    stepId: string;
    queryParams: { supplierInvoiceId: string; origin: string };
  }) => void;
}

export const usePayNow = (upcomingTransaction: UpcomingTransaction | null): (() => void) => {
  const segment = useSegment();
  const type = upcomingTransaction?.provider_object_type;
  const invoiceId = upcomingTransaction?.provider_object_id;
  const flowLinkManager = useEmberService('flow-link-manager') as unknown as FlowLinkManager;

  return (): void => {
    segment.track('compass_action_clicked', {
      action: 'pay_now',
      type,
    });

    if (invoiceId === undefined) return;

    flowLinkManager.transitionTo({
      name: 'sepa-transfer',
      stepId: 'summary',
      queryParams: {
        supplierInvoiceId: invoiceId,
        origin: 'upcoming-transactions',
      },
    });
  };
};
