/* eslint-disable react/jsx-no-leaked-render -- This rule shouldn't be enforced, && is cleaner than an empty ternary */
import type { ReactNode } from 'react';
import { Tooltip } from '@repo/design-system-kit';
import { IconInfoRoundedOutlined } from '@repo/monochrome-icons';
import { useIntl, FormattedMessage } from 'react-intl';
import cx from 'clsx';
import type { OptionPrice } from 'qonto/react/cards/types/option-price';
import styles from './free-card-pricing.strict-module.css';

interface FreeCardPricingProps {
  optionPrice?: OptionPrice | null;
}

export function FreeCardPricing({ optionPrice }: FreeCardPricingProps): ReactNode {
  const { formatMessage, formatNumber } = useIntl();

  const optionPriceValue = Number(optionPrice?.value);

  return (
    <p className={cx('title-4', styles.free)} data-test-card-pricing-free>
      <FormattedMessage id="cards.steps.choose-card.included" />
      {optionPrice && optionPriceValue > 0 && (
        <span data-test-card-pricing-free-tooltip>
          <Tooltip
            label={formatMessage(
              {
                id: 'cards.steps.choose-card.included-tooltip',
              },
              {
                amount: formatNumber(optionPriceValue, {
                  currency: 'EUR',
                  minimumFractionDigits: 0,
                  style: 'currency',
                }),
              }
            )}
            needsButton
            placement="top"
          >
            <IconInfoRoundedOutlined aria-hidden="true" className={styles.icon} data-test-icon />
          </Tooltip>
        </span>
      )}
    </p>
  );
}
