import { type ReactNode } from 'react';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { FormattedMessage } from 'react-intl';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './styles.strict-module.css';

export function EligibilityLossBanner(): ReactNode {
  const zendeskLocalization = useEmberService('zendesk-localization');

  return (
    <div className={styles['eligibility-loss-banner']} data-test-eligibility-loss-banner>
      <div className={styles.container}>
        <h2 className="title-3 mb-12" data-test-eligibility-loss-banner-title>
          <FormattedMessage id="sdd-collections.tabs.activated-not-eligible-banner.title" />
        </h2>

        <p className="body-2 mb-20" data-test-eligibility-loss-banner-body>
          <FormattedMessage id="sdd-collections.tabs.activated-not-eligible-banner.body" />
        </p>

        <a
          className="btn btn--tertiary mb-8"
          data-test-eligibility-loss-banner-link
          href={zendeskLocalization.getLocalizedArticle(9145313)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FormattedMessage id="sdd-collections.tabs.activated-not-eligible-banner.cta-link-text" />
        </a>
      </div>

      <StaticThemedAsset
        assetPath="/illustrations/ill-cp-onboarding-identity-rejected.svg"
        className={styles.illustration}
        data-test-eligibility-loss-banner-link-img
      />
    </div>
  );
}
