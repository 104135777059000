import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function EyeHiddenOutlined(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" {...props}>
      <path d="m10.89 7.225-.9.9a1.987 1.987 0 0 1-1.87 1.87l-.89.9c.24.07.5.11.77.11 1.66 0 3-1.34 3-3 0-.27-.04-.53-.11-.78ZM8 5.005c-1.66 0-3 1.34-3 3 0 .27.04.53.11.77l.9-.9c.06-1 .86-1.8 1.86-1.86l.9-.91c-.24-.06-.5-.1-.77-.1Z" />
      <path d="M.038 7.755c-.05.16-.05.34 0 .5.46 1.42 1.2 2.58 2.12 3.48l.7-.71c-.8-.78-1.45-1.79-1.85-3.02 1.42-4.35 5.902-5.9 9.493-4.63l.78-.78C7.11.865 1.688 2.585.038 7.755Zm15.925 0c-.46-1.42-1.2-2.58-2.121-3.48l-.71.7c.8.78 1.45 1.79 1.86 3.03-1.43 4.35-5.912 5.9-9.503 4.63l-.77.78c4.171 1.73 9.593.01 11.243-5.16.05-.16.05-.34 0-.5ZM14.842 1.855l-1.76 1.77-.72.71-1.921 1.93-.72.72-2.731 2.74-2.481 2.48-.74.75-1.9 1.9c-.101.1-.231.15-.351.15-.13 0-.26-.05-.36-.15-.2-.19-.2-.51 0-.71l1.76-1.76.71-.72 2.651-2.65 2.731-2.74.72-.72 1.751-1.76.75-.74 1.9-1.91c.1-.1.231-.15.351-.15.13 0 .26.05.36.15.2.19.2.51 0 .71Z" />
    </svg>
  );
}
