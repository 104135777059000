import { useIntl } from 'react-intl';
import type { ReactElement } from 'react';
import cx from 'clsx';
import { useEmberService, useFlags } from '@qonto/react-migration-toolkit/react/hooks';
import { Disclaimer } from '@repo/design-system-kit';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import type { SubscriptionChangeDataContext } from '../../data-context.type';
import styles from './styles.strict-module.css';

interface ConfirmDisclaimersProps {
  context: SubscriptionChangeDataContext;
  hasProductChangeNotAvailableError: boolean;
}

export function ConfirmDisclaimers({
  hasProductChangeNotAvailableError,
  context,
}: ConfirmDisclaimersProps): ReactElement {
  const { formatMessage } = useIntl();
  const { organization } = useOrganizationManager();
  const { featureBooleanPricingItalianDisclaimers, featureBooleanMpItalyCheckout } = useFlags();
  const subscriptionManager = useEmberService('subscription-manager');
  const showDisclaimerForItalianOrganizations =
    featureBooleanPricingItalianDisclaimers && organization.legalCountry === 'IT';
  const showNoRefundDisclaimer = context.warnings?.some(warning => warning.code === 'no_refund');
  const isLegacyPricePlan = (
    subscriptionManager.currentPricePlan as { disabled: boolean } | undefined
  )?.disabled;
  return (
    <>
      {context.isBlockedForItaly ? (
        <div data-test-price-plans-confirm-blocked-disclaimer>
          <Disclaimer.Block className={cx(styles.disclaimer, 'mb-24')} level="error">
            {formatMessage({ id: 'subscription.account-suspended.no-action-allowed' })}
          </Disclaimer.Block>
        </div>
      ) : null}
      {showDisclaimerForItalianOrganizations ? (
        <div data-test-price-plans-confirm-italian-disclaimer>
          <Disclaimer.Block className={cx(styles.disclaimer, 'mb-24')}>
            {formatMessage(
              { id: 'subscription.change.bank-of-italy-disclaimer.body' },
              {
                faqUrl: (
                  <a
                    data-test-price-plans-confirm-italian-disclaimer-link
                    href="https://support-it.qonto.com/hc/it/articles/26999640842513-Restrizioni-in-Italia-e-miglioramento-delle-misure-di-antiriciclaggio"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {formatMessage({ id: 'subscription.change.bank-of-italy-disclaimer.link' })}
                  </a>
                ),
              }
            )}
          </Disclaimer.Block>
        </div>
      ) : null}
      {context.hasInsufficientFunds ? (
        <div data-test-balance-error>
          <Disclaimer.Block className={cx(styles.disclaimer, 'mb-24')} level="error">
            {formatMessage({ id: 'subscription.change.confirmation.low-balance' })}
          </Disclaimer.Block>
        </div>
      ) : null}

      {!featureBooleanMpItalyCheckout && hasProductChangeNotAvailableError ? (
        <div data-test-boi-error>
          <Disclaimer.Block className={cx(styles.disclaimer, 'mb-24')} level="error">
            {formatMessage({ id: 'toasts.errors.try-later' })}
          </Disclaimer.Block>
        </div>
      ) : null}

      {isLegacyPricePlan ? (
        <div data-test-legacy-price-plan-disclaimer>
          <Disclaimer.Block className={cx(styles.disclaimer, 'mb-24')}>
            {formatMessage({ id: 'subscription.change.confirmation.warnings.legacy-plan' })}
          </Disclaimer.Block>
        </div>
      ) : null}

      {showNoRefundDisclaimer ? (
        <div data-test-downsize-disclaimer>
          <Disclaimer.Block className={cx(styles.disclaimer, 'mb-24')}>
            {formatMessage({ id: 'subscription.change.cancellation.addon.disclaimer' })}
          </Disclaimer.Block>
        </div>
      ) : null}
    </>
  );
}
