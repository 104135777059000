import { useEffect, useState, type ReactNode } from 'react';
import { Popup, Button } from '@repo/design-system-kit';
import { LOCALES_DEFINITION } from '@repo/shared-config/app/constants/locales';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import ENV from 'qonto/config/environment';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import styles from './esm-addon-features-popup.strict-module.css';

export const ESM_POPUP_STORAGE_KEY = 'esm-features-popup-displayed';

export const SEGMENT_TRACKING_EVENTS = {
  events: {
    displayed: 'discovery_promotional-pop-up_displayed',
    clicked: 'discovery_promotional-pop-up_clicked',
  },
  features: {
    'audience-1': 'Forced_migrated_add_on_1',
    'audience-2': 'Forced_migrated_add_on_2',
    'audience-3': 'Forced_migrated_add_on_3',
  },
};
export const TOUR_LINKS = {
  'audience-1': '/?tdm=add-a-manager',
  'audience-2': '/?tdm=reinforce-control-over-teams-cards',
  'audience-3': '/?tdm=customize-approval-workflows',
};

interface EsmAddonFeaturesPopupProps {
  audience: 'audience-1' | 'audience-2' | 'audience-3';
}

export function EsmAddonFeaturesPopup(props: EsmAddonFeaturesPopupProps): ReactNode {
  const [isOpen, setIsOpen] = useState(false);
  const segment = useEmberService('segment');
  const { formatMessage, locale } = useIntl();

  const popupDelay = ENV.environment === 'test' ? 0 : 2000;
  const isTest = ENV.environment === 'test';
  useEffect(() => {
    if (safeLocalStorage.getItem(ESM_POPUP_STORAGE_KEY) === 'true') {
      return;
    }
    const timeout = setTimeout(() => {
      setIsOpen(true);
      segment.track(SEGMENT_TRACKING_EVENTS.events.displayed, {
        feature:
          SEGMENT_TRACKING_EVENTS.features[
            props.audience as keyof typeof SEGMENT_TRACKING_EVENTS.features
          ],
      });
      safeLocalStorage.setItem(ESM_POPUP_STORAGE_KEY, 'true');
    }, popupDelay);

    return () => {
      clearTimeout(timeout);
    };
  }, [segment, props.audience, popupDelay]);

  // react-aria modal escapes the test container, we render it where it can find the modal (i.e. <main> component)
  const TEST_PORTAL_CONTAINER =
    document.getElementById('ember-testing') ?? document.querySelector('main') ?? undefined;

  const handleConfirm = (): void => {
    segment.track(SEGMENT_TRACKING_EVENTS.events.clicked, {
      feature: SEGMENT_TRACKING_EVENTS.features[props.audience],
    });
    setIsOpen(false);
    window.location.replace(`${window.location.pathname}${TOUR_LINKS[props.audience]}`);
  };

  const handleCancel = (): void => {
    setIsOpen(false);
  };

  const getSegmentedCopy = (key: string): string => {
    return `pricing-migration.${props.audience}.popup-modal.${key}`;
  };

  const getAssetUrl = (): string => {
    const isItalianOrPortuguese =
      locale === LOCALES_DEFINITION.IT.code || locale === LOCALES_DEFINITION.PT.code;
    return isItalianOrPortuguese
      ? `/illustrations/esm-promotional-popup/${props.audience}-illustration-en.svg`
      : `/illustrations/esm-promotional-popup/${props.audience}-illustration-${locale}.svg`;
  };
  const isGerman = locale === LOCALES_DEFINITION.DE.code;
  const isAudience3 = props.audience === 'audience-3';
  return (
    <Popup.Root
      data-test-id="esm-features-popup"
      isDismissable
      isOpen={isOpen}
      portalContainer={isTest ? TEST_PORTAL_CONTAINER : undefined}
    >
      <Popup.CloseButton onPress={handleCancel} />
      <Popup.Illustration isPromotional>
        <div className={isGerman && isAudience3 ? styles['banner-de'] : styles.banner}>
          <StaticThemedAsset
            alt=""
            assetPath={getAssetUrl()}
            className={isGerman ? styles[`${props.audience}-de`] : styles[props.audience]}
            data-test-id="modal-banner"
          />
        </div>
      </Popup.Illustration>
      <Popup.Header>
        <Popup.Title data-test-id="modal-title">
          <FormattedMessage id={getSegmentedCopy('title')} />
        </Popup.Title>
        <Popup.Subtitle data-test-id="modal-subtitle" style={{ whiteSpace: 'pre-line' }}>
          <FormattedMessage
            id={getSegmentedCopy('subtitle')}
            values={{
              faqUrl: (
                <a
                  className="body-link"
                  data-test-id="modal-subtitle-link"
                  href={formatMessage({
                    id: getSegmentedCopy('link-url'),
                  })}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {formatMessage({
                    id: getSegmentedCopy('link-text'),
                  })}
                </a>
              ),
            }}
          />
        </Popup.Subtitle>
      </Popup.Header>
      <Popup.Footer>
        <Button
          aria-label={formatMessage({ id: getSegmentedCopy('cta') })}
          data-test-id="modal-cta"
          onPress={handleConfirm}
          variant="primary"
        >
          <FormattedMessage id={getSegmentedCopy('cta')} />
        </Button>
      </Popup.Footer>
    </Popup.Root>
  );
}
