import { useState, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'clsx';
import { Button, LottiePlayer } from '@repo/design-system-kit';
import { useThemedAssetPath } from 'qonto/react/hooks/use-themed-asset-path';
import styles from './styles.strict-module.css';

interface TimeoutProps {
  /**
   * Callback for when the close button is clicked
   */
  onClose: () => void;
}

export function Timeout({ onClose }: TimeoutProps): ReactElement {
  const [isAnimationReady, setIsAnimationReady] = useState(false);

  const lottiePath = useThemedAssetPath('/lotties/hourglass.json');

  const playAnimation = (): void => {
    setIsAnimationReady(true);
  };

  return (
    <section
      className={styles.wrapper}
      data-test-transfers-multi-status
      data-test-transfers-multi-status-type="timeout"
    >
      <LottiePlayer
        autoplay
        className={cx(styles.lottie, !isAnimationReady && styles.hidden)}
        data-test-transfers-multi-status-illustration=""
        loop
        onDataReady={playAnimation}
        path={lottiePath}
      />

      <div className={styles.content}>
        <h1 className="title-1 mb-16" data-test-transfers-multi-timeout-title>
          <FormattedMessage id="transfers.modals.bulk.timeout.title" />
        </h1>
        <p className="body-1" data-test-transfers-multi-timeout-description>
          <FormattedMessage id="transfers.modals.bulk.timeout.description" />
        </p>
      </div>

      <Button
        data-test-transfers-multi-timeout-close
        onPress={onClose}
        type="button"
        variant="primary"
      >
        <FormattedMessage id="btn.close" />
      </Button>
    </section>
  );
}
