import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { LottiePlayer } from '@repo/design-system-kit';
import { useThemedAssetPath } from 'qonto/react/hooks/use-themed-asset-path';
import styles from './styles.strict-module.css';

interface ActivationCheckpointProps {
  creditorIdentifierRequested?: boolean;
}

export function ActivationCheckpoint({
  creditorIdentifierRequested,
}: ActivationCheckpointProps): ReactNode {
  const { formatMessage } = useIntl();

  const i18nKeys = {
    title: creditorIdentifierRequested
      ? formatMessage({ id: 'sdd-collections.activation-request-checkpoint.title' })
      : formatMessage({ id: 'sdd-collections.activation-verification-checkpoint.title' }),
    subtitle: creditorIdentifierRequested
      ? formatMessage({ id: 'sdd-collections.activation-request-checkpoint.subtitle' })
      : formatMessage({ id: 'sdd-collections.activation-verification-checkpoint.subtitle' }),
  };

  return (
    <div className={styles.container} data-test-sdd-collection-activation-checkpoint>
      <LottiePlayer
        className={styles.lottie}
        data-test-activation-checkpoint-lottie=""
        path={useThemedAssetPath('/lotties/direct-debit-collection/timeout.json')}
      />
      <h2 className={cx('title-2 mb-8', styles.title)} data-test-activation-checkpoint-title>
        {i18nKeys.title}
      </h2>
      <p className="body-2" data-test-activation-checkpoint-subtitle>
        {i18nKeys.subtitle}
      </p>
    </div>
  );
}
