import type { SVGProps, ReactNode } from 'react';

export function CheckmarkRoundedFilled(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.7658 6.3534C12.0262 6.09305 12.0262 5.67094 11.7658 5.41059C11.5055 5.15024 11.0834 5.15024 10.823 5.41059L7.05841 9.17519L5.17648 7.29326C4.91613 7.03291 4.49402 7.03291 4.23367 7.29326C3.97332 7.55361 3.97332 7.97572 4.23367 8.23607L6.58701 10.5894C6.84736 10.8498 7.26947 10.8498 7.52982 10.5894L11.7658 6.3534Z"
        fill="#45B184"
      />
    </svg>
  );
}
