import type { Amount } from 'qonto/react/models/amount';
import type { CashflowForecastFormulaBound } from 'qonto/react/models/cash-flow-forecast-formula';

export interface CashflowForecastFormula {
  frequency_in_months: ForecastFormulaFrequency;
  percentage_increment: number | null;
  sum_increment: Amount<string> | null;
}

export interface CashflowForecastFormulaReadable extends CashflowForecastFormula {
  start: CashflowForecastFormulaBound;
  end: CashflowForecastFormulaBound;
  operation: string;
}

export interface CashflowForecastFormulaUpdatable extends CashflowForecastFormula {
  repeat_until: CashflowForecastFormulaBound;
}

export enum ForecastFormulaFrequency {
  Monthly = 1,
  BiMonthly = 2,
  Quarterly = 3,
  SemiAnnually = 6,
  Annually = 12,
}
