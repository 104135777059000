import type { ReactNode } from 'react';
import styles from './pan.strict-module.css';

interface PanProps {
  isRevealed?: boolean;
  pan: string;
}

export function Pan({ isRevealed = false, pan }: PanProps): ReactNode {
  const panArray = pan.match(/.{1,4}/g);

  return (
    <>
      {/* eslint-disable react/no-array-index-key -- need to use idx to well update the PAN when switching cards */}
      {panArray?.map((panSlice, idx) => (
        <span
          aria-hidden={!isRevealed}
          className={styles['pan-numbers']}
          data-test-card-image-pan={idx}
          key={idx}
        >
          {panSlice}
        </span>
      ))}
    </>
  );
}
