import { SkeletonLoader } from '@repo/design-system-kit';
import cx from 'clsx';
import { type ComponentPropsWithoutRef, type ReactNode } from 'react';
import styles from './loader.strict-module.css';

export function BeneficiaryCardLoader({
  className,
  ...props
}: ComponentPropsWithoutRef<'div'>): ReactNode {
  return (
    <div className={cx(styles.wrapper, className)} {...props} data-test-beneficiary-card-loader>
      <SkeletonLoader.Line data-test-beneficiary-card-loader-line="name" standalone width="104px" />
      <SkeletonLoader.Line
        data-test-beneficiary-card-loader-line="account"
        standalone
        width="240px"
      />
    </div>
  );
}
