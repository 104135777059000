import { type ReactNode } from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './placeholder.strict-module.css';

export function Placeholder(): ReactNode {
  return (
    <div className={styles['placeholder-sidebar']} data-test-sidebar-placeholder>
      <section className={styles.details}>
        <div className={styles['details-header']}>
          <SkeletonLoader.Line width="130px" />
        </div>
        <div className={styles['details-content']}>
          <SkeletonLoader.Line className="mb-24" width="132px" />
          <SkeletonLoader.Line className="mb-24" width="210px" />
          <SkeletonLoader.Line className="mb-24" width="247px" />
          <SkeletonLoader.Line className="mb-24" width="197px" />
          <SkeletonLoader.Line width="174px" />
        </div>
        <div className={styles['details-footer']}>
          <SkeletonLoader.Line width="163px" />
        </div>
      </section>
    </div>
  );
}
