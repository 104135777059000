import { type ReactNode } from 'react';
import cx from 'clsx';
import { IconCertifiedFilled } from '@repo/monochrome-icons';
import { QontoFlower } from 'qonto/react/assets/icons';
import styles from './styles.strict-module.css';

export interface InfoProps {
  /** The beneficiary's full name */
  name: string;
  /** The ISO 4217 three-letter currency code of the beneficiary's account */
  currency: string | null;
  /** The beneficiary's account number formatted for display */
  displayAccountNumber?: string;
  /** Whether the beneficiary is marked as trusted */
  isTrusted?: boolean;
  /** Whether the beneficiary's bank account is a Qonto account */
  isQontoBankAccount?: boolean;
}

export function Info({
  name,
  currency,
  displayAccountNumber,
  isTrusted = false,
  isQontoBankAccount = false,
}: InfoProps): ReactNode {
  return (
    <div className={styles.container} data-test-beneficiary-card-info>
      <div>
        <p className={cx(styles.name, 'body-1')}>
          <span data-test-beneficiary-card-info-name>{name}</span>
          {isTrusted ? (
            <IconCertifiedFilled aria-hidden data-test-beneficiary-item-trusted />
          ) : null}
        </p>

        <p className={cx(styles.account, 'body-2')}>
          {isQontoBankAccount ? (
            <QontoFlower aria-hidden data-test-beneficiary-card-info-account-icon />
          ) : null}
          <span data-test-beneficiary-card-info-account-number>{displayAccountNumber}</span>
        </p>
      </div>

      {currency ? (
        <div className={cx(styles.currency, 'caption')} data-test-beneficiary-card-info-currency>
          {currency}
        </div>
      ) : null}
    </div>
  );
}
