import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import cx from 'clsx';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import { CLAIM_TYPES } from 'qonto/react/constants/transactions';
import { useOrganizationNavigation } from 'qonto/react/shared/hooks/use-organization-navigation';
import styles from './styles.strict-module.css';

interface SuccessProps {
  claimType: string;
  transitionToNext: () => void;
}

interface FlowLinkManager {
  transitionTo: (options: { name: string; stepId: string }) => Promise<void>;
}

export function Success({ claimType, transitionToNext }: SuccessProps): ReactNode {
  const { formatMessage } = useIntl();
  const abilities = useEmberService('abilities');
  const segment = useEmberService('segment');
  const flowLinkManager = useEmberService('flow-link-manager') as unknown as FlowLinkManager;
  const organizationNavigation = useOrganizationNavigation();
  const isFraudClaim = claimType === CLAIM_TYPES.fraud;

  const title = isFraudClaim
    ? formatMessage({
        id: 'dispute-flow.success.fraud.title',
      })
    : formatMessage({
        id: 'dispute-flow.success.default.title',
      });

  const subtitle = isFraudClaim
    ? formatMessage({
        id: 'dispute-flow.success.fraud.subtitle',
      })
    : formatMessage({
        id: 'dispute-flow.success.default.subtitle',
      });

  const shouldShowSecondaryCta: boolean =
    isFraudClaim && (abilities.can('create card') || abilities.can('create card request'));

  const secondaryCta: string = abilities.can('create card')
    ? formatMessage({
        id: 'dispute-flow.success.fraud.create-card',
      })
    : formatMessage({
        id: 'dispute-flow.success.fraud.request-card',
      });

  const onSuccess = (): void => {
    segment.track('cards-chargeback_dispute-in-review-screen_closed', {
      type: claimType,
    });
    transitionToNext();
  };

  const onCreateCard = (): void => {
    transitionToNext();

    if (abilities.can('create card')) {
      segment.track('cards-chargeback_create-new-virtual-card_clicked');

      void flowLinkManager.transitionTo({
        name: 'card-new-digital',
        stepId: 'choose-card',
      });
    } else {
      segment.track('cards-chargeback_request-new-virtual-card_clicked');

      void organizationNavigation('/requests/landing?origin=cards');
    }
  };

  return (
    <>
      <StaticThemedAsset
        alt=""
        assetPath="/illustrations/circle-success.svg"
        className={cx(styles.illustration, 'mb-32')}
        data-test-chargeback-success-success-illustration
      />
      <h1 className="title-1 mb-16" data-test-chargeback-claim-success-title>
        {title}
      </h1>
      <p className="mb-32 body-1" data-test-chargeback-claim-success-subtitle>
        {subtitle}
      </p>
      <button
        className="btn btn--primary"
        data-test-chargeback-success-cta
        onClick={onSuccess}
        type="button"
      >
        {formatMessage({
          id: 'dispute-flow.success.default.confirm',
        })}
      </button>
      {shouldShowSecondaryCta ? (
        <button
          className={cx(styles['create-card-button'], 'btn btn--tertiary')}
          data-test-chargeback-create-card-cta
          onClick={onCreateCard}
          type="button"
        >
          {secondaryCta}
        </button>
      ) : null}
    </>
  );
}
