import { useSegment } from '@repo/poly-hooks/segment';
import type { UpcomingTransaction } from 'qonto/react/hooks/use-upcoming-transactions';
import { useUpdateSupplierInvoice } from 'qonto/react/components/cash-flow/hooks/use-update-supplier-invoice';

export const useScheduleSupplierInvoiceHandler = (
  upcomingTransaction: UpcomingTransaction | null
): (() => void) => {
  const segment = useSegment();
  const type = upcomingTransaction?.provider_object_type;

  return () => {
    segment.track('compass_action_clicked', {
      action: 'schedule_supplier_invoice',
      type,
    });
  };
};

export const useScheduleSupplierInvoice = (): {
  updateSupplierInvoice: (params: {
    type: string;
    supplierInvoiceId: string;
    newDate: string;
    counterpartyName: string;
  }) => void;
} => {
  const segment = useSegment();
  const { mutate } = useUpdateSupplierInvoice();

  const updateSupplierInvoice = ({
    type,
    supplierInvoiceId,
    newDate,
    counterpartyName,
  }: {
    type: string;
    supplierInvoiceId: string;
    newDate: string;
    counterpartyName: string;
  }): void => {
    segment.track('compass_action_clicked', {
      action: 'schedule_supplier_invoice_submit',
      type,
    });

    mutate({
      supplierInvoiceId,
      updatedFields: { due_date: newDate },
      counterpartyName,
    });
  };

  return { updateSupplierInvoice };
};
