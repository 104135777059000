import { type InfiniteData } from '@tanstack/react-query';
import { useMemo } from 'react';
import { type SearchTransactionsResult } from 'qonto/react/hooks/use-fetch-infinite-transactions';
import { camelizeKeys } from 'qonto/react/api/utils/camelize-keys';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import type BankAccount from 'qonto/models/bank-account';
import { useTransactionOperationMethodLabels } from 'qonto/react/hooks/use-transaction-operation-method';
import type { OperationMethod } from 'qonto/react/graphql';
import type { TransactionTableRow } from './transactions-table';

export function formatTransactions(
  transactions: InfiniteData<SearchTransactionsResult> | undefined,
  accounts?: BankAccount[],
  operationMethodLabels?: Record<OperationMethod, string>
): TransactionTableRow[] {
  if (!transactions) return [];

  const flatDataPages = transactions.pages.flat();
  const formattedTransactions = flatDataPages.map(page =>
    page.transactions.map(trans => {
      const transaction = camelizeKeys(trans);
      const operationMethodKey = transaction.operationMethod.toUpperCase() as OperationMethod;
      const displayType = operationMethodLabels?.[operationMethodKey] ?? '';

      return {
        transactionDetails: {
          account: accounts?.find(account => account.id === transaction.bankAccountId),
          id: transaction.id,
          counterpartyName: transaction.counterpartyName,
          rawCounterpartyName: transaction.rawCounterpartyName,
          accountId: transaction.bankAccountId,
          operationMethod: transaction.operationMethod,
          displayType,
        },
        amountDetails: {
          side: transaction.side,
          amount: transaction.amount,
          amountCurrency: transaction.amountCurrency,
          emittedAt: transaction.emittedAt,
        },
        category: {
          id: transaction.categoryAssignment?.id ?? null,
        },
      };
    })
  );

  return formattedTransactions.flat();
}

export const useFormattedTransactions = (
  transactionsResult: InfiniteData<SearchTransactionsResult> | undefined
): TransactionTableRow[] => {
  const organization = useOrganizationManager();
  const operationMethodLabels = useTransactionOperationMethodLabels();
  const accounts = organization.accounts as BankAccount[];

  return useMemo(() => {
    return formatTransactions(transactionsResult, accounts, operationMethodLabels);
  }, [accounts, operationMethodLabels, transactionsResult]);
};
