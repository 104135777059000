import type { ReactNode } from 'react';
import dayjs from 'dayjs';
import { Button } from '@repo/design-system-kit';
import { IconChevronLeftOutlined, IconChevronRightOutlined } from '@repo/monochrome-icons';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import { useEmberService, useFlags } from '@qonto/react-migration-toolkit/react/hooks';
import type { SidePanelHeaderProps } from '../header';
import styles from './default-header.strict-module.css';

export function DefaultHeader({
  selectedInterval,
  frequencyFormat,
  selectedCategories,
  isFlowSelected,
  periodNavigation,
}: SidePanelHeaderProps): ReactNode {
  const { formatMessage } = useIntl();

  const segment = useEmberService('segment');
  const queryClient = useQueryClient();
  const abilities = useEmberService('abilities');
  const { featureBooleanCashFlowFirstTimeExperience } = useFlags();

  const getFlowLabel = (): string => {
    if (selectedCategories[0]?.flowType === 'inflows') {
      return formatMessage({ id: 'cash-flow-categories.group-title.inflows' });
    }
    return formatMessage({ id: 'cash-flow-categories.group-title.outflows' });
  };

  const clearTransactionsCache = (): void => {
    queryClient.removeQueries({ queryKey: ['infinite-transactions'] });
  };

  const trackPeriodNavigation = (direction: 'previous' | 'next'): void => {
    segment.track('cash-flow_sidepanel_navigate-period', {
      direction,
    });
  };

  const categoryName = isFlowSelected ? getFlowLabel() : (selectedCategories[0]?.name ?? '');

  const disableNavigationButtons =
    Boolean(featureBooleanCashFlowFirstTimeExperience) &&
    abilities.cannot('fully interact with forecast cash-flow');

  return (
    <div>
      <div className={styles.period}>
        <div>
          {/*TODO: add aria-label*/}
          <Button
            data-testid="cash-flow-sidepanel-header-previous-period"
            iconOnly
            isDisabled={periodNavigation.isFirstPeriod || disableNavigationButtons}
            onPress={() => {
              clearTransactionsCache();
              trackPeriodNavigation('previous');
              periodNavigation.onPreviousMonth?.();
            }}
            size="small"
            variant="tertiary"
          >
            <IconChevronLeftOutlined />
          </Button>
          <Button
            data-testid="cash-flow-sidepanel-header-next-period"
            iconOnly
            isDisabled={periodNavigation.isLastPeriod || disableNavigationButtons}
            onPress={() => {
              clearTransactionsCache();
              trackPeriodNavigation('next');
              periodNavigation.onNextMonth?.();
            }}
            size="small"
            variant="tertiary"
          >
            <IconChevronRightOutlined />
          </Button>
        </div>
        <span
          className={cx('body-2', styles.periodTitle)}
          data-testid="cash-flow-sidepanel-header-period"
        >
          {dayjs(selectedInterval?.start).format(frequencyFormat)}
        </span>
      </div>
      <h1 className="title-3" data-testid="cash-flow-sidepanel-header-category">
        {categoryName}
      </h1>
    </div>
  );
}
