import { type ReactNode } from 'react';
import cx from 'clsx';
import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './placeholder-row.strict-module.css';

interface PlaceholderRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  isApproveCompactView?: boolean;
}

export function PlaceholderRow({ isApproveCompactView, ...props }: PlaceholderRowProps): ReactNode {
  return (
    <tr
      aria-hidden="true"
      className={cx(styles.row, isApproveCompactView && styles['row-compact'])}
      data-test-request-table-placeholder
      data-test-request-table-placeholder-approver
      {...props}
    >
      <td className={cx(styles.cell, styles.empty)} />

      <td className={styles.cell}>
        <div className={styles.type}>
          <SkeletonLoader.Avatar height="40px" width="55px" />
          <SkeletonLoader.Header smallLinesWidth="192px" width="137px" />
        </div>
      </td>

      <td className={styles.cell}>
        <SkeletonLoader.Line width="120px" />
      </td>

      <td className={styles.cell}>
        <SkeletonLoader.Line width="96px" />
      </td>

      <td className={styles.cell}>
        <SkeletonLoader.Line className={styles['amount-content']} width="104px" />
      </td>

      <td className={cx(styles.cell, styles['cell-approve'])}>
        <SkeletonLoader.Line
          className={styles['approve-content']}
          width={isApproveCompactView ? '72px' : '232px'}
        />
      </td>

      <td className={cx(styles.cell, styles.empty)} />
    </tr>
  );
}
