import { useMemo } from 'react';
import { useDeepMemo } from 'qonto/react/hooks/use-deep-memo';
import {
  UNCATEGORIZED_CATEGORY,
  type CashflowParentCategory,
} from 'qonto/react/models/cash-flow-category';
import {
  DEFAULT_TIMESERIES,
  type CashflowSide,
  type CashflowTimeframe,
  type CashflowTimeseries,
} from 'qonto/react/models/cash-flow-timeseries';
import type { Amount } from 'qonto/react/models/amount';
import { useMapCategoriesWithData } from 'qonto/react/utils/cashflow-categories/categories-data-map';
import { CashflowForecastEntrySource } from 'qonto/react/models/cash-flow-forecast-entry';
import {
  getCategoryType,
  type CategoriesSideTables,
  type CategoriesTableColumn,
  type CategoriesTableRow,
} from '../models/categories-table-display';

const DEFAULT_AMOUNT: Amount<string> = { value: '0', currency: 'EUR' };

export const getCategoryColumns = (
  side: CashflowSide,
  timeframes: CashflowTimeframe[],
  categoryId: string | null,
  subcategoryId?: string | null
): CategoriesTableColumn[] => {
  return timeframes.map((timeframe: CashflowTimeframe) => {
    const flows = timeframe.cashFlowData[side];
    const datapoint = flows.categoriesDataPoints.find(({ categoryId: id }) => id === categoryId);

    const subcategoryDataPoint = datapoint?.subcategoriesDataPoints?.find(
      ({ subcategoryId: id }) => id === subcategoryId
    );

    let amount = DEFAULT_AMOUNT;
    let forecast;

    if (subcategoryId) {
      amount = subcategoryDataPoint?.amountSum ?? DEFAULT_AMOUNT;
      forecast = subcategoryDataPoint?.forecast;
    } else {
      amount = datapoint?.amountSum ?? DEFAULT_AMOUNT;
      forecast = datapoint?.forecast;
    }

    return {
      amount,
      forecast,
      interval: {
        start: new Date(timeframe.inclusiveStartDate).getTime(),
        end: new Date(timeframe.exclusiveEndDate).getTime(),
      },
    };
  });
};

export const getCategoryRow = (
  category: CashflowParentCategory,
  timeframes: CashflowTimeframe[],
  side: CashflowSide
): CategoriesTableRow => {
  const columns = getCategoryColumns(side, timeframes, category.id);
  const { id, name, colorKey: color, iconKey: icon } = category;

  const subRows: CategoriesTableRow[] =
    category.subcategories?.map(subcategory => {
      return {
        id: subcategory.id,
        name: subcategory.name,
        color: subcategory.colorKey,
        type: getCategoryType(subcategory as CashflowParentCategory),
        flowType: side,
        columns: getCategoryColumns(side, timeframes, category.id, subcategory.id),
      };
    }) ?? [];

  return {
    id,
    name,
    color,
    icon,
    type: getCategoryType(category),
    flowType: side,
    columns,
    subRows,
  };
};

export const getSideAmountSums = (
  timeframes: CashflowTimeframe[],
  side: CashflowSide
): CategoriesTableColumn[] => {
  return timeframes.map((timeframe: CashflowTimeframe) => {
    const { amountSum: amount, forecast, projectedAmount } = timeframe.cashFlowData[side];

    return {
      amount,
      forecastAmountSum: forecast?.amountSum,
      forecast: forecast?.amountSum
        ? {
            amount: forecast.amountSum,
            actualPercentage: forecast.actualPercentage,
            projectedPercentage: forecast.projectedPercentage,
            source: CashflowForecastEntrySource.Aggregated,
          }
        : undefined,
      projectedAmount,
      interval: {
        start: new Date(timeframe.inclusiveStartDate).getTime(),
        end: new Date(timeframe.exclusiveEndDate).getTime(),
      },
    };
  });
};

export const useCategoriesCashflows = (
  offset: number,
  displayedMonths: number,
  timeseries: CashflowTimeseries = DEFAULT_TIMESERIES
): CategoriesSideTables => {
  const { mapCategoriesWithData } = useMapCategoriesWithData();

  // Keep offset - 2 and offset + displayedMonths + 2 months buffer data
  const maxBufferMonths = 2;
  const previousBuffer = Math.min(offset, maxBufferMonths);

  const bufferStart = Math.max(0, offset - previousBuffer);
  const bufferEnd = offset + displayedMonths + maxBufferMonths;

  const selectedTimeframes = useDeepMemo(
    () => timeseries.timeframes.slice(bufferStart, bufferEnd),
    [timeseries, offset, displayedMonths]
  );

  const categoriesData = useMemo(
    () =>
      mapCategoriesWithData({
        inflow: [...timeseries.categoriesData.inflow, UNCATEGORIZED_CATEGORY],
        outflow: [...timeseries.categoriesData.outflow, UNCATEGORIZED_CATEGORY],
      }),
    [mapCategoriesWithData, timeseries.categoriesData.inflow, timeseries.categoriesData.outflow]
  );

  const { inflow: inflowCategories, outflow: outflowCategories } = categoriesData;

  const bufferInflows: CategoriesTableRow[] = inflowCategories.map(category =>
    getCategoryRow(category, selectedTimeframes, 'inflows')
  );
  const bufferOutflows: CategoriesTableRow[] = outflowCategories.map(category =>
    getCategoryRow(category, selectedTimeframes, 'outflows')
  );

  const bufferInflowSums = getSideAmountSums(selectedTimeframes, 'inflows');
  const bufferOutflowSums = getSideAmountSums(selectedTimeframes, 'outflows');

  // Calculate offset within buffer for display window data
  const bufferOffset = offset - bufferStart;

  const displayedInflows = bufferInflows.map(row => ({
    ...row,
    columns: row.columns.slice(bufferOffset, bufferOffset + displayedMonths),
    subRows: row.subRows?.map(subRow => ({
      ...subRow,
      columns: subRow.columns.slice(bufferOffset, bufferOffset + displayedMonths),
    })),
  }));

  const displayedOutflows = bufferOutflows.map(row => ({
    ...row,
    columns: row.columns.slice(bufferOffset, bufferOffset + displayedMonths),
    subRows: row.subRows?.map(subRow => ({
      ...subRow,
      columns: subRow.columns.slice(bufferOffset, bufferOffset + displayedMonths),
    })),
  }));

  const displayedInflowSums = bufferInflowSums.slice(bufferOffset, bufferOffset + displayedMonths);
  const displayedOutflowSums = bufferOutflowSums.slice(
    bufferOffset,
    bufferOffset + displayedMonths
  );

  return {
    inflows: displayedInflows,
    outflows: displayedOutflows,
    inflowSums: displayedInflowSums,
    outflowSums: displayedOutflowSums,
    bufferData: {
      inflows: bufferInflows,
      outflows: bufferOutflows,
      inflowSums: bufferInflowSums,
      outflowSums: bufferOutflowSums,
    },
  };
};
