import { type ReactNode } from 'react';
import cx from 'clsx';
import { FormattedMessage } from '@repo/domain-kit/shared';
import { SortIcon } from 'qonto/react/components/paginated-table';
import { DEFAULT_SORT_ORDER } from 'qonto/constants/sort';
import styles from './header.strict-module.css';

type SortOrder = 'asc' | 'desc';

interface HeaderProps {
  sortBy?: string;
  handleSortBy?: (sortBy: string) => void;
}

export function Header({ sortBy, handleSortBy }: HeaderProps): ReactNode {
  const sortField = sortBy?.split(':')[0];
  const sortOrder = sortBy ? sortBy.split(':')[1] : DEFAULT_SORT_ORDER;

  const handleSort = (name: string): void => {
    if (!handleSortBy) {
      return;
    }

    let newSortOrder: SortOrder = DEFAULT_SORT_ORDER;
    if (name === sortField) {
      newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    }

    handleSortBy(`${name}:${newSortOrder}`);
  };

  const getAriaSort = (field: string): 'ascending' | 'descending' | 'none' => {
    if (field !== sortField) {
      return 'none';
    }
    return sortOrder === 'asc' ? 'ascending' : 'descending';
  };

  return (
    <>
      <th aria-hidden="true" className={cx(styles['header-cell'], styles.empty)} scope="col" />

      <th
        aria-sort={getAriaSort('emitter_name')}
        className={cx(styles['header-cell'], styles.emitter)}
        data-test-table-header-emitter
        scope="col"
      >
        <button
          className={cx(styles['header-content'], sortField === 'emitter_name' && styles.active)}
          onClick={(): void => {
            handleSort('emitter_name');
          }}
          type="button"
        >
          <FormattedMessage id="checks.columns.emitter" />
          <SortIcon isActive={sortField === 'emitter_name'} isAscending={sortOrder === 'asc'} />
        </button>
      </th>

      <th
        aria-sort={getAriaSort('emitted_date')}
        className={cx(styles['header-cell'], styles.date)}
        data-test-table-header-emitted-date
        scope="col"
      >
        <button
          className={cx(styles['header-content'], sortField === 'emitted_date' && styles.active)}
          onClick={(): void => {
            handleSort('emitted_date');
          }}
          type="button"
        >
          <FormattedMessage id="checks.columns.requested_at" />
          <SortIcon isActive={sortField === 'emitted_date'} isAscending={sortOrder === 'asc'} />
        </button>
      </th>

      <th
        aria-sort={getAriaSort('amount')}
        className={cx(styles['header-cell'], styles.amount)}
        data-test-table-header-amount
        scope="col"
      >
        <button
          className={cx(styles['header-content'], sortField === 'amount' && styles.active)}
          onClick={(): void => {
            handleSort('amount');
          }}
          type="button"
        >
          <FormattedMessage id="checks.columns.amount" />
          <SortIcon isActive={sortField === 'amount'} isAscending={sortOrder === 'asc'} />
        </button>
      </th>

      <th
        aria-sort={getAriaSort('status')}
        className={cx(styles['header-cell'], styles.status)}
        data-test-table-header-status
        scope="col"
      >
        <button
          className={cx(styles['header-content'], sortField === 'status' && styles.active)}
          onClick={(): void => {
            handleSort('status');
          }}
          type="button"
        >
          <FormattedMessage id="checks.columns.status" />
          <SortIcon isActive={sortField === 'status'} isAscending={sortOrder === 'asc'} />
        </button>
      </th>

      <th aria-hidden="true" className={cx(styles['header-cell'], styles.empty)} scope="col" />

      <th className="sr-only" data-test-table-header-details scope="col">
        <FormattedMessage id="checks.columns.view-details" />
      </th>
    </>
  );
}
