import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { ComparisonIcons } from '@repo/cards-kit/assets';
import { CARD_LEVELS } from 'qonto/constants/cards';
import styles from './card-features.strict-module.css';

interface CardFeaturesProps {
  cardLevel?: string;
}

export function CardFeatures({ cardLevel }: CardFeaturesProps): ReactNode {
  const isMetalCard = cardLevel === CARD_LEVELS.METAL;

  return (
    <div data-test-card-details-card-features={cardLevel}>
      <h3 className="title-2 mb-32" data-test-card-features-title>
        <FormattedMessage id="cards.details.features.title" />
      </h3>
      <div className="mb-56">
        <div className={styles['business-cards-item']}>
          <img
            alt=""
            className={styles['business-cards-icon']}
            data-test-card-features-lock-image
            loading="lazy"
            src={isMetalCard ? ComparisonIcons.IconLockX : ComparisonIcons.IconLock}
          />
          <div className={styles['business-cards']}>
            <h4 className="title-4 mb-4" data-test-card-features-lock-title>
              <FormattedMessage id="cards.details.features.lock.label" />
            </h4>
            <p className="body-2" data-test-card-features-lock-text>
              <FormattedMessage id="cards.details.features.lock.description" />
            </p>
          </div>
        </div>
        <div className={styles['business-cards-item']}>
          <img
            alt=""
            className={styles['business-cards-icon']}
            data-test-card-features-pin-image
            loading="lazy"
            src={isMetalCard ? ComparisonIcons.IconPinX : ComparisonIcons.IconPin}
          />
          <div className={styles['business-cards']}>
            <h4 className="title-4 mb-4" data-test-card-features-pin-title>
              <FormattedMessage id="cards.details.features.pin.label" />
            </h4>
            <p className="body-2" data-test-card-features-pin-text>
              <FormattedMessage id="cards.details.features.pin.description" />
            </p>
          </div>
        </div>
        <div className={styles['business-cards-item']}>
          <img
            alt=""
            className={styles['business-cards-icon']}
            data-test-card-features-settings-image
            loading="lazy"
            src={isMetalCard ? ComparisonIcons.IconParamX : ComparisonIcons.IconParam}
          />
          <div className={styles['business-cards']}>
            <h4 className="title-4 mb-4" data-test-card-features-settings-title>
              <FormattedMessage id="cards.details.features.settings.label" />
            </h4>
            <p className="body-2" data-test-card-features-settings-text>
              <FormattedMessage id="cards.details.features.settings.description" />
            </p>
          </div>
        </div>
        <div className={styles['business-cards-item']}>
          <img
            alt=""
            className={styles['business-cards-icon']}
            data-test-card-features-secure-image
            loading="lazy"
            src={isMetalCard ? ComparisonIcons.IconSecureX : ComparisonIcons.IconSecure}
          />
          <div className={styles['business-cards']}>
            <h4 className="title-4 mb-4" data-test-card-features-secure-title>
              <FormattedMessage id="cards.details.features.secure.label" />
            </h4>
            <p className="body-2" data-test-card-features-secure-text>
              <FormattedMessage id="cards.details.features.secure.description" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
