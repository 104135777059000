import React from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './placeholder.strict-module.css';

export function F24TablePlaceholder(): React.ReactElement {
  return (
    <tr className={styles.row} data-test-f24-table-placeholder>
      <td className={styles.cell}>
        <div className={styles['cell-content']}>
          <SkeletonLoader.Block borderRadius="32%" className={styles.icon} />
          <SkeletonLoader.Header lines={1} smallLinesWidth="40%" width="60%" />
        </div>
      </td>
      <td className={styles.cell} key="placeholder-line-0">
        <SkeletonLoader.Line className={styles['align-right']} width="80px" />
      </td>
      <td className={styles.cell} key="placeholder-line-1">
        <SkeletonLoader.Line className={styles['align-right']} width="80px" />
      </td>
    </tr>
  );
}
