/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import { type ReactNode } from 'react';
import cx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import styles from './tile.strict-module.css';

interface TileProps {
  page?: string;
  className?: string;
}

export function InContextIntegrationsSideDrawerIntegrationTile({
  page,
  className,
  ...props
}: TileProps): ReactNode {
  const { formatMessage } = useIntl();
  const segment = useEmberService('segment');

  const handleClick = (): void => {
    segment.track('connect_API-own-integration_clicked', {
      source: 'in-context',
      page,
    });
  };

  return (
    <div className={cx(styles.tile, className)} data-test-side-drawer-tile {...props}>
      <p className="body-1" data-test-title>
        <FormattedMessage id="in-context-integrations.side-drawer.tile.text" />
      </p>
      <div>
        <a
          className="body-link"
          data-test-link
          href={formatMessage({ id: 'in-context-integrations.side-drawer.tile.url' })}
          onClick={handleClick}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FormattedMessage id="in-context-integrations.side-drawer.tile.linked-text" />
        </a>
        <span>.</span>
      </div>
    </div>
  );
}
