/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { Sidebar } from '@repo/cards-kit/components';
import {
  IconCheckmarkRoundedFilled,
  IconCrossRoundedFilled,
  IconPenOutlined,
} from '@repo/monochrome-icons';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { Link } from '@qonto/react-migration-toolkit/react/components';
import type { CardModel } from 'qonto/react/cards/models/card';
import styles from './options.strict-module.css';

interface OptionsProps {
  atmOption: boolean;
  card: CardModel;
  cardId: string;
  foreignOption: boolean;
  nfcOption: boolean;
  onlineOption: boolean;
  organizationSlug: string;
}

export function Options({
  atmOption,
  card,
  cardId,
  foreignOption,
  nfcOption,
  onlineOption,
  organizationSlug,
}: OptionsProps): ReactNode {
  const abilities = useEmberService('abilities');
  const segment = useEmberService('segment');
  const { formatMessage } = useIntl();

  const options: { label: string; value: boolean }[] = [
    { label: formatMessage({ id: 'cards.sidebar.options.nfc' }), value: nfcOption },
    { label: formatMessage({ id: 'cards.sidebar.options.atm' }), value: atmOption },
    { label: formatMessage({ id: 'cards.sidebar.options.online-payment' }), value: onlineOption },
  ];

  if (abilities.can('enable abroad option card')) {
    options.splice(2, 0, {
      label: formatMessage({ id: 'cards.sidebar.options.foreign-payment' }),
      value: foreignOption,
    });
  }

  const handleOnEditClick = (): void => {
    segment.track('cards_side_panel_settings_edit_clicked');
  };

  const Edit = (
    <Link
      aria-label={`${formatMessage({ id: 'btn.edit' })} ${formatMessage({ id: 'cards.sidebar.options.title' })}`}
      className={cx('btn btn--small btn--icon-only', styles.edit)}
      data-test-section-options-edit=""
      onClick={handleOnEditClick}
      to={`/organizations/${organizationSlug}/cards/settings/${cardId}/options`}
    >
      <IconPenOutlined aria-hidden />
    </Link>
  );

  return (
    <Sidebar.Section
      action={abilities.can('update operational card settings card', card) ? Edit : null}
      title={formatMessage({ id: 'cards.sidebar.options.title' })}
    >
      <ul className={styles.options} data-test-security-options>
        {options.map((opt: { label: string; value: boolean }) => (
          <li
            className={cx('body-2', 'mb-16', styles.option)}
            data-test-security-option={opt.label}
            key={opt.label}
          >
            <div>{opt.label}</div>
            <div className={styles['option-icon']}>
              {opt.value ? (
                <IconCheckmarkRoundedFilled
                  aria-label={formatMessage({ id: 'a11y.icons.alt-values.activated' })}
                  className={styles['option-enabled']}
                />
              ) : (
                <IconCrossRoundedFilled
                  aria-label={formatMessage({ id: 'a11y.icons.alt-values.deactivated' })}
                />
              )}
            </div>
          </li>
        ))}
      </ul>
    </Sidebar.Section>
  );
}
