import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { FIRST_TIME_EXPERIENCE_STATES } from 'qonto/services/cash-flow-manager';
import { useOpenFirstRowCurrentMonthSidepanel } from '../../hooks/use-open-sidepanel-first-row-current-month';
import { OverlayGradient } from './gradient-overlay';
import { OverlayCta } from './overlay-cta';

interface ChartOverlayProps {
  numberOfColumns: number;
}

function FirstTimeExperienceCta({ numberOfColumns }: ChartOverlayProps): ReactNode {
  const { formatMessage } = useIntl();
  const openSidepanel = useOpenFirstRowCurrentMonthSidepanel(numberOfColumns);
  return (
    <OverlayCta
      ctaAction={openSidepanel}
      ctaText={formatMessage({
        id: 'cash-flow.first-time-experience.chart-overlay.set-forecast.cta',
      })}
      subtitle={formatMessage({
        id: 'cash-flow.first-time-experience.chart-overlay.set-forecast.subheading',
      })}
      title={formatMessage({
        id: 'cash-flow.first-time-experience.chart-overlay.set-forecast.heading',
      })}
    />
  );
}
function UpsellCta(): ReactNode {
  const { formatMessage } = useIntl();
  const upsellManager = useEmberService('upsell-manager');
  return (
    <OverlayCta
      ctaAction={() => upsellManager.upgradeRecommendationWithTransition('cfm_forecast')}
      ctaText={formatMessage({ id: 'cash-flow.first-time-experience.chart-overlay.try-free.cta' })}
      subtitle={formatMessage({
        id: 'cash-flow.first-time-experience.chart-overlay.try-free.subheading',
      })}
      title={formatMessage({
        id: 'cash-flow.first-time-experience.chart-overlay.try-free.heading',
      })}
    />
  );
}

export function ChartOverlay({ numberOfColumns }: ChartOverlayProps): ReactNode {
  const { firstTimeExperienceState } = useEmberService('cash-flow-manager');

  if (firstTimeExperienceState === FIRST_TIME_EXPERIENCE_STATES.FORECAST_SET) {
    return (
      <OverlayGradient numberOfColumns={numberOfColumns}>
        <UpsellCta />
      </OverlayGradient>
    );
  }

  return (
    <OverlayGradient numberOfColumns={numberOfColumns}>
      <FirstTimeExperienceCta numberOfColumns={numberOfColumns} />
    </OverlayGradient>
  );
}
