import React, { type ReactNode } from 'react';
import styles from './sidebar-info-list.strict-module.css';

interface SidebarInfoListProps {
  children: ReactNode;
}

export function SidebarInfoList({ children, ...props }: SidebarInfoListProps): ReactNode {
  return (
    <div className={styles.infobox}>
      <dl className={styles.infolist} data-test-sidebar-info-list {...props}>
        {children}
      </dl>
    </div>
  );
}
