import type { SVGProps, ReactNode } from 'react';

export function ArrowBottomRoundedFilled(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <g clipPath="url(#a)">
        <path
          fill="#636360"
          fillRule="evenodd"
          d="M8 0C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8Zm0 4a.5.5 0 0 0-.5.5v5.793L4.854 7.646a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l3.5-3.5a.5.5 0 0 0-.708-.708L8.5 10.293V4.5A.5.5 0 0 0 8 4Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
