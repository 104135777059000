import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from '@repo/design-system-kit';
import { Info } from 'qonto/react/assets/icons/info';
import styles from './payable-amount-tooltip.strict-module.css';

export interface PayableAmountTooltipProps {
  totalAmount: string;
  creditNotesAmount: string;
  payableAmount: string;
  creditNotesCount: number;
  isInvoicePaid?: boolean;
  shouldAddMargin?: boolean;
  size?: string;
  bottomPosition?: string;
}

function TooltipContent({
  creditNotesAmount,
  payableAmount,
  totalAmount,
  creditNotesCount,
  isInvoicePaid = false,
}: PayableAmountTooltipProps): ReactNode {
  const { formatMessage } = useIntl();

  return (
    <span data-testid="payable-amount-tooltip-content">
      <span className={styles.content} data-testid="payable-amount-tooltip-content-total-amount">
        <span>
          {formatMessage({ id: 'supplier-invoices.edit.modal.credit-note.tooltip.invoice-amount' })}
        </span>
        <span>{totalAmount}</span>
      </span>
      <span
        className={styles.content}
        data-testid="payable-amount-tooltip-content-credit-note-amount"
      >
        <span>
          {formatMessage(
            { id: 'supplier-invoices.edit.modal.credit-note.tooltip.credit-note' },
            { count: creditNotesCount }
          )}
        </span>
        <span>{creditNotesAmount}</span>
      </span>
      <span className={styles.content} data-testid="payable-amount-tooltip-content-payable-amount">
        <span>
          {isInvoicePaid
            ? formatMessage({ id: 'supplier-invoices.edit.modal.credit-note.tooltip.paid-amount' })
            : formatMessage({
                id: 'supplier-invoices.edit.modal.credit-note.tooltip.payable-amount',
              })}
        </span>
        <span>{payableAmount}</span>
      </span>
    </span>
  );
}

export function PayableAmountTooltip({
  creditNotesAmount,
  payableAmount,
  totalAmount,
  creditNotesCount,
  isInvoicePaid,
  shouldAddMargin = false,
  size = '16px',
  bottomPosition = '-6px',
}: PayableAmountTooltipProps): ReactNode {
  return (
    <Tooltip
      className={`${styles.tooltip} ${shouldAddMargin && styles['with-margin']}`}
      closeDelay={0}
      delay={0}
      label={
        <TooltipContent
          creditNotesAmount={creditNotesAmount}
          creditNotesCount={creditNotesCount}
          isInvoicePaid={isInvoicePaid}
          payableAmount={payableAmount}
          totalAmount={totalAmount}
        />
      }
      needsButton
      placement="top"
      tooltipTestId="payable-amount-tooltip-icon"
    >
      <Info className={styles.icon} style={{ width: size, height: size, bottom: bottomPosition }} />
    </Tooltip>
  );
}
