import { useIntl } from 'react-intl';
import { type ReactElement, useEffect } from 'react';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { TRANSFER_FLOW_ORIGIN } from 'qonto/constants/transfers';
import {
  PAY_LATER_SIGNATURE_STATUS,
  PAY_LATER_APPLICATION_ORIGIN,
  PAY_LATER_SIGNATURE_EVENT,
} from 'qonto/constants/financing';
import styles from './styles.strict-module.css';

export interface SignatureProps {
  signatureUrl: string;
  goToNextStep: (status: string) => void;
  context: {
    origin: string;
    signature: {
      url: string;
      status: string;
    };
    sepaTransfer?: object;
  };
}

interface SignatureEvent {
  origin: string;
  data?: {
    event: string;
    type: string;
  };
}

export function Signature({ goToNextStep, context }: SignatureProps): ReactElement {
  const { formatMessage } = useIntl();
  const segment = useEmberService('segment');

  const { signature } = context;

  useEffect(() => {
    const handleEvents = (e: SignatureEvent): void => {
      let status;

      if (
        e.origin === PAY_LATER_SIGNATURE_EVENT.ORIGIN &&
        e.data?.type === PAY_LATER_SIGNATURE_EVENT.TYPE
      ) {
        if (e.data.event === 'success') {
          const { origin, sepaTransfer } = context;

          const applicationFlowOrigins = Object.values(PAY_LATER_APPLICATION_ORIGIN);
          let trackingPayload = {};
          let trackingOrigin = origin;

          status = PAY_LATER_SIGNATURE_STATUS.SUCCESS;

          if (sepaTransfer) {
            if (origin === TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES) {
              trackingOrigin = PAY_LATER_APPLICATION_ORIGIN.SUPPLIER_INVOICES;
            } else if (origin !== PAY_LATER_APPLICATION_ORIGIN.PAY_BY_BENEFICIARY) {
              trackingOrigin = PAY_LATER_APPLICATION_ORIGIN.TRANSFER;
            }
          }

          if (applicationFlowOrigins.includes(trackingOrigin)) {
            trackingPayload = {
              origin: trackingOrigin,
            };
          }

          segment.track('pay-later_signed_displayed', trackingPayload);
        }

        if (e.data.event === 'error') {
          status = PAY_LATER_SIGNATURE_STATUS.ERROR;
        }
      }

      if (status) {
        context.signature.status = status;
        goToNextStep(status);
      }
    };

    window.addEventListener('message', handleEvents);

    return () => {
      window.removeEventListener('message', handleEvents);
    };
  }, [goToNextStep, context, segment]);

  return (
    <div className={styles.signature}>
      <iframe
        className={styles['iframe-container']}
        data-test-signature
        referrerPolicy="strict-origin-when-cross-origin"
        src={`${signature.url}&disable_domain_validation=true`}
        title={formatMessage({ id: 'financing.pay-later.application-flow.signature.title' })}
      />
    </div>
  );
}
