import type { HTMLAttributes, ReactNode } from 'react';
import cx from 'clsx';
import styles from './styles.strict-module.css';

interface LabelProps extends HTMLAttributes<HTMLLabelElement> {
  label: string;
  htmlFor: string;
}

export function F24FormElementsLabel({
  label,
  className,
  htmlFor,
  ...attributes
}: LabelProps): ReactNode {
  return (
    <label
      className={cx(styles.label, className)}
      data-test-f24-form-elements-label
      htmlFor={htmlFor}
      {...attributes}
    >
      {label}
    </label>
  );
}
