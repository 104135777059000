import { useContext } from 'react';
import { OverlayTriggerStateContext } from 'react-aria-components';
import { useIntl } from 'react-intl';
import type { SortDirection } from '@tanstack/react-table';
import { IconArrowBottomOutlined, IconArrowTopOutlined } from '@repo/monochrome-icons';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { Popover, PopoverButton, PopoverSection } from 'qonto/react/components/table-v2/popover';
import { SORT_DIRECTION } from 'qonto/react/constants';
import { EyeHiddenOutlined } from 'qonto/react/assets/icons';
import type { DisplayColumn } from 'qonto/constants/table-view';
import styles from './styles.strict-module.css';

interface HeaderCellPopoverProps {
  onSortBy?: (sortBy: string | undefined) => void;
  sortedColumn?: string;
  sortedDirection?: SortDirection;
  isCurrentColumnSorted?: boolean;
  updateColumn: (column: Partial<DisplayColumn>) => void;
  columnId: string;
  showHideColumn?: boolean;
  showSorting?: boolean;
}

export function HeaderCellPopover({
  onSortBy,
  sortedColumn,
  sortedDirection,
  isCurrentColumnSorted,
  updateColumn,
  columnId,
  showHideColumn,
  showSorting,
  ...props
}: HeaderCellPopoverProps): JSX.Element {
  const { formatMessage } = useIntl();
  const triggerState = useContext(OverlayTriggerStateContext);
  const segment = useEmberService('segment');

  const handleSort = (direction: SortDirection): void => {
    const newSort =
      isCurrentColumnSorted && sortedDirection === direction
        ? undefined // Reset sort if already sorted in the same direction
        : `${sortedColumn}:${direction}`;
    onSortBy?.(newSort);
    triggerState?.close();
  };

  const handleToggle = (): void => {
    triggerState?.toggle();
  };

  const hideColumn = (): void => {
    updateColumn({
      id: columnId,
      isVisible: false,
    });

    segment.track('tables_column-mgmt-popover_clicked', {
      popover: 'hide-column',
    });
  };

  return (
    <Popover
      crossOffset={4}
      isOpen={triggerState?.isOpen}
      onOpenChange={handleToggle}
      placement="bottom start"
      width={274}
      {...props}
    >
      {showSorting ? (
        <PopoverSection className={styles['popover-section']}>
          <PopoverButton
            className={
              isCurrentColumnSorted && sortedDirection === SORT_DIRECTION.ASCENDING
                ? styles.selected
                : undefined
            }
            data-testid="sort-ascending"
            onPress={() => {
              handleSort(SORT_DIRECTION.ASCENDING);
            }}
            slots={{
              icon: <IconArrowTopOutlined />,
              text: formatMessage({
                id: 'transactions.modular_table.manage_column.label.sort_ascending',
              }),
            }}
          />
          <PopoverButton
            className={
              isCurrentColumnSorted && sortedDirection === SORT_DIRECTION.DESCENDING
                ? styles.selected
                : undefined
            }
            data-testid="sort-descending"
            onPress={() => {
              handleSort(SORT_DIRECTION.DESCENDING);
            }}
            slots={{
              icon: <IconArrowBottomOutlined />,
              text: formatMessage({
                id: 'transactions.modular_table.manage_column.label.sort_descending',
              }),
            }}
          />
        </PopoverSection>
      ) : null}
      {showHideColumn ? (
        <PopoverSection className={styles['popover-section']}>
          <PopoverButton
            data-testid="hide-column"
            onPress={hideColumn}
            slots={{
              icon: <EyeHiddenOutlined />,
              text: formatMessage({
                id: 'transactions.modular_table.manage_column.label.hide_column',
              }),
            }}
          />
        </PopoverSection>
      ) : null}
    </Popover>
  );
}
