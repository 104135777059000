import { type ReactNode } from 'react';
import cx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { IconArrowLeftOutlined, IconCrossOutlined } from '@repo/monochrome-icons';
import styles from './styles.strict-module.css';

interface HeaderProps {
  title: string;
  onClose: () => void;
  onBackButtonClick: () => void;
  isBackButtonVisible: boolean;
  bucketTitle: string;
  bucketSubtitle?: string;
}

export function Header({
  bucketTitle,
  bucketSubtitle,
  onBackButtonClick,
  isBackButtonVisible,
  onClose,
  ...props
}: HeaderProps): ReactNode {
  const { formatMessage } = useIntl();
  return (
    <div className={styles.header} data-test-side-drawer-header {...props}>
      <button
        aria-label={formatMessage({ id: 'a11y.buttons.back-aria-label' })}
        className={cx(
          styles['back-button'],
          isBackButtonVisible ? styles.visible : styles.hidden,
          'btn',
          'btn--icon-only',
          'btn--tertiary'
        )}
        data-test-back-button
        data-test-visible={isBackButtonVisible}
        onClick={onBackButtonClick}
        type="button"
      >
        <IconArrowLeftOutlined aria-hidden="true" />
      </button>
      <div className={styles.heading}>
        <h1 className={styles.title} data-test-title>
          {bucketTitle || <FormattedMessage id="in-context-integrations.side-drawer.title" />}
        </h1>
        {Boolean(bucketSubtitle) && (
          <p className={styles.subtitle} data-test-subtitle>
            {bucketSubtitle}
          </p>
        )}
      </div>
      <button
        aria-label={formatMessage({ id: 'a11y.buttons.close-aria-label' })}
        className={cx(styles['close-button'], 'btn', 'btn--icon-only', 'btn--tertiary')}
        data-test-close-button
        onClick={onClose}
        type="button"
      >
        <IconCrossOutlined aria-hidden="true" />
      </button>
    </div>
  );
}
