import { FormattedMessage } from '@repo/domain-kit/shared';
import { Disclaimer } from '@repo/design-system-kit';
import { CashflowPeriodRate } from 'qonto/react/models/cash-flow-period.ts';
import type { CategoriesTableRow } from 'qonto/react/components/cash-flow/models/categories-table-display.ts';

export function ForecastEntryDisclaimer({
  category,
  frequency,
  isFlowSelected,
}: {
  frequency?: CashflowPeriodRate;
  category?: CategoriesTableRow;
  isFlowSelected: boolean;
}): React.ReactNode {
  const categoryHasChildren = Boolean(category?.subRows?.length);
  const isMonthlyFrequency = frequency === CashflowPeriodRate.Monthly;

  if (!isMonthlyFrequency) {
    return (
      <Disclaimer.Inline>
        <FormattedMessage id="cashflow.side-panel.forecast.summary.disclaimer.period" />
      </Disclaimer.Inline>
    );
  }

  if (isFlowSelected) {
    return (
      <Disclaimer.Inline>
        <FormattedMessage id="cashflow.side-panel.forecast.summary.disclaimer.inflows-outflows" />
      </Disclaimer.Inline>
    );
  }

  return categoryHasChildren ? (
    <Disclaimer.Inline>
      <FormattedMessage id="cashflow.side-panel.forecast.summary.disclaimer.category" />
    </Disclaimer.Inline>
  ) : null;
}
