import { type ReactNode, useMemo, Suspense } from 'react';
import { useEmberService, useFlags } from '@qonto/react-migration-toolkit/react/hooks';
import { ErrorState, SearchFieldWithDebounce } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
import type SupplierInvoiceModel from 'qonto/models/supplier-invoice';
import { INVOICE_STATUSES } from 'qonto/constants/supplier-invoice';
import { ErrorBoundary, type FallbackProps } from 'qonto/react/utils/error-boundary';
import { LINKED_ATTACHMENTS_LIMIT } from 'qonto/constants/transactions';
import { useLinkTransactionAttachment } from 'qonto/react/hooks/mutations/use-link-attachment';
import { TransactionPlaceholder } from 'qonto/react/components/supplier-invoices/transactions-modal/transaction/placeholder';
import { SuggestedTransactions } from '../suggested-transactions';
import { Transactions } from '../transactions';
import { useGenerateTransactionQuery } from '../use-generate-transaction-query';
import { PeriodSelector } from '../period-selector';
import styles from './match-transaction.strict-module.css';

interface MatchTransactionProps {
  invoice?: SupplierInvoiceModel;
  closeModal: () => void;
  origin?: string;
}
export function MatchTransaction({
  invoice,
  closeModal,
  origin,
}: MatchTransactionProps): ReactNode {
  const { query, setSearch, setPeriod, period } = useGenerateTransactionQuery(invoice);
  const segment = useEmberService('segment');
  const toastFlashMessages = useEmberService('toast-flash-messages');
  const store = useEmberService('store');
  // eslint-disable-next-line @qonto/no-use-ember-service-router -- required for refreshing the supplier-invoice.list ember model
  const router = useEmberService('router');

  const { featureBooleanApCreditNotes } = useFlags();
  const { formatMessage } = useIntl();

  const shouldFetchSuggestedTransactions = Boolean(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- in some cases suggestedTransactions is undefined
    invoice?.suggestedTransactions?.length &&
      invoice.status === INVOICE_STATUSES.toReview &&
      !query.search &&
      !period
  );

  const successMessage = useMemo(() => {
    const isLinkCreditNoteCtaDisplayed = Boolean(
      featureBooleanApCreditNotes && invoice?.isCreditNote
    );

    return isLinkCreditNoteCtaDisplayed
      ? formatMessage({ id: 'supplier-invoices.success-toast.matched-transaction' })
      : formatMessage({ id: 'supplier-invoices.success-toast.mark-as-paid' });
  }, [featureBooleanApCreditNotes, invoice?.isCreditNote, formatMessage]);

  const { mutate: linkTransaction, isPending } = useLinkTransactionAttachment({
    throwOnError: false,
    onSuccess: async transaction => {
      const supplierInvoice = await store.findRecord('supplier-invoice', invoice?.id || '');
      await supplierInvoice.markAsPaid(
        dayjs(transaction.settledAt).format(DATE_PICKER_FIELD_FORMAT)
      );

      closeModal();
      toastFlashMessages.toastSuccess(successMessage);
      if (origin === 'supplier-invoices.list') {
        void router.refresh('supplier-invoices.list');
      }

      if (origin === 'upcoming-transactions') {
        void router.refresh('upcoming-transactions');
      }
    },
    onError: (error: Response) => {
      closeModal();
      if (error.status === 422) {
        toastFlashMessages.toastError(
          formatMessage(
            { id: 'receivable-invoices.match-transaction.error-toast' },
            {
              invoiceLimit: LINKED_ATTACHMENTS_LIMIT,
            }
          )
        );
        return;
      }
      toastFlashMessages.toastError(formatMessage({ id: 'toasts.errors.server_error' }));
    },
  });

  const handleSelectTransaction = (transaction: {
    id: string;
    side: 'debit' | 'credit';
    settledAt?: string;
  }): void => {
    if (invoice?.id && !isPending) {
      linkTransaction({ attachmentId: invoice.attachmentId, transactionId: transaction.id });
      if (featureBooleanApCreditNotes) {
        const transactionTrackingParams = {
          direction: transaction.side,
          origin_type: invoice.isCreditNote ? 'credit_note' : 'invoice',
        };
        segment.track('supplier-invoices_mark-as-paid_submitted', transactionTrackingParams);
      }
    }
  };

  const maxDate = useMemo(() => dayjs().toDate().toISOString(), []);

  return (
    <>
      <div className={styles['toolbar-row']}>
        <SearchFieldWithDebounce
          className={styles['search-input']}
          compact
          data-test-match-transaction-search
          onChange={setSearch}
          placeholder={formatMessage({
            id: 'supplier-invoices.match-transaction-modal.search-placeholder',
          })}
          value={query.search}
        />
        <PeriodSelector
          cancelPeriodSelection={() => {
            setPeriod(null);
          }}
          maxDate={maxDate}
          onChangePeriod={setPeriod}
          selectedPeriod={period}
          selectorLabel={formatMessage({
            id: 'transactions.filters.properties.payment-date',
          })}
        />
      </div>

      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Suspense
          fallback={Array.from({ length: 20 }).map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key -- static array
            <TransactionPlaceholder key={index} />
          ))}
        >
          {invoice && shouldFetchSuggestedTransactions ? (
            <SuggestedTransactions handleClick={handleSelectTransaction} invoice={invoice} />
          ) : null}
          {query.filter_group.expressions.length > 0 && (
            <Transactions
              handleClick={handleSelectTransaction}
              invoice={invoice}
              period={period}
              query={query}
              shouldFilterOutSuggestedTransactions={shouldFetchSuggestedTransactions}
            />
          )}
        </Suspense>
      </ErrorBoundary>
    </>
  );
}

function ErrorFallback({ resetErrorBoundary }: FallbackProps): ReactNode {
  const { formatMessage } = useIntl();
  const { reset } = useQueryErrorResetBoundary();

  return (
    <ErrorState
      className={styles.errorState}
      onRetry={() => {
        resetErrorBoundary();
        reset();
      }}
      retryButtonVariant="secondary"
      subtitle={formatMessage({ id: 'supplier-invoices.error-state.subtitle' })}
      title={formatMessage({ id: 'supplier-invoices.error-state.title' })}
    />
  );
}
