import type { ReactElement } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Selector } from '@repo/design-system-kit';
import cx from 'clsx';
import type {
  EarlyRepaymentOption,
  RepaymentOption,
} from 'qonto/react/api/models/repayment-option';
import styles from './styles.strict-module.css';

interface FullRepaymentProps {
  option: RepaymentOption;
  onSelect: (option: EarlyRepaymentOption) => void;
  className?: string;
}

export function FullRepayment({ option, onSelect, ...rest }: FullRepaymentProps): ReactElement {
  return (
    <Selector
      content={
        option.savingsAmount ? (
          <span className={cx(styles.content, 'body-1')}>
            <FormattedMessage
              id="financing.pay-later.early-repayment.options.full-repayment.saving"
              values={{
                amount: (
                  <FormattedNumber
                    currency={option.savingsAmount.currency}
                    maximumFractionDigits={2}
                    minimumFractionDigits={2}
                    style="currency"
                    value={Number(option.savingsAmount.value)}
                  />
                ),
              }}
            />
          </span>
        ) : undefined
      }
      data-testid="full-repayment-selector"
      icon={
        <img alt="" data-testid="full-repayment-icon" src="/icon/transfer/instant-transfer.svg" />
      }
      onPress={() => {
        onSelect(option.earlyRepaymentOption);
      }}
      subtitle={
        <FormattedMessage id="financing.pay-later.early-repayment.options.full-repayment.subtitle" />
      }
      title={
        <FormattedNumber
          currency={option.totalAmount.currency}
          maximumFractionDigits={2}
          minimumFractionDigits={2}
          style="currency"
          value={Number(option.totalAmount.value)}
        />
      }
      {...rest}
    />
  );
}
