/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import { useEffect, useRef, useState, type InputHTMLAttributes, type ReactNode } from 'react';
import styles from './vat-rate-field.strict-module.css';

interface AutoWidthInputProps extends InputHTMLAttributes<HTMLInputElement> {
  value?: string;
  minWidth?: number;
  inputRef?: React.RefObject<HTMLInputElement>;
}
export function AutoWidthInput({
  value = '',
  onChange,
  placeholder = '',
  minWidth = 12,
  className,
  style = {},
  inputRef,
  ...props
}: AutoWidthInputProps): ReactNode {
  const [width, setWidth] = useState(minWidth);
  const measureRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (measureRef.current) {
      const textWidth = measureRef.current.offsetWidth;
      const newWidth = Math.max(textWidth + 4, minWidth);
      setWidth(newWidth);
    }
  }, [value, placeholder, minWidth]);

  return (
    <div className={styles['auto-width-input-wrapper']}>
      <span className={styles['auto-width-hidden-value']} ref={measureRef}>
        {value || placeholder || '0'}
      </span>
      <input
        className={className}
        onChange={onChange}
        placeholder={placeholder}
        ref={inputRef}
        style={{
          ...style,
          width: `${width}px`,
        }}
        type="text"
        value={value}
        {...props}
      />
    </div>
  );
}
