import { useContext, type PropsWithChildren, type ReactNode, type UIEventHandler } from 'react';
import {
  Dialog,
  OverlayTriggerStateContext,
  Button as ReactAriaButton,
  Popover as ReactAriaPopover,
  type ButtonProps,
  type PopoverProps,
  type PressEvent,
} from 'react-aria-components';
import cx from 'clsx';
import { Button } from '@repo/design-system-kit';
import { IconChevronTopOutlined } from '@repo/monochrome-icons';
import styles from './styles.strict-module.css';

interface CustomPopoverProps extends Omit<PopoverProps, 'children'> {
  children: ReactNode;
  width?: number;
  height?: number;
}

export interface OverlayTriggerState {
  readonly isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  open: () => void;
  close: () => void;
  toggle: () => void;
}

export function Popover({
  children,
  width = 272,
  height,
  ...props
}: CustomPopoverProps): ReactNode {
  const portalContainer = document.querySelector('#ra-popover-container') ?? undefined;

  return (
    <ReactAriaPopover
      UNSTABLE_portalContainer={portalContainer}
      offset={4}
      placement="bottom start"
      {...props}
    >
      <Dialog
        className={styles.container}
        data-testid="popover"
        style={{
          width: `${width.toString()}px`,
          height: height ? `${height.toString()}px` : undefined,
        }}
      >
        {children}
      </Dialog>
    </ReactAriaPopover>
  );
}

interface PopoverHeaderProps extends PropsWithChildren {
  className?: string;
}

export function PopoverHeader({ children, className }: PopoverHeaderProps): ReactNode {
  const triggerState = useContext<OverlayTriggerState | null>(OverlayTriggerStateContext);

  return (
    <header className={cx(styles.header, className)} data-testid="popover-header">
      {children}
      <Button
        className={styles['header-button']}
        data-testid="popover-close-button"
        iconOnly
        onPress={triggerState?.close}
        variant="tertiary"
      >
        <IconChevronTopOutlined aria-hidden="true" className={styles.chevron} />
      </Button>
    </header>
  );
}

interface PopoverSectionProps extends PropsWithChildren {
  className?: string;
  onScroll?: UIEventHandler<HTMLElement>;
}

export function PopoverSection({ children, className, ...props }: PopoverSectionProps): ReactNode {
  return (
    <section className={cx(styles.section, className)} data-testid="popover-section" {...props}>
      {children}
    </section>
  );
}

interface PopoverButtonProps extends Omit<ButtonProps, 'children'> {
  onPress: (e: PressEvent) => void;
  slots: {
    icon: ReactNode;
    text: ReactNode;
  };
  'data-testid'?: string;
  className?: string;
}

export function PopoverButton({
  onPress,
  slots,
  className,
  ...props
}: PopoverButtonProps): ReactNode {
  return (
    <ReactAriaButton
      className={cx(styles.button, 'overlay', className)}
      data-testid="popover-button"
      onPress={onPress}
      {...props}
    >
      {slots.icon}
      <span className="body-2" data-testid="button-text">
        {slots.text}
      </span>
    </ReactAriaButton>
  );
}
