import { type SortingFn } from '@tanstack/react-table';
import { type UpcomingTransaction } from 'qonto/react/hooks/use-upcoming-transactions';

export const amountWithSideSorting: SortingFn<UpcomingTransaction> = (rowA, rowB, columnId) => {
  const aAmount = rowA.getValue(columnId);
  const bAmount = rowB.getValue(columnId);

  const aSide = rowA.original.side;
  const bSide = rowB.original.side;

  const aValue = Number(aAmount) * (aSide === 'debit' ? -1 : 1);
  const bValue = Number(bAmount) * (bSide === 'debit' ? -1 : 1);

  return Math.sign(aValue - bValue);
};
