import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, LottiePlayer } from '@repo/design-system-kit';
import { useThemedAssetPath } from 'qonto/react/hooks/use-themed-asset-path';
import styles from './styles.strict-module.css';

interface IntroProps {
  close: () => void;
}

export function Intro({ close }: IntroProps): ReactNode {
  const lottiePath = useThemedAssetPath('/lotties/financing-intro-animation.json');

  return (
    <div className={styles.container} data-test-financing-introduction>
      <section className={styles.content}>
        <LottiePlayer
          autoplay
          className="mb-32"
          data-test-financing-intro-animation-lottie=""
          loop
          path={lottiePath}
        />

        <h1 className="title-1 mb-16" data-test-financing-intro-title>
          <FormattedMessage id="financing.dismiss-modal.title" />
        </h1>

        <p className="body-1 mb-32" data-test-financing-intro-body>
          <FormattedMessage id="financing.dismiss-modal.description" />
        </p>

        <Button data-test-financing-intro-cta onPress={close} stretch variant="primary">
          <FormattedMessage id="financing.dismiss-modal.cta" />
        </Button>
      </section>
    </div>
  );
}
