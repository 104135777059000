import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { IconExternalOutlined } from '@repo/monochrome-icons';

export function InsuranceClaimLink(): ReactNode {
  const { formatMessage } = useIntl();
  const segment = useEmberService('segment');

  const handleClick = (): void => {
    segment.track('insurance_claim_fraud');
  };

  return (
    <div>
      <a
        className="btn btn--primary"
        data-test-insurance-claim-cta
        href={formatMessage({ id: 'insurance.claim-link' })}
        onClick={handleClick}
        rel="noopener noreferrer"
        target="_blank"
      >
        <span className="mr-8">{formatMessage({ id: 'insurance.claim' })}</span>
        <IconExternalOutlined aria-hidden="true" data-test-insurance-claim-icon="icon_external" />
      </a>
    </div>
  );
}
