import { LOCALES_DEFINITION } from '@repo/shared-config/app/constants/locales';
import { CardLevel } from '@repo/cards-kit/constants';
import { SelectionAssets } from '@repo/cards-kit/assets';

export const IMAGE_URLS = {
  [CardLevel.Advertising]: SelectionAssets.CardAdvertising,
  [CardLevel.Flash]: SelectionAssets.CardFlash,
  [CardLevel.Virtual]: SelectionAssets.CardVirtual,
  [CardLevel.Standard]: SelectionAssets.CardOne,
  [CardLevel.Plus]: SelectionAssets.CardPlus,
  [CardLevel.Metal]: SelectionAssets.CardX,
} as const;

export const FLASH_CARD_FRENCH_IMAGE_URL = SelectionAssets.CardFlashFrench;

export const getImageUrl = ({
  locale,
  cardLevel,
}: {
  locale: string;
  cardLevel: CardLevel;
}): string => {
  const isFrenchLocale = locale === LOCALES_DEFINITION.FR.code;
  const isFlashCard = cardLevel === CardLevel.Flash;

  return isFlashCard && isFrenchLocale ? FLASH_CARD_FRENCH_IMAGE_URL : IMAGE_URLS[cardLevel];
};
