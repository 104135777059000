/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import type { ReactNode } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

interface ChooseCardInviteePriceProps {
  price: number;
}

export function ChooseCardInviteePrice({ price }: ChooseCardInviteePriceProps): ReactNode {
  return (
    <p data-test-price>
      {price ? (
        <>
          <FormattedNumber
            currency="EUR"
            currencyDisplay="symbol"
            maximumFractionDigits={0}
            style="currency"
            value={price}
          />
          <span className="body-1">
            {` `}
            <FormattedMessage id="cards.steps.choose-card.pricing-period" />
          </span>
        </>
      ) : (
        <FormattedMessage id="cards.steps.choose-card.included" />
      )}
    </p>
  );
}
