import type { ReactElement } from 'react';
import type { AvailableCreditTileProps } from './available-credit-tile/available-credit-tile';
import type { TotalLeftToPayTileProps } from './total-left-to-pay-tile/total-left-to-pay-tile';
import type { NextInstallmentsTileProps } from './next-installments-tile/next-installments-tile';
import { AvailableCreditTile } from './available-credit-tile';
import { NextInstallmentsTile } from './next-installments-tile';
import { TotalLeftToPayTile } from './total-left-to-pay-tile';
import styles from './styles.strict-module.css';

export function Insights({
  availableCreditAmount,
  creditLimitAmount,
  isNoMoreEligible = false,
  isContractOnHold = false,
  nextRepaymentAmount,
  count: installmentsCount,
  repaymentStatus,
  totalLeftToPayAmount,
  lastInstallmentDate,
}: AvailableCreditTileProps & NextInstallmentsTileProps & TotalLeftToPayTileProps): ReactElement {
  return (
    <section className={styles.row}>
      <AvailableCreditTile
        availableCreditAmount={availableCreditAmount}
        creditLimitAmount={creditLimitAmount}
        isContractOnHold={isContractOnHold}
        isNoMoreEligible={isNoMoreEligible}
      />
      <NextInstallmentsTile
        count={installmentsCount}
        nextRepaymentAmount={nextRepaymentAmount}
        repaymentStatus={repaymentStatus}
      />
      <TotalLeftToPayTile
        lastInstallmentDate={lastInstallmentDate}
        totalLeftToPayAmount={totalLeftToPayAmount}
      />
    </section>
  );
}
