import { IconErrorFilled, IconWarningSignFilled } from '@repo/monochrome-icons';
import cx from 'clsx';
import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './value.strict-module.css';

interface ValueProps extends React.HTMLAttributes<HTMLSpanElement> {
  missing?: boolean;
  warningLevel?: 'error' | 'warning';
  children?: ReactNode;
}

export function Value({
  missing,
  warningLevel,
  children,
  className,
  ...props
}: ValueProps): ReactNode {
  if (!missing && !children) {
    return null;
  }
  return (
    <span className={cx(styles.value, missing && styles.missing, className)} {...props}>
      {missing ? (
        <>
          {warningLevel === 'error' ? (
            <IconErrorFilled className={styles.errorIcon} data-test-missing="error" />
          ) : (
            <IconWarningSignFilled className={styles.warningIcon} data-test-missing="warning" />
          )}
          <FormattedMessage id="organizations.profile.company_profile.missing_information" />
        </>
      ) : (
        children
      )}
    </span>
  );
}
