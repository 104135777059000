import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@repo/design-system-kit';
import { cashFlowSidePanelManager } from 'qonto/react/contexts/cash-flow-sidepanel-context';
import { ForecastAILoading } from '../forecast-ai-loading';
import styles from './flash-forecast.strict-module.css';

const FLASH_FORECAST_VIEWS = {
  LOADING: 'loading',
  SUCCESS: 'success',
} as const;

export type FlashForecastViewType =
  (typeof FLASH_FORECAST_VIEWS)[keyof typeof FLASH_FORECAST_VIEWS];

export function FlashForecast(props: React.HTMLAttributes<HTMLDivElement>): React.ReactNode {
  const [view, setView] = useState<FlashForecastViewType>(FLASH_FORECAST_VIEWS.LOADING);
  const { closeSidepanel } = cashFlowSidePanelManager.useCashFlowSidePanel();

  const onLoadingCompleted = (): void => {
    setView(FLASH_FORECAST_VIEWS.SUCCESS);
  };

  const handleCTAClick = (): void => {
    closeSidepanel();
  };

  switch (view) {
    case FLASH_FORECAST_VIEWS.SUCCESS:
      return (
        <section {...props}>
          <div className={styles['success-block']} data-testid="flash-forecast-success">
            <img
              alt=""
              className={styles['success-illustration']}
              data-testid="flash-forecast-success-illustration"
              src="/illustrations/cash-flow/forecast/flash-forecast-success.svg"
            />
            <h2 className={styles.title} data-testid="flash-forecast-success-title">
              <FormattedMessage id="cash-flow.first-time-experience.sidepanel.flash.success.title" />
            </h2>
            <p className="body-2 mb-32" data-testid="flash-forecast-success-subtitle">
              <FormattedMessage id="cash-flow.first-time-experience.sidepanel.flash.success.subtitle" />
            </p>
            <Button data-testid="start-planning-cta" onPress={handleCTAClick} variant="primary">
              <FormattedMessage id="cash-flow.first-time-experience.sidepanel.flash.success.cta" />
            </Button>
          </div>
        </section>
      );
    case FLASH_FORECAST_VIEWS.LOADING:
    default:
      return (
        <section {...props}>
          <ForecastAILoading onLoadingCompleted={onLoadingCompleted} />
        </section>
      );
  }
}
