import dayjs from 'dayjs';

export const getEntryTimeline = (
  interval:
    | {
        start: number;
        end: number;
      }
    | undefined
): {
  isFutureMonth: boolean;
  isCurrentMonth: boolean;
  isPreviousMonth: boolean;
} => {
  const currentDate = dayjs().utc();
  const intervalDate = dayjs(interval?.start).utc();

  const isFutureMonth = intervalDate.isAfter(currentDate, 'month');
  const isCurrentMonth = intervalDate.isSame(currentDate, 'month');
  const isPreviousMonth = intervalDate.isBefore(currentDate, 'month');

  return {
    isFutureMonth,
    isCurrentMonth,
    isPreviousMonth,
  };
};
