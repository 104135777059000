import { IconReloadOutlined, IconWarningSignFilled } from '@repo/monochrome-icons';
import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import styles from './styles.strict-module.css';

interface WidgetInvoicesErrorProps {
  reload: () => void;
}

export function WidgetInvoicesError({ reload }: WidgetInvoicesErrorProps): ReactNode {
  const { formatMessage } = useIntl();

  return (
    <div className={styles['error-container']} data-test-widget-invoices-error>
      <p className={styles['error-message']} data-test-widget-invoices-error-body>
        <IconWarningSignFilled height={12} width={12} />
        {formatMessage({ id: 'qonto-invoicing.overview.widgets.error.title' })}
      </p>
      <button
        className={styles['error-cta']}
        data-test-widget-invoices-reload-button
        onClick={reload}
        type="button"
      >
        <IconReloadOutlined />
        {formatMessage({ id: 'qonto-invoicing.overview.widgets.error.cta' })}
      </button>
    </div>
  );
}
