import React, { type ReactNode } from 'react';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import { Badge, Tooltip } from '@repo/design-system-kit';
import styles from './row.strict-module.css';

interface RowProps {
  label: string;
  content?: string;
  color?: 'danger';
  descriptionStyles?: string;
  isFrenchEinvoice?: boolean;
  children?: ReactNode;
}

export function SidebarInfoListRow({
  label,
  content,
  color,
  descriptionStyles,
  isFrenchEinvoice,
  children,
  ...props
}: RowProps): ReactNode {
  const { formatMessage } = useIntl();

  if (!children && !content) {
    return null;
  }

  return (
    <div
      className={cx(styles.row, color === 'danger' && styles.danger)}
      data-test-sidebar-info-list-row
      {...props}
    >
      <dt className={styles.label} data-test-label>
        {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx -- We add a non-breaking space to avoid the label and content to be glued together */}
        {label}{' '}
      </dt>
      <dd className={cx(styles.content, descriptionStyles)} data-test-content>
        {content ? (
          <>
            {content}
            {isFrenchEinvoice ? (
              <Tooltip
                data-test-einvoicing-tooltip
                label={formatMessage({
                  id: 'receivable-invoices.einvoicing-client.tooltip',
                })}
                placement="top"
              >
                <Badge
                  aria-hidden="true"
                  className={styles['badge-union']}
                  data-test-einvoicing-icon=""
                  type="union"
                />
              </Tooltip>
            ) : null}
          </>
        ) : (
          children
        )}
      </dd>
    </div>
  );
}
