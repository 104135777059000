import { useContext, useState, type ReactNode } from 'react';
import { OverlayTriggerStateContext, type Selection } from 'react-aria-components';
import { useEmberService, useRouter } from '@qonto/react-migration-toolkit/react/hooks';
import type { Label } from 'qonto/react/graphql';
import type { LabelList } from 'qonto/react/models/label';
import { useUpdateTransactions } from 'qonto/react/hooks/use-update-transaction';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { getLabelValue, updateLabels } from '../utils';
import { LabelPopover } from '../../../popovers/label-popover';
import { useLabelFilter } from '../../../popovers/label-popover/use-label-filter';

interface LabelCellPopoverProps {
  label: Label | undefined;
  labelList: LabelList;
  transactionId: string;
  transactionLabels: Label[];
}
export function LabelCellPopover({
  label,
  labelList,
  transactionId,
  transactionLabels,
}: LabelCellPopoverProps): ReactNode {
  const { labels: listLabels } = labelList;

  const router = useRouter();
  const { organization } = useOrganizationManager();
  const { mutate } = useUpdateTransactions(transactionId);
  const triggerState = useContext(OverlayTriggerStateContext);
  const toastFlashMessages = useEmberService('toast-flash-messages');
  const { resetFilter } = useLabelFilter(listLabels);
  const [activeLabelIds, setActiveLabelIds] = useState<Selection>(new Set(label ? [label.id] : []));

  const activeLabel = getLabelValue(activeLabelIds, listLabels);

  const mutateTransaction = (newLabelIds: string[], newLabels: Label[]): void => {
    mutate(
      { serverPayload: { label_ids: newLabelIds }, cachePayload: { labels: newLabels } },
      {
        onSuccess: () => {
          resetFilter();
          triggerState?.close();
        },
        onError: () => {
          setActiveLabelIds(activeLabelIds);
          resetFilter();
          toastFlashMessages.toastError('Label could not be saved');
          triggerState?.close();
        },
      }
    );
  };

  const handleSelectionChange = (labelSelection: Selection): void => {
    const selectedLabel = getLabelValue(labelSelection, listLabels);
    if (selectedLabel && selectedLabel.id !== activeLabel?.id) {
      const { newLabels, newLabelIds } = updateLabels({
        activeLabel,
        selectedLabel,
        labels: transactionLabels,
      });
      mutateTransaction(newLabelIds, newLabels);
    }
  };

  const handleLabelDismiss = (): void => {
    const { newLabels, newLabelIds } = updateLabels({ activeLabel, labels: transactionLabels });
    mutateTransaction(newLabelIds, newLabels);
  };

  return (
    <LabelPopover
      activeLabelIds={activeLabelIds}
      labelList={labelList}
      onEditLabelsLinkClick={() => {
        void router.push(`/organizations/${organization.slug}/custom-labels`);
      }}
      onLabelDismiss={handleLabelDismiss}
      onSelectionChange={handleSelectionChange}
    />
  );
}
