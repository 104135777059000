import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './styles.strict-module.css';

interface DetailedFeeItemProps {
  title: string;
  subtitle: string;
  iconPath: string;
  totalFee: number;
  currency: string;
}

export function DetailedFeeItem({
  title,
  subtitle,
  iconPath,
  totalFee,
  currency,
  ...props
}: DetailedFeeItemProps): ReactNode {
  const { formatNumber } = useIntl();

  return (
    <div className={styles['item-wrapper']} {...props}>
      <div className={styles['icon-title-wrapper']}>
        <span className={styles['icon-wrapper']} data-test-detailed-fee-item-icon={iconPath}>
          <StaticThemedAsset alt="" assetPath={iconPath} />
        </span>
        <div className={styles['title-sub-wrapper']}>
          <span className={styles.title} data-test-detailed-fee-item-title>
            {title}
          </span>
          <span className={styles.subtitle} data-test-detailed-fee-item-subtitle>
            {subtitle}
          </span>
        </div>
      </div>
      <span className={styles.amount} data-test-detailed-fee-item-amount>
        {formatNumber(totalFee, {
          style: 'currency',
          currency,
        })}
      </span>
    </div>
  );
}
