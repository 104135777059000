import { useIntl } from 'react-intl';
import { type ReactElement } from 'react';
import { Button, Spinner } from '@repo/design-system-kit';
import { StickyPanel } from 'qonto/react/components/sticky-panel';
import {
  DISCLAIMER_TYPES,
  INSTANT_DECLINED_REASONS_RETRY_STATUS,
  TRANSFER_FLOW_ORIGIN,
} from 'qonto/constants/transfers';
import type { InstantDeclinedReason, OPERATION_TYPES } from 'qonto/constants/transfers';
import type TransferModel from 'qonto/models/transfer';
import { Declined } from '../declined';
import { Processing } from '../processing';
import { Success } from '../success';
import { Timeout } from '../timeout';
import styles from '../styles.strict-module.css';

type ClickHandler = (
  action: string,
  data?: {
    transfer?: TransferModel;
    declinedReason?: string;
    confirmTransferTask?: unknown;
    shouldTrack?: boolean;
  }
) => void;

interface InstantTransferProps {
  instantTransfer: TransferModel;
  declinedReason?: InstantDeclinedReason;
  isTimeout: boolean;
  beneficiaryName: string;
  context: {
    origin: string;
    isDedicatedFlow: boolean;
    confirmationResult?: {
      warnings?: string[];
    };
  };
  showApprovalWorkflowPromotion: boolean;
  onClickButtonPrimary: ClickHandler & {
    isRunning?: boolean;
  };
  onClickButtonSecondary: ClickHandler;
  confirmTransferTask?: unknown;
}

export function InstantTransfer({
  instantTransfer,
  declinedReason,
  isTimeout,
  beneficiaryName,
  context,
  showApprovalWorkflowPromotion,
  onClickButtonPrimary,
  onClickButtonSecondary,
  confirmTransferTask,
}: InstantTransferProps): ReactElement {
  const { formatMessage } = useIntl();

  const renderContent = (): ReactElement | null => {
    const hasQontoBeneficiaryWarning = Boolean(
      context.confirmationResult?.warnings?.includes(DISCLAIMER_TYPES.QONTO_BANK_ACCOUNT)
    );

    if (isTimeout) {
      return <Timeout />;
    }

    if (instantTransfer.isProcessing) {
      return <Processing />;
    }

    if (instantTransfer.isDeclined) {
      return <Declined reason={declinedReason ?? ''} />;
    }

    if (instantTransfer.isCompleted) {
      return (
        <Success
          amount={instantTransfer.amount}
          beneficiaryName={beneficiaryName}
          currency={instantTransfer.amountCurrency}
          isInstantTransfer
          isPending={instantTransfer.isPending}
          isQontoBeneficiary={hasQontoBeneficiaryWarning}
          isScheduled={instantTransfer.wasScheduled}
          operationType={
            instantTransfer.operationType as (typeof OPERATION_TYPES)[keyof typeof OPERATION_TYPES]
          }
          showApprovalWorkflowPromotion={showApprovalWorkflowPromotion}
        />
      );
    }

    return null;
  };

  const renderPrimaryButton = (): ReactElement | null => {
    const canBeRetried =
      declinedReason && declinedReason in INSTANT_DECLINED_REASONS_RETRY_STATUS
        ? INSTANT_DECLINED_REASONS_RETRY_STATUS[declinedReason]
        : true;

    const shouldDisplayBackToSupplierInvoicesButton =
      (instantTransfer.isCompleted || instantTransfer.isDeclined) &&
      context.origin === TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES;

    if (instantTransfer.isDeclined && canBeRetried) {
      return (
        <Button
          data-test-sepa-transfer-retry-button
          onPress={() => {
            onClickButtonPrimary('retry-as-regular', {
              confirmTransferTask,
              transfer: instantTransfer,
            });
          }}
        >
          {formatMessage({ id: 'transfers.modals.instant.error.declined.try-regular-btn' })}
          {onClickButtonPrimary.isRunning ? (
            <Spinner className="ml-8" color="primary-b" size="small" />
          ) : null}
        </Button>
      );
    }

    if (instantTransfer.isCompleted && showApprovalWorkflowPromotion) {
      return (
        <Button
          data-test-go-to-approval-workflows-button
          onPress={() => {
            onClickButtonPrimary('go-to-approval-workflows');
          }}
        >
          {formatMessage({ id: 'transfers.modals.success.supplier-invoice.sent.cta-primary' })}
        </Button>
      );
    }

    if (shouldDisplayBackToSupplierInvoicesButton) {
      return (
        <Button
          data-test-back-to-supplier-invoices-button
          onPress={() => {
            onClickButtonPrimary('back-to-supplier-invoices');
          }}
        >
          {formatMessage({ id: 'transfers.modals.to-supplier-invoices-btn' })}
        </Button>
      );
    }

    if (instantTransfer.isCompleted || instantTransfer.isDeclined) {
      return (
        <Button
          data-test-sepa-transfer-make-another-button
          onPress={() => {
            onClickButtonPrimary('make-another-transfer', {
              transfer: instantTransfer,
              declinedReason,
            });
          }}
        >
          {context.isDedicatedFlow
            ? formatMessage({ id: 'transfers.modals.finance-another-btn' })
            : formatMessage({ id: 'transfers.modals.make-another-btn' })}
        </Button>
      );
    }

    return null;
  };

  const renderSecondaryButton = (): ReactElement | null => {
    if (instantTransfer.isPayLater && instantTransfer.isCompleted) {
      return (
        <Button
          data-test-sepa-transfer-go-to-pay-later-section
          onPress={() => {
            onClickButtonSecondary('pay-later');
          }}
          variant="tertiary"
        >
          {formatMessage({ id: 'financing.pay-later.application-flow.success.secondary-cta' })}
        </Button>
      );
    }

    if (instantTransfer.isDeclined || isTimeout) {
      return (
        <Button
          data-test-sepa-transfer-close-button
          onPress={() => {
            onClickButtonSecondary('close');
          }}
          variant={
            instantTransfer.isCompleted || instantTransfer.isDeclined ? 'tertiary' : 'primary'
          }
        >
          {formatMessage({ id: 'btn.close' })}
        </Button>
      );
    }

    if (instantTransfer.isCompleted) {
      if (showApprovalWorkflowPromotion) {
        return (
          <Button
            data-test-go-to-supplier-invoices-button
            onPress={() => {
              onClickButtonSecondary('back-to-supplier-invoices', { shouldTrack: false });
            }}
            variant="tertiary"
          >
            {formatMessage({
              id: 'transfers.modals.success.supplier-invoice.sent.cta-secondary',
            })}
          </Button>
        );
      }

      return (
        <Button
          data-test-sepa-transfer-details-button
          onPress={() => {
            onClickButtonSecondary('see-transfer-details', { transfer: instantTransfer });
          }}
          variant="tertiary"
        >
          {formatMessage({ id: 'transfers.repeat.sepa.new.success.cta' })}
        </Button>
      );
    }

    return null;
  };

  return (
    <>
      {renderContent()}

      <StickyPanel className={styles['sticky-panel']}>
        {renderPrimaryButton()}

        {renderSecondaryButton()}
      </StickyPanel>
    </>
  );
}
