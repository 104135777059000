import { IconAttachmentOutlined } from '@repo/monochrome-icons';
import type { ReactElement } from 'react';
import {
  StatusApprovedXS,
  StatusCancelledXS,
  StatusScheduledXS,
} from 'qonto/react/assets/icons/status/xs';
import styles from './styles.strict-module.css';

export enum AttachmentState {
  APPROVED = 'approved',
  LOST = 'lost',
  REQUESTED = 'requested',
}

interface AttachmentIconProps {
  state: AttachmentState;
}

// Map the enum values to icons
const STATUS_ICONS = {
  [AttachmentState.APPROVED]: StatusApprovedXS,
  [AttachmentState.LOST]: StatusCancelledXS,
  [AttachmentState.REQUESTED]: StatusScheduledXS,
} as const;

export function AttachmentIcon({ state }: AttachmentIconProps): ReactElement {
  const StatusIcon = STATUS_ICONS[state];

  return (
    <div className={styles.wrapper} data-test-attachment-icon>
      <IconAttachmentOutlined className={styles['attachment-icon']} />
      <StatusIcon aria-hidden="false" className={styles['status-icon']} role="img" />
    </div>
  );
}
