import { BadgeStatus, type StatusType } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import { INVOICE_STATUSES, STATUS_TAG_LEVELS } from 'qonto/constants/supplier-invoice.ts';

interface StatusTagProps {
  status: (typeof INVOICE_STATUSES)[keyof typeof INVOICE_STATUSES];
  customText?: Record<(typeof INVOICE_STATUSES)[keyof typeof INVOICE_STATUSES], string>;
  isEinvoice?: boolean;
}

interface StatusValues {
  level: StatusType;
  text: string;
}

const useStatusValues = ({
  status,
  customText,
  isEinvoice = false,
}: StatusTagProps): StatusValues => {
  const { formatMessage } = useIntl();

  switch (status) {
    case INVOICE_STATUSES.scheduled:
      return {
        text:
          customText?.[INVOICE_STATUSES.scheduled] ??
          formatMessage({ id: 'supplier-invoices.preview.status.scheduled-payment' }),
        level: STATUS_TAG_LEVELS.scheduled,
      };
    case INVOICE_STATUSES.pending:
      return {
        text:
          customText?.[INVOICE_STATUSES.pending] ??
          formatMessage({ id: 'supplier-invoices.preview.status.pending-request' }),
        level: STATUS_TAG_LEVELS.pending,
      };
    case INVOICE_STATUSES.archived:
      return {
        text:
          customText?.[INVOICE_STATUSES.archived] ??
          formatMessage({ id: 'supplier-invoices.preview.status.archived' }),
        level: STATUS_TAG_LEVELS.archived,
      };
    case INVOICE_STATUSES.paid:
      return {
        text:
          customText?.[INVOICE_STATUSES.paid] ??
          formatMessage({ id: 'supplier-invoices.preview.status.paid' }),
        level: STATUS_TAG_LEVELS.paid,
      };
    case INVOICE_STATUSES.toReview:
      return {
        text:
          customText?.[INVOICE_STATUSES.toReview] ??
          formatMessage({ id: 'supplier-invoices.preview.status.imported' }),
        level: STATUS_TAG_LEVELS.toReview,
      };
    case INVOICE_STATUSES.toPay:
      return {
        text:
          customText?.[INVOICE_STATUSES.toPay] ??
          (isEinvoice
            ? formatMessage({ id: 'supplier-invoices.preview.status.to-pay' })
            : formatMessage({ id: 'supplier-invoices.section.tab.inbox' })),
        level: isEinvoice ? STATUS_TAG_LEVELS.einvoice : STATUS_TAG_LEVELS.toPay,
      };
    case INVOICE_STATUSES.awaitingPayment:
      return {
        text:
          customText?.[INVOICE_STATUSES.awaitingPayment] ??
          formatMessage({ id: 'supplier-invoices.preview.status.to-pay' }),
        level: STATUS_TAG_LEVELS.awaitingPayment,
      };
    case INVOICE_STATUSES.toApprove:
      return {
        text:
          customText?.[INVOICE_STATUSES.toApprove] ??
          formatMessage({ id: 'supplier-invoices.preview.status.to-approve' }),
        level: STATUS_TAG_LEVELS.toApprove,
      };
    case INVOICE_STATUSES.rejected:
      return {
        text:
          customText?.[INVOICE_STATUSES.rejected] ??
          formatMessage({ id: 'supplier-invoices.preview.status.rejected' }),
        level: STATUS_TAG_LEVELS.rejected,
      };
    default:
      return {
        text:
          customText?.[INVOICE_STATUSES.inbox] ??
          formatMessage({ id: 'supplier-invoices.section.tab.inbox' }),
        level: STATUS_TAG_LEVELS.inbox,
      };
  }
};

export function StatusTag(props: StatusTagProps): JSX.Element {
  const { text, level } = useStatusValues(props);

  return <BadgeStatus data-test-status="" level={level} text={text} />;
}
