import { type ReactElement } from 'react';
import { Button, Popup } from '@repo/design-system-kit';
import ENV from 'qonto/config/environment';
import styles from './non-eligible-modal.strict-module.css';

const isTest = ENV.environment === 'test';

interface NonEligibleModalProps {
  title: string;
  subtitle: string;
  ctaText: string;
  onClose: () => void;
}

export function NonEligibleModal({
  title,
  subtitle,
  ctaText,
  onClose,
  ...props
}: NonEligibleModalProps): ReactElement {
  const TEST_PORTAL_CONTAINER =
    document.getElementById('ember-testing') ?? document.querySelector('main') ?? undefined;

  return (
    <Popup.Root
      data-testid="data-test-non-eligible-modal"
      portalContainer={isTest ? TEST_PORTAL_CONTAINER : undefined}
      {...props}
    >
      <Popup.CloseButton
        data-testid="data-test-non-eligible-modal-close-button"
        onPress={onClose}
      />

      <Popup.Header>
        <Popup.Title data-testid="data-test-non-eligible-modal-title">{title}</Popup.Title>

        <Popup.Subtitle data-testid="data-test-non-eligible-modal-subtitle">
          <span className={styles.subtitle} dangerouslySetInnerHTML={{ __html: subtitle }} />
        </Popup.Subtitle>
      </Popup.Header>

      <Popup.Footer>
        <Button data-testid="data-test-non-eligible-modal-cta" onPress={onClose} variant="primary">
          {ctaText}
        </Button>
      </Popup.Footer>
    </Popup.Root>
  );
}
