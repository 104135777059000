import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { Category } from 'qonto/react/components/transactions/sidebar/category/cashflow-category/components/category';
import type { CashflowCategory } from 'qonto/react/models/cash-flow-category';
import type { Transaction } from 'qonto/react/graphql';
import styles from './styles.strict-module.css';

interface CashflowCategorySelectedProps {
  categories?: CashflowCategory[];
  transaction: Transaction;
}

export function CashflowCategorySelected({
  categories,
  transaction,
}: CashflowCategorySelectedProps): ReactNode {
  if (!categories?.length || !transaction.categoryAssignment) return <EmptyCategoryCell />;

  const selectedCategoryId = transaction.categoryAssignment.id;
  const isAutoCategorized = Boolean(
    !['manual', 'rule'].includes(transaction.categoryAssignment.source)
  );
  const selectedCategory = categories.find(category => category.id === selectedCategoryId);

  if (selectedCategory) {
    return <Category emphasized isAutoCategorized={isAutoCategorized} item={selectedCategory} />;
  }

  return <EmptyCategoryCell />;
}

export function EmptyCategoryCell(): ReactNode {
  const { formatMessage } = useIntl();

  return (
    <span className={cx('body-1', styles.uncategorized)} data-testid="category-name-empty">
      {formatMessage({ id: 'cash-flow-categories.uncategorized.label' })}
    </span>
  );
}
