import { Fragment, useState, type ReactNode } from 'react';
import cx from 'clsx';
import { useIntl, type IntlShape } from 'react-intl';
import {
  DndContext,
  DragOverlay,
  useDroppable,
  useSensor,
  useSensors,
  PointerSensor,
  KeyboardSensor,
  closestCorners,
  type DragEndEvent,
  type UniqueIdentifier,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { Popover } from 'qonto/react/components/table-v2/popover';
import type { DisplayColumn } from 'qonto/constants/table-view';
import { getColumnTitle } from 'qonto/react/utils/transactions/modular-tables-columns-map';
import type { LabelList } from 'qonto/react/graphql';
import styles from './styles.strict-module.css';
import { ColumnManagerItem } from './item';

interface ColumnManagerListProps {
  listId: 'shown' | 'hidden';
  listTitle: string;
  columns: DisplayColumn[];
  formatMessage: IntlShape['formatMessage'];
  updateColumn: (column: DisplayColumn) => void;
  labelLists: LabelList[];
  activeId: UniqueIdentifier | null;
  overId: UniqueIdentifier | null;
  findContainer: (id: UniqueIdentifier) => 'shown' | 'hidden';
}

function ColumnManagerList({
  listId,
  listTitle,
  columns,
  formatMessage,
  updateColumn,
  labelLists,
  findContainer,
  activeId,
  overId,
}: ColumnManagerListProps): ReactNode {
  const { setNodeRef } = useDroppable({ id: listId });
  const sortableItems = columns.filter(col => col.pinned !== 'left').map(col => col.id);

  const renderPlaceholder = (column: DisplayColumn): ReactNode | undefined => {
    if (column.id === overId && overId !== 'transaction') {
      const activeContainer = activeId ? findContainer(activeId) : null;
      const overContainer = listId;

      if (activeContainer !== overContainer) {
        return <div className={styles.placeholder} />;
      }
    }
  };

  return (
    <div className={styles.section}>
      <div className={styles.header}>
        <span className={cx(styles.title, 'body-2')} data-testid="column-manager-list-title">
          {listTitle}
        </span>
      </div>
      <div className={styles.scrollable} ref={setNodeRef}>
        <SortableContext items={sortableItems} strategy={verticalListSortingStrategy}>
          <ul className={styles.list}>
            {columns.map(column => (
              <Fragment key={column.id}>
                {renderPlaceholder(column)}
                <ColumnManagerItem
                  column={column}
                  key={column.id}
                  title={getColumnTitle(column.id, labelLists, formatMessage)}
                  updateColumn={updateColumn}
                />
              </Fragment>
            ))}
          </ul>
        </SortableContext>
      </div>
    </div>
  );
}

interface ColumnManagerPopoverProps {
  visibleColumns: DisplayColumn[];
  hiddenColumns: DisplayColumn[];
  updateColumn: (column: DisplayColumn) => void;
  labelLists: LabelList[];
}

export function ColumnManagerPopover({
  visibleColumns = [],
  hiddenColumns = [],
  updateColumn,
  labelLists,
  ...props
}: ColumnManagerPopoverProps): ReactNode {
  const { formatMessage } = useIntl();
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 5 } }),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const findContainer = (id: UniqueIdentifier): 'shown' | 'hidden' =>
    visibleColumns.some(col => col.id === id) ? 'shown' : 'hidden';

  const handleDragOver = ({ over }: DragEndEvent): void => {
    if (!over) return;
    setOverId(over.id);
  };

  const handleDragEnd = ({ active, over }: DragEndEvent): void => {
    if (!over) return;

    const activeItemId = active.id;
    const overItemId = over.id;
    const activeContainer = findContainer(activeItemId);
    const overContainer = findContainer(overItemId);
    const isVisible = overContainer === 'shown';
    const sourceColumns = activeContainer === 'shown' ? [...visibleColumns] : [...hiddenColumns];
    const targetColumns = overContainer === 'shown' ? [...visibleColumns] : [...hiddenColumns];

    const activeIndex = sourceColumns.findIndex(col => col.id === activeItemId);
    const overIndex = targetColumns.findIndex(col => col.id === overId);

    if (activeIndex !== -1) {
      const [movedColumn] = sourceColumns.splice(activeIndex, 1);
      if (movedColumn) {
        let newPosition;

        if (!isVisible) {
          newPosition = movedColumn.position;
          if (!movedColumn.isVisible) return;
        } else {
          newPosition = overIndex !== -1 ? overIndex : targetColumns.length;
        }

        updateColumn({ ...movedColumn, isVisible, position: newPosition === 0 ? 1 : newPosition });
      }
    }

    setActiveId(null);
    setOverId(null);
  };

  const activeColumn = [...visibleColumns, ...hiddenColumns].find(col => col.id === activeId);

  return (
    <Popover
      className={styles.popover}
      crossOffset={16}
      data-testid="column-manager-popover"
      height={342}
      offset={8}
      width={448}
      {...props}
    >
      <div className={styles.wrapper}>
        <DndContext
          collisionDetection={closestCorners}
          onDragEnd={handleDragEnd}
          onDragOver={handleDragOver}
          onDragStart={({ active }) => {
            setActiveId(active.id);
          }}
          sensors={sensors}
        >
          <ColumnManagerList
            activeId={activeId}
            columns={visibleColumns}
            findContainer={findContainer}
            formatMessage={formatMessage}
            labelLists={labelLists}
            listId="shown"
            listTitle={formatMessage({
              id: 'transactions.modular_table.manage_column.dialog.label.shown',
            })}
            overId={overId}
            updateColumn={updateColumn}
          />
          <div className={styles.separator} />
          <ColumnManagerList
            activeId={activeId}
            columns={hiddenColumns}
            findContainer={findContainer}
            formatMessage={formatMessage}
            labelLists={labelLists}
            listId="hidden"
            listTitle={formatMessage({
              id: 'transactions.modular_table.manage_column.dialog.label.hidden',
            })}
            overId={overId}
            updateColumn={updateColumn}
          />
          <DragOverlay className={styles['drag-overlay']}>
            {activeId && activeColumn ? (
              <ColumnManagerItem
                column={activeColumn}
                hideActionSlot
                title={getColumnTitle(activeColumn.id, labelLists, formatMessage)}
                updateColumn={updateColumn}
              />
            ) : null}
          </DragOverlay>
        </DndContext>
      </div>
    </Popover>
  );
}
