import { type ReactNode } from 'react';
import { CardLevel } from '@repo/cards-kit/constants';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import cx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from '@qonto/react-migration-toolkit/react/components';
import { IconArrowLeftOutlined } from '@repo/monochrome-icons';
import type { Estimates } from 'qonto/react/cards/types/estimates';
import type { OptionPrices } from 'qonto/react/cards/types/option-price';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import type { CardLimits } from 'qonto/react/cards/types/limits';
import { ChooseCardLayout } from 'qonto/react/cards/components/flows/choose-card-layout';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import type { Subscription } from 'qonto/models/organization-subscription';
import styles from './styles.strict-module.css';
import { VirtualCard } from './internal/virtual-card';
import { AdvertisingCard } from './internal/advertising-card';
import { FlashCard } from './internal/flash-card';
import { Footer } from './internal/footer';

interface ChooseCardDigitalProps {
  context: {
    cardOptionsPrices: OptionPrices;
    cardsLimits: CardLimits;
    currentSubscription: Subscription | null;
    estimates: Estimates;
    pricePlan: {
      code: string;
    };
  };
  onUpsellSuccess?: (data: { recurrence: string; addonCode: string }) => void;
  handleClick: { perform: (cardLevel: CardLevel) => Promise<void> };
}

export function ChooseCardDigital({
  context,
  onUpsellSuccess,
  handleClick,
}: ChooseCardDigitalProps): ReactNode {
  const abilities = useEmberService('abilities');

  const { formatMessage } = useIntl();
  const { organization } = useOrganizationManager();

  const { currentSubscription, estimates, cardOptionsPrices: optionPrices, cardsLimits } = context;

  const handleNewCardClick = async (cardLevel: CardLevel): Promise<void> => {
    await handleClick.perform(cardLevel).catch(ignoreCancelation);
  };

  return (
    <>
      <Link
        aria-label={formatMessage({ id: 'btn.back' })}
        className={cx(styles['back-button'], 'btn btn--tertiary', 'btn--large', 'btn--icon-only')}
        data-test-choose-card-back
        to={`/organizations/${organization.slug}/cards/physical-or-virtual`}
      >
        <IconArrowLeftOutlined />
      </Link>

      <div className={styles.container}>
        <h1 className={cx('title-1', 'mb-32')} data-test-choose-card-title>
          <FormattedMessage id="cards.steps.choose-card.virtual-category.title" />
        </h1>

        <div className={styles['selectors-container']}>
          <ul className={cx(styles['cards-list'], 'mb-20')} data-test-choose-card-selectors>
            <ChooseCardLayout>
              <VirtualCard
                assetProps={{ isImageOnly: true, className: styles.asset }}
                cardLimit={cardsLimits[CardLevel.Virtual]}
                ctaProps={{ handleClick: () => handleNewCardClick(CardLevel.Virtual) }}
                headerProps={{
                  cardEstimates: estimates[CardLevel.Virtual],
                  optionPrice: optionPrices[CardLevel.Virtual],
                }}
              />
              {abilities.can('view advertising card') && (
                <AdvertisingCard
                  assetProps={{ isImageOnly: true, className: styles.asset }}
                  ctaProps={{ handleClick: () => handleNewCardClick(CardLevel.Advertising) }}
                  headerProps={{
                    cardEstimates: estimates[CardLevel.Advertising],
                    optionPrice: optionPrices[CardLevel.Advertising],
                  }}
                />
              )}
              {(abilities.can('view flash card') || abilities.can('update subscription')) && (
                <FlashCard
                  assetProps={{ isImageOnly: true, className: styles.asset }}
                  cardLimit={cardsLimits[CardLevel.Flash]}
                  ctaProps={{ handleClick: () => handleNewCardClick(CardLevel.Flash) }}
                  flashCardUpsellProps={{
                    activeTrialRemainingDays: currentSubscription?.activeTrialRemainingDays ?? 0,
                    hasInitialTrial: currentSubscription?.hasInitialTrial ?? false,
                    hasInitialTrialUpsell: currentSubscription?.hasInitialTrialUpsell ?? false,
                    onUpsellSuccess,
                    pricePlan: context.pricePlan,
                  }}
                  headerProps={{
                    cardEstimates: estimates[CardLevel.Flash],
                    optionPrice: optionPrices[CardLevel.Flash],
                    shouldHidePricing: abilities.cannot('view flash card'),
                  }}
                />
              )}
            </ChooseCardLayout>
          </ul>

          <Footer estimates={estimates} />
        </div>
      </div>
    </>
  );
}
