import { useRouter } from '@qonto/react-migration-toolkit/react/hooks';
import { useSegment } from '@repo/poly-hooks/segment';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import type { UpcomingTransaction } from 'qonto/react/hooks/use-upcoming-transactions';

export const useMarkAsPaid = (upcomingTransaction: UpcomingTransaction | null): (() => void) => {
  const segment = useSegment();
  const router = useRouter();
  const { organization } = useOrganizationManager();
  const type = upcomingTransaction?.provider_object_type;
  const invoiceId = upcomingTransaction?.provider_object_id;

  return (): void => {
    segment.track('compass_action_clicked', {
      action: 'mark_as_paid',
      type,
    });

    if (invoiceId === undefined) return;

    if (type === 'supplier_invoice') {
      const path = `/organizations/${organization.slug}/supplier-invoices/list/match-transaction`;
      const queryParams = new URLSearchParams({
        invoice_id: invoiceId,
        origin: 'upcoming-transactions',
      }).toString();
      void router.push(`${path}?${queryParams}`);
    } else {
      const path = `/organizations/${organization.slug}/flows/match-invoice/select-transaction`;

      const queryParams = new URLSearchParams({
        invoiceId,
        trigger: 'receivableInvoices',
        origin: 'upcoming-transactions',
      }).toString();

      void router.push(`${path}?${queryParams}`);
    }
  };
};
