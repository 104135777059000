/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import React, { useCallback, useEffect, useState, type Key, type ReactNode } from 'react';
import { Button, Label, Select } from 'react-aria-components';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { SkeletonLoader } from '@repo/design-system-kit';
import { IconChevronBottomOutlined } from '@repo/monochrome-icons';
import type {
  CashflowActiveCategoryId,
  CashflowCategories,
} from 'qonto/react/models/cash-flow-category';
import { getFlatCategories } from '../utils/cash-flow-categories';
import styles from './styles.strict-module.css';
import { Category } from './category';
import { CategoriesMenu } from './categories-menu';
import { CategorySelectorPopover } from './category-selector/category-selector-popover';

interface CashflowCategorySelectorProps {
  categories?: CashflowCategories;
  activeCategoryId: CashflowActiveCategoryId;
  onSelectionChange: (categoryId: CashflowActiveCategoryId) => void;
  isLoading?: boolean;
  isAutoCategorized?: boolean;
  showCategoriesMenu?: boolean;
  popoverWidth?: string;
  showLabel?: boolean;
  children?: ReactNode;
  portalContainer?: Element;
  isIntegratedInSidepanel?: boolean;
}
export function CashflowCategorySelector({
  categories = {
    inflow: [],
    outflow: [],
  },
  activeCategoryId = null,
  onSelectionChange,
  isLoading = false,
  isAutoCategorized = false,
  showCategoriesMenu = true,
  popoverWidth,
  showLabel = true,
  portalContainer = document.body,
  isIntegratedInSidepanel = false,
}: CashflowCategorySelectorProps): ReactNode {
  const { formatMessage } = useIntl();
  const segment = useEmberService('segment');

  const flatCategories = getFlatCategories(categories);
  const hasCategories = flatCategories.length > 0;
  const [selectedCategoryId, setSelectedCategoryId] = useState(activeCategoryId);
  useEffect(() => {
    setSelectedCategoryId(activeCategoryId);
  }, [activeCategoryId]);

  const handleOpenChange = (isOpen: boolean): void => {
    if (isOpen) {
      segment.track('transaction_categories-opened', {
        ...(isIntegratedInSidepanel ? { origin: 'sidepanel' } : {}),
      });
    }
  };

  const handleSelectionChange = (key: Key): void => {
    const newCategoryId = key === selectedCategoryId ? null : (key as string);
    setSelectedCategoryId(newCategoryId);
    onSelectionChange(newCategoryId);

    const category = flatCategories.find(({ id }) => id === key);
    segment.track('transaction_category-selected', {
      category: category?.nameKey ?? category?.name,
      selection: newCategoryId ? 'selected' : 'unselected',
      ...(isIntegratedInSidepanel ? { origin: 'sidepanel' } : {}),
    });
  };

  const displaySelectedCategory = (): ReactNode => {
    const selectedCategory = flatCategories.find(category => category.id === selectedCategoryId);
    if (selectedCategory)
      return <Category emphasized isAutoCategorized={isAutoCategorized} item={selectedCategory} />;

    return (
      <span className={styles['select-placeholder']}>
        {hasCategories
          ? formatMessage({ id: 'transactions.sidebar.cash-flow-categories.dropdown.empty' })
          : 'No categories available'}
      </span>
    );
  };

  const [triggerWidth, setTriggerWidth] = useState<string>('auto');
  const buttonRef = useCallback((node: HTMLButtonElement | null) => {
    if (node !== null) {
      const newWidth = node.getBoundingClientRect().width;
      setTriggerWidth(`${newWidth.toString()}px`);
    }
  }, []);

  return (
    <div className={styles['category-block']}>
      <Select
        aria-label={formatMessage({ id: 'transactions.sidebar.category' })}
        className={styles.select}
        data-testid="cash-flow-category-selector"
        isDisabled={!hasCategories}
        onOpenChange={handleOpenChange}
        onSelectionChange={handleSelectionChange}
        selectedKey={selectedCategoryId}
      >
        {showLabel ? (
          <Label className={styles.title} data-testid="title">
            {formatMessage({ id: 'transactions.sidebar.category' })}
          </Label>
        ) : null}
        {isLoading ? (
          <SkeletonLoader.Line borderRadius="4px" height="40px" width="100%" />
        ) : (
          <Button
            className={cx('body-1', 'overlay', styles.trigger)}
            data-testid="trigger"
            ref={buttonRef}
          >
            <span className={styles['trigger-text']}>{displaySelectedCategory()}</span>
            <IconChevronBottomOutlined aria-hidden="true" className={styles['arrow-icon']} />
          </Button>
        )}
        {hasCategories ? (
          <CategorySelectorPopover
            activeCategoryId={activeCategoryId}
            categories={categories}
            onAction={handleSelectionChange}
            popoverWidth={popoverWidth}
            portalContainer={portalContainer}
            triggerWidth={triggerWidth}
          />
        ) : null}
      </Select>
      {showCategoriesMenu ? (
        <div className={styles['menu-trigger']}>
          <CategoriesMenu />
        </div>
      ) : null}
    </div>
  );
}
