import { Badge, Button } from '@repo/design-system-kit';
import cx from 'clsx';
import type { ComponentProps, ComponentPropsWithoutRef, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { IconCrossOutlined } from '@repo/monochrome-icons';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './promotional-banner.strict-module.css';

function Arrow(props: ComponentPropsWithoutRef<'svg'>): ReactNode {
  return (
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.01835 14.0202C7.82309 13.8249 7.82309 13.5084 8.01835 13.3131L12.8315 8.49998L2.03857 8.49998C1.76243 8.49998 1.53857 8.27612 1.53857 7.99998C1.53857 7.72384 1.76243 7.49998 2.03857 7.49998L12.8315 7.49998L8.01835 2.68687C7.82309 2.4916 7.82309 2.17502 8.01835 1.97976C8.21362 1.7845 8.5302 1.7845 8.72546 1.97976L14.3921 7.64643C14.5874 7.84169 14.5874 8.15827 14.3921 8.35353L8.72546 14.0202C8.5302 14.2155 8.21362 14.2155 8.01835 14.0202Z"
        fill="currentColor"
      />
    </svg>
  );
}

interface PromotionalBannerProps extends ComponentPropsWithoutRef<'div'> {
  badgeOptions?: Pick<ComponentProps<typeof Badge>, 'type' | 'text'>;
  cta: string;
  description: string;
  illustration?: string;
  onClick: () => void;
  onClose?: () => void;
  title?: string;
}

export function PromotionalBanner({
  badgeOptions,
  className,
  cta,
  description,
  illustration,
  onClick,
  onClose,
  title,
  ...props
}: PromotionalBannerProps): ReactNode {
  const { formatMessage } = useIntl();

  return (
    <div className={cx(className, styles.wrapper)} {...props} data-test-promotional-banner>
      <div className={styles.content}>
        {badgeOptions !== undefined && (
          <Badge {...badgeOptions} data-test-promotional-banner-badge />
        )}
        {Boolean(title) && (
          <h4 className="title-4" data-test-promotional-banner-title>
            {title}
          </h4>
        )}
        <p className="body-2" data-test-promotional-banner-description>
          {description}
        </p>
        <Button
          data-test-promotional-banner-button
          onPress={onClick}
          size="small"
          variant="tertiary"
        >
          {cta}
          <Arrow className="ml-8" data-test-promotional-banner-button-arrow-icon />
        </Button>
      </div>
      {illustration !== undefined && (
        <div className={styles.image}>
          <StaticThemedAsset assetPath={illustration} data-test-promotional-banner-illustration />
        </div>
      )}
      {Boolean(onClose) && (
        <Button
          aria-label={formatMessage({ id: 'a11y.buttons.close-aria-label' })}
          className={styles.close}
          data-test-promotional-banner-close-button
          iconOnly
          onPress={onClose}
          variant="tertiary"
        >
          <IconCrossOutlined />
        </Button>
      )}
    </div>
  );
}
