import cx from 'clsx';
import { type ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSegment } from '@repo/poly-hooks/segment';
import { IconExternalOutlined } from '@repo/monochrome-icons';
import { ExternalLink } from '@repo/design-system-kit';
import { CARD_LEVELS } from 'qonto/constants/cards';
import styles from './insurance-links.strict-module.css';

interface InsuranceLinksProps {
  cardLevel?: string;
  className?: string;
}

interface GetInsuranceNotice {
  cta: string;
  link: string;
}

export function InsuranceLinks({ cardLevel, className, ...props }: InsuranceLinksProps): ReactNode {
  const { formatMessage } = useIntl();
  const segment = useSegment();

  const getInsuranceNotice = (): GetInsuranceNotice => {
    switch (cardLevel) {
      case CARD_LEVELS.METAL:
        return {
          cta: formatMessage({ id: 'insurance.notice.metal.download' }),
          link: formatMessage({ id: 'insurance.notice.metal.download-link' }),
        };
      case CARD_LEVELS.PLUS:
        return {
          cta: formatMessage({ id: 'insurance.notice.plus.download' }),
          link: formatMessage({ id: 'insurance.notice.plus.download-link' }),
        };
      default:
        return {
          cta: formatMessage({ id: 'insurance.notice.standard.download' }),
          link: formatMessage({ id: 'insurance.notice.standard.download-link' }),
        };
    }
  };

  const insuranceNotice = getInsuranceNotice();

  const handleCertificateClick = (): void => {
    segment.track('insurance_certificate');
  };

  return (
    <div className={cx(styles.container, className)} {...props}>
      <h2 className="sr-only">
        <FormattedMessage id="insurance.resources.title" />
      </h2>
      <a
        className="btn btn--tertiary mb-32"
        data-test-insurance-notice-link
        download
        href={insuranceNotice.link}
        rel="noopener noreferrer"
        target="_blank"
      >
        {insuranceNotice.cta}
      </a>

      <span className={cx(styles.highlight, 'mb-8')}>
        <ExternalLink
          className="mr-8"
          data-test-insurance-certificate-link
          href={formatMessage({ id: 'insurance.certificate.request-link' })}
          onPress={handleCertificateClick}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FormattedMessage id="insurance.certificate.request" />
        </ExternalLink>
        <IconExternalOutlined
          aria-hidden="true"
          data-test-insurance-links-icon-certificate="icon_external"
          height="12"
          width="12"
        />
      </span>
      <span className={styles.highlight}>
        <ExternalLink
          className="mr-8"
          data-test-insurance-compare-link
          href={formatMessage({ id: 'insurance.coverage.compare-link' })}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FormattedMessage id="insurance.coverage.compare" />
        </ExternalLink>
        <IconExternalOutlined
          aria-hidden="true"
          data-test-insurance-links-icon-compare="icon_external"
          height="12"
          width="12"
        />
      </span>
    </div>
  );
}
