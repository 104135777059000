import { type ReactNode } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { Sidebar } from '@repo/cards-kit/components';

export function AdvertisingMerchants(): ReactNode {
  const { formatMessage } = useIntl();
  const zendeskLocalization = useEmberService('zendeskLocalization');

  return (
    <Sidebar.Section title={formatMessage({ id: 'cards.sidebar.advertising.merchants.title' })}>
      <span className="body-2">
        <FormattedMessage
          id="cards.sidebar.advertising.merchants.platforms.body"
          values={{
            advertisingPlatforms: (
              <a
                className="body-link"
                href={zendeskLocalization.getLocalizedArticle(7206496)}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FormattedMessage id="cards.sidebar.advertising.merchants.platforms.link-copy" />
              </a>
            ),
          }}
        />
      </span>
    </Sidebar.Section>
  );
}
