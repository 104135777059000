import cx from 'clsx';
import { type ReactElement } from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './placeholder.strict-module.css';

export function Placeholder(): ReactElement {
  return (
    <tr className={styles.row}>
      <td className={styles.empty} />
      <td className={cx(styles.cell, styles.supplier)}>
        <div className={styles['cell-content']}>
          <SkeletonLoader.Avatar
            className="mr-16"
            data-testid="placeholder-block"
            height="32px"
            width="32px"
          />
          <div className={styles['cell-details']}>
            <SkeletonLoader.Line data-testid="placeholder-line" standalone width="90px" />
            <SkeletonLoader.Line data-testid="placeholder-line" standalone width="130px" />
          </div>
        </div>
      </td>
      <td className={cx(styles.cell, styles['next-installment'])}>
        <SkeletonLoader.Line data-testid="placeholder-line" standalone width="96px" />
      </td>
      <td className={cx(styles.cell, styles['installment-amount'])}>
        <SkeletonLoader.Line data-testid="placeholder-line" standalone width="96px" />
      </td>
      <td className={cx(styles.cell, styles['total-repayment'])}>
        <SkeletonLoader.Line
          className={styles['align-right']}
          data-testid="placeholder-line"
          standalone
          width="104px"
        />
      </td>
      <td className={styles.empty} />
    </tr>
  );
}
