import type { ReactNode } from 'react';
import type {
  CashflowActiveCategoryId,
  CashflowCategories,
  CashflowCategoryAssignment,
} from 'qonto/react/models/cash-flow-category';
import { CategorySelectorPopover } from 'qonto/react/components/transactions/sidebar/category/cashflow-category/components/category-selector/category-selector-popover';
import { useAssignTransactionsCategory } from 'qonto/react/hooks/use-assign-transactions-category';
import {
  useCategorizationRulesManager,
  useReactCategorizationRulesManager,
} from 'qonto/react/hooks/use-categorization-rules-manager';

interface CashflowCategoryCellPopoverProps {
  categories?: CashflowCategories;
  activeCategoryId?: CashflowActiveCategoryId;
  transactionId: string;
}

export function CashflowCategoryCellPopover({
  categories = {
    inflow: [],
    outflow: [],
  },
  activeCategoryId,
  transactionId,
}: CashflowCategoryCellPopoverProps): ReactNode {
  const { mutate } = useAssignTransactionsCategory();
  const categorizationManager = useCategorizationRulesManager();
  const reactCategorizationManager = useReactCategorizationRulesManager();
  const portalContainer = document.body;
  const popoverWidth = '280px';
  const mtPopoverStyles = {
    marginLeft: '-16px',
    backgroundColor: 'var(--content-primary-b)',
    border: '1px solid var(--border-tertiary)',
    borderRadius: '4px',
    boxShadow: 'var(--shadow-high)',
  };

  const handleCategorySelection = (selectedCategoryId: string): void => {
    if (selectedCategoryId === activeCategoryId) return;

    mutate({ transactionIds: [transactionId], categoryId: selectedCategoryId });
    const assignment: CashflowCategoryAssignment = {
      transactionIds: [transactionId],
      categoryId: selectedCategoryId,
    };

    reactCategorizationManager.categorizeTransactions(assignment);
    categorizationManager.categorizeTransactions(assignment);
  };

  return (
    <CategorySelectorPopover
      activeCategoryId={activeCategoryId}
      categories={categories}
      offset={4}
      onAction={handleCategorySelection}
      placement="bottom start"
      popoverStyles={mtPopoverStyles}
      popoverWidth={popoverWidth}
      portalContainer={portalContainer}
      triggerWidth={popoverWidth}
    />
  );
}
