import React, { useState, useMemo } from 'react';
import { Select, SelectOption, Button, Flag } from '@repo/design-system-kit';
import type { Key } from 'react-aria';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useIntl } from 'react-intl';
import { type CountryCode } from '@repo/design-system-kit';
import { LOCALES_DEFINITION } from '@repo/shared-config/constants/locales';
import styles from './account-iban-download.strict-module.css';

interface BankAccount {
  id: string;
  iban: string;
  organization: {
    legalCountry: string;
  };
}

interface AccountIbanDownloadProps {
  bankAccount: BankAccount;
}

const localeToCountryCode = (locale: string): string => {
  if (locale === 'en') {
    return 'GB';
  }
  return locale.toUpperCase();
};

export function AccountIbanDownload({ bankAccount }: AccountIbanDownloadProps): React.JSX.Element {
  const { formatMessage } = useIntl();
  const segment = useEmberService('segment');
  const bankAccountService = useEmberService('bankAccount');
  const toastFlashMessages = useEmberService('toastFlashMessages');

  const organizationLocale = bankAccount.organization.legalCountry.toLowerCase();

  const allLocales = useMemo(() => {
    return Object.values(LOCALES_DEFINITION).map(locale => locale.code);
  }, []);

  const sortedLocales = useMemo((): string[] => {
    return [...allLocales].sort((a, b) => {
      if (a === organizationLocale) {
        return -1;
      }

      if (b === organizationLocale) {
        return 1;
      }

      if (a === 'en') {
        return -1;
      }

      if (b === 'en') {
        return 1;
      }

      return a < b ? -1 : 1;
    });
  }, [allLocales, organizationLocale]);

  const [selectedLocale, setSelectedLocale] = useState<string | null>(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleSelectionChange = (key: Key): void => {
    setSelectedLocale(key as string);
  };

  const handleDownload = async (): Promise<void> => {
    if (isDownloading || !selectedLocale) {
      return;
    }

    setIsDownloading(true);

    try {
      if (selectedLocale === organizationLocale) {
        segment.track('account_details_download_local');
      } else {
        segment.track(`account_details_download_${selectedLocale}`);
      }

      await bankAccountService.downloadIbanPdf(bankAccount.id, selectedLocale);
    } catch {
      toastFlashMessages.toastError('An error occurred while downloading the IBAN PDF');
    } finally {
      setIsDownloading(false);
    }
  };

  const handleCopyIban = (): void => {
    try {
      const formattedIban = bankAccount.iban.replace(/(?<group>.{4})/g, '$<group> ').trim();

      void navigator.clipboard.writeText(formattedIban);

      toastFlashMessages.toastInfo('IBAN copied to clipboard');

      segment.track('account_details_iban_copied');
    } catch {
      toastFlashMessages.toastError('bank_accounts.share.copy_error');
    }
  };

  const getLocaleName = (localeCode: string): string => {
    const localeObj = Object.values(LOCALES_DEFINITION).find(l => l.code === localeCode);
    return localeObj?.name || localeCode;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.selectContainer}>
        <Select
          className={styles.select}
          data-testid="iban-language-dropdown"
          isDisabled={isDownloading}
          label={formatMessage(
            { id: 'bank_accounts.download.selector-title' },
            { defaultMessage: 'Document Language' }
          )}
          onSelectionChange={handleSelectionChange}
          placeholder={formatMessage(
            { id: 'bank_accounts.download.selector-placeholder' },
            { defaultMessage: 'Select a Language' }
          )}
          selectedKey={selectedLocale}
        >
          {sortedLocales.map(locale => (
            <SelectOption id={locale} key={locale}>
              <div className={styles.optionContent}>
                <Flag
                  className={styles.flagIcon}
                  code={localeToCountryCode(locale) as CountryCode}
                  size="small"
                  variant="square"
                />
                <span className={styles.optionText}>
                  {formatMessage(
                    { id: `bank_accounts.share.download.link-${locale}` },
                    { defaultMessage: getLocaleName(locale) }
                  )}
                </span>
              </div>
            </SelectOption>
          ))}
        </Select>
      </div>

      <div className={styles.actionsContainer}>
        <Button
          data-testid="download-iban-button"
          isDisabled={isDownloading || !selectedLocale}
          isLoading={isDownloading}
          onPress={handleDownload}
          variant="primary"
        >
          {formatMessage(
            { id: 'bank_accounts.share.download.cta' },
            { defaultMessage: 'Download (PDF)' }
          )}
        </Button>

        <span className={styles.orSeparator}>
          {formatMessage({ id: 'bank_accounts.iban.cta-or' }, { defaultMessage: 'OR' })}
        </span>

        <Button data-testid="copy-iban-button" onPress={handleCopyIban} variant="tertiary">
          {formatMessage({ id: 'bank_accounts.iban.copy-cta' }, { defaultMessage: 'Copy Iban' })}
        </Button>
      </div>
    </div>
  );
}
