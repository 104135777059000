import type { ReactNode } from 'react';
import {
  prepareCashflowTimeseriesRequest,
  useCashflowTimeseries,
} from 'qonto/react/hooks/use-cashflow-timeseries';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { type CashflowPeriod, type CashflowPeriodRate } from 'qonto/react/models/cash-flow-period';
import { CASHFLOW_FORECASTED_MONTHS_DEFAULT } from 'qonto/react/api/models/cash-flow-timeseries';
import styles from './overview-boxes.strict-module.css';
import { CurrentBalanceOverviewBox } from './current-balance-overview';
import { VatOverviewBox } from './vat-overview';
import { CashBurnOverviewBox } from './cash-burn-overview';

interface OverviewBoxesProps {
  bankAccounts: string;
  selectedPeriod: CashflowPeriod;
  selectedFrequency?: CashflowPeriodRate;
  isLoading?: boolean;
}
export function OverviewBoxes({
  bankAccounts,
  selectedPeriod,
  selectedFrequency,
  isLoading = false,
}: OverviewBoxesProps): ReactNode {
  const { organization } = useOrganizationManager();
  const { queryKey, request } = prepareCashflowTimeseriesRequest({
    selectedFrequency,
    selectedPeriod,
    forecastedPeriods: CASHFLOW_FORECASTED_MONTHS_DEFAULT,
    organization,
    bankAccounts,
  });

  const { data: timeseries, isPending, isError } = useCashflowTimeseries(request, queryKey);
  const isDataLoading = isPending || isLoading;

  return (
    <section className={styles.container}>
      <CurrentBalanceOverviewBox
        currentBalanceAmount={timeseries?.currentBalanceAmount}
        isError={isError}
        isLoading={isDataLoading}
      />
      <VatOverviewBox isError={isError} isLoading={isDataLoading} vatData={timeseries?.vatData} />
      <CashBurnOverviewBox
        cashBurnData={timeseries?.cashBurnData}
        isError={isError}
        isLoading={isDataLoading}
        selectedFrequency={selectedFrequency}
      />
    </section>
  );
}
