/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { IconLockOutlined, IconChevronBottomOutlined } from '@repo/monochrome-icons';
import { formatAmountToNumber } from 'qonto/utils/amount';
import { formatMoney } from 'qonto/react/shared/utils/format-money';
import { EmailPreviewFooter } from 'qonto/react/components/receivable-invoices/email-preview/footer';
import {
  DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES,
  FREQUENCY_CUSTOM_UNITS,
} from 'qonto/constants/direct-debit-subscriptions';
import styles from './payment-page-preview.strict-module.css';

interface PaymentPagePreviewProps {
  logo?: { fileUrl: string };
  organization: { name: string };
  mandate: {
    isRecurring?: boolean;
    paymentInfo: {
      firstPayment?: {
        amount: {
          value: string;
          currency: string;
        };
        reference?: string;
        collectionDate?: string;
      };
      scheduleType: string;
      scheduleCustomInterval?: number;
      scheduleCustomUnit?: string;
    };
  };
  client: {
    kind: string;
    name?: string;
    firstName?: string;
    lastName?: string;
    email: string;
  };
  language: string;
}

export function PaymentPagePreview({
  logo,
  organization,
  mandate,
  client,
  language,
  ...rest
}: PaymentPagePreviewProps): ReactNode {
  const { formatMessage, formatDate } = useIntl();
  const theme = useEmberService('theme');
  const { locale } = useIntl();

  // @ts-expect-error ThemeService is not typed
  const { isCurrentThemeDark }: { isCurrentThemeDark: boolean } = theme;

  const getScheduleLabel = (): string | undefined => {
    const { scheduleType, scheduleCustomInterval, scheduleCustomUnit } = mandate.paymentInfo;
    let recurrence;

    switch (scheduleType) {
      case DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.WEEKLY:
        return formatMessage({ id: 'sdd-collections.recurrence.slash.every-week' }, { language });
      case DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.MONTHLY:
        return formatMessage({ id: 'sdd-collections.recurrence.slash.every-month' }, { language });
      case DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.QUARTERLY:
        return formatMessage(
          { id: 'sdd-collections.recurrence.slash.every-quarter' },
          { language }
        );
      case DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.YEARLY:
        return formatMessage({ id: 'sdd-collections.recurrence.slash.every-year' }, { language });
      case DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.CUSTOM:
        switch (scheduleCustomUnit) {
          case FREQUENCY_CUSTOM_UNITS.WEEKS:
            recurrence = formatMessage(
              {
                id: 'sdd-collections.creation-flow.payment-link.email.recurrence.weeks',
              },
              { language }
            );
            break;
          case FREQUENCY_CUSTOM_UNITS.MONTHS:
            recurrence = formatMessage(
              {
                id: 'sdd-collections.creation-flow.payment-link.email.recurrence.months',
              },
              { language }
            );
            break;
          case undefined:
          case FREQUENCY_CUSTOM_UNITS.YEARS:
            recurrence = formatMessage(
              {
                id: 'sdd-collections.creation-flow.payment-link.email.recurrence.years',
              },
              { language }
            );
            break;
        }
        return formatMessage(
          { id: 'sdd-collections.recurrence.slash.every-custom' },
          {
            language,
            recurrence,
            number: scheduleCustomInterval,
          }
        );
    }
  };

  const footerDisclaimer = formatMessage(
    {
      id: 'sdd-collections.creation-flow.payment-link.email.qonto-disclaimer',
    },
    { language },
    { ignoreTag: true }
  );

  return (
    <div className={styles.envelope} {...rest}>
      <div className={styles['payment-page']}>
        <div className={cx(styles.header, 'mb-32')}>
          <p className={styles.title}>
            <span className="title-1 mb-4" data-test-sdd-payment-page-preview-title>
              {formatMessage(
                {
                  id: mandate.isRecurring
                    ? 'sdd-collections.payment-link-page.title.recurring'
                    : 'sdd-collections.payment-link-page.title.one-off',
                },
                { language }
              )}
            </span>
            <span className="title-2" data-test-sdd-payment-page-preview-company-name>
              {organization.name}
            </span>
          </p>
          {logo ? (
            <img
              alt=""
              className={styles.logo}
              data-test-sdd-payment-page-preview-logo
              src={logo.fileUrl}
            />
          ) : null}
        </div>

        <p className="title-2 mb-4" data-test-sdd-payment-page-preview-amount>
          {formatMoney(
            formatAmountToNumber({
              amount: mandate.paymentInfo.firstPayment?.amount.value ?? '0',
              precision: 2,
            }),
            {
              currency: mandate.paymentInfo.firstPayment?.amount.currency,
              locale,
            }
          )}
          {mandate.isRecurring ? (
            <span data-test-sdd-payment-page-preview-frequency>{getScheduleLabel()}</span>
          ) : null}
        </p>

        <div className={cx(styles['payment-details'], 'title-4 mb-32')}>
          {mandate.paymentInfo.firstPayment?.reference ? (
            <dl className={styles.detail} data-test-sdd-payment-page-preview-reference>
              <dt>
                {formatMessage({ id: 'sdd-collections.payment-link-page.reference' }, { language })}
              </dt>
              <dd>{mandate.paymentInfo.firstPayment.reference}</dd>
            </dl>
          ) : null}

          {mandate.paymentInfo.firstPayment?.collectionDate ? (
            <dl className={styles.detail} data-test-sdd-payment-page-preview-collection-date>
              <dt>
                {formatMessage({ id: 'sdd-collections.payment-link-page.due-date' }, { language })}
              </dt>
              <dd>
                {formatDate(mandate.paymentInfo.firstPayment.collectionDate, {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
              </dd>
            </dl>
          ) : null}
        </div>

        <p className="title-3 mb-16" data-test-sdd-payment-page-preview-details-title>
          {formatMessage({ id: 'sdd-collections.payment-link-page.details' }, { language })}
        </p>

        <form className="body-1">
          <div className={cx(styles['dummy-field'], 'mb-16')}>
            <div className="mb-4" data-test-sdd-payment-page-preview-name-label>
              {formatMessage({ id: 'sdd-collections.payment-link-page.name' }, { language })}
            </div>
            <div className={cx(styles.input, styles['input--readonly'])}>
              <div className={styles.value} data-test-sdd-payment-page-preview-name-value>
                {client.kind === 'company' ? client.name : `${client.firstName} ${client.lastName}`}
              </div>
            </div>
          </div>

          <div className={cx(styles['dummy-field'], 'mb-16')}>
            <div className="mb-4" data-test-sdd-payment-page-preview-email-label>
              {formatMessage({ id: 'sdd-collections.payment-link-page.email' }, { language })}
            </div>
            <div className={cx(styles.input, styles['input--readonly'])}>
              <div className={styles.value} data-test-sdd-payment-page-preview-email-value>
                {client.email}
              </div>
            </div>
          </div>

          <div className={cx(styles['dummy-field'], 'mb-16')}>
            <div className="mb-4" data-test-sdd-payment-page-preview-address-label>
              {formatMessage({ id: 'sdd-collections.payment-link-page.address' }, { language })}
            </div>
            <div className={styles.input}>
              <div
                className={styles.placeholder}
                data-test-sdd-payment-page-preview-address-placeholder
              >
                {formatMessage(
                  { id: 'sdd-collections.payment-link-page.address-placeholder' },
                  { language }
                )}
              </div>
            </div>
          </div>

          <div className={cx(styles.columns, 'mb-16')}>
            <div className={cx(styles['dummy-field'], 'mr-16')}>
              <div className="mb-4" data-test-sdd-payment-page-preview-zipcode-label>
                {formatMessage({ id: 'sdd-collections.payment-link-page.zipcode' }, { language })}
              </div>
              <div className={styles.input}>
                <div
                  className={styles.placeholder}
                  data-test-sdd-payment-page-preview-zipcode-placeholder
                >
                  {formatMessage(
                    { id: 'sdd-collections.payment-link-page.zipcode-placeholder' },
                    { language }
                  )}
                </div>
              </div>
            </div>

            <div className={styles['dummy-field']}>
              <div className="mb-4" data-test-sdd-payment-page-preview-city-label>
                {formatMessage({ id: 'sdd-collections.payment-link-page.city' }, { language })}
              </div>
              <div className={styles.input}>
                <div
                  className={styles.placeholder}
                  data-test-sdd-payment-page-preview-city-placeholder
                >
                  {formatMessage(
                    { id: 'sdd-collections.payment-link-page.city-placeholder' },
                    { language }
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className={cx(styles['dummy-field'], 'mb-16')}>
            <div className="mb-4" data-test-sdd-payment-page-preview-country-label>
              {formatMessage({ id: 'sdd-collections.payment-link-page.country' }, { language })}
            </div>
            <div className={cx(styles.input, styles['input--select'])}>
              <div
                className={styles.placeholder}
                data-test-sdd-payment-page-preview-country-placeholder
              >
                {formatMessage(
                  { id: 'sdd-collections.payment-link-page.country-placeholder' },
                  { language }
                )}
              </div>
              <IconChevronBottomOutlined />
            </div>
          </div>

          <div className={styles['dummy-field']}>
            <div className="mb-4" data-test-sdd-payment-page-preview-iban-label>
              {formatMessage({ id: 'labels.iban' })}
            </div>
            <div className={styles.input}>
              <div
                className={styles.placeholder}
                data-test-sdd-payment-page-preview-iban-placeholder
              >
                {formatMessage({ id: 'placeholders.iban' }, { legalCountry: language })}
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className={styles.mandate}>
        <p
          className={cx(styles['mandate-title'], 'title-4 mb-16')}
          data-test-sdd-payment-page-preview-mandate-title
        >
          <span>
            {formatMessage({ id: 'sdd-collections.payment-link-page.mandate.title' }, { language })}
          </span>
          <span className={styles['icon-chevron']}>
            <IconChevronBottomOutlined />
          </span>
        </p>

        <div className={cx(styles['mandate-details'], 'mb-32')}>
          <div className={styles.checkbox} />
          <p className="body-2" data-test-sdd-payment-page-preview-mandate-description>
            {formatMessage(
              { id: 'sdd-collections.payment-link-page.mandate.description-part-1' },
              { language, creditorName: organization.name }
            )}
            &nbsp;
            {formatMessage(
              { id: 'sdd-collections.payment-link-page.mandate.description-part-2' },
              { language }
            )}
            &nbsp;
            {formatMessage(
              { id: 'sdd-collections.payment-link-page.mandate.description-part-3' },
              { language }
            )}
          </p>
        </div>

        <div className={cx(styles.cta, 'mb-32')} data-test-sdd-payment-page-preview-cta>
          {formatMessage({ id: 'sdd-collections.payment-link-page.authorize-cta' }, { language })}
        </div>

        <div
          className={cx(styles['secure-note'], 'caption mb-32')}
          data-test-sdd-payment-page-preview-secure-note
        >
          <IconLockOutlined aria-hidden="true" className={cx(styles['icon-lock'], 'mr-8')} />
          {formatMessage({ id: 'sdd-collections.payment-link-page.secure-service' }, { language })}
        </div>
      </div>
      <EmailPreviewFooter
        disclaimer={footerDisclaimer}
        isCurrentThemeDark={isCurrentThemeDark}
        isPaymentLink="true"
        language={language}
      />
    </div>
  );
}
