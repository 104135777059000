import { useContext, type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { IconPenOutlined } from '@repo/monochrome-icons';
import { OverlayTriggerStateContext } from 'react-aria-components';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { Popover, PopoverButton, PopoverSection } from 'qonto/react/components/table-v2/popover';
import type { Vat } from 'qonto/react/graphql';
import { VAT_STATUS } from 'qonto/constants/vat';
import { VatDetails } from 'qonto/react/components/transactions/table/side-panel/details';
import { routeContextManager } from 'qonto/react/contexts/route-context';
import { VAT_POPOVER_CLICK_EVENT_NAME } from 'qonto/react/constants';
import styles from './styles.strict-module.css';

interface VATCellProps {
  vat: Vat;
  transactionId: string;
}

export function VATCellPopover({ vat, transactionId }: VATCellProps): ReactNode {
  const segment = useEmberService('segment');
  const store = useEmberService('store');
  const { openModal } = routeContextManager.useRouteContext();
  const triggerState = useContext(OverlayTriggerStateContext);

  const { formatMessage } = useIntl();

  const stringToNumber = (value: string | null | undefined): number | null =>
    value ? Number(value) : null;

  const vatItems = vat.items.filter(Boolean).map(item => ({
    ...item,
    amount: { ...item.amount, value: stringToNumber(item.amount?.value) },
    amountExcludingVat: {
      ...item.amountExcludingVat,
      value: stringToNumber(item.amountExcludingVat?.value),
    },
    rate: item.rate ?? 0,
  }));

  const handleOpen = (): void => {
    segment.track(VAT_POPOVER_CLICK_EVENT_NAME);

    triggerState?.close();

    openModal('attachments/attachment-viewer-modal-wrapper', {
      isFullScreenModal: true,
      hasSidebar: true,
      title: formatMessage({
        id: 'transactions.sidebar.bookkeeping.bookkeeping-details',
      }),
      transactionId,
      onClose: async () => {
        const transaction = await store.findRecord('transaction', transactionId, {
          include: 'vat',
        });

        transaction.updateTanstackQueryStore();
      },
    });
  };

  return (
    <Popover data-testid="vat-cell-popover" placement="bottom end" width={324}>
      <PopoverSection className={styles['section-vat']}>
        <VatDetails status={vat.status || VAT_STATUS.undetected} vatItems={vatItems} />
      </PopoverSection>
      <PopoverSection className={styles['section-bottom']}>
        <PopoverButton
          data-testid="edit-vat"
          onPress={handleOpen}
          slots={{
            icon: <IconPenOutlined data-testid="default-icon" />,
            text: formatMessage({
              id: 'transactions.modular_table.vat.tooltip.edit-vat.cta',
            }),
          }}
        />
      </PopoverSection>
    </Popover>
  );
}
