import React, { type ReactNode } from 'react';
import type { UseQueryResult } from '@tanstack/react-query';
import type {
  CashflowCategories,
  CashflowCategorySide,
} from 'qonto/react/models/cash-flow-category';
import type { QueryResult } from 'qonto/react/api/utils/query-result';
import { useCategoriesManagementRouting } from '../hooks/use-categories-management-routing';
import { CategoriesListEmpty } from './categories-list-empty';
import { CategoriesListError } from './categories-list-error';
import { SortableCategoriesList } from './sortable-categories-list';
import { CategoriesListLoading } from './categories-list-loading';

export interface CategoriesListProps {
  type: CashflowCategorySide;
  handleRetry?: () => Promise<UseQueryResult>;
  query?: QueryResult<CashflowCategories>;
}

export function CategoriesList({ type, query }: CategoriesListProps): ReactNode {
  const { isCreatingNewCategory } = useCategoriesManagementRouting();

  if (!query) {
    return null;
  }

  const { data: categories, isPending, isError, refetch } = query;
  const isListEmpty = !categories?.[type].length;

  const attemptRetry = (): Promise<UseQueryResult> =>
    refetch({ throwOnError: true, cancelRefetch: true });

  if (isPending) {
    return <CategoriesListLoading type={type} />;
  }

  if (isError) {
    return <CategoriesListError handleRetry={attemptRetry} type={type} />;
  }

  if (isListEmpty && !isCreatingNewCategory) {
    return <CategoriesListEmpty type={type} />;
  }

  return <SortableCategoriesList categoriesList={categories?.[type] ?? []} type={type} />;
}
