import type { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { IconDownloadOutlined, IconFileAttachmentOutlined } from '@repo/monochrome-icons';
import { Button } from 'react-aria-components';
import { downloadHook } from 'qonto/react/hooks/use-download-with-headers';
import styles from './download-button.strict-module.css';

export function DownloadTemplateButton(): ReactNode {
  const { locale } = useIntl();
  const { downloadFile } = downloadHook.useDownloadWithHeaders({
    href: `v2/cash-flow/timeseries/forecasts/template/download?lang=${locale}`,
  });

  return (
    <Button
      className={styles.downloadButton}
      data-testid="download-template-cta"
      onPress={downloadFile}
      type="button"
    >
      <span className={styles.buttonContent}>
        <span className={styles.fileIconWrapper}>
          <IconFileAttachmentOutlined />
        </span>
        <span className={styles.description}>
          <p className="body-1" data-testid="title">
            <FormattedMessage id="cash-flow.first-time-experience.sidepanel.import.step-1.button" />
          </p>
          <p className={styles.fileSize} data-testid="file-size">
            <FormattedMessage id="cash-flow.first-time-experience.sidepanel.import.step-1.file-size" />
          </p>
        </span>
      </span>
      <IconDownloadOutlined />
    </Button>
  );
}
