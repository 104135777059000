import cx from 'clsx';
import type { ReactNode } from 'react';
import { FormattedMessage } from '@repo/domain-kit/shared';
import {
  IconChatOutlined,
  IconExternalOutlined,
  IconHeadsetOutlined,
} from '@repo/monochrome-icons';
import { ExternalLink } from '@repo/design-system-kit';
import styles from './insurance-support-contact.strict-module.css';

interface InsuranceSupportContactProps {
  className?: string;
}

export function InsuranceSupportContact({
  className,
  ...props
}: InsuranceSupportContactProps): ReactNode {
  return (
    <div className={cx(styles.supportCard, 'body-1', className)} {...props}>
      <h2 className={cx(styles.title, 'title-4', 'mb-16')} data-test-insurance-support-title>
        <FormattedMessage id="insurance.contact.title" />
      </h2>

      <p className="mb-16" data-test-insurance-support-description>
        <FormattedMessage id="insurance.contact.description" />
      </p>

      <div className={cx(styles.container, 'mb-24')}>
        <IconChatOutlined
          aria-hidden="true"
          className="mr-8"
          data-test-support-chat-or-form-icon="icon_chat_outlined"
          height="32"
          width="32"
        />

        <span className={styles.container}>
          <p data-test-support-chat-or-form-cta>
            <FormattedMessage id="insurance.contact.by-chat-or-form.prefix" />
            <ExternalLink
              className="mr-8"
              data-test-support-chat-or-form-link
              href="https://qover-qonto.zendesk.com/hc/requests/new"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FormattedMessage id="insurance.contact.by-chat-or-form.text" />
            </ExternalLink>
          </p>
          <IconExternalOutlined
            aria-hidden="true"
            className={styles.icon}
            data-test-support-chat-or-form-icon="icon_external"
          />
        </span>
      </div>

      <div className={styles.container}>
        <IconHeadsetOutlined
          aria-hidden="true"
          className="mr-8"
          data-test-support-by-phone-icon="icon_headset_outlined"
          height="32"
          width="32"
        />

        <div>
          <p data-test-support-by-phone-number>
            <FormattedMessage id="insurance.contact.by-phone" />
          </p>
          <p data-test-support-by-phone-hours>
            <FormattedMessage id="insurance.contact.by-phone-hours" />
          </p>
        </div>
      </div>
    </div>
  );
}
