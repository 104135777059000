import { type ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useSegment } from '@repo/poly-hooks/segment';
import { Link } from '@qonto/react-migration-toolkit/react/components';
import { IconEyeOutlined } from '@repo/monochrome-icons';
import cx from 'clsx';
import { dateToken, Disclaimer } from '@repo/design-system-kit';
import { DATE_FORMAT_TOKENS, type DateTokenArgs } from '@qonto/ui-kit/utils/date-token';
import { F24ActivityTag, F24SidebarHeader, StatusBox } from 'qonto/react/f24/components/sidebar';
import { SidebarInfoBox, SidebarInfoBoxRow } from 'qonto/react/components/sidebar-info-box';
import { SidebarInfoList, SidebarInfoListRow } from 'qonto/react/components/sidebar-info-list';
import { formatMoney } from 'qonto/react/shared/utils/format-money';
import { F24_STATUS } from 'qonto/constants/f24';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import type { F24Order } from 'qonto/react/f24/types';
import styles from './sidebar-header.strict-module.css';

interface F24SidebarContainerHeaderProps {
  order: F24Order;
  closeSidebar: () => void;
}

export function F24SidebarContainerHeader({
  order,
  closeSidebar,
}: F24SidebarContainerHeaderProps): ReactNode {
  const segment = useSegment();
  const abilities = useEmberService('abilities');
  const { organization } = useOrganizationManager();
  const { formatMessage, locale } = useIntl();

  const titleTranslationKey = (() => {
    const statusToTranslationKey = {
      [F24_STATUS.SCHEDULED]: formatMessage({ id: 'f24.archive.sidepanel.header.scheduled' }),
      [F24_STATUS.COMPLETED]: formatMessage({ id: 'f24.archive.sidepanel.header.completed' }),
      [F24_STATUS.DECLINED]: formatMessage({ id: 'f24.archive.sidepanel.header.declined' }),
      [F24_STATUS.CANCELED]: formatMessage({ id: 'f24.archive.sidepanel.header.canceled' }),
    } as Record<F24Order['status'], string>;
    return statusToTranslationKey[order.status];
  })();

  const headerDateToken = (() => {
    const statusToDate = {
      [F24_STATUS.SCHEDULED]: { date: order.createdAt, token: DATE_FORMAT_TOKENS.DATE_TIME_L },
      [F24_STATUS.COMPLETED]: {
        date: order.expirationDate,
        token: DATE_FORMAT_TOKENS.DATE_YEAR_L,
      },
      [F24_STATUS.DECLINED]: {
        date: order.expirationDate,
        token: DATE_FORMAT_TOKENS.DATE_YEAR_L,
      },
      [F24_STATUS.CANCELED]: { date: order.canceledAt, token: DATE_FORMAT_TOKENS.DATE_TIME_L },
    } as Record<F24Order['status'], { date: string; token: DateTokenArgs['token'] }>;

    return statusToDate[order.status];
  })();

  const isDeclinedForInsufficientFunds =
    order.status === F24_STATUS.DECLINED && order.declinedReason === 'insufficient_funds';

  const membership = {
    avatarThumb: {
      fileUrl: order.membership.get('avatarThumb.fileUrl'),
    },
    id: order.membership.id,
    fullName: order.membership.get('fullName'),
    team: {
      name: order.membership.get('team.name'),
    },
  };

  return (
    <>
      <F24SidebarHeader
        isCompletedPayment={order.isCompletedPayment}
        isTerminatedPayment={order.isTerminatedPayment}
        onClose={closeSidebar}
        subtitle={dateToken({
          locale,
          date: headerDateToken.date,
          token: headerDateToken.token,
        })}
        title={titleTranslationKey}
      />
      <StatusBox icon="tax-l" status={order.status} statusIcon="status_check-xs">
        <div
          className={cx('title-3 currency mb-8', order.isTerminatedPayment && styles.ghost)}
          data-test-f24-amount
        >
          {formatMoney(Number(order.documentAmount), {
            locale,
            signus: '-',
            currency: order.currency,
          })}
        </div>
        {abilities.cannot('assign category') && (
          <F24ActivityTag data-test-f24-transaction-sidebar-details-activity-tag="" />
        )}
        <SidebarInfoBox data-test-f24-initiator="">
          <SidebarInfoBoxRow membership={membership} />
        </SidebarInfoBox>
        <SidebarInfoList data-test-f24-account-name="">
          <SidebarInfoListRow
            content={order.bankAccount.get('name')}
            label={formatMessage({ id: 'labels.account_from' })}
          />
        </SidebarInfoList>
        {order.isNotCanceled ? (
          <Link
            className={cx(styles.view, 'body-link')}
            data-test-f24-sidebar-open-pop
            onClick={() => {
              segment.track('f24_details_submitted-form-visualized');
            }}
            to={`/organizations/${organization.slug}/f24/${order.id}`}
          >
            <IconEyeOutlined className="mr-8" />
            <FormattedMessage id="f24.archive.sidepanel.open-f24" />
          </Link>
        ) : null}
      </StatusBox>
      {isDeclinedForInsufficientFunds ? (
        <Disclaimer.Block
          className={styles.disclaimer}
          data-test-f24-declined-disclaimer=""
          level="error"
        >
          <FormattedMessage id="f24.archive.sidepanel.declined-disclaimer" />
        </Disclaimer.Block>
      ) : null}
    </>
  );
}
