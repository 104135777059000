import type { HTMLAttributes, ReactNode } from 'react';
import { useId } from 'react';
import { Checkbox } from '@repo/design-system-kit';
import { F24FormElementsLabel } from 'qonto/react/f24/components/form-elements';
import styles from './styles.strict-module.css';

interface F24FormFieldsCheckboxFieldProps extends HTMLAttributes<HTMLDivElement> {
  ariaLabel: string;
  hasError: boolean;
  isChecked: boolean;
  isDisabled: boolean;
  label: string;
  onUpdate: (checked: boolean) => void;
}

export function F24FormFieldsCheckboxField({
  label,
  isChecked,
  isDisabled,
  hasError,
  ariaLabel,
  onUpdate,
  ...attributes
}: F24FormFieldsCheckboxFieldProps): ReactNode {
  const checkboxId = useId();

  return (
    <div className={styles.checkbox} {...attributes}>
      {label ? <F24FormElementsLabel className="mb-4" htmlFor={checkboxId} label={label} /> : null}
      <Checkbox
        aria-label={ariaLabel}
        className={styles.checkbox}
        data-test-f24-checkbox-field-cb=""
        id={checkboxId}
        isDisabled={isDisabled}
        isInvalid={hasError}
        isSelected={isChecked}
        onChange={onUpdate}
      />
    </div>
  );
}
