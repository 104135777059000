import { useRef, useState, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Button, MenuTrigger, Popover } from 'react-aria-components';
import { IconChevronRightOutlined } from '@repo/monochrome-icons';
import dayjs from 'dayjs';
import { FilterButton } from '@repo/design-system-kit';
import cx from 'clsx';
import { PERIOD_KEYS } from 'qonto/constants/bookkeeping';
import { CustomPeriod } from './custom-period/custom-period';
import styles from './period-selector.strict-module.css';

export interface Period {
  startDate?: string | null;
  endDate?: string | null;
  label: string;
  key: string;
}

interface PeriodSelectorProps {
  onChangePeriod: (period: Period) => void;
  selectorLabel: string;
  selectedPeriod: Period | null;
  cancelPeriodSelection: () => void;
  maxDate: string;
}

export function PeriodSelector({
  onChangePeriod,
  selectorLabel,
  selectedPeriod,
  cancelPeriodSelection,
  maxDate,
}: PeriodSelectorProps): React.ReactNode {
  const [isCustomPeriod, setIsCustomPeriod] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { formatMessage } = useIntl();

  const triggerRef = useRef(null);

  const TEST_PORTAL_CONTAINER = document.getElementById('ember-testing') ?? undefined;

  const periodOptions = useMemo(
    () => [
      {
        key: PERIOD_KEYS.THIS_MONTH,
        label: formatMessage({ id: 'bookkeeping.transactions.filters.option.period.month' }),
        startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
        endDate: dayjs().endOf('month').format('YYYY-MM-DD'),
      },
      {
        key: PERIOD_KEYS.LAST_MONTH,
        label: formatMessage({ id: 'bookkeeping.transactions.filters.option.period.last-month' }),
        startDate: dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
        endDate: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
      },
      {
        key: PERIOD_KEYS.CUSTOM_PERIOD,
        label: formatMessage({ id: 'bookkeeping.transactions.filters.option.period.custom.label' }),
        startDate: null,
        endDate: null,
      },
    ],
    [formatMessage]
  );

  const isCustomOption = useCallback(
    (key: string | undefined): boolean => key === PERIOD_KEYS.CUSTOM_PERIOD,
    []
  );

  const isCurrentOption = useCallback(
    (key: string): boolean => key === selectedPeriod?.key,
    [selectedPeriod?.key]
  );

  const customPeriodItem = useCallback(
    (): Period | null =>
      isCustomOption(selectedPeriod?.key)
        ? selectedPeriod
        : (periodOptions.find(option => isCustomOption(option.key)) ?? null),
    [selectedPeriod, periodOptions, isCustomOption]
  );

  const handleSelect = (item: Period): void => {
    if (isCustomOption(item.key)) {
      setIsCustomPeriod(true);
    } else {
      onChangePeriod(item);
      setIsOpen(false);
    }
  };

  const handleCancel = useCallback(
    (e: React.MouseEvent): void => {
      e.stopPropagation();
      cancelPeriodSelection();
    },
    [cancelPeriodSelection]
  );

  const handleBack = useCallback((): void => {
    setIsCustomPeriod(false);
  }, []);

  const updateCustomPeriod = ({
    startDate,
    endDate,
  }: {
    startDate: string;
    endDate: string;
  }): void => {
    const item = { ...customPeriodItem(), startDate, endDate };
    onChangePeriod(item as Period);
    setIsOpen(false);
  };

  const handleOpenChange = (open: boolean): void => {
    setIsOpen(open);
    setIsCustomPeriod(false);
  };

  return (
    <div className={styles['period-wrapper']}>
      <MenuTrigger isOpen={isOpen} onOpenChange={handleOpenChange}>
        <FilterButton
          data-test-selector-trigger=""
          handleCancel={handleCancel}
          isActive={Boolean(selectedPeriod)}
          isOpen={isOpen}
          ref={triggerRef}
        >
          {selectedPeriod ? selectedPeriod.label : selectorLabel}
        </FilterButton>
        <Popover
          UNSTABLE_portalContainer={TEST_PORTAL_CONTAINER}
          className={styles.dropdown}
          // @ts-expect-error the Popover id is passed as portalContainerId to the date-picker
          id="custom-period-selector-dropdown"
          placement="bottom right"
        >
          {isCustomPeriod ? (
            <div>
              <CustomPeriod
                endDate={customPeriodItem()?.endDate}
                handleBack={handleBack}
                maxDate={maxDate}
                onCancel={() => {
                  setIsOpen(false);
                }}
                onUpdate={updateCustomPeriod}
                startDate={customPeriodItem()?.startDate}
              />
            </div>
          ) : (
            <div className={styles['invoices-entry-container']}>
              {periodOptions.map((item, index) => (
                <Button
                  className={cx(
                    styles['dropdown-button'],
                    'overlay',
                    isCurrentOption(item.key) && styles['dropdown-button-current']
                  )}
                  data-option-index={index}
                  id={item.key}
                  key={item.key}
                  onPress={() => {
                    handleSelect(item as Period);
                  }}
                >
                  {item.label}
                  {isCustomOption(item.key) && <IconChevronRightOutlined />}
                </Button>
              ))}
            </div>
          )}
        </Popover>
      </MenuTrigger>
    </div>
  );
}
