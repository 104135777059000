/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import cx from 'clsx';
import { type CountryCode, Flag, Spinner } from '@repo/design-system-kit';
import { type ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { formatAccountInformation } from 'qonto/utils/international-out/format';
import type { Beneficiary } from 'qonto/services/international-out/types';
import styles from './information.strict-module.css';

interface InformationProps {
  /**
   * The beneficiary to display information for.
   */
  beneficiary: Omit<Beneficiary, 'branchIdentifier'>;
  /**
   * Whether the component is in a loading state. If true, the currency is replaced by a loading spinner.
   */
  isLoading?: boolean;
  /**
   * Whether the name and banking details should be truncated if they overflow.
   */
  withEllipsis?: boolean;
}

export function Information({
  beneficiary,
  isLoading = false,
  withEllipsis = false,
}: InformationProps): ReactNode {
  const { formatDisplayName, formatMessage, messages } = useIntl();

  const formattedAccountInformation = formatAccountInformation({
    accountIdentifier: beneficiary.accountIdentifier,
    bankIdentifier: beneficiary.bankIdentifier,
  });

  const paymentType = useMemo(() => {
    const translationKey = `international-out.dynamic-form.payment-type.${beneficiary.paymentType}`;

    if (messages[translationKey]) {
      return formatMessage({ id: translationKey });
    }

    return null;
  }, [beneficiary.paymentType, formatMessage, messages]);

  return (
    <div className={styles.wrapper}>
      <div>
        <div
          className={cx(styles.name, withEllipsis && styles.ellipsis, 'body-1 mr-8')}
          data-testid="name"
        >
          <span>{beneficiary.name}</span>
        </div>

        <div className={cx(styles.account, withEllipsis && styles.ellipsis, 'body-2 mr-16')}>
          <span>
            {paymentType ? (
              <span className={styles['payment-type']} data-testid="payment-type">
                {paymentType}:{' '}
              </span>
            ) : null}
            <span className={styles['account-number']} data-testid="account-numbers">
              {formattedAccountInformation}
            </span>
          </span>
        </div>
      </div>

      <div className={styles.destination}>
        <Flag
          aria-label={formatDisplayName(beneficiary.country, { type: 'region' })}
          code={beneficiary.country as CountryCode}
          data-testid="country"
          size="small"
        />
        <div className={styles['currency-wrapper']}>
          {isLoading ? (
            <Spinner color="primary-a" data-testid="spinner" size="small" />
          ) : (
            <span className={styles.currency} data-testid="currency">
              {beneficiary.currency}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
