import { type FormEvent, type ReactElement } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import cx from 'clsx';
import { Button, DateField, Form, TextField } from '@repo/design-system-kit';
import type { DateValue } from 'react-aria';
import { ChecksImage } from 'qonto/react/components/checks/image';
import styles from './emitter.strict-module.css';

interface FlowCheckEmitterProps {
  emittedDate?: DateValue;
  emittedDateErrorMessage?: string;
  emitterName: string;
  emitterNameErrorMessage?: string;
  onEmittedDateChange: (value: DateValue | null, isInvalid?: boolean) => void;
  onEmittedDateFocusChange: (isFocused: boolean) => void;
  onEmitterNameChange: (value: string) => void;
  onEmitterNameFocusChange: (isFocused: boolean) => void;
  onEmitterNameKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  shouldHighlightEmitterDate: boolean;
  shouldHighlightEmitterName: boolean;
}

export function FlowCheckEmitter({
  emittedDate,
  emittedDateErrorMessage,
  emitterName,
  emitterNameErrorMessage,
  onEmitterNameChange,
  onEmitterNameFocusChange,
  onEmitterNameKeyDown,
  onEmittedDateChange,
  onEmittedDateFocusChange,
  onSubmit,
  shouldHighlightEmitterDate,
  shouldHighlightEmitterName,
}: FlowCheckEmitterProps): ReactElement {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.checks} data-test-check-emitter>
      <div className={styles.content} data-test-check-emitter-content>
        <Form className={styles.form} data-test-check-form onSubmit={onSubmit}>
          <h2 className={cx('title-2 mb-8', styles.title)} data-test-check-form-title>
            <FormattedMessage id="checks.emitter.title" />
          </h2>

          <p
            className={cx('body-2 mb-24', styles.description)}
            dangerouslySetInnerHTML={{
              __html: formatMessage({ id: 'checks.emitter.description' }),
            }}
            data-test-check-form-description
          />

          <div className={cx('mb-40', styles.wrapper)}>
            <TextField
              className="mb-16"
              data-test-check-form-emitter-name
              errorMessage={emitterNameErrorMessage}
              isInvalid={Boolean(emitterNameErrorMessage)}
              label={formatMessage({ id: 'labels.emitter_name' })}
              onChange={onEmitterNameChange}
              onFocusChange={onEmitterNameFocusChange}
              onKeyDown={onEmitterNameKeyDown}
              placeholder={formatMessage({ id: 'placeholders.john_doe' })}
              value={emitterName}
            />

            <DateField
              data-test-check-form-emitted-date
              errorMessage={emittedDateErrorMessage}
              label={formatMessage({ id: 'labels.emitted_at' })}
              onChange={onEmittedDateChange}
              onFocusChange={onEmittedDateFocusChange}
              value={emittedDate}
            />
          </div>

          <Button className="btn btn--primary" data-test-check-form-cta type="submit">
            <FormattedMessage id="btn.continue" />
          </Button>
        </Form>
      </div>

      <aside className={styles.aside} data-test-check-emitter-aside>
        <ChecksImage
          highlightAmount={shouldHighlightEmitterName}
          highlightCheckId={shouldHighlightEmitterDate}
        />
      </aside>
    </div>
  );
}
