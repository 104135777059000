import type {
  ActivityTag,
  Side,
  Status,
  StatusHistory as GqlStatusHistory,
  StatusHistoryStatus as GqlStatusHistoryStatus,
  OperationMethod,
} from 'qonto/react/graphql';

type EnumValues<T> = T[keyof T];
type EnumValuesToUnionType<T extends object> = EnumValues<T>;

export type TransactionStatus = EnumValuesToUnionType<typeof Status>;
export type StatusHistoryStatus = EnumValuesToUnionType<typeof GqlStatusHistoryStatus>;
export type StatusHistory = GqlStatusHistory;

export type TransactionCategory = EnumValuesToUnionType<typeof ActivityTag>;
export type TransactionSide = EnumValuesToUnionType<typeof Side>;
export type TransactionOperationMethod = EnumValuesToUnionType<typeof OperationMethod>;

export const MAX_ATTACHMENT_COUNT = 20;
export const UPLOAD_DISCLAIMER_TIMEOUT = 60 * 60 * 1000;

export const CHARGEBACK_CLAIM_CARD_POSSESSION_VALUES: {
  YES: 'yes';
  NO: 'no';
} = {
  YES: 'yes',
  NO: 'no',
} as const;

export const MODULAR_TABLES_FEEDBACK_FORM_URL = {
  en: 'https://qonto.typeform.com/to/dfGcGiT0',
  fr: 'https://qonto.typeform.com/to/LRjxFpcc',
  de: 'https://qonto.typeform.com/to/gvGdsY4d',
  it: 'https://qonto.typeform.com/to/CRRSPKdC',
  es: 'https://qonto.typeform.com/to/S7Ne53ZF',
  pt: 'https://qonto.typeform.com/to/YeMRWTyt',
  nl: 'https://qonto.typeform.com/to/dfGcGiT0',
};

export interface ModularTableColumn {
  id: string;
  width: number;
  position: number;
}

export const CLAIM_TYPES = {
  atm: 'atm',
  commercial: 'commercial',
  fraud: 'fraud',
};

export const SORT_QUERY_PARAM = {
  transaction: 'counterparty_name',
  amount: 'amount',
  operationDate: 'emitted_at',
  paymentMethod: 'operation_method',
};

export const SORT_DIRECTION = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
} as const;

export const DEFAULT_SORT_PARAM = 'emitted_at:desc';

export const SORTABLE_COLUMNS = ['transaction', 'amount', 'operationDate', 'paymentMethod'];
