import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { useEventCallback } from 'usehooks-ts';
import { IconBacklinkOutlined } from '@repo/monochrome-icons';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { Popover, PopoverSection } from 'qonto/react/components/table-v2/popover';
import type { OperationMethod, Card } from 'qonto/react/graphql';
import tinyMaskPan from 'qonto/utils/tiny-mask-pan';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './styles.strict-module.css';
import { FilterByButton } from './filter-by-button';
import { PopoverItem } from './popover-item';

function CardInfo({ card }: { card: Card }): ReactNode {
  return (
    <div className={styles.cardInfo} data-testid="card-info">
      <span className={cx(styles.title, 'body-2')} data-testid="title">
        {card.holder?.fullName}
      </span>
      <div className={cx(styles.subtitle, 'caption')} data-testid="subtitle">
        <span className={styles.nickname} data-testid="nickname">
          {card.nickname || card.cardLevel}
        </span>
        <span className={styles.last4} data-testid="last4">
          {tinyMaskPan(card.last4)}
        </span>
      </div>
    </div>
  );
}

function CardIcon({ card }: { card: Card }): ReactNode {
  const iconName = `card-${card.cardDesign}`;
  const iconUri = `/illustrations/cards/${iconName}.svg`;

  return (
    <StaticThemedAsset
      alt=""
      assetPath={iconUri}
      className={styles.cardIcon}
      data-testid={iconName}
      loading="lazy"
    />
  );
}

function CardDetailsButton({ card }: { card: Card }): ReactNode {
  const { formatMessage } = useIntl();
  /**
   * This is a workaround to an issue where navigating using
   * useNavigate hook results in the beforeModel hook of the destination
   * route not being called.
   */
  // eslint-disable-next-line @qonto/no-use-ember-service-router -- see above
  const emberRouter = useEmberService('router');

  const navigateToCardDetails = useEventCallback((): void => {
    void emberRouter.transitionTo('cards.index', {
      queryParams: {
        highlight: card.id,
      },
    });
  });

  return (
    <PopoverItem
      data-testid="go-to-card-details"
      icon={<IconBacklinkOutlined />}
      onPress={navigateToCardDetails}
      text={formatMessage({
        id: 'transactions.modular_table.payment_method.card_details.link-text',
      })}
    />
  );
}

export function CardPaymentMethodCellPopover({
  card,
  paymentMethod,
}: {
  card: Card;
  paymentMethod: OperationMethod;
}): ReactNode {
  const { formatMessage } = useIntl();

  return (
    <Popover data-testid="card-payment-method-cell-popover">
      {card.holder ? (
        <PopoverSection className={styles.section}>
          <div className={styles.cardInfoSection} data-testid="container">
            <CardIcon card={card} />
            <CardInfo card={card} />
          </div>
        </PopoverSection>
      ) : null}
      <PopoverSection className={styles.section}>
        {card.holder ? (
          <FilterByButton
            data-testid="filter-by-cardholder"
            filterQP={{ initiator: card.holder.id }}
            text={formatMessage({
              id: 'transactions.modular_table.payment_method.filter.cardholder',
            })}
          />
        ) : null}
        <FilterByButton
          data-testid="filter-by-method"
          filterQP={{ 'payment-method': paymentMethod.toLowerCase() }}
          text={formatMessage({
            id: 'transactions.modular_table.payment_method.filter.method',
          })}
        />
      </PopoverSection>
      <PopoverSection className={styles.section}>
        <CardDetailsButton card={card} />
      </PopoverSection>
    </Popover>
  );
}
