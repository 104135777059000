/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import type { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from '@repo/design-system-kit';
import { IconWarningSignFilled, IconErrorFilled } from '@repo/monochrome-icons';
import { dateToken, DATE_FORMAT_TOKENS } from '@qonto/ui-kit/utils/date-token';
import type { FinancingModel } from 'qonto/react/financing/api';
import styles from '../styles.strict-module.css';
import { InstallmentAmount } from './installment-amount';

export interface UseWarningDataReturn {
  tooltipMessage: string;
  icon: ReactElement;
  text: string;
}

export function NextInstallmentAmount({ financing }: { financing: FinancingModel }): ReactElement {
  const warning = useWarningData(financing);

  if (financing.isProcessing || !financing.nextInstallmentTotalAmount) {
    return <>-</>;
  }

  if (warning) {
    return (
      <Tooltip
        label={warning.tooltipMessage}
        needsButton
        placement="top right"
        tooltipTestId="warning-tooltip"
      >
        <div>
          <InstallmentAmount financing={financing} />
          <div className={styles.disclaimer} data-test-disclaimer>
            <span
              className={styles[financing.repaymentStatus === 'at_risk' ? 'warning' : 'error']}
              data-test-icon-container
            >
              {warning.icon}
            </span>
            <span className="caption">{warning.text}</span>
          </div>
        </div>
      </Tooltip>
    );
  }

  return <InstallmentAmount financing={financing} />;
}

function useWarningData(financing: FinancingModel): UseWarningDataReturn | null {
  const intl = useIntl();

  const formattedDate = dateToken({
    date: String(financing.nextInstallmentAt),
    locale: intl.locale,
    token: DATE_FORMAT_TOKENS.DATE_S,
  });

  switch (financing.repaymentStatus) {
    case 'at_risk':
      return {
        tooltipMessage: intl.formatMessage(
          {
            id: 'financing.pay-later.cockpit.table.row.installment-amount.at-risk.tooltip',
          },
          { account_name: financing.bankAccountName, date: formattedDate }
        ),
        icon: (
          <IconWarningSignFilled
            className={styles.disclaimerIcon}
            data-test-disclaimer-icon="icon_warning_sign_filled"
          />
        ),
        text: intl.formatMessage({
          id: 'financing.pay-later.cockpit.table.row.installment-amount.at-risk.disclaimer',
        }),
      };
    case 'late':
      return {
        tooltipMessage: intl.formatMessage(
          {
            id: 'financing.pay-later.cockpit.table.row.installment-amount.overdue.tooltip',
          },
          { account_name: financing.bankAccountName }
        ),
        icon: (
          <IconErrorFilled
            className={styles.disclaimerIcon}
            data-test-disclaimer-icon="icon_error_filled"
          />
        ),
        text: intl.formatMessage({
          id: 'financing.pay-later.cockpit.table.row.installment-amount.overdue.disclaimer',
        }),
      };
    default:
      return null;
  }
}
