import { createContext, useCallback, useContext, useReducer, type ReactNode } from 'react';
import { type ScheduledTransactionPreview } from '@repo/domain-kit/cashflow';

interface UpcomingTransactionsState {
  scheduledTransactionPreview: ScheduledTransactionPreview | null;
  activeScheduledTransactionId: string | null;
}

type UpcomingTransactionsAction =
  | {
      type: 'SET_SCHEDULED_TRANSACTION_PREVIEW';
      payload: ScheduledTransactionPreview | null;
    }
  | {
      type: 'SET_ACTIVE_SCHEDULED_TRANSACTION_ID';
      payload: string | null;
    };

interface UpcomingTransactionsContextType {
  state: UpcomingTransactionsState;
  dispatch: React.Dispatch<UpcomingTransactionsAction>;
}

const initialState: UpcomingTransactionsState = {
  activeScheduledTransactionId: null,
  scheduledTransactionPreview: null,
};

function upcomingTransactionsReducer(
  state: UpcomingTransactionsState,
  action: UpcomingTransactionsAction
): UpcomingTransactionsState {
  switch (action.type) {
    case 'SET_SCHEDULED_TRANSACTION_PREVIEW':
      return { ...state, scheduledTransactionPreview: action.payload };
    case 'SET_ACTIVE_SCHEDULED_TRANSACTION_ID':
      return {
        ...state,
        activeScheduledTransactionId: action.payload,
      };
    default:
      return state;
  }
}

const UpcomingTransactionsContext = createContext<UpcomingTransactionsContextType | undefined>(
  undefined
);

export function UpcomingTransactionsProvider({ children }: { children: ReactNode }): ReactNode {
  const [state, dispatch] = useReducer(upcomingTransactionsReducer, initialState);

  return (
    <UpcomingTransactionsContext.Provider value={{ state, dispatch }}>
      {children}
    </UpcomingTransactionsContext.Provider>
  );
}

export function useUpcomingTransactionsContext(): {
  state: UpcomingTransactionsState;
  setScheduledTransactionPreview: (flow: ScheduledTransactionPreview | null) => void;
  setActiveScheduledTransactionId: (scheduledTransactionId: string | null) => void;
} {
  const context = useContext(UpcomingTransactionsContext);
  if (context === undefined) {
    throw new Error(
      'useUpcomingTransactionsContext must be used within an UpcomingTransactionsProvider'
    );
  }

  const { state, dispatch } = context;

  const setScheduledTransactionPreview = useCallback(
    (scheduledTransactionPreview: ScheduledTransactionPreview | null): void => {
      dispatch({
        type: 'SET_SCHEDULED_TRANSACTION_PREVIEW',
        payload: scheduledTransactionPreview,
      });
    },
    [dispatch]
  );

  const setActiveScheduledTransactionId = useCallback(
    (scheduledTransactionId: string | null): void => {
      dispatch({
        type: 'SET_ACTIVE_SCHEDULED_TRANSACTION_ID',
        payload: scheduledTransactionId,
      });
    },
    [dispatch]
  );

  return {
    state,
    setScheduledTransactionPreview,
    setActiveScheduledTransactionId,
  };
}
