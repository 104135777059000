import { type ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';
import type SupplierInvoiceModel from 'qonto/models/supplier-invoice';
import { InvoicePreview } from 'qonto/react/components/match-invoice/invoice-preview';

interface InvoiceDetailsProps {
  invoice: SupplierInvoiceModel | undefined;
  isLoading: boolean;
}

export function InvoiceDetails({ invoice, isLoading }: InvoiceDetailsProps): ReactNode {
  const intl = useIntl();
  const isCreditNote = useMemo(
    (): boolean => Boolean(invoice?.isCreditNote),
    [invoice?.isCreditNote]
  );

  const titleText = useMemo(
    () =>
      isCreditNote
        ? intl.formatMessage({
            id: 'supplier-invoices.match-transaction-modal.credit-note-details',
          })
        : intl.formatMessage({
            id: 'supplier-invoices.match-transaction-modal.invoice-details.title',
          }),
    [isCreditNote, intl]
  );

  const supplierName = useMemo(
    (): string => (invoice?.supplierSnapshot?.name ?? invoice?.fileName ?? '') as string,
    [invoice?.supplierSnapshot?.name, invoice?.fileName]
  );

  return (
    <>
      <h3 className="caption mb-8" data-test-invoice-details-title="">
        {titleText}
      </h3>
      <InvoicePreview
        amount={invoice?.totalAmount.value}
        currency={invoice?.totalAmount.currency}
        data-test-supplier-invoice-preview
        date={invoice?.dueDate}
        isCreditNote={isCreditNote}
        isLoading={isLoading}
        name={supplierName}
        nameLabel={intl.formatMessage({
          id: 'supplier-invoices.supplier-creation.supplier-name.label',
        })}
        showSignus
      />
    </>
  );
}
