import React, { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { Avatar, Badge, BadgeHighlight, Tooltip, type BadgeProps } from '@repo/design-system-kit';
// @ts-expect-error -- TODO remove this when ui-kit constants are typed
import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import { GMI_SOLUTION_ID_PREFIX, INTEGRATION_TYPES } from 'qonto/constants/connect';
import styles from './styles.strict-module.css';

interface Logo {
  url: string;
  alt?: string;
}

interface BadgeType {
  TRY_FOR_FREE: BadgeProps['type'];
  UPGRADE: BadgeProps['type'];
}

interface ApplicationHeaderContentProps {
  application: {
    integration_type: string;
    tray_solution_id?: string;
    minimum_integration_level: string;
    name: string;
    logo?: Logo;
    waitlisted: boolean;
    tag_line: string;
  };
}

export function ApplicationHeaderContent({
  application: {
    integration_type: integrationType,
    logo,
    minimum_integration_level: minimumIntegrationLevel,
    name,
    tag_line: tagLine,
    tray_solution_id: traySolutionId,
    waitlisted,
  },
}: ApplicationHeaderContentProps): ReactNode {
  const { formatMessage } = useIntl();
  const upsellManager = useEmberService('upsell-manager');

  const abilities = useEmberService('abilities');

  const badgeTypes = BADGE_TYPE as BadgeType;

  const cannotAccessIntegrationConnect = abilities.cannot(
    'access integration connect',
    minimumIntegrationLevel
  );

  const isTrialAvailable: boolean =
    Boolean(minimumIntegrationLevel) &&
    upsellManager.hasAvailableFreeTrial(minimumIntegrationLevel);

  const isGMIIntegration = Boolean(traySolutionId?.startsWith(GMI_SOLUTION_ID_PREFIX));

  // Force GMI integrations to show as waitlisted, since we are sunsetting them
  const isWaitlisted = isGMIIntegration ? true : waitlisted;

  const isPartnership = integrationType === INTEGRATION_TYPES.partnerships;

  return (
    <div className={styles['connect-app-header-content-left']}>
      <div className={styles['connect-app-header-meta']}>
        <div className={styles['application-avatar']}>
          <Avatar
            alt={logo?.alt ? logo.alt : name}
            data-test-application-avatar
            size="56"
            src={logo?.url}
            withBorder
          />
        </div>

        {isGMIIntegration ? (
          <BadgeHighlight
            data-test-application-beta-badge
            level="secondary"
            text={formatMessage({ id: 'bank_accounts.header.beta-badge' })}
          />
        ) : null}

        {isPartnership ? (
          <Tooltip
            data-test-partnership-tooltip
            delay={0}
            label={formatMessage({ id: 'qonto-hub.connect.partnership-tooltip' })}
            needsButton
          >
            <BadgeHighlight
              data-test-application-partnership-badge
              text={formatMessage({ id: 'qonto-hub.connect.partnership-badge' })}
            />
          </Tooltip>
        ) : null}

        {!isPartnership && cannotAccessIntegrationConnect ? (
          <Badge
            data-test-application-details-upsell-badge
            text={
              isTrialAvailable
                ? formatMessage({ id: 'promotion-system.badge.trial.label' })
                : formatMessage({ id: 'upsell.add-ons.badge' })
            }
            type={isTrialAvailable ? badgeTypes.TRY_FOR_FREE : badgeTypes.UPGRADE}
          />
        ) : null}
      </div>
      <div className={styles['title-wrapper']}>
        <h1 className={styles.title} data-test-integration-name>
          {name}
        </h1>
      </div>
      <p className={styles['tag-line']} data-test-integration-tag-line>
        {isWaitlisted
          ? formatMessage({ id: 'qonto-hub.connect.integration.waitlist.description' })
          : tagLine}
      </p>
    </div>
  );
}
