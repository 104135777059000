import { type ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'clsx';
import dayjs from 'dayjs';
import { BlackCardDesign, LightCardDesign } from '@repo/cards-kit/constants';
import type { CardDesign, CardLevel } from '@repo/cards-kit/constants';
import { ChiplessAssets } from '@repo/cards-kit/assets';
import { isBlackDesign } from '@repo/cards-kit/utils';
import { CopyToClipboard } from 'qonto/react/shared/components/copy-to-clipboard';
import styles from './card-image.strict-module.css';
import { Caption } from './internal/caption';
import { Pan } from './internal/pan';

interface CardImageProps {
  cardDesign: CardDesign;
  cardLevel?: CardLevel;
  className?: string;
  cvv?: string;
  expiration?: string;
  holderFullName: string;
  isDisabled?: boolean;
  isRevealed?: boolean;
  hasCaption?: boolean;
  pan: string;
}

const {
  CardAdvertisingChipless,
  CardFlashChipless,
  CardMetalGraphiteBlack2019Chipless,
  CardMetalMineralGray2024Chipless,
  CardMetalSandGold2024Chipless,
  CardOneChipless,
  CardPlusBlack2023Chipless,
  CardPlusRecycledPlasticBlack2023Chipless,
  CardPlusRecycledPlasticLilac2023Chipless,
  CardPlusRecycledPlasticSilver2023Chipless,
  CardVirtualChipless,
} = ChiplessAssets;

const CARD_IMAGES: Record<CardDesign, string> = {
  [BlackCardDesign.MetalGraphiteBlack2019]: CardMetalGraphiteBlack2019Chipless,
  [BlackCardDesign.PlusBlack2023]: CardPlusBlack2023Chipless,
  [BlackCardDesign.PlusRecycledPlasticBlack2023]: CardPlusRecycledPlasticBlack2023Chipless,
  [LightCardDesign.AdvertisingDefault2023]: CardAdvertisingChipless,
  [LightCardDesign.FlashDefault2021]: CardFlashChipless,
  [LightCardDesign.MetalMineralGray2024]: CardMetalMineralGray2024Chipless,
  [LightCardDesign.MetalSandGold2024]: CardMetalSandGold2024Chipless,
  [LightCardDesign.PlusRecycledPlasticLilac2023]: CardPlusRecycledPlasticLilac2023Chipless,
  [LightCardDesign.PlusRecycledPlasticSilver2023]: CardPlusRecycledPlasticSilver2023Chipless,
  [LightCardDesign.StandardRecycledPlastic2023]: CardOneChipless,
  [LightCardDesign.VirtualDefault2017]: CardVirtualChipless,
};

const MASK_CHAR = '•';
const THREE_DOTS: string = MASK_CHAR.repeat(3);

export function CardImage({
  cardDesign,
  cardLevel,
  className,
  cvv,
  expiration,
  holderFullName,
  isDisabled = false,
  isRevealed = false,
  hasCaption = false,
  pan,
}: CardImageProps): ReactNode {
  const { formatMessage } = useIntl();

  const image: string = CARD_IMAGES[cardDesign];
  const isBlack: boolean = isBlackDesign(cardDesign);
  const isFullyMasked = !/\d/.test(pan);

  const expDate = expiration ? dayjs(expiration).format('MM/YY') : THREE_DOTS;

  return (
    <div
      className={cx(
        styles.container,
        isDisabled && styles.disabled,
        isFullyMasked && styles['fully-masked'],
        className
      )}
      data-test-card-image-design={cardDesign}
    >
      <img alt="" className={styles.image} src={image} />
      <dl className={cx('body-1', styles.infos, isBlack && styles.black)}>
        {Boolean(hasCaption) && <Caption cardLevel={cardLevel} />}
        <dt className="sr-only">
          <FormattedMessage id="cards.image.holder" />
        </dt>
        <dd
          className={cx(styles['infos-holder'], styles['description-details'])}
          data-test-card-image-holder={holderFullName}
        >
          {holderFullName}
        </dd>
        <dt className="sr-only">
          <FormattedMessage id="cards.image.number" />
        </dt>
        <dd
          className={cx(styles['infos-pan'], styles['description-details'])}
          data-test-card-image-pan-full=""
        >
          {isRevealed ? (
            <CopyToClipboard
              className={cx('overlay', styles['copy-btn'], isBlack && styles.black)}
              clipboardText={pan}
              toastMessage={formatMessage({ id: 'cards.image.pan.copy-success' })}
            >
              <Pan isRevealed={isRevealed} pan={pan} />
            </CopyToClipboard>
          ) : (
            <Pan isRevealed={isRevealed} pan={pan} />
          )}
        </dd>
        <div
          aria-hidden={!expiration}
          className={styles['infos-exp-date']}
          data-test-expiration-container=""
        >
          <dt className={styles['infos-caption']}>
            <FormattedMessage id="cards.image.exp" />
          </dt>
          <dd className={styles['description-details']} data-test-card-image-expiration="">
            {expDate}
          </dd>
        </div>
        <div aria-hidden={!cvv} className={styles['infos-cvv']} data-test-cvv-container="">
          <dt className={styles['infos-caption']}>
            <FormattedMessage id="cards.image.cvv" />
          </dt>
          <dd className={styles['description-details']} data-test-card-image-cvv="">
            {cvv || THREE_DOTS}
          </dd>
        </div>
      </dl>
    </div>
  );
}
