import { type ReactNode } from 'react';
import { createElement } from 'react';
import { useIntl } from 'react-intl';
import type { MenuItemType } from '@repo/domain-kit/cashflow';
import dayjs from 'dayjs';
import {
  IconEyeHiddenOutlined,
  IconBacklinkOutlined,
  IconTrashOutlined,
} from '@repo/monochrome-icons';
import type { UpcomingTransaction } from 'qonto/react/hooks/use-upcoming-transactions';
import { useUpcomingTransactionsContext } from '../../upcoming-transactions-context';
import { useUpcomingTransactionActions } from './use-upcoming-transaction-actions';

export interface MenuConfig {
  mainAction?: {
    action: () => void;
    text: string;
  };
  useActionsMenu: boolean;
  sections: {
    firstSection?: MenuItemType[];
    secondSection?: MenuItemType[];
  };
}

export const useMenuConfig = (
  upcomingTransaction: UpcomingTransaction | null,
  handleEditUpcomingTransaction?: (upcomingTransaction: UpcomingTransaction) => void
): MenuConfig => {
  const { formatMessage } = useIntl();
  const {
    handleMarkAsPaid,
    handlePayNow,
    handleSeeInvoice,
    handleSeeSupplierInvoice,
    handleResendInvoice,
    handleDelete,
    handleCancelTransfer,
    handleRescheduleTransfer,
    handleScheduleSupplierInvoice,
    handleScheduleClientInvoice,
  } = useUpcomingTransactionActions(upcomingTransaction);

  const { setActiveScheduledTransactionId } = useUpcomingTransactionsContext();

  const type = upcomingTransaction?.provider_object_type;
  const invoiceId = upcomingTransaction?.provider_object_id;
  const shouldShowMarkPaid = invoiceId !== undefined;

  const isOverdueInvoice = dayjs(upcomingTransaction?.due_date).isBefore(dayjs().startOf('day'));

  const supplierInvoiceMainActionText = isOverdueInvoice
    ? formatMessage({ id: 'cash-flow.upcoming-transactions.actions.pay' })
    : formatMessage({ id: 'cash-flow.upcoming-transactions.actions.schedule' });

  const handleScheduleSupplierInvoiceWrapper = (): void => {
    handleScheduleSupplierInvoice();
    setActiveScheduledTransactionId(upcomingTransaction?.id ?? null);
  };

  const handleScheduleClientInvoiceWrapper = (): void => {
    handleScheduleClientInvoice();
    setActiveScheduledTransactionId(upcomingTransaction?.id ?? null);
  };

  const createAction = (
    id: string,
    testId: string,
    onPress: () => void,
    isDestructive = false,
    icon?: ReactNode | null
  ): MenuItemType => ({
    label: formatMessage({ id }),
    testId,
    onPress,
    ...(isDestructive && { isDestructive }),
    ...(icon && { icon }),
  });

  const receivableInvoiceConfig: MenuConfig = {
    useActionsMenu: true,
    mainAction: {
      action: handleScheduleClientInvoiceWrapper,
      text: formatMessage({ id: 'cash-flow.upcoming-transactions.actions.change-date.label' }),
    },
    sections: {
      firstSection: [
        createAction(
          'cash-flow.upcoming-transactions.actions.resend-invoice',
          'resend-action',
          () => {
            handleResendInvoice();
          }
        ),
        ...(shouldShowMarkPaid
          ? [
              createAction(
                'cash-flow.upcoming-transactions.actions.mark-paid',
                'mark-as-paid-action',
                handleMarkAsPaid
              ),
            ]
          : []),
      ],
      secondSection: [
        createAction(
          'cash-flow.upcoming-transactions.actions.check-invoice',
          'check-invoice-action',
          handleSeeInvoice,
          false,
          createElement(IconBacklinkOutlined)
        ),
        createAction(
          'cash-flow.upcoming-transactions.actions.remove',
          'delete-action',
          () => void handleDelete(),
          true,
          createElement(IconEyeHiddenOutlined)
        ),
      ],
    },
  };

  const supplierInvoiceConfig: MenuConfig = {
    useActionsMenu: true,
    mainAction: {
      action: isOverdueInvoice ? handlePayNow : handleScheduleSupplierInvoiceWrapper,
      text: supplierInvoiceMainActionText,
    },
    sections: {
      firstSection: [
        !isOverdueInvoice
          ? createAction(
              'cash-flow.upcoming-transactions.actions.pay',
              'pay-now-action',
              handlePayNow
            )
          : createAction(
              'cash-flow.upcoming-transactions.actions.schedule',
              'schedule-supplier-invoice-action',
              handleScheduleSupplierInvoiceWrapper
            ),
        ...(shouldShowMarkPaid
          ? [
              createAction(
                'cash-flow.upcoming-transactions.actions.mark-paid',
                'mark-as-paid-action',
                handleMarkAsPaid
              ),
            ]
          : []),
      ],
      secondSection: [
        createAction(
          'cash-flow.upcoming-transactions.actions.check-invoice',
          'check-invoice-action',
          () => {
            handleSeeSupplierInvoice();
          },
          false,
          createElement(IconBacklinkOutlined)
        ),
        createAction(
          'cash-flow.upcoming-transactions.actions.remove',
          'delete-action',
          () => void handleDelete(),
          true,
          createElement(IconEyeHiddenOutlined)
        ),
      ],
    },
  };

  const transferConfig: MenuConfig = {
    useActionsMenu: true,
    mainAction: {
      action: () => {
        void handleRescheduleTransfer();
        setActiveScheduledTransactionId(upcomingTransaction?.id ?? null);
      },
      text: formatMessage({
        id: 'cash-flow.upcoming-transactions.actions.reschedule',
      }),
    },
    sections: {
      firstSection: [
        createAction(
          'cash-flow.upcoming-transactions.actions.remove',
          'delete-action',
          () => void handleDelete(),
          true,
          createElement(IconEyeHiddenOutlined)
        ),
        createAction(
          'cash-flow.upcoming-transactions.actions.cancel-transaction.modal.confirm.cta',
          'cancel-transfer-action',
          () => void handleCancelTransfer(),
          true,
          createElement(IconTrashOutlined)
        ),
      ],
    },
  };

  const defaultConfig: MenuConfig = {
    useActionsMenu: false,
    mainAction: {
      action: () => {
        if (upcomingTransaction) handleEditUpcomingTransaction?.(upcomingTransaction);
      },
      text: formatMessage({ id: 'cash-flow.upcoming-transactions.actions.edit' }),
    },
    sections: {
      firstSection: [
        createAction(
          'cash-flow.upcoming-transactions.actions.remove',
          'delete-action',
          () => void handleDelete(),
          true,
          createElement(IconTrashOutlined)
        ),
      ],
    },
  };

  const configMap: Record<string, MenuConfig> = {
    receivable_invoice: receivableInvoiceConfig,
    supplier_invoice: supplierInvoiceConfig,
    transfer: transferConfig,
    default: defaultConfig,
  };

  return {
    ...(configMap[type ?? 'default'] ?? defaultConfig),
  };
};
