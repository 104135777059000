import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'clsx';
import { Link } from '@qonto/react-migration-toolkit/react/components';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { Disclaimer } from '@repo/design-system-kit';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import styles from './styles.strict-module.css';

interface ConnectionStatuses {
  failedConnections: { id: string; name: string }[];
  actionableFailures: { id: string; name: string }[];
}

interface StatusBannerProps {
  connectionStatuses?: ConnectionStatuses;
  className?: string;
}

export function StatusBanner({
  connectionStatuses,
  className,
  ...props
}: StatusBannerProps): ReactNode {
  const abilities = useEmberService('abilities');
  const { organization } = useOrganizationManager();

  const hasActionableFailures = Boolean(connectionStatuses?.actionableFailures.length);
  const hasFailedConnections = Boolean(connectionStatuses?.failedConnections.length);
  const hasSingleFailedConnection = connectionStatuses?.failedConnections.length === 1;

  const failedConnection = connectionStatuses?.failedConnections[0];
  const actionableConnection = connectionStatuses?.actionableFailures[0];

  const canDisplay = abilities.can('view connections external-account') && hasFailedConnections;

  if (!canDisplay) {
    return null;
  }

  if (hasActionableFailures) {
    return (
      <Disclaimer.Block
        className={cx(styles.wrapper, className)}
        data-test-connections-status-banner
        level="error"
        {...props}
      >
        {hasSingleFailedConnection ? (
          <FormattedMessage
            id="banner.external-connection.error.fixable.disclaimer.text"
            values={{
              bankName: actionableConnection?.name,
              activeConnections: (
                <Link
                  data-test-action-link
                  to={`/organizations/${organization.slug}/settings/connections/banks?highlight=${actionableConnection?.id}`}
                >
                  <FormattedMessage id="banner.external-connection.error.fixable.disclaimer.link" />
                </Link>
              ),
            }}
          />
        ) : (
          <FormattedMessage
            id="banner.external-connection.error.fixable.disclaimer.text-plural"
            values={{
              connectionsCount: connectionStatuses?.failedConnections.length,
              activeConnections: (
                <Link
                  data-test-action-link
                  to={`/organizations/${organization.slug}/settings/connections/banks`}
                >
                  <FormattedMessage id="banner.external-connection.error.fixable.disclaimer.link" />
                </Link>
              ),
            }}
          />
        )}
      </Disclaimer.Block>
    );
  }

  return (
    <Disclaimer.Block
      className={cx(styles.wrapper, className)}
      data-test-connections-status-banner
      level="error"
      {...props}
    >
      {hasSingleFailedConnection ? (
        <FormattedMessage
          id="banner.external-connection.error.non-fixable.disclaimer.text"
          values={{
            bankName: failedConnection?.name,
          }}
        />
      ) : (
        <FormattedMessage
          id="banner.external-connection.error.non-fixable.disclaimer.text-plural"
          values={{
            connectionsCount: connectionStatuses?.failedConnections.length,
          }}
        />
      )}
    </Disclaimer.Block>
  );
}
