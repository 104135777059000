/* eslint-disable @qonto/use-composable-popup -- Temporarily disabled the rule until migrating the popup */
import { useEffect, useState, type ReactNode } from 'react';
import { Popup } from '@repo/design-system-kit';
import window from 'ember-window-mock';
import { useSegment } from '@repo/poly-hooks/segment';
import { useIntl } from 'react-intl';
import { useRouter } from '@qonto/react-migration-toolkit/react/hooks';
import ENV from 'qonto/config/environment';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { shouldShowKycPendingPopup } from './kyc-pending-popup-helpers';
import { KycPendingPopupIllustration } from './kyc-pending-popup.illustration';
import styles from './kyc-pending-popup.strict-module.css';

export const KYC_PENDING_POPUP_STORAGE_KEY = 'kyc-pending-popup';

const isTest = ENV.environment === 'test';
const popupDelay = isTest ? 0 : 2000;

export function KycPendingPopup(): ReactNode {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const segment = useSegment();
  const router = useRouter();
  const { organization } = useOrganizationManager();

  const segmentEvent = (action: 'started' | 'closed'): void => {
    segment.track('kyc_intro_popup', { action });
  };

  useEffect(() => {
    const storedDate = window.localStorage.getItem(KYC_PENDING_POPUP_STORAGE_KEY);
    if (storedDate && !shouldShowKycPendingPopup(new Date(storedDate))) {
      return;
    }

    const timeout = setTimeout(() => {
      setIsOpen(true);
      window.localStorage.setItem(KYC_PENDING_POPUP_STORAGE_KEY, new Date().toUTCString());
    }, popupDelay);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const handleCancel = (): void => {
    segmentEvent('closed');
    setIsOpen(false);
  };

  const handleConfirm = (): void => {
    segmentEvent('started');
    setIsOpen(false);
    void router.push(`/organizations/${organization.slug}/kyc/start`);
  };

  // react-aria modal escapes the test container, we render it where it can find the modal (i.e. <main> component)
  const TEST_PORTAL_CONTAINER = document.querySelector('main') ?? undefined;

  return (
    <Popup
      UNSTABLE_portalContainer={isTest ? TEST_PORTAL_CONTAINER : undefined}
      confirmText={intl.formatMessage({ id: 'kyc.popup.pending.cta-primary' })}
      data-test-id="kyc-pending-popup"
      illustration={
        <div className={styles.illustrationWrapper}>
          <KycPendingPopupIllustration />
        </div>
      }
      isOpen={isOpen}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      subtitle={intl.formatMessage({ id: 'kyc.popup.pending.subtitle' })}
      title={intl.formatMessage({ id: 'kyc.popup.pending.title' })}
    />
  );
}
