import React from 'react';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { formatMoney } from 'qonto/helpers/format/money';
import { F24_STATUS } from 'qonto/constants/f24';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './item.strict-module.css';

interface F24Item {
  id: string;
  name: string;
  status: string;
  expirationDate: string;
  documentAmount: number;
  currency: string;
}

interface ItemProps {
  item: F24Item;
  highlightedItem?: F24Item;
  highlightItem: (itemId: string) => void;
  onItemKeydown: (itemId: string) => void;
}

interface F24StatusType {
  SCHEDULED: string;
  COMPLETED: string;
  DECLINED: string;
  CANCELED: string;
}

export function Item({
  item,
  highlightedItem,
  highlightItem,
  onItemKeydown,
  ...props
}: ItemProps): React.ReactElement {
  const intl = useIntl();
  const emberIntl = useEmberService('intl');

  const status = {
    [(F24_STATUS as F24StatusType).SCHEDULED]: intl.formatMessage({ id: 'f24.status.scheduled' }),
    [(F24_STATUS as F24StatusType).COMPLETED]: intl.formatMessage({ id: 'f24.status.completed' }),
    [(F24_STATUS as F24StatusType).DECLINED]: intl.formatMessage({ id: 'f24.status.declined' }),
    [(F24_STATUS as F24StatusType).CANCELED]: intl.formatMessage({ id: 'f24.status.canceled' }),
  };

  const isTerminatedPayment = item.status === 'declined' || item.status === 'canceled';
  const isHighlighted = item.id === highlightedItem?.id;

  return (
    <tr
      className={cx(
        styles.row,
        isHighlighted && styles['row-active'],
        isTerminatedPayment && styles.terminated
      )}
      data-test-table-item-f24-order={item.id}
      onClick={() => {
        highlightItem(item.id);
      }}
      onKeyDown={() => {
        onItemKeydown(item.id);
      }}
      tabIndex={-1}
      {...props}
    >
      <td className={cx(styles.cell, styles['item-name-cell'])}>
        <div className={styles['item-name-container']}>
          <div className={styles['status-icon']}>
            {item.status === 'completed' && (
              <div data-test-f24-status-icon-completed>
                <StaticThemedAsset alt="" assetPath="/icon/status/status_check-m.svg" />
              </div>
            )}
            {item.status === 'scheduled' && (
              <div data-test-f24-status-icon-scheduled>
                <StaticThemedAsset
                  alt=""
                  assetPath="/icon/feature/feature_transfer_scheduled-m.svg"
                />
              </div>
            )}
            {item.status === 'declined' && (
              <div data-test-f24-status-icon-declined>
                <StaticThemedAsset alt="" assetPath="/icon/status/status_stop-m.svg" />
              </div>
            )}
            {item.status === 'canceled' && (
              <div data-test-f24-status-icon-canceled>
                <StaticThemedAsset alt="" assetPath="/icon/status/status_stop-m.svg" />
              </div>
            )}
          </div>
          <div className={styles['item-info']}>
            <div className={styles.name} data-test-f24-name>
              {item.name}
            </div>
            <div className={styles['status-displayed']} data-test-f24-status-displayed>
              {status[item.status as unknown as keyof typeof status]}
            </div>
          </div>
        </div>
      </td>
      <td className={styles.cell} data-test-f24-table-item-expiration-date>
        <span>
          {dateToken({
            date: item.expirationDate,
            token: 'date-year-s',
            locale: intl.locale,
          })}
        </span>
      </td>
      <td className={styles.cell} data-test-f24-table-item-amount>
        <span className={styles.amount}>
          {formatMoney(Number(item.documentAmount), {
            signus: '-',
            currency: item.currency,
            intl: emberIntl,
          })}
        </span>
      </td>
    </tr>
  );
}
