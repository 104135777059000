import { useEffect, useState, type ReactNode } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useIntl, type IntlShape } from 'react-intl';
import type { LabelCategorizationRule, LabelIdentifier } from 'qonto/react/models/label';
import type { TransactionModel } from 'qonto/react/models/transaction';
import { CategorizationWidget } from './categorization-widget';
import { CategorizationSubtitleWidget } from './categorization-subtitle-widget';

interface LabelCategorizationProps {
  labelIds: LabelIdentifier[];
  transactions: TransactionModel[];
  hideDisclaimer?: boolean;
  onClose: () => void;
  onGenerateRules: (rules: LabelCategorizationRule[]) => void;
  resetSelectedLabelIds: (callback: () => void) => void;
}

export function LabelCategorization({
  labelIds,
  transactions = [],
  hideDisclaimer,
  onClose,
  onGenerateRules,
  resetSelectedLabelIds,
}: LabelCategorizationProps): ReactNode {
  const { formatMessage } = useIntl();
  const segment = useEmberService('segment');
  const [initLabelIds, setInitLabelIds] = useState(labelIds);
  const [initTransactions, setInitTransactions] = useState(transactions);
  const [shouldBeVisible, setShouldBeVisible] = useState(true);

  const handleClose = (): void => {
    setShouldBeVisible(false);
    onClose();
  };

  const listState = new Map<string, string | undefined>([]);

  const categorizationCriterias = transactions.map(({ counterpartyName, rawCounterpartyName }) => ({
    counterpartyName,
    rawCounterpartyName,
  }));
  const uniqueCounterparties = [
    ...new Set(
      categorizationCriterias.map(
        criteria => criteria.counterpartyName || criteria.rawCounterpartyName
      )
    ),
  ];
  const hasMultipleCounterparties = uniqueCounterparties.length > 1;
  const remainingCounterparties = uniqueCounterparties.slice(2);

  labelIds.forEach(({ labelListId, labelId }) => {
    listState.set(labelListId, labelId);
  });

  Array.from(listState).forEach(([key]) => {
    const isUndefined = !labelIds.find(({ labelListId }) => labelListId === key);
    if (isUndefined) listState.set(key, undefined);
  });

  const trackGenerateRules = (includePast: boolean): void => {
    segment.track('analytic_labels_generate_rules_clicked', {
      origin: 'transactions-details',
      type: includePast ? 'past-future' : 'future',
      bulk_selection: transactions.length > 1,
      number_of_counterparties: uniqueCounterparties.length,
    });
  };

  const handleGenerateRules = (includePast: boolean): void => {
    const categorizationRules = Array.from(listState)
      .map(([labelListId, labelId]) => {
        return categorizationCriterias.map(matchingCriteria => ({
          labelListId,
          labelId,
          matchingCriteria,
          includePast,
        }));
      })
      .flat() as LabelCategorizationRule[];

    trackGenerateRules(includePast);
    onGenerateRules(categorizationRules);
    setInitLabelIds(labelIds);
    setInitTransactions(transactions);
    listState.clear();
  };

  const hasLabelChange = JSON.stringify(initLabelIds) !== JSON.stringify(labelIds);
  const hasTransactionChange = initTransactions.some(
    ({ id }) => !transactions.find(transaction => transaction.id === id)
  );

  const isDisclosable = !hasTransactionChange && hasLabelChange;

  const resetTransactionsAndLabels = (): void => {
    setInitTransactions(transactions);
    setInitLabelIds(labelIds);
  };

  useEffect(() => {
    resetSelectedLabelIds(resetTransactionsAndLabels);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- adding all deps creates an infinite loop due to state dependencies with Ember on labelIds
  }, [hasTransactionChange, resetSelectedLabelIds]);

  useEffect(() => {
    setShouldBeVisible(true);
  }, [labelIds]);

  const {
    title,
    categorizationSubtitle,
    bulkCounterpartyPlaceholder,
    remainingCounterpartiesString,
    pastToFutureCta,
    futureCta,
  } = getWidgetContent(
    hasMultipleCounterparties,
    labelIds,
    uniqueCounterparties,
    remainingCounterparties,
    handleGenerateRules,
    formatMessage
  );

  return (
    <AnimatePresence>
      {shouldBeVisible && isDisclosable && !hideDisclaimer ? (
        <motion.div
          animate={{ opacity: 1, height: 'auto' }}
          initial={{ opacity: 0, height: 0.8 }}
          transition={{ duration: 0.5, ease: 'easeInOut' }}
        >
          <CategorizationWidget
            data-testid="label-categorization"
            futureCta={futureCta}
            onClose={handleClose}
            pastFutureCta={pastToFutureCta}
            subtitle={
              <CategorizationSubtitleWidget
                bulkCounterpartyPlaceholder={bulkCounterpartyPlaceholder}
                categorizationSubtitle={categorizationSubtitle}
                remainingCounterpartiesLength={remainingCounterparties.length}
                remainingCounterpartiesString={remainingCounterpartiesString}
                uniqueCounterparties={uniqueCounterparties}
              />
            }
            title={title}
          />
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}

interface WidgetContent {
  title: string;
  categorizationSubtitle: string;
  bulkCounterpartyPlaceholder: string;
  remainingCounterpartiesString: string;
  pastToFutureCta: {
    copy: string;
    action: (includePastTransactions: boolean) => void;
  };
  futureCta: {
    copy: string;
    action: (includePastTransactions: boolean) => void;
  };
}

function getWidgetContent(
  hasMultipleCounterparties: boolean,
  labelIds: LabelIdentifier[],
  uniqueCounterparties: string[],
  remainingCounterparties: string[],
  handleGenerateRules: (includePastTransactions: boolean) => void,
  formatMessage: IntlShape['formatMessage']
): WidgetContent {
  const getRemainingCounterpartiesString = (): string => {
    if (remainingCounterparties.length > 1) {
      return `${remainingCounterparties.slice(0, -1).join(', ')} ${formatMessage({ id: 'transactions.sidepanel.cash-flow.analytic-labels.auto-labelling-widget.tooltip' }, { counterparty_name: remainingCounterparties.at(-1) ?? '' })}`;
    }
    return remainingCounterparties[0] ?? '';
  };

  return {
    title: formatMessage(
      { id: 'transactions.sidepanel.cash-flow.analytic-labels.auto-labelling-widget.title' },
      { count: labelIds.length }
    ),
    categorizationSubtitle: hasMultipleCounterparties
      ? formatMessage(
          {
            id: 'transactions.sidepanel.cash-flow.analytic-labels.auto-labelling-widget.subtitle.bulk.message',
          },
          {
            count: labelIds.length,
            counterparty_name_one: uniqueCounterparties[0] ?? '',
            counterparty_name_two: uniqueCounterparties[1] ?? '',
          }
        )
      : formatMessage(
          {
            id: 'transactions.sidepanel.cash-flow.analytic-labels.auto-labelling-widget.subtitle.single',
          },
          {
            count: labelIds.length,
            counterparty_name: uniqueCounterparties[0] ?? '',
          }
        ),
    bulkCounterpartyPlaceholder: formatMessage(
      {
        id: 'transactions.sidepanel.cash-flow.analytic-labels.auto-labelling-widget.subtitle.bulk.counterparty-placeholder',
      },
      { count: remainingCounterparties.length }
    ),
    remainingCounterpartiesString: getRemainingCounterpartiesString(),
    pastToFutureCta: {
      copy: formatMessage({
        id: 'transactions.sidepanel.cash-flow.analytic-labels.auto-labelling-widget.button.past-future',
      }),
      action: handleGenerateRules,
    },
    futureCta: {
      copy: formatMessage({
        id: 'transactions.sidepanel.cash-flow.analytic-labels.auto-labelling-widget.button.future',
      }),
      action: handleGenerateRules,
    },
  };
}
