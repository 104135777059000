import type { ReactNode } from 'react';
import { useCallback, useState } from 'react';
import { FullscreenBase, Button } from '@repo/design-system-kit';
import { useRouter, useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { buildWaiter } from '@ember/test-waiters';
import { useIntl } from 'react-intl';
import { Modal, ModalOverlay } from 'react-aria-components';
import dayjs from 'dayjs';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import ENV from 'qonto/config/environment';
import { useFetchSupplierInvoice } from 'qonto/react/hooks/use-fetch-supplier-invoice';
import { LINKED_ATTACHMENTS_LIMIT } from 'qonto/constants/transactions';
import { useMarkSupplierInvoiceAsPaid } from 'qonto/react/accounts-payable/hooks/api/use-mark-as-paid';
import { InvoiceDetails } from './invoice-details';
import { MatchTransaction } from './match-transaction';
import styles from './match-transaction-modal.strict-module.css';

const isTest = ENV.environment === 'test';

const MODAL_ANIMATION_MS = isTest ? 0 : 250;

const waiter = buildWaiter('match-transaction-modal');

interface RouteQueryParams {
  // this query param always exists because we check it in the route
  invoice_id: string;
  origin?: string;
}

export function MatchTransactionModal(): ReactNode {
  const router = useRouter();
  const intl = useIntl();
  const { organization } = useOrganizationManager();
  const [isOpen, setIsOpen] = useState(true);

  const toastFlashMessages = useEmberService('toast-flash-messages');

  const TEST_PORTAL_CONTAINER = document.getElementById('ember-testing') ?? undefined;
  const query = router.query as RouteQueryParams;
  const closeModal = useCallback(() => {
    const token = waiter.beginAsync();
    setIsOpen(false);
    setTimeout(() => {
      if (query.origin === 'upcoming-transactions') {
        void router.push(`/organizations/${organization.slug}/upcoming-transactions`);
      } else if (query.origin === 'supplier-invoices.list') {
        const queryParams = { ...query };
        // We need to cleanup the query params to avoid having null/undefined in the URL
        Object.keys(query).forEach((key: string) => {
          const queryKey = key as keyof RouteQueryParams;
          if (!queryParams[queryKey]) {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- keys are safe to be deleted
            delete queryParams[queryKey];
          }
        });
        const params = new URLSearchParams(queryParams);
        void router.push(
          `/organizations/${organization.slug}/supplier-invoices/list?${params.toString()}`
        );
      } else {
        void router.push(
          `/organizations/${organization.slug}/supplier-invoices/${query.invoice_id}`
        );
      }
      waiter.endAsync(token);
    }, MODAL_ANIMATION_MS);
  }, [organization.slug, router, query]);

  const onError = useCallback(() => {
    closeModal();
    toastFlashMessages.toastError(intl.formatMessage({ id: 'toasts.errors.server_error' }));
  }, [closeModal, intl, toastFlashMessages]);

  const { data: invoice, isPending } = useFetchSupplierInvoice(query.invoice_id, onError, {
    retry: isTest ? false : 3,
  });

  return (
    <ModalOverlay
      UNSTABLE_portalContainer={TEST_PORTAL_CONTAINER}
      className={styles.overlay}
      isOpen={isOpen}
    >
      <Modal
        className={styles.modal}
        isKeyboardDismissDisabled
        isOpen={isOpen}
        onOpenChange={setIsOpen}
      >
        <FullscreenBase
          onClose={() => {
            closeModal();
          }}
        >
          <section className={styles.main}>
            <h1 className="title-1 mb-32" data-testid="match-transaction-title">
              {intl.formatMessage({ id: 'supplier-invoices.match-transaction-modal.title' })}
            </h1>
            <InvoiceDetails invoice={invoice} isLoading={isPending} />
            <MatchTransaction closeModal={closeModal} invoice={invoice} origin={query.origin} />
            <FooterActions />
          </section>
        </FullscreenBase>
      </Modal>
    </ModalOverlay>
  );
}

function FooterActions(): ReactNode {
  const { formatMessage } = useIntl();
  const router = useRouter();

  const toastFlashMessages = useEmberService('toast-flash-messages');
  const { organization } = useOrganizationManager();

  const query = router.query as RouteQueryParams;

  const { mutateAsync: markAsPaid, isPending } = useMarkSupplierInvoiceAsPaid(query.invoice_id);

  const handleContinueWithoutTransaction = async (): Promise<void> => {
    try {
      await markAsPaid(dayjs().format('YYYY-MM-DD'));
      toastFlashMessages.toastSuccess(
        formatMessage({ id: 'supplier-invoices.success-toast.mark-as-paid' })
      );
    } catch (error) {
      // @ts-expect-error forced to cast any/unknown type
      if (error?.status === 422) {
        toastFlashMessages.toastError(
          formatMessage(
            { id: 'receivable-invoices.match-transaction.error-toast' },
            {
              invoiceLimit: LINKED_ATTACHMENTS_LIMIT,
            }
          )
        );
      } else {
        toastFlashMessages.toastError(formatMessage({ id: 'toasts.errors.server_error' }));
      }
    } finally {
      if (query.origin === 'upcoming-transactions') {
        void router.push(`/organizations/${organization.slug}/upcoming-transactions`);
      } else {
        void router.push(`/organizations/${organization.slug}/supplier-invoices/list`);
      }
    }
  };

  return (
    <div className={styles.actions} data-test-actions>
      <Button
        data-testid="continue-without-transaction"
        isLoading={isPending}
        onPress={() => void handleContinueWithoutTransaction()}
        type="button"
        variant="secondary"
      >
        {formatMessage({ id: 'supplier-invoices.match-transaction-modal.cta' })}
      </Button>
    </div>
  );
}
