import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'clsx';
import { useEffect, useRef, type ReactElement } from 'react';
import { BENEFITS_LIST_WARNINGS } from 'qonto/constants/subscriptions';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import type { Amount } from 'qonto/react/models/amount';
import styles from './styles.strict-module.css';

interface Plan {
  sepaOutLimit: number;
  checkLimit: number;
}

interface NewPlanOptions {
  additionalUser: Amount<string>;
  sepaOutboundTransfer: Amount<string>;
}

interface BenefitsListProps {
  context: {
    errors?: {
      code: string;
      detail: string;
      additional_data?: Record<string, number>;
    }[];
    warnings: {
      code: string;
    }[];
    currentPricePlanCode: string;
    subscription: {
      pricePlan: {
        get: ((key: 'optionsObject') => NewPlanOptions) &
          ((key: 'code') => string) &
          ((key: 'sepaOutLimit') => number) &
          ((key: 'checkLimit') => number) &
          ((key: string) => unknown);
      };
    };
    estimatedPrice: Amount<string>;
  };
  transitionToNext: () => void;
  transitionToPrevious: () => void;
}

export function BenefitsList(props: BenefitsListProps): ReactElement {
  const segment = useEmberService('segment');
  const modals = useEmberService('modals');
  const subscriptionManager = useEmberService('subscription-manager');
  const { formatMessage, formatNumber } = useIntl();
  const hasTracked = useRef(false);

  useEffect(() => {
    if (!hasTracked.current) {
      segment.track('change_plan_lose_benefits_displayed');
      hasTracked.current = true;
    }
  }, [segment]);

  const openBlockerModal = (): void => {
    const { errors, subscription } = props.context;
    const userLimitReachError = errors?.find(err => err.code === 'user_limit_reached');
    const membershipsToRevokeCount =
      userLimitReachError?.additional_data?.memberships_to_revoke_count;

    modals.open('subscription/blockers-modal', {
      membershipsToRevokeCount,
      isFullScreenModal: true,
      errors,
      subscription,
    });

    segment.track('plans_blockers_displayed', {
      current_plan: props.context.currentPricePlanCode,
      target_plan: props.context.subscription.pricePlan.get('code'),
      blockers: errors?.map(it => it.code),
    });
  };

  const transitionToNext = (): void => {
    segment.track('change_plan_lose_benefits_clicked');
    if (props.context.errors?.length) {
      openBlockerModal();
    } else {
      props.transitionToNext();
    }
  };

  const onKeepCurrentPlan = (): void => {
    segment.track('change_plan_keep_benefits_clicked');
    props.transitionToPrevious();
  };

  const getWarningTranslations = (): Record<string, { icon: string; text: string }> => {
    const oldPlan = subscriptionManager.currentPricePlan as Plan;

    const newPlan = props.context.subscription.pricePlan;
    const newPlanOptions = newPlan.get('optionsObject');

    const sepaPrice = newPlanOptions.sepaOutboundTransfer.value;
    const additionalUserPrice = newPlanOptions.additionalUser;
    const additionalUserPriceStr = formatNumber(Number(additionalUserPrice.value), {
      currency: additionalUserPrice.currency,
      style: 'currency',
    });

    return {
      [BENEFITS_LIST_WARNINGS.ADVERTISING_CARDS_DISALLOWED]: {
        icon: 'feature_physical_card-m',
        text: formatMessage({ id: 'subscription.change.confirmation.warnings.advertising' }),
      },
      [BENEFITS_LIST_WARNINGS.FEATURE_BOOKKEEPING_DISABLED]: {
        icon: 'feature_label-m',
        text: formatMessage({ id: 'subscription.change.confirmation.warnings.bookkeeping' }),
      },
      [BENEFITS_LIST_WARNINGS.FLASH_CARDS_DISALLOWED]: {
        icon: 'feature_flash_card-m',
        text: formatMessage({ id: 'subscription.change.confirmation.warnings.flash' }),
      },
      [BENEFITS_LIST_WARNINGS.MANAGER_ROLE_DISALLOWED]: {
        icon: 'feature_user-m',
        text: formatMessage({ id: 'subscription.change.confirmation.warnings.manager' }),
      },
      [BENEFITS_LIST_WARNINGS.INCLUDED_TRANSFERS_DECREASE]: {
        icon: 'feature_transfer_recurring-m',
        text: formatMessage(
          { id: 'subscription.change.confirmation.warnings.transfers-decrease' },
          {
            count1: oldPlan.sepaOutLimit,
            count2: newPlan.get('sepaOutLimit'),
            transferPrice: formatNumber(Number(sepaPrice), {
              currency: props.context.estimatedPrice.currency,
              style: 'currency',
            }),
          }
        ),
      },
      [BENEFITS_LIST_WARNINGS.INCLUDED_CHECKS_DECREASE]: {
        icon: 'feature_check-m',
        text: formatMessage(
          { id: 'subscription.change.confirmation.warnings.checks-decrease' },
          {
            count1: oldPlan.checkLimit,
            count2: newPlan.get('checkLimit'),
          }
        ),
      },
      [BENEFITS_LIST_WARNINGS.FEATURE_REQUESTS_DISABLED]: {
        icon: 'feature_request-m',
        text: formatMessage({
          id: 'subscription.change.confirmation.warnings.pending-requests',
        }),
      },
      [BENEFITS_LIST_WARNINGS.FEATURE_SUPPLIER_INVOICES_PENDING_DISABLED]: {
        icon: 'feature_supplier_invoice-m',
        text: formatMessage({
          id: 'subscription.change.confirmation.warnings.supplier-invoices-pending-requests',
        }),
      },
      [BENEFITS_LIST_WARNINGS.CONNECT_EBICS_INTEGRATIONS_DISABLED]: {
        icon: 'feature_connect-m',
        text: formatMessage({ id: 'subscription.change.confirmation.warnings.connect.ebics' }),
      },
      [BENEFITS_LIST_WARNINGS.CONNECT_PLUS_INTEGRATIONS_DISABLED]: {
        icon: 'feature_connect-m',
        text: formatMessage({
          id: 'subscription.change.confirmation.warnings.connect.api-integrations',
        }),
      },
      [BENEFITS_LIST_WARNINGS.UNLIMITED_USERS_DISALLOWED]: {
        icon: 'feature_user-m',
        text: formatMessage(
          { id: 'subscription.change.confirmation.warnings.unlimited-users' },
          {
            amount: additionalUserPriceStr,
          }
        ),
      },
      [BENEFITS_LIST_WARNINGS.FREE_USERS_DISALLOWED]: {
        icon: 'feature_user-m',
        text: formatMessage(
          { id: 'subscription.change.confirmation.warnings.free-users' },
          {
            amount: additionalUserPriceStr,
          }
        ),
      },
    };
  };

  const translations = getWarningTranslations();
  const warnings = props.context.warnings
    .map(warning => ({ ...translations[warning.code], code: warning.code }))
    .filter((item): item is { icon: string; text: string; code: string } =>
      Boolean(item.icon && item.text && item.code)
    );

  return (
    <div className={styles.wrapper}>
      <div className={styles['benefits-list']}>
        <h1 className="title-1 mb-8" data-test-benefits-list-title>
          <FormattedMessage id="subscription.change.benefits-list.title" />
        </h1>
        <h2 className="body-1 mb-24" data-test-benefits-list-subtitle>
          <FormattedMessage id="subscription.change.benefits-list.subtitle" />
        </h2>

        {props.context.warnings.length ? (
          <ul className={styles.list}>
            {warnings.map((warning, index) => (
              <li
                className={cx(styles.card, 'body-1')}
                data-test-confirm-warning={index}
                key={warning.code}
              >
                <span className="styles.icon" data-test-icon={warning.icon}>
                  <StaticThemedAsset
                    assetPath={`/icon/feature/${warning.icon}.svg`}
                    className={styles.icon}
                    data-test-icon={warning.icon}
                  />
                </span>
                <span dangerouslySetInnerHTML={{ __html: warning.text }} />
              </li>
            ))}
          </ul>
        ) : null}

        <button
          className="btn btn--primary btn--large btn--stretch mb-16"
          data-test-benefits-list-cta
          onClick={transitionToNext}
          type="button"
        >
          <FormattedMessage id="btn.confirm" />
        </button>

        <button
          className={styles['btn-keep-current']}
          data-test-benefits-list-cta-keep
          onClick={onKeepCurrentPlan}
          type="button"
        >
          <FormattedMessage id="subscription.change.confirmation.cancel-cta" />
        </button>
      </div>
    </div>
  );
}
