/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import type { ReactNode } from 'react';
import { CockpitTile, InfoIcon, Tooltip } from '@repo/design-system-kit';
import { FormattedNumber, FormattedMessage, useIntl } from 'react-intl';
import type { VatData } from 'qonto/react/models/cash-flow-timeseries';
import styles from './overview-boxes.strict-module.css';

interface VatOverviewBoxProps {
  vatData?: VatData;
  isLoading?: boolean;
  isError?: boolean;
}
export function VatOverviewBox({
  vatData,
  isLoading = false,
  isError = false,
}: VatOverviewBoxProps): ReactNode {
  const { formatMessage, formatNumber } = useIntl();
  const netAmount = vatData?.netAmount.value ? Number(vatData.netAmount.value) : 0;

  const tooltipContent = formatMessage(
    {
      id: 'cash-flow.vat.tooltip',
    },
    {
      credit: formatNumber(Number(vatData?.creditAmount.value), {
        currency: vatData?.creditAmount.currency ?? 'EUR',
        style: 'currency',
      }),
      debit: formatNumber(Number(vatData?.debitAmount.value), {
        currency: vatData?.debitAmount.currency ?? 'EUR',
        style: 'currency',
      }),
    }
  );

  const title = (
    <span className={styles['title-container']}>
      <span className={styles.title}>
        <span data-testid="overview-title">
          {netAmount < 0 ? (
            <FormattedMessage id="cash-flow.overview.vat.debit.title" />
          ) : (
            <FormattedMessage id="cash-flow.overview.vat.credit.title" />
          )}
        </span>
        <span className={styles['inline-subtitle-divider']}>▪</span>
        <span className={styles['inline-subtitle']} data-testid="overview-subtitle">
          <FormattedMessage id="cash-flow.overview.vat.estimated-hint" />
        </span>
      </span>
      {vatData ? (
        <Tooltip label={<span dangerouslySetInnerHTML={{ __html: tooltipContent }} />} needsButton>
          <InfoIcon data-testid="overview-tooltip-trigger" height={12} width={12} />
        </Tooltip>
      ) : null}
    </span>
  );

  const amount = (
    <span className={styles['amount-container']} data-testid="overview-amount">
      {vatData && !isError ? (
        <FormattedNumber
          currency={vatData.netAmount.currency}
          signDisplay="never"
          style="currency"
          value={Number(vatData.netAmount.value)}
        />
      ) : (
        '--'
      )}
    </span>
  );

  return <CockpitTile amount={amount} isAmountLoading={isLoading} title={title} />;
}
