import { useCallback, useMemo, useState, type ReactElement } from 'react';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { LottiePlayer } from '@repo/design-system-kit';
import { KYC_STATUS } from 'qonto/constants/membership';
import { KYB_STATUS } from 'qonto/constants/organization';
import { OPERATION_TYPES } from 'qonto/constants/transfers';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './styles.strict-module.css';

interface SuccessProps {
  /** The amount of the transfer */
  amount: number;
  /** The currency of the transfer. Defaults to EUR */
  currency: string;
  /** The name of the beneficiary of the transfer */
  beneficiaryName: string;
  /** Whether the beneficiary's account is a Qonto account */
  isQontoBeneficiary: boolean;
  /** Whether the transfer is instant */
  isInstantTransfer: boolean;
  /** Whether the transfer is pending */
  isPending: boolean;
  /** Whether the transfer is scheduled */
  isScheduled: boolean;
  /** The type of transfer operation */
  operationType: (typeof OPERATION_TYPES)[keyof typeof OPERATION_TYPES];
  /** Whether to show the approval workflow promotion */
  showApprovalWorkflowPromotion: boolean;
}

export function Success({
  amount,
  currency = 'EUR',
  beneficiaryName,
  isQontoBeneficiary,
  isInstantTransfer,
  isPending,
  isScheduled,
  operationType,
  showApprovalWorkflowPromotion,
}: SuccessProps): ReactElement {
  const intl = useIntl();
  const { organization, membership } = useOrganizationManager();
  const [isAnimationReady, setIsAnimationReady] = useState<boolean>(false);

  const isKybPending = organization.kybStatus === KYB_STATUS.PENDING;
  const isKycPending = membership.kycStatus === KYC_STATUS.PENDING;

  const shouldDisplayPendingLottie =
    isKybPending || isKycPending || (isQontoBeneficiary && isPending && !isScheduled);

  const lottiePath = shouldDisplayPendingLottie
    ? '/lotties/transfers/timeout.json'
    : '/lotties/success.json';

  const playAnimation = (): void => {
    setIsAnimationReady(true);
  };

  const localisedAmount = useMemo(
    () => intl.formatNumber(amount, { style: 'currency', currency, currencyDisplay: 'code' }),
    [amount, currency, intl]
  );

  const getTitle = useCallback((): string => {
    if (operationType === OPERATION_TYPES.PAY_LATER) {
      return isInstantTransfer
        ? intl.formatMessage({ id: 'transfers.modals.instant.success.title' })
        : intl.formatMessage({ id: 'transfers.modals.success.regular.title' });
    }

    if (isInstantTransfer) {
      return showApprovalWorkflowPromotion
        ? intl.formatMessage({ id: 'transfers.modals.success.supplier-invoice.sent.title' })
        : intl.formatMessage({ id: 'transfers.modals.instant.success.title' });
    }

    if (isKycPending) {
      return intl.formatMessage({ id: 'transfers.modals.success.kyc-pending.title' });
    }

    if (isKybPending) {
      return isScheduled
        ? intl.formatMessage({ id: 'transfers.modals.success.kyb-pending.scheduled.title' })
        : intl.formatMessage({ id: 'transfers.modals.success.kyb-pending.title' });
    }

    if (isQontoBeneficiary) {
      if (isScheduled) {
        return intl.formatMessage({ id: 'transfers.modals.success.scheduled.title' });
      }

      if (isPending) {
        return intl.formatMessage({
          id: 'transfers.modals.success.qonto-beneficiary.pending.title',
        });
      }

      return intl.formatMessage({
        id: 'transfers.modals.success.qonto-beneficiary.title',
      });
    }

    if (showApprovalWorkflowPromotion) {
      return intl.formatMessage({
        id: 'transfers.modals.success.supplier-invoice.sent.title',
      });
    }

    return isScheduled
      ? intl.formatMessage({ id: 'transfers.modals.success.scheduled.title' })
      : intl.formatMessage({ id: 'transfers.modals.success.regular.title' });
  }, [
    operationType,
    isInstantTransfer,
    isKycPending,
    isKybPending,
    isQontoBeneficiary,
    isScheduled,
    intl,
    isPending,
    showApprovalWorkflowPromotion,
  ]);

  const getDescription = useCallback((): string => {
    if (operationType === OPERATION_TYPES.PAY_LATER) {
      return intl.formatMessage(
        {
          id: 'transfers.modals.sepa.success.pay-later.description',
        },
        {
          beneficiary: beneficiaryName,
          amount: localisedAmount,
        }
      );
    }

    if (isInstantTransfer) {
      let description;
      if (showApprovalWorkflowPromotion) {
        description = intl.formatMessage(
          { id: 'transfers.modals.success.supplier-invoice.sent.subtitle' },
          {
            beneficiary_name: beneficiaryName,
            amount_currency: localisedAmount,
          }
        );
      } else {
        description = intl.formatMessage(
          { id: 'transfers.modals.instant.success.description' },
          {
            beneficiary_name: beneficiaryName,
            amount_currency: localisedAmount,
          }
        );
      }
      return description;
    }

    if (isKycPending) {
      return intl.formatMessage({ id: 'transfers.modals.success.kyc-pending.description' });
    }

    if (isKybPending) {
      return isScheduled
        ? intl.formatMessage({ id: 'transfers.modals.success.kyb-pending.scheduled.description' })
        : intl.formatMessage({ id: 'transfers.modals.success.kyb-pending.description' });
    }

    if (isQontoBeneficiary) {
      if (isScheduled) {
        return intl.formatMessage({ id: 'transfers.modals.success.scheduled.description' });
      }

      if (isPending) {
        return intl.formatMessage({
          id: 'transfers.modals.success.qonto-beneficiary.pending.description',
        });
      }

      return intl.formatMessage(
        { id: 'transfers.modals.success.qonto-beneficiary.description' },
        {
          beneficiary_name: beneficiaryName,
          amount_currency: localisedAmount,
        }
      );
    }

    if (showApprovalWorkflowPromotion) {
      return isScheduled
        ? intl.formatMessage({
            id: 'transfers.modals.success.supplier-invoice.scheduled.subtitle',
          })
        : intl.formatMessage(
            {
              id: 'transfers.modals.success.supplier-invoice.normal.subtitle',
            },
            {
              beneficiary_name: beneficiaryName,
              amount_currency: localisedAmount,
            }
          );
    }

    return isScheduled
      ? intl.formatMessage({ id: 'transfers.modals.success.scheduled.description' })
      : intl.formatMessage(
          { id: 'transfers.modals.success.regular.description' },
          {
            beneficiary_name: beneficiaryName,
            amount_currency: localisedAmount,
          }
        );
  }, [
    operationType,
    isInstantTransfer,
    isKycPending,
    isKybPending,
    isQontoBeneficiary,
    isScheduled,
    intl,
    beneficiaryName,
    localisedAmount,
    isPending,
    showApprovalWorkflowPromotion,
  ]);

  return (
    <section className={styles.wrapper} data-test-transfers-sepa-settlement-success>
      {showApprovalWorkflowPromotion ? (
        <StaticThemedAsset
          assetPath="/illustrations/supplier-invoices/approval-workflow-promotion.svg"
          className={styles.illustration}
          data-test-approval-workflow-promotion-success-illustration=""
        />
      ) : (
        <LottiePlayer
          autoplay
          className={cx(
            'mb-32',
            shouldDisplayPendingLottie ? styles.pending : styles.success,
            !isAnimationReady && styles.hidden
          )}
          data-test-transfers-sepa-settlement-success-illustration={lottiePath}
          loop
          onDataReady={playAnimation}
          path={lottiePath}
        />
      )}

      {/* dangerouslySetInnerHTML is used to handle HTML tags in the title */}
      <h1
        className="title-1 mb-16"
        dangerouslySetInnerHTML={{ __html: getTitle() }}
        data-test-transfers-sepa-settlement-success-title
      />

      <p className="body-1" data-test-transfers-sepa-settlement-success-description>
        {getDescription()}
      </p>
    </section>
  );
}
