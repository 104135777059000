import { type ReactNode } from 'react';
import { Button } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import { IconCrossOutlined } from '@repo/monochrome-icons';
import { useCategoriesManagementRouting } from '../hooks/use-categories-management-routing';
import styles from './categories-management-side-panel.strict-module.css';

interface HeaderProps {
  isParentCategory?: boolean;
  hideTitle?: boolean;
}

export function Header({ isParentCategory = false, hideTitle = false }: HeaderProps): ReactNode {
  const { formatMessage } = useIntl();
  const { currentType, navigateToType } = useCategoriesManagementRouting();

  const title = isParentCategory
    ? formatMessage({ id: 'categories-management.create-category.sidepanel-header' })
    : formatMessage({ id: 'categories-management.create-subcategory.sidepanel-header' });

  const handleClose = (): void => {
    navigateToType(currentType);
  };

  return (
    <div className={styles.header} data-testid="categories-management-side-panel-header">
      <p className={styles.title}>{hideTitle ? '' : title}</p>
      <Button
        className={styles['close-button']}
        data-testid="close-button"
        iconOnly
        onPress={handleClose}
        size="medium"
        variant="tertiary"
      >
        <IconCrossOutlined />
      </Button>
    </div>
  );
}
