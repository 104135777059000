import { type PropsWithChildren, type ReactNode } from 'react';
import cx from 'clsx';
import { IconCheckmarkOutlined } from '@repo/monochrome-icons';
import styles from './account-feature-item.strict-module.css';

interface AccountFeatureItemProps extends PropsWithChildren {
  className?: string;
}

export function AccountFeatureItem({
  children,
  className,
  ...rest
}: AccountFeatureItemProps): ReactNode {
  return (
    <li className={cx(styles.feature, className)} data-testid="account-feature-item" {...rest}>
      <IconCheckmarkOutlined className={styles.check} data-testid="feature-icon" />
      <span className={styles['feature-title']} data-testid="feature-text">
        {children}
      </span>
    </li>
  );
}
