import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { DEFAULT_SORT_ORDER, SORT_ORDER } from 'qonto/constants/sort';
import { SortIcon } from 'qonto/react/components/paginated-table';
import styles from './header.strict-module.css';

interface HeaderProps {
  connectionNameField: string;
  memberNameField: string;
  grantedAtField: string;
  statusField: string;
  handleSortBy: (field: string, order: string) => void;
  sortBy?: string;
  isSidebarOpen?: boolean;
  tabsDisplayed?: boolean;
}

export function Header({
  connectionNameField,
  memberNameField,
  grantedAtField,
  statusField,
  handleSortBy,
  sortBy,
  isSidebarOpen,
  tabsDisplayed,
}: HeaderProps): ReactNode {
  const { formatMessage } = useIntl();

  const sortField = sortBy?.split(':')[0];
  const sortOrder = sortBy?.split(':')[1] || DEFAULT_SORT_ORDER;

  const handleSort = (field: string): void => {
    let order: 'asc' | 'desc' = DEFAULT_SORT_ORDER;

    if (field === sortField) {
      order = sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;
    }

    handleSortBy(field, order);
  };

  return (
    <>
      <th
        aria-hidden="true"
        className={cx(styles.empty, styles.cell, tabsDisplayed && styles['no-border'])}
        data-test-table-header=""
        scope="col"
      />

      <th
        className={cx(styles.cell, tabsDisplayed && styles['no-border'])}
        data-test-table-header-connection
        scope="col"
      >
        <button
          className={cx(styles.label, sortField === connectionNameField && styles.active)}
          onClick={() => {
            handleSort(connectionNameField);
          }}
          type="button"
        >
          {formatMessage({ id: 'settings.connections.table-header.connection' })}
          <SortIcon
            isActive={sortField === connectionNameField}
            isAscending={sortOrder === 'asc'}
          />
        </button>
      </th>

      <th
        className={cx(styles.cell, tabsDisplayed && styles['no-border'])}
        data-test-table-header-member
        scope="col"
      >
        <button
          className={cx(styles.label, sortField === memberNameField && styles.active)}
          onClick={() => {
            handleSort(memberNameField);
          }}
          type="button"
        >
          {formatMessage({ id: 'settings.connections.table-header.member' })}
          <SortIcon isActive={sortField === memberNameField} isAscending={sortOrder === 'asc'} />
        </button>
      </th>

      <th
        className={cx(
          styles.cell,
          tabsDisplayed && styles['no-border'],
          isSidebarOpen && styles.hidden
        )}
        data-test-table-header-activation-date
        scope="col"
      >
        <button
          className={cx(styles.label, sortField === grantedAtField && styles.active)}
          onClick={() => {
            handleSort(grantedAtField);
          }}
          type="button"
        >
          {formatMessage({ id: 'settings.connections.table-header.activation-date' })}
          <SortIcon isActive={sortField === grantedAtField} isAscending={sortOrder === 'asc'} />
        </button>
      </th>

      <th
        className={cx(styles.cell, tabsDisplayed && styles['no-border'])}
        data-test-table-header-status
        scope="col"
      >
        <button
          className={cx(styles.label, sortField === statusField && styles.active)}
          onClick={() => {
            handleSort(statusField);
          }}
          type="button"
        >
          {formatMessage({ id: 'settings.connections.table-header.status' })}
          <SortIcon isActive={sortField === statusField} isAscending={sortOrder === 'asc'} />
        </button>
      </th>

      <th
        aria-hidden="true"
        className={cx(styles.empty, styles.cell, tabsDisplayed && styles['no-border'])}
        scope="col"
      />
    </>
  );
}
