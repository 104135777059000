import type { ReactNode } from 'react';

export function ClockOutlined(): ReactNode {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 9.00001H11.5C11.78 9.00001 12 8.78001 12 8.50001C12 8.22001 11.78 8.00001 11.5 8.00001H8V3.51001C8 3.23001 7.78 3.01001 7.5 3.01001C7.22 3.01001 7 3.23001 7 3.51001V9.00001Z"
        fill="#636360"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.42 16 16 12.42 16 8C16 3.58 12.42 0 8 0C3.58 0 0 3.58 0 8C0 12.42 3.58 16 8 16ZM15 8C15 11.87 11.87 15 8 15C4.13 15 1 11.87 1 8C1 4.13 4.13 1 8 1C11.87 1 15 4.13 15 8Z"
        fill="#636360"
      />
    </svg>
  );
}
