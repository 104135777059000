/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import { type ReactNode } from 'react';
import cx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatMoney } from 'qonto/react/shared/utils/format-money';
import styles from './monthly-transfer-limit.strict-module.css';

interface MonthlyTransferLimitProps {
  currentMonthSpendings?: {
    value: string;
    currency: string;
  };
  monthlyTransferLimit?: {
    value: string;
    currency: string;
  };
}

export function MonthlyTransferLimit({
  currentMonthSpendings,
  monthlyTransferLimit,
  ...props
}: MonthlyTransferLimitProps): ReactNode {
  const intl = useIntl();

  const progress = monthlyTransferLimit
    ? Math.min(
        100,
        Math.floor(
          (100 / Number(monthlyTransferLimit.value)) * Number(currentMonthSpendings?.value)
        )
      )
    : 0;

  const isLimitClose = progress > 80;

  const monthlyAvailability = monthlyTransferLimit
    ? Number(monthlyTransferLimit.value) - Number(currentMonthSpendings?.value)
    : 0;

  return (
    <div {...props}>
      <div className={styles['monthly-transfer-limit-label']}>
        <div>
          <FormattedMessage id="members.labels.monthly-limit" />
        </div>
        {monthlyTransferLimit ? (
          <div>
            <span data-test-monthly-transfer-limit-current-month-spendings>
              {formatMoney(Number(currentMonthSpendings?.value), {
                locale: intl.locale,
                currency: currentMonthSpendings?.currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </span>
            /
            <span data-test-monthly-transfer-limit-monthly-transfer-limit>
              {formatMoney(Number(monthlyTransferLimit.value), {
                locale: intl.locale,
                currency: monthlyTransferLimit.currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </span>
          </div>
        ) : (
          <span data-test-monthly-transfer-limit-unlimited>
            <FormattedMessage id="members.labels.unlimited" />
          </span>
        )}
      </div>

      {monthlyTransferLimit ? (
        <>
          <div
            aria-hidden="true"
            className={styles['bar-container']}
            data-test-monthly-transfer-limit-bar-container
          >
            <div
              className={cx(styles.bar, isLimitClose && styles.danger)}
              data-test-monthly-transfer-limit-bar
              style={{ width: `${progress}%` }}
            />
          </div>

          <div className={cx('caption', styles['available-amount'])}>
            <span>
              <FormattedMessage id="members.labels.remaining-funds" />
            </span>
            <span className={styles.amount} data-test-monthly-transfer-limit-available-amount>
              {formatMoney(Number(monthlyAvailability), {
                locale: intl.locale,
                currency: monthlyTransferLimit.currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </span>
          </div>
        </>
      ) : null}
    </div>
  );
}
