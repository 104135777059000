import { type ReactNode } from 'react';
import { Button } from '@repo/design-system-kit';
import { IconPenOutlined } from '@repo/monochrome-icons';
import { useIntl } from 'react-intl';
import styles from './edit-vehicle.strict-module.css';

interface EditVehicleProps {
  vehicleType: string;
  vehiclePower?: string;
  isVehicleElectric?: boolean;
  onClick?: () => void;
}

export function EditVehicle({
  vehicleType,
  vehiclePower,
  isVehicleElectric,
  onClick,
}: EditVehicleProps): ReactNode {
  const { formatMessage } = useIntl();

  const getVehicleFiscalPower = (): string => {
    if (vehiclePower) {
      return formatMessage(
        {
          id: isVehicleElectric
            ? 'requests.mileage.steps.request-details.fiscal-power-electric'
            : 'requests.mileage.steps.request-details.fiscal-power',
        },
        {
          fiscal_power: vehiclePower,
        }
      );
    }
    if (isVehicleElectric) {
      return formatMessage({
        id: 'requests.mileage.steps.request-details.electric',
      });
    }

    return '';
  };

  return (
    <div className={styles['edit-vehicle']} data-test-edit-vehicle-card>
      <p className={styles.header}>
        <span className="body-1" data-test-edit-vehicle-card-vehicle-type>
          {vehicleType}
        </span>
        <span className="body-2" data-test-edit-vehicle-card-vehicle-power>
          {getVehicleFiscalPower()}
        </span>
      </p>

      <Button
        className="btn btn--icon-only btn--small"
        data-test-edit-vehicle-card-vehicle-edit-button
        iconOnly
        onPress={onClick}
      >
        <IconPenOutlined />
      </Button>
    </div>
  );
}
