import { type ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { Disclaimer, RadioGroup } from '@repo/design-system-kit';
import { default as APPEARANCE } from 'qonto/constants/appearance-setting';
import { ThemeSelectorItem } from '../theme-selector-item';
import styles from './theme-selector.strict-module.css';

const TRACKING_EVENT_NAMES = {
  [APPEARANCE.LIGHT]: 'appearance_light_selected',
  [APPEARANCE.DARK]: 'appearance_dark_selected',
  [APPEARANCE.SYSTEM]: 'appearance_automatic_selected',
};

interface ThemeSelectorProps {
  isAutomaticSupported?: boolean;
  setAppearance: (value: string) => void;
  currentAppearance: string;
}

export function ThemeSelector({
  isAutomaticSupported = true,
  setAppearance,
  currentAppearance,
}: ThemeSelectorProps): ReactNode {
  const { formatMessage: t } = useIntl();
  const segment = useEmberService('segment');
  const tandem = useEmberService('tandem');
  const [groupValue, setGroupValue] = useState<string>(currentAppearance);

  const handleCheck = (value: string): void => {
    const trackingEventName = TRACKING_EVENT_NAMES[value];
    setGroupValue(value);
    setAppearance(value);
    tandem.setTheme(value);
    segment.track(trackingEventName);
  };

  return (
    <>
      <p className="body-2 mb-32" data-test-appearance-page-subtitle id="theme-selector-group">
        {t({ id: 'users.profile.settings.appearance_subtitle' })}
      </p>
      <RadioGroup
        aria-labelledby="theme-selector-group"
        className={styles['theme-selector']}
        data-test-theme-selector-group
        onChange={handleCheck}
        value={groupValue}
      >
        <ThemeSelectorItem
          className="mr-24"
          data-test-light-mode-theme
          groupValue={groupValue}
          illustration="/illustrations/theme-selector/illu-light.svg"
          title={t({ id: 'users.profile.appearance.light' })}
          value={APPEARANCE.LIGHT}
        />

        <ThemeSelectorItem
          className="mr-24"
          data-test-dark-mode-theme
          groupValue={groupValue}
          illustration="/illustrations/theme-selector/illu-dark.svg"
          title={t({ id: 'users.profile.appearance.dark' })}
          value={APPEARANCE.DARK}
        />

        <div className={styles['container-auto']}>
          <fieldset className={styles.fieldset} data-test-fieldset disabled={!isAutomaticSupported}>
            <ThemeSelectorItem
              className={styles.automatic}
              data-test-automatic-mode-theme
              groupValue={groupValue}
              illustration="/illustrations/theme-selector/illu-auto.svg"
              title={t({ id: 'users.profile.appearance.automatic' })}
              value={APPEARANCE.SYSTEM}
            />
          </fieldset>

          {!isAutomaticSupported && (
            <Disclaimer.Inline
              className={styles.warning}
              data-test-automatic-mode-disclaimer
              level="warning"
            >
              <span>{t({ id: 'users.profile.appearance.old_browser_error' })}</span>
            </Disclaimer.Inline>
          )}
        </div>
      </RadioGroup>
    </>
  );
}
