/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import cx from 'clsx';
import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import styles from './styles.strict-module.css';

interface SupplierProps {
  supplierName: string;
  dueDate: string | null;
}

export function Supplier({ supplierName, dueDate }: SupplierProps): ReactNode {
  const { formatMessage, locale } = useIntl();

  return (
    <div data-test-task-cell-supplier>
      <div className={styles.title} data-test-supplier-cell-supplier-name>
        {supplierName}
      </div>
      {dueDate ? (
        <div className={cx(styles.subtitle, 'caption')} data-test-supplier-cell-due-date>
          {formatMessage({
            id: 'request.table.cell.from.due-on',
          })}{' '}
          {dateToken({ date: dueDate, locale, token: 'date-year-s' })}
        </div>
      ) : null}
    </div>
  );
}
