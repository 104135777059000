import { useEffect, useCallback, type ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSegment } from '@repo/poly-hooks/segment';
import { useEmberService, useFlags } from '@qonto/react-migration-toolkit/react/hooks';
import dayjs from 'dayjs';
import { useFetchTransaction } from 'qonto/react/hooks/use-fetch-transaction';
import { OperationMethod } from 'qonto/react/graphql';
import { SUGGESTED_TRANSACTIONS_POPUP_DISMISSED_STORAGE_KEY } from 'qonto/constants/supplier-invoice';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { useLinkTransactionAttachment } from 'qonto/react/hooks/mutations/use-link-attachment';
import { useMarkSupplierInvoiceAsPaid } from 'qonto/react/accounts-payable/hooks/api/use-mark-as-paid';
import { useOrganizationNavigation } from 'qonto/react/shared/hooks/use-organization-navigation';
import type { Transaction, TransactionResponse } from 'qonto/react/models/transaction';
import type { WithSuggestedTransactionHOC } from './types';

export const withSuggestedTransaction: WithSuggestedTransactionHOC = EnhancedComponent => {
  return function ComponentWithSuggestedTransaction(props): ReactElement {
    const { formatMessage } = useIntl();
    const toastFlashMessages = useEmberService('toast-flash-messages');
    const segment = useSegment();
    const { featureBooleanMatchTransactionModalMigration } = useFlags();
    const organizationNavigation = useOrganizationNavigation();
    const {
      transactionId,
      attachmentId,
      supplierInvoiceId,
      onMatchSuccess,
      setIsDismissed,
      origin = 'inbox',
    } = props;

    const {
      data: transactionPayload,
      isLoading,
      isError,
    } = useFetchTransaction<TransactionResponse>(transactionId);

    const dismissSuggestedTransaction = useCallback(() => {
      const dismissedSuggestions = JSON.parse(
        safeLocalStorage.getItem(SUGGESTED_TRANSACTIONS_POPUP_DISMISSED_STORAGE_KEY) || '[]'
      ) as string[];

      if (!dismissedSuggestions.includes(supplierInvoiceId)) {
        dismissedSuggestions.push(supplierInvoiceId);
        safeLocalStorage.setItem(
          SUGGESTED_TRANSACTIONS_POPUP_DISMISSED_STORAGE_KEY,
          JSON.stringify(dismissedSuggestions)
        );
      }
      setIsDismissed(true);
    }, [supplierInvoiceId, setIsDismissed]);

    useEffect(() => {
      if (isError) {
        dismissSuggestedTransaction();
        toastFlashMessages.toastError(
          formatMessage({ id: 'supplier-invoices.error-toast.suggested-transaction-fetch-error' })
        );
      }
    }, [isError, formatMessage, toastFlashMessages, dismissSuggestedTransaction]);

    const { mutateAsync: linkAttachment } = useLinkTransactionAttachment();
    const { mutateAsync: markAsPaid } = useMarkSupplierInvoiceAsPaid(supplierInvoiceId);

    const labelForOperationMethod = {
      [OperationMethod.Biller]: <FormattedMessage id="transactions.operation-types.biller" />,
      [OperationMethod.Card]: <FormattedMessage id="transactions.operation-types.card" />,
      [OperationMethod.CardAcquirerPayout]: (
        <FormattedMessage id="transactions.operation-types.card-acquirer-payout" />
      ),
      [OperationMethod.Cheque]: <FormattedMessage id="transactions.operation-types.cheque" />,
      [OperationMethod.DirectDebit]: (
        <FormattedMessage id="transactions.operation-types.direct-debit" />
      ),
      [OperationMethod.DirectDebitHold]: (
        <FormattedMessage id="transactions.operation-types.direct-debit-hold" />
      ),
      [OperationMethod.PagopaPayment]: (
        <FormattedMessage id="transactions.operation-types.pagopa-payment" />
      ),
      [OperationMethod.Tax]: <FormattedMessage id="transactions.operation-types.tax" />,
      [OperationMethod.Transfer]: <FormattedMessage id="transactions.operation-types.transfer" />,
      [OperationMethod.PayLater]: <FormattedMessage id="transactions.operation-types.pay-later" />,
      [OperationMethod.RibaPayment]: <FormattedMessage id="transactions.operation-types.riba" />,
      [OperationMethod.Other]: <FormattedMessage id="transactions.operation-types.unknown" />,
    };

    const suggestedTransaction = {
      localAmount: transactionPayload?.localAmount,
      localAmountCurrency: transactionPayload?.localAmountCurrency,
      settledAt: transactionPayload?.settledAt,
      counterpartyName: transactionPayload?.counterpartyName,
      localizedOperationMethod:
        labelForOperationMethod[
          transactionPayload?.operationMethod.toUpperCase() as OperationMethod
        ],
    };

    const handleDismissSuggestion = (): void => {
      segment.track('supplier-invoices_partial_match-button_dismiss_clicked', {
        origin,
      });
      dismissSuggestedTransaction();
      toastFlashMessages.toastSuccess(
        formatMessage({ id: 'supplier-invoices.success-toast.ignore-suggested-transaction' })
      );
    };

    const handleMatchTransaction = async (): Promise<void> => {
      try {
        await linkAttachment({ transactionId, attachmentId });
        await markAsPaid(dayjs(suggestedTransaction.settledAt).format('YYYY-MM-DD'));
        onMatchSuccess?.();
      } catch (error) {
        toastFlashMessages.toastError(
          formatMessage({ id: 'supplier-invoices.error-toast.suggested-transaction-match-error' })
        );
      }
    };

    const handleViewAllTransactions = async (): Promise<void> => {
      segment.track('supplier-invoices_partial_match-button_view-transactions_clicked', {
        origin,
      });

      if (featureBooleanMatchTransactionModalMigration) {
        await organizationNavigation(
          `/supplier-invoices/list/match-transaction?invoice_id=${supplierInvoiceId}&origin=${origin === 'inbox' ? 'supplier-invoices.list' : ''}`
        );
        return;
      }

      await organizationNavigation(
        `/supplier-invoices/${supplierInvoiceId}/match-transactions?origin=${origin}`
      );
    };

    return (
      <EnhancedComponent
        {...props}
        handleDismissSuggestion={handleDismissSuggestion}
        handleMatchTransaction={handleMatchTransaction}
        handleViewAllTransactions={handleViewAllTransactions}
        isLoading={isLoading}
        transaction={suggestedTransaction as Transaction}
      />
    );
  };
};
