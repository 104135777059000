import type { ReactNode, ComponentPropsWithoutRef } from 'react';
import { useIntl } from 'react-intl';
import { IconPenOutlined } from '@repo/monochrome-icons';
import { Tooltip, Button } from '@repo/design-system-kit';
import { formatMoney } from 'qonto/react/shared/utils/format-money';
import styles from './payment-details-card.strict-module.css';

interface Amount {
  value: string;
  currency: string;
}

interface PaymentDetailsCardProps extends ComponentPropsWithoutRef<'div'> {
  supplierName: string;
  payableAmount: Amount;
  iban: string;
  paymentReference: string;
  onEditClick?: () => void;
}

export function PaymentDetailsCard({
  supplierName,
  payableAmount,
  iban,
  paymentReference,
  onEditClick,
  ...props
}: PaymentDetailsCardProps): ReactNode {
  const { locale, formatMessage } = useIntl();

  return (
    <div className={styles.card} {...props}>
      <div className={styles.header}>
        <div className={styles.details}>
          <div className={styles['supplier-name']} data-testid="supplier-name">
            <span>
              {formatMessage(
                { id: 'transfers.pay-by-invoice.payment-details.supplier.body' },
                { supplierName: '' }
              )}
            </span>
            <span>{supplierName}</span>
          </div>
          <div className="title-3" data-testid="payable-amount">
            {formatMoney(Number(payableAmount.value), {
              currency: payableAmount.currency,
              locale,
            })}
          </div>
          <div className="body-2" data-testid="iban">
            {iban}
          </div>
        </div>
        <Tooltip
          label={formatMessage({ id: 'transfers.pay-by-invoice.payment-details.edit.tooltip' })}
          placement="top left"
          tooltipTestId="edit-button-tooltip"
        >
          <Button
            className={styles['edit-button']}
            data-testid="edit-button"
            onPress={onEditClick}
            type="button"
          >
            <IconPenOutlined />
          </Button>
        </Tooltip>
      </div>
      <div className={styles.footer} data-testid="payment-reference">
        {paymentReference}
      </div>
    </div>
  );
}
