/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import { dateToken, Flag, type CountryCode } from '@repo/design-system-kit';
import cx from 'clsx';
import { type ComponentPropsWithoutRef, type ReactNode } from 'react';
import { Separator } from 'react-aria-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { CURRENCIES } from 'qonto/constants/international-out/currency';
import type { BankAccountModel as BankAccount } from 'qonto/react/models/bank-account';
import {
  type Beneficiary,
  type Fees,
  type Quote,
  type SchedulingOptions,
  StandingFrequency,
} from 'qonto/services/international-out/types';
import { getAllowedDecimalPlaces } from 'qonto/utils/currency';
import {
  formatAccountInformation,
  formatExchangeRateWithDetails,
} from 'qonto/utils/international-out/format';
import { isSwiftNetwork, isSwiftSha } from 'qonto/utils/international-out/quote';
import { STANDING_FREQUENCIES } from 'qonto/constants/international-out/schedule';
import styles from './summary.strict-module.css';
import { Description, Item, List, Term } from './description-list';

function Amount({
  value,
  currency,
  minimumFractionDigits,
  maximumFractionDigits,
}: {
  value: number;
  currency: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}): ReactNode {
  const { formatNumber } = useIntl();

  const formattedNumber = formatNumber(value, {
    style: 'decimal',
    minimumFractionDigits: minimumFractionDigits ?? getAllowedDecimalPlaces(currency),
    maximumFractionDigits: maximumFractionDigits ?? getAllowedDecimalPlaces(currency),
  });

  // To respect the design specifications, we specifically want to display the currency code after the amount, regardless of the locale.
  // That's why we can't use directly the `FormattedNumber` component (from `react-intl`) with the `currencyDisplay` prop set to `code`.
  return (
    <>
      {formattedNumber}&nbsp;{currency}
    </>
  );
}

interface SummaryProps extends ComponentPropsWithoutRef<'dl'> {
  account: Pick<BankAccount, 'authorizedBalance' | 'balanceCurrency' | 'name'>;
  beneficiary: Omit<Beneficiary, 'id' | 'paymentType' | 'branchIdentifier'>;
  budgetName?: string | null;
  fees: Fees;
  quote: Pick<
    Quote,
    | 'formattedEstimatedDelivery'
    | 'payOut'
    | 'rate'
    | 'sourceAmount'
    | 'sourceCurrency'
    | 'targetAmount'
    | 'targetCurrency'
  >;
  schedule?: SchedulingOptions | null;
}

export function Summary({
  account,
  beneficiary,
  budgetName,
  fees,
  quote,
  schedule,
  ...props
}: SummaryProps): ReactNode {
  const { formatMessage, locale } = useIntl();

  const exchangeRate = formatExchangeRateWithDetails(quote.rate);
  const isUnity = quote.sourceCurrency === quote.targetCurrency && quote.rate === 1;
  const isSwift = isSwiftNetwork(quote);
  const isSha = isSwiftSha(quote);
  const isShaEuro = isSha && quote.targetCurrency === CURRENCIES.EUR;
  const isRecurring = Boolean(schedule && schedule.standingFrequency !== StandingFrequency.None);
  const isOneOff = Boolean(schedule?.startDate && !isRecurring);

  const getFeeBreakdown = (): string => {
    const getSwiftMessage = (): string => {
      if (isShaEuro) {
        return formatMessage({
          id: 'international-out.summary.tooltips.fees.sha-eur',
        });
      }

      if (fees.minimum > 0) {
        return formatMessage(
          {
            id: 'international-out.summary.tooltips.fees.local',
          },
          {
            pricingFee: fees.variable,
            minimumFee: fees.minimum,
          }
        );
      }

      return formatMessage(
        {
          id: 'international-out.summary.tooltips.fees.generic',
        },
        {
          pricingFee: fees.variable,
        }
      );
    };

    const getLocalMessage = (): string => {
      return formatMessage(
        {
          id: 'international-out.summary.tooltips.fees.local',
        },
        {
          pricingFee: fees.variable,
          minimumFee: fees.minimum,
        }
      );
    };

    return isSwift ? getSwiftMessage() : getLocalMessage();
  };

  const computeFeeAmount = (): number => {
    const { fix, total } = fees;

    if (isSwift && !isShaEuro && fix > 0) {
      return total - fix;
    }

    return total;
  };

  return (
    <List data-test-summary {...props}>
      <Item data-test-account>
        <Term>
          <FormattedMessage id="international-out.summary.account-to-debit" />
        </Term>

        <Description>
          <p data-test-account-name>{account.name}</p>

          {account.authorizedBalance !== undefined && account.balanceCurrency ? (
            <p className={cx('body-2', styles['color-secondary'])} data-test-account-balance>
              <Amount currency={account.balanceCurrency} value={account.authorizedBalance} />
            </p>
          ) : null}
        </Description>
      </Item>

      <Item data-test-beneficiary>
        <Term>
          <FormattedMessage id="international-out.summary.beneficiary" />
        </Term>

        <Description>
          <p className={styles['beneficiary-information']}>
            <span className={styles['beneficiary-source']}>
              <Flag
                code={beneficiary.country as CountryCode}
                data-test-beneficiary-country={beneficiary.country}
                size="small"
              />

              <span
                className={cx(
                  styles['beneficiary-currency'],
                  styles['color-secondary'],
                  'caption',
                  'ml-8'
                )}
                data-test-beneficiary-currency
              >
                {beneficiary.currency}
              </span>
            </span>

            <span className={cx('ml-8', styles['break-word'])} data-test-beneficiary-name>
              {beneficiary.name}
            </span>
          </p>

          <p
            className={cx('body-2', styles['break-word'])}
            data-test-beneficiary-account-identifier
          >
            {formatAccountInformation(beneficiary)}
          </p>
        </Description>
      </Item>

      {budgetName ? (
        <Item data-test-budget>
          <Term
            hint={formatMessage({
              id: 'international-out.summary.tooltips.budget',
            })}
          >
            <FormattedMessage id="international-out.summary.budget" />
          </Term>

          <Description data-test-budget-name>{budgetName}</Description>
        </Item>
      ) : null}

      <Separator className={styles.separator} />

      <Item data-test-amount>
        <Term>
          <FormattedMessage id="international-out.summary.beneficiary-receives" />
        </Term>

        <Description>
          <p data-test-amount-target>
            <Amount currency={quote.targetCurrency} value={quote.targetAmount} />
          </p>

          {!isUnity && (
            <p className={cx('body-2', styles['color-secondary'])} data-test-amount-source>
              <Amount currency={quote.sourceCurrency} value={quote.sourceAmount} />
            </p>
          )}
        </Description>
      </Item>

      {!isUnity && (
        <Item data-test-exchange-rate>
          <Term>
            <FormattedMessage id="international-out.summary.exchange-rate" />
          </Term>

          <Description>
            <Amount
              currency={quote.sourceCurrency}
              maximumFractionDigits={0}
              minimumFractionDigits={0}
              value={1}
            />
            &nbsp;=&nbsp;
            <Amount
              currency={quote.targetCurrency}
              maximumFractionDigits={20}
              minimumFractionDigits={exchangeRate.decimalPlacesCount}
              value={Number(exchangeRate.value)}
            />
          </Description>
        </Item>
      )}

      {Boolean(isSwift) && !isShaEuro && fees.fix > 0 && (
        <Item data-test-swift-fee>
          <Term
            hint={formatMessage({
              id: `international-out.summary.tooltips.fees.${isSha ? 'sha' : 'our'}`,
            })}
          >
            <FormattedMessage id="international-out.summary.swift-fee" />
          </Term>

          <Description>
            <Amount currency={quote.sourceCurrency} value={fees.fix} />
          </Description>
        </Item>
      )}

      <Item data-test-fee>
        <Term hint={getFeeBreakdown()}>
          <FormattedMessage id="international-out.summary.transfer-fee" />
        </Term>

        <Description>
          <Amount currency={quote.sourceCurrency} value={computeFeeAmount()} />
        </Description>
      </Item>

      <Separator className={styles.separator} />

      {isRecurring ? (
        <>
          {schedule?.startDate ? (
            <Item data-test-start-date>
              <Term>
                <FormattedMessage id="international-out.summary.schedule.start-date" />
              </Term>

              <Description>
                {dateToken({
                  date: schedule.startDate,
                  locale,
                })}
              </Description>
            </Item>
          ) : null}

          {schedule?.standingFrequency ? (
            <Item data-test-frequency>
              <Term>
                <FormattedMessage id="international-out.summary.schedule.frequency" />
              </Term>

              <Description capitalized>
                <FormattedMessage
                  id="international-out.summary.schedule.frequency-value"
                  values={{
                    frequency: (
                      <FormattedMessage
                        id={`international-out.schedule.frequency.${STANDING_FREQUENCIES[schedule.standingFrequency]}`}
                      />
                    ),
                    endDate: schedule.endDate
                      ? dateToken({
                          date: schedule.endDate,
                          locale,
                        })
                      : undefined,
                  }}
                />
              </Description>
            </Item>
          ) : null}

          <Separator className={styles.separator} />
        </>
      ) : null}

      {!isRecurring && (
        <Item data-test-date>
          <Term>
            <FormattedMessage
              id={`international-out.summary.${isOneOff ? 'schedule.date' : 'eta'}`}
            />
          </Term>

          <Description capitalized>
            {isOneOff && schedule?.startDate
              ? dateToken({
                  date: schedule.startDate,
                  locale,
                })
              : quote.formattedEstimatedDelivery}
          </Description>
        </Item>
      )}

      <Item data-test-total-amount highlighted>
        <Term>
          <FormattedMessage id="international-out.summary.total-amount" />
        </Term>

        <Description>
          <Amount currency={quote.sourceCurrency} value={quote.sourceAmount + fees.total} />
          {isRecurring ? (
            <p className={cx('body-2', styles['color-secondary'])} data-test-each>
              <FormattedMessage id="international-out.summary.schedule.each-transfer" />
            </p>
          ) : null}
        </Description>
      </Item>
    </List>
  );
}
