import { useEffect, useState, useRef } from 'react';
import { useSegment } from '@repo/poly-hooks/segment';
import { useIntl } from 'react-intl';
import { Button, Popup } from '@repo/design-system-kit';
import ENV from 'qonto/config/environment';
import { AP_ADDON_MESSAGING_POPUP_DISMISSED_KEY } from 'qonto/constants/supplier-invoice';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

interface AddOnPromoPopupProps {
  confirmCta: string; // copy string
  subtitle: string; // copy string
  title: string; // copy string
  userSegment: string;
  variant: string;
  onConfirm: () => void;
}

const isTestEnv = ENV.environment === 'test';
const popupDelay = isTestEnv ? 0 : 2000;

export function AddOnPromoPopup({
  confirmCta,
  subtitle,
  title,
  userSegment,
  variant,
  onConfirm,
}: AddOnPromoPopupProps): React.ReactElement {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const segment = useSegment();
  const { formatMessage } = useIntl();
  const confirmCTA = formatMessage({ id: confirmCta });
  const displayTime = useRef(0);
  const TEST_PORTAL_CONTAINER =
    document.getElementById('ember-testing') ?? document.querySelector('main') ?? undefined;

  // Track event when the popup is displayed
  useEffect(() => {
    if (safeLocalStorage.getItem(AP_ADDON_MESSAGING_POPUP_DISMISSED_KEY) === 'true') return;

    const timeout = setTimeout(() => {
      setIsPopupVisible(true);
      displayTime.current = Date.now();
      segment.track('suppliers_invoices_test_modal_displayed', {
        variant,
        user_segment: userSegment,
      });
    }, popupDelay);

    return () => {
      clearTimeout(timeout);
    };
  }, [segment, variant, userSegment]);

  const handleClickAction = (action: 'cta_click' | 'close'): void => {
    safeLocalStorage.setItem(AP_ADDON_MESSAGING_POPUP_DISMISSED_KEY, 'true');

    segment.track('suppliers_invoices_test_modal_clicked', {
      variant,
      action_type: action,
      time_to_action: (Date.now() - displayTime.current) / 1000, // in seconds
    });
    setIsPopupVisible(false);
  };

  const handleDismiss = (): void => {
    handleClickAction('close');
  };

  const handleConfirm = (): void => {
    handleClickAction('cta_click');
    onConfirm();
  };

  return (
    <Popup.Root
      data-test-id="add-on-messaging-promo-popup"
      isOpen={isPopupVisible}
      portalContainer={isTestEnv ? TEST_PORTAL_CONTAINER : undefined}
    >
      <Popup.CloseButton onPress={handleDismiss} />
      <Popup.Illustration isPromotional>
        <img
          alt=""
          data-test-id="add-on-messaging-promo-popup-illustration"
          src="/illustrations/supplier-invoices/addon-messaging-promo-popup/ap_add-on_test_1X.png"
          srcSet="/illustrations/supplier-invoices/addon-messaging-promo-popup/ap_add-on_test_2X.png"
        />
      </Popup.Illustration>
      <Popup.Header>
        <Popup.Title>{formatMessage({ id: title })}</Popup.Title>
        <Popup.Subtitle>{formatMessage({ id: subtitle })}</Popup.Subtitle>
      </Popup.Header>
      <Popup.Body />
      <Popup.Footer>
        <Button
          aria-label={confirmCTA}
          data-test-id="add-on-cta-button"
          onPress={handleConfirm}
          variant="primary"
        >
          {confirmCTA}
        </Button>
      </Popup.Footer>
    </Popup.Root>
  );
}
