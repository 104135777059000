/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import { type ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { isNil } from 'es-toolkit';
import { BaseCell } from 'qonto/react/components/table-v2/cells/base-cell';
import { VAT_STATUS } from 'qonto/constants/vat';
import { cellContextManager } from 'qonto/react/contexts/cell-context';
import { Status, type VatStatus } from 'qonto/react/graphql';
import { VAT_CELL_CLICK_EVENT_NAME } from 'qonto/react/constants';
import { VATCellPopover } from './popover';
import styles from './styles.strict-module.css';

export function VATCell(): ReactNode {
  const segment = useEmberService('segment');
  const transaction = cellContextManager.useCellContext();
  const { formatNumber } = useIntl();
  const { vat, id, status } = transaction;

  const isDeclined = status === Status.Declined || status === Status.Reversed;

  const rates: string = vat.items
    .filter(Boolean)
    .map(item => (isNil(item.rate) ? '-' : `${item.rate}%`))
    .join(', ');

  if (isDeclined) {
    return (
      <BaseCell className={styles.container}>
        <span className={styles['empty-state']} data-testid="vat-cell-empty">
          -
        </span>
      </BaseCell>
    );
  }
  const trackCellOpen = (isOpen: boolean): void => {
    if (isOpen) {
      segment.track(VAT_CELL_CLICK_EVENT_NAME);
    }
  };

  return (
    <BaseCell
      data-testid="vat-cell"
      onOpenChange={trackCellOpen}
      popoverClassName={styles.container}
      popoverSlot={<VATCellPopover transactionId={id} vat={vat} />}
    >
      {vat.items.length === 0 ? (
        <p className={styles['empty-state']} data-testid="vat-cell-no-vat">
          <FormattedMessage
            id={
              vat.status === (VAT_STATUS.undetected as VatStatus)
                ? 'transactions.modular_table.vat.status.title.not-detected'
                : 'transactions.modular_table.vat.status.title.no-vat'
            }
          />
        </p>
      ) : (
        <>
          <span className={styles['total-amount']} data-testid="vat-total-amount">
            {formatNumber(Number(vat.totalAmount.value), {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{' '}
            {vat.totalAmount.currency}
          </span>
          {rates ? (
            <span className={styles.rates} data-testid="vat-rates">
              {rates}
            </span>
          ) : null}
        </>
      )}
    </BaseCell>
  );
}
