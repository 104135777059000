/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import { Button, Tooltip } from '@repo/design-system-kit';
import cx from 'clsx';
import {
  Children,
  cloneElement,
  isValidElement,
  type ComponentPropsWithoutRef,
  type ReactNode,
} from 'react';
import { Info } from 'qonto/react/assets/icons/info';
import styles from './description-list.strict-module.css';

interface HighlightableProps {
  highlighted?: boolean;
}

export function Term({
  children,
  className,
  capitalized = false,
  highlighted = false,
  hint,
  ...props
}: ComponentPropsWithoutRef<'dt'> &
  HighlightableProps & { capitalized?: boolean; hint?: string }): ReactNode {
  return (
    <dt
      className={cx(
        styles.term,
        highlighted ? 'title-4' : 'body-2',
        capitalized && styles.capitalize,
        className
      )}
      data-test-label
      {...props}
    >
      {children}
      {hint ? (
        <Tooltip label={hint} placement="top" tooltipTestId="tooltip">
          <Button aria-label="tooltip" className={cx(styles.tooltip, 'ml-8')} data-test-tooltip>
            <Info className={styles.icon} />
          </Button>
        </Tooltip>
      ) : null}
    </dt>
  );
}

export function Description({
  children,
  className,
  capitalized = false,
  highlighted = false,
  ...props
}: ComponentPropsWithoutRef<'dd'> & HighlightableProps & { capitalized?: boolean }): ReactNode {
  return (
    <dd
      className={cx(
        styles.description,
        highlighted ? 'title-4' : 'body-1',
        capitalized && styles.capitalize,
        className
      )}
      data-test-value
      {...props}
    >
      {children}
    </dd>
  );
}

export function Item({
  children,
  className,
  highlighted = false,
  ...props
}: ComponentPropsWithoutRef<'div'> & HighlightableProps): ReactNode {
  return (
    <div className={cx(styles.item, className)} data-test-item {...props}>
      {Children.map(children, child =>
        isValidElement<HighlightableProps>(child) ? cloneElement(child, { highlighted }) : child
      )}
    </div>
  );
}

export function List({ children, className, ...props }: ComponentPropsWithoutRef<'dl'>): ReactNode {
  return (
    <dl className={cx(styles.list, className)} data-test-list {...props}>
      {children}
    </dl>
  );
}
