import { type HTMLAttributes, type ReactNode } from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import cx from 'clsx';
import styles from './styles.strict-module.css';

export function PlaceholderRow({
  className,
  ...props
}: HTMLAttributes<HTMLTableRowElement>): ReactNode {
  return (
    <tr className={cx(styles.row, className)} {...props}>
      <td aria-hidden="true" className="spacer" />

      <td className={styles.cell} data-test-products-table-placeholder-title="">
        <SkeletonLoader.Line className={cx('mb-8', styles.placeholder)} standalone width="236px" />
      </td>

      <td className={styles.cell} data-test-products-table-placeholder-internal-note>
        <SkeletonLoader.Line className={cx('mb-8', styles.placeholder)} standalone width="220px" />
      </td>

      <td className={styles.cell} data-test-products-table-placeholder-unit>
        <SkeletonLoader.Line className={cx('mb-8', styles.placeholder)} standalone width="104px" />
      </td>

      <td className={cx(styles.cell, styles.price)} data-test-products-table-placeholder-price>
        <SkeletonLoader.Line className={cx('mb-8', styles.placeholder)} standalone width="124px" />
      </td>

      <td aria-hidden="true" className={styles.cell} />
      <td aria-hidden="true" className="spacer" />
    </tr>
  );
}
