import React, { type HTMLAttributes } from 'react';
import cx from 'clsx';
import styles from './styles.strict-module.css';

export function General({
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>): React.ReactElement {
  return (
    <div className={cx(styles.container, 'header-section')} data-test-general {...props}>
      {children}
    </div>
  );
}
