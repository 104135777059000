import type { ReactNode, ReactElement } from 'react';
import {
  Sidebar as BaseSidebar,
  type SidebarProps as BaseSidebarProps,
  type SidebarSlots,
} from 'qonto/react/components/sidebar';
import type RibaPaymentModel from 'qonto/models/riba-payment';
import { RIBA_STATUS } from 'qonto/constants/riba';
import { Header } from './header';
import { Details } from './details';
import { Timeline } from './timeline';
import { Footer } from './footer';
import { ProofOfPayment } from './proof-of-payment';

export interface BankAccount {
  id: string;
  name: string;
  balance: number;
  balanceCurrency: string;
}

interface SidebarProps extends BaseSidebarProps {
  payment: RibaPaymentModel;
  activeAccounts?: BankAccount[];
  defaultAccountId?: string;
  onApprove: (bankAccountId: string, paymentIds: string[]) => void;
  onReject: (bankAccountId: string, paymentIds: string[]) => void;
  isApproveRunning?: boolean;
  isRejectRunning?: boolean;
}

function getFooterComponent(
  payment: RibaPaymentModel,
  props: {
    activeAccounts?: BankAccount[];
    defaultAccountId?: string;
    onApprove?: (bankAccountId: string, paymentIds: string[]) => void;
    onReject?: (bankAccountId: string, paymentIds: string[]) => void;
    isApproveRunning?: boolean;
    isRejectRunning?: boolean;
  }
): ReactElement | undefined {
  if (payment.status === RIBA_STATUS.PENDING) {
    return (
      <Footer
        activeAccounts={props.activeAccounts}
        defaultAccountId={props.defaultAccountId}
        isApproveRunning={props.isApproveRunning}
        isRejectRunning={props.isRejectRunning}
        onApprove={bankAccountId => props.onApprove?.(bankAccountId, [payment.id])}
        onReject={bankAccountId => props.onReject?.(bankAccountId, [payment.id])}
      />
    );
  }

  if (payment.receiptUrl) {
    return <ProofOfPayment payment={payment} />;
  }

  return undefined;
}

function getSidebarSlots(
  payment?: RibaPaymentModel,
  activeAccounts?: BankAccount[],
  defaultAccountId?: string,
  onApprove?: (bankAccountId: string, paymentIds: string[]) => void,
  onReject?: (bankAccountId: string, paymentIds: string[]) => void,
  isApproveRunning?: boolean,
  isRejectRunning?: boolean
): SidebarSlots | undefined {
  if (!payment) {
    return undefined;
  }

  const contents = [
    <Details key="payment-details" payment={payment} />,
    // @ts-expect-error TODO: improve types
    <Timeline key="payment-timeline" onApprove={onApprove} onReject={onReject} payment={payment} />,
  ];

  return {
    header: <Header payment={payment} />,
    contents,
    footer: getFooterComponent(payment, {
      activeAccounts,
      defaultAccountId,
      onApprove,
      onReject,
      isApproveRunning,
      isRejectRunning,
    }),
  };
}

export function Sidebar({
  isOpened,
  onClose,
  payment,
  title,
  activeAccounts,
  defaultAccountId,
  onApprove,
  onReject,
  isApproveRunning,
  isRejectRunning,
}: SidebarProps): ReactNode {
  return (
    <BaseSidebar
      isOpened={isOpened}
      onClose={onClose}
      slots={getSidebarSlots(
        payment,
        activeAccounts,
        defaultAccountId,
        onApprove,
        onReject,
        isApproveRunning,
        isRejectRunning
      )}
      title={title}
    />
  );
}
