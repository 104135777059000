import { type ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { BankAccountsSelectStep } from 'qonto/react/components/bank-accounts/select-step';
import type BankAccount from 'qonto/models/bank-account';

interface FlowsChecksAccountProps {
  setAccount: (account: BankAccount) => void;
  transitionToNext: () => void;
}

export function FlowsChecksAccount({
  setAccount,
  transitionToNext,
}: FlowsChecksAccountProps): ReactElement {
  const { formatMessage } = useIntl();

  return (
    <BankAccountsSelectStep
      setAccount={setAccount}
      subtitle={formatMessage({ id: 'checks.choose-account.subtitle' })}
      title={formatMessage({ id: 'checks.choose-account.title' })}
      transitionToNext={transitionToNext}
    />
  );
}
