import * as React from 'react';

export function Illustration(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg fill="none" height={224} width={504} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0 0h504v216H0z" fill="#F4F8AC" />
      <path
        d="M275.163 17.2 177.84 73.442c-2.147 1.24-3.887 4.255-3.887 6.735l-.002 33.326v81.999c0 1.389.557 2.378 1.508 2.679l1.84.583c.696.221 1.555.056 2.435-.466l95.331-56.578c2.126-1.261 3.835-4.248 3.835-6.703l.005-82.424 1.619-32.13c.069-1.372-.378-2.412-1.222-2.838l-1.478-.747c-.721-.364-1.672-.25-2.661.322Z"
        fill="#1D1D1B"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeWidth={0.58}
      />
      <path
        clipRule="evenodd"
        d="M277.107 142.658c2.147-1.24 3.888-4.256 3.889-6.735l.002-38.936.003-43.426-36.432 21.144-34.261 19.883-34.261 19.883-.003 43.269-.003 38.778c0 2.48 1.741 3.485 3.889 2.245l30.375-17.537 34.264-19.782 32.538-18.786Z"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        d="M281.003 20.551c0-2.48-1.741-3.485-3.889-2.245l-97.176 56.105c-2.148 1.24-3.889 4.256-3.889 6.736l-.002 33.324 34.261-19.883 34.261-19.883 36.432-21.144.002-33.01Z"
        fill="#F4F8AC"
      />
      <path
        d="m176.047 114.471.002-33.324c0-2.48 1.741-5.496 3.889-6.736l97.177-56.105c2.147-1.24 3.888-.235 3.888 2.245l-.002 33.01m-104.954 60.91 34.261-19.883m-34.261 19.883-.003 43.269M281.001 53.561l-36.432 21.144m36.432-21.144-.003 43.426m-36.429-22.282v86.739m0-86.74-34.261 19.884m34.261 66.856 32.538-18.786c2.147-1.24 3.888-4.256 3.889-6.735l.002-38.936m-36.429 64.457-34.264 19.782m.003-86.638-.003 86.638m0 0-30.375 17.537c-2.148 1.24-3.889.235-3.889-2.245l.003-38.778m0 0 104.954-60.753"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeWidth={0.58}
      />
      <mask
        height={183}
        id="a"
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'alpha',
        }}
        width={107}
        x={175}
        y={17}
      >
        <path
          clipRule="evenodd"
          d="M277.106 142.657c2.148-1.24 3.889-4.255 3.889-6.735l.003-38.936.003-43.426-36.433 21.144-34.26 19.883-34.261 19.884-.003 43.268-.003 38.778c0 2.48 1.741 3.485 3.889 2.245l30.375-17.537 34.263-19.782 32.538-18.786Z"
          fill="#fff"
          fillRule="evenodd"
        />
        <path
          d="M281.003 20.55c0-2.48-1.741-3.485-3.889-2.245L179.938 74.41c-2.148 1.24-3.889 4.256-3.889 6.736l-.002 33.325 34.261-19.884 34.26-19.883 36.433-21.144.002-33.01Z"
          fill="#F4F8AC"
        />
        <path
          d="m176.047 114.471.002-33.325c0-2.48 1.741-5.496 3.889-6.736l97.176-56.105c2.148-1.24 3.889-.234 3.889 2.245l-.002 33.01m-104.954 60.911 34.261-19.884m-34.261 19.884-.003 43.268M281.001 53.56l-36.433 21.144m36.433-21.144-.003 43.426m-36.43-22.282v86.739m0-86.739-34.26 19.883m34.26 66.856 32.538-18.786c2.148-1.24 3.889-4.255 3.889-6.735l.003-38.936m-36.43 64.457-34.263 19.782m.003-86.638-.003 86.638m0 0-30.375 17.537c-2.148 1.24-3.889.235-3.889-2.245l.003-38.778m0 0 104.954-60.753"
          stroke="#050505"
          strokeLinecap="round"
          strokeWidth={0.58}
        />
      </mask>
      <g fill="#1D1D1B" mask="url(#a)">
        <path d="M273.601 148.395c-10.647 6.147-19.278 4.832-19.278-2.936s8.631-19.049 19.278-25.196c10.647-6.147 19.278-4.833 19.278 2.936 0 7.768-8.631 19.049-19.278 25.196Z" />
        <path d="M254.326 145.089c-.146 3.785 1.753 6.391 5.55 7.16 7.448 1.252 19.716-5.831 27.164-15.683 3.651-4.7 5.842-10.221 5.842-13.738l-.008 24.834c0 5.552-5.104 18.493-19.562 26.841-14.458 8.347-18.993.971-18.993-4.581l.007-24.833Z" />
        <path d="M254.326 145.089h.46a.46.46 0 0 0-.92-.018l.46.018Zm5.55 7.16-.091.451.015.003.076-.454Zm27.164-15.683-.363-.283-.004.005.367.278Zm5.842-13.738h.46a.46.46 0 0 0-.92 0h.46Zm-.008 24.834h-.46.46Zm-38.555 22.26h-.461.461Zm-.456-24.463c0 2.029.565 3.683 1.628 4.903 1.062 1.219 2.582 1.96 4.409 2.238 3.636.554 8.565-.709 13.931-3.807l-.46-.797c-5.281 3.049-9.991 4.203-13.332 3.694-1.662-.253-2.965-.913-3.854-1.933-.888-1.019-1.402-2.443-1.402-4.298h-.92Zm19.968 3.334c5.378-3.105 10.243-7.503 13.767-12.109 3.513-4.591 5.741-9.453 5.741-13.485h-.92c0 3.736-2.088 8.399-5.552 12.926-3.453 4.514-8.228 8.83-13.496 11.871l.46.797Zm19.508-25.594c0-2.029-.565-3.683-1.628-4.903-1.062-1.219-2.582-1.96-4.409-2.238-3.636-.554-8.565.708-13.931 3.807l.46.796c5.28-3.048 9.99-4.202 13.332-3.693 1.662.253 2.965.912 3.854 1.933.888 1.019 1.402 2.443 1.402 4.298h.92Zm-19.968-3.334c-5.378 3.105-10.243 7.503-13.767 12.108-3.513 4.592-5.741 9.454-5.741 13.486h.92c0-3.736 2.088-8.399 5.552-12.926 3.453-4.514 8.227-8.83 13.496-11.872l-.46-.796Zm-19.505 25.206c-.076 1.973.38 3.684 1.391 5.008 1.011 1.326 2.542 2.219 4.528 2.621l.182-.902c-1.812-.367-3.128-1.162-3.979-2.277-.852-1.117-1.272-2.602-1.202-4.415l-.92-.035Zm5.934 7.632c3.881.652 8.925-.868 13.888-3.733 4.98-2.876 9.957-7.15 13.719-12.127l-.734-.555c-3.686 4.876-8.568 9.069-13.445 11.885-4.895 2.826-9.709 4.222-13.276 3.622l-.152.908Zm27.603-15.855c3.686-4.744 5.939-10.363 5.939-14.02h-.92c0 3.376-2.129 8.801-5.745 13.455l.726.565Zm5.019-14.02-.008 24.834h.92l.008-24.834h-.92Zm-.008 24.834c0 2.665-1.237 7.205-4.29 12.13-3.046 4.912-7.883 10.179-15.042 14.312l.46.797c7.299-4.214 12.243-9.592 15.364-14.625 3.113-5.02 4.428-9.727 4.428-12.614h-.92Zm-19.332 26.442c-7.184 4.148-11.75 4.298-14.48 2.911-2.729-1.387-3.823-4.414-3.823-7.093h-.921c0 2.873 1.173 6.31 4.327 7.913 3.152 1.602 8.083 1.266 15.357-2.934l-.46-.797Zm-18.303-4.182.007-24.833-.92-.001-.008 24.834h.921Z" />
      </g>
      <path
        d="m264.518 79.138-10.316 21.727M252.111 83.018l15.202 10.961M277.059 77.481c-.105 10.183-9.832 23.022-21.241 28.042-2.218 1.059-4.754 1.67-7.368 1.777a6.772 6.772 0 0 1-2.139-.261 5.666 5.666 0 0 1-1.82-.894c-1.786-1.368-2.756-3.918-2.721-7.18.09-8.715 7.23-19.377 16.44-25.41"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.584}
      />
      <path
        d="m312.872 136.703 16.475-9.512c2.879-1.662 5.273-5.613 5.791-9.063l-12.684 7.258-9.581-6.633 15.769-9.009c3.02-1.743 5.655-2.147 8.143-.532l5.579 4.17c2.062 1.508 2.961 5.081 1.88 9.714-1.393 4.979-4.566 10.104-8.672 12.474l-16.187 9.319-6.513-8.186Z"
        fill="#FDFFD0"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeWidth={0.58}
      />
      <path
        d="m312.871 136.704 16.474-9.512c2.879-1.662 5.273-5.613 5.791-9.062l7.227-4.747c2.061 1.508 2.96 5.081 1.88 9.715-1.393 4.978-4.567 10.103-8.673 12.474l-16.186 9.318-6.513-8.186Z"
        fill="#F4F8AC"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeWidth={0.58}
      />
      <path
        d="m318.676 127.637 17.206-9.878c3.257-1.88 5.996-1.256 5.996 2.505s-2.739 9.235-5.996 11.115l-17.207 9.878v4.33l16.475-9.512c5.328-3.076 9.647-10.558 9.647-16.71 0-6.152-4.319-8.646-9.647-5.57l-16.474 9.512v4.33Z"
        fill="#fff"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeWidth={0.58}
      />
      <path
        d="M298.544 145.212c-16.73 0-30.293-7.808-30.293-17.441 0-9.632 13.563-17.441 30.293-17.441 16.73 0 30.292 7.809 30.292 17.441 0 9.633-13.562 17.441-30.292 17.441Z"
        fill="#F4F8AC"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.58}
      />
      <path
        clipRule="evenodd"
        d="M268.252 127.771c0 9.633 13.562 17.441 30.292 17.441 16.73 0 30.293-7.808 30.293-17.441 0-9.632-13.563-17.441-30.293-17.441-16.73 0-30.292 7.809-30.292 17.441Zm4.699-.116c0 7.899 11.458 14.302 25.593 14.302 14.134 0 25.593-6.403 25.593-14.302 0-7.898-11.459-14.301-25.593-14.301-14.135 0-25.593 6.403-25.593 14.301Z"
        fill="#fff"
        fillRule="evenodd"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.58}
      />
      <path
        d="M275.424 133.934c4.223 4.757 12.943 8.022 23.015 8.022 10.074 0 18.795-3.267 23.017-8.024-3.503-5.496-12.486-9.406-23.015-9.406-10.531 0-19.515 3.911-23.017 9.408Z"
        fill="#1D1D1B"
      />
      <path
        d="M268.257 127.314c-.23 4.59 2.753 9.18 8.72 12.852 11.704 6.884 30.981 6.884 42.685 0 5.737-3.213 9.18-8.491 9.18-12.852l-.013 30.787c0 6.885-8.02 19.277-30.739 19.277s-29.845-12.392-29.845-19.277l.012-30.787Z"
        fill="#FDFFD0"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.58}
      />
      <mask
        height={51}
        id="b"
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'alpha',
        }}
        width={63}
        x={267}
        y={127}
      >
        <path
          d="M268.253 127.401c-.23 4.589 2.961 9.062 8.928 12.733 11.704 6.885 30.98 6.885 42.684 0 5.738-3.212 8.973-8.373 8.973-12.733l-.013 30.702c0 6.884-8.02 19.277-30.739 19.277s-29.845-12.393-29.845-19.277l.012-30.702Z"
          fill="#D9D9D9"
          stroke="#1D1D1B"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.58}
        />
      </mask>
      <g mask="url(#b)">
        <path d="M311.953 124.528h13.652v50.158h-13.652z" fill="#fff" />
      </g>
      <path
        d="M268.257 127.314c-.23 4.59 2.753 9.18 8.72 12.852 11.704 6.884 30.981 6.884 42.685 0 5.737-3.213 9.18-8.491 9.18-12.852l-.013 30.787c0 6.885-8.02 19.277-30.739 19.277s-29.845-12.392-29.845-19.277l.012-30.787Z"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.58}
      />
      <path d="m314.139 139.658 7.775-2.237 1.305 4.535-7.775 2.237z" fill="#fff" />
      <path
        d="M294.72 107.287c-8.37-11.56-5.75-22.597 2.851-29.529 8.099-6.526 8.099-12.818 8.099-13.956m2.165 44.361c-15.631-9.889-11.199-22.5-7.626-27.221"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeWidth={0.58}
      />
      <path
        d="M282.268 131.693c3.523-2.577 13.736-6.723 26.406-2.69"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={0.58}
      />
    </svg>
  );
}
