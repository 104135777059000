import type { SVGProps, ReactNode } from 'react';

export function CrossRoundedFilled(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_881_18958)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 0C3.58 0 0 3.58 0 8C0 12.42 3.58 16 8 16C12.42 16 16 12.42 16 8C16 3.58 12.42 0 8 0ZM10.88 10.14C11.08 10.34 11.08 10.67 10.88 10.87C10.78 10.97 10.65 11.02 10.52 11.02C10.39 11.02 10.26 10.97 10.16 10.87L8.01 8.72L5.85 10.87C5.75 10.97 5.62 11.02 5.49 11.02C5.36 11.02 5.23 10.97 5.13 10.87C4.93 10.67 4.93 10.34 5.13 10.14L7.28 8L5.13 5.86C4.93 5.67 4.93 5.34 5.13 5.14C5.33 4.94 5.65 4.94 5.85 5.14L8.01 7.28L10.16 5.14C10.36 4.94 10.68 4.94 10.88 5.14C11.08 5.34 11.08 5.67 10.88 5.86L8.73 8L10.88 10.14Z"
          fill="#F95656"
        />
      </g>
      <defs>
        <clipPath id="clip0_881_18958">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
