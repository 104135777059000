import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { BadgeHighlight } from '@repo/design-system-kit';
import { CardLevel } from '@repo/cards-kit/constants';
import styles from './caption.strict-module.css';

interface CaptionProps {
  cardLevel?: CardLevel;
}

export function Caption({ cardLevel }: CaptionProps): ReactNode {
  const { formatMessage } = useIntl();

  if (cardLevel === CardLevel.Flash || cardLevel === CardLevel.Virtual) {
    const text: string =
      cardLevel === CardLevel.Flash
        ? formatMessage({ id: 'cards.image.badge.flash' })
        : formatMessage({ id: 'cards.image.badge.virtual' });

    return (
      <BadgeHighlight
        aria-hidden="true"
        className={styles.caption}
        data-test-card-image-caption=""
        text={text}
      />
    );
  }

  return null;
}
