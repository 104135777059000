/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import type { ReactNode } from 'react';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import type IntlServiceBase from 'ember-intl/services/intl';
import cx from 'clsx';
import { BadgeStatus } from '@repo/design-system-kit';
import { Wallet } from 'qonto/react/assets/icons';
import type RibaPaymentModel from 'qonto/models/riba-payment';
import { RIBA_STATUS } from 'qonto/constants/riba';
import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import styles from './styles.strict-module.css';

interface QontoIntlService extends IntlServiceBase {
  formatMoney: (value: number, options: { currency: string }) => string;
}

interface HeaderProps {
  payment: RibaPaymentModel;
}

export function Header({ payment }: HeaderProps): ReactNode {
  const intl = useEmberService('intl') as QontoIntlService;

  const isTerminatedPayment =
    payment.status === RIBA_STATUS.CANCELED ||
    payment.status === RIBA_STATUS.REJECTED ||
    payment.status === RIBA_STATUS.DECLINED;

  const STATUS_CONFIG = {
    [RIBA_STATUS.PENDING]: {
      level: 'waiting' as const,
      message: '',
    },
    [RIBA_STATUS.SCHEDULED]: {
      level: 'waiting' as const,
      message: intl.formatMessage({ id: 'riba.status.scheduled' }),
    },
    [RIBA_STATUS.COMPLETED]: {
      level: 'validated' as const,
      message: intl.formatMessage({ id: 'riba.status.completed' }),
    },
    [RIBA_STATUS.CANCELED]: {
      level: 'error' as const,
      message: intl.formatMessage({ id: 'riba.status.canceled' }),
    },
    [RIBA_STATUS.REJECTED]: {
      level: 'error' as const,
      message: intl.formatMessage({ id: 'riba.status.rejected' }),
    },
    [RIBA_STATUS.DECLINED]: {
      level: 'error' as const,
      message: intl.formatMessage({ id: 'riba.status.declined' }),
    },
  } as const;

  const formattedExecutionDate = dateToken({
    date: payment.executionDate.toISOString(),
    locale: intl.primaryLocale || 'en',
    token:
      isTerminatedPayment || payment.status === RIBA_STATUS.COMPLETED
        ? DATE_FORMAT_TOKENS.DATE_TIME_S
        : DATE_FORMAT_TOKENS.DATE_YEAR_S,
  });

  const statusConfig = STATUS_CONFIG[payment.status];

  return (
    <div className={styles.header}>
      <div className={styles['top-content']}>
        <div className={cx(styles.avatar, 'title-4')}>
          <Wallet />
        </div>
        <div data-test-riba-status>
          {payment.status !== RIBA_STATUS.PENDING && (
            <BadgeStatus level={statusConfig.level} text={statusConfig.message} />
          )}
        </div>
      </div>
      <div>
        <div className={styles.creditor} data-test-creditor-name>
          {payment.creditorName}
        </div>
        <div
          className={cx(styles.amount, 'title-2', isTerminatedPayment && styles.ghost)}
          data-test-amount
        >
          -{' '}
          {intl.formatMoney(payment.amount.value, {
            currency: payment.amount.currency,
          })}
        </div>
        <div className={styles.date} data-test-execution-date>
          {formattedExecutionDate}
        </div>
      </div>
    </div>
  );
}
