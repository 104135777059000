import { type ReactNode } from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import cx from 'clsx';
import { useIntl, FormattedNumber } from 'react-intl';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import styles from './styles.strict-module.css';

interface InvoiceProps {
  date?: string;
  nameLabel: string;
  showSignus: boolean;
  currency?: string;
  name: string;
  amount?: string;
  isCreditNote?: boolean;
  isLoading?: boolean;
}

export function InvoicePreview({
  name,
  nameLabel,
  showSignus,
  date,
  amount,
  currency,
  isCreditNote,
  isLoading = false,
}: InvoiceProps): ReactNode {
  const { formatMessage, locale } = useIntl();

  const missingData = formatMessage({ id: 'receivable-invoices.label.missing-data' });
  const formattedDate = date ? dateToken({ date, locale, token: 'date-year-s' }) : missingData;
  const nameValue = name || missingData;

  const formattedAmount = (): JSX.Element | string => {
    if (amount === undefined) {
      return missingData;
    }

    const sign = showSignus ? '-\xA0' : '';
    return (
      <>
        {sign}
        <FormattedNumber
          maximumFractionDigits={2}
          minimumFractionDigits={2}
          value={Number(amount)}
        />
        {'\xA0'}
        {currency}
      </>
    );
  };

  return (
    <div className={styles.card} data-test-invoice-preview-container>
      <div className={cx('mb-8', styles.row)}>
        <LoadingText
          content={
            <p className="body-2" data-test-invoice-preview-name-label>
              {nameLabel}
            </p>
          }
          isLoading={isLoading}
          skeletonWidth="104px"
        />
        <span className="body-1" data-test-invoice-preview-name-value>
          <LoadingText content={nameValue} isLoading={isLoading} skeletonWidth="96px" />
        </span>
      </div>
      {!isCreditNote && (
        <div className={cx('mb-8', styles.row)}>
          <LoadingText
            content={
              <p className="body-2">
                {formatMessage({
                  id: 'aeat.table-header.due-date',
                })}
              </p>
            }
            isLoading={isLoading}
            skeletonWidth="72px"
          />
          <span className="body-1" data-test-invoice-preview-date>
            <LoadingText content={formattedDate} isLoading={isLoading} skeletonWidth="96px" />
          </span>
        </div>
      )}
      <div className={styles.row}>
        <LoadingText
          content={
            <p className="body-2">
              {formatMessage({
                id: 'aeat.table-header.amount',
              })}
            </p>
          }
          isLoading={isLoading}
          skeletonWidth="72px"
        />
        <span className="body-1" data-test-invoice-preview-amount>
          <LoadingText content={formattedAmount()} isLoading={isLoading} skeletonWidth="64px" />
        </span>
      </div>
    </div>
  );
}

interface LoadingTextProps {
  isLoading: boolean;
  content: JSX.Element | string;
  skeletonWidth: string;
}

function LoadingText({ isLoading, content, skeletonWidth }: LoadingTextProps): ReactNode {
  if (isLoading) {
    return <SkeletonLoader.Line width={skeletonWidth} />;
  }
  return content;
}
