import type { ReactNode, ReactElement } from 'react';
import { Badge, BadgeStatus } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import {
  Selector,
  type SelectorProps,
} from 'qonto/react/components/account-receivable/summary/selector/selector';
import { Company, Calculator, Invoice, Pole } from 'qonto/react/assets/icons/account-receivable';
import PaymentLinkSvg from 'qonto/static/illustrations/user-actions/payment-links.svg';
import styles from './styles.strict-module.css';

const icons = {
  pole: Pole,
  company: Company,
  invoice: Invoice,
  calculator: Calculator,
  'payment-link': function PaymentLink(): ReactNode {
    return <img alt="" src={PaymentLinkSvg} />;
  },
};

export type SubStepStatus = 'toDo' | 'prefilled' | 'confirmed' | 'tryForFree' | 'upgrade';
export type StepSelectorProps = Omit<SelectorProps, 'icon'> & {
  status: SubStepStatus;
  icon: keyof typeof icons;
};

interface StatusBadgeProps {
  status: SubStepStatus;
  className?: string;
}

function StatusBadge({ status, className }: StatusBadgeProps): ReactElement | null {
  const { formatMessage } = useIntl();

  switch (status) {
    case 'confirmed':
      return (
        <BadgeStatus
          className={className}
          level="validated"
          text={formatMessage({
            id: 'receivable-invoices.onboarding.status-badge.completed',
          })}
        />
      );
    case 'prefilled':
      return (
        <BadgeStatus
          className={className}
          level="waiting"
          text={formatMessage({
            id: 'receivable-invoices.onboarding.status-badge.to-review',
          })}
        />
      );
    case 'tryForFree':
      return (
        <Badge
          className={className}
          text={formatMessage({
            id: 'promotion-system.badge.trial.label',
          })}
          type="tryForFree"
        />
      );
    case 'upgrade':
      return (
        <Badge
          className={className}
          text={formatMessage({
            id: 'promotion-system.badge.upgrade.label',
          })}
          type="upgrade"
        />
      );
    default:
      return null;
  }
}

export function StepSelector({ title, status, icon, ...props }: StepSelectorProps): ReactElement {
  const computedTitle = (
    <span className={styles.title}>
      {title}
      <StatusBadge className={styles.badge} status={status} />
    </span>
  );

  const IconComponent = icons[icon];
  const computedIcon: ReactElement = <IconComponent />;

  return <Selector {...props} icon={computedIcon} title={computedTitle} />;
}
