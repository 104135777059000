import { type PropsWithChildren, type ReactNode } from 'react';
import cx from 'clsx';
import styles from './styles.strict-module.css';

interface AnimatedRowProps extends PropsWithChildren {
  className?: string;
}

export function AnimatedRow({ children, className }: AnimatedRowProps): ReactNode {
  return (
    <tr className={cx(styles.wrapper, className)}>
      {children}
      <td className={styles.mask} data-test-animated-row="">
        <div className={cx(styles.glow, styles['glow--1'])} />
        <div className={cx(styles.glow, styles['glow--2'])} />
        <div className={cx(styles.glow, styles['glow--3'])} />
      </td>
    </tr>
  );
}
