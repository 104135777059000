import { type ReactNode } from 'react';
import { Button } from '@repo/design-system-kit';
import { FormattedMessage } from 'react-intl';
import cx from 'clsx';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { importAsset } from 'qonto/helpers/import-asset';
import styles from './styles.strict-module.css';

interface SuccessProps {
  completeFlow: () => void;
  restartFlow: ({ initialStepId }: { initialStepId: string }) => void;
}

export function SuccessLight({ completeFlow, restartFlow }: SuccessProps): ReactNode {
  const segment = useEmberService('segment');

  const completeFlowEvent = (): void => {
    segment.track('expense_submitted_close');
    completeFlow();
  };

  const restartFlowEvent = (): void => {
    restartFlow({ initialStepId: 'receipt' });
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img
          alt=""
          className={cx('mb-32', styles.illustration)}
          data-test-expense-report-success-illustration
          src={importAsset(['/illustrations/ill-cp-requests-expense-reimbursement.svg'])}
        />

        <h1 className="title-1 mb-16" data-test-expense-report-success-title>
          <FormattedMessage id="expense-reports.steps.success.title" />
        </h1>

        <p className="body-2 mb-32" data-test-expense-report-success-subtitle>
          <FormattedMessage id="expense-reports.steps.success.subtitle" />
        </p>

        <Button
          className="mb-16"
          data-test-expense-report-success-close
          onPress={completeFlowEvent}
        >
          <FormattedMessage id="expense-reports.steps.success.cta" />
        </Button>

        <Button
          data-test-expense-report-success-restart
          onPress={restartFlowEvent}
          variant="tertiary"
        >
          <FormattedMessage id="expense-reports.steps.success.cta-secondary" />
        </Button>
      </div>
    </div>
  );
}
