import { type StatusType } from '@repo/design-system-kit';
import type { Model } from 'qonto/react/models/model';
import type { Amount } from 'qonto/react/models/amount';

export enum CardAcquirerPaymentStatus {
  Processing = 'processing',
  Declined = 'declined',
  Completed = 'completed',
}

export interface CardAcquirerPaymentModel extends Model {
  amount: Amount;
  reference: string;
  organizationId: string;
  membershipId: string;
  cardAcquirerPayoutId: string;
  status: CardAcquirerPaymentStatus;
  paymentMethod: string | null;
  cardLast4: string | null;
  processingAt: string;
  completedAt: string | null;
  declinedAt: string | null;
  card: string;
  executionDate: string;
  avatarInfo: {
    smallLogo: string;
  };
  displayedStatus: {
    level: StatusType;
    text: string;
  };
}
