import { type ReactNode, useMemo } from 'react';
import cx from 'clsx';
import { dateToken, Tag } from '@repo/design-system-kit';
import { FormattedMessage, useIntl } from 'react-intl';
import { StatusAvatar } from 'qonto/react/components/avatar';
import { type CheckStatus } from 'qonto/constants/checks';
import type { Check } from 'qonto/react/models/check';
import styles from './item.strict-module.css';

interface ChecksTableItemProps {
  check: Check;
}

export function ChecksTableItem({ check }: ChecksTableItemProps): ReactNode {
  const { locale, formatNumber, formatMessage } = useIntl();

  const isAvatarDisabled = useMemo(() => {
    const { status } = check;
    const disabledAvatarStatuses: CheckStatus[] = ['canceled', 'declined', 'refunded'];
    return disabledAvatarStatuses.includes(status);
  }, [check]);

  const status = useMemo(() => {
    const { status: itemStatus, declinedReason } = check;

    const CHECK_DISPLAY_INFO: Record<
      CheckStatus,
      { color: 'gray' | 'red' | 'green' | 'orange' | 'purple'; copy: string }
    > = {
      canceled: {
        color: 'red',
        copy: formatMessage({ id: 'checks.status.canceled' }),
      },
      declined: {
        color: 'red',
        copy:
          declinedReason === 'not_received'
            ? formatMessage({ id: 'checks.status.canceled' })
            : formatMessage({ id: 'checks.status.declined' }),
      },
      pending: {
        color: 'orange',
        copy: formatMessage({ id: 'checks.status.pending' }),
      },
      received: {
        color: 'orange',
        copy: formatMessage({ id: 'checks.status.received' }),
      },
      refunded: {
        color: 'red',
        copy: formatMessage({ id: 'checks.status.refunded' }),
      },
      validated: {
        color: 'green',
        copy: formatMessage({ id: 'checks.status.validated' }),
      },
    } as const;

    return CHECK_DISPLAY_INFO[itemStatus];
  }, [check, formatMessage]);

  return (
    <>
      <td aria-hidden="true" />

      <td className={cx('body-1', styles.cell)}>
        <div className={cx(styles['cell-content'], 'emitter')}>
          <div className={styles['status-avatar']}>
            <StatusAvatar
              data-test-table-item-check-avatar=""
              hasBorder={check.enrichmentData.logo}
              iconName={check.avatarInfo.icon}
              isDisabled={isAvatarDisabled}
              isLazyLoaded
              src={check.avatarInfo.smallLogo}
            />
          </div>
          <div className={styles['item-emitter-name']}>
            <span className={styles['item-emitter-name-text']}>{check.emitterName}</span>
          </div>
        </div>
      </td>

      <td className={cx(styles.cell, 'body-2')}>
        <div className={styles['cell-content']}>
          {dateToken({
            date: check.createdAt,
            locale,
            token: 'date-year-s',
          })}
        </div>
      </td>

      <td
        className={cx(
          styles.cell,
          styles['cell-amount'],
          check.validated && styles.validated,
          check.ghost && styles.ghost,
          'body-1'
        )}
      >
        <div className={styles['cell-content']}>
          {formatNumber(check.amount, {
            style: 'currency',
            currency: check.amountCurrency,
          })}
        </div>
      </td>

      <td className={cx(styles.cell, styles['not-disabled'], 'body-2')}>
        <div className={styles['cell-content']}>
          <Tag color={status.color} data-test-table-item-check-status="" label={status.copy} />
        </div>
      </td>

      <td aria-hidden="true" className={styles.empty} />

      <td className="sr-only" data-test-table-item-details-button-cell>
        <button tabIndex={-1} type="button">
          <FormattedMessage
            id="checks.list.view-details-button"
            values={{ emitterName: check.emitterName }}
          />
        </button>
      </td>
    </>
  );
}
