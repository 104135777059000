import { useState, type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { IconChevronBottomOutlined } from '@repo/monochrome-icons';
import { QualityItem } from '../item';
import styles from './accordion.strict-module.css';

interface AccordionProps extends React.HTMLAttributes<HTMLDivElement> {
  inboundItems: {
    domain: string;
    qualities: string[];
  }[];
  outboundItems: {
    domain: string;
    qualities: string[];
  }[];
  trackEvent?: (event: string) => void;
}

export function InContextIntegrationsSideDrawerIntegrationQualityAccordion({
  inboundItems,
  outboundItems,
  trackEvent,
  ...props
}: AccordionProps): ReactNode {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const { formatMessage } = useIntl();

  const uniqueQualities =
    inboundItems.flatMap(item => item.qualities).length +
    outboundItems.flatMap(item => item.qualities).length;

  const toggleAccordion = (): void => {
    setIsAccordionOpen(!isAccordionOpen);
    trackEvent?.('ICI_integration_quality_clicked');
  };

  return (
    <div data-test-side-drawer-integration-quality-accordion {...props}>
      <button
        aria-controls="accordion-content"
        aria-expanded={isAccordionOpen}
        className={cx('body-2 mb-8', styles['accordion-trigger'])}
        data-test-trigger
        id="accordion-trigger"
        onClick={toggleAccordion}
        type="button"
      >
        {formatMessage(
          { id: 'in-context-integrations.side-drawer.integration.accordion.label' },
          { count: uniqueQualities }
        )}
        <span
          className={cx('ml-8', styles.chevron, isAccordionOpen && styles['chevron-down'])}
          data-test-is-icon-open={isAccordionOpen}
        >
          <IconChevronBottomOutlined aria-hidden="true" />
        </span>
      </button>

      <div
        aria-labelledby="accordion-trigger"
        className={cx(styles['content-wrapper'], isAccordionOpen && styles.expanded)}
        data-test-is-content-expanded={isAccordionOpen}
        id="accordion-content"
      >
        <ul className={styles.content}>
          {inboundItems.map(item => (
            <li
              className={styles['quality-item']}
              data-test-inbound-quality-item
              key={`inbound-${item.domain}`}
            >
              <QualityItem dataFlow="in" domain={item.domain} qualities={item.qualities} />
            </li>
          ))}
          {outboundItems.map(item => (
            <li
              className={styles['quality-item']}
              data-test-outbound-quality-item
              key={`outbound-${item.domain}`}
            >
              <QualityItem dataFlow="out" domain={item.domain} qualities={item.qualities} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
