import { type PropsWithChildren, type ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { Badge, type BadgeType, Button } from '@repo/design-system-kit';
import { IconPlusOutlined } from '@repo/monochrome-icons';
import cx from 'clsx';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import type ReceivableInvoiceModel from 'qonto/models/receivable-invoice.ts';
import type ReceivableInvoiceSectionModel from 'qonto/models/receivable-invoice/section.ts';
import type { ReceivableInvoiceItemModel } from 'qonto/react/receivable-invoices/api/models/receivable-invoices-item.ts';
import { type FormErrors } from 'qonto/react/hooks/use-form.ts';
import { Section } from './section';
import styles from './styles.strict-module.css';

interface ProductsAndServicesProps {
  className?: string;
  document: ReceivableInvoiceModel;
  sections: ReceivableInvoiceSectionModel[];
  errors?: FormErrors<ReceivableInvoiceModel>;
  onAddItem: (section?: ReceivableInvoiceSectionModel, skipTrack?: boolean) => void;
  onAddSection: () => void;
  onDeleteItem: (item: ReceivableInvoiceItemModel) => void;
  onDeleteSection: (section: ReceivableInvoiceSectionModel) => void;
  onSortItems: (section: ReceivableInvoiceSectionModel, sortedIds: string[]) => void;
  onVatResetDisclaimerDismiss: () => void;
  showSectionsPopup: () => void;
  shouldDisplayInternationalClientVatDisclaimer?: boolean;
  isDefaultSection?: boolean;
  isDisabled?: boolean;
  badgeType: BadgeType;
}

export function ProductsAndServices({
  className,
  document,
  sections,
  onAddItem,
  onAddSection,
  onDeleteItem,
  onDeleteSection,
  onSortItems,
  onVatResetDisclaimerDismiss,
  showSectionsPopup,
  shouldDisplayInternationalClientVatDisclaimer,
  isDefaultSection,
  isDisabled,
  children,
  badgeType = 'upgrade',
}: PropsWithChildren<ProductsAndServicesProps>): ReactElement {
  const { formatMessage } = useIntl();
  const abilities = useEmberService('abilities');

  const showAddItemCTA = sections.length === 1 && isDefaultSection;

  return (
    <div className={cx(styles.container, className)}>
      <h3 className="title-3 mb-16" data-test-form-products-section-header="">
        {formatMessage({ id: 'receivable-invoices.invoice-creation.items.section-title' })}
      </h3>

      {children}

      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access -- Ember models */}
      {document.sections.map((section: ReceivableInvoiceSectionModel, index: number) => (
        <Section
          className="mb-16"
          currency={document.currency}
          document={document}
          // @ts-expect-error -- Ember models
          errors={section.errors.content}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- Ember models
          hasMultipleItems={section.items.length > 1}
          index={index}
          isDefault={isDefaultSection}
          isDisabled={isDisabled}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Ember models
          items={section.items}
          key={section.uuid}
          onAddItem={onAddItem}
          onDeleteItem={onDeleteItem}
          onDeleteSection={onDeleteSection}
          onSortItems={onSortItems}
          onVatResetDisclaimerDismiss={onVatResetDisclaimerDismiss}
          section={section}
          shouldDisplayInternationalClientVatDisclaimer={
            shouldDisplayInternationalClientVatDisclaimer
          }
        />
      ))}

      {!isDisabled ? (
        <div className={styles.actions}>
          {showAddItemCTA ? (
            <Button
              data-test-document-add-item=""
              onPress={() => {
                onAddItem();
              }}
              size="small"
              variant="tertiary"
            >
              <IconPlusOutlined className="mr-8" />
              {formatMessage({ id: 'receivable-invoices.invoice-creation.add-item.cta' })}
            </Button>
          ) : null}

          {abilities.can('view accountsReceivableAdvancedCustomization') ? (
            <>
              {abilities.can('use accountsReceivableAdvancedCustomization') ? (
                <Button
                  data-test-document-add-section=""
                  onPress={onAddSection}
                  size="small"
                  variant="tertiary"
                >
                  <IconPlusOutlined className="mr-8" />
                  {formatMessage({ id: 'receivable-invoices.invoice-creation.add-section.cta' })}
                </Button>
              ) : (
                <div className={styles.upsellCta}>
                  <Button onPress={showSectionsPopup} size="small" variant="tertiary">
                    <IconPlusOutlined className="mr-8" />
                    {formatMessage({ id: 'receivable-invoices.invoice-creation.add-section.cta' })}
                  </Button>
                  <button className={styles.upsellBadge} onClick={showSectionsPopup} type="button">
                    <Badge type={badgeType} />
                  </button>
                </div>
              )}
            </>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
