/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import type { ReactNode } from 'react';
import cx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { ArrowLeft, ArrowRight } from 'qonto/react/assets/icons';
import type { SearchTransactionsMeta } from 'qonto/react/graphql';
import styles from './styles.strict-module.css';

interface PaginationProps {
  responseMeta: SearchTransactionsMeta;
  updatePerPage: (pageSize: number) => void;
  updatePage: (page: number) => void;
}

function PerPageOption({
  perPage,
  updatePerPage,
  isActive,
}: {
  perPage: number;
  updatePerPage: (pageSize: number) => void;
  isActive: boolean;
}): ReactNode {
  return (
    <button
      className={cx(styles.option, isActive && styles.active)}
      data-testid={`per-page-option-${String(perPage)}`}
      onClick={() => {
        updatePerPage(perPage);
      }}
      type="button"
    >
      {perPage}
    </button>
  );
}

export function Pagination({
  updatePerPage,
  updatePage,
  responseMeta,
}: PaginationProps): ReactNode {
  const { formatMessage } = useIntl();

  const canGoToPreviousPage = Boolean(responseMeta.prevPage);
  const canGoToNextPage = Boolean(responseMeta.nextPage);

  const goToPreviousPage = (): void => {
    if (responseMeta.prevPage) {
      updatePage(responseMeta.prevPage);
    }
  };

  const goToNextPage = (): void => {
    if (responseMeta.nextPage) {
      updatePage(responseMeta.nextPage);
    }
  };

  return (
    <div className={styles.pagination} data-testid="pagination">
      <div className={styles['per-page-controls']} data-testid="per-page-controls">
        <div className={styles.options} data-testid="per-page-options">
          {[25, 50, 100].map((perPage: number) => (
            <PerPageOption
              isActive={perPage === responseMeta.perPage}
              key={perPage}
              perPage={perPage}
              updatePerPage={updatePerPage}
            />
          ))}
        </div>
        <div className={cx(styles['per-page-description'], 'body-2')} data-testid="per-page-label">
          <FormattedMessage id="labels.items_per_page" />
        </div>
      </div>
      <div
        className={cx(styles['current-page-description'], 'body-2')}
        data-testid="current-page-description"
      >
        {getCurrentPageDescription(responseMeta, formatMessage)}
      </div>
      <div className={styles.options} data-testid="navigation-controls">
        <button
          aria-label="Go to previous page"
          className={cx(styles.option, 'btn', 'btn--icon-only', 'btn--tertiary')}
          data-testid="previous-page"
          disabled={!canGoToPreviousPage}
          onClick={goToPreviousPage}
          type="button"
        >
          <ArrowLeft />
        </button>
        <button
          aria-label="Go to next page"
          className={cx(styles.option, 'btn', 'btn--icon-only', 'btn--tertiary')}
          data-testid="next-page"
          disabled={!canGoToNextPage}
          onClick={goToNextPage}
          type="button"
        >
          <ArrowRight />
        </button>
      </div>
    </div>
  );
}

function getCurrentPageDescription(
  meta: SearchTransactionsMeta,
  formatMessage: (descriptor: { id: string; defaultMessage?: string }) => string
): string {
  const currentPage = meta.currentPage;
  const perPage = meta.perPage;
  const totalCount = meta.totalCount;
  const offset = currentPage * perPage;
  const end = Math.min(offset, totalCount);
  const start = totalCount && offset - perPage + 1;
  const ofText = formatMessage({
    id: 'labels.pagination_summary',
    defaultMessage: 'of',
  });

  return `${String(start)}-${String(end)} ${ofText} ${String(totalCount)}`;
}
