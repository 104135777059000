import type { ReactElement } from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './styles.strict-module.css';

export function ItemSkeleton(): ReactElement {
  return (
    <div className={styles.itemSkeleton} data-test-review-item-skeleton="">
      <div className={styles.column}>
        <SkeletonLoader.Line height="12px" standalone width="104px" />

        <SkeletonLoader.Line height="8px" standalone width="144px" />
        <SkeletonLoader.Line height="8px" standalone width="72px" />
      </div>

      <div className={styles.column}>
        <SkeletonLoader.Line height="12px" standalone width="48px" />

        <SkeletonLoader.Line height="8px" standalone width="64px" />
        <SkeletonLoader.Line height="8px" standalone width="104px" />
      </div>
    </div>
  );
}
