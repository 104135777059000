/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import { useContext, useEffect, useState, type ReactNode } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  GridList,
  GridListItem,
  Input,
  OverlayTriggerStateContext,
  type PopoverProps,
  type Selection,
} from 'react-aria-components';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { DataWithIconCell } from 'qonto/react/components/table-v2/cells/data-with-icon-cell';
import { Popover, PopoverSection } from 'qonto/react/components/table-v2/popover';
import { useUpdateTransactionCategory } from 'qonto/react/hooks/use-update-transaction-category';
import { SearchOutlined } from 'qonto/react/assets/icons';
import { getSelectionValue } from 'qonto/react/utils/react-aria-helpers';
import { CATEGORY_POPOVER_CLICK_EVENT_NAME } from 'qonto/react/constants';
import { useCategoryFilter } from '../category-map';
import styles from './styles.strict-module.css';

interface CategoryCellPopoverProps {
  category?: string | null;
  transactionId: string;
  customPopoverProps?: Omit<PopoverProps, 'children'>;
}

export function CategoryCellPopover({
  category,
  transactionId,
  customPopoverProps,
}: CategoryCellPopoverProps): ReactNode {
  const { formatMessage } = useIntl();
  const segment = useEmberService('segment');
  const triggerState = useContext(OverlayTriggerStateContext);
  const toastFlashMessages = useEmberService('toast-flash-messages');
  const { mutate } = useUpdateTransactionCategory(transactionId);
  const { filteredList, filter, resetFilter } = useCategoryFilter();
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<Selection>(
    new Set(category ? [category] : [])
  );

  useEffect(() => {
    setSelectedCategoryIds(new Set(category ? [category] : []));
  }, [category]);

  const handleSelectionChange = (selectedId: Selection): void => {
    setSelectedCategoryIds(selectedId);
    const categoryValue = getSelectionValue(selectedId);
    if (categoryValue) {
      segment.track(CATEGORY_POPOVER_CLICK_EVENT_NAME);

      mutate(
        {
          categoryValue,
          closePopover: () => {
            triggerState?.close();
          },
        },
        {
          onSuccess: () => {
            resetFilter();
          },
          onError: () => {
            setSelectedCategoryIds(selectedCategoryIds);
            resetFilter();
            toastFlashMessages.toastError('Category could not be saved');
          },
        }
      );
    }
  };

  return (
    <Popover
      isOpen={triggerState?.isOpen}
      onOpenChange={() => {
        resetFilter();
        triggerState?.toggle();
      }}
      {...customPopoverProps}
    >
      <PopoverSection>
        <form className={styles.search} role="search">
          <SearchOutlined aria-hidden="true" />
          <Input
            aria-label="Search categories"
            data-testid="category-search-input"
            onChange={e => {
              filter(e.target.value);
            }}
            placeholder={formatMessage({
              id: 'transactions.modular_table.category.search.placeholder',
            })}
          />
        </form>
      </PopoverSection>
      <PopoverSection>
        {!filteredList.length && (
          <p className={styles['list-item']}>
            <FormattedMessage id="transactions.modular_table.category.no_category.label" />
          </p>
        )}
        <GridList
          aria-label="available categories"
          disallowEmptySelection
          items={filteredList}
          onSelectionChange={id => {
            handleSelectionChange(id);
          }}
          selectedKeys={selectedCategoryIds}
          selectionMode="single"
        >
          {item => (
            <GridListItem
              className={styles['list-item']}
              data-testid="categories-list-item"
              textValue={item.title}
            >
              <DataWithIconCell icon={item.icon} title={item.title} />
            </GridListItem>
          )}
        </GridList>
      </PopoverSection>
    </Popover>
  );
}
