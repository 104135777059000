import type { ReactNode, HTMLAttributes } from 'react';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import type { TransactionModel } from 'qonto/react/models/transaction';
import styles from './styles.strict-module.css';

interface AmountProps extends HTMLAttributes<HTMLDivElement> {
  transaction: TransactionModel;
  showLocalAmount?: boolean;
  fx?: boolean;
}

export function Amount({ transaction, showLocalAmount = false, ...props }: AmountProps): ReactNode {
  const { formatNumber } = useIntl();

  const formatAmount = (amount: number, currency: string): string => {
    const isNegative = amount < 0 || transaction.side === 'debit';
    const formattedNumber = formatNumber(Math.abs(amount), {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const sign = isNegative ? '-' : '+';
    return `${sign}\u00A0${formattedNumber}\u00A0${currency}`;
  };

  const amountValue = transaction.signedAmount;
  const localAmount = transaction.signedLocalAmount;
  const localCurrency = transaction.localAmountCurrency;

  const wrapperClass = styles[transaction.status] || '';
  const baseClass = styles.wrapper;

  const canShowLocalAmount = Boolean(
    showLocalAmount &&
      localAmount !== 0 &&
      localCurrency &&
      localCurrency !== transaction.amountCurrency
  );

  return (
    <div
      className={cx(baseClass, wrapperClass)}
      data-test-transaction-status={transaction.status}
      {...props}
    >
      <span className="title-3" data-test-amount>
        {formatAmount(amountValue, transaction.amountCurrency)}
      </span>

      {canShowLocalAmount ? (
        <span className={styles['local-amount']} data-test-local-amount>
          {formatAmount(localAmount, localCurrency)}
        </span>
      ) : null}
    </div>
  );
}
