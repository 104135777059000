import React, { type ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from '@qonto/react-migration-toolkit/react/components';
import cx from 'clsx';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import styles from './fee-details.strict-module.css';

interface FeeDetailsProps {
  transaction?: {
    amount: number;
    amountCurrency: string;
    localAmount?: number;
    localAmountCurrency?: string;
    side?: 'credit' | 'debit';
  } | null;
  fees: {
    id: string;
    name: string;
    amount: {
      currency: string;
      value: number;
    };
  }[];
}

export function FeeDetails({ transaction, fees }: FeeDetailsProps): ReactNode {
  const { organization } = useOrganizationManager();
  const { formatNumber } = useIntl();

  const formatAmount = (amount: number, currency: string, sign?: '-' | '+'): string => {
    const isNegative = amount < 0 || transaction?.side === 'debit';
    const formattedNumber = formatNumber(Math.abs(amount), {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return `${sign || (isNegative ? '-' : '+')} ${formattedNumber} ${currency}`;
  };

  if (fees.length === 0 || !transaction) {
    return null;
  }

  return (
    <>
      <section className={styles.section} data-test-fee-details-transaction>
        <h6 className={cx(styles.title, 'caption-bold')} data-test-fee-details-transaction-title>
          <FormattedMessage id="transactions.columns.counterparty_name" />
        </h6>
        <dl className={cx(styles.info, 'body-2')}>
          <dt data-test-fee-details-transaction-amount-header>
            <FormattedMessage id="transactions.modular_table.amount.header" />
          </dt>
          <dd
            className={cx(styles.amount, 'body-2')}
            data-test-fee-details-transaction-amount-value
          >
            {formatAmount(transaction.amount, transaction.amountCurrency)}
          </dd>
        </dl>
        {transaction.localAmount &&
        transaction.localAmountCurrency &&
        transaction.localAmountCurrency !== transaction.amountCurrency ? (
          <dl className={cx(styles.info, 'body-2')} data-test-fee-details-exchanged-amount>
            <dt data-test-fee-details-exchanged-amount-header>
              <FormattedMessage id="transactions.modular_table.amount.exchanged" />
            </dt>
            <dd className="body-2" data-test-fee-details-exchanged-amount-value>
              {formatAmount(transaction.localAmount, transaction.localAmountCurrency)}
            </dd>
          </dl>
        ) : null}
      </section>

      <section className={styles.section} data-test-fee-details-fees>
        <h6 className={cx(styles.title, 'caption-bold')} data-test-fee-details-fees-title>
          <FormattedMessage
            id={
              fees.length > 1
                ? 'transactions.modular_table.fees.multiple'
                : 'transactions.modular_table.fees.single'
            }
          />
        </h6>
        {fees.map(fee => (
          <dl className={styles.info} data-test-fee-details-fees-item={fee.name} key={fee.id}>
            <dt className={styles.feeName} data-test-fee-details-fee-name>
              <Link
                className={cx(styles.feeName, 'body-link')}
                to={`/organizations/${organization.slug}/transactions?highlight=${fee.id}`}
              >
                {fee.name}
              </Link>
            </dt>
            <dd className={cx(styles.amount, 'body-2')} data-test-fee-details-fee-amount>
              {formatAmount(fee.amount.value, fee.amount.currency, '-')}
            </dd>
          </dl>
        ))}
      </section>
    </>
  );
}
