import { TabList, TabPanel, Tabs } from '@repo/design-system-kit';
import React, { type ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useEmberService, useFlags } from '@qonto/react-migration-toolkit/react/hooks';
import { TransactionsTableWrapper } from 'qonto/react/components/cash-flow/components/sidepanel/transactions-table';
import type { LabelTableInterval } from 'qonto/react/components/cash-flow/models/labels-cashflow-display.ts';
import type { CategoriesTableRow } from 'qonto/react/components/cash-flow/models/categories-table-display.ts';
import type { CashflowSidePanelTab } from 'qonto/react/contexts/cash-flow-sidepanel-context.tsx';
import type { CashflowPeriodRate } from 'qonto/react/models/cash-flow-period.ts';
import { CashflowSidePanelTabs } from 'qonto/react/models/cash-flow-period.ts';
import { SidePanelTabsLoading } from 'qonto/react/components/cash-flow/components/sidepanel/tabs/loading-tabs.tsx';
import type { CashflowForecastEntry } from 'qonto/react/models/cash-flow-forecast-entry';
import { cashFlowSidePanelManager } from 'qonto/react/contexts/cash-flow-sidepanel-context';
import { ForecastEntryWrapper } from '../forecast-entry-wrapper';
import styles from './sidepanel-tabs.strict-module.css';

export function SidePanelTabs({
  bankAccounts,
  isFlowSelected,
  refreshChart,
  selectedCategories,
  selectedInterval,
  data,
  changeTab,
  selectedFrequency,
  forecast,
}: {
  bankAccounts: string[];
  selectedInterval: LabelTableInterval | undefined;
  selectedCategories: CategoriesTableRow[];
  isFlowSelected: boolean;
  refreshChart: () => void;
  changeTab: (tab: CashflowSidePanelTabs) => void;
  data: CashflowSidePanelTab;
  selectedFrequency?: CashflowPeriodRate;
  forecast?: CashflowForecastEntry | null;
}): ReactNode {
  const { formatMessage, formatNumber } = useIntl();
  const abilities = useEmberService('abilities');
  const { featureBooleanCashFlowFirstTimeExperience } = useFlags();

  const { isFirstTimeExperience } = cashFlowSidePanelManager.useCashFlowSidePanel();

  const shouldHideForecastTab =
    featureBooleanCashFlowFirstTimeExperience &&
    !abilities.can('fully interact with forecast cash-flow') &&
    !data.isFutureMonth &&
    !isFirstTimeExperience;

  const handleChangeTab = (): void => {
    const { Actual, Forecast } = CashflowSidePanelTabs;
    changeTab(data.selectedValue === Actual ? Forecast : Actual);
  };

  const transactionsTable = useMemo(
    () => (
      <TransactionsTableWrapper
        bankAccounts={bankAccounts}
        isFlowSelected={isFlowSelected}
        refreshChart={refreshChart}
        selectedCategories={selectedCategories}
        selectedInterval={selectedInterval}
      />
    ),
    [bankAccounts, isFlowSelected, refreshChart, selectedCategories, selectedInterval]
  );

  const tabs = useMemo(() => {
    const formatCurrencyValue = ({
      value,
      currency,
      isForecast = false,
    }: {
      value: string;
      currency: string;
      isForecast?: boolean;
    }): string => {
      const amount = Number(value);
      const flowType = selectedCategories[0]?.flowType;
      const displayAmountSign =
        !isForecast &&
        ((flowType === 'inflows' && amount < 0) || (flowType === 'outflows' && amount > 0));

      return formatNumber(amount, {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        signDisplay: displayAmountSign ? 'exceptZero' : 'never',
      });
    };

    const forecastTab = {
      id: CashflowSidePanelTabs.Forecast,
      title: `${formatMessage({ id: 'cashflow.side-panel.tabs.forecast.label' })} ${formatCurrencyValue({ value: data.tabValues.forecast.value, currency: data.tabValues.forecast.currency, isForecast: true })}`,
      content: (
        <ForecastEntryWrapper
          category={selectedCategories[0]}
          entry={forecast}
          frequency={selectedFrequency}
          interval={selectedInterval}
          isFlowSelected={isFlowSelected}
        />
      ),
      index: 2,
      isDisabled: false,
    };
    const baseTabs = [];

    if (abilities.can('view sidepanel transactions cash-flow')) {
      baseTabs.push({
        id: CashflowSidePanelTabs.Actual,
        title: `${formatMessage({ id: 'cashflow.side-panel.tabs.actual.label' })} ${formatCurrencyValue({ value: data.tabValues.actual.value, currency: data.tabValues.actual.currency })}`,
        content: transactionsTable,
        index: 1,
        isDisabled: data.isFutureMonth,
      });
    }

    if (abilities.can('view sidepanel forecast cash-flow') && !shouldHideForecastTab) {
      baseTabs.push(forecastTab);
    }

    return baseTabs;
  }, [
    forecast,
    selectedCategories,
    selectedInterval,
    formatNumber,
    data,
    formatMessage,
    transactionsTable,
    selectedFrequency,
    abilities,
    isFlowSelected,
    shouldHideForecastTab,
  ]);

  return (
    <nav className={styles['sidepanel-tabs-wrapper']} data-testid="cashflow-sidepanel-tabs">
      <Tabs
        activeId={data.selectedValue}
        changeTab={handleChangeTab}
        className={styles['sidepanel-tabs']}
      >
        {data.isLoading ? (
          <SidePanelTabsLoading />
        ) : (
          <TabList
            className={styles['sidepanel-tab-list']}
            tabs={tabs.map(({ id, title, index, isDisabled }) => ({
              id,
              title,
              'data-test-tabs-nav-item': index,
              'data-test-tabs-nav-item-value': id,
              isDisabled,
            }))}
          />
        )}
        <div className={styles['tab-panel-container']}>
          {tabs.map(tab => (
            <TabPanel
              className={styles['sidepanel-tab-panel']}
              data-test-sidepanel-tab-panel-value={tab.id}
              key={tab.id}
              tabId={tab.id}
            >
              {tab.content}
            </TabPanel>
          ))}
        </div>
      </Tabs>
    </nav>
  );
}
