import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Badge, type BadgeType } from '@repo/design-system-kit';
// @ts-expect-error -- BADGE_TYPE is not typed
import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import type { ActionProps } from 'qonto/react/cards/components/flows/choose-card/internal/action';
import type { FlashCardUpsellProps } from './flash-card';

interface UseFlashCardUpsellAndTrialPropsProps {
  ctaProps: ActionProps;
  flashCardUpsellProps: FlashCardUpsellProps | undefined;
}

export function useFlashCardUpsellAndTrialProps({
  ctaProps,
  flashCardUpsellProps,
}: UseFlashCardUpsellAndTrialPropsProps): {
  badge: ReactNode;
  ctaProps: ActionProps;
} {
  const abilities = useEmberService('abilities');
  const upsellManager = useEmberService('upsell-manager');
  const organizationManager = useEmberService('organization-manager');
  const segment = useEmberService('segment');
  const sentry = useEmberService('sentry');
  const toastFlashMessages = useEmberService('toast-flash-messages');

  const { formatMessage } = useIntl();

  const handleFlashCardUpsell = async (): Promise<void> => {
    try {
      const { organization } = organizationManager;

      segment.track('upsell_flashcard_clicked', {
        price_plan: flashCardUpsellProps?.pricePlan.code,
        country: organization.legalCountry,
      });
      const {
        recommended_recurrence: recurrence,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- recommended_product is not typed
        recommended_product: { code },
      } = await upsellManager.getUpgradeRecommendation('flash_card');

      if (flashCardUpsellProps?.onUpsellSuccess) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- recommended_product is not typed
        flashCardUpsellProps.onUpsellSuccess({ recurrence, addonCode: code });
      }
    } catch (error) {
      toastFlashMessages.toastError(formatMessage({ id: 'toasts.errors.server_error' }));
      sentry.captureException(error);
    }
  };

  if (abilities.can('view flash card')) {
    return { ctaProps, badge: null };
  }

  if (upsellManager.hasAvailableFreeTrial('flash_card')) {
    return {
      badge: (
        <Badge
          data-test-discover-try-badge
          text={formatMessage({ id: 'promotion-system.badge.trial.label' })}
          type={(BADGE_TYPE as { TRY_FOR_FREE: BadgeType }).TRY_FOR_FREE}
        />
      ),
      ctaProps: {
        handleClick: handleFlashCardUpsell,
        text: formatMessage({ id: 'promotion-system.cta.trial' }),
      },
    };
  }

  // User has > 1 day left of initial trial
  if (flashCardUpsellProps?.hasInitialTrialUpsell) {
    return {
      badge: (
        <Badge
          data-test-discover-upsell-badge
          text={formatMessage(
            { id: 'upsell.discover.initial-trial.badge' },
            {
              daysLeft: flashCardUpsellProps.activeTrialRemainingDays,
            }
          )}
          type={(BADGE_TYPE as { TRY_FOR_FREE: BadgeType }).TRY_FOR_FREE}
        />
      ),
      ctaProps: {
        handleClick: handleFlashCardUpsell,
        text: formatMessage({ id: 'upsell.discover.trial.cta' }),
      },
    };
  }

  // User has 1 day left of initial trial
  if (flashCardUpsellProps?.hasInitialTrial) {
    return {
      badge: (
        <Badge
          data-test-discover-badge
          text={formatMessage({ id: 'promotion-system.badge.trial.label' })}
          type={(BADGE_TYPE as { TRY_FOR_FREE: BadgeType }).TRY_FOR_FREE}
        />
      ),
      ctaProps: {
        handleClick: handleFlashCardUpsell,
        text: formatMessage({ id: 'upsell.discover.trial.cta' }),
      },
    };
  }

  return {
    badge: (
      <Badge
        data-test-flash-upsell-badge
        text={formatMessage({ id: 'upsell.add-ons.badge' })}
        type={(BADGE_TYPE as { UPGRADE: BadgeType }).UPGRADE}
      />
    ),
    ctaProps: {
      handleClick: handleFlashCardUpsell,
      text: formatMessage({ id: 'upsell.add-ons.cta' }),
    },
  };
}
