import type { ReactNode } from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import type { PressEvent } from 'react-aria-components';
import { Disclaimer, Tooltip } from '@repo/design-system-kit';
import { AnimatePresence, motion } from 'framer-motion';
import { IconCheckmarkRoundedFilled } from '@repo/design-system-kit/assets/icons';
import {
  IconCrossRoundedFilled,
  IconChevronBottomOutlined,
  IconInfoRoundedOutlined,
} from '@repo/monochrome-icons';
import cx from 'clsx';
import type { FnciResult as FnciResultType } from 'qonto/react/models/check';
import styles from './styles.strict-module.css';

interface FnciResultProps {
  result: FnciResultType;
}

export function FnciResult({ result }: FnciResultProps): ReactNode {
  const { formatMessage } = useIntl();
  const [isFNCIResultOpened, setIsFNCIResultOpened] = useState(false);

  // eslint-disable-next-line camelcase -- snake_case is expected
  const { color, cpt1, cpt2, cpt3, issuing_bank } = result;

  const isGreen = color === 'green';
  const isRed = color === 'red';
  const isOrange = color === 'orange';

  const toggleFNCIresult = (): void => {
    setIsFNCIResultOpened(prev => !prev);
  };

  const getResultMessageKey = (): string | undefined => {
    if (isGreen) {
      return formatMessage({ id: 'checks.fnci.green.key' });
    } else if (isRed) {
      return formatMessage({ id: 'checks.fnci.red.key' });
    } else if (isOrange) {
      return formatMessage({ id: 'checks.fnci.orange.key' });
    }
  };

  return (
    <>
      <div
        aria-expanded={isFNCIResultOpened}
        className={cx(styles.header, 'mb-16')}
        data-test-fnci-result-header
        onClick={toggleFNCIresult}
        onKeyDown={e => {
          if (e.key === 'Enter' || e.key === ' ') {
            toggleFNCIresult();
          }
        }}
        role="button"
        tabIndex={0}
      >
        <div className={styles.result}>
          <p className={cx(styles['result-title'], styles['tooltip-wrapper'])}>
            <span className="body-1" data-test-fnci-result-title>
              {formatMessage({ id: 'checks.fnci.title' })}
            </span>

            <Tooltip
              buttonProps={{
                onPress: (event: PressEvent) => {
                  event.continuePropagation(); // allow to click on the header
                },
              }}
              label={formatMessage({ id: 'checks.fnci.title-tooltip' })}
              needsButton
              placement="top start"
              tooltipTestId="result-tooltip"
            >
              <IconInfoRoundedOutlined aria-hidden="true" className={styles['tooltip-icon']} />
            </Tooltip>
          </p>

          <div className={styles['result-value']}>
            <p className={cx(styles['result-value-key'], 'body-1')} data-test-fnci-result-value>
              {isGreen ? (
                <>
                  <IconCheckmarkRoundedFilled
                    className={cx(styles['result-value-icon'], styles.green)}
                    data-test-fnci-result-green-icon
                  />
                  {formatMessage({ id: 'checks.fnci.green.title' })}
                </>
              ) : null}

              {isRed || isOrange ? (
                <>
                  <IconCrossRoundedFilled
                    className={cx(styles['result-value-icon'], styles.red)}
                    data-test-fnci-result-red-icon
                  />
                  {formatMessage({ id: 'checks.fnci.red.title' })}
                </>
              ) : null}
            </p>

            <span
              className={cx('caption', styles['result-value-description'])}
              data-test-fnci-result-description
            >
              {isGreen
                ? formatMessage({ id: 'checks.fnci.green.description' })
                : formatMessage({ id: 'checks.fnci.red.description' })}
            </span>
          </div>

          <span className={styles.chevron}>
            <IconChevronBottomOutlined />
          </span>
        </div>
      </div>

      <AnimatePresence>
        {isFNCIResultOpened ? (
          <motion.section
            animate={{ opacity: 1, height: 'auto' }}
            className={styles.details}
            exit={{ opacity: 0, height: 0 }}
            initial={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2, ease: 'easeInOut' }}
          >
            <div className={cx(styles.detail, 'mb-16')}>
              <dt className={cx(styles['detail-title'], 'body-1')} data-test-fnci-result-code>
                {formatMessage({ id: 'checks.fnci.code' })}
              </dt>

              <dd className={cx(styles['detail-value'], 'body-1')} data-test-fnci-result-key>
                {getResultMessageKey()}
              </dd>
            </div>

            <div className={cx(styles.detail, 'mb-8')}>
              <p className={cx(styles['detail-title'], styles['tooltip-wrapper'], 'body-1')}>
                <span data-test-fnci-result-verifications>
                  {formatMessage({ id: 'checks.fnci.verifications' })}
                </span>

                <Tooltip
                  label={formatMessage({ id: 'checks.fnci.verifications-tooltip' })}
                  needsButton
                  placement="top start"
                  tooltipTestId="verifications-tooltip"
                >
                  <IconInfoRoundedOutlined aria-hidden="true" className={styles['tooltip-icon']} />
                </Tooltip>
              </p>
            </div>

            <div className={cx(styles.detail, 'mb-8')}>
              <dt className={cx(styles['detail-title'], 'body-1')} data-test-fnci-result-cpt1>
                {formatMessage({ id: 'checks.fnci.cpt1' })}
              </dt>

              <dd className={cx(styles['detail-value'], 'body-1')} data-test-fnci-result-cpt1-value>
                {cpt1}
              </dd>
            </div>

            <div className={cx(styles.detail, 'mb-8')}>
              <dt className={cx(styles['detail-title'], 'body-1')} data-test-fnci-result-cpt2>
                {formatMessage({ id: 'checks.fnci.cpt2' })}
              </dt>

              <dd className={cx(styles['detail-value'], 'body-1')} data-test-fnci-result-cpt2-value>
                {cpt2}
              </dd>
            </div>

            <div className={cx(styles.detail, 'mb-16')}>
              <dt className={cx(styles['detail-title'], 'body-1')} data-test-fnci-result-cpt3>
                {formatMessage({ id: 'checks.fnci.cpt3' })}
              </dt>

              <dd className={cx(styles['detail-value'], 'body-1')} data-test-fnci-result-cpt3-value>
                {cpt3}
              </dd>
            </div>
          </motion.section>
        ) : null}
      </AnimatePresence>

      <div className={styles.detail}>
        <dt className={cx(styles['detail-title'], 'body-1')} data-test-fnci-result-bank>
          {formatMessage({ id: 'checks.fnci.bank' })}
        </dt>

        <dd className={cx(styles['detail-value'], 'body-1')} data-test-fnci-result-bank-value>
          {/* eslint-disable-next-line camelcase -- snake_case is expected */}
          {issuing_bank}
        </dd>
      </div>

      {isRed || isOrange ? (
        <Disclaimer.Block
          className={styles.disclaimer}
          data-test-fnci-result-red-disclaimer=""
          level="warning"
        >
          {formatMessage({ id: 'checks.fnci.red.disclaimer' })}
        </Disclaimer.Block>
      ) : null}
    </>
  );
}
