import type { ComponentPropsWithRef, ReactNode } from 'react';

export function IconStatusCancelled(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path fill="#F95656" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8Z" />
      <path
        fill="#fff"
        d="M8 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8Zm3-4c0-.622-.196-1.228-.56-1.733L6.268 10.44A2.993 2.993 0 0 0 11 8ZM5 8c0 .622.196 1.228.56 1.733L9.733 5.56A2.993 2.993 0 0 0 5 8Z"
      />
    </svg>
  );
}
