import { EmptyStatesInform } from '@repo/domain-kit/pricing';
import { useIntl } from 'react-intl';
import { useThemedAssetPath } from 'qonto/react/hooks/use-themed-asset-path.ts';
import styles from './table-states.strict-module.css';

export function SidepanelEmptyState(): React.ReactNode {
  const { formatMessage } = useIntl();
  const lottieSrc = useThemedAssetPath('/lotties/cash-flow/no-transactions.json');

  return (
    <div className={styles.tableStateContainer} data-testid="cash-flow-sidepanel-empty-state">
      <EmptyStatesInform
        lottieSrc={lottieSrc}
        subtitle={formatMessage({ id: 'cashflow.side-panel.tabs.actual.empty-state.subtitle' })}
        title={formatMessage({ id: 'cashflow.side-panel.tabs.actual.empty-state.title' })}
      />
    </div>
  );
}
