import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function TableOutlined(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="none" {...props}>
      <path
        fill="currentColor"
        d="M10.438 0h-8.88c-.45 0-.81.36-.81.81v10.38c0 .45.36.81.81.81h8.88c.442 0 .81-.36.81-.81V.81c0-.45-.367-.81-.81-.81ZM5.631 11.25H1.558s-.06-.03-.06-.06V9.188h4.133v2.062Zm0-2.813H1.498V6.376h4.133v2.063Zm0-2.812H1.498V3.562h4.133v2.063Zm0-2.813H1.498V.81s.023-.06.06-.06h4.073v2.063Zm4.867 8.378s-.03.06-.06.06H6.381V9.187h4.117v2.003Zm0-2.752H6.381V6.374h4.117v2.063Zm0-2.813H6.381V3.562h4.117v2.063Zm0-2.813H6.381V.75h4.057s.06.03.06.06v2.002ZM11.255 2.813h-.007v.75h.008v-.75ZM11.255 5.625h-.007v.75h.008v-.75ZM11.255 8.438h-.007v.75h.008v-.75Z"
      />
    </svg>
  );
}
