import { FormattedMessage, FormattedNumber } from 'react-intl';
import type { ReactElement } from 'react';
import cx from 'clsx';
import { Separator } from 'react-aria-components';
import { Button, Disclaimer } from '@repo/design-system-kit';
import {
  EarlyRepaymentOption,
  type RepaymentOption,
} from 'qonto/react/api/models/repayment-option';
import styles from './styles.strict-module.css';

export interface OverviewProps {
  repaymentOption: RepaymentOption;
  account: {
    name: string;
    balance: {
      value: number;
      currency: string;
    };
  };
  onConfirm: () => void;
  isLoading: boolean;
  errorMessage?: string;
}

function formatCurrencyAmount(value: number, currency: string): ReactElement {
  return (
    <FormattedNumber
      currency={currency}
      maximumFractionDigits={2}
      minimumFractionDigits={2}
      style="currency"
      value={value}
    />
  );
}

export function Overview({
  account,
  repaymentOption,
  onConfirm,
  isLoading,
  errorMessage,
}: OverviewProps): ReactElement {
  const details = [
    {
      key: 'account',
      label: <FormattedMessage id="financing.pay-later.early-repayment.summary.account.label" />,
      value: (
        <>
          <div data-testid="account-name">{account.name}</div>
          <span className={cx(styles['account-balance'], 'body-2')} data-testid="account-balance">
            {formatCurrencyAmount(account.balance.value, account.balance.currency)}
          </span>
        </>
      ),
    },
    {
      key: 'date',
      label: <FormattedMessage id="financing.pay-later.early-repayment.summary.date.label" />,
      value: <FormattedMessage id="financing.pay-later.early-repayment.summary.date.value" />,
    },
    {
      key: 'capital-amount',
      label:
        repaymentOption.earlyRepaymentOption === EarlyRepaymentOption.Next ? (
          <FormattedMessage id="financing.pay-later.early-repayment.summary.principal-amount.label" />
        ) : (
          <FormattedMessage id="financing.pay-later.early-repayment.summary.financed-amount.label" />
        ),
      value: formatCurrencyAmount(
        Number(repaymentOption.capitalAmount.value),
        repaymentOption.capitalAmount.currency
      ),
    },
    {
      key: 'interest',
      label:
        repaymentOption.earlyRepaymentOption === EarlyRepaymentOption.Next ? (
          <FormattedMessage id="financing.pay-later.early-repayment.summary.monthly-interest.label" />
        ) : (
          <FormattedMessage id="financing.pay-later.early-repayment.summary.interest.label" />
        ),
      value: (
        <>
          <div data-testid="current-interest">
            {formatCurrencyAmount(
              Number(repaymentOption.interestAmount.value),
              repaymentOption.interestAmount.currency
            )}
          </div>
          {repaymentOption.previousInterestAmount ? (
            <span
              className={cx(styles['previous-interest'], 'body-2')}
              data-testid="previous-interest"
            >
              {formatCurrencyAmount(
                Number(repaymentOption.previousInterestAmount.value),
                repaymentOption.previousInterestAmount.currency
              )}
            </span>
          ) : null}
        </>
      ),
    },
  ];

  if (repaymentOption.penaltyFeesAmount) {
    details.push({
      key: 'penalty-fees',
      label: (
        <FormattedMessage id="financing.pay-later.early-repayment.summary.penalty-fees.label" />
      ),
      value: formatCurrencyAmount(
        Number(repaymentOption.penaltyFeesAmount.value),
        repaymentOption.penaltyFeesAmount.currency
      ),
    });
  }

  return (
    <section className={styles.container}>
      <h1 className="title-1 mb-32" data-testid="overview-title">
        <FormattedMessage id="financing.pay-later.early-repayment.summary.title" />
      </h1>
      <dl className={styles.details} data-test-details-list>
        {details.map(detail => (
          <div className={styles['details-row']} key={detail.key}>
            <dt className="body-2" data-testid={`${detail.key}-label`}>
              {detail.label}
            </dt>
            <dd
              className={cx(styles['details-row-text'], 'body-1')}
              data-testid={`${detail.key}-value`}
            >
              {detail.value}
            </dd>
          </div>
        ))}
        <Separator className={styles.separator} />
        <div className={styles['details-row']}>
          <dt className="title-4" data-testid="total-label">
            <FormattedMessage id="financing.pay-later.early-repayment.summary.total-amount.label" />
          </dt>
          <dd className={cx(styles['details-row-text'], 'title-4')} data-testid="total-value">
            {formatCurrencyAmount(
              Number(repaymentOption.totalAmount.value),
              repaymentOption.totalAmount.currency
            )}
          </dd>
        </div>
      </dl>
      {Boolean(errorMessage) && (
        <Disclaimer.Block className={styles.disclaimer} level="error">
          {errorMessage}
        </Disclaimer.Block>
      )}
      <Button className={styles.button} data-testid="cta" isLoading={isLoading} onPress={onConfirm}>
        <FormattedMessage id="financing.pay-later.early-repayment.summary.cta" />
      </Button>
    </section>
  );
}
