import type { Amount } from 'qonto/react/models/amount';
import type { CashflowPeriodRate } from 'qonto/react/models/cash-flow-period';
import type { CashBurnComputationType } from 'qonto/react/models/cash-flow-timeseries';
import type { CashflowParentCategory } from './cash-flow-category';
import type { CashflowForecastEntry } from './cash-flow-forecast-entry';

export interface TimeframeInterval {
  inclusive_start_date: string; // ISO UTC date
  exclusive_end_date: string; // ISO UTC date
  requested_at?: string; // ISO UTC date
  aggregation_interval?: CashflowPeriodRate;
}

export interface CategoryColumnData {
  inflow: CashflowParentCategory[];
  outflow: CashflowParentCategory[];
}

export interface CashflowSubcategoryDataPoint {
  subcategory_id: string;
  transactions_count: number;
  amount_sum: Amount<string>;
  forecast?: CashflowForecastEntry | null;
  alerts?: CashflowAlert[];
}

export interface CashflowCategoryDataPoint {
  category_id: string;
  transactions_count: number;
  amount_sum: Amount<string>;
  forecast?: CashflowForecastEntry | null;
  subcategories_data_points?: CashflowSubcategoryDataPoint[];
  alerts?: CashflowAlert[];
}

export interface CashflowSideForecastDto {
  amount_sum: Amount<string>;
  actual_percentage?: string;
  projected_percentage?: string;
  gap_to_forecast: Amount<string>;
}

export interface CashflowSideDatapoint {
  amount_sum: Amount<string>;
  growth_percentage?: number;
  transactions_count: number;
  categories_data_points: CashflowCategoryDataPoint[];
  forecast?: CashflowSideForecastDto;
  projected_amount?: Amount<string>;
  alerts?: CashflowAlert[];
}

export interface CashflowVAT {
  credit_amount: Amount<string>;
  debit_amount: Amount<string>;
  net_amount?: Amount<string>;
}

export interface CashflowTimeframeDatapoint {
  transactions_count: number;
  starting_amount?: Amount<string>;
  ending_amount?: Amount<string>;
  forecasted_starting_amount?: Amount<string>;
  forecasted_ending_amount?: Amount<string>;
  projected_starting_amount?: Amount<string>;
  projected_ending_amount?: Amount<string>;
  inflows: CashflowSideDatapoint;
  outflows: CashflowSideDatapoint;
  vat?: CashflowVAT;
}

export interface CashflowTimeframe {
  cash_flow_data: CashflowTimeframeDatapoint;
  inclusive_start_date: string; // ISO UTC date
  exclusive_end_date: string; // ISO UTC date
  requested_at?: string; // ISO UTC date
  aggregation_interval?: CashflowPeriodRate;
}

export interface CashBurnData {
  amount: Amount<string>;
  computation_type: CashBurnComputationType;
}

export type CurrentBalanceAmount = Amount<string>;

export interface VatData {
  credit_amount: Amount<string>;
  debit_amount: Amount<string>;
  net_amount: Amount<string>;
}

export interface CashflowTimeseries {
  timeframe: TimeframeInterval;
  categories_data: CategoryColumnData;
  timeframes: CashflowTimeframe[];
  current_balance_amount?: CurrentBalanceAmount;
  vat_data: VatData;
  cash_burn_data: CashBurnData;
}

export interface CashflowTimeframeRequest {
  start_year_month: string; // YYYY-MM string date format
  end_year_month: string; // YYYY-MM string date format
}

export interface CashflowTimeseriesRequest {
  bank_account_ids: string[] | undefined;
  aggregation_interval?: CashflowPeriodRate;
  start_year_month: string; // YYYY-MM string date format
  end_year_month: string; // YYYY-MM string date format
}

export const CASHFLOW_REQUEST_DATE_FORMAT = 'YYYY-MM';

export const CASHFLOW_FORECASTED_MONTHS_DEFAULT = 144;

export enum CashflowAlert {
  MaxPercentageExceeded = 'max_percentage_exceeded',
}
