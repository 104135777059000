/* eslint-disable react/jsx-no-leaked-render -- && is better than empty ternary */
import { type ReactNode } from 'react';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import { getCardShortName } from '@repo/cards-kit/utils';
import type { CardLevel } from '@repo/cards-kit/constants';
import type {
  ChooseCardLayoutHeaderProps,
  ChooseCardLayoutSubtitleProps,
} from 'qonto/react/cards/components/flows/choose-card-layout/internal/choose-card-layout-props.interface';
import type { Estimate } from 'qonto/react/cards/types/estimates';
import type { OptionPrice } from 'qonto/react/cards/types/option-price';
import { CARD_TYPES } from 'qonto/constants/cards';
import { Pricing } from 'qonto/react/cards/components/flows/choose-card/pricing';
import styles from './header.strict-module.css';

export interface CoreHeaderProps {
  badge?: ReactNode;
  cardEstimates: Estimate | null;
  optionPrice?: OptionPrice;
  shouldHidePricing?: boolean;
  subtitle: string;
  layoutHeaderProps?: ChooseCardLayoutHeaderProps;
  layoutSubtitleProps?: ChooseCardLayoutSubtitleProps;
}

interface HeaderProps extends CoreHeaderProps {
  cardLevel: CardLevel;
}

export function Header({
  badge,
  cardEstimates,
  optionPrice,
  cardLevel,
  shouldHidePricing = false,
  subtitle,
  layoutHeaderProps,
  layoutSubtitleProps,
  ...props
}: HeaderProps): ReactNode {
  const intl = useIntl();

  const isPhysical = CARD_TYPES.PHYSICALS.includes(cardLevel);

  return (
    <header className={styles.header} {...props}>
      <h2
        className={styles.title}
        data-test-title
        ref={layoutHeaderProps?.ref}
        style={{
          minHeight: layoutHeaderProps?.minHeight,
        }}
      >
        <div className={styles['name-wrapper']}>
          <span className={cx('title-3', styles.name)}>{getCardShortName(cardLevel, intl)}</span>

          {badge}
        </div>

        {cardEstimates && !shouldHidePricing && (
          <div className={styles.pricing} data-test-pricing>
            <Pricing cardEstimates={cardEstimates} optionPrice={optionPrice} />
          </div>
        )}
      </h2>

      <p
        className={cx('body-1', styles.subtitle, isPhysical && styles.physical)}
        data-test-subtitle
        ref={layoutSubtitleProps?.ref}
        style={{
          minHeight: layoutSubtitleProps?.minHeight,
        }}
      >
        {subtitle}
      </p>
    </header>
  );
}
