import { type ComponentPropsWithRef, type ReactNode } from 'react';

export function StatusScheduledXS(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="currentColor"
      {...props}
    >
      <rect width="12" height="12" rx="6" fill="white" />
      <circle cx="6" cy="6" r="6" fill="#9B81F6" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 4.5C6.20711 4.5 6.375 4.66789 6.375 4.875V5.72972L7.24359 6.01924C7.44006 6.08474 7.54625 6.29711 7.48076 6.49359C7.41526 6.69006 7.20289 6.79625 7.00641 6.73076L5.88141 6.35576C5.72829 6.30471 5.625 6.16141 5.625 6V4.875C5.625 4.66789 5.79289 4.5 6 4.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 8.25C7.24264 8.25 8.25 7.24264 8.25 6C8.25 4.75736 7.24264 3.75 6 3.75C4.75736 3.75 3.75 4.75736 3.75 6C3.75 7.24264 4.75736 8.25 6 8.25ZM6 9C7.65685 9 9 7.65685 9 6C9 4.34315 7.65685 3 6 3C4.34315 3 3 4.34315 3 6C3 7.65685 4.34315 9 6 9Z"
        fill="white"
      />
    </svg>
  );
}
