import { FormattedMessage, useIntl } from 'react-intl';
import { type ReactElement } from 'react';
import { Button } from '@repo/design-system-kit';
import { useSegment } from '@repo/poly-hooks/segment';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
// @ts-expect-error The file needs to be migrated to TypeScript
import { ErrorInfo } from 'qonto/utils/error-info';
import { useFetchSignatureRequest } from 'qonto/react/financing/hooks/use-fetch-signature-request';
import styles from './styles.strict-module.css';

type TransitionToFlowType = ({
  flowName,
  queryParams,
}: {
  flowName: string;
  queryParams: { origin: string; signatureUrl: string };
}) => void;

export interface FailureProps {
  goToCockpit: () => void;
  transitionToFlow: TransitionToFlowType;
}

interface ErrorInfoType {
  shouldSendToSentry: boolean;
  for: (error: Error) => ErrorInfoType;
}

export function Failure({ goToCockpit, transitionToFlow }: FailureProps): ReactElement {
  const toastFlashMessages = useEmberService('toastFlashMessages');
  const segment = useSegment();
  const { formatMessage } = useIntl();
  const sentry = useEmberService('sentry');

  const onSignatureRequestError = (error: unknown): void => {
    toastFlashMessages.toastError(
      formatMessage({ id: 'financing.pay-later.empty-state.education.error-toast' })
    );

    const errorInfo = (ErrorInfo as ErrorInfoType).for(error as Error);
    if (errorInfo.shouldSendToSentry) {
      sentry.captureException(error);
    }
  };
  const { isSignatureRequestLoading, retrievePayLaterSignatureUrl } =
    useFetchSignatureRequest(onSignatureRequestError);

  const handlePrimaryCta = async (): Promise<void> => {
    segment.track('pay-later_try-again_clicked', { origin: 'yousign_failure' });
    const data = await retrievePayLaterSignatureUrl();
    if (data?.url) {
      transitionToFlow({
        flowName: 'pay-later-application',
        queryParams: { origin: 'failure', signatureUrl: data.url },
      });
    }
  };

  const handleSecondaryCta = (): void => {
    segment.track('pay-later-cockpit_displayed', { origin: 'failure' });
    goToCockpit();
  };

  return (
    <div className={styles.failure}>
      <StaticThemedAsset
        assetPath="/illustrations/ill-cp-general-external-error.svg"
        className={styles['failure-illustration']}
        data-test-failure-illustration
      />
      <h1 className="title-2 mb-16 align-center" data-test-failure-title>
        <FormattedMessage id="financing.pay-later.application-flow.failure.title" />
      </h1>
      <p className="body-1 align-center mb-32" data-test-failure-description>
        <FormattedMessage id="financing.pay-later.application-flow.failure.description" />
      </p>
      <Button
        className="mb-16"
        data-test-failure-primary-cta
        isLoading={isSignatureRequestLoading}
        onPress={handlePrimaryCta}
      >
        <FormattedMessage id="financing.pay-later.application-flow.failure.primary-cta" />
      </Button>
      <Button data-test-failure-secondary-cta onPress={handleSecondaryCta} variant="tertiary">
        <FormattedMessage id="financing.pay-later.application-flow.failure.secondary-cta" />
      </Button>
    </div>
  );
}
