/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import { Label, type CountryCode } from '@repo/design-system-kit';
import { useEffect, useRef, useState, type ReactNode } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import { useSegment } from '@repo/poly-hooks/segment';
import { IconCrossRoundedFilled } from '@repo/monochrome-icons';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { VAT_RATES_PER_COUNTRY } from 'qonto/react/components/cash-flow/constants/vat';
import styles from './vat-rate-field.strict-module.css';
import { AutoWidthInput } from './auto-width-input';
import { ToggleButton } from './toggle-button';

interface VatRateFieldsProps {
  categoryVatRate: number | null;
  onVatRateChange: () => void;
}
export function VatRateFields({ categoryVatRate, onVatRateChange }: VatRateFieldsProps): ReactNode {
  const { organization } = useOrganizationManager();
  const { formatNumber, formatMessage } = useIntl();
  const segment = useSegment();
  const countryCode = organization.legalCountry as CountryCode;
  const defaultRates = VAT_RATES_PER_COUNTRY[countryCode] ?? VAT_RATES_PER_COUNTRY.FR ?? [];
  const inputRef = useRef<HTMLInputElement>(null);

  const getInitialVatValue = (): string => {
    if (categoryVatRate === null) return '0';
    if (defaultRates.includes(categoryVatRate.toString())) return categoryVatRate.toString();
    return 'other';
  };

  const getInitialOtherValue = (): string => {
    return getInitialVatValue() === 'other' ? (categoryVatRate?.toString() ?? '') : '';
  };

  const [hasValueChanged, setHasValueChanged] = useState(false);
  const [selectedRate, setSelectedRate] = useState<string>(getInitialVatValue());
  const [otherValue, setOtherValue] = useState<string>(getInitialOtherValue());

  const isOtherRateSelected = selectedRate === 'other';
  const hasOtherValue = otherValue !== '';

  useEffect(() => {
    if (hasValueChanged) {
      segment.track('cash-flow-categories_category_vat_edited');
      onVatRateChange();
      setHasValueChanged(false);
    }
  }, [onVatRateChange, isOtherRateSelected, hasOtherValue, hasValueChanged, segment]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    if (isOtherRateValid(value)) {
      setOtherValue(value);
      setHasValueChanged(true);
    }
  };

  const getHiddenInputValue = (): string => {
    if (isOtherRateSelected) return otherValue || '0';
    return selectedRate;
  };

  const handleDefaultToggleChange = (value: string): void => {
    const newRate = selectedRate === value ? '0' : value;
    setSelectedRate(newRate);
    setHasValueChanged(newRate !== '0' || selectedRate !== '0');
  };

  const handleOtherToggleChange = (): void => {
    setSelectedRate(selectedRate === 'other' ? '0' : 'other');
    setHasValueChanged(true);
  };

  return (
    <div>
      <Label data-testid="category-details-select-vat-label" id="vat-rate-label">
        {formatMessage({ id: 'categories-management.create-category.vat.label' })}
      </Label>
      <input name="vatRate" type="hidden" value={getHiddenInputValue()} />
      <div className={styles['toggle-button-group']}>
        <AnimatePresence initial={false}>
          {!isOtherRateSelected ? (
            <motion.div
              animate={{ opacity: 1, width: 'auto' }}
              className={styles['default-group']}
              exit={{ opacity: 0, width: '0%' }}
              initial={{ opacity: 1, width: '0%' }}
              key="default-group"
              transition={{ duration: 0.2 }}
            >
              {defaultRates.map(value => (
                <ToggleButton
                  data-testid={`vat-rate-${value}`}
                  isSelected={selectedRate === value}
                  key={value}
                  onChange={() => {
                    handleDefaultToggleChange(value);
                  }}
                >
                  {formatNumber(Number(value))}%
                </ToggleButton>
              ))}
            </motion.div>
          ) : null}
          <div className={styles['other-group']}>
            <ToggleButton
              data-testid="vat-rate-other"
              isSelected={selectedRate === 'other'}
              onChange={handleOtherToggleChange}
            >
              {formatMessage({ id: 'categories-management.create-category.vat.other.chip' })}
              {isOtherRateSelected ? <IconCrossRoundedFilled height={12} width={12} /> : null}
            </ToggleButton>
            {isOtherRateSelected ? (
              <motion.div
                animate={{ opacity: 1, width: '100%' }}
                className={styles['vat-input-wrapper']}
                exit={{ opacity: 0, width: '0%' }}
                initial={{ opacity: 0, width: '0%' }}
                key="other-vat-rate-field"
                onClick={() => inputRef.current?.focus()}
                transition={{ duration: 0.2 }}
              >
                <AutoWidthInput
                  className={styles['vat-input']}
                  data-testid="vat-rate-other-input"
                  inputMode="decimal"
                  inputRef={inputRef}
                  onChange={onInputChange}
                  placeholder="0"
                  value={otherValue}
                />
                <span className={cx(styles['percentage-sign'], hasOtherValue && styles.active)}>
                  %
                </span>
              </motion.div>
            ) : null}
          </div>
        </AnimatePresence>
      </div>
    </div>
  );
}

/**
 Accepted values:
 - empty string: ''
 - float: '12.34' between 0 and 100
 - integer: '12' between 0 and 100
 */
const isOtherRateValid = (value: string): boolean => {
  if (value === '') return true;
  if (!/^\d*\.?\d*$/.test(value)) return false;
  const numValue = parseFloat(value);
  return !isNaN(numValue) && numValue >= 0 && numValue <= 100;
};
