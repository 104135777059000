/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import type { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'clsx';
import { Sidebar } from '@repo/cards-kit/components';
import { Tooltip } from '@repo/design-system-kit';
import { IconInfoRoundedOutlined } from '@repo/monochrome-icons';
import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import styles from './validity-period.strict-module.css';

interface ValidityPeriodProps {
  preExpiresAt: string;
}

export function ValidityPeriod({ preExpiresAt }: ValidityPeriodProps): ReactNode {
  const { formatMessage, locale } = useIntl();

  const expirationDate: string = dateToken({
    date: preExpiresAt,
    locale,
    token: DATE_FORMAT_TOKENS.DATE_TIME_S,
  });

  return (
    <Sidebar.Section title={formatMessage({ id: 'cards.sidebar.validity-period.title' })}>
      <div className={cx('body-2', styles.content)} data-test-section-validity-period-content="">
        <div className={styles.description}>
          <span>
            <FormattedMessage id="cards.sidebar.validity-period.description" />{' '}
          </span>
          <Tooltip
            data-test-section-validity-period-content-tooltip=""
            label={formatMessage({ id: 'cards.sidebar.validity-period.tooltip' })}
            needsButton
            placement="bottom"
          >
            <IconInfoRoundedOutlined aria-hidden="true" className={cx('ml-8', styles.icon)} />
          </Tooltip>
        </div>
        <div className={styles.date}>{expirationDate}</div>
      </div>
    </Sidebar.Section>
  );
}
