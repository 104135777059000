import cx from 'clsx';
import type { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, LottiePlayer } from '@repo/design-system-kit';
import { useThemedAssetPath } from 'qonto/react/hooks/use-themed-asset-path';
import styles from './styles.strict-module.css';

export interface SettlementProps {
  onComplete: () => void;
}

export function Settlement({ onComplete }: SettlementProps): ReactElement {
  return (
    <section className={styles.container}>
      <LottiePlayer
        className={cx(styles.illustration, 'mb-32')}
        data-testid="settlement-illustration"
        path={useThemedAssetPath('/lotties/hourglass.json')}
      />

      <div className={cx(styles.content, 'mb-32')}>
        <h1 className="title-1 mb-16" data-testid="settlement-title">
          <FormattedMessage id="financing.pay-later.early-repayment.success.title" />
        </h1>
        <p className="body-1 align-center" data-testid="settlement-description">
          <FormattedMessage id="financing.pay-later.early-repayment.success.description" />
        </p>
      </div>

      <Button data-testid="settlement-cta" onPress={onComplete}>
        <FormattedMessage id="financing.pay-later.early-repayment.success.cta" />
      </Button>
    </section>
  );
}
