import { IconWarningSignFilled } from '@repo/monochrome-icons';
import cx from 'clsx';
import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import styles from './missing-information.strict-module.css';

interface MissingInformationProps {
  shouldShow?: boolean;
}

export function MissingInformation({ shouldShow }: MissingInformationProps): ReactNode {
  const { formatMessage } = useIntl();

  if (!shouldShow) return null;

  return (
    <div className={styles.container} data-test-missing-information>
      <IconWarningSignFilled className={styles.icon} />
      <span className={cx('body-2', styles.text)}>
        {formatMessage({ id: 'organizations.profile.company_profile.missing_information' })}
      </span>
    </div>
  );
}
