import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useSegment } from '@repo/poly-hooks/segment';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { EmptyState } from 'qonto/react/components/empty-state';
import styles from './empty-state.strict-module.css';

export function F24EmptyState(): ReactNode {
  const { formatMessage } = useIntl();
  const segment = useSegment();
  const flowLinkManager = useEmberService('flowLinkManager');

  const ctaCallback = (): void => {
    segment.track('f24_create-flow_start');

    void flowLinkManager.transitionTo({
      name: 'f24-manual-declaration',
      stepId: 'form-ordinario',
    });
  };

  const emptyStateOptions = {
    title: formatMessage({ id: 'f24.empty-state.title' }),
    subtitle: formatMessage({ id: 'f24.empty-state.subtitle' }),
    lottieSrc: '/lotties/f24/empty-state.json',
    button: {
      label: formatMessage({ id: 'f24.pay-f24-cta' }),
      callback: ctaCallback,
    },
  };

  return (
    <div className={styles['empty-state-container']} data-test-empty-state>
      <EmptyState options={emptyStateOptions} />
    </div>
  );
}
