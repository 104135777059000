import cx from 'clsx';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import type { IncludedTransaction } from 'qonto/react/financing/api';
import { TransactionHistoryRow } from './internal/transaction-history-row';
import styles from './styles.strict-module.css';

interface TransactionHistoryProps {
  transactions: IncludedTransaction[];
}

export function TransactionHistory({ transactions }: TransactionHistoryProps): ReactNode {
  return (
    <div data-testid="transaction-history">
      <p className={cx('body-1', 'mb-12', styles.title)} data-testid="transaction-history-title">
        <FormattedMessage id="financing.pay-later.cockpit.details.transaction-history.title" />
      </p>

      <div className={styles['transaction-history-rows']} data-testid="transaction-history-rows">
        {transactions.map(transaction => (
          <TransactionHistoryRow key={transaction.id} transaction={transaction} />
        ))}
      </div>
    </div>
  );
}
