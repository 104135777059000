import { createContext, type ReactNode, useCallback, useContext, useMemo, useReducer } from 'react';
import dayjs, { type QUnitType } from 'dayjs';
import type { Amount } from 'qonto/react/models/amount';
import { getFormulaBoundDate } from 'qonto/react/models/cash-flow-forecast-formula.ts';
import type {
  CategoriesTableColumn,
  CategoriesTableRow,
} from '../components/cash-flow/models/categories-table-display';
import type { LabelTableInterval } from '../components/cash-flow/models/labels-cashflow-display';
import {
  CashflowFrequencyToInterval,
  CashflowPeriodRate,
  CashflowSidePanelTabs,
} from '../models/cash-flow-period';
import {
  type CashflowForecastEntry,
  CashflowForecastEntrySource,
} from '../models/cash-flow-forecast-entry';

export interface CashflowSidePanelTab {
  selectedValue: CashflowSidePanelTabs;
  tabValues: {
    actual: Amount<string>;
    forecast: Amount<string>;
  };
  isFutureMonth: boolean;
  isLoading: boolean;
}

export type SidepanelMode = 'default' | 'flashForecast' | 'importForecast';

export interface CashFlowSidePanelPayload {
  forecast?: CashflowForecastEntry | null;
  selectedCategories: CategoriesTableRow[];
  selectedInterval: LabelTableInterval | undefined;
  isFlowSelected: boolean;
  tab: CashflowSidePanelTab;
  isFirstTimeExperience?: boolean;
}

interface ContextState extends CashFlowSidePanelPayload {
  isVisible: boolean;
  selectedFrequency?: CashflowPeriodRate;
  bankAccounts: string[];
  forecastEdit: {
    isDirty: boolean;
    isVisible: boolean;
  };
  isFirstTimeExperience: boolean;
  mode: SidepanelMode;
}

interface ContextActions {
  openSidepanelWith: (payload: CashFlowSidePanelPayload) => void;
  closeSidepanel: (forceClose?: boolean) => void;
  refreshChart: () => void;
  periodNavigation: {
    onNextMonth?: () => void;
    isLastPeriod?: boolean;
    onPreviousMonth?: () => void;
    isFirstPeriod?: boolean;
  };
  updateSidepanelMode: (mode: SidepanelMode) => void;
  changeSidepanelTab: (tab: CashflowSidePanelTabs) => void;
  setForecastEditDirtyState: (isDirty: boolean) => void;
  setForecastEditVisibility: (isVisible: boolean) => void;
}

export type CashFlowSidePanelContextType = ContextState & ContextActions;

const initialState: ContextState = {
  isVisible: false,
  selectedCategories: [],
  selectedInterval: undefined,
  selectedFrequency: CashflowPeriodRate.Monthly,
  bankAccounts: [],
  isFlowSelected: false,
  tab: {
    selectedValue: CashflowSidePanelTabs.Actual,
    tabValues: {
      actual: {
        value: '0',
        currency: 'EUR',
      },
      forecast: {
        value: '0',
        currency: 'EUR',
      },
    },
    isFutureMonth: false,
    isLoading: true,
  },
  mode: 'default',
  forecastEdit: {
    isDirty: false,
    isVisible: false,
  },
  forecast: undefined,
  isFirstTimeExperience: false,
};

const CashFlowSidePanelContext = createContext<CashFlowSidePanelContextType | undefined>(undefined);

type Action =
  | {
      type: 'OPEN_SIDEPANEL';
      payload: CashFlowSidePanelPayload;
    }
  | { type: 'CLOSE_SIDEPANEL' }
  | { type: 'UPDATE_MODE'; payload: { mode: SidepanelMode } }
  | {
      type: 'UPDATE_INTERVAL';
      payload: {
        interval: LabelTableInterval | undefined;
        tab: {
          tabValues: {
            actual: Amount<string>;
            forecast: Amount<string>;
          };
          isFutureMonth: boolean;
        };
        forecast: CashflowForecastEntry | null;
      };
    }
  | { type: 'CHANGE_TAB'; payload: { selectedTab: CashflowSidePanelTabs } }
  | { type: 'UPDATE_TAB_LOADING'; payload: { isLoading: boolean } }
  | {
      type: 'UPDATE_FORECAST_EDIT_DIRTY_STATE';
      payload: { isDirty: boolean };
    }
  | {
      type: 'UPDATE_FORECAST_EDIT_VISIBILITY';
      payload: { isVisible: boolean };
    };

function reducer(state: ContextState, action: Action): ContextState {
  switch (action.type) {
    case 'OPEN_SIDEPANEL':
      return {
        isVisible: true,
        selectedCategories: action.payload.selectedCategories,
        selectedInterval: action.payload.selectedInterval,
        isFlowSelected: action.payload.isFlowSelected,
        bankAccounts: state.bankAccounts,
        tab: action.payload.tab,
        forecast: action.payload.forecast,
        mode: 'default',
        forecastEdit: {
          isVisible: false,
          isDirty: false,
        },
        isFirstTimeExperience: action.payload.isFirstTimeExperience || false,
      };
    case 'CLOSE_SIDEPANEL':
      return initialState;
    case 'UPDATE_INTERVAL':
      return {
        ...state,
        selectedInterval: action.payload.interval,
        tab: {
          ...state.tab,
          ...action.payload.tab,
          isLoading: false,
          selectedValue: action.payload.tab.isFutureMonth
            ? CashflowSidePanelTabs.Forecast
            : state.tab.selectedValue,
        },
        forecast: action.payload.forecast,
      };
    case 'CHANGE_TAB': {
      return {
        ...state,
        tab: {
          ...state.tab,
          selectedValue: action.payload.selectedTab,
        },
      };
    }
    case 'UPDATE_MODE': {
      return {
        ...state,
        mode: action.payload.mode,
      };
    }
    case 'UPDATE_TAB_LOADING': {
      return {
        ...state,
        tab: {
          ...state.tab,
          isLoading: action.payload.isLoading,
        },
      };
    }
    case 'UPDATE_FORECAST_EDIT_DIRTY_STATE': {
      return {
        ...state,
        forecastEdit: {
          ...state.forecastEdit,
          isDirty: action.payload.isDirty,
        },
      };
    }
    case 'UPDATE_FORECAST_EDIT_VISIBILITY': {
      return {
        ...state,
        forecastEdit: {
          ...state.forecastEdit,
          isVisible: action.payload.isVisible,
        },
      };
    }
    default:
      return state;
  }
}

interface CashFlowSidePanelProviderProps {
  children: ReactNode;
  selectedFrequency?: CashflowPeriodRate;
  bankAccounts: string | undefined;
  onSelectInterval?: (interval: LabelTableInterval | undefined) => void;
  onRefreshChart?: () => void;
  periodNavigation: {
    onNextMonth?: () => void;
    isLastPeriod?: boolean;
    onPreviousMonth?: () => void;
    isFirstPeriod?: boolean;
    startDate: string;
    endDate: string;
  };
  categoriesData: {
    inflows: CategoriesTableRow[];
    outflows: CategoriesTableRow[];
    outflowSums: CategoriesTableColumn[];
    inflowSums: CategoriesTableColumn[];
  };
}

export function CashFlowSidePanelProvider({
  children,
  onSelectInterval,
  onRefreshChart,
  selectedFrequency,
  bankAccounts,
  periodNavigation,
  categoriesData,
}: CashFlowSidePanelProviderProps): ReactNode {
  const [state, dispatch] = useReducer(reducer, initialState);

  const flatCategoriesData = useMemo(() => {
    return [...categoriesData.inflows, ...categoriesData.outflows].flatMap(category => [
      category,
      ...(category.subRows ?? []),
    ]);
  }, [categoriesData.inflows, categoriesData.outflows]);

  const bankAccountsCollection = useMemo(() => {
    return bankAccounts ? bankAccounts.split(',') : [];
  }, [bankAccounts]);

  const openSidepanelWith = useCallback(
    ({
      selectedCategories,
      selectedInterval,
      isFlowSelected,
      tab,
      forecast,
      isFirstTimeExperience = false,
    }: CashFlowSidePanelPayload) => {
      dispatch({
        type: 'OPEN_SIDEPANEL',
        payload: {
          selectedCategories,
          selectedInterval,
          isFlowSelected,
          tab,
          forecast,
          isFirstTimeExperience,
        },
      });
      onSelectInterval?.(selectedInterval);
    },
    [onSelectInterval]
  );

  const wrappedPeriodNavigation = useMemo(() => {
    const setSelectedInterval = ({ isPreviousPeriod = true }): void => {
      dispatch({ type: 'UPDATE_TAB_LOADING', payload: { isLoading: true } });
      let frequency: QUnitType;
      if (CashflowPeriodRate.Quarterly === selectedFrequency) {
        frequency = 'quarter';
      } else {
        frequency = selectedFrequency === CashflowPeriodRate.Yearly ? 'year' : 'month';
      }

      let interval;

      if (isPreviousPeriod) {
        interval = {
          start: dayjs(state.selectedInterval?.start).subtract(1, frequency).valueOf(),
          end: dayjs(state.selectedInterval?.end).subtract(1, frequency).valueOf(),
        };
      } else {
        interval = {
          start: dayjs(state.selectedInterval?.start).add(1, frequency).valueOf(),
          end: dayjs(state.selectedInterval?.end).add(1, frequency).valueOf(),
        };
      }

      const isSameInterval = (columnInterval: { start: number; end: number }): boolean => {
        const intervalDate = dayjs(interval.start);
        const columnIntervalData = dayjs(columnInterval.start);

        return (
          intervalDate.month() === columnIntervalData.month() &&
          intervalDate.year() === columnIntervalData.year()
        );
      };

      const categoryData = flatCategoriesData.find(
        row => row.id === state.selectedCategories[0]?.id
      );

      let columnData: CategoriesTableColumn | undefined;

      if (state.isFlowSelected) {
        columnData =
          state.selectedCategories[0]?.flowType === 'inflows'
            ? categoriesData.inflowSums.find(col => isSameInterval(col.interval))
            : categoriesData.outflowSums.find(col => isSameInterval(col.interval));
      } else {
        columnData = categoryData?.columns.find(col => isSameInterval(col.interval));
      }

      const forecastValue = {
        value: columnData?.forecast?.amount.value ?? '0',
        currency: columnData?.forecast?.amount.currency ?? 'EUR',
      };
      const currentDate = dayjs().utc();
      const intervalDate = dayjs(interval.start).utc();
      const isFutureMonth = intervalDate.isAfter(currentDate, 'month');

      dispatch({
        type: 'UPDATE_INTERVAL',
        payload: {
          interval,
          tab: {
            tabValues: {
              actual: {
                value: columnData?.amount?.value || '0',
                currency: columnData?.amount?.currency || 'EUR',
              },
              forecast: forecastValue,
            },
            isFutureMonth,
          },
          forecast: columnData?.forecast ?? null,
        },
      });
    };

    const getDisabledPeriods = (): { isFirstPeriod: boolean; isLastPeriod: boolean } => {
      const frequencyUnit = CashflowFrequencyToInterval[selectedFrequency || 'monthly'];
      const currentDate = dayjs(state.selectedInterval?.start);
      const startDate = dayjs(periodNavigation.startDate);

      return {
        isFirstPeriod: currentDate
          .startOf(frequencyUnit)
          .isSame(startDate.startOf(frequencyUnit).add(1, frequencyUnit)),
        isLastPeriod: dayjs(state.selectedInterval?.end)
          .add(1, frequencyUnit)
          .isAfter(dayjs(periodNavigation.endDate)),
      };
    };

    const { isFirstPeriod, isLastPeriod } = getDisabledPeriods();

    return {
      onNextMonth: () => {
        setSelectedInterval({ isPreviousPeriod: false });
        if (periodNavigation.onNextMonth && !periodNavigation.isLastPeriod) {
          periodNavigation.onNextMonth();
        }
      },
      onPreviousMonth: () => {
        setSelectedInterval({ isPreviousPeriod: true });
        if (periodNavigation.onPreviousMonth && !periodNavigation.isFirstPeriod) {
          periodNavigation.onPreviousMonth();
        }
      },
      isFirstPeriod,
      isLastPeriod,
    };
  }, [
    state.isFlowSelected,
    flatCategoriesData,
    categoriesData.inflowSums,
    categoriesData.outflowSums,
    periodNavigation,
    selectedFrequency,
    state.selectedCategories,
    state.selectedInterval?.end,
    state.selectedInterval?.start,
  ]);

  const shouldShowEditForecastPopup = useCallback(
    (forceClose?: boolean): boolean | undefined => {
      const entryEnd = state.forecast?.formula
        ? getFormulaBoundDate(state.forecast.formula.end)
        : undefined;

      const isForecastFormula =
        state.tab.selectedValue === CashflowSidePanelTabs.Forecast &&
        state.forecast?.source === CashflowForecastEntrySource.Formula;
      const isForecastFormulaEdited = state.forecastEdit.isDirty && !state.forecastEdit.isVisible;

      return (
        !forceClose &&
        isForecastFormula &&
        isForecastFormulaEdited &&
        entryEnd?.isAfter(dayjs(state.selectedInterval?.start), 'month')
      );
    },
    [
      state.forecast?.formula,
      state.forecast?.source,
      state.forecastEdit.isDirty,
      state.forecastEdit.isVisible,
      state.selectedInterval?.start,
      state.tab.selectedValue,
    ]
  );

  const closeSidepanel = useCallback(
    (forceClose?: boolean) => {
      if (shouldShowEditForecastPopup(forceClose)) {
        dispatch({ type: 'UPDATE_FORECAST_EDIT_VISIBILITY', payload: { isVisible: true } });
      } else {
        dispatch({ type: 'CLOSE_SIDEPANEL' });
      }
    },
    [shouldShowEditForecastPopup]
  );

  const updateSidepanelMode = useCallback((mode: SidepanelMode) => {
    dispatch({ type: 'UPDATE_MODE', payload: { mode } });
  }, []);

  const refreshChart = useCallback(() => {
    onRefreshChart?.();
  }, [onRefreshChart]);

  const changeSidepanelTab = (tab: CashflowSidePanelTabs): void => {
    dispatch({ type: 'CHANGE_TAB', payload: { selectedTab: tab } });
  };

  const setForecastEditDirtyState = (isDirty: boolean): void => {
    if (isDirty !== state.forecastEdit.isDirty) {
      dispatch({ type: 'UPDATE_FORECAST_EDIT_DIRTY_STATE', payload: { isDirty } });
    }
  };

  const setForecastEditVisibility = (isVisible: boolean): void => {
    dispatch({ type: 'UPDATE_FORECAST_EDIT_VISIBILITY', payload: { isVisible } });
  };

  return (
    <CashFlowSidePanelContext.Provider
      value={{
        ...state,
        openSidepanelWith,
        closeSidepanel,
        refreshChart,
        selectedFrequency,
        bankAccounts: bankAccountsCollection,
        periodNavigation: wrappedPeriodNavigation,
        changeSidepanelTab,
        setForecastEditDirtyState,
        setForecastEditVisibility,
        updateSidepanelMode,
      }}
    >
      {children}
    </CashFlowSidePanelContext.Provider>
  );
}

export function useCashFlowSidePanel(): CashFlowSidePanelContextType {
  const context = useContext(CashFlowSidePanelContext);

  if (!context) {
    throw new Error('useCashFlowSidePanel must be used within a CashFlowSidePanelProvider');
  }

  return context;
}

export const cashFlowSidePanelManager = {
  useCashFlowSidePanel,
};
