import { type ReactNode } from 'react';
import cx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import ValuePropositionIllustrationURL from 'qonto/react/assets/illustrations/card-acquirer/value-proposition-illustration.svg';
import ValuePropositionQrURL from 'qonto/react/assets/illustrations/card-acquirer/value-proposition-qr.svg';
import { FlowValueProposition } from 'qonto/react/components/flows/screens';
import styles from './value-proposition.strict-module.css';

interface CardAcquirerValuePropositionModalProps {
  onClose: () => void;
}

export function CardAcquirerValuePropositionModal({
  onClose,
}: CardAcquirerValuePropositionModalProps): ReactNode {
  const { formatMessage } = useIntl();

  return (
    <FlowValueProposition
      className={styles.container}
      data-test-card-acquirer-value-proposition-modal
      illustration={
        <img alt="" className={styles.illustration} src={ValuePropositionIllustrationURL} />
      }
      onClose={onClose}
    >
      <div className="mb-32" data-test-header>
        <h1 className={cx('title-2', 'mb-8')}>
          <FormattedMessage id="tap-to-pay.payments.discover.title" />
        </h1>
        <p className="body-2">
          <FormattedMessage id="tap-to-pay.payments.discover.subtitle" />
        </p>
      </div>

      <div className="mb-16" data-test-feature="0">
        <h2 className={cx('title-4', 'mb-8')}>
          <FormattedMessage id="tap-to-pay.payments.discover.block-1.title" />
        </h2>
        <p className={cx('body-2', styles.subtitle)}>
          <FormattedMessage id="tap-to-pay.payments.discover.block-1.subtitle" />
        </p>
      </div>

      <div className="mb-32" data-test-feature="1">
        <h2 className={cx('title-4', 'mb-8')}>
          <FormattedMessage id="tap-to-pay.payments.discover.block-2.title" />
        </h2>
        <p className={cx('body-2', styles.subtitle)}>
          <FormattedMessage id="tap-to-pay.payments.discover.block-2.subtitle" />
        </p>
      </div>

      <div className={styles['qr-container']} data-test-qr>
        <img
          alt={formatMessage({ id: 'tap-to-pay.payments.discover.qr.caption' })}
          className={styles.qr}
          src={ValuePropositionQrURL}
        />

        {/* Image is already described so this text is redundant */}
        <p aria-hidden="true" className="body-2">
          <FormattedMessage id="tap-to-pay.payments.discover.qr.caption" />
        </p>
      </div>
    </FlowValueProposition>
  );
}
