import { useIntl } from 'react-intl';
import { useEmberService, useNavigate } from '@qonto/react-migration-toolkit/react/hooks';
import { IconDocumentOutlined, IconCrossOutlined } from '@repo/monochrome-icons';
import cx from 'clsx';
import { INVOICE_OR_CREDIT_NOTE_TEXT } from 'qonto/constants/supplier-invoice.ts';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import styles from './related-documents.strict-module.css';

interface Document {
  id: string;
  invoice_number?: string;
  total_amount?: {
    value: number;
    currency: string;
  };
}

interface RelatedDocumentsProps {
  invoice?: {
    relatedInvoices?: Document[];
    isCreditNote: boolean;
    isElectronicInvoice?: boolean;
  };
  withBorder?: boolean;
  hideUnlinkButton?: boolean;
  unlinkInvoiceFromCreditNote?: { perform: (id: string) => Promise<void> };
}

export function RelatedDocuments({
  invoice,
  withBorder = false,
  hideUnlinkButton = false,
  unlinkInvoiceFromCreditNote,
}: RelatedDocumentsProps): React.ReactNode {
  const { formatMessage, formatNumber } = useIntl();
  const navigate = useNavigate();
  const { organization } = useOrganizationManager();
  const segment = useEmberService('segment');
  if (!invoice?.relatedInvoices) return null;

  const formatAmount = (
    amount: { value: number; currency: string },
    isCreditNote: boolean
  ): string => {
    const formattedAmount = formatNumber(Number(amount.value), {
      style: 'decimal',
      minimumFractionDigits: 2,
    });

    return isCreditNote
      ? `${formattedAmount}\xA0${amount.currency}`
      : `-\xA0${formattedAmount}\xA0${amount.currency}`;
  };

  const originType = invoice.isCreditNote
    ? INVOICE_OR_CREDIT_NOTE_TEXT.creditNote
    : INVOICE_OR_CREDIT_NOTE_TEXT.invoice;

  const handleClick = (e: React.MouseEvent, documentId: string): void => {
    e.preventDefault();

    segment.track('supplier-invoices_linked-document_clicked', {
      origin_type: originType,
      is_einvoice: invoice.isElectronicInvoice ?? false,
    });
    void navigate(`/organizations/${organization.slug}/supplier-invoices/${documentId}`);
  };

  return (
    <div className={cx('mb-16', withBorder && styles.wrapper)}>
      <h3 className="caption-bold mb-16" data-testid="related-documents-title">
        {invoice.isCreditNote
          ? formatMessage({ id: 'supplier-invoices.credit-note-modal.related-invoice.title' })
          : formatMessage(
              { id: 'supplier-invoices.invoice-modal.related-credit-notes.title' },
              { count: invoice.relatedInvoices.length }
            )}
      </h3>
      {invoice.relatedInvoices.map(document => (
        <div className={styles.item} data-testid="related-document" key={document.id}>
          <button
            className={styles.icon}
            data-testid="document-icon"
            onClick={(e: React.MouseEvent) => {
              handleClick(e, document.id);
            }}
            type="button"
          >
            <IconDocumentOutlined aria-hidden="true" />
          </button>
          <div className={styles.details}>
            <div>
              <button
                className={cx('body-link', styles['link-color'])}
                data-testid="related-document-number"
                onClick={(e: React.MouseEvent) => {
                  handleClick(e, document.id);
                }}
                type="button"
              >
                {document.invoice_number ||
                  formatMessage({ id: 'supplier-invoices.related-documents.missing-details' })}
              </button>
              <p
                className={cx('body-2', originType === 'invoice' && styles['credit-note-amount'])}
                data-testid="related-document-amount"
              >
                {document.total_amount
                  ? formatAmount(document.total_amount, invoice.isCreditNote)
                  : formatMessage({ id: 'supplier-invoices.related-documents.missing-details' })}
              </p>
            </div>
            {!hideUnlinkButton && (
              <button
                className="btn btn--icon-only btn--tertiary"
                data-testid="related-document-unlink-button"
                onClick={() => {
                  segment.track('supplier-invoices_unlink-document_clicked', {
                    origin_type: originType,
                    is_einvoice: invoice.isElectronicInvoice ?? false,
                  });
                  void unlinkInvoiceFromCreditNote?.perform(document.id);
                }}
                type="button"
              >
                <IconCrossOutlined aria-hidden="true" />
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
