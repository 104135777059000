import * as React from 'react';
import { RadioButton, RadioGroup, type RadioGroupProps } from '@repo/design-system-kit';
import styles from './export-format-radio-group.strict-module.css';

export interface ExportFormatRadioGroupOption<T> {
  value: T;
  label: string;
  description?: string;
}

export interface ExportFormatRadioGroupProps<T extends string> extends RadioGroupProps<T> {
  options: ExportFormatRadioGroupOption<T>[];
}

export function ExportFormatRadioGroup({
  options,
  ...props
}: ExportFormatRadioGroupProps<string>): React.ReactElement {
  return (
    <RadioGroup {...props}>
      {options.map(option => (
        <RadioButton
          className={styles.radioButton}
          data-test-export-format-radio-group-option={option.value}
          key={option.value}
          value={option.value}
        >
          {option.description ? (
            <div className={styles.labelContainer}>
              <div className={styles.label}>{option.label}</div>
              <small className={styles.description}>{option.description}</small>
            </div>
          ) : (
            option.label
          )}
        </RadioButton>
      ))}
    </RadioGroup>
  );
}
