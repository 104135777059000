import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useIntl } from 'react-intl';
import { transferNamespace } from 'qonto/constants/hosts';
import { useFetchApi, type FetchFunction } from 'qonto/react/hooks/use-fetch-api';

export interface CancelTransferRequest {
  transferId: string;
}

export const cancelTransfer = async (
  fetchApi: FetchFunction,
  request: CancelTransferRequest
): Promise<void> => {
  const { transferId } = request;
  const response = await fetchApi(`${transferNamespace}/transfers/${transferId}/cancel`, {
    method: 'PUT',
  });

  if (!response.ok)
    throw Error(`[HTTP status: ${response.status}] Transfer ${transferId} could not be canceled`);
};

export const useCancelTransfer = (): UseMutationResult<void, Error, CancelTransferRequest> => {
  const fetchApi = useFetchApi();
  const { formatMessage } = useIntl();
  const toastFlashMessages = useEmberService('toast-flash-messages');
  const sentry = useEmberService('sentry');

  return useMutation({
    mutationFn: request => cancelTransfer(fetchApi, request),
    onSuccess: () => {
      toastFlashMessages.toastSuccess(
        formatMessage({ id: 'transfers.cancel-transfer-feedback.success' })
      );
    },
    onError: error => {
      toastFlashMessages.toastError(formatMessage({ id: 'toasts.errors.server_error' }));
      sentry.captureException(error);
    },
  });
};
