import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { FormattedMessage } from '@repo/domain-kit/shared';
import cx from 'clsx';
import { CardLevel } from '@repo/cards-kit/constants';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { ChooseCard } from '../../choose-card/choose-card';
import styles from './advertising-card.strict-module.css';
import type { CardSelectorCoreProps } from './card-selector-core-props.interface';

export function AdvertisingCard({
  assetProps,
  ctaProps,
  headerProps,
  layoutProps,
}: CardSelectorCoreProps): ReactNode {
  const modals = useEmberService('modals');
  const segment = useEmberService('segment');

  const { formatMessage } = useIntl();

  const handleModalOpen = (): void => {
    modals.open('card/modals/online-ad-features', {
      isFullScreenModal: true,
    });

    segment.track('cards_order_advertising_card_more_info');
  };

  const features = [
    formatMessage({
      id: 'cards.steps.choose-card.virtual-category.advertising.features.limit',
    }),
    formatMessage(
      {
        id: 'cards.steps.choose-card.virtual-category.advertising.features.merchants.body',
      },
      {
        otherPlatforms: (
          <button
            className={cx('body-link', styles['ad-modal-button'])}
            data-test-card-feature-advertising-modal-button
            onClick={handleModalOpen}
            type="button"
          >
            <FormattedMessage id="cards.steps.choose-card.virtual-category.advertising.features.merchants.link-copy" />
          </button>
        ),
      }
    ),
    formatMessage({
      id: 'cards.steps.choose-card.virtual-category.advertising.features.validity',
    }),
  ];

  return (
    <ChooseCard
      assetProps={assetProps}
      cardLevel={CardLevel.Advertising}
      ctaProps={ctaProps}
      features={features}
      headerProps={{
        ...headerProps,
        subtitle: formatMessage({
          id: 'cards.steps.choose-card.virtual-category.advertising.subtitle',
        }),
      }}
      layoutProps={layoutProps}
    />
  );
}
