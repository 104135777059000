import { useMutation, useQueryClient, type UseMutationResult } from '@tanstack/react-query';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useIntl } from 'react-intl';
import { upcomingTransactionsNamespace } from 'qonto/constants/hosts';
import { useFetchApi, type FetchFunction } from 'qonto/react/hooks/use-fetch-api';

interface Recurrence {
  value?: {
    frequency: string;
  } | null;
  valid: boolean;
}

export interface UpdateUpcomingTransactionPayload {
  amount?: {
    currency: string;
    value: string | number;
  };
  expected_date?: string;
  name?: string;
  side?: 'credit' | 'debit';
  recurrence?: Recurrence;
}

export interface UpdateUpcomingTransactionRequest {
  upcomingTransactionId: string;
  payload: UpdateUpcomingTransactionPayload;
}

export interface UpdateUpcomingTransactionResponse {
  id: string;
  amount: {
    currency: string;
    value: string;
  };
  bank_account_id: string;
  expected_date: string;
  name: string;
  side: 'credit' | 'debit';
  recurrence: Recurrence | null;
}

export const updateUpcomingTransaction = async (
  fetchApi: FetchFunction,
  request: UpdateUpcomingTransactionRequest
): Promise<UpdateUpcomingTransactionResponse> => {
  const { upcomingTransactionId, payload } = request;

  const response = await fetchApi(
    `${upcomingTransactionsNamespace}/cash-flow/upcoming-transactions/${upcomingTransactionId}?cascade=true`,
    {
      method: 'PATCH',
      body: JSON.stringify({ ...payload }),
    }
  );

  if (!response.ok) {
    throw new Error(
      `[HTTP status: ${response.status}] Upcoming transaction ${upcomingTransactionId} could not be updated`
    );
  }

  return response.json() as Promise<UpdateUpcomingTransactionResponse>;
};

export const useUpdateUpcomingTransaction = (): UseMutationResult<
  UpdateUpcomingTransactionResponse,
  Error,
  UpdateUpcomingTransactionRequest
> => {
  const fetchApi = useFetchApi();
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();
  const toastFlashMessages = useEmberService('toast-flash-messages');
  const sentry = useEmberService('sentry');

  return useMutation({
    mutationFn: request => updateUpcomingTransaction(fetchApi, request),
    onMutate: () => ({}),
    onSuccess: async _ => {
      await queryClient.invalidateQueries({ queryKey: ['upcoming-transactions'] });

      toastFlashMessages.toastSuccess(
        formatMessage({ id: 'cash-flow.upcoming-transactions.actions.change-date.toast.success' })
      );
    },
    onError: error => {
      toastFlashMessages.toastError(formatMessage({ id: 'toasts.errors.server_error' }));
      sentry.captureException(error);
    },
  });
};
