import { FormattedMessage, FormattedNumber } from 'react-intl';
import type { ReactElement } from 'react';
import { Button, LottiePlayer } from '@repo/design-system-kit';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useThemedAssetPath } from 'qonto/react/hooks/use-themed-asset-path';
import styles from './styles.strict-module.css';

export interface SuccessProps {
  goToTransfers: () => void;
  goToCockpit: () => void;
  creditLimitAmount: {
    value: number;
    currency: string;
  };
}

export function Success({
  creditLimitAmount,
  goToTransfers,
  goToCockpit,
}: SuccessProps): ReactElement {
  const segment = useEmberService('segment');

  const handlePrimaryCta = (): void => {
    segment.track('pay-later-signed_usage_clicked');
    goToTransfers();
  };

  const handleSecondaryCta = (): void => {
    segment.track('pay-later-cockpit_displayed', { origin: 'success' });
    goToCockpit();
  };

  return (
    <div className={styles.success}>
      <LottiePlayer
        className={styles['success-lottie']}
        data-test-success-lottie=""
        loop={false}
        path={useThemedAssetPath('/lotties/success.json')}
      />

      <h1 className="title-2 mb-16 align-center" data-test-success-title>
        <FormattedMessage id="financing.pay-later.application-flow.success.title" />
      </h1>
      <p className="body-1 align-center mb-32" data-test-success-description>
        <FormattedMessage
          id="financing.pay-later.application-flow.success.description"
          values={{
            amount: (
              <FormattedNumber
                currency={creditLimitAmount.currency}
                maximumFractionDigits={0}
                minimumFractionDigits={0}
                style="currency"
                value={Number(creditLimitAmount.value)}
              />
            ),
          }}
        />
      </p>
      <Button className="mb-16" data-test-success-primary-cta onPress={handlePrimaryCta}>
        <FormattedMessage id="financing.pay-later.application-flow.success.primary-cta" />
      </Button>
      <Button data-test-success-secondary-cta onPress={handleSecondaryCta} variant="tertiary">
        <FormattedMessage id="financing.pay-later.application-flow.success.secondary-cta" />
      </Button>
    </div>
  );
}
