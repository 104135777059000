import type { ReactElement } from 'react';
import cs from 'clsx';
import { FormattedNumber } from 'react-intl';
import { AnimatedCounter } from 'qonto/react/components/counter/internal/animated-counter';
import ENV from 'qonto/config/environment';

const isTesting = (ENV as { environment: string }).environment === 'test';

export const COUNTER_ANIMATION_DURATION = isTesting ? 1 : 800;

interface CounterProps {
  currentValue: number;
  currency?: string;
  delay?: number;
  className?: string;
  isLoading?: boolean;
  previousValue?: number;
}

export function Counter({
  className = '',
  currency = 'EUR',
  isLoading = false,
  ...props
}: CounterProps): ReactElement {
  if (isLoading) {
    return (
      <div className={cs(className)} data-testid="insight-figure">
        <FormattedNumber currency={currency} style="currency" value={props.previousValue ?? 0} />
      </div>
    );
  }

  return <AnimatedCounter className={cs(className)} currency={currency} {...props} />;
}
