import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@repo/design-system-kit';
import { IconPlusOutlined } from '@repo/monochrome-icons';
import cx from 'clsx';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './empty-state.strict-module.css';

interface EmptyStateProps {
  canCreate?: boolean;
  createAccount?: () => void;
}

export function EmptyState({ canCreate, createAccount }: EmptyStateProps): ReactNode {
  const getTitleId = (): string => {
    return canCreate ? 'accounts.savings.empty.title' : 'accounts.savings.empty.no-cta.title';
  };

  const getSubtitleId = (): string => {
    return canCreate ? 'accounts.savings.empty.subtitle' : 'accounts.savings.empty.no-cta.subtitle';
  };
  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <section className={styles.informations} role="presentation">
          <FormattedMessage id={getTitleId()}>
            {text => (
              <h1 className="title-2 mb-16" data-test-savings-empty-state-title>
                {text}
              </h1>
            )}
          </FormattedMessage>
          <FormattedMessage id={getSubtitleId()}>
            {text => (
              <p
                className={`body-2 ${canCreate ? 'mb-32' : ''} ${styles.subtitle}`}
                data-test-savings-empty-state-subtitle
              >
                {text}
              </p>
            )}
          </FormattedMessage>
          {canCreate ? (
            <Button data-test-savings-empty-state-create onPress={createAccount} variant="primary">
              <IconPlusOutlined className={cx(styles['button-icon'], 'mr-8')} />
              <FormattedMessage id="accounts.create-cta" />
            </Button>
          ) : null}
        </section>
        <StaticThemedAsset
          alt=""
          assetPath="/illustrations/cash_surplus.svg"
          className={styles['empty-state']}
          data-test-savings-empty-state-illustration
        />
      </div>
    </div>
  );
}
