import React, { Fragment, useMemo, type ReactNode, type RefCallback } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
  flexRender,
  type ColumnDef,
} from '@tanstack/react-table';
import { SkeletonLoader } from '@repo/design-system-kit';
import { BalanceCell } from '@repo/domain-kit/shared';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import type BankAccount from 'qonto/models/bank-account';
import type TransactionModel from 'qonto/models/transaction';
import type { CashflowCategories } from 'qonto/react/models/cash-flow-category';
import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import type { LabelTableInterval } from 'qonto/react/components/cash-flow/models/labels-cashflow-display.ts';
import type { CategoriesTableRow } from '../../../models/categories-table-display';
import styles from './transactions-table.strict-module.css';
import { CategoryCell } from './category-cell';
import { CashflowTransactionCounterparty } from './counterparty';

export interface TransactionDetails {
  id: string;
  account: BankAccount | undefined;
  counterpartyName: TransactionModel['counterpartyName'];
  rawCounterpartyName: TransactionModel['rawCounterpartyName'];
  accountId: TransactionModel['bankAccount']['id'];
  operationMethod: TransactionModel['operationMethod'];
  displayType: string;
}

export interface TransactionTableRow {
  transactionDetails: TransactionDetails;
  amountDetails: {
    side: TransactionModel['side'];
    amount: TransactionModel['amount'];
    amountCurrency: TransactionModel['amountCurrency'];
    emittedAt: TransactionModel['emittedAt'];
  };
  category: {
    id: string | null;
  };
}

interface TransactionsTableProps {
  data: TransactionTableRow[];
  categories: CashflowCategories | undefined;
  selectedCategories: CategoriesTableRow[];
  selectedInterval: LabelTableInterval | undefined;
  infiniteScroll: {
    isLoading: boolean;
    loadMoreRef: RefCallback<Element>;
  };
  refreshChart: () => void;
}
export function TransactionsTable({
  data,
  categories,
  selectedCategories,
  selectedInterval,
  infiniteScroll,
  refreshChart,
}: TransactionsTableProps): ReactNode {
  const { locale } = useIntl();
  const columns = useMemo(
    () => buildColumns(categories, locale, selectedCategories, selectedInterval, refreshChart),
    [categories, locale, refreshChart, selectedCategories, selectedInterval]
  );
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  const { isLoading, loadMoreRef: loadMoreTrigger } = infiniteScroll;
  return (
    <table className={styles.transactionsTable} data-testid="sidepanel-transactions-table">
      <colgroup>
        <col />
        <col />
        <col />
      </colgroup>
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header, index) => (
              <th data-header-col={index} key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(header.column.columnDef.header, header.getContext())}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row, rowIndex) => {
          const shouldFetchNextPage = rowIndex === 0;
          return (
            <Fragment key={row.id}>
              <tr
                className={styles['table-row']}
                data-row={rowIndex}
                ref={shouldFetchNextPage ? loadMoreTrigger : undefined}
              >
                {row.getVisibleCells().map((cell, cellIndex) => (
                  <td data-col={cellIndex} key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
              <tr
                className={styles['memorize-widget-node-row']}
                data-row-memorize-widget={rowIndex}
              >
                <td colSpan={3} />
              </tr>
            </Fragment>
          );
        })}
        {isLoading ? <LoadingRows /> : null}
      </tbody>
    </table>
  );
}

const buildColumns = (
  categories: CashflowCategories | undefined,
  locale: string,
  selectedCategories: CategoriesTableRow[],
  selectedInterval: LabelTableInterval | undefined,
  refreshChart: () => void
): ColumnDef<TransactionTableRow>[] => {
  const columnHelper = createColumnHelper<TransactionTableRow>();
  const columns = [
    columnHelper.accessor('transactionDetails', {
      header: 'Transaction Details',
      cell: info => (
        <div className={styles['transactions-table-counterparty']}>
          <CashflowTransactionCounterparty transaction={info.getValue()} />
        </div>
      ),
    }),
    columnHelper.accessor('amountDetails', {
      header: 'Amount Details',
      cell: info => {
        const { amount, amountCurrency, side, emittedAt } = info.getValue();
        return (
          <div className={styles['transactions-table-amount-details']}>
            <BalanceCell
              amount={{ value: amount, currency: amountCurrency }}
              side={side}
              subtitle={dateToken({
                date: String(emittedAt),
                locale,
                token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
              })}
            />
          </div>
        );
      },
    }),
    columnHelper.accessor('category', {
      header: 'Category',
      cell: info => (
        <CategoryCell
          activeCategoryId={info.getValue().id}
          categories={categories}
          key={selectedInterval?.start}
          refreshChart={refreshChart}
          rowIndex={info.row.index}
          selectedCategories={selectedCategories}
          transactionDetails={info.row.original.transactionDetails}
          transactionId={info.row.original.transactionDetails.id}
        />
      ),
    }),
  ] as ColumnDef<TransactionTableRow>[];

  return columns;
};

interface LoadingRowsProps {
  count?: number;
}
function LoadingRows({ count = 25 }: LoadingRowsProps): ReactNode {
  return Array.from({ length: count }).map((_, index) => (
    // eslint-disable-next-line react/no-array-index-key -- array is immutable
    <tr className={styles['loading-row']} data-row-loading={index} key={index}>
      <td data-col-loading={0}>
        <div className={styles['loading-cell']}>
          <SkeletonLoader.Line height="12px" standalone width="30%" />
          <SkeletonLoader.Line height="8px" standalone width="100%" />
        </div>
      </td>
      <td data-col-loading={1}>
        <div className={cx(styles['loading-cell'], styles['align-right'])}>
          <SkeletonLoader.Line height="12px" standalone width="60%" />
          <SkeletonLoader.Line height="8px" standalone width="100%" />
        </div>
      </td>
      <td data-col-loading={2}>
        <SkeletonLoader.Block height="32px" standalone width="100%" />
      </td>
    </tr>
  ));
}
