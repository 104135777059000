import { useEffect } from 'react';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { PromotionalCard } from '@repo/design-system-kit';
import { useSegment } from '@repo/poly-hooks/segment';
import { useIntl } from 'react-intl';

interface EInvoicingConsentCardProps {
  segmentation: string;
  origin: string;
}

export function EInvoicingConsentPromotionalCard({
  segmentation = 'standard',
  origin = 'standard',
}: EInvoicingConsentCardProps): JSX.Element {
  const { formatMessage } = useIntl();
  const modals = useEmberService('modals');
  const segment = useSegment();

  useEffect(() => {
    segment.track('einvoicing_transaction-banner_viewed', {
      segmentation,
      origin,
    });
  }, [segment, segmentation, origin]);

  function handleCtaClick(): void {
    segment.track('einvoicing_transaction-banner_clicked', {
      segmentation,
      origin,
    });
    modals.open('e-invoicing-opt-in-pop-up', {
      segmentation,
      origin,
    });
  }

  return (
    <PromotionalCard
      badgeText={formatMessage({
        id: 'promotion-system.badge.included-in-subscription.label',
      })}
      badgeType="activate"
      ctaText={formatMessage({
        id: 'transactions.details-section.einvoicing.promotional-card.cta',
      })}
      data-testid="einvoicing-consent-promotional-card"
      onCtaClick={handleCtaClick}
      subtitle={formatMessage({
        id: 'transactions.details-section.einvoicing.promotional-card.title',
      })}
    />
  );
}
