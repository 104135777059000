import { useIntl } from 'react-intl';
import { InfoIcon, Tooltip as TooltipComponent } from '@repo/design-system-kit';
import type { ReactElement } from 'react';
import type { RepaymentStatus } from '../next-installments-tile';
import styles from './styles.strict-module.css';

export function Tooltip({
  repaymentStatus,
  count,
}: {
  repaymentStatus: Exclude<RepaymentStatus, 'frozen'>;
  count: number;
}): ReactElement {
  const intl = useIntl();
  const labelStatusMap: Record<Exclude<RepaymentStatus, 'frozen'>, string> = {
    at_risk: intl.formatMessage(
      {
        id: 'financing.pay-later.cockpit.insights.next-installments.at-risk.tooltip',
      },
      { count }
    ),
    late: intl.formatMessage(
      {
        id: 'financing.pay-later.cockpit.insights.next-installments.late.tooltip',
      },
      { count }
    ),
  };

  const tooltipLabel = labelStatusMap[repaymentStatus];

  return (
    <span className={styles.tooltipContainer} data-test-tooltip-container="">
      <TooltipComponent label={tooltipLabel} needsButton placement="top right">
        <InfoIcon height={16} width={16} />
      </TooltipComponent>
    </span>
  );
}
