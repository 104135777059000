/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import type { ReactElement, ReactNode } from 'react';
import { CockpitTile, InfoIcon, Tooltip } from '@repo/design-system-kit';
import { FormattedNumber, FormattedMessage } from 'react-intl';
import {
  CashBurnComputationType,
  type CashBurnData,
} from 'qonto/react/models/cash-flow-timeseries';
import { CashflowPeriodRate } from 'qonto/react/models/cash-flow-period';
import styles from './overview-boxes.strict-module.css';

interface CashBurnOverviewBoxProps {
  cashBurnData?: CashBurnData;
  isLoading: boolean;
  isError: boolean;
  selectedFrequency?: CashflowPeriodRate;
}
export function CashBurnOverviewBox({
  cashBurnData,
  isLoading,
  isError,
  selectedFrequency = CashflowPeriodRate.Monthly,
}: CashBurnOverviewBoxProps): ReactNode {
  const isCashBurnNegative = Number(cashBurnData?.amount.value) < 0;

  return (
    <CockpitTile
      amount={
        <Amount
          cashBurnData={cashBurnData}
          isError={isError}
          selectedFrequency={selectedFrequency}
        />
      }
      isAmountLoading={isLoading}
      title={
        <Title
          computationType={cashBurnData?.computationType}
          isCashBurnNegative={isCashBurnNegative}
          selectedFrequency={selectedFrequency}
        />
      }
    />
  );
}

interface TitleProps {
  isCashBurnNegative: boolean;
  selectedFrequency: CashflowPeriodRate;
  computationType?: CashBurnComputationType;
}
function Title({
  isCashBurnNegative,
  selectedFrequency,
  computationType = CashBurnComputationType.Default,
}: TitleProps): ReactNode {
  const tooltipCopy = getTooltipCopy(selectedFrequency, computationType);
  return (
    <span className={styles['title-container']}>
      <span className={styles.title} data-testid="overview-title">
        {isCashBurnNegative ? (
          <FormattedMessage id="cash-flow.overview.net-change.negative.title" />
        ) : (
          <FormattedMessage id="cash-flow.overview.net-change.positive.title" />
        )}
      </span>
      <Tooltip label={tooltipCopy} needsButton>
        <InfoIcon height={12} width={12} />
      </Tooltip>
    </span>
  );
}

interface AmountProps {
  cashBurnData: CashBurnData | undefined;
  isError: boolean;
  selectedFrequency: CashflowPeriodRate;
}
function Amount({ cashBurnData, isError, selectedFrequency }: AmountProps): ReactNode {
  const amountFrequencyCopy = getAmountFrequencyCopy(selectedFrequency);

  return (
    <span className={styles['amount-container']} data-testid="overview-amount">
      {cashBurnData && !isError ? (
        <>
          <span>
            <FormattedNumber
              currency={cashBurnData.amount.currency}
              signDisplay="always"
              style="currency"
              value={Number(cashBurnData.amount.value)}
            />
          </span>
          <span className="body-2">{amountFrequencyCopy}</span>
        </>
      ) : (
        '--'
      )}
    </span>
  );
}

const getAmountFrequencyCopy = (selectedFrequency: CashflowPeriodRate): ReactNode => {
  const frequencyMap: Partial<Record<CashflowPeriodRate, ReactNode>> = {
    [CashflowPeriodRate.Monthly]: <FormattedMessage id="cash-flow.overview.net-change.monthly" />,
    [CashflowPeriodRate.Quarterly]: (
      <FormattedMessage id="cash-flow.overview.net-change.quarterly" />
    ),
    [CashflowPeriodRate.Yearly]: <FormattedMessage id="cash-flow.overview.net-change.yearly" />,
  };
  return frequencyMap[selectedFrequency] ?? frequencyMap[CashflowPeriodRate.Monthly];
};

const getTooltipCopy = (
  selectedFrequency: CashflowPeriodRate,
  computationType?: CashBurnComputationType
): ReactElement => {
  const frequencyCopy = getTooltipFrequencyCopy(selectedFrequency, computationType);

  switch (computationType) {
    case CashBurnComputationType.NoData:
      return (
        <FormattedMessage
          id="cash-flow.overview.net-change.no-data.tooltip"
          values={{ period: frequencyCopy }}
        />
      );
    case CashBurnComputationType.Fallback:
      return (
        <FormattedMessage
          id="cash-flow.overview.net-change.single-period.tooltip"
          values={{ period: frequencyCopy }}
        />
      );
    default:
      return (
        <FormattedMessage
          id="cash-flow.overview.net-change.tooltip"
          values={{ period: frequencyCopy }}
        />
      );
  }
};

const getTooltipFrequencyCopy = (
  selectedFrequency: CashflowPeriodRate,
  computationType: CashBurnComputationType = CashBurnComputationType.Default
): ReactNode => {
  const frequencyMap: Record<
    CashBurnComputationType,
    Partial<Record<CashflowPeriodRate, ReactNode>>
  > = {
    [CashBurnComputationType.Default]: {
      [CashflowPeriodRate.Monthly]: (
        <FormattedMessage id="cash-flow.overview.net-change.tooltip-months" />
      ),
      [CashflowPeriodRate.Quarterly]: (
        <FormattedMessage id="cash-flow.overview.net-change.tooltip-quarters" />
      ),
      [CashflowPeriodRate.Yearly]: (
        <FormattedMessage id="cash-flow.overview.net-change.tooltip-years" />
      ),
    },
    [CashBurnComputationType.Fallback]: {
      [CashflowPeriodRate.Monthly]: (
        <FormattedMessage id="cash-flow.overview.net-change.single-period.tooltip-months" />
      ),
      [CashflowPeriodRate.Quarterly]: (
        <FormattedMessage id="cash-flow.overview.net-change.single-period.tooltip-quarters" />
      ),
      [CashflowPeriodRate.Yearly]: (
        <FormattedMessage id="cash-flow.overview.net-change.single-period.tooltip-years" />
      ),
    },
    [CashBurnComputationType.NoData]: {
      [CashflowPeriodRate.Monthly]: (
        <FormattedMessage id="cash-flow.overview.net-change.no-data.tooltip-months" />
      ),
      [CashflowPeriodRate.Quarterly]: (
        <FormattedMessage id="cash-flow.overview.net-change.no-data.tooltip-quarters" />
      ),
      [CashflowPeriodRate.Yearly]: (
        <FormattedMessage id="cash-flow.overview.net-change.no-data.tooltip-years" />
      ),
    },
  };

  return (
    frequencyMap[computationType][selectedFrequency] ??
    frequencyMap[CashBurnComputationType.Default][CashflowPeriodRate.Monthly]
  );
};
