import type { ReactNode } from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from '@qonto/react-migration-toolkit/react/components';
import { Popup } from '@repo/design-system-kit';
import { useTimeout } from 'usehooks-ts';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import ENV from 'qonto/config/environment';

export const CASH_FLOW_FIRST_TIME_EXPERIENCE_STORAGE_KEY = 'cash-flow-first-time-experience';

const isTest = ENV.environment === 'test';

const POPUP_DELAY = isTest ? 0 : 2000;

export function FirstTimeExperiencePopup(): ReactNode {
  const { formatMessage } = useIntl();

  const { organization } = useOrganizationManager();

  const [isOpen, setIsOpen] = useState(false);

  const TEST_PORTAL_CONTAINER = document.getElementById('ember-testing') ?? undefined;

  useTimeout(() => {
    setIsOpen(true);
  }, POPUP_DELAY);

  const setLocalStorageKey = (): void => {
    window.localStorage.setItem(CASH_FLOW_FIRST_TIME_EXPERIENCE_STORAGE_KEY, 'true');
  };

  const handleCancel = (): void => {
    setLocalStorageKey();
    setIsOpen(false);
  };

  return (
    <Popup.Root
      data-testid="cash-flow.first-time-experience-popup"
      isOpen={isOpen}
      portalContainer={TEST_PORTAL_CONTAINER}
    >
      <Popup.CloseButton onPress={handleCancel} />
      <Popup.Illustration isPromotional>
        <img alt="" src="/illustrations/cash-flow-promotional-popup/header.svg" />
      </Popup.Illustration>
      <Popup.Header>
        <Popup.Title>
          {formatMessage({
            id: 'cash-flow.first-time-experience.promotions.promotional-popup.cfm-addon.title',
          })}
        </Popup.Title>
      </Popup.Header>
      <Popup.Subtitle>
        {formatMessage({
          id: 'cash-flow.first-time-experience.promotions.promotional-popup.cfm-addon.body',
        })}
      </Popup.Subtitle>
      <Popup.Footer>
        <Link
          aria-label={formatMessage({ id: 'btn.confirm' })}
          className="btn btn--primary"
          data-test-id="modal-cta-button"
          onClick={setLocalStorageKey}
          to={`/organizations/${organization.slug}/flows/cash-flow-first-time-experience/intro`}
        >
          {formatMessage({
            id: 'cash-flow.first-time-experience.promotions.promotional-popup.cfm-addon.cta',
          })}
        </Link>
      </Popup.Footer>
    </Popup.Root>
  );
}
