import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@repo/design-system-kit';
import { IconDotsOutlined, IconPenOutlined } from '@repo/monochrome-icons';
import { MenuTrigger, Popover, Menu, MenuItem } from 'react-aria-components';
import cx from 'clsx';
import { TrashOutlined } from 'qonto/react/assets/icons/trash-outlined';
import styles from './forecast-entry-menu.strict-module.css';

export function ForecastEntryMenu({
  onEditClick,
  onDeleteClick,
}: {
  onEditClick: () => void;
  onDeleteClick: () => void;
}): ReactNode {
  return (
    <MenuTrigger>
      <Button
        className={styles['menu-trigger']}
        data-testid="forecast-entry-menu"
        iconOnly
        size="small"
        variant="tertiary"
      >
        <IconDotsOutlined height={16} width={16} />
      </Button>
      <Popover
        className={styles['menu-popover']}
        data-testid="forecast-entry-menu-popover"
        placement="bottom right"
      >
        <Menu className={styles.menu}>
          <MenuItem
            className={styles['menu-item']}
            data-testid="forecast-entry-menu-edit-button"
            onAction={onEditClick}
          >
            <IconPenOutlined />
            <FormattedMessage id="cashflow.side-panel.forecast.summary.menu.edit" />
          </MenuItem>
          <MenuItem
            className={cx(styles['menu-item'], styles.destructive)}
            data-testid="forecast-entry-menu-delete-button"
            onAction={onDeleteClick}
          >
            <TrashOutlined />
            <FormattedMessage id="cashflow.side-panel.forecast.summary.menu.delete" />
          </MenuItem>
        </Menu>
      </Popover>
    </MenuTrigger>
  );
}
