import { SkeletonLoader } from '@repo/design-system-kit';
import type { HTMLAttributes, ReactElement } from 'react';
import cx from 'clsx';
import styles from './placeholder-row.styles.strict-module.css';

export function PlaceholderRow({
  className,
  ...props
}: HTMLAttributes<HTMLTableRowElement>): ReactElement {
  return (
    <tr
      aria-hidden="true"
      className={cx(styles.row, className)}
      data-test-sdd-multi-requests-table-placeholder-row=""
      {...props}
    >
      <td
        className={cx(styles.cell, styles.empty)}
        data-test-multi-requests-table-placeholder-empty=""
      />
      <td className={styles.cell}>
        <div className={styles.cellContent}>
          <SkeletonLoader.Block
            borderRadius="50%"
            className="mr-16"
            data-test-multi-requests-table-placeholder-block=""
            height="32px"
            width="32px"
          />
          <SkeletonLoader.Line
            data-test-multi-requests-table-placeholder-line=""
            standalone
            width="176px"
          />
        </div>
      </td>
      <td className={styles.cell}>
        <SkeletonLoader.Line
          data-test-multi-requests-table-placeholder-line=""
          standalone
          width="90px"
        />
      </td>
      <td className={styles.cell}>
        <div className={cx(styles.cellContent, styles.justifyContentRight)}>
          <SkeletonLoader.Line
            data-test-multi-requests-table-placeholder-line=""
            standalone
            width="90px"
          />
        </div>
      </td>
      <td className={cx(styles.cell, styles.review)}>
        <div className={cx(styles.cellContent, styles.justifyContentRight)}>
          <SkeletonLoader.Block
            borderRadius="4px"
            className="mr-8"
            data-test-multi-requests-table-placeholder-block=""
            height="32px"
            width="152px"
          />
          <SkeletonLoader.Block
            borderRadius="4px"
            className="mr-8"
            data-test-multi-requests-table-placeholder-block=""
            height="32px"
            width="32px"
          />
          <SkeletonLoader.Block
            borderRadius="4px"
            data-test-multi-requests-table-placeholder-block=""
            height="32px"
            width="32px"
          />
        </div>
      </td>
      <td
        className={cx(styles.cell, styles.empty)}
        data-test-multi-requests-table-placeholder-empty=""
      />
    </tr>
  );
}
