import * as React from 'react';
import { useIntl } from 'react-intl';
import {
  RadioButtonBoolean,
  RadioGroupBoolean,
  type RadioGroupBooleanProps,
} from '@repo/design-system-kit';

export interface WithCorporateOfficer {
  corporateOfficer: boolean;
}

export interface CorporateOfficerRadioGroupProps
  extends Omit<RadioGroupBooleanProps, 'value' | 'label' | 'labelClassName' | 'name'> {
  entity: WithCorporateOfficer;
  showValidations: boolean;
}

export function CorporateOfficerRadioGroup({
  entity,
  errorMessage,
  showValidations,
  onChange,
  ...props
}: CorporateOfficerRadioGroupProps): React.ReactElement {
  const { formatMessage } = useIntl();

  return (
    <RadioGroupBoolean
      errorMessage={errorMessage}
      isInvalid={Boolean(errorMessage && showValidations)}
      label={formatMessage({
        id: 'organizations.profile.company_profile.person_info.corporate_officer',
      })}
      labelClassName="body-1"
      name="corporateOfficer"
      onChange={value => {
        entity.corporateOfficer = value;
        onChange?.(value);
      }}
      tooltipText={formatMessage({
        id: 'organizations.profile.owner-form.tooltip.corporate-officer',
      })}
      value={entity.corporateOfficer}
      {...props}
    >
      <RadioButtonBoolean data-test-corporate-officer="true" value>
        {formatMessage({ id: 'organizations.profile.owner-form.role.radio-button-yes' })}
      </RadioButtonBoolean>
      <RadioButtonBoolean data-test-corporate-officer="false" value={false}>
        {formatMessage({ id: 'organizations.profile.owner-form.role.radio-button-no' })}
      </RadioButtonBoolean>
    </RadioGroupBoolean>
  );
}
