import { useState, type ReactNode } from 'react';
import cx from 'clsx';
import { BadgeHighlight, LottiePlayer } from '@repo/design-system-kit';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './empty-state.strict-module.css';

interface EmptyStateOptions {
  badge?: {
    text: string;
    level?: 'primary' | 'secondary';
  };
  title: string;
  subtitle?: string;
  button?: {
    label: string;
    callback: () => void;
  };
  secondaryButton?: {
    label: string;
    callback: () => void;
  };
  illuSrc?: string;
  lottieSrc?: string;
  layout?: 'compact';
}

interface EmptyStateProps {
  options: EmptyStateOptions;
  children?: ReactNode;
}

export function EmptyState({ options, children, ...props }: EmptyStateProps): ReactNode {
  const [animationReady, setAnimationReady] = useState(false);

  const actAsPrimaryOrSecondary =
    options.button && options.secondaryButton ? 'btn btn--secondary mr-16' : 'btn btn--primary';

  return (
    <div {...props} className={cx('empty-state', styles.wrapper)}>
      <div
        className={cx(
          styles.content,
          options.layout === 'compact' ? styles.compact : styles['full-width']
        )}
      >
        <div className={styles.left}>
          {options.badge?.text ? (
            <BadgeHighlight
              className="mb-16"
              data-test-empty-state-badge=""
              level={options.badge.level || 'primary'}
              text={options.badge.text}
            />
          ) : null}
          <h1 className="mb-16 title-2" data-test-empty-state-title>
            {options.title}
          </h1>
          {options.subtitle ? (
            <div className="mb-32 body-2" data-test-empty-state-subtitle>
              {options.subtitle}
            </div>
          ) : null}
          {children ? children : null}
          {!children && options.button ? (
            <button
              className={actAsPrimaryOrSecondary}
              data-test-empty-state-button
              onClick={options.button.callback}
              type="button"
            >
              {options.button.label}
            </button>
          ) : null}
          {options.secondaryButton ? (
            <button
              className="btn btn--primary"
              data-test-empty-state-secondary-button
              onClick={options.secondaryButton.callback}
              type="button"
            >
              {options.secondaryButton.label}
            </button>
          ) : null}
        </div>
        {options.illuSrc ? (
          <div className={styles['right-image']} data-test-q-state>
            <StaticThemedAsset
              alt=""
              assetPath={options.illuSrc}
              data-test-empty-state-image
              loading="lazy"
            />
          </div>
        ) : null}
        {!options.illuSrc && options.lottieSrc ? (
          <div className={styles['right-lottie']} data-test-q-state>
            <LottiePlayer
              className={cx(styles.animation, !animationReady && styles.hidden)}
              data-test-empty-state-animation={options.lottieSrc}
              onDataReady={() => {
                setAnimationReady(true);
              }}
              path={options.lottieSrc}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
