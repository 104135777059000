import type { ReactNode } from 'react';
import type React from 'react';
import { useLayoutEffect, useState, createRef, Children } from 'react';

export function useAlignedLayout(children: ReactNode): {
  minHeight: number;
  refs: React.RefObject<HTMLElement>[] | null | undefined;
} {
  const refs = Children.map(children, () => createRef<HTMLElement>());
  const [minHeight, setMinHeight] = useState(0);

  useLayoutEffect(() => {
    if (!refs?.length) return;

    const highestHeight = Math.max(
      ...refs.map(ref => (ref.current ? ref.current.offsetHeight : 0))
    );

    setMinHeight(highestHeight);
  }, [refs]);

  return { refs, minHeight };
}
