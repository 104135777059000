import cx from 'clsx';
import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './styles.strict-module.css';

interface PlaceholderProps extends React.HTMLAttributes<HTMLTableRowElement> {
  isSidepanelOpen?: boolean;
}

export function Placeholder({ isSidepanelOpen, ...props }: PlaceholderProps): React.ReactNode {
  return (
    <tr className={styles.row} {...props}>
      <td aria-hidden="true" />

      <td
        className={cx(styles.col, isSidepanelOpen ? styles['col-8'] : styles['col-6'])}
        data-test-counterparty-col
      >
        <div className={styles.counterparty}>
          <div className={styles.icon}>
            <SkeletonLoader.Block borderRadius="50%" height="40px" width="40px" />
          </div>
          <div className={styles.header}>
            <SkeletonLoader.Line width="118px" />
          </div>
        </div>
      </td>

      <td className={cx(styles.col, styles['col-4'])} data-test-frequency-col>
        <SkeletonLoader.Line width="72px" />
      </td>

      <td className={cx(styles.col, styles['col-4'])} data-test-next-date-col>
        <SkeletonLoader.Line width="88px" />
      </td>

      <td
        className={cx(styles.col, isSidepanelOpen ? styles.hide : styles['col-4'])}
        data-test-end-date-col
      >
        <SkeletonLoader.Line width="88px" />
      </td>

      <td
        className={cx(styles.col, isSidepanelOpen ? styles.hide : styles['col-2'])}
        data-test-status-col
      >
        <SkeletonLoader.Line width="72px" />
      </td>

      <td align="right" className={cx(styles.col, styles['col-4'])} data-test-amount-col>
        <SkeletonLoader.Line width="96px" />
      </td>

      <td aria-hidden="true" />
    </tr>
  );
}
