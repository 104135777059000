import type { ReactElement } from 'react';
import { ConfirmLayout } from 'qonto/react/pricing/components/confirm-layout';

interface ConfirmUpgradeProps {
  header: ReactElement;
}

export function ConfirmUpgrade({ header }: ConfirmUpgradeProps): ReactElement {
  return (
    <ConfirmLayout header={header} testId="data-test-subscription-change-confirm">
      {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx -- TODO: Add content */}
      <div>Content</div>
    </ConfirmLayout>
  );
}
