import type { PropsWithChildren, ReactNode } from 'react';
import cx from 'clsx';
import styles from './step-layout.strict-module.css';

interface StepLayoutProps {
  className?: string;
}

export function StepLayout({ children, className }: PropsWithChildren<StepLayoutProps>): ReactNode {
  return (
    <div className={cx(styles.container, className)} data-test-chargeback-claim-step-layout>
      <div className={styles.content}>{children}</div>
    </div>
  );
}
