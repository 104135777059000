import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@repo/design-system-kit';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './self-transfer.strict-module.css';

interface SelfTransferProps {
  transitionToNext: () => void;
}

export function SelfTransfer({ transitionToNext }: SelfTransferProps): ReactNode {
  return (
    <section className={styles['step-container']} data-test-pay-later-transfer-self-transfer-error>
      <div className={styles.wrapper}>
        <StaticThemedAsset
          alt=""
          assetPath="/illustrations/ill-cp-general-rejected.svg"
          className={styles['error-image']}
          data-test-pay-later-transfer-self-transfer-error-image
        />

        <div className="content">
          <h1 className="title-1 mb-16" data-test-pay-later-transfer-self-transfer-error-title>
            <FormattedMessage id="pay-later-flow.errors.self-transfer.title" />
          </h1>

          <p className="body-1" data-test-pay-later-transfer-self-transfer-error-description>
            <FormattedMessage id="pay-later-flow.errors.self-transfer.description" />
          </p>
        </div>

        <Button
          data-test-pay-later-transfer-self-transfer-error-redirect-button
          onPress={transitionToNext}
          variant="primary"
        >
          <FormattedMessage id="pay-later-flow.errors.self-transfer.got-it-btn" />
        </Button>
      </div>
    </section>
  );
}
