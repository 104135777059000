/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import type { ReactElement } from 'react';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import cx from 'clsx';
import { Spinner } from '@repo/design-system-kit';
import styles from './review.strict-module.css';

interface CurrencyAmount {
  value: string;
  currency: string;
}

interface Payment {
  fiscalCode: string;
  noticeNumber: string;
  creditorName: string;
  bankAccount: { name: string };
  amount: CurrencyAmount;
  totalAmount: CurrencyAmount;
  feeEstimateAmount?: CurrencyAmount;
}

interface ReviewProps {
  payment: Payment;
  onConfirm: () => void;
  isSubmitting: boolean;
}

export function Review({ payment, onConfirm, isSubmitting }: ReviewProps): ReactElement {
  const feeValue = payment.feeEstimateAmount && parseFloat(payment.feeEstimateAmount.value);
  const hasPaymentCost = feeValue && feeValue !== 0;

  return (
    <div className={styles.container}>
      <h1 className="title-1 mb-16" data-test-review-title="">
        <FormattedMessage id="pagopa.payment-creation.review.title" />
      </h1>
      <p className="body-1 mb-32" data-test-review-subtitle="">
        <FormattedMessage id="pagopa.payment-creation.review.subtitle" />
      </p>

      <div className={cx('mb-32', styles.content)}>
        <p className="mb-16" data-test-review-notice-number="">
          <span>
            <FormattedMessage id="pagopa.payment-creation.review.notice-number-label" />
          </span>{' '}
          <span>{payment.noticeNumber}</span>
        </p>
        <p className="mb-16" data-test-review-creditor-code="">
          <span>
            <FormattedMessage id="pagopa.payment-creation.review.creditor-code-label" />
          </span>{' '}
          <span>{payment.fiscalCode}</span>
        </p>
        <p className="mb-16" data-test-review-beneficiary-name="">
          <span>
            <FormattedMessage id="pagopa.payment-creation.review.beneficiary-name-label" />
          </span>{' '}
          <span>{payment.creditorName}</span>
        </p>
        <p className="mb-16" data-test-review-account-name="">
          <span>
            <FormattedMessage id="pagopa.payment-creation.review.account-name-label" />
          </span>{' '}
          <span>{payment.bankAccount.name}</span>
        </p>
        <hr className={styles.divider} />
        <p className="mb-16" data-test-review-amount="">
          <span>
            <FormattedMessage id="pagopa.payment-creation.review.amount-label" />
          </span>{' '}
          <span>
            <FormattedNumber
              currency={payment.amount.currency}
              style="currency"
              value={Number(payment.amount.value)}
            />
          </span>
        </p>
        <p data-test-review-payment-cost="">
          <span>
            <FormattedMessage id="pagopa.payment-creation.review.payment-cost-label" />
          </span>{' '}
          <span>
            {hasPaymentCost ? (
              <FormattedNumber
                currency={payment.feeEstimateAmount?.currency}
                style="currency"
                value={Number(payment.feeEstimateAmount?.value)}
              />
            ) : (
              <FormattedMessage id="pagopa.payment-creation.review.payment-cost-placeholder" />
            )}
          </span>
        </p>
        <p className="caption" data-test-review-payment-cost-caption="">
          {hasPaymentCost ? (
            <FormattedMessage id="pagopa.payment-creation.review.payment-cost.not-included" />
          ) : (
            <FormattedMessage id="pagopa.payment-creation.review.payment-cost.included" />
          )}
        </p>
        <hr className={styles.divider} />
        <p data-test-review-total-amount="">
          <span>
            <FormattedMessage id="pagopa.payment-creation.review.total-amount-label" />
          </span>{' '}
          <span>
            <FormattedNumber
              currency={payment.totalAmount.currency}
              style="currency"
              value={Number(payment.totalAmount.value)}
            />
          </span>
        </p>
      </div>

      <div>
        <button
          className="btn btn--primary"
          data-test-review-cta=""
          onClick={onConfirm}
          type="button"
        >
          <FormattedMessage id="btn.confirm" />
          {isSubmitting ? (
            <Spinner
              className="ml-8"
              color="primary-b"
              data-test-review-cta-spinner=""
              size="small"
            />
          ) : null}
        </button>
      </div>
    </div>
  );
}
