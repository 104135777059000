/* eslint-disable formatjs/no-literal-string-in-jsx -- Step component is not translated */
import { type ReactElement } from 'react';
import cx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { Disclaimer, ExternalLink } from '@repo/design-system-kit';
import { ChecksImage } from 'qonto/react/components/checks/image';
import styles from './intro.strict-module.css';

interface FlowsCheckIntroProps {
  handleNext: () => void;
  maxAmount: number;
  maxAmountOnPeriod: number;
  organizationSlug: string;
  faqUrl: string;
}

export function FlowsCheckIntro({
  handleNext,
  maxAmount,
  maxAmountOnPeriod,
  organizationSlug,
  faqUrl,
}: FlowsCheckIntroProps): ReactElement {
  return (
    <div className={styles.checks}>
      <div className={styles.wrapper}>
        <div className={styles.form}>
          <h2 className={cx(styles.title, 'title-1 mb-16')} data-test-create-check-intro-title>
            <FormattedMessage id="checks.intro.title" />
          </h2>

          <div className={styles['wrapper-body']}>
            <div className={cx(styles['steps-container'], 'mb-24')}>
              <div className={styles.step}>
                <span className={styles['container-dot']}>1</span>
                <div className={styles.text}>
                  <p className={cx(styles['text-title'], 'body-1')}>
                    <FormattedMessage id="checks.intro.step1" />
                  </p>
                  <p className={cx(styles['text-desc'], 'body-1')}>
                    <FormattedMessage id="checks.intro.step1_desc" />
                  </p>
                </div>
              </div>

              <div className={styles.step}>
                <span className={styles['container-dot']}>2</span>
                <div className={styles.text}>
                  <p className={cx(styles['text-title'], 'body-1')}>
                    <FormattedMessage id="checks.intro.step2" />
                  </p>
                  <p className={cx(styles['text-desc'], 'body-1')}>
                    <FormattedMessage id="checks.intro.step2_desc" />
                  </p>
                </div>
              </div>

              <div className={styles.step}>
                <span className={styles['container-dot']}>3</span>
                <div className={styles.text}>
                  <p className={cx(styles['text-title'], 'body-1')}>
                    <FormattedMessage id="checks.intro.step3" />
                  </p>
                  <p className={cx(styles['text-desc'], 'body-1')} data-test-step3-desc>
                    <FormattedMessage id="checks.intro.step3-desc" />
                  </p>
                </div>
              </div>
            </div>

            <Disclaimer.Block className="mb-16" data-test-disclaimer="">
              <FormattedMessage
                id="checks.intro.disclaimer"
                values={{
                  maxAmount,
                  maxAmountOnPeriod,
                  organizationSlug,
                  // eslint-disable-next-line react/no-unstable-nested-components -- To be able to use the ExternalLink component
                  link: text => (
                    <ExternalLink
                      className="body-link"
                      href={faqUrl}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {text}
                    </ExternalLink>
                  ),
                }}
              />
            </Disclaimer.Block>

            <Disclaimer.Block className="mb-40">
              <FormattedMessage id="checks.intro.disclaimer_legal_name" />
            </Disclaimer.Block>

            <button
              className={cx('btn btn--primary', styles['start-button'])}
              data-test-create-check-intro-start-button
              onClick={handleNext}
              type="button"
            >
              <FormattedMessage id="btn.start" />
            </button>
          </div>
        </div>
      </div>
      <aside className={styles.aside}>
        <ChecksImage />
      </aside>
    </div>
  );
}
