import { type ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { InfoList } from '@repo/domain-kit/shared';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import cmc7Splitter from 'qonto/utils/cmc7-splitter';
import type { TransactionModel } from 'qonto/react/models/transaction';
import type { Check as CheckModel } from 'qonto/react/models/check';
import type { ICON_PATHS } from 'qonto/react/components/avatar/internal/icon-map';
import { Base } from '../base';
import { Close } from '../close';
import { Type } from '../type';
import { Picto } from '../picto';
import { General } from '../general';
import { Amount } from '../amount';
import { Counterparty } from '../counterparty';
import { Date } from '../date';
import styles from './styles.strict-module.css';

interface CheckProps {
  checkTransaction: TransactionModel;
  checkSubject: CheckModel;
  transactionHeaderTypeWording: string;
  sidebarCloseId: string;
  closeSidebar: () => void;
  showAccountName?: boolean;
}

export function Check({
  checkTransaction,
  checkSubject,
  transactionHeaderTypeWording,
  sidebarCloseId,
  closeSidebar,
  showAccountName = false,
  ...props
}: CheckProps): ReactElement {
  const { formatMessage, locale } = useIntl();

  const checkCmc7 = cmc7Splitter(checkSubject.cmc7 || '', ' ');

  return (
    <Base className={styles['check-header']} {...props}>
      <Base.Type>
        <Type content={transactionHeaderTypeWording} status={checkSubject.status} />
        <Close data-test-close id={sidebarCloseId} onClose={closeSidebar} />
      </Base.Type>

      <Base.Content>
        <Picto
          avatar={checkTransaction.avatarInfo.mediumLogo}
          categoryAssignment={checkTransaction.categoryAssignment}
          data-test-check-transaction-sidebar-header-picto
          hasBorder={Boolean(checkTransaction.enrichmentData.logo)}
          iconName={checkTransaction.avatarInfo.icon as keyof typeof ICON_PATHS}
        />

        <General>
          <Amount className="mb-4" transaction={checkTransaction} />
          <Counterparty className="mb-4" name={checkTransaction.counterpartyName} />
          <Date date={checkTransaction.emittedAt} />
        </General>

        {showAccountName ? (
          <InfoList className={styles.infobox}>
            <InfoList.Row
              content={checkTransaction.bankAccount?.name}
              data-test-account-name
              label={formatMessage({
                id: checkTransaction.debit ? 'labels.account_from' : 'labels.account_to',
              })}
            />
          </InfoList>
        ) : null}

        <InfoList className={styles.infobox}>
          <InfoList.Row
            content={checkCmc7}
            data-test-check-sidebar-checkid
            label={formatMessage({ id: 'checks.sidebar.check_id' })}
          />
          <InfoList.Row
            content={checkSubject.rlmc}
            data-test-check-sidebar-rlmckey
            label={formatMessage({ id: 'checks.sidebar.rlmc_key' })}
          />
          <InfoList.Row
            content={dateToken({
              date: checkSubject.emittedDate,
              locale,
              token: 'date-year-s',
            })}
            data-test-check-sidebar-emittedat
            label={formatMessage({ id: 'checks.sidebar.emitted_at' })}
          />
        </InfoList>
      </Base.Content>
    </Base>
  );
}
