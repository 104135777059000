import { type ReactNode } from 'react';
import cx from 'clsx';
import styles from './styles.strict-module.css';

export interface DataWithIconProps {
  title?: string;
  subtitle?: ReactNode;
  icon?: ReactNode;
  type?: 'info' | 'error';
  isEmptyCell?: boolean;
  cellTitleClassName?: string;
  cellSubtitleClassName?: string;
}

export function DataWithIconCell({
  icon,
  title,
  subtitle = null,
  type,
  isEmptyCell,
  cellTitleClassName,
  cellSubtitleClassName,
}: DataWithIconProps): ReactNode {
  return (
    <div className={styles.container} data-test-type={type ?? ''} data-testid="data-with-icon-cell">
      {icon ? (
        <div
          aria-hidden="true"
          className={cx(styles.icon, type && !subtitle && styles[type])}
          data-testid="cell-icon"
        >
          {icon}
        </div>
      ) : null}
      <div className={styles['title-group']}>
        <p
          className={cx(
            styles.title,
            type && (!subtitle || (isEmptyCell && type === 'error')) && styles[type],
            'body-2',
            cellTitleClassName
          )}
          data-testid="cell-title"
        >
          {title}
        </p>
        {typeof subtitle === 'string' ? (
          <p
            className={cx(
              styles.subtitle,
              type && !(isEmptyCell && type === 'error') && styles[type],
              cellSubtitleClassName
            )}
            data-testid="cell-subtitle"
          >
            {subtitle}
          </p>
        ) : (
          subtitle
        )}
      </div>
    </div>
  );
}
