import { type ReactNode } from 'react';
import cx from 'clsx';
import styles from './styles.strict-module.css';

interface StickyPanelProps {
  children: ReactNode;
  className?: string;
}

export function StickyPanel({ children, className }: StickyPanelProps): ReactNode {
  return <div className={cx(styles['sticky-panel'], className)}>{children}</div>;
}
