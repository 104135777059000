/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useSegment } from '@repo/poly-hooks/segment';
import cx from 'clsx';
import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import { ORGA_STATUS } from 'qonto/constants/organization';
import { SUBSCRIPTION_RECURRENCES } from 'qonto/constants/subscriptions';
import { codeToName } from 'qonto/models/subscription-product';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import type FlowLinkManagerService from 'qonto/services/flow-link-manager';
import styles from './styles.strict-module.css';

interface SubscriptionFee {
  pricePlanBrandName: string;
  amount: {
    value: string;
    currency: string;
  };
  fromDate: string;
  toDate: string;
  recurrence?: 'annual' | 'monthly';
}

interface Refunds {
  initialOrSubscriptionAmount: {
    value: string;
    currency: string;
  };
  monthlyAmountOrRewardAmount: {
    value: string;
    currency: string;
  };
  totalAmount: {
    value: string;
    currency: string;
  };
}

interface AddonSubscriptionFee {
  recurrence: 'annual' | 'monthly';
  amount: {
    value: string;
    currency: string;
  };
  fromDate: string;
  toDate: string;
}

interface Addon {
  code: string;
  subscriptionFees: AddonSubscriptionFee[];
  subscriptionFeesTotalAmount: {
    value: string;
    currency: string;
  };
}

interface TotalFeesProps {
  subscriptionsFeesTotal: {
    value: string;
    currency: string;
  };
  additionalFeesTotal: {
    value: string;
    currency: string;
  };
  allFeesTotal: {
    value: string;
    currency: string;
  };
  allFeesTotalVatIncluded: {
    value: string;
    currency: string;
  };
  subscriptions: SubscriptionFee[];
  refunds?: Refunds;
  addons?: Addon[];
  hasDeactivationInProgress?: boolean;
}

export function TotalFees({
  subscriptionsFeesTotal,
  additionalFeesTotal,
  allFeesTotal,
  allFeesTotalVatIncluded,
  subscriptions,
  refunds,
  addons,
  hasDeactivationInProgress,
  ...props
}: TotalFeesProps): ReactNode {
  const { formatMessage, formatNumber, locale } = useIntl();
  const flowLinkManager = useEmberService('flow-link-manager') as unknown as FlowLinkManagerService;
  const intl = useEmberService('intl');
  const subscriptionManager = useEmberService('subscription-manager');
  const organizationManager = useOrganizationManager();
  const segment = useSegment();

  const currentMonthYear = dateToken({
    date: new Date().toISOString(),
    locale,
    token: 'month-year-l',
  });

  const isAnnual =
    subscriptionManager.currentSubscription?.recurrence === SUBSCRIPTION_RECURRENCES.ANNUAL;

  const switchAnnualEnabled =
    organizationManager.organization.status === ORGA_STATUS.ACTIVATED && !hasDeactivationInProgress;

  const currentPricePlanCode = `${(subscriptionManager.currentPricePlan as { groupCode: string }).groupCode}_2024`;

  const getNameFromCode = (code: string): string | null => codeToName(intl, code);

  const subscriptionFlowUrl = `/organizations/${organizationManager.organization.slug}/flows/subscription-change/plans?plan=${currentPricePlanCode}&recurrence=${SUBSCRIPTION_RECURRENCES.ANNUAL}`;
  const handleSwitchAnnualClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
    segment.track('plans_switch_to_annual_clicked');
    void flowLinkManager.transitionTo({
      name: 'subscription-change',
      stepId: 'plans',
      queryParams: { plan: currentPricePlanCode, recurrence: SUBSCRIPTION_RECURRENCES.ANNUAL },
    });
  };

  return (
    <div {...props}>
      <div className={styles['flex-wrapper']}>
        <h2 className="mb-4 title-3" data-test-subscription-total-fees-title>
          {formatMessage(
            { id: 'subscription.consumption-table.plan-overview.total-price.current-month' },
            { currentMonthYear }
          )}
        </h2>
        <span className="mb-4 title-3" data-test-subscription-total-fees-title-amount>
          {formatNumber(Number(allFeesTotal.value), {
            style: 'currency',
            currency: allFeesTotal.currency,
          })}
        </span>
      </div>

      <div className={styles['flex-wrapper']}>
        <p
          className={cx('body-1', styles['total-vat'])}
          data-test-subscription-total-fees-vat-title
        >
          {formatMessage(
            { id: 'subscription.consumption-table.plan-overview.total-price.current-month-vat' },
            { currentMonthYear }
          )}
        </p>
        <span
          className={cx('body-2', styles['total-vat'])}
          data-test-subscription-total-fees-vat-title-amount
        >
          {formatNumber(Number(allFeesTotalVatIncluded.value), {
            style: 'currency',
            currency: allFeesTotalVatIncluded.currency,
          })}
        </span>
      </div>

      {!isAnnual && switchAnnualEnabled ? (
        <div className={cx(styles['switch-recurrence'], 'mb-16')}>
          <a
            className="body-link"
            data-test-annual-recurrence-link
            href={subscriptionFlowUrl}
            onClick={handleSwitchAnnualClick}
          >
            {formatMessage({
              id: 'subscription.consumption-table.plan-overview.total-price.switch-recurrence',
            })}
          </a>
        </div>
      ) : null}

      <div
        className={cx(styles['flex-wrapper'], 'body-1', subscriptions.length > 1 ? 'mb-4' : 'mb-8')}
      >
        <p className={styles['fee-description']} data-test-subscription-total-fees-recurring-title>
          {formatMessage({
            id: 'subscription.consumption-table.plan-overview.total-price.monthly-cost',
          })}
        </p>
        <span data-test-subscription-total-fees-recurring-amount>
          {formatNumber(Number(subscriptionsFeesTotal.value), {
            style: 'currency',
            currency: subscriptionsFeesTotal.currency,
          })}
        </span>
      </div>

      {subscriptions.length > 1 &&
        subscriptions.map(subscription => (
          <div
            className={cx(styles['flex-wrapper'], 'body-1', 'mb-8')}
            data-test-subscription-total-fees-price-plan-item
            key={`${subscription.pricePlanBrandName}-${subscription.fromDate}`}
          >
            <p className={styles['fee-description']}>
              {formatMessage(
                {
                  id:
                    subscription.recurrence === 'annual'
                      ? 'subscription.consumption-table.plan-overview.total-price.change-plan-new-annual'
                      : 'subscription.consumption-table.plan-overview.total-price.change-plan-new-monthly',
                },
                {
                  nameNewPlan: subscription.pricePlanBrandName,
                  beginningDate: dateToken({
                    date: subscription.fromDate,
                    locale,
                    token: 'date-s',
                  }),
                }
              )}
            </p>
            <span>
              {formatNumber(Number(subscription.amount.value), {
                style: 'currency',
                currency: subscription.amount.currency,
              })}
            </span>
          </div>
        ))}

      {addons?.map((addon, index) => (
        <div data-test-addon-fee={index} key={addon.code}>
          <div className={cx(styles['flex-wrapper'], 'body-1', 'mb-8')}>
            <p className={styles['fee-description']} data-test-addon-fee-title>
              {getNameFromCode(addon.code)}
            </p>
            <span data-test-addon-fee-amount>
              {formatNumber(Number(addon.subscriptionFeesTotalAmount.value), {
                style: 'currency',
                currency: addon.subscriptionFeesTotalAmount.currency,
              })}
            </span>
          </div>

          {addon.subscriptionFees.length > 1 &&
            addon.subscriptionFees.map((subscription, subIndex) => (
              <div
                className={cx(
                  styles['flex-wrapper'],
                  styles['subscription-detail'],
                  'body-1',
                  'mb-8'
                )}
                data-test-subscription-item={subIndex}
                key={`${addon.code}-${subscription.fromDate}-${subscription.recurrence}`}
              >
                <p className={styles['fee-description']} data-test-subscription-description>
                  {formatMessage(
                    {
                      id:
                        subscription.recurrence === 'annual'
                          ? 'subscription.consumption-table.addon-overview.billing-details.annual-billing'
                          : 'subscription.consumption-table.addon-overview.billing-details.monthly-billing',
                    },
                    {
                      addonName: getNameFromCode(addon.code),
                      beginningDate: dateToken({
                        date: subscription.fromDate,
                        locale,
                        token: 'date-s',
                      }),
                    }
                  )}
                </p>
                <span data-test-subscription-amount>
                  {formatNumber(Number(subscription.amount.value), {
                    style: 'currency',
                    currency: subscription.amount.currency,
                  })}
                </span>
              </div>
            ))}
        </div>
      ))}

      <div
        className={cx(
          styles['flex-wrapper'],
          'body-1',
          Number(refunds?.totalAmount.value) > 0 && 'mb-8'
        )}
      >
        <p
          className={styles['fee-description']}
          data-test-subscription-total-fees-additional-fees-title
        >
          {formatMessage({
            id: 'subscription.consumption-table.plan-overview.total-price.additional-fees',
          })}
        </p>
        <span data-test-subscription-total-fees-additional-fees-amount>
          {formatNumber(Number(additionalFeesTotal.value), {
            style: 'currency',
            currency: additionalFeesTotal.currency,
          })}
        </span>
      </div>

      {Number(refunds?.totalAmount.value) > 0 && (
        <>
          <div className={styles['flex-wrapper']}>
            <p
              className={cx(
                'body-1',
                styles['fee-description'],
                Number(refunds?.initialOrSubscriptionAmount.value) > 0 && 'mb-4'
              )}
              data-test-subscription-total-fees-refunds-title
            >
              {formatMessage({
                id: 'subscription.consumption-table.plan-overview.total-price.refunds.title',
              })}
            </p>
            <span
              className={cx(
                styles['refunds-total'],
                'body-1',
                Number(refunds?.initialOrSubscriptionAmount.value) > 0 && 'mb-4'
              )}
              data-test-subscription-total-fees-refund-total
            >
              -{' '}
              {formatNumber(Number(refunds?.totalAmount.value), {
                style: 'currency',
                currency: refunds?.totalAmount.currency,
              })}
            </span>
          </div>

          {Number(refunds?.initialOrSubscriptionAmount.value) > 0 && (
            <div className={styles['flex-wrapper']}>
              <p
                className={cx(
                  styles['promo-code'],
                  styles['fee-description'],
                  'body-2',
                  Number(refunds?.monthlyAmountOrRewardAmount.value) > 0 && 'mb-4'
                )}
                data-test-subscription-total-fees-promo-code-title
              >
                {formatMessage({
                  id: 'subscription.consumption-table.plan-overview.total-price.promo-code',
                })}
              </p>
              <span
                className={cx(
                  styles['promo-code-total'],
                  'body-2',
                  Number(refunds?.monthlyAmountOrRewardAmount.value) > 0 && 'mb-4'
                )}
                data-test-subscription-total-fees-promo-code-total
              >
                {formatNumber(Number(refunds?.initialOrSubscriptionAmount.value), {
                  style: 'currency',
                  currency: refunds?.initialOrSubscriptionAmount.currency,
                })}
              </span>
            </div>
          )}

          {Number(refunds?.monthlyAmountOrRewardAmount.value) > 0 && (
            <div className={styles['flex-wrapper']}>
              <p
                className={cx(styles.others, styles['fee-description'], 'body-2')}
                data-test-subscription-total-fees-others-title
              >
                {formatMessage({
                  id: 'subscription.consumption-table.plan-overview.total-price.refunds.others',
                })}
              </p>
              <span
                className={cx(styles['others-total'], 'body-2')}
                data-test-subscription-total-fees-others-total
              >
                {formatNumber(Number(refunds?.monthlyAmountOrRewardAmount.value), {
                  style: 'currency',
                  currency: refunds?.monthlyAmountOrRewardAmount.currency,
                })}
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
}
