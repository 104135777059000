/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import { Label } from '@repo/design-system-kit';
import cx from 'clsx';
import { useRef, useState, type ChangeEvent, type ReactNode } from 'react';
import { useId } from 'react-aria';
import { Group, GroupContext, Input, LabelContext, type GroupProps } from 'react-aria-components';
import { useIntl } from 'react-intl';
import {
  formatCheckCMC7,
  formatCheckRLMC,
  handleFormatCheckCMC7,
  handleFormatCheckRLMC,
} from 'qonto/utils/format-input';
import styles from './styles.strict-module.css';

const CMC7_LENGTH = 31;
const RLMC_LENGTH = 2;

interface LabeledGroupProps {
  children: ReactNode;
}

function LabeledGroup({ children }: LabeledGroupProps): ReactNode {
  const labelId = useId();

  return (
    <LabelContext.Provider value={{ id: labelId, elementType: 'span' }}>
      <GroupContext.Provider value={{ 'aria-labelledby': labelId }}>
        {children}
      </GroupContext.Provider>
    </LabelContext.Provider>
  );
}

interface CheckFieldProps extends GroupProps {
  cmc7?: string;
  rlmc?: string;
  label: string;
  errorMessage?: string;
  onCmc7Update: (value: string) => void;
  onRlmcUpdate: (value: string) => void;
}

export function CheckField({
  cmc7,
  rlmc,
  label,
  errorMessage,
  onCmc7Update,
  onRlmcUpdate,
  className,
  ...props
}: CheckFieldProps): ReactNode {
  const [cmc7Value, setCmc7Value] = useState(cmc7 ?? '');
  const [rlmcValue, setRlmcValue] = useState(rlmc ?? '');
  const { formatMessage } = useIntl();
  const errorMessageId = useId();
  const isInvalid = Boolean(errorMessage);
  const rlmcInputRef = useRef<HTMLInputElement>(null);

  const handleCodeChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const element = event.target;
    const sanitizedValue = element.value.replace(/\D/g, '');

    if (sanitizedValue.length >= CMC7_LENGTH) {
      rlmcInputRef.current?.focus();
    }

    setCmc7Value(sanitizedValue);
    handleFormatCheckCMC7(element);
    onCmc7Update(sanitizedValue);
  };

  const handleReferenceChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const element = event.target;
    const sanitizedValue = element.value.replace(/\D/g, '');
    handleFormatCheckRLMC(element);
    setRlmcValue(sanitizedValue);
    onRlmcUpdate(sanitizedValue);
  };

  return (
    <LabeledGroup>
      <div className={cx(styles.container, className)} data-test-check-field>
        <Label data-test-label>{label}</Label>
        <Group
          {...props}
          aria-describedby={errorMessageId}
          className={styles.group}
          isInvalid={isInvalid}
          role="group"
        >
          <Input
            aria-label="cmc7"
            className={styles['cmc7-input']}
            data-test-input="cmc7"
            onChange={handleCodeChange}
            placeholder={formatMessage({ id: 'placeholders.cmc7' })}
            size={CMC7_LENGTH}
            type="text"
            value={formatCheckCMC7(cmc7Value)}
          />
          <Input
            aria-label="rlmc"
            className={styles['rlmc-input']}
            data-test-input="rlmc"
            onChange={handleReferenceChange}
            placeholder={formatMessage({ id: 'placeholders.rlmc' })}
            ref={rlmcInputRef}
            size={RLMC_LENGTH}
            type="text"
            value={formatCheckRLMC(rlmcValue)}
          />
        </Group>
        {isInvalid ? (
          <span className={cx(styles.error, 'caption')} id={errorMessageId} slot="errorMessage">
            {errorMessage}
          </span>
        ) : null}
      </div>
    </LabeledGroup>
  );
}
