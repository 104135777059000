/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import { type ReactNode } from 'react';
import { FormattedNumber } from 'react-intl';
import { useFirstTimeExperience } from '../../hooks/use-first-time-experience';
import type { BalanceData } from '../../models/balance';
import styles from './styles.strict-module.css';

interface BalanceCellProps {
  balance: BalanceData;
}

export function BalanceCell({ balance }: BalanceCellProps): ReactNode {
  const { amount, forecastAmount, isPastMonth, isFutureMonth, isCurrentMonth, type } = balance;

  const { isFirstTimeExperience, hasFirstTimeExperieceForecastBeenSet } = useFirstTimeExperience();

  const calculatedForecastAmount =
    (isFirstTimeExperience && !hasFirstTimeExperieceForecastBeenSet) || !forecastAmount
      ? 0
      : forecastAmount;

  if (type === 'end' && isCurrentMonth) {
    return (
      <span className={styles.forecastAmount}>
        <FormattedNumber
          currency="EUR"
          maximumFractionDigits={0}
          minimumFractionDigits={0}
          value={forecastAmount ?? 0}
        />
      </span>
    );
  }

  if (isCurrentMonth || isPastMonth) {
    return (
      <FormattedNumber
        currency="EUR"
        maximumFractionDigits={0}
        minimumFractionDigits={0}
        value={amount ?? 0}
      />
    );
  }

  if (isFutureMonth) {
    return (
      <span className={styles.forecastAmount}>
        <FormattedNumber
          currency="EUR"
          maximumFractionDigits={0}
          minimumFractionDigits={0}
          value={calculatedForecastAmount}
        />
      </span>
    );
  }

  return <span>-</span>;
}
