import type { StatusProps } from '@repo/design-system-kit';
import type { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { BadgeStatus } from '@repo/design-system-kit';
import type { RepaymentStatus } from '../next-installments-tile';
import styles from './styles.strict-module.css';

export function Badge({
  repaymentStatus,
}: {
  repaymentStatus: Exclude<RepaymentStatus, 'frozen'>;
}): ReactElement {
  const intl = useIntl();
  const badgeStatusMap: Record<Exclude<RepaymentStatus, 'frozen'>, StatusProps> = {
    at_risk: {
      level: 'warning',
      text: intl.formatMessage({
        id: 'financing.pay-later.cockpit.insights.next-installments.at-risk.badge-text',
      }),
    },
    late: {
      level: 'error',
      text: intl.formatMessage({
        id: 'financing.pay-later.cockpit.insights.next-installments.overdue.badge-text',
      }),
    },
  };

  const badgeDetails = badgeStatusMap[repaymentStatus];

  return (
    <BadgeStatus
      {...badgeDetails}
      className={styles.badge}
      data-test-next-installments-tile-badge=""
    />
  );
}
