/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { dateToken } from '@repo/design-system-kit';
import { BaseCell } from 'qonto/react/components/table-v2/cells/base-cell';
import { cellContextManager } from 'qonto/react/contexts/cell-context';
import { DataWithIconCell } from 'qonto/react/components/table-v2/cells/data-with-icon-cell';
import { StatusAvatar } from 'qonto/react/components/avatar/status-avatar';
import { Status } from 'qonto/react/graphql';
import { StopXS } from 'qonto/react/assets/icons/stop-xs';
import { UploadOutlined } from 'qonto/react/assets/icons/upload-outlined';
import { getSignedAmount } from 'qonto/react/components/table-v2/cells/amount-cell/amount-cell';
import { useFormattedAmount } from 'qonto/react/hooks/ui';
import styles from './styles.strict-module.css';

export function AttachmentCellDropzone(): ReactNode {
  const { formatMessage, locale } = useIntl();
  const transaction = cellContextManager.useCellContext();
  const {
    counterpartyName: name,
    enrichmentData,
    status,
    amount,
    side,
    localAmount,
    emittedAt: date,
  } = transaction;
  const avatar = enrichmentData.logo?.small;
  const isDeclined = status === Status.Declined || status === Status.Reversed;
  const { currency, value } = amount;
  const amountNumber = getSignedAmount(value, side);
  const localAmountNumber = getSignedAmount(localAmount?.value, side);
  const { formattedAmount } = useFormattedAmount({
    amount: { currency, value: amountNumber },
    localAmount: localAmount
      ? { currency: localAmount.currency, value: localAmountNumber }
      : undefined,
  });

  const formattedDate = dateToken({
    date,
    locale,
    token: 'date-year-s',
  });

  const isTransaction = Boolean(side);

  const transactionScrollLeft = document.getElementById('transactions-table-scroll-container');

  const isNull = amountNumber === 0;
  const isNegative = amountNumber < 0 && !isNull;
  const positiveHighlight = isTransaction && !isNegative && !isNull;

  const icon = avatar ? (
    <StatusAvatar
      alt={name}
      dataTestId="avatar"
      hasBorder
      icon={isDeclined ? <StopXS /> : undefined}
      iconSize="16"
      src={avatar}
    />
  ) : (
    <StatusAvatar
      avatar="custom"
      dataTestId="custom-avatar"
      icon={isDeclined ? <StopXS /> : undefined}
      iconSize="16"
      name={name}
    />
  );

  const subtitle = (
    <span className={cx(styles.subtitle, 'caption')}>
      {positiveHighlight ? <span className={styles['plus-sign']}>+ </span> : null}
      {formattedAmount} {currency}
      <span> • </span>
      {formattedDate || '-'}
    </span>
  );

  return (
    <BaseCell
      className={styles.wrapper}
      data-is-dragging
      data-testid="attachment-cell-dropzone"
      style={{ left: transactionScrollLeft?.scrollLeft }}
    >
      <DataWithIconCell icon={icon} subtitle={subtitle} title={name} />
      <span className={styles['title-wrapper']}>
        <UploadOutlined className={styles.icon} />
        <p className={cx(styles.title, 'body-2')}>
          {formatMessage({ id: 'transactions.table.attachments.dropzone' }, { maxSize: '15MB' })}
        </p>
      </span>
    </BaseCell>
  );
}
