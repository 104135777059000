import { type ReactNode } from 'react';
import type { Uppy } from '@uppy/core';
import { FormattedMessage, useIntl } from 'react-intl';
import useUppyState from '@uppy/react/lib/useUppyState';
import { Disclaimer, type DisclaimerProps } from '@repo/design-system-kit';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { PopoverSection } from 'qonto/react/components/table-v2/popover';
import { cellContextManager } from 'qonto/react/contexts/cell-context';
import { useUnlinkTransactionAttachment } from 'qonto/react/hooks/mutations/use-unlink-attachment';
import type { Body, Meta } from 'qonto/react/hooks/use-attachments-uploader';
import { MAX_ATTACHMENT_COUNT } from 'qonto/react/constants';
import styles from './styles.strict-module.css';

interface DisclaimerPopoverSectionProps {
  uppy: Uppy<Meta, Body>;
}

export function DisclaimerPopoverSection({ uppy }: DisclaimerPopoverSectionProps): ReactNode {
  const { formatMessage } = useIntl();
  const transaction = cellContextManager.useCellContext();
  const { id: transactionId, attachments, automaticallyAddedAttachmentIds } = transaction;
  useUnlinkTransactionAttachment(transactionId);
  const uploadDisclaimerInfo = useUppyState(uppy, state => state.info);
  const zendeskLocalization = useEmberService('zendesk-localization');

  const uniqueDisclaimerInfo = uploadDisclaimerInfo.filter(
    (item, index, arr) =>
      arr.findIndex(currentItem => currentItem.message === item.message) === index
  );
  const hasReachedMaxAttachments = attachments.length >= MAX_ATTACHMENT_COUNT;
  const hasAttachmentScanning = attachments.some(
    attachment => attachment.probativeAttachment?.status === 'pending'
  );
  const abilities = useEmberService('abilities');
  const hasAutoAttachments =
    abilities.can('access email forward attachment') && automaticallyAddedAttachmentIds.length;
  const shouldShowDisclaimerSection =
    hasReachedMaxAttachments ||
    hasAttachmentScanning ||
    uniqueDisclaimerInfo.length ||
    hasAutoAttachments;

  return (
    <>
      {shouldShowDisclaimerSection ? (
        <PopoverSection className={styles.section}>
          {hasAutoAttachments ? (
            <Disclaimer.Inline
              className={styles.disclaimer}
              data-testid="auto-matching-disclaimer"
              level="info"
            >
              <FormattedMessage
                id="transactions.table.attachments.popover.disclaimer.auto-matching"
                values={{
                  link: (
                    <a
                      href={zendeskLocalization.getLocalizedArticle('attachments-auto-matching')}
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      {formatMessage({
                        id: 'transactions.table.attachments.popover.link-text.auto-matching',
                      })}
                    </a>
                  ),
                }}
              />
            </Disclaimer.Inline>
          ) : null}
          {hasReachedMaxAttachments ? (
            <Disclaimer.Inline
              className={styles.disclaimer}
              data-testid="max-count-disclaimer"
              level="info"
            >
              {formatMessage(
                {
                  id: 'transactions.table.attachments.popover.disclaimer.upload-limit',
                },
                {
                  count: MAX_ATTACHMENT_COUNT,
                }
              )}
            </Disclaimer.Inline>
          ) : null}
          {uniqueDisclaimerInfo.length
            ? uniqueDisclaimerInfo.map(({ type: level, message }) => (
                <Disclaimer.Inline
                  className={styles.disclaimer}
                  data-testid="upload-error-disclaimer"
                  key={message}
                  level={level as DisclaimerProps['level']}
                >
                  {message}
                </Disclaimer.Inline>
              ))
            : null}
          {hasAttachmentScanning ? (
            <Disclaimer.Inline
              className={styles.disclaimer}
              data-testid="scanning-disclaimer"
              level="info"
            >
              {formatMessage({
                id: 'transactions.modular_table.scanning.label.disclaimer',
              })}
            </Disclaimer.Inline>
          ) : null}
        </PopoverSection>
      ) : null}
    </>
  );
}
