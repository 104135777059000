import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './related-documents-loading.strict-module.css';

interface RelatedDocumentsLoadingProps {
  isCreditNote: boolean;
}

export function RelatedDocumentsLoading({ isCreditNote }: RelatedDocumentsLoadingProps): ReactNode {
  const { formatMessage } = useIntl();
  const headingText = isCreditNote
    ? formatMessage({ id: 'supplier-invoices.credit-note-modal.related-invoice.title' })
    : formatMessage(
        { id: 'supplier-invoices.invoice-modal.related-credit-notes.title' },
        { count: 1 }
      );
  return (
    <div className={styles.wrapper}>
      <h3 className="caption-bold mb-16" data-test-related-documents-title>
        {headingText}
      </h3>
      <div className={styles.item}>
        <div>
          <SkeletonLoader.Avatar
            data-testid="related-documents-loading-avatar"
            height="48px"
            width="48px"
          />
        </div>
        <div className={styles.details}>
          <SkeletonLoader.Line
            data-testid="related-documents-loading-line"
            height="8px"
            standalone
            width="91px"
          />
          <SkeletonLoader.Line
            data-testid="related-documents-loading-line"
            height="8px"
            standalone
            width="215px"
          />
        </div>
      </div>
    </div>
  );
}
