import type { ReactNode } from 'react';
import { Button } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import { Dialog, DialogTrigger, Popover, Button as AriaButton } from 'react-aria-components';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { IconDotsOutlined } from '@repo/monochrome-icons';
import { useOrganizationNavigation } from 'qonto/react/shared/hooks/use-organization-navigation';
import styles from './styles.strict-module.css';

export function CategoriesMenu(): ReactNode {
  const { formatMessage } = useIntl();
  const segment = useEmberService('segment');
  const organizationNavigation = useOrganizationNavigation();

  const goToManageCategories = (): void => {
    segment.track('cash-flow_category_manage');
    void organizationNavigation('/cash-flow-categories');
  };

  return (
    <div>
      <DialogTrigger>
        <Button data-testid="categories-mgt-menu-trigger" iconOnly size="small" variant="secondary">
          <IconDotsOutlined height={16} width={16} />
        </Button>
        <Popover
          className={styles['menu-popover']}
          data-testid="categories-mgt-menu-popover"
          placement="bottom right"
        >
          <Dialog>
            <AriaButton
              className={styles['menu-item']}
              data-testid="categories-mgt-navigate-button"
              onPress={goToManageCategories}
            >
              <div className={styles['menu-item-content']}>
                {formatMessage({ id: 'transactions.sidebar.manage-categories.cta' })}
              </div>
            </AriaButton>
          </Dialog>
        </Popover>
      </DialogTrigger>
    </div>
  );
}
