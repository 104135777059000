import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { IconNavTasks, TabList, Tabs } from '@repo/design-system-kit';
import type { Key, ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { INSIGHT_LABELS, TABS } from 'qonto/constants/supplier-invoice.ts';
import { NavRequests } from 'qonto/react/assets/icons';
import { useOrganizationNavigation } from 'qonto/react/shared/hooks/use-organization-navigation';
import styles from './tabs.strict-module.css';

type IconKey = 'icon_nav_tasks' | 'icon_nav_requests';
type Insights = Record<(typeof INSIGHT_LABELS)[keyof typeof INSIGHT_LABELS], number>;

interface SupplierInvoicesTabsProps {
  activeId: string;
  insights?: Insights;
  totalInvoices: number;
  onTabChange: (tab: Key) => void;
}

interface TabsProps {
  id: string;
  index: number;
  icon?: IconKey;
  title: string;
  tab: string;
  count?: number;
  hasNotifications?: boolean;
  hasDivider?: boolean;
  event?: string;
}

const TAB_ICONS: Record<IconKey, ReactElement> = {
  icon_nav_tasks: <IconNavTasks data-testid="icon-nav-tasks" />,
  icon_nav_requests: <NavRequests data-testid="icon-nav-requests" />,
};

export function SupplierInvoicesTabs({
  activeId,
  insights,
  totalInvoices,
  onTabChange,
}: SupplierInvoicesTabsProps): JSX.Element {
  const abilities = useEmberService('abilities');
  const organizationNavigation = useOrganizationNavigation();
  const segment = useEmberService('segment');
  const subscriptionManager = useEmberService('subscription-manager');
  const { formatMessage } = useIntl();

  const canReviewTransferRequest = abilities.can('review transfer request');

  const tabs: TabsProps[] = [
    {
      id: TABS.ALL_INVOICES,
      title: formatMessage({ id: 'supplier-invoices.section.tabs.all-invoices.title' }),
      count: totalInvoices,
      tab: TABS.ALL_INVOICES,
      event: 'supplier-invoices_all-invoices-tab_clicked',
    },
    {
      id: TABS.INBOX,
      title: formatMessage({ id: 'supplier-invoices.section.tabs.inbox.title' }),
      count: insights?.[INSIGHT_LABELS.inboxCounter],
      tab: TABS.INBOX,
      event: 'supplier-invoices_imported-tab_clicked',
    },
    ...(abilities.can('see state approval-workflow')
      ? [
          {
            id: TABS.TO_APPROVE,
            title: formatMessage({ id: 'supplier-invoices.section.tabs.to-approve.title' }),
            count: insights?.[INSIGHT_LABELS.toApproveCounter] ?? 0,
            tab: TABS.TO_APPROVE,
            event: 'supplier-invoices_to-approve-tab_clicked',
          },
        ]
      : []),
    {
      id: TABS.TO_PAY,
      title: formatMessage({ id: 'supplier-invoices.section.tabs.imported.title' }),
      count: insights?.[INSIGHT_LABELS.awaitingPaymentCounter] ?? 0,
      tab: TABS.TO_PAY,
      event: 'supplier-invoices_to-pay-tab_clicked',
    },
    ...(subscriptionManager.hasFeature('supplier_invoices_processing_invoices')
      ? [
          {
            id: TABS.SCHEDULED,
            title: formatMessage({ id: 'supplier-invoices.section.tabs.processing.title' }),
            count: insights?.[INSIGHT_LABELS.scheduledCounter] ?? 0,
            tab: TABS.SCHEDULED,
            event: 'supplier-invoices_processing-tab_clicked',
          },
        ]
      : []),
    {
      id: TABS.COMPLETED,
      title: formatMessage({ id: 'supplier-invoices.section.tabs.completed.title' }),
      tab: TABS.COMPLETED,
      event: 'supplier-invoices_paid-tab_clicked',
    },
  ].map((tab, idx) => ({
    ...tab,
    index: idx + 1,
  }));

  const handleLinkClick = (e: React.MouseEvent): void => {
    e.preventDefault();
    void organizationNavigation('/tasks/past');
  };

  const handleTabChange = (key: Key): void => {
    const selectedTab = tabs.find(tab => tab.tab === key);

    if (selectedTab?.event) {
      segment.track(selectedTab.event);
    }

    onTabChange(key);
  };

  return (
    <div className={styles['tabs-container']}>
      <Tabs activeId={activeId} changeTab={handleTabChange}>
        <TabList
          tabs={tabs.map(
            ({ id, title, icon, index, tab, count, hasNotifications, hasDivider }) => ({
              id: tab,
              title,
              count,
              hasNotifications,
              hasDivider,
              icon: icon && TAB_ICONS[icon],
              'data-test-tabs-nav-item': index,
              'data-test-tabs-nav-item-value': id,
            })
          )}
        />
        {canReviewTransferRequest && activeId === TABS.TASKS ? (
          <button
            className={styles['previous-requests']}
            data-testid="previous-requests-link"
            onClick={handleLinkClick}
            type="button"
          >
            {formatMessage({ id: 'supplier-invoices.section.tabs.pending-requests.tasks-link' })}
          </button>
        ) : null}
      </Tabs>
    </div>
  );
}
