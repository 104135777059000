import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { ProgressBar } from '@repo/design-system-kit';
import { IconCheckmarkRoundedOutlined } from '@repo/monochrome-icons';
import { INVOICED_STATUS } from 'qonto/constants/receivable-invoice';
import styles from './styles.strict-module.css';

interface InvoicedAmountProps {
  readonly invoicingDocument: {
    amountDue: number;
    currency: string;
    invoicedAmount?: number;
    invoicedStatus: string;
  };
}

export function InvoicedAmount({ invoicingDocument }: InvoicedAmountProps): ReactNode {
  const intl = useIntl();
  const { formatNumber } = useIntl();

  const currency = invoicingDocument.currency;

  const invoicedAmount = Number(invoicingDocument.invoicedAmount) || 0;

  const invoicedAmountText = formatNumber(invoicingDocument.invoicedAmount || 0, {
    currency,
    style: 'currency',
    currencyDisplay: 'code',
  });

  const amountDueText = formatNumber(Number(invoicingDocument.amountDue), {
    currency,
    style: 'currency',
    currencyDisplay: 'code',
  });

  const isInvoicedAmountFull = [INVOICED_STATUS.FULLY_INVOICED].includes(
    invoicingDocument.invoicedStatus
  );

  const showInvoicedAmountProgressBar = invoicedAmount !== 0 && !isInvoicedAmountFull;

  let invoicedAmountProgress;
  if (isInvoicedAmountFull) {
    invoicedAmountProgress = Number(invoicingDocument.amountDue);
  } else if (invoicedAmount > 0) {
    invoicedAmountProgress = invoicedAmount;
  } else {
    invoicedAmountProgress = 0;
  }

  return (
    <div className={styles['progress-container']} data-test-invoiced-amount-section>
      <div className={styles['progress-labels']}>
        <label className={styles['progress-text']} data-test-invoiced-amount-section-title>
          {intl.formatMessage({ id: 'receivable-invoices.quote-modal.invoiced-amount.title' })}
        </label>
        <div className={styles['flew-row']}>
          <label className={styles['progress-text']} data-test-invoiced-amount-text>
            {intl.formatMessage(
              { id: 'receivable-invoices.quote-modal.related-documents.invoiced-amount' },
              { amountInvoiced: invoicedAmountText, totalAmount: amountDueText }
            )}
          </label>
          {isInvoicedAmountFull ? (
            <div className={styles['check-icon']}>
              <IconCheckmarkRoundedOutlined
                data-test-invoiced-amount-full-icon
                style={{ color: 'var(--content-secondary)' }}
              />
            </div>
          ) : null}
        </div>
      </div>
      {showInvoicedAmountProgressBar ? (
        <ProgressBar
          className={styles['progress-bar']}
          data-test-invoiced-amount-progress-bar=""
          maxValue={invoicingDocument.amountDue}
          minValue={0}
          value={invoicedAmountProgress}
        />
      ) : null}
    </div>
  );
}
