import { useMutation, useQueryClient, type UseMutationResult } from '@tanstack/react-query';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useIntl } from 'react-intl';
import { upcomingTransactionsNamespace } from 'qonto/constants/hosts';
import { useFetchApi, type FetchFunction } from 'qonto/react/hooks/use-fetch-api';

export interface DeleteUpcomingTransactionRequest {
  upcomingTransactionId: string;
  isMultiple?: boolean;
  cascade?: boolean;
}

export const deleteUpcomingTransaction = async (
  fetchApi: FetchFunction,
  request: DeleteUpcomingTransactionRequest
): Promise<void> => {
  const { upcomingTransactionId, cascade = false } = request;
  const response = await fetchApi(
    `${upcomingTransactionsNamespace}/cash-flow/upcoming-transactions/${upcomingTransactionId}?cascade=${cascade}`,
    {
      method: 'DELETE',
    }
  );

  if (!response.ok)
    throw Error(
      `[HTTP status: ${response.status}] Upcoming transaction ${upcomingTransactionId} could not be deleted`
    );
};

export const useDeleteUpcomingTransaction = (): UseMutationResult<
  void,
  Error,
  DeleteUpcomingTransactionRequest
> => {
  const fetchApi = useFetchApi();
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();
  const toastFlashMessages = useEmberService('toast-flash-messages');
  const sentry = useEmberService('sentry');

  return useMutation({
    mutationFn: request => deleteUpcomingTransaction(fetchApi, request),
    onMutate: ({ isMultiple }) => ({ isMultiple }), // Store isMultiple in context
    onSuccess: async (_, __, context) => {
      // Invalidate the upcoming transactions query to refresh the list
      await queryClient.invalidateQueries({ queryKey: ['upcoming-transactions'] });

      toastFlashMessages.toastSuccess(
        formatMessage(
          { id: 'cash-flow.upcoming-transactions.toast.remove' },
          { count: context.isMultiple ? 2 : 1 }
        )
      );
    },
    onError: error => {
      toastFlashMessages.toastError(formatMessage({ id: 'toasts.errors.server_error' }));
      sentry.captureException(error);
    },
  });
};
