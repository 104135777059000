/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import type { ReactNode } from 'react';
import { CockpitTile } from '@repo/design-system-kit';
import { FormattedNumber, FormattedMessage } from 'react-intl';
import type { CurrentBalanceAmount } from 'qonto/react/models/cash-flow-timeseries';
import styles from './overview-boxes.strict-module.css';

interface CurrentBalanceOverviewBoxProps {
  currentBalanceAmount?: CurrentBalanceAmount;
  isLoading?: boolean;
  isError?: boolean;
}

export function CurrentBalanceOverviewBox({
  currentBalanceAmount,
  isLoading = false,
  isError = false,
}: CurrentBalanceOverviewBoxProps): ReactNode {
  const amount = (
    <span className={styles['amount-container']} data-testid="overview-amount">
      {currentBalanceAmount && !isError ? (
        <FormattedNumber
          currency={currentBalanceAmount.currency}
          style="currency"
          value={Number(currentBalanceAmount.value)}
        />
      ) : (
        '--'
      )}
    </span>
  );

  return (
    <CockpitTile
      amount={amount}
      isAmountLoading={isLoading}
      title={
        <span data-testid="overview-title">
          <FormattedMessage id="cash-flow.balance.current-header" />
        </span>
      }
    />
  );
}
