import { useIntl } from 'react-intl';
import { SubscriptionSuccess } from '@repo/domain-kit/pricing';
import type { ReactElement } from 'react';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './styles.strict-module.css';

export interface SuccessItalyCSProps {
  transitionToNext: () => void;
}

export function SuccessItalyCS({ transitionToNext }: SuccessItalyCSProps): ReactElement {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.container} data-test-addon-success-italy-step>
      <SubscriptionSuccess
        asset={
          <StaticThemedAsset
            alt=""
            assetPath="/illustrations/ill-cp-cards-concierge.svg"
            className={styles.image}
            data-test-subscription-success-image
          />
        }
        ctaText={formatMessage({ id: 'btn.close' })}
        onClick={transitionToNext}
        stretch={false}
        subtitle={formatMessage({ id: 'subscription.change.success.italy-restrictions.subtitle' })}
        title={formatMessage({ id: 'subscription.change.success.italy-restrictions.title' })}
      />
    </div>
  );
}
