import type { UpcomingTransaction } from 'qonto/react/hooks/use-upcoming-transactions';
import { useMarkAsPaid } from './actions-handlers/use-mark-as-paid';
import { useSeeInvoice } from './actions-handlers/use-see-invoice';
import { useSeeSupplierInvoice } from './actions-handlers/use-see-supplier-invoice';
import { useResendInvoice } from './actions-handlers/use-resend-invoice';
import { useDeleteHandler } from './actions-handlers/use-delete-handler';
import { useCancelTransferHandler } from './actions-handlers/use-cancel-transfer-handler';
import { usePayNow } from './actions-handlers/use-pay-now';
import { useRescheduleTransferHandler } from './actions-handlers/use-reschedule-transfer';
import { useScheduleSupplierInvoiceHandler } from './actions-handlers/use-schedule-supplier-invoice';
import { useScheduleClientInvoiceHandler } from './actions-handlers/use-schedule-client-invoice-handler';

export const useUpcomingTransactionActions = (
  upcomingTransaction: UpcomingTransaction | null
): {
  handleMarkAsPaid: () => void;
  handleSeeInvoice: () => void;
  handleSeeSupplierInvoice: () => void;
  handleResendInvoice: () => void;
  handleDelete: () => Promise<void>;
  handleCancelTransfer: () => Promise<void>;
  handlePayNow: () => void;
  handleRescheduleTransfer: () => Promise<void>;
  handleScheduleSupplierInvoice: () => void;
  handleScheduleClientInvoice: () => void;
} => {
  const handleMarkAsPaid = useMarkAsPaid(upcomingTransaction);
  const handleSeeInvoice = useSeeInvoice(upcomingTransaction);
  const handleSeeSupplierInvoice = useSeeSupplierInvoice(upcomingTransaction);
  const handleResendInvoice = useResendInvoice(upcomingTransaction);
  const handleDelete = useDeleteHandler(upcomingTransaction);
  const handleCancelTransfer = useCancelTransferHandler(upcomingTransaction);
  const handlePayNow = usePayNow(upcomingTransaction);
  const handleRescheduleTransfer = useRescheduleTransferHandler(upcomingTransaction);
  const handleScheduleSupplierInvoice = useScheduleSupplierInvoiceHandler(upcomingTransaction);
  const handleScheduleClientInvoice = useScheduleClientInvoiceHandler(upcomingTransaction);

  return {
    handleMarkAsPaid,
    handleSeeInvoice,
    handleSeeSupplierInvoice,
    handleResendInvoice,
    handleDelete,
    handleCancelTransfer,
    handlePayNow,
    handleRescheduleTransfer,
    handleScheduleSupplierInvoice,
    handleScheduleClientInvoice,
  };
};
