import { type ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useSegment } from '@repo/poly-hooks/segment';
import { Button } from '@repo/design-system-kit';
import { FullscreenModalTrigger } from 'qonto/react/shared/components/full-screen-modal-trigger';
import { AccountAggregationValueProposition } from 'qonto/react/financing/components/pay-later/cockpit/account-aggregation-value-proposition';
import { PageHeader as PageHeaderComponent } from 'qonto/react/shared/components/page-header';
// @ts-expect-error The file needs to be migrated to TypeScript
import { ErrorInfo } from 'qonto/utils/error-info';
import { useFetchSignatureRequest } from 'qonto/react/financing/hooks/use-fetch-signature-request';

interface FlowLinkManager {
  transitionTo: (options: {
    name: string;
    stepId?: string;
    queryParams: { origin: string; signatureUrl?: string };
  }) => Promise<void>;
}

interface PageHeaderProps {
  canFinanceTransfer?: boolean;
  canReactivate?: boolean;
  canIncreaseEligibility?: boolean;
  isLowCreditAmount?: boolean;
  className?: string;
}

interface ErrorInfoType {
  shouldSendToSentry: boolean;
  for: (error: Error) => ErrorInfoType;
}

export function PageHeader({
  canFinanceTransfer,
  canReactivate,
  canIncreaseEligibility,
  isLowCreditAmount,
  className,
}: PageHeaderProps): ReactNode {
  const { formatMessage } = useIntl();
  const toastFlashMessages = useEmberService('toastFlashMessages');
  const segment = useSegment();
  const sentry = useEmberService('sentry');
  const flowLinkManager = useEmberService('flow-link-manager') as unknown as FlowLinkManager;

  const onSignatureRequestError = (error: unknown): void => {
    toastFlashMessages.toastError(
      formatMessage({ id: 'financing.pay-later.empty-state.education.error-toast' })
    );

    const errorInfo = (ErrorInfo as ErrorInfoType).for(error as Error);
    if (errorInfo.shouldSendToSentry) {
      sentry.captureException(error);
    }
  };
  const { isSignatureRequestLoading, retrievePayLaterSignatureUrl } =
    useFetchSignatureRequest(onSignatureRequestError);

  const title = formatMessage({ id: 'financing.pay-later.cockpit.title' });

  let actions = null;
  if (canFinanceTransfer) {
    const handleFinanceTransferClick = (): void => {
      segment.track('pay-later-cockpit_usage_clicked');
      void flowLinkManager.transitionTo({
        name: 'pay-later-transfer',
        stepId: 'invoice-upload',
        queryParams: {
          origin: 'financing.pay-later',
        },
      });
    };

    if (isLowCreditAmount) {
      const handleTriggerClick = (): void => {
        segment.track('pay-later_cockpit_external-account-clicked', {
          origin: 'low-credit',
        });
      };

      actions = (
        <>
          <FullscreenModalTrigger
            buttonSize="small"
            buttonText={formatMessage({
              id: 'financing.pay-later.cockpit.signed.empty.check-credit.cta',
            })}
            buttonVariant="tertiary"
            onTriggerClick={handleTriggerClick}
          >
            <AccountAggregationValueProposition data-testid="account-aggregation-value-proposition" />
          </FullscreenModalTrigger>
          <Button
            className="ml-16"
            data-test-pay-later-primary-cta
            onPress={handleFinanceTransferClick}
          >
            <FormattedMessage id="financing.pay-later.cockpit.signed.empty.primary-cta" />
          </Button>
        </>
      );
    } else {
      actions = (
        <Button data-test-pay-later-primary-cta onPress={handleFinanceTransferClick}>
          <FormattedMessage id="financing.pay-later.cockpit.signed.empty.primary-cta" />
        </Button>
      );
    }
  } else if (canReactivate) {
    const handleReactivateClick = async (): Promise<void> => {
      segment.track('pay_later_cockpit-on_hold_reactivate');

      const data = await retrievePayLaterSignatureUrl();
      if (data?.url) {
        void flowLinkManager.transitionTo({
          name: 'pay-later-application',
          queryParams: { origin: 'cockpit', signatureUrl: data.url },
        });
      }
    };

    actions = (
      <Button
        data-test-reactivate-cta
        isLoading={isSignatureRequestLoading}
        onPress={handleReactivateClick}
        variant="primary"
      >
        <FormattedMessage id="financing.pay-later.cockpit.reactivate.cta" />
      </Button>
    );
  } else if (canIncreaseEligibility) {
    const handleTriggerClick = (): void => {
      segment.track('pay-later_cockpit_external-account-clicked', {
        origin: 'no-more-eligible',
      });
    };

    actions = (
      <FullscreenModalTrigger
        buttonSize="small"
        buttonText={formatMessage({
          id: 'financing.pay-later.cockpit.signed.empty.check-eligibility.cta',
        })}
        buttonVariant="tertiary"
        onTriggerClick={handleTriggerClick}
      >
        <AccountAggregationValueProposition data-testid="account-aggregation-value-proposition" />
      </FullscreenModalTrigger>
    );
  }

  return <PageHeaderComponent actions={actions} className={className} title={title} />;
}
