import { type ReactElement, useMemo, useCallback } from 'react';
import { Tooltip } from '@repo/design-system-kit';
import { FormattedNumber, useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import type { Amount } from 'qonto/react/models/amount.ts';
import type { CashflowForecastEntry } from 'qonto/react/models/cash-flow-forecast-entry.ts';
import { CashFlowAmountTypes, type CashflowSide } from 'qonto/react/models/cash-flow-timeseries.ts';
import styles from '../styles.strict-module.css';
import { useFirstTimeExperience } from '../../../hooks/use-first-time-experience';

interface CellTooltipWrapperProps {
  children: React.ReactElement;
  showForecastTooltip?: boolean | undefined;
  isCurrentMonth?: boolean;
  isFutureMonth?: boolean;
  currentMonthData: {
    amount: Amount<string> | undefined;
    forecastAmount?: Amount<string> | undefined;
    projectedAmount?: Amount<string> | undefined;
    forecast?: CashflowForecastEntry | null;
    flowType: CashflowSide;
  };
  showProjectedForecast?: boolean;
}

export function CellTooltipWrapper({
  children,
  showForecastTooltip,
  isCurrentMonth,
  isFutureMonth,
  currentMonthData,
  showProjectedForecast,
}: CellTooltipWrapperProps): ReactElement {
  const { formatMessage } = useIntl();
  const abilities = useEmberService('abilities');
  const { isFirstTimeExperience, hasFirstTimeExperieceForecastBeenSet } = useFirstTimeExperience();

  const getCurrentMonthWithForecastTooltip = useCallback((): ReactElement => {
    const amountValue = Number(currentMonthData.amount?.value ?? 0);
    const displayAmountSign =
      (currentMonthData.flowType === 'inflows' && amountValue < 0) ||
      (currentMonthData.flowType === 'outflows' && amountValue > 0);

    return (
      <div className={styles['current-month-tooltip-container']}>
        <CurrentMonthValue
          data={currentMonthData.amount}
          displayAmountSign={displayAmountSign}
          type={CashFlowAmountTypes.Actual}
        />
        {showProjectedForecast ? (
          <CurrentMonthValue
            data={currentMonthData.projectedAmount}
            type={CashFlowAmountTypes.Projected}
          />
        ) : null}
        <CurrentMonthValue
          data={currentMonthData.forecast?.amount}
          type={CashFlowAmountTypes.Forecast}
        />
        <CurrentMonthValue
          data={currentMonthData.forecast?.gapToForecast}
          type={CashFlowAmountTypes.GapToForecast}
        />
      </div>
    );
  }, [
    currentMonthData.amount,
    currentMonthData.flowType,
    currentMonthData.forecast?.gapToForecast,
    currentMonthData.forecast?.amount,
    currentMonthData.projectedAmount,
    showProjectedForecast,
  ]);

  const showFirstTimeExperienceTooltip = isFirstTimeExperience && isFutureMonth;

  const tooltipLabel = useMemo((): ReactElement | string => {
    if (showFirstTimeExperienceTooltip) {
      return hasFirstTimeExperieceForecastBeenSet
        ? formatMessage({
            id: 'cash-flow.first-time-experience.chart-overlay.forecast-set.tooltip',
          })
        : formatMessage({
            id: 'cash-flow.first-time-experience.chart-overlay.forecast-locked.tooltip',
          });
    }
    if (showForecastTooltip) {
      return getCurrentMonthWithForecastTooltip();
    }

    return formatMessage({ id: 'cash-flow.forecast.set-manual.tooltip' });
  }, [
    formatMessage,
    hasFirstTimeExperieceForecastBeenSet,
    getCurrentMonthWithForecastTooltip,
    showForecastTooltip,
    showFirstTimeExperienceTooltip,
  ]);

  const cellTooltip = useMemo(() => {
    return (
      <Tooltip
        closeDelay={0}
        delay={200}
        label={tooltipLabel}
        placement="top"
        tooltipTestId="balance-cell-tooltip"
      >
        {children}
      </Tooltip>
    );
  }, [children, tooltipLabel]);

  const canUseFlashForecasts = abilities.can('use flash forecasts cash-flow');

  if (
    (canUseFlashForecasts && (isCurrentMonth || showForecastTooltip)) ||
    showFirstTimeExperienceTooltip
  ) {
    return cellTooltip;
  }

  return children;
}

export function CurrentMonthValue({
  type,
  data,
  displayAmountSign,
}: {
  type: CashFlowAmountTypes;
  data: Amount<string> | undefined;
  displayAmountSign?: boolean;
}): ReactElement {
  const { formatMessage, formatNumber } = useIntl();

  const currentMonthValueText = useMemo(
    () => ({
      [CashFlowAmountTypes.Actual]: formatMessage({
        id: 'cash-flow.graph.period-tooltip.inflows.actual',
      }),
      [CashFlowAmountTypes.Forecast]: formatMessage({
        id: 'cash-flow.graph.period-tooltip.inflows.forecast',
      }),
      [CashFlowAmountTypes.Projected]: formatMessage({
        id: 'cash-flow.graph.period-tooltip.projected',
      }),
      [CashFlowAmountTypes.GapToForecast]: formatMessage({
        id: 'cash-flow.graph.period-tooltip.gap-to-forecast',
      }),
    }),
    [formatMessage]
  );

  const value = Number(data?.value ?? 0);
  const currency = data?.currency ?? 'EUR';

  return (
    <span className={styles['current-month-tooltip']}>
      <span>{currentMonthValueText[type]}</span>
      {type === CashFlowAmountTypes.Actual ? (
        <FormattedNumber
          currency={currency}
          maximumFractionDigits={0}
          minimumFractionDigits={0}
          signDisplay={displayAmountSign ? 'exceptZero' : 'never'}
          style="currency"
          value={value}
        />
      ) : (
        formatNumber(value, {
          style: 'currency',
          currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
      )}
    </span>
  );
}
