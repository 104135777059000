import { SkeletonLoader } from '@repo/design-system-kit';
import type { ReactElement } from 'react';
import styles from './styles.strict-module.css';

export function QPageSelectorPlaceholder({ ...props }): ReactElement {
  return (
    <div className={styles.placeholder} {...props}>
      <div className={styles.container}>
        <div className={styles.left}>
          <SkeletonLoader.Block
            borderRadius="3px"
            className={styles.buttons}
            height="24px"
            width="60px"
          />
          <SkeletonLoader.Line standalone width="70px" />
        </div>
        <div className={styles.right}>
          <SkeletonLoader.Line standalone width="70px" />
          <SkeletonLoader.Block
            borderRadius="3px"
            className={styles.buttons}
            height="24px"
            width="60px"
          />
        </div>
      </div>
    </div>
  );
}
