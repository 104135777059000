/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import cx from 'clsx';
import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { StatusAvatar } from 'qonto/react/components/avatar/status-avatar';
import { dateToken, DATE_FORMAT_TOKENS } from '@qonto/ui-kit/utils/date-token';
import type { FinancingModel } from 'qonto/react/financing/api';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import { formatMoney } from 'qonto/react/shared/utils/format-money';
import styles from './styles.strict-module.css';
import { NextInstallmentAmount } from './internal/next-installment-amount';

interface RowProps {
  financing: FinancingModel;
}

export function Row({ financing }: RowProps): ReactNode {
  const intl = useIntl();

  return (
    <>
      <td aria-hidden="true" className={cx(styles.cell, styles.empty)} />

      <td className={cx('body-1', styles.cell, styles.supplier)}>
        <div className={styles.supplierContent}>
          <div className={styles.supplierAvatar}>
            <StatusAvatar
              aria-hidden="true"
              data-test-enriched-logo
              hasBorder={financing.avatarInfo.hasBorder}
              icon={
                financing.status === 'processing' ? (
                  <StaticThemedAsset
                    alt=""
                    assetPath={`/icon/status/${financing.avatarInfo.icon}.svg`}
                    data-test-status-avatar-icon={financing.status}
                  />
                ) : undefined
              }
              iconSize="16"
              isLazyLoaded
              src={financing.avatarInfo.smallLogo}
            />
          </div>
          <div className={styles.overflowHidden}>
            <p className={styles.ellipsis} data-test-beneficiary-name>
              {financing.beneficiaryName}
            </p>
            {financing.isProcessing ? (
              <p className={styles.financingStatus} data-test-financing-status>
                {intl.formatMessage({
                  id: 'financing.pay-later.cockpit.table.transaction.status.processing',
                })}
              </p>
            ) : (
              <p className={styles.installments} data-test-installments>
                {intl.formatMessage(
                  {
                    id: 'financing.pay-later.cockpit.table.row.supplier.installments',
                  },
                  {
                    count: financing.paidInstallments,
                    current: financing.paidInstallments,
                    total: financing.totalInstallments,
                  }
                )}
              </p>
            )}
          </div>
        </div>
      </td>

      <td
        className={cx('body-2', styles.cell, styles.nextInstallmentDate)}
        data-test-next-installment
      >
        {financing.isProcessing
          ? '-'
          : dateToken({
              date: String(financing.nextInstallmentAt),
              locale: intl.locale,
              token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
            })}
      </td>

      <td
        className={cx(styles.cell, styles.nextInstallmentAmount)}
        data-test-next-installment-amount
      >
        <NextInstallmentAmount financing={financing} />
      </td>

      <td className={cx(styles.cell, styles.totalRepayment)} data-test-total-repayment>
        {formatMoney(Number(financing.totalAmount.value), {
          locale: intl.locale,
          currency: financing.totalAmount.currency,
        })}
      </td>

      <td aria-hidden="true" className={cx(styles.cell, styles.empty)} />
    </>
  );
}
