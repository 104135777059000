import type { ReactNode } from 'react';
import { useEventCallback } from 'usehooks-ts';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { PopoverButton } from 'qonto/react/components/table-v2/popover';
import { PAYMENT_METHOD_POPOVER_CLICK_EVENT_NAME } from 'qonto/react/constants';

export function PopoverItem({
  icon,
  text,
  onPress,
  ...props
}: {
  icon: ReactNode;
  text: string;
  onPress: () => void;
}): ReactNode {
  const segment = useEmberService('segment');

  const handlePress = useEventCallback((): void => {
    segment.track(PAYMENT_METHOD_POPOVER_CLICK_EVENT_NAME);
    onPress();
  });

  return (
    <PopoverButton
      onPress={handlePress}
      slots={{
        icon,
        text,
      }}
      {...props}
    />
  );
}
