import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { Tooltip } from '@repo/design-system-kit';
import { FlowHeader, type FlowHeaderProps } from '@repo/domain-kit/accounts-receivable';
import { IconSettingsOutlined } from '@repo/monochrome-icons';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { InstructionalTooltip } from 'qonto/react/components/product-discovery/instructional-tooltip';
import {
  AR_ADVANCED_INSTRUCTIONAL_TOOLTIP_KEY_STORAGE,
  AR_ADVANCED_INSTRUCTIONAL_TOOLTIP_END_DATE,
} from 'qonto/react/receivable-invoices/constants';
import styles from './header.strict-module.css';

interface HeaderProps extends FlowHeaderProps {
  onInvoiceSettingsPress: () => void;
}

interface InvoiceSettingsButtonProps {
  onPress: () => void;
  shouldDisplayTooltip: boolean;
}

function InvoiceSettingsButton({
  onPress,
  shouldDisplayTooltip,
}: InvoiceSettingsButtonProps): React.ReactNode {
  const [isTooltipDismissed, setIsTooltipDismissed] = useState(!shouldDisplayTooltip);

  const { formatMessage } = useIntl();

  const handleDismiss = (): void => {
    setIsTooltipDismissed(true);
  };

  useEffect(() => {
    if (safeLocalStorage.getItem(AR_ADVANCED_INSTRUCTIONAL_TOOLTIP_KEY_STORAGE) === 'true') {
      return;
    }

    safeLocalStorage.setItem(AR_ADVANCED_INSTRUCTIONAL_TOOLTIP_KEY_STORAGE, 'true');
  }, []);

  return (
    <div className={styles.buttonContainer} data-test-flow-header-invoicing-settings-button="">
      <div>
        <Tooltip
          buttonProps={{
            'aria-label': formatMessage({
              id: 'receivable-invoices.invoice-settings.cta.a11y.aria-label',
            }),
            iconOnly: true,
            onPress,
            variant: 'tertiary',
            size: 'large',
          }}
          label={formatMessage({ id: 'receivable-invoices.invoice-settings.cta.tooltip' })}
          needsButton
        >
          <IconSettingsOutlined />
        </Tooltip>
      </div>

      <InstructionalTooltip
        crossOffset={-2}
        description={formatMessage({
          id: 'receivable-invoices.invoice-creation.promotions.instructional-tooltip.ar-advanced.body',
        })}
        isDismissed={isTooltipDismissed}
        isNew
        onClose={handleDismiss}
        placement="top-corner"
        title={formatMessage({
          id: 'receivable-invoices.invoice-creation.promotions.instructional-tooltip.ar-advanced.title',
        })}
      />
    </div>
  );
}

export function Header({ onInvoiceSettingsPress, ...rest }: HeaderProps): React.ReactNode {
  const hasTooltipEnded = dayjs(AR_ADVANCED_INSTRUCTIONAL_TOOLTIP_END_DATE).isBefore(dayjs());
  const shouldDisplayTooltip =
    safeLocalStorage.getItem(AR_ADVANCED_INSTRUCTIONAL_TOOLTIP_KEY_STORAGE) !== 'true' &&
    !hasTooltipEnded;

  return (
    <FlowHeader
      actions={
        <InvoiceSettingsButton
          onPress={onInvoiceSettingsPress}
          shouldDisplayTooltip={shouldDisplayTooltip}
        />
      }
      className={styles.header}
      {...rest}
    />
  );
}
