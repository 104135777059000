/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import type { ReactElement } from 'react';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import { Badge } from '@repo/design-system-kit';
import { useEmberService, useFlags } from '@qonto/react-migration-toolkit/react/hooks';
import { useSegment } from '@repo/poly-hooks/segment';
import styles from './styles.strict-module.css';

interface Client {
  kind: 'freelancer' | 'company';
  firstName?: string;
  lastName?: string;
  name?: string;
  billingAddress: {
    streetAddress: string;
    city: string;
    zipCode: string;
    countryCode: string;
  };
  email?: string | null;
  einvoicing?: boolean;
  eInvoicingAddress?: string;
}

interface CardProps {
  client: Client;
}

export function Card({ client }: CardProps): ReactElement {
  const { formatDisplayName, formatMessage } = useIntl();
  const zendeskLocalization = useEmberService('zendesk-localization');
  const segment = useSegment();
  const { featureBooleanArClientEinvoicingAddress } = useFlags();

  const clientCountryName = formatDisplayName(client.billingAddress.countryCode, {
    type: 'region',
  });

  const displayName =
    client.kind === 'company' ? client.name : `${client.firstName} ${client.lastName}`;

  const showEInvoicingBadge =
    featureBooleanArClientEinvoicingAddress &&
    (Boolean(client.eInvoicingAddress) || client.einvoicing);

  const eInvoiceFaqLink = (): string => {
    return zendeskLocalization.getLocalizedArticle('fr-einvoice');
  };

  const handleFaqClick = (): void => {
    segment.track('Einvoicing-FAQ_link_clicked', {
      origin: 'client-invoice',
    });
  };

  return (
    <div className={styles.card} data-test-clients-sidebar-card>
      <h2
        className={cx('title-3 mb-8', styles.name, styles.overflow)}
        data-test-clients-sidebar-card-name
      >
        {displayName}
      </h2>

      <p
        className={cx('body-2', styles.street, styles.overflow)}
        data-test-clients-sidebar-card-street
      >
        {client.billingAddress.streetAddress}
      </p>

      <p
        className={cx('body-2', styles['zipcode-city-country'], styles.overflow)}
        data-test-clients-sidebar-card-zipcode-city-country
      >
        {client.billingAddress.zipCode} {client.billingAddress.city}, {clientCountryName}
      </p>

      {client.email ? (
        <p
          className={cx('body-2', styles.email, styles.overflow)}
          data-test-clients-sidebar-card-email
        >
          {client.email}
        </p>
      ) : null}
      {showEInvoicingBadge ? (
        <div className={styles.eInvoicingBadge} data-test-customer-card-einvoicing="">
          <Badge
            data-test-customer-card-invoice-einvoicing-badge=""
            text={formatMessage({ id: 'receivable-invoices.customer-card.einvoicing-disclaimer' })}
            type="union"
          />
          <a
            className="body-link"
            data-test-customer-card-einvoicing-faq=""
            href={eInvoiceFaqLink()}
            onClick={handleFaqClick}
            rel="noopener noreferrer"
            target="_blank"
          >
            {formatMessage({
              id: 'receivable-invoices.customer-card.einvoicing-disclaimer-faq.link',
            })}
          </a>
        </div>
      ) : null}
    </div>
  );
}
