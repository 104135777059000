import React, { type ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { Disclaimer } from '@repo/design-system-kit';
import { useFlags } from '@qonto/react-migration-toolkit/react/hooks';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import type { SubscriptionChangeDataContext } from 'qonto/react/components/flows/subscription-change/data-context.type';
import styles from './confirm-disclaimers.strict-module.css';

interface ConfirmDisclaimersProps {
  context: SubscriptionChangeDataContext;
  hasProductChangeNotAvailableError: boolean;
}

export function ConfirmDisclaimers({
  context,
  hasProductChangeNotAvailableError,
}: ConfirmDisclaimersProps): ReactElement {
  const { formatMessage } = useIntl();
  const { featureBooleanPricingItalianDisclaimers, featureBooleanMpItalyCheckout } = useFlags();
  const { organization } = useOrganizationManager();
  const showDisclaimerForItalianOrganizations =
    featureBooleanPricingItalianDisclaimers && organization.legalCountry === 'IT';

  if (context.hasInsufficientFunds) {
    return (
      <Disclaimer.Block
        className={styles.disclaimer}
        data-test-trial-confirm-insufficient-fund-disclaimer-revamp=""
        level="error"
      >
        {formatMessage({ id: 'subscription.change.confirmation.low-balance' })}
      </Disclaimer.Block>
    );
  }

  return (
    <>
      {hasProductChangeNotAvailableError && !featureBooleanMpItalyCheckout ? (
        <Disclaimer.Block className={styles.disclaimer} data-test-boi-error="" level="error">
          {formatMessage({ id: 'toasts.errors.try-later' })}
        </Disclaimer.Block>
      ) : null}

      {showDisclaimerForItalianOrganizations ? (
        <Disclaimer.Block
          className={styles.disclaimer}
          data-test-confirm-trial-italian-disclaimer=""
          level="info"
        >
          {formatMessage(
            { id: 'subscription.change.bank-of-italy-disclaimer.body' },
            {
              faqUrl: (
                <a
                  data-test-confirm-trial-italian-disclaimer-link
                  href="https://support-it.qonto.com/hc/it/articles/26999640842513-Restrizioni-in-Italia-e-miglioramento-delle-misure-di-antiriciclaggio"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {formatMessage({ id: 'subscription.change.bank-of-italy-disclaimer.link' })}
                </a>
              ),
            }
          )}
        </Disclaimer.Block>
      ) : null}
    </>
  );
}
