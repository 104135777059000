import type { ReactNode } from 'react';
import { Toggle, Tooltip } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import { useEmberService, useFlags } from '@qonto/react-migration-toolkit/react/hooks';
import cx from 'clsx';
import styles from './projected-forecast-toggle.strict-module.css';

interface ProjectedForecastToggleProps extends React.HTMLAttributes<HTMLDivElement> {
  showProjectedForecast: boolean;
  onShowProjectedForecastChange: (checked: boolean) => void;
}

export function ProjectedForecastToggle({
  showProjectedForecast,
  onShowProjectedForecastChange,
  ...props
}: ProjectedForecastToggleProps): ReactNode {
  const abilities = useEmberService('abilities');
  const { featureBooleanCashFlowFirstTimeExperience } = useFlags();

  const { formatMessage } = useIntl();
  const isFirstTimeExperience = Boolean(
    featureBooleanCashFlowFirstTimeExperience &&
      abilities.cannot('fully interact with forecast cash-flow')
  );

  return (
    <section className={styles['projected-forecast-toggle']} {...props}>
      <span className="title-4" data-testid="projected-forecast-toggle-title">
        {formatMessage({ id: 'cash-flow.header.projected.toggle' })}
      </span>
      <Tooltip
        isDisabled={!isFirstTimeExperience}
        label={formatMessage({ id: 'cash-flow.first-time-experience.tooltip.free-trial' })}
        needsButton
        tooltipTestId="projected-forecast-toggle-tooltip"
      >
        <Toggle
          checked={showProjectedForecast}
          className={cx(isFirstTimeExperience && styles['disabled-toggle'])}
          data-test-projected-forecast-toggle=""
          isDisabled={isFirstTimeExperience}
          onChange={onShowProjectedForecastChange}
        />
      </Tooltip>
    </section>
  );
}
