import { type ReactElement } from 'react';
import { Button, Spinner, Tooltip } from '@repo/design-system-kit';
import { Button as AriaButton } from 'react-aria-components';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'clsx';
import { IconDownloadOutlined, IconAttachmentOutlined } from '@repo/monochrome-icons';
import { TrashOutlined } from 'qonto/react/assets/icons/trash-outlined';
import { AttachmentItemBase } from '../attachment-item-base';
import styles from './styles.strict-module.css';

interface AttachmentItemProps {
  fileName: string;
  fileStatus?: string;
  onFilePreview: () => void;
  onDownload: () => void;
  onDelete: () => void;
  canProbate?: boolean;
}

export function AttachmentItem({
  fileName,
  fileStatus,
  onFilePreview,
  onDownload,
  onDelete,
  canProbate,
}: AttachmentItemProps): ReactElement {
  const intl = useIntl();

  const isNotCertified = fileStatus === 'corrupted';
  const isScanning = canProbate && fileStatus === 'pending';
  const subtitle =
    isNotCertified || isScanning ? (
      <span className={cx('caption', styles.uploading)} data-testid="subtitle">
        {isNotCertified ? (
          <FormattedMessage id="transactions.table.attachments.status.title.not-certified" />
        ) : null}
        {isScanning ? (
          <FormattedMessage id="transactions.table.attachments.status.title.scanning-single-file" />
        ) : null}
      </span>
    ) : null;

  return (
    <AttachmentItemBase
      actions={
        <>
          <Tooltip
            closeDelay={0}
            delay={400}
            label={intl.formatMessage({
              id: 'transactions.table.attachments.popover.tooltip.download',
            })}
            tooltipTestId="tooltip-download"
          >
            <Button
              className={styles.button}
              data-testid="action-download"
              iconOnly
              onPress={onDownload}
              type="button"
              variant="tertiary"
            >
              <IconDownloadOutlined />
            </Button>
          </Tooltip>
          <Tooltip
            closeDelay={0}
            delay={400}
            label={intl.formatMessage({
              id: 'transactions.table.attachments.popover.tooltip.delete',
            })}
            tooltipTestId="tooltip-delete"
          >
            <Button
              className={styles.button}
              data-testid="action-delete"
              iconOnly
              onPress={onDelete}
              type="button"
              variant="tertiary"
            >
              <TrashOutlined />
            </Button>
          </Tooltip>
        </>
      }
      data-testid="attachment-item"
      fileName={
        <AriaButton
          aria-label={intl.formatMessage(
            {
              id: 'transactions.table.attachments.popover.action.open.aria',
            },
            {
              filename: fileName,
            }
          )}
          className={cx('body-2', styles.file)}
          data-testid="file-preview"
          onPress={onFilePreview}
        >
          {fileName}
        </AriaButton>
      }
      icon={isScanning ? <Spinner color="primary-a" /> : <IconAttachmentOutlined />}
      subtitle={subtitle}
    />
  );
}
