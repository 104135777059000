import type { ComponentPropsWithRef, ReactNode } from 'react';

export function IconStatusApproved(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path fill="#45B184" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8Z" />
      <path
        fill="#fff"
        d="M6.5 11a.742.742 0 0 0 .53-.221l4.824-4.917a.51.51 0 0 0-.162-.823.496.496 0 0 0-.545.109L6.5 9.886 4.854 8.148a.497.497 0 0 0-.707 0 .508.508 0 0 0 0 .714L5.97 10.78c.142.142.33.22.53.22Z"
      />
    </svg>
  );
}
