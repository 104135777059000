import {
  Children,
  cloneElement,
  type PropsWithChildren,
  type ReactNode,
  isValidElement,
} from 'react';
import React from 'react';
import { useAlignedLayout } from './internal/use-aligned-layout';

export function ChooseCardLayout({ children }: PropsWithChildren): ReactNode {
  const header = useAlignedLayout(children);
  const features = useAlignedLayout(children);
  const subtitle = useAlignedLayout(children);

  return (
    <>
      {Children.map(children, (child, index) => {
        if (!isValidElement(child)) {
          return child;
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- Shape of children props is irrelevant
        return cloneElement(child, {
          ...child.props,
          layoutProps: {
            features: {
              ref: features.refs?.[index],
              minHeight: features.minHeight,
            },
            header: {
              ref: header.refs?.[index],
              minHeight: header.minHeight,
            },
            subtitle: {
              ref: subtitle.refs?.[index],
              minHeight: subtitle.minHeight,
            },
          },
        });
      })}
    </>
  );
}
