export enum InstallmentStatus {
  Paid = 'paid',
  Late = 'late',
  AtRisk = 'at_risk',
  Scheduled = 'scheduled',
  Processing = 'processing',
  Frozen = 'frozen',
}

export interface InstallmentModel {
  status: InstallmentStatus;
  totalAmount: {
    value: string;
    currency: string;
  };
  date: string;
}
