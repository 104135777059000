import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { IconPlusOutlined } from '@repo/monochrome-icons';
import styles from './styles.strict-module.css';

interface ItemSearchBeforeOptionsProps {
  onClick: () => void;
  title: string;
}

export function ItemSearchBeforeOptions({
  onClick,
  title,
}: ItemSearchBeforeOptionsProps): ReactNode {
  const { formatMessage } = useIntl();

  const buttonText: string = title
    ? formatMessage(
        { id: 'receivable-invoices.invoice-creation.items.cta.save-item' },
        { item: title }
      )
    : formatMessage({ id: 'receivable-invoices.invoice-creation.items.cta.save' });

  return (
    <>
      <button
        aria-label={buttonText}
        className={`btn btn--tertiary body-2 ${styles.button}`}
        data-test-item-search-before-options-button
        onClick={onClick}
        type="button"
      >
        <IconPlusOutlined className="mr-8" height={16} width={16} />
        {buttonText}
      </button>
      <div className={styles.divider} data-test-item-search-before-options-divider />
    </>
  );
}
