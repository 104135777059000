import { type ReactNode, useState } from 'react';
import { Button, Label, NumberField, Select, SelectOption, Tooltip } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import { IconTrashOutlined } from '@repo/monochrome-icons';
import { isNil } from 'es-toolkit';
import type {
  ReceivableInvoiceDiscount,
  ReceivableInvoiceItemModel,
} from 'qonto/react/receivable-invoices/api/models/receivable-invoices-item.ts';
import { multiplyAndRound } from 'qonto/utils/receivable-invoicing';
import styles from './styles.strict-module.css';

const PERCENTAGE_SYMBOL = '%';
const DISCOUNT_PERCENTAGE = 'percentage';
const DISCOUNT_ABSOLUTE = 'absolute';

function asAbsoluteValue(value: string | null, maxValue?: number): string | null {
  if (value) {
    if (maxValue) {
      const cappedValue = Math.min(parseFloat(value), maxValue);
      return String(cappedValue).replace(/[,.]/g, '.');
    }
    return value;
  }
  return null;
}

function asPercentageValue(value: string | null): string | null {
  if (value) {
    const cappedValue = Math.min(parseFloat(value), 100);
    // the percentage will be sent in decimals, so 11.11% = 0.1111
    const preciseValue = Math.round(cappedValue * 100) / 100;
    const rate = preciseValue / 100;
    const preciseRate = Math.round(rate * 10000) / 10000;

    return String(preciseRate).replace(/[,.]/g, '.');
  }
  return null;
}

export interface DiscountFieldProps {
  item: ReceivableInvoiceItemModel;
  isDisabled?: boolean;
  currency: string;
  onDeleteDiscount: () => void;
  amountErrorMessage?: string;
  percentageErrorMessage?: string;
  priceLimit?: number;
  className?: string;
}

export function DiscountField({
  item,
  currency,
  amountErrorMessage,
  percentageErrorMessage,
  isDisabled,
  priceLimit,
  onDeleteDiscount,
  ...props
}: DiscountFieldProps): ReactNode {
  const { formatMessage } = useIntl();
  const [discount, setDiscount] = useState(item.discount);

  const selectedDiscountType: string = discount?.type ?? 'percentage';
  // eslint-disable-next-line no-nested-ternary -- ember types
  const discountValue: number | null = discount?.value
    ? discount.type === 'percentage'
      ? parseFloat(discount.value) * 100
      : parseFloat(discount.value)
    : null;

  const errorMessage =
    selectedDiscountType === DISCOUNT_ABSOLUTE ? amountErrorMessage : percentageErrorMessage;

  function updateDiscount(updatedDiscount: ReceivableInvoiceDiscount | null): void {
    setDiscount(updatedDiscount);
    item.discount = updatedDiscount;
  }

  function setDiscountObj(type: string, value: string | null): void {
    const absoluteValue = asAbsoluteValue(value, priceLimit);
    const percentageValue = asPercentageValue(value);
    updateDiscount({
      type,
      value: type === 'absolute' ? absoluteValue : percentageValue,
      amount:
        type === 'absolute'
          ? value
          : String(multiplyAndRound(percentageValue, item.totalExcludingVat, 100)),
    });
  }

  function handleSelectionChange(key: string | number): void {
    if (typeof key === 'string') {
      if (item.errors?.has('discountAmount')) {
        item.errors.remove('discountAmount');
      }

      setDiscountObj(key, isNil(discountValue) ? null : String(discountValue));
    }
  }

  function handleDiscountChange(value: string | null): void {
    if (item.errors?.has('discountAmount')) {
      item.errors.remove('discountAmount');
    }

    if (!value) {
      updateDiscount(null);
    } else {
      const valueNumber = parseFloat(value);
      // negative number are not allowed
      const positiveValue = valueNumber < 0 ? Math.abs(valueNumber) : valueNumber;
      const newValue =
        !priceLimit || positiveValue <= priceLimit ? String(positiveValue) : String(priceLimit);

      setDiscountObj(selectedDiscountType, newValue);
    }
  }

  return (
    <div data-test-discount-field="" {...props}>
      <Label data-test-discount-field-label="">
        {formatMessage({ id: 'receivable-invoices.invoice-creation.items.discount.label' })}
      </Label>
      <div className={styles['discount-field']}>
        <NumberField
          className={styles['amount-field']}
          data-test-item-discount-input=""
          errorMessage={errorMessage}
          isDisabled={isDisabled}
          label=""
          maxValue={selectedDiscountType === 'percentage' ? 100 : priceLimit}
          maximumFractionDigits={2}
          minimumFractionDigits={selectedDiscountType === 'absolute' ? 2 : undefined}
          onChange={handleDiscountChange}
          placeholder={selectedDiscountType === 'absolute' ? '0.00' : '0'}
          value={discountValue}
        />
        <Select
          className={styles.select}
          data-test-item-discount-dropdown=""
          isDisabled={isDisabled}
          label=""
          onSelectionChange={handleSelectionChange}
          placeholder=""
          selectedKey={selectedDiscountType}
        >
          <SelectOption id={DISCOUNT_PERCENTAGE}>{PERCENTAGE_SYMBOL}</SelectOption>
          <SelectOption id={DISCOUNT_ABSOLUTE}>{currency}</SelectOption>
        </Select>
        {!isDisabled && (
          <Tooltip
            label={formatMessage({
              id: 'receivable-invoices.invoice-creation.items.discount.delete-tooltip',
            })}
            placement="top right"
          >
            <Button
              aria-label={formatMessage({
                id: 'receivable-invoices.invoice-creation.items.discount.delete-aria-label',
              })}
              className={styles['trash-btn']}
              data-test-item-discount-delete=""
              iconOnly
              onPress={onDeleteDiscount}
              size="small"
              variant="tertiary"
            >
              <IconTrashOutlined />
            </Button>
          </Tooltip>
        )}
      </div>
    </div>
  );
}
