import React, { type ReactNode } from 'react';
import cx from 'clsx';
import { Button } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import { importAsset } from 'qonto/helpers/import-asset';
import styles from './intro.strict-module.css';

const VIDEO_PATHS = {
  de: '/videos/budgets/value-prop-de.webm',
  en: '/videos/budgets/value-prop-en.webm',
  es: '/videos/budgets/value-prop-es.webm',
  fr: '/videos/budgets/value-prop-fr.webm',
  it: '/videos/budgets/value-prop-it.webm',
  pt: '/videos/budgets/value-prop-en.webm',
} as const;

const IMAGE_PATHS = {
  de: '/illustrations/budgets/value-prop-de.webp',
  en: '/illustrations/budgets/value-prop-en.webp',
  es: '/illustrations/budgets/value-prop-es.webp',
  fr: '/illustrations/budgets/value-prop-fr.webp',
  it: '/illustrations/budgets/value-prop-it.webp',
  pt: '/illustrations/budgets/value-prop-en.webp',
} as const;

type SourceTypes = typeof VIDEO_PATHS | typeof IMAGE_PATHS;

type LanguageCode = keyof SourceTypes;
interface IntroProps {
  onClickCreate: () => void;
}

export function Intro(props: IntroProps): ReactNode {
  const { formatMessage, locale } = useIntl();

  const getAssetPath = (pathLocale: string, sourceObject: SourceTypes): string => {
    const typedLocale = pathLocale as LanguageCode;
    return importAsset([sourceObject[typedLocale]]);
  };
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title} data-test-value-prop-title>
        {formatMessage({ id: 'team-budgets.intro.title' })}
      </h1>

      <div className={styles.container}>
        <div className={styles['video-wrapper']}>
          <video
            aria-hidden="true"
            autoPlay
            className="video"
            data-test-value-prop-video
            height="450"
            muted
            playsInline
            poster={getAssetPath(locale, IMAGE_PATHS)}
            preload="auto"
            width="500"
          >
            <source
              data-test-value-prop-video-source
              src={getAssetPath(locale, VIDEO_PATHS)}
              type="video/webm;codecs=vp8, vorbis"
            />
          </video>
        </div>

        <div>
          <div className={styles['content-block']}>
            <h3 className={cx('title-3 mb-8', styles.stroke)} data-test-value-prop-a-title>
              {formatMessage({ id: 'team-budgets.intro.benefit1.title' })}
            </h3>

            <p className="body-2" data-test-value-prop-a-description>
              {formatMessage({ id: 'team-budgets.intro.benefit1.subtitle' })}
            </p>
          </div>

          <div className={styles['content-block']}>
            <h3 className="title-3 mb-8" data-test-value-prop-b-title>
              {formatMessage({ id: 'team-budgets.intro.benefit2.title' })}
            </h3>

            <p className="body-2" data-test-value-prop-b-description>
              {formatMessage({ id: 'team-budgets.intro.benefit2.subtitle' })}
            </p>
          </div>

          <div className={styles['content-block']}>
            <h3 className="title-3 mb-8" data-test-value-prop-c-title>
              {formatMessage({ id: 'team-budgets.intro.benefit3.title' })}
            </h3>

            <p className="body-2" data-test-value-prop-c-description>
              {formatMessage({ id: 'team-budgets.intro.benefit3.subtitle' })}
            </p>
          </div>
        </div>
      </div>
      <Button
        className={styles.closeButton}
        data-test-value-prop-cta
        onPress={props.onClickCreate}
        variant="primary"
      >
        {formatMessage({ id: 'team-budgets.intro.cta' })}
      </Button>
    </div>
  );
}
