import type { ReactNode } from 'react';
import { Counterparty } from '@repo/domain-kit/shared';
import { Button } from 'react-aria-components';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks/use-ember-service';
import { BankAccountAvatar } from 'qonto/react/components/bank-account-avatar';
import { useOrganizationNavigation } from 'qonto/react/shared/hooks/use-organization-navigation';
import type { TransactionDetails } from '../transactions-table';
import styles from './counterparty.strict-module.css';

export function CashflowTransactionCounterparty({
  transaction,
}: {
  transaction: TransactionDetails;
}): ReactNode {
  const organizationNavigation = useOrganizationNavigation();
  const segment = useEmberService('segment');

  const handleRedirectToIndividualTransaction = (): void => {
    segment.track('cash-flow_sidepanel_view-individual-transaction');
    void organizationNavigation(`/transactions?highlight=${transaction.id}`);
  };

  const subtitle = (
    <div className={styles['counterparty-subtitle']}>
      {transaction.account ? (
        <BankAccountAvatar
          bankAccount={transaction.account}
          className={styles['counterparty-avatar']}
          size="small"
        />
      ) : null}
      <p>{transaction.displayType}</p>
    </div>
  );

  return (
    <div data-test-counterparty={transaction.id}>
      <Button
        className={styles['counterparty-button']}
        data-testid="counterparty-button"
        onPress={handleRedirectToIndividualTransaction}
      >
        <Counterparty showAvatar={false} subtitle={subtitle} title={transaction.counterpartyName} />
      </Button>
    </div>
  );
}
