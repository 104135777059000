import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { TopBanner } from '@repo/design-system-kit';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import styles from './styles.strict-module.css';

interface DefenseModeCardsBannerProps {
  onClose: () => void;
}

export function CardsBannerComponent({ onClose }: DefenseModeCardsBannerProps): ReactNode {
  const zendeskLocalization = useEmberService('zendesk-localization');
  return (
    <TopBanner onClose={onClose} type="warning">
      <div data-test-topbanner-cards-banner>
        <a
          className={`${styles.bodyLink} ${styles.spacing}`}
          href={zendeskLocalization.getLocalizedArticle(9023814)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FormattedMessage id="topbar_msg.warning-fraud.value" />
        </a>
        <FormattedMessage id="topbar_msg.warning-fraud.card-transaction" />
      </div>
    </TopBanner>
  );
}
