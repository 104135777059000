/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import cx from 'clsx';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import { BadgeStatus } from '@repo/design-system-kit';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import { StatusAvatar } from 'qonto/react/components/avatar/status-avatar';
import titleize from 'qonto/utils/titleize';
import { formatMoney } from 'qonto/react/shared/utils/format-money';
import type TransferModel from 'qonto/models/transfer';
import type BeneficiaryModel from 'qonto/models/beneficiary';
import {
  getEndDateText,
  getNextDate,
  getStatusIcon,
  getStatusLevel,
  getStatusText,
  getTransferFrequencyType,
  type StatusIcon,
} from './util';
import styles from './styles.strict-module.css';

type ExtendedTransferModel = Omit<TransferModel, 'enrichmentData'> & {
  enrichmentData?: {
    logo?: string;
  };
};

interface TableItemProps extends React.HTMLAttributes<HTMLTableRowElement> {
  item: TransferModel;
  beneficiaryName: BeneficiaryModel['name'];
  isHighlighted: boolean;
  isSidepanelOpen: boolean;
}

export function TableItem({
  item,
  beneficiaryName,
  isHighlighted,
  isSidepanelOpen,
  ...props
}: TableItemProps): React.ReactNode {
  const { formatMessage, locale } = useIntl();

  const isDisabled = item.isDeclined || item.isCanceled;
  const nextDate = getNextDate(item);
  const hasBorder = Boolean((item as ExtendedTransferModel).enrichmentData?.logo);

  return (
    <tr
      className={cx(styles.row, isDisabled && styles.disabled, isHighlighted && styles.active)}
      data-test-transfer-table-item
      {...props}
    >
      <td aria-hidden="true" className={styles.empty} />

      <td className={styles.cell}>
        <div className={cx('body-1', styles['cell-content'], styles.counterparty)}>
          <StatusAvatar
            className={styles['status-avatar']}
            data-test-transfers-item-avatar
            dataTestId="transfers-item-avatar"
            hasBorder={hasBorder}
            icon={getStatusIcon(item.avatarInfo.icon as StatusIcon)}
            iconSize="16"
            isDisabled={isDisabled}
            isLazyLoaded
            src={item.avatarInfo.smallLogo as string}
          />
          <div className={cx('body-1', styles['counterparty-name'])}>
            <span className={styles['beneficiary-name']} data-test-transfer-counterparty-name>
              {titleize(beneficiaryName)}
            </span>
          </div>
        </div>
      </td>

      <td className={styles.cell}>
        <div className={cx(styles['cell-content'], styles.frequency)} data-test-transfer-frequency>
          <span>{getTransferFrequencyType(item, formatMessage)}</span>
        </div>
      </td>

      <td className={cx(styles.cell, styles['no-padding'])}>
        <div className={styles['cell-content']} data-test-transfer-next-date>
          {nextDate
            ? dateToken({
                date: dayjs(nextDate).toISOString(),
                locale,
                token: 'date-year-s',
              })
            : '-'}
        </div>
      </td>

      <td
        className={cx(styles.cell, styles['no-padding'], isSidepanelOpen && 'hide')}
        data-test-transfer-end-date-col
      >
        <div className={styles['cell-content']} data-test-transfer-end-date>
          {getEndDateText(item, formatMessage, locale)}
        </div>
      </td>

      <td
        className={cx(styles.cell, styles['no-padding'], isSidepanelOpen && 'hide')}
        data-test-transfer-status-tag
      >
        <div className={styles['cell-content']}>
          <BadgeStatus level={getStatusLevel(item)} text={getStatusText(item, formatMessage)} />
        </div>
      </td>

      <td className={cx(styles.cell, styles['no-padding'])}>
        <div
          className={cx('body-1', styles['cell-content'], styles.amount)}
          data-test-transfer-in-list-amount={item.localAmount}
        >
          {formatMoney(item.localAmount, {
            currency: item.localAmountCurrency,
            locale,
            signus: '-',
          })}
        </div>
      </td>

      <td aria-hidden="true" className="empty" />
    </tr>
  );
}
