import React, { type ReactElement } from 'react';
import { Disclaimer, ExternalLink } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';

export function FinancingDeclinedDisclaimer(): ReactElement {
  const { formatMessage } = useIntl();
  const faqLink = formatMessage({
    id: 'financing.pay-later.cockpit.details.financing-not-compliant.url',
  });
  const faqLinkText = formatMessage({
    id: 'financing.pay-later.cockpit.details.financing-not-compliant.link-text',
  });

  return (
    <Disclaimer.Block data-test-financing-declined-disclaimer="" level="error">
      {formatMessage(
        { id: 'financing.pay-later.cockpit.details.financing-not-compliant.text' },
        {
          faqUrl: (
            <ExternalLink
              className="body-link"
              href={faqLink}
              key="faq-link"
              rel="noopener noreferrer"
              target="_blank"
            >
              {faqLinkText}
            </ExternalLink>
          ),
        }
      )}
    </Disclaimer.Block>
  );
}
