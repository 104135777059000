import { type ReactNode } from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import cx from 'clsx';
import styles from './styles.strict-module.css';

export function Placeholder(props: object): ReactNode {
  return (
    <tr aria-hidden="true" className={styles.row} data-test-card-placeholder-row {...props}>
      <td />

      <td className={styles['underlined-cell']}>
        <div className={styles['cell-content']} data-test-card-placeholder>
          <SkeletonLoader.Avatar data-test-card-placeholder="avatar" height="32px" width="32px" />
          <SkeletonLoader.Line className={styles.line} height="8px" standalone width="176px" />
        </div>
      </td>

      <td className={styles['underlined-cell']} data-test-date-placeholder>
        <SkeletonLoader.Line className={styles.line} height="8px" standalone width="90px" />
      </td>

      <td className={styles['underlined-cell']} data-test-status-placeholder>
        <SkeletonLoader.Block height="24px" width="77px" />
      </td>

      <td className={styles['underlined-cell']} data-test-amount-placeholder>
        <SkeletonLoader.Line
          className={cx(styles.line, styles['amount-placeholder'])}
          height="8px"
          standalone
          width="85px"
        />
      </td>

      <td />
    </tr>
  );
}
