import { useMemo, type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { EmptyState } from '@repo/design-system-kit';
import type SupplierInvoiceModel from 'qonto/models/supplier-invoice';
import { useFetchTransactions } from 'qonto/react/hooks/use-fetch-transactions';
import type { TransactionsQuery } from 'qonto/react/hooks/use-fetch-transactions';
import { Transaction } from 'qonto/react/components/supplier-invoices/transactions-modal/transaction/transaction';
import { useDisplayCounterparty } from 'qonto/react/hooks/use-display-counterparty';
import { formatMoney } from 'qonto/react/shared/utils/format-money';
import ENV from 'qonto/config/environment';
import type { Period } from '../period-selector/period-selector';

const isTesting = (ENV as { environment: string }).environment === 'test';

interface TransactionsProps {
  invoice?: SupplierInvoiceModel;
  query: TransactionsQuery;
  period: Period | null;
  shouldFilterOutSuggestedTransactions: boolean;
  handleClick: (transaction: { id: string; side: 'debit' | 'credit'; settledAt?: string }) => void;
}

export function Transactions({
  invoice,
  query,
  handleClick,
  period,
  shouldFilterOutSuggestedTransactions,
}: TransactionsProps): ReactNode {
  const { formatMessage, formatDate, locale } = useIntl();
  const displayCounterparty = useDisplayCounterparty();

  const { data } = useFetchTransactions(query, {
    retry: isTesting ? false : 3,
    throwOnError: true,
    suspense: true,
  });

  const formattedAmount = (amount: number, currency: string, side: 'credit' | 'debit'): string => {
    return formatMoney(amount, {
      locale,
      currency,
      signus: side === 'credit' ? '+' : '-',
    });
  };

  const formattedDate = (emittedAt: string): string =>
    formatDate(emittedAt, {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });

  const shouldDisplayTransactionsTitle = !query.search && !period && data?.transactions.length;

  const transactions = useMemo(() => {
    if (!data?.transactions) {
      return [];
    }
    if (!shouldFilterOutSuggestedTransactions) {
      return data.transactions;
    }
    const suggestedTransactionIds = invoice?.suggestedTransactions.map(({ id }) => id) || [];
    return data.transactions.filter(
      transaction => !suggestedTransactionIds.includes(transaction.id)
    );
  }, [data, invoice, shouldFilterOutSuggestedTransactions]);

  if (transactions.length === 0) {
    const title =
      query.search.length > 0
        ? formatMessage({ id: 'supplier-invoices.match-transaction-modal.no-results.title' })
        : formatMessage({ id: 'supplier-invoices.match-transaction-modal.empty-state' });

    return (
      <EmptyState
        subtitle={formatMessage({
          id: 'supplier-invoices.match-transaction-modal.no-results.subtitle',
        })}
        title={title}
      />
    );
  }

  return (
    <div data-test-transactions>
      {shouldDisplayTransactionsTitle ? (
        <h3 className="caption mb-16" data-test-transactions-header>
          {formatMessage({
            id: 'supplier-invoices.match-transaction-modal.all-transactions.title',
          })}
        </h3>
      ) : null}
      {transactions.map(transaction => (
        <Transaction
          data-test-modal-transaction={transaction.id}
          formattedAmount={formattedAmount(
            transaction.amount,
            transaction.amountCurrency,
            transaction.side
          )}
          handleClick={() => {
            handleClick(transaction);
          }}
          key={transaction.id}
          methodLabel={transaction.subjectType}
          operationMethod={transaction.operationMethod}
          side={transaction.side}
          transactionDate={formattedDate(transaction.emittedAt)}
          transactionName={displayCounterparty(transaction.counterpartyName)}
        />
      ))}
    </div>
  );
}
