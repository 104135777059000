import type { ReactNode } from 'react';
import React from 'react';
import { FullscreenBase } from '@repo/design-system-kit';
import { Modal, ModalOverlay } from 'react-aria-components';
import { ManualEntryForm } from '@repo/domain-kit/cashflow';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useIntl } from 'react-intl';
import { useSegment } from '@repo/poly-hooks/segment';
import { PopupDestructive } from 'qonto/react/components/popups/destructive';
import type { UpcomingTransaction } from 'qonto/react/hooks/use-upcoming-transactions';
import { useDeleteUpcomingTransaction } from '../../../hooks/use-delete-upcoming-transaction';
import { getDestructiveModalProps } from '../table/utils/get-destructive-modal-props';
import styles from './styles.strict-module.css';

interface FormData {
  id: string;
  amount: string;
  expectedDate: string;
  name: string;
  side: 'debit' | 'credit';
  frequency: string;
}
interface FormModalProps {
  existingUpcomingTransactionData?: FormData;
  closeModal: () => void;
  onUpdate: (formData: FormData, cascade?: boolean) => void;
}
export function FormModal({
  closeModal,
  onUpdate,
  existingUpcomingTransactionData,
}: FormModalProps): ReactNode {
  const { mutate: deleteUpcomingTransaction } = useDeleteUpcomingTransaction();
  const reactPopup = useEmberService('reactPopup');
  const { formatMessage } = useIntl();
  const segment = useSegment();

  const handleDelete = async (): Promise<void> => {
    if (!existingUpcomingTransactionData) return;

    segment.track('compass_action_clicked', {
      action: 'remove',
      type: 'manual_entry',
    });

    // Create a mock upcoming transaction object with the necessary properties
    const upcomingTransaction = {
      id: existingUpcomingTransactionData.id,
      provider_object_type: 'manual_entry',
      metadata:
        existingUpcomingTransactionData.frequency !== 'none' &&
        existingUpcomingTransactionData.frequency !== ''
          ? { recurrence: { frequency: existingUpcomingTransactionData.frequency } }
          : undefined,
    };

    const { titleId, subtitleId, confirmTextId, cancelTextId } = getDestructiveModalProps(
      upcomingTransaction as UpcomingTransaction
    );

    const isRecurring = existingUpcomingTransactionData.frequency !== 'none';

    const result = await reactPopup.open(PopupDestructive, {
      title: formatMessage({ id: titleId }),
      subtitle: formatMessage({ id: subtitleId }),
      confirmText: formatMessage({ id: confirmTextId }),
      cancelText: formatMessage({ id: cancelTextId }),
    });

    if (result === 'cancel') {
      closeModal();
      return;
    }

    if (result === 'confirm') {
      deleteUpcomingTransaction({
        upcomingTransactionId: existingUpcomingTransactionData.id,
        cascade: isRecurring,
      });
      closeModal();
    }
  };
  const submit = (formData: FormData): void => {
    // Ensure the date is properly formatted before sending to backend
    const updatedFormData = {
      ...formData,
      expectedDate: formData.expectedDate,
    };
    onUpdate(updatedFormData);
  };

  return (
    <ModalOverlay className={styles.overlay} isOpen>
      <Modal className={styles.modal} isKeyboardDismissDisabled isOpen>
        <FullscreenBase
          onClose={() => {
            closeModal();
          }}
        >
          <section className={styles.main}>
            <ManualEntryForm
              existingUpcomingTransactionData={existingUpcomingTransactionData}
              onCancel={closeModal}
              onDelete={handleDelete}
              onUpdate={submit}
            />
          </section>
        </FullscreenBase>
      </Modal>
    </ModalOverlay>
  );
}
