import cx from 'clsx';
import { useIntl, type IntlShape } from 'react-intl';
import type { ReactElement } from 'react';
import type { FinancingModel } from 'qonto/react/financing/api';
import { Header as BaseHeader, type HeaderBadge } from 'qonto/react/components/sidebar/header';
import styles from './styles.strict-module.css';

interface HeaderProps {
  financing: FinancingModel;
}

function getBadge(financing: FinancingModel, intl: IntlShape): HeaderBadge | undefined {
  if (financing.isProcessing) {
    return {
      type: 'in-progress',
      text: intl.formatMessage({
        id: 'financing.pay-later.cockpit.details.header.badge.processing',
      }),
    };
  }
  if (financing.isDeclined) {
    return {
      type: 'error',
      text: intl.formatMessage({ id: 'financing.pay-later.cockpit.details.header.badge.declined' }),
    };
  }

  switch (financing.repaymentStatus) {
    case 'at_risk':
      return {
        type: 'warning',
        text: intl.formatMessage({
          id: 'financing.pay-later.cockpit.details.header.badge.at-risk',
        }),
      };
    case 'late':
      return {
        type: 'error',
        text: intl.formatMessage({
          id: 'financing.pay-later.cockpit.details.header.badge.overdue',
        }),
      };
    case null:
      return undefined;
  }

  return undefined;
}

export function Header({ financing }: HeaderProps): ReactElement {
  const intl = useIntl();

  const isRemainingAmountDisplayed = Boolean(financing.remainingAmount);

  const formattedAmount = intl.formatNumber(Number(financing.totalAmount.value), {
    currency: financing.totalAmount.currency,
    style: 'currency',
  });

  return (
    <BaseHeader
      amount={formattedAmount}
      amountIsDisabled={financing.isDeclined}
      avatarHasBorder={financing.avatarInfo.hasBorder}
      avatarIsDisabled={financing.isDeclined}
      avatarSrc={financing.avatarInfo.mediumLogo || financing.avatarInfo.smallLogo}
      badge={getBadge(financing, intl)}
      name={financing.beneficiaryName}
    >
      {isRemainingAmountDisplayed ? (
        <p className={cx(styles['left-to-pay'], 'body-2')} data-testid="left-to-pay">
          {intl.formatMessage(
            { id: 'financing.pay-later.cockpit.details.header.left-to-pay' },
            {
              left_amount: intl.formatNumber(Number(financing.remainingAmount?.value), {
                currency: financing.remainingAmount?.currency,
                style: 'currency',
              }),
            }
          )}
        </p>
      ) : undefined}
    </BaseHeader>
  );
}
