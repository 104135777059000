import { useMutation, useQueryClient, type UseMutationResult } from '@tanstack/react-query';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { upcomingTransactionsNamespace } from 'qonto/constants/hosts';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { useFetchApi } from '../../../hooks/use-fetch-api';

interface CreateUpcomingTransactionData {
  amount: { currency: string; value: string };
  expected_date: string;
  name: string;
  side: 'debit' | 'credit';
  recurrence: { frequency: string } | null;
}

interface CreateUpcomingTransactionResponse extends CreateUpcomingTransactionData {
  id: string;
}

interface OrganizationManager {
  organization: {
    mainAccount: { id: string };
  };
}

export const useCreateUpcomingTransaction = (): UseMutationResult<
  CreateUpcomingTransactionResponse,
  Error,
  CreateUpcomingTransactionData
> => {
  const fetchApi = useFetchApi();
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const toastFlashMessages = useEmberService('toast-flash-messages');

  const { organization }: OrganizationManager = useOrganizationManager();
  const mainBankAccountId: string = organization.mainAccount.id;

  const createUpcomingTransaction = async (
    payload: CreateUpcomingTransactionData
  ): Promise<CreateUpcomingTransactionResponse> => {
    const date = payload.expected_date
      ? dayjs(payload.expected_date).format('YYYY-MM-DD')
      : dayjs().format('YYYY-MM-DD');

    payload.expected_date = date;

    const response = await fetchApi(
      `${upcomingTransactionsNamespace}/cash-flow/upcoming-transactions`,
      {
        method: 'POST',
        body: JSON.stringify({ ...payload, bank_account_id: mainBankAccountId }),
      }
    );

    if (!response.ok) {
      throw new Error(`Failed to create upcoming transaction: [HTTP status: ${response.status}]`);
    }

    return (await response.json()) as CreateUpcomingTransactionResponse;
  };

  return useMutation({
    mutationFn: createUpcomingTransaction,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['upcoming-transactions'] });

      toastFlashMessages.toastSuccess(
        formatMessage({ id: 'cash-flow.upcoming-transactions.toast.create' })
      );
    },
    onError: () => {
      toastFlashMessages.toastError(formatMessage({ id: 'toasts.errors.server_error' }));
    },
  });
};
