/* eslint-disable @qonto/use-composable-popup -- Temporarily disabled the rule until migrating the popup */
import React, { useEffect, useState, type ReactNode } from 'react';
import { LottiePlayer, Popup } from '@repo/design-system-kit';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useIntl, FormattedMessage } from 'react-intl';
import { useThemedAssetPath } from 'qonto/react/hooks/use-themed-asset-path';
import ENV from 'qonto/config/environment';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import styles from './promotional-popup.strict-module.css';

export const MT_PROMO_POPUP_STORAGE_KEY = 'mt-promo-popup-dismissed';

const isTest = ENV.environment === 'test';

const popupDelay = isTest ? 0 : 2000;

export function MtPromotionalPopup(): ReactNode {
  const [isOpen, setIsOpen] = useState(false);
  const segment = useEmberService('segment');
  const { formatMessage } = useIntl();
  const lottieSrc = useThemedAssetPath(
    '/lotties/modular-tables/ill_prom_pop_modular_tables_mustard.json'
  );

  useEffect(() => {
    if (safeLocalStorage.getItem(MT_PROMO_POPUP_STORAGE_KEY) === 'true') {
      return;
    }

    const timeout = setTimeout(() => {
      setIsOpen(true);

      segment.track('discovery_promotional-pop-up_displayed', {
        feature: 'modular_tables_onboarding',
        source: 'transactions_screen',
      });
    }, popupDelay);

    return () => {
      clearTimeout(timeout);
    };
  }, [segment]);

  const handleCancel = (): void => {
    handleClose('discovery_promotional-pop-up_closed');
  };

  const handleConfirm = (): void => {
    handleClose('discovery_promotional-pop-up_clicked');
  };

  const handleClose = (eventName: string): void => {
    safeLocalStorage.setItem(MT_PROMO_POPUP_STORAGE_KEY, 'true');

    segment.track(eventName, {
      feature: 'modular_tables_onboarding',
      source: 'transactions_screen',
    });
    setIsOpen(false);
  };

  // react-aria modal escapes the test container, we render it where it can find the modal (i.e. <main> component)
  const TEST_PORTAL_CONTAINER =
    document.getElementById('ember-testing') ?? document.querySelector('main') ?? undefined;

  return (
    <Popup
      UNSTABLE_portalContainer={isTest ? TEST_PORTAL_CONTAINER : undefined}
      className={styles.popup}
      confirmText={formatMessage({
        id: 'transactions.modular_table.promotional_pop_up_modal.cta',
      })}
      data-test-id="mt-promotional-popup"
      illustration={
        <LottiePlayer
          className={styles.lottie}
          data-test-mt-promo-popup-illustration=""
          path={lottieSrc}
        />
      }
      isOpen={isOpen}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      title={formatMessage({
        id: 'transactions.modular_table.promotional_pop_up_modal.title',
      })}
      type="promotional"
    >
      <ul>
        <li data-test-id="mt-promotional-popup-bullet-actions">
          <FormattedMessage id="transactions.modular_table.promotional_pop_up_modal.bullet.actions" />
        </li>
        <li data-test-id="mt-promotional-popup-bullet-columns">
          <FormattedMessage id="transactions.modular_table.promotional_pop_up_modal.bullet.columns" />
        </li>
        <li data-test-id="mt-promotional-popup-bullet-details">
          <FormattedMessage id="transactions.modular_table.promotional_pop_up_modal.bullet.details" />
        </li>
      </ul>
    </Popup>
  );
}
