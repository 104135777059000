import { type ReactNode } from 'react';
import cx from 'clsx';
import { IconLightningOutlined } from '@repo/monochrome-icons';
import styles from './styles.strict-module.css';

interface LabelTileProps {
  title: string;
  body: string;
  ctaText: string;
  onClick: () => void;
}

export function LabelTile({ title, body, ctaText, onClick }: LabelTileProps): ReactNode {
  return (
    <article className={styles['label-tile']} data-testid="label-tile">
      <h4 className={cx(styles.title, 'body-1')} data-testid="title">
        <IconLightningOutlined className="mr-8" />
        {title}
      </h4>
      <p className="mb-16 body-2" data-testid="body">
        {body}
      </p>
      <footer className={styles.actions}>
        <button
          className="btn btn--primary btn--small body-1"
          data-testid="cta"
          onClick={onClick}
          type="button"
        >
          {ctaText}
        </button>
      </footer>
    </article>
  );
}
