import { type ReactNode } from 'react';
import type { CellContext } from '@tanstack/react-table';
import { type UpcomingTransaction } from 'qonto/react/hooks/use-upcoming-transactions';
import { ActionsComponent } from '../actions-component';

export function ActionsCell(
  info: CellContext<UpcomingTransaction, unknown>,
  handleEditUpcomingTransaction: (upcomingTransaction: UpcomingTransaction) => void,
  handleHover: (amount: string | null) => () => void,
  handleUpdatedDateForPreviewLine: (
    date: string | null,
    amount: string | null,
    originalDate: string | null,
    side: string | null
  ) => void
): ReactNode {
  return (
    <ActionsComponent
      handleEditUpcomingTransaction={handleEditUpcomingTransaction}
      handleHover={handleHover}
      upcomingTransaction={info.row.original}
      updatedDateForPreviewLine={handleUpdatedDateForPreviewLine}
    />
  );
}
