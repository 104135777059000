import { PromotionalCard } from '@repo/design-system-kit';
import { useSegment } from '@repo/poly-hooks/segment';
import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { STORAGE_KEYS } from 'qonto/constants/international-out/storage';
import { EVENTS } from 'qonto/constants/international-out/tracking';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { useOrganizationNavigation } from 'qonto/react/shared/hooks/use-organization-navigation';

export function SchedulingPromotionalCard(props: ComponentPropsWithoutRef<'button'>): ReactNode {
  const navigate = useOrganizationNavigation();
  const segment = useSegment();
  const { formatMessage } = useIntl();

  const handleClick = (): void => {
    if (safeLocalStorage.getItem(STORAGE_KEYS.PROMOTIONAL_CARD_SCHEDULE) === null) {
      safeLocalStorage.setItem(STORAGE_KEYS.PROMOTIONAL_CARD_SCHEDULE, Date.now().toString());
    }
    segment.track(EVENTS.PROMOTIONAL_CARD_CLICKED);
    void navigate('/flows/international-out/quote');
  };

  return (
    <PromotionalCard
      {...props}
      badgeText={formatMessage({ id: 'promotion-system.badge.new.label' })}
      badgeType="newFeature"
      ctaText={formatMessage({ id: 'international-out.promotional-card.schedule-transfers.cta' })}
      data-testid="scheduling-promotional-card"
      onCtaClick={handleClick}
      subtitle={formatMessage({
        id: 'international-out.promotional-card.schedule-transfers.subtitle',
      })}
    />
  );
}
