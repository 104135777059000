import * as React from 'react';
import { useIntl } from 'react-intl';
import {
  Disclaimer,
  RadioButtonBoolean,
  RadioGroupBoolean,
  type RadioGroupBooleanProps,
} from '@repo/design-system-kit';

export interface WithLegalRepresentative {
  legalRepresentative: boolean;
}

export interface LegalRepresentativeRadioGroupProps
  extends Omit<RadioGroupBooleanProps, 'onChange' | 'value' | 'label' | 'labelClassName' | 'name'> {
  entity: WithLegalRepresentative;
  showValidations: boolean;
  isEligible: boolean;
}

export function LegalRepresentativeRadioGroup({
  entity,
  errorMessage,
  showValidations,
  isEligible,
  ...props
}: LegalRepresentativeRadioGroupProps): React.ReactElement {
  const { formatMessage } = useIntl();

  return (
    <RadioGroupBoolean
      errorMessage={errorMessage}
      isInvalid={Boolean(errorMessage && showValidations)}
      label={formatMessage({
        id: 'organizations.profile.owner-form.role.label-legal-representative',
      })}
      labelClassName="body-1"
      name="legalRepresentative"
      onChange={value => {
        entity.legalRepresentative = value;
      }}
      tooltipText={formatMessage({
        id: 'organizations.profile.owner-form.tooltip.legal-representative',
      })}
      value={entity.legalRepresentative}
      {...props}
    >
      {isEligible ? (
        <>
          <RadioButtonBoolean data-test-legal-representative-radio-true="" value>
            {formatMessage({ id: 'organizations.profile.owner-form.role.radio-button-yes' })}
          </RadioButtonBoolean>
          <RadioButtonBoolean data-test-legal-representative-radio-false="" value={false}>
            {formatMessage({ id: 'organizations.profile.owner-form.role.radio-button-no' })}
          </RadioButtonBoolean>
        </>
      ) : (
        <Disclaimer.Inline data-test-legal-representative-disclaimer="">
          {formatMessage({
            id: 'organizations.profile.owner-form.disclaimer-legal-representative',
          })}
        </Disclaimer.Inline>
      )}
    </RadioGroupBoolean>
  );
}
