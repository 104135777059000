import { type ReactElement } from 'react';
import cx from 'clsx';
import { Tooltip } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import { CopyButton } from 'qonto/react/components/table-v2/buttons';
import { ActionSlot } from 'qonto/react/components/table-v2/action-slot';
import styles from './styles.strict-module.css';

type InformationType = 'email' | 'role' | 'team';
interface MembershipItemProps {
  information: InformationType;
  value: string;
}

export function MembershipItem({
  information,
  value,
  ...props
}: MembershipItemProps): ReactElement {
  const { formatMessage } = useIntl();

  const getTranslatedLabel = (type: InformationType): string => {
    switch (type) {
      case 'email':
        return formatMessage({ id: 'labels.email' });
      case 'role':
        return formatMessage({ id: 'labels.role' });
      case 'team':
        return formatMessage({ id: 'labels.team' });
    }
  };

  return (
    <div className={cx('overlay', styles.container)} data-testid="item" {...props}>
      <div className={styles.infos}>
        <span className={cx('caption', styles.key)} data-testid="information">
          {getTranslatedLabel(information)}
        </span>
        <span className="body-2" data-testid="value">
          {value}
        </span>
      </div>

      {information === 'email' && (
        <ActionSlot className={styles.action} size="28">
          <Tooltip
            closeDelay={0}
            data-testid="tooltip-content"
            delay={400}
            label={formatMessage({ id: 'aria.copy_to_clipboard.generic' })}
          >
            <CopyButton text={value} trackingEventSource="membership_cell_copy_email" />
          </Tooltip>
        </ActionSlot>
      )}
    </div>
  );
}
