import { useIntl } from 'react-intl';
import type { ReactElement } from 'react';
import { useEmberService, useFlags } from '@qonto/react-migration-toolkit/react/hooks';
import { Disclaimer } from '@repo/design-system-kit';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import type { EstimationError, EstimationWarning } from '../data-context.type';

interface ConfirmDisclaimersProps {
  warnings?: EstimationWarning[];
  errors?: EstimationError[];
  hasInsufficientFunds: boolean;
  planName?: string;
  addonName?: string;
}

export function ConfirmDisclaimers({
  warnings = [],
  errors = [],
  hasInsufficientFunds,
  planName,
  addonName,
}: ConfirmDisclaimersProps): ReactElement {
  const { formatMessage } = useIntl();
  const { organization } = useOrganizationManager();
  const { featureBooleanPricingItalianDisclaimers } = useFlags();
  const subscriptionManager = useEmberService('subscription-manager');

  const showDisclaimerForItalianOrganizations =
    featureBooleanPricingItalianDisclaimers && organization.legalCountry === 'IT';

  const showLegacyDisclaimer = warnings.some(warning => warning.code === 'current_plan_disabled');

  const showNoRefundDisclaimer = warnings.some(warning => warning.code === 'no_refund');

  const showPlanChangeDisclaimer = warnings.some(
    warning => warning.code === 'current_plan_does_not_support_target_addon'
  );

  const showRecurrenceDisclaimer = warnings.some(
    warning => warning.code === 'current_plan_recurrence_does_not_support_target_addon_recurrence'
  );

  const showESMDisclaimerForItalianOrganizations = errors.some(
    error => error.code === 'boi_change_disallowed'
  );

  return (
    <>
      {showDisclaimerForItalianOrganizations ? (
        <div data-test-addons-italian-disclaimer>
          <Disclaimer.Block>
            {formatMessage(
              { id: 'subscription.change.bank-of-italy-disclaimer.body' },
              {
                faqUrl: (
                  <a
                    data-test-addons-italian-disclaimer-link
                    href="https://support-it.qonto.com/hc/it/articles/26999640842513-Restrizioni-in-Italia-e-miglioramento-delle-misure-di-antiriciclaggio"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {formatMessage({ id: 'subscription.change.bank-of-italy-disclaimer.link' })}
                  </a>
                ),
              }
            )}
          </Disclaimer.Block>
        </div>
      ) : null}

      {showESMDisclaimerForItalianOrganizations ? (
        <div data-test-addons-esm-italian-disclaimer>
          <Disclaimer.Block>
            {formatMessage({ id: 'subscription.change.confirmation.add-on-blocked' })}
          </Disclaimer.Block>
        </div>
      ) : null}
      {hasInsufficientFunds ? (
        <div data-test-addons-insufficient-funds-disclaimer>
          <Disclaimer.Block level="error">
            {formatMessage({ id: 'subscription.change.confirmation.low-balance' })}
          </Disclaimer.Block>
        </div>
      ) : null}
      {showLegacyDisclaimer ? (
        <div data-test-addons-legacy-disclaimer>
          <Disclaimer.Block>
            {formatMessage({ id: 'subscription.change.confirmation.warnings.legacy-plan' })}
          </Disclaimer.Block>
        </div>
      ) : null}

      {showPlanChangeDisclaimer ? (
        <div data-test-addons-plan-switch-disclaimer>
          <Disclaimer.Block>
            {formatMessage(
              { id: 'subscription.change.confirmation.plan-addon.disclaimer-addon' },
              {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- subscriptionManager.currentPricePlan is not typed
                currentPlanName: (subscriptionManager.currentPricePlan?.localName as string) || '',
                planName,
                addonName,
              }
            )}
          </Disclaimer.Block>
        </div>
      ) : null}
      {showNoRefundDisclaimer ? (
        <div data-test-addons-no-refund-disclaimer>
          <Disclaimer.Block>
            {formatMessage({ id: 'subscription.change.cancellation.addon.disclaimer' })}
          </Disclaimer.Block>
        </div>
      ) : null}

      {showRecurrenceDisclaimer ? (
        <div data-test-addons-recurrence-disclaimer>
          <Disclaimer.Block>
            {formatMessage({ id: 'subscription.change.confirmation.plan-addon.disclaimer' })}
          </Disclaimer.Block>
        </div>
      ) : null}
    </>
  );
}
