/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import React from 'react';
import cx from 'clsx';
import { IconCrossOutlined } from '@repo/monochrome-icons';
import styles from './header.strict-module.css';

interface SidebarHeaderProps {
  title: string;
  subtitle: string;
  isTerminatedPayment?: boolean;
  isCompletedPayment?: boolean;
  onClose: () => void;
}

export function F24SidebarHeader({
  title,
  subtitle,
  isTerminatedPayment = false,
  isCompletedPayment = false,
  onClose,
}: SidebarHeaderProps): React.ReactElement {
  return (
    <div className={styles.container} data-test-f24-sidebar-header>
      <h1
        className={cx(
          styles.title,
          isTerminatedPayment && styles.error,
          isCompletedPayment && styles.success
        )}
        data-test-f24-sidebar-header-title
      >
        {title}
      </h1>
      <p className={styles.subtitle} data-test-f24-sidebar-header-date>
        {subtitle}
      </p>
      <button
        aria-label="Close"
        className={styles.close}
        data-test-f24-sidebar-close
        onClick={onClose}
        title="close"
        type="button"
      >
        <IconCrossOutlined aria-hidden="true" className={styles.closeIcon} height={12} width={12} />
      </button>
    </div>
  );
}
