import * as React from 'react';
import { useIntl } from 'react-intl';
import {
  RadioButtonBoolean,
  RadioGroupBoolean,
  type RadioGroupBooleanProps,
} from '@repo/design-system-kit';

export interface WithTaxPayerOutsideCountry {
  taxPayerOutsideLegalCountry: boolean;
}

export interface TaxPayerOutsideLegalCountryRadioGroupProps
  extends Omit<RadioGroupBooleanProps, 'onChange' | 'value' | 'label' | 'labelClassName' | 'name'> {
  entity: WithTaxPayerOutsideCountry;
  showValidations: boolean;
  legalCountry: string;
}

export function TaxPayerOutsideLegalCountryRadioGroup({
  entity,
  errorMessage,
  showValidations,
  legalCountry,
  ...props
}: TaxPayerOutsideLegalCountryRadioGroupProps): React.ReactElement {
  const { formatMessage } = useIntl();

  return (
    <RadioGroupBoolean
      errorMessage={errorMessage}
      isInvalid={Boolean(errorMessage && showValidations)}
      label={formatMessage(
        {
          id: 'organizations.profile.owner-form.tax-residency-elsewhere',
        },
        { legalCountry }
      )}
      labelClassName="title-4"
      name="taxPayerOutsideLegalCountry"
      onChange={value => {
        entity.taxPayerOutsideLegalCountry = value;
      }}
      value={entity.taxPayerOutsideLegalCountry}
      {...props}
    >
      <RadioButtonBoolean data-test-tax-info-us-tax-payer-outside-legal-country-true value>
        {formatMessage({ id: 'organizations.profile.company_profile.yes' })}
      </RadioButtonBoolean>
      <RadioButtonBoolean data-test-tax-info-us-tax-payer-outside-legal-country-false value={false}>
        {formatMessage({ id: 'organizations.profile.company_profile.no' })}
      </RadioButtonBoolean>
    </RadioGroupBoolean>
  );
}
