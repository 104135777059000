import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Value } from '../value';

interface BooleanValueProps {
  value?: boolean;
  warningLevel?: 'error' | 'warning';
}

export function BooleanValue({ value, warningLevel, ...props }: BooleanValueProps): ReactNode {
  const missing = !(value || value === false);

  return (
    <Value missing={missing} warningLevel={warningLevel} {...props}>
      {value ? (
        <FormattedMessage id="organizations.profile.company_profile.yes" />
      ) : (
        <FormattedMessage id="organizations.profile.company_profile.no" />
      )}
    </Value>
  );
}
