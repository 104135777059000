import { EmptyStatesInform } from '@repo/domain-kit/pricing';
import { useIntl } from 'react-intl';
import { useThemedAssetPath } from 'qonto/react/hooks/use-themed-asset-path.ts';
import styles from './table-states.strict-module.css';

export function SidepanelErrorState(): React.ReactNode {
  const { formatMessage } = useIntl();
  const lottieSrc = useThemedAssetPath('/lotties/generic_error.json');

  return (
    <div className={styles.tableStateContainer} data-testid="cash-flow-sidepanel-error-state">
      <EmptyStatesInform
        lottieSrc={lottieSrc}
        subtitle={formatMessage({ id: 'cashflow.side-panel.page.error.subtitle' })}
        title={formatMessage({ id: 'cashflow.side-panel.page.error.title' })}
      />
    </div>
  );
}
