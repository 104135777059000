import { type ReactNode } from 'react';
import cx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { ComparisonIcons } from '@repo/cards-kit/assets';
import { CARD_LEVELS } from 'qonto/constants/cards';
import styles from './insurance.strict-module.css';

interface InsuranceItemProps {
  isMetalCard: boolean;
  level: 'metal' | 'plus';
}

function PurchaseProtection({ isMetalCard, level }: InsuranceItemProps): ReactNode {
  return (
    <div className={styles['insurance-item']}>
      <img
        alt=""
        className={styles['insurance-item-icon']}
        data-test-insurance-purchase-illustration={level}
        loading="lazy"
        src={isMetalCard ? ComparisonIcons.IconPurchaseX : ComparisonIcons.IconPurchase}
      />
      <h4 className="title-4" data-test-insurance-purchase-label={level}>
        <FormattedMessage
          id={
            level === 'metal'
              ? 'cards.details.insurance-coverage.metal.purchase.label'
              : 'cards.details.insurance-coverage.plus.purchase.label'
          }
        />
      </h4>
      <p className="body-2" data-test-insurance-purchase-description={level}>
        <FormattedMessage
          id={
            level === 'metal'
              ? 'cards.details.insurance-coverage.metal.purchase.description'
              : 'cards.details.insurance-coverage.plus.purchase.description'
          }
        />
      </p>
    </div>
  );
}

function CoverageAbroad({ isMetalCard, level }: InsuranceItemProps): ReactNode {
  return (
    <div className={styles['insurance-item']}>
      <img
        alt=""
        className={styles['insurance-item-icon']}
        data-test-insurance-abroad-illustration={level}
        loading="lazy"
        src={isMetalCard ? ComparisonIcons.IconAbroadX : ComparisonIcons.IconAbroad}
      />
      <h4 className="title-4" data-test-insurance-abroad-label={level}>
        <FormattedMessage
          id={
            level === 'metal'
              ? 'cards.details.insurance-coverage.metal.abroad.label'
              : 'cards.details.insurance-coverage.plus.abroad.label'
          }
        />
      </h4>
      <p className="body-2" data-test-insurance-abroad-description={level}>
        <FormattedMessage
          id={
            level === 'metal'
              ? 'cards.details.insurance-coverage.metal.abroad.description'
              : 'cards.details.insurance-coverage.plus.abroad.description'
          }
        />
      </p>
    </div>
  );
}

function FlightDelay({ isMetalCard, level }: InsuranceItemProps): ReactNode {
  return (
    <div className={styles['insurance-item']}>
      <img
        alt=""
        className={styles['insurance-item-icon']}
        data-test-insurance-travel-illustration={level}
        loading="lazy"
        src={isMetalCard ? ComparisonIcons.IconFlightX : ComparisonIcons.IconFlight}
      />
      <h4 className="title-4" data-test-insurance-travel-label={level}>
        <FormattedMessage
          id={
            level === 'metal'
              ? 'cards.details.insurance-coverage.metal.travel.label'
              : 'cards.details.insurance-coverage.plus.travel.label'
          }
        />
      </h4>
      <p className="body-2" data-test-insurance-travel-description={level}>
        <FormattedMessage
          id={
            level === 'metal'
              ? 'cards.details.insurance-coverage.metal.travel.description'
              : 'cards.details.insurance-coverage.plus.travel.description'
          }
        />
      </p>
    </div>
  );
}

function FraudulentPayments({ isMetalCard, level }: InsuranceItemProps): ReactNode {
  return (
    <div className={styles['insurance-item']}>
      <img
        alt=""
        className={styles['insurance-item-icon']}
        data-test-insurance-fraud-illustration={level}
        loading="lazy"
        src={isMetalCard ? ComparisonIcons.IconFraudX : ComparisonIcons.IconFraud}
      />
      <h4 className="title-4" data-test-insurance-fraud-label={level}>
        <FormattedMessage
          id={
            level === 'metal'
              ? 'cards.details.insurance-coverage.metal.fraud.label'
              : 'cards.details.insurance-coverage.plus.fraud.label'
          }
        />
      </h4>
      <p className="body-2" data-test-insurance-fraud-description={level}>
        <FormattedMessage
          id={
            level === 'metal'
              ? 'cards.details.insurance-coverage.metal.fraud.description'
              : 'cards.details.insurance-coverage.plus.fraud.description'
          }
        />
      </p>
    </div>
  );
}

function Phishing({ isMetalCard, level }: InsuranceItemProps): ReactNode {
  return (
    <div className={styles['insurance-item']}>
      <img
        alt=""
        className={styles['insurance-item-icon']}
        data-test-insurance-phishing-illustration={level}
        loading="lazy"
        src={isMetalCard ? ComparisonIcons.IconPhishingX : ComparisonIcons.IconPhishing}
      />
      <h4 className="title-4" data-test-insurance-phishing-label={level}>
        <FormattedMessage
          id={
            level === 'metal'
              ? 'cards.details.insurance-coverage.metal.phishing.label'
              : 'cards.details.insurance-coverage.plus.phishing.label'
          }
        />
      </h4>
      <p className="body-2" data-test-insurance-phishing-description={level}>
        <FormattedMessage
          id={
            level === 'metal'
              ? 'cards.details.insurance-coverage.metal.phishing.description'
              : 'cards.details.insurance-coverage.plus.phishing.description'
          }
        />
      </p>
    </div>
  );
}
function CarRentals(): ReactNode {
  return (
    <div className={styles['insurance-item']}>
      <img
        alt=""
        className={styles['insurance-item-icon']}
        data-test-insurance-rental-illustration="metal"
        loading="lazy"
        src={ComparisonIcons.IconRentalX}
      />
      <h4 className="title-4" data-test-insurance-rental-label="metal">
        <FormattedMessage id="cards.details.insurance-coverage.metal.rental.label" />
      </h4>
      <p className="body-2" data-test-insurance-rental-description="metal">
        <FormattedMessage id="cards.details.insurance-coverage.metal.rental.description" />
      </p>
    </div>
  );
}

interface InsuranceProps {
  cardLevel: string;
}

export function Insurance({ cardLevel }: InsuranceProps): ReactNode {
  const isMetalCard = cardLevel === CARD_LEVELS.METAL;
  const isPlusCard = cardLevel === CARD_LEVELS.PLUS;

  if (!isMetalCard && !isPlusCard) return null;

  const level = isMetalCard ? 'metal' : 'plus';

  return (
    <div className="mb-80" data-test-card-details-insurance={level}>
      <h3 className="mb-16 title-2" data-test-insurance-title={level}>
        <FormattedMessage
          id={
            level === 'metal'
              ? 'cards.details.insurance-coverage.metal.title'
              : 'cards.details.insurance-coverage.plus.title'
          }
        />
      </h3>
      <p
        className={cx('body-1 mb-40', styles['content-insurance-text'])}
        data-test-insurance-description={level}
      >
        <FormattedMessage
          id={
            level === 'metal'
              ? 'cards.details.insurance-coverage.metal.description'
              : 'cards.details.insurance-coverage.plus.description'
          }
        />
      </p>

      <div className={styles.insurance}>
        <PurchaseProtection isMetalCard={isMetalCard} level={level} />
        <CoverageAbroad isMetalCard={isMetalCard} level={level} />
        <FlightDelay isMetalCard={isMetalCard} level={level} />
        <FraudulentPayments isMetalCard={isMetalCard} level={level} />
        <Phishing isMetalCard={isMetalCard} level={level} />
        {isMetalCard ? <CarRentals /> : null}
      </div>

      <p
        className={cx('body-1', styles['content-insurance-text'])}
        data-test-card-details-insurance-level-info
      >
        <FormattedMessage id="cards.details.insurance-coverage.level-info" />
      </p>
    </div>
  );
}
