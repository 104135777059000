import type { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import type { Amount } from 'qonto/react/models/amount';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import { CockpitTile } from '../cockpit-tile';
import styles from './styles.strict-module.css';

export interface TotalLeftToPayTileProps {
  totalLeftToPayAmount: Amount;
  lastInstallmentDate?: string;
}

export function TotalLeftToPayTile({
  totalLeftToPayAmount: amount,
  lastInstallmentDate,
}: TotalLeftToPayTileProps): ReactElement {
  const intl = useIntl();
  const subtitle = lastInstallmentDate
    ? intl.formatMessage(
        { id: 'financing.pay-later.cockpit.insights.total-left-to-pay.due-date' },
        {
          date: dateToken({
            date: lastInstallmentDate,
            locale: intl.locale,
            token: 'date-year-s',
          }),
        }
      )
    : intl.formatMessage({
        id: 'financing.pay-later.cockpit.insights.total-left-to-pay.no-installments',
      });

  return (
    <CockpitTile
      amount={amount}
      dataTestId="total-left-to-pay-tile"
      subtitle={
        <span className={styles.subtitle} data-test-total-left-to-pay-tile-subtitle="">
          {subtitle}
        </span>
      }
      title={intl.formatMessage({
        id: 'financing.pay-later.cockpit.insights.total-left-to-pay.label',
      })}
    />
  );
}
