import { type ReactNode } from 'react';
import { Link } from '@qonto/react-migration-toolkit/react/components';
import titleize from 'qonto/utils/titleize';
import type { Membership } from 'qonto/react/models/membership';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager.ts';
import styles from './row.strict-module.css';

type MembershipRowProps = Omit<
  Membership,
  'avatar' | 'firstName' | 'lastName' | 'email' | 'role' | 'status'
>;

interface SidebarInfoBoxRowProps {
  membership: MembershipRowProps;
}

export function SidebarInfoBoxRow({ membership, ...props }: SidebarInfoBoxRowProps): ReactNode {
  const { avatarThumb, fullName, id, team } = membership;
  const { organization } = useOrganizationManager();

  return (
    <div className={styles.row} {...props}>
      {avatarThumb?.fileUrl ? (
        <img
          alt={fullName}
          className={styles['avatar-image']}
          data-test-avatar
          src={avatarThumb.fileUrl}
        />
      ) : null}
      <div>
        <Link
          className={styles.link}
          data-test-link
          to={`/organizations/${organization.slug}/members/active/${id}`}
        >
          <div className={styles.name} data-test-name>
            {fullName}
          </div>
        </Link>
        {team?.name ? (
          <div className={styles.team} data-test-team>
            {titleize(team.name)}
          </div>
        ) : null}
      </div>
    </div>
  );
}
