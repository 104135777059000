import { type ReactElement } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import cx from 'clsx';
import { AmountField, Button, Form, Spinner } from '@repo/design-system-kit';
import { ChecksImage } from 'qonto/react/components/checks/image';
import { CheckField } from 'qonto/react/components/form-fields/check-field';
import styles from './details.strict-module.css';

interface FlowCheckDetailsProps {
  onSubmit: () => void;
  isSubmitting: boolean;
  maxAmount: number;
  maxAmountOnPeriod: number;
  shouldHighlightAmount: boolean;
  onAmountChange: (value: string) => void;
  onAmountFocusChange: (isFocused: boolean) => void;
  amountErrorMessage?: string;
  check: {
    amount?: string;
    cmc7: string;
    rlmc: string;
  };
  shouldHighlightCheckId: boolean;
  onCheckCmc7Update: (value: string) => void;
  onCheckRlmcUpdate: (value: string) => void;
  checkErrorMessage?: string;
  onCheckFocus: () => void;
  onCheckBlur: () => void;
}

export function FlowCheckDetails({
  onSubmit,
  isSubmitting,
  maxAmount,
  maxAmountOnPeriod,
  shouldHighlightAmount,
  onAmountChange,
  onAmountFocusChange,
  amountErrorMessage,
  check,
  shouldHighlightCheckId,
  onCheckCmc7Update,
  onCheckRlmcUpdate,
  checkErrorMessage,
  onCheckFocus,
  onCheckBlur,
}: FlowCheckDetailsProps): ReactElement {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.checks}>
      <div className={styles.content}>
        <Form className={styles.form} onSubmit={onSubmit}>
          <h2 className={cx('title-2 mb-8', styles.title)} data-test-create-check-details-title>
            <FormattedMessage id="checks.details.title" />
          </h2>

          <p className={cx('body-2 mb-24', styles.description)} data-test-check-details-description>
            <FormattedMessage
              id="checks.details.description"
              values={{
                maxAmount,
                maxAmountOnPeriod,
              }}
            />
          </p>

          <div className={cx('mb-40', styles.wrapper)}>
            <AmountField
              amount={check.amount}
              className={cx('mb-16', styles['amount-field'])}
              data-test-new-check-form-amount-cents
              errorMessage={amountErrorMessage}
              label={formatMessage({ id: 'checks.details.amount_label' })}
              minValue={0}
              onAmountChange={onAmountChange}
              onFocusChange={onAmountFocusChange}
            />

            <CheckField
              cmc7={check.cmc7}
              data-test-new-check-form-check
              errorMessage={checkErrorMessage}
              label={formatMessage({ id: 'labels.check_id' })}
              onBlur={onCheckBlur}
              onCmc7Update={onCheckCmc7Update}
              onFocus={onCheckFocus}
              onRlmcUpdate={onCheckRlmcUpdate}
              rlmc={check.rlmc}
            />
          </div>

          <Button
            className="btn btn--primary"
            data-test-new-check-form-details-submit
            isDisabled={isSubmitting}
            type="submit"
          >
            <FormattedMessage id="btn.continue" />
            {isSubmitting ? <Spinner className="ml-8" data-test-spinner size="small" /> : null}
          </Button>
        </Form>
      </div>

      <aside className={styles.aside}>
        <ChecksImage
          highlightAmount={shouldHighlightAmount}
          highlightCheckId={shouldHighlightCheckId}
        />
      </aside>
    </div>
  );
}
