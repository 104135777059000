import { LightCardDesign } from '@repo/cards-kit/constants';
import { IconCrossOutlined } from '@repo/monochrome-icons';
import cx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { CardImage } from 'qonto/react/cards/components/card-image';
import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import styles from './header.strict-module.css';

interface RequestDetails {
  status: 'declined' | 'canceled' | 'approved' | 'pending';
  requestType: string;
  createdAt: Date;
  processedAt: Date;
  note: string;
}

interface Approver {
  firstName: string;
  lastName: string;
}

interface RequestSidebarCardHeaderProps {
  request: RequestDetails & {
    approver: Approver;
    initiatorName: string;
  };
  closeSidebar: () => void;
}

export function RequestSidebarCardHeader({
  request,
  closeSidebar,
}: RequestSidebarCardHeaderProps): JSX.Element {
  const intl = useIntl();
  const {
    status,
    approver: { firstName, lastName },
    requestType,
    initiatorName,
    createdAt,
    processedAt,
    note,
  } = request;

  const pan = '•'.repeat(16);

  const getStatusClass = (): 'danger' | 'success' | 'warning' | '' => {
    switch (status) {
      case 'declined':
      case 'canceled':
        return 'danger';
      case 'approved':
        return 'success';
      case 'pending':
        return 'warning';
      default:
        return '';
    }
  };

  const design =
    requestType === 'virtual_card'
      ? LightCardDesign.VirtualDefault2017
      : LightCardDesign.FlashDefault2021;

  const getTitleStatus = (): string => {
    let approverName;

    if ((status === 'declined' || status === 'approved') && firstName && lastName) {
      approverName = `${firstName.charAt(0)}. ${lastName}`;
    }

    if (status === 'declined') {
      return intl.formatMessage(
        { id: 'requests.sidebar.header.status.declined' },
        { approver_name: approverName }
      );
    } else if (status === 'approved') {
      return intl.formatMessage(
        { id: 'requests.sidebar.header.status.accepted' },
        { approver_name: approverName }
      );
    } else if (status === 'canceled') {
      return intl.formatMessage({ id: 'requests.sidebar.header.status.canceled' });
    }
    // "pending" is the only option that status can have at this point
    return intl.formatMessage({ id: 'requests.sidebar.header.status.pending' });
  };

  const getLastActionDate = (): string => {
    if (status === 'pending') {
      return intl.formatMessage(
        { id: 'requests.sidebar.header.created-date' },
        {
          created_at: dateToken({
            //@ts-expect-error dateToken expects string but Date works just as well (Signature type is wrong)
            date: createdAt,
            locale: intl.locale,
            token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
          }),
        }
      );
    }

    return intl.formatMessage(
      { id: 'requests.sidebar.header.updated-date' },
      {
        updated_at: dateToken({
          //@ts-expect-error dateToken expects string but Date works just as well (Signature type is wrong)
          date: processedAt,
          locale: intl.locale,
          token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
        }),
      }
    );
  };

  return (
    <div
      className={cx(styles['card-sidebar-header'], 'mb-16')}
      data-test-request-card-sidebar-header
    >
      <div className={styles.content}>
        <div className={styles['header-type']}>
          <div className={styles['header-infos']}>
            <h2
              className={cx(styles.title, styles[getStatusClass()])}
              data-test-request-card-sidebar-title
            >
              {getTitleStatus()}
            </h2>
            <p className={styles.date}>{getLastActionDate()}</p>
          </div>
          <button
            aria-label={intl.formatMessage({ id: 'a11y.buttons.close-aria-label' })}
            className={cx('btn', 'btn--icon-only', 'btn--tertiary', styles.close)}
            data-test-close
            onClick={closeSidebar}
            type="button"
          >
            <IconCrossOutlined aria-hidden="true" className={styles['close-icon']} />
          </button>
        </div>
        <CardImage
          cardDesign={design}
          data-test-request-card-sidebar-image
          holderFullName={initiatorName}
          pan={pan}
        />
        <div className={styles['info-container']}>
          <div className={styles['spending-reason']}>
            <span className={styles['spending-reason-label']}>
              <FormattedMessage id="requests.sidebar.note" />
            </span>
            <span className={styles['spending-reason-note']}>{note}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
