import { FormattedMessage, useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { Education } from '@repo/domain-kit/shared';
import type { ReactElement } from 'react';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import { useFetchSignatureRequest } from 'qonto/react/financing/hooks/use-fetch-signature-request';
// @ts-expect-error The file needs to be migrated to TypeScript
import { ErrorInfo } from 'qonto/utils/error-info';
import { FullscreenModalTrigger } from 'qonto/react/shared/components/full-screen-modal-trigger';
import { AccountAggregationValueProposition } from 'qonto/react/financing/components/pay-later/cockpit/account-aggregation-value-proposition';
import styles from './styles.strict-module.css';

interface FlowLinkManager {
  transitionTo: (options: {
    name: string;
    stepId?: string;
    queryParams: { origin: string; signatureUrl?: string };
  }) => Promise<void>;
}

interface NotSignedStateProps {
  amount: {
    value: number;
    currency: string;
  };
  minimumInterestRate: string;
}

interface CardItem {
  type: string;
  illustration: ReactElement | string;
  title: string;
  list: string[];
}

interface ErrorInfoType {
  shouldSendToSentry: boolean;
  for: (error: Error) => ErrorInfoType;
}

export function NotSignedState({ amount, minimumInterestRate }: NotSignedStateProps): JSX.Element {
  const flowLinkManager = useEmberService('flow-link-manager') as unknown as FlowLinkManager;
  const toastFlashMessages = useEmberService('toastFlashMessages');
  const segment = useEmberService('segment');
  const sentry = useEmberService('sentry');
  const { formatMessage, formatNumber } = useIntl();
  const cardsContent: CardItem[] = [
    {
      type: 'flexible',
      illustration: (
        <StaticThemedAsset
          alt=""
          assetPath="/illustrations/financing/pay-later/education-empty-state/instant-activation.svg"
          className={styles.emptyStateIllustration}
        />
      ),
      title: formatMessage({
        id: 'financing.pay-later.empty-state.education.activation.title',
      }),
      list: [
        formatMessage({
          id: 'financing.pay-later.empty-state.education.activation.bullet-1',
        }),
        formatMessage(
          {
            id: 'financing.pay-later.empty-state.education.activation.bullet-2',
          },
          {
            rate: minimumInterestRate,
          }
        ),
      ],
    },
    {
      type: 'compliant',
      illustration: (
        <StaticThemedAsset
          alt=""
          assetPath="/illustrations/financing/pay-later/education-empty-state/easy-financing.svg"
          className={styles.emptyStateIllustration}
        />
      ),
      title: formatMessage({
        id: 'financing.pay-later.empty-state.education.usage.title',
      }),
      list: [
        formatMessage({
          id: 'financing.pay-later.empty-state.education.usage.bullet-1',
        }),
        formatMessage({
          id: 'financing.pay-later.empty-state.education.usage.bullet-2',
        }),
      ],
    },
    {
      type: 'integrated',
      illustration: (
        <StaticThemedAsset
          alt=""
          assetPath="/illustrations/financing/pay-later/education-empty-state/flexibility.svg"
          className={styles.emptyStateIllustration}
        />
      ),
      title: formatMessage({
        id: 'financing.pay-later.empty-state.education.repayment.title',
      }),
      list: [
        formatMessage({
          id: 'financing.pay-later.empty-state.education.repayment.bullet-1',
        }),
        formatMessage({
          id: 'financing.pay-later.empty-state.education.repayment.bullet-2',
        }),
      ],
    },
  ];

  const onSignatureRequestError = (error: unknown): void => {
    toastFlashMessages.toastError(
      formatMessage({ id: 'financing.pay-later.empty-state.education.error-toast' })
    );

    const errorInfo = (ErrorInfo as ErrorInfoType).for(error as Error);
    if (errorInfo.shouldSendToSentry) {
      sentry.captureException(error);
    }
  };
  const { isSignatureRequestLoading, retrievePayLaterSignatureUrl } =
    useFetchSignatureRequest(onSignatureRequestError);

  const handleFlowEntrance = async (): Promise<void> => {
    segment.track('pay-later-value_sign-contract_clicked');

    const data = await retrievePayLaterSignatureUrl();
    if (data?.url) {
      void flowLinkManager.transitionTo({
        name: 'pay-later-application',
        queryParams: { origin: 'cockpit', signatureUrl: data.url },
      });
    }
  };

  const handleTriggerClick = (): void => {
    segment.track('pay-later_cockpit_external-account-clicked', {
      origin: 'not-signed',
    });
  };

  return (
    <Education
      cardsContent={cardsContent}
      isPrimaryCtaLoading={isSignatureRequestLoading}
      onPrimaryCtaClick={handleFlowEntrance}
      primaryCtaText={formatMessage({ id: 'financing.pay-later.empty-state.education.cta' })}
      textBadge={formatMessage({ id: 'empty-states.system.activate.a3.badge' })}
      title={
        <FormattedMessage
          id="financing.pay-later.empty-state.education.title"
          values={{
            amount: formatNumber(Number(amount.value), {
              currency: amount.currency,
              minimumFractionDigits: 0,
              style: 'currency',
            }),
          }}
        >
          {text => <span dangerouslySetInnerHTML={{ __html: text }} />}
        </FormattedMessage>
      }
    >
      <FullscreenModalTrigger
        buttonSize="small"
        buttonText={formatMessage({ id: 'financing.pay-later.empty-state.education.footer.cta' })}
        buttonVariant="tertiary"
        onTriggerClick={handleTriggerClick}
      >
        <AccountAggregationValueProposition data-testid="account-aggregation-value-proposition" />
      </FullscreenModalTrigger>
    </Education>
  );
}
