import cx from 'clsx';
import type { ReactNode } from 'react';
import { Button } from '@repo/design-system-kit';
import { FormattedMessage } from 'react-intl';
import styles from './styles.strict-module.css';

interface FinancingStatementProps {
  isButtonDisabled: boolean;
  isButtonLoading: boolean;
  onClickGenerate: () => void;
}

export function FinancingStatement({
  isButtonDisabled,
  isButtonLoading,
  onClickGenerate,
}: FinancingStatementProps): ReactNode {
  return (
    <div className={styles['financing-statement']} data-testid="financing-statement">
      <p className={cx('body-1', styles.secondary)} data-testid="financing-statement-title">
        <FormattedMessage id="financing.pay-later.cockpit.details.statement.title" />
      </p>
      <p className={cx('body-2', styles.secondary)} data-testid="financing-statement-subtitle">
        <FormattedMessage id="financing.pay-later.cockpit.details.statement.subtitle" />
      </p>
      <Button
        data-testid="financing-statement-cta"
        isDisabled={isButtonDisabled}
        isLoading={isButtonLoading}
        onPress={onClickGenerate}
        size="small"
        variant="secondary"
      >
        <FormattedMessage id="financing.pay-later.cockpit.details.statement.cta" />
      </Button>
    </div>
  );
}
