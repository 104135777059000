import cx from 'clsx';
import { useIntl } from 'react-intl';
import { Button } from '@repo/design-system-kit';
import { IconCrossOutlined } from '@repo/monochrome-icons';
import styles from './value-proposition.strict-module.css';

interface FlowValuePropositionProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  // TODOL: Remove the `string` type when ember UI will be gone. At the moment ReactBridge doesn't support `React.ReactNode` props neither html components. The only way to pass html components is to pass them as a string or in children.
  illustration: React.ReactNode | string;
  className?: string;
  mainClassName?: string;
  illustrationClassName?: string;
  onClose?: () => void;
}

export function FlowValueProposition({
  children,
  illustration,
  className,
  mainClassName,
  illustrationClassName,
  onClose,
  ...props
}: FlowValuePropositionProps): React.ReactElement {
  const { formatMessage } = useIntl();

  return (
    <div className={cx(styles.container, className)} {...props}>
      <main className={cx(styles.content, mainClassName)}>
        <section>{children}</section>
      </main>
      <div className={cx(styles.illustration, illustrationClassName)}>
        {typeof illustration === 'string' ? (
          <figure dangerouslySetInnerHTML={{ __html: illustration }} />
        ) : (
          <figure> {illustration}</figure>
        )}
        {Boolean(onClose) && (
          <Button
            aria-label={formatMessage({ id: 'a11y.buttons.close-aria-label' })}
            className={styles.closeButton}
            iconOnly
            onPress={onClose}
            size="medium"
            variant="tertiary"
          >
            <IconCrossOutlined />
          </Button>
        )}
      </div>
    </div>
  );
}
