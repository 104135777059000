import { useState, type ReactNode } from 'react';
import { DialogTrigger, Popover } from 'react-aria-components';
import { QontoIntelligenceButton } from '@repo/domain-kit/accounts-payable';
import { useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useSegment } from '@repo/poly-hooks/segment';
import { SUGGESTED_TRANSACTIONS_POPUP_DISMISSED_STORAGE_KEY } from 'qonto/constants/supplier-invoice';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import styles from './suggested-transactions-popover.strict-module.css';
import { SuggestedTransactionsPopoverContent } from './content';
import type { SuggestedTransactionsPopoverProps } from './types';
import { withSuggestedTransaction } from './with-suggested-transaction';

export function SuggestedTransactionsPopover({
  invoice,
  reloadData,
}: SuggestedTransactionsPopoverProps): ReactNode | null {
  const TEST_PORTAL_CONTAINER = document.getElementById('ember-testing') ?? undefined;
  const [isOpen, setIsOpen] = useState(false);
  const [isDismissed, setIsDismissed] = useState(false);

  const toastFlashMessages = useEmberService('toast-flash-messages');
  const { formatMessage } = useIntl();
  const segment = useSegment();

  const suggestedTransactionId = invoice.suggestedTransactions[0]?.id;
  const attachmentId = invoice.attachmentId;
  const supplierInvoiceId = invoice.id;

  const dismissedSuggestions = JSON.parse(
    safeLocalStorage.getItem(SUGGESTED_TRANSACTIONS_POPUP_DISMISSED_STORAGE_KEY) || '[]'
  ) as string[];

  const hasRequiredData = Boolean(invoice.supplierName && invoice.totalAmount);

  const shouldShowSuggestedTransaction =
    suggestedTransactionId &&
    !dismissedSuggestions.includes(supplierInvoiceId) &&
    !isDismissed &&
    hasRequiredData;

  const handleMatchTransactionSuccess = (): void => {
    segment.track('supplier-invoices_partial_match-button_match_clicked', {
      origin: 'inbox',
    });
    setIsOpen(false);
    reloadData();
    toastFlashMessages.toastSuccess(
      formatMessage({
        id: 'supplier-invoices.success-toast.mark-as-paid',
      })
    );
  };

  const handleButtonClick = (): void => {
    if (!isOpen) {
      segment.track('supplier-invoices_partial_match-button_clicked');
    }
  };

  return shouldShowSuggestedTransaction ? (
    <DialogTrigger isOpen={isOpen} onOpenChange={setIsOpen}>
      <QontoIntelligenceButton
        className={styles['qonto-intelligence-button']}
        data-test-suggested-transactions-button
        onPress={handleButtonClick}
        size="small"
        variant="tertiary"
      />
      <Popover
        UNSTABLE_portalContainer={TEST_PORTAL_CONTAINER}
        className={styles['popover-wrapper']}
        crossOffset={4}
        data-testid="suggested-transactions-popover"
        placement="top start"
      >
        <SuggestedTransactionsPopoverContentWithSuggestedTransaction
          attachmentId={attachmentId}
          onMatchSuccess={handleMatchTransactionSuccess}
          setIsDismissed={setIsDismissed}
          supplierInvoiceId={supplierInvoiceId}
          transactionId={suggestedTransactionId}
        />
      </Popover>
    </DialogTrigger>
  ) : null;
}

const SuggestedTransactionsPopoverContentWithSuggestedTransaction = withSuggestedTransaction(
  SuggestedTransactionsPopoverContent
);
