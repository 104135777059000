/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import type { ReactNode } from 'react';
import cx from 'clsx';
import { FormattedMessage } from 'react-intl';
import type { CardModel } from 'qonto/react/cards/models/card';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import tinyMaskPan from 'qonto/utils/tiny-mask-pan';
import styles from './styles.strict-module.css';

interface CardDescriptionBoxProps {
  card: CardModel;
  className?: string;
  holderFullName: string;
}

interface CardStatusProps {
  card: CardModel;
}

function CardStatus({ card }: CardStatusProps): ReactNode {
  if (card.paused || card.lost || card.stolen || card.deleted) {
    return (
      <div
        className={cx(styles['status-text'], card.paused ? styles.paused : styles.deleted)}
        data-test-blocked
      >
        <span aria-hidden="true" className={styles.dot}>
          •
        </span>
        <FormattedMessage
          id={
            card.paused
              ? 'dispute-flow.card-description.blocked'
              : 'dispute-flow.card-description.deleted'
          }
        />
      </div>
    );
  }
}

export function CardDescriptionBox({
  card,
  className: classNameProp,
  holderFullName,
  ...restProps
}: CardDescriptionBoxProps): ReactNode {
  return (
    <div
      className={cx(styles['card-box'], classNameProp)}
      data-test-card-description-box
      {...restProps}
    >
      <StaticThemedAsset
        assetPath={`/illustrations/cards/card-${card.cardDesign}.svg`}
        className={styles['card-icon']}
        data-test-icon={`card-${card.cardDesign}`}
      />
      <div className={styles['card-container']}>
        <div className="body-1">
          <div className={styles['card-name']}>
            <p data-test-card-name>{card.fullName}</p>
            <CardStatus card={card} />
          </div>
          <p className={styles['card-holder-name']} data-test-card-holder-name>
            {holderFullName}
          </p>
        </div>
        <p aria-label={card.last4} className="body-1" data-test-last-4>
          {tinyMaskPan(card.last4)}
        </p>
      </div>
    </div>
  );
}
