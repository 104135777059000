import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { SkeletonLoader } from '@repo/design-system-kit';
import type { HTMLAttributes, ReactElement } from 'react';
import cx from 'clsx';
import styles from './styles.strict-module.css';

export function TablePlaceholder(props: HTMLAttributes<HTMLTableRowElement>): ReactElement {
  const abilities = useEmberService('abilities');

  return (
    <tr aria-hidden="true" className="row" {...props}>
      <td aria-hidden="true" className={styles.empty} />

      <td className={cx(styles.cell, styles.counterparty)}>
        <div className={styles.counterpartyContent}>
          <SkeletonLoader.Block
            borderRadius="50%"
            className={styles.avatarPlaceholder}
            data-test-id="skeleton-block"
            height="32px"
            width="32px"
          />
          <SkeletonLoader.Header
            data-test-id="skeleton-header"
            lines={1}
            smallLinesWidth="70px"
            width="118px"
          />
        </div>
      </td>

      <td className={styles.cell}>
        <SkeletonLoader.Line data-test-id="skeleton-line" standalone width="62px" />
      </td>

      <td className={styles.cell}>
        <SkeletonLoader.Line data-test-id="skeleton-line" standalone width="126px" />
      </td>

      <td className={styles.cell}>
        <SkeletonLoader.Line data-test-id="skeleton-line" standalone width="94px" />
      </td>

      <td align="right" className={cx(styles.cell, styles.amount)}>
        <SkeletonLoader.Line data-test-id="skeleton-line" standalone width="62px" />
      </td>

      <td align="right" className={cx(styles.cell, styles.removeTransaction)}>
        {abilities.can('update transaction budget') && (
          <SkeletonLoader.Block
            borderRadius="4px"
            data-test-id="skeleton-block"
            height="32px"
            width="32px"
          />
        )}
      </td>

      <td aria-hidden="true" className={styles.empty} />
    </tr>
  );
}
