import { useState } from 'react';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { IconEyeOutlined, IconEyeHiddenOutlined } from '@repo/monochrome-icons';
import styles from './styles.strict-module.css';

interface PinCodeInputProps {
  initialValue?: string;
  errorMessage?: string;
  valueChanged?: (value: string) => void;
  className?: string;
}

export function PinCodeInput({
  initialValue = '',
  errorMessage,
  valueChanged,
  className,
  ...props
}: PinCodeInputProps): React.ReactNode {
  const [hidden, setHidden] = useState(true);
  const [value, setValue] = useState(initialValue);
  const { formatMessage } = useIntl();

  const hasError = Boolean(errorMessage);
  const inputType = hidden ? 'password' : 'text';

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;
    setValue(newValue);
    valueChanged?.(newValue);
  };

  const toggleView = (): void => {
    setHidden(!hidden);
  };

  return (
    <div {...props} className={cx(styles['input-wrapper'], className)}>
      <div className={styles['input-container']}>
        <input
          aria-invalid={hasError}
          aria-label={formatMessage({ id: 'cards.pin-code-input.label' })}
          /* eslint-disable-next-line jsx-a11y/no-autofocus -- This input should focus on render */
          autoFocus
          className={cx('title-3 mr-8', styles.code, hasError && styles.error)}
          data-test-pin-code-input
          inputMode="numeric"
          maxLength={4}
          onChange={handleValueChange}
          type={inputType}
          value={value}
        />
        <button
          aria-checked={!hidden}
          aria-label={formatMessage({
            id: hidden ? 'a11y.icons.alt-values.show' : 'a11y.icons.alt-values.hide',
          })}
          className="btn btn--tertiary btn--icon-only btn--large"
          data-test-pin-code-reveal-btn
          onClick={toggleView}
          role="switch"
          type="button"
        >
          {hidden ? (
            <IconEyeOutlined
              aria-hidden="true"
              data-test-pin-code-reveal-btn-icon="icon_eye_outlined"
            />
          ) : (
            <IconEyeHiddenOutlined
              aria-hidden="true"
              data-test-pin-code-reveal-btn-icon="icon_eye_hidden_outlined"
            />
          )}
        </button>
      </div>
      {hasError ? (
        <p className={cx('caption', styles['error-message'])} data-test-pin-code-error role="alert">
          {errorMessage}
        </p>
      ) : null}
    </div>
  );
}
