import type { ComponentPropsWithRef, ReactNode } from 'react';

export function IconStatusProcessing(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path fill="#636360" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8" />
      <path
        fill="#fff"
        d="M4.494 7.991A.485.485 0 0 1 4 7.508v-.046l.004-.103c.056-1.082.639-2.063 1.599-2.696 1.575-1.036 3.75-.828 5.065.418l.557-.367a.501.501 0 0 1 .768.503l-.24 1.41a.5.5 0 0 1-.562.412L9.62 6.822a.499.499 0 0 1-.207-.913l.392-.258c-.999-.8-2.526-.895-3.652-.151-.69.456-1.11 1.152-1.15 1.91L5 7.485a.516.516 0 0 1-.5.506h-.006ZM12 8.513l-.004.126c-.056 1.082-.639 2.063-1.599 2.697A4.116 4.116 0 0 1 8.135 12c-1.034 0-2.058-.373-2.805-1.082l-.555.367a.498.498 0 0 1-.768-.504l.24-1.41a.497.497 0 0 1 .562-.411l1.573.216a.5.5 0 0 1 .206.913l-.392.259c.999.8 2.525.893 3.652.15.692-.455 1.111-1.151 1.15-1.91L11 8.513a.519.519 0 0 1 .511-.506A.486.486 0 0 1 12 8.5v.013Z"
      />
    </svg>
  );
}
