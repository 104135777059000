import React, { type ReactNode } from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './forecast-entry-loading-state.strict-module.css';

export function ForecastEntryLoadingState(): ReactNode {
  return (
    <div
      className={styles['forecast-entry-loading-state-container']}
      data-testid="forecast-entry-loading-state"
    >
      <div className={styles['loading-state-1']}>
        <SkeletonLoader.Line height="12px" standalone width="45px" />
        <SkeletonLoader.Line height="8px" standalone width="95px" />
      </div>
      <SkeletonLoader.Block height="100%" standalone width="170px" />
    </div>
  );
}
