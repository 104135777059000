import type { PropsWithChildren, ReactNode } from 'react';
import styles from './account-feature-list.strict-module.css';

export function AccountFeatureList({ children }: PropsWithChildren): ReactNode {
  return (
    <ul className={styles.features} data-test-feature-list>
      {children}
    </ul>
  );
}
