import { type ReactNode } from 'react';
import type { TransactionOperationMethod } from 'qonto/react/constants/transactions';
import styles from './styles.strict-module.css';

interface TransactionProps {
  handleClick: () => void;
  operationMethod: TransactionOperationMethod;
  transactionName: string;
  transactionDate: string;
  methodLabel: string;
  formattedAmount: string;
  side?: 'credit' | 'debit';
}

export function Transaction({
  handleClick,
  operationMethod,
  transactionName,
  transactionDate,
  methodLabel,
  formattedAmount,
  side,
  ...props
}: TransactionProps): ReactNode {
  const isCredit = side === 'credit';
  return (
    <button className={styles.transaction} onClick={handleClick} type="button" {...props}>
      <div>
        <div className={styles['transaction-name']} data-test-modal-transaction-name>
          {transactionName}
        </div>
        <div className={styles['transaction-date']} data-test-modal-transaction-date>
          {transactionDate}
        </div>
        <div
          className={styles['transaction-method']}
          data-test-modal-transaction-method={operationMethod}
        >
          {methodLabel}
        </div>
      </div>
      <div
        className={isCredit ? styles['credit-transaction'] : ''}
        data-test-modal-transaction-amount
      >
        {formattedAmount}
      </div>
    </button>
  );
}
