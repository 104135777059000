import * as React from 'react';
import { useIntl } from 'react-intl';
import { RadioGroup, RadioButton, type RadioGroupProps } from '@repo/design-system-kit';
import { GENDER } from 'qonto/constants/membership';

type ValueOf<T> = T[keyof T];

export type Gender = ValueOf<typeof GENDER>;

export interface WithGender {
  gender?: Gender;
}

export interface GenderSelectProps
  extends Omit<
    RadioGroupProps<Gender>,
    'onChange' | 'value' | 'label' | 'labelClassName' | 'name' | 'isInvalid'
  > {
  entity: WithGender;
  showValidations: boolean;
}

export function GenderSelect({
  entity,
  showValidations,
  errorMessage,
  ...props
}: GenderSelectProps): React.ReactElement {
  const { formatMessage } = useIntl();

  const handleChange = (value: Gender): void => {
    entity.gender = value;
  };

  return (
    <RadioGroup
      data-test-membership-gender
      errorMessage={errorMessage}
      isInvalid={Boolean(errorMessage && showValidations)}
      label={formatMessage({ id: 'labels.gender' })}
      labelClassName="body-1"
      name="gender"
      onChange={handleChange}
      value={entity.gender}
      {...props}
    >
      <RadioButton data-test-membership-gender-female value={GENDER.FEMALE}>
        {formatMessage({ id: 'gender-selector.female' })}
      </RadioButton>
      <RadioButton data-test-membership-gender-male value={GENDER.MALE}>
        {formatMessage({ id: 'gender-selector.male' })}
      </RadioButton>
    </RadioGroup>
  );
}
