import { type ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Disclaimer } from '@repo/design-system-kit';
import { IconCheckmarkRoundedFilled, IconCheckmarkRoundedOutlined } from '@repo/monochrome-icons';
import { type LocaleCode } from '@repo/shared-config/constants/locales';
import { useFlags } from '@qonto/react-migration-toolkit/react/hooks';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import { type RiskClass } from 'qonto/constants/kyc-kyb-update-process';
import { getCompanyType } from 'qonto/utils/organization';
import { getKycbTypeformLink, hasSddEddTypeformFeature } from 'qonto/utils/typeform';
import styles from './document-collection-banner.strict-module.css';

interface DocumentCollectionBannerProps {
  updateProcess: {
    status: string;
    riskClass: RiskClass;
    hasChanges: boolean;
    legallyRequired: boolean;
  };
  documentCollectionProcess: {
    status: string;
  };
  organization: {
    id: string;
    legalName: string;
    legalCountry: string;
    legalForm: string;
    legalCode: string;
    hasRestrictSensitiveActionsFeature: boolean;
    isItalian: boolean;
    status: string;
    suspensionReason: string;
  };
  membership: {
    userId: string;
    firstName: string;
  };
  onDocumentCollectionUpload: () => void;
  submitDocumentCollectionTask: { perform: () => Promise<void> };
  showMissingInfoDisclaimer: boolean;
}

interface DocumentCollectionBannerState {
  header?: string;
  subtitle?: string;
  'task-1'?: string;
  'task-2'?: string;
  'task-3'?: string;
  isTask1Completed?: boolean;
  cta?: string;
  action?: () => void;
  href?: string;
  bannerIllustrationSrc?: string;
  submitTask?: { perform: () => Promise<void> } | null;
}

export function DocumentCollectionBanner({
  updateProcess,
  documentCollectionProcess,
  organization,
  membership,
  onDocumentCollectionUpload,
  submitDocumentCollectionTask,
  showMissingInfoDisclaimer,
  ...props
}: DocumentCollectionBannerProps): ReactNode {
  const { locale, formatMessage } = useIntl();
  const { featureBooleanImproveCommsInIt } = useFlags();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isDocumentCollectionCreated = documentCollectionProcess.status === 'created';
  const isDocumentCollectionPendingUpload = documentCollectionProcess.status === 'pending_upload';
  const isDocumentCollectionPendingReview = documentCollectionProcess.status === 'pending_review';

  const getTypeformLink = (): string | null => {
    const { id, legalName, legalForm, legalCode } = organization;
    const { userId, firstName } = membership;
    const { riskClass } = updateProcess;

    const companyType = getCompanyType({
      legalForm,
      legalCode,
    });
    const href = getKycbTypeformLink(
      {
        organizationId: id,
        membershipUserId: userId,
        membershipFirstName: firstName,
        organizationLegalName: legalName,
      },
      riskClass,
      locale as LocaleCode,
      companyType
    );

    return href;
  };

  const shouldDisplaySddEddTypeform = (): boolean => {
    return hasSddEddTypeformFeature(
      organization,
      updateProcess,
      Boolean(featureBooleanImproveCommsInIt)
    );
  };

  const getDocumentCollectionPendingReviewHeader = (): string => {
    if (shouldDisplaySddEddTypeform()) {
      return formatMessage({
        id: 'organizations.profile.company_profile.manual-validation.title-italy',
      });
    }
    return formatMessage({
      id: 'organizations.profile.company_profile.manual-validation.title',
    });
  };

  const getDocumentCollectionPendingReviewSubtitle = (): string => {
    if (shouldDisplaySddEddTypeform()) {
      return formatMessage({
        id: 'organizations.profile.company_profile.manual-validation.subtitle-italy',
      });
    }
    return formatMessage({
      id: 'organizations.profile.company_profile.manual-validation.subtitle',
    });
  };

  const getDocumentCollectionPendingReviewBannerIllustrationSrc = (): string => {
    if (shouldDisplaySddEddTypeform()) {
      return '/illustrations/company-profile/fill-out-form.svg';
    }
    return '/illustrations/ill-cp-company-profile-review-validation.svg';
  };

  const bannerState: DocumentCollectionBannerState = (() => {
    const { hasChanges, status } = updateProcess;

    const commonTexts = {
      header: formatMessage({ id: 'periodic-review.document-upload.banner-manual-confirm.header' }),
      subtitle: formatMessage({
        id: 'periodic-review.document-upload.banner-manual-confirm.subtitle',
      }),
      'task-2': formatMessage({
        id: 'periodic-review.document-upload.banner-manual-confirm.task-2',
      }),
      ...(shouldDisplaySddEddTypeform() && {
        'task-3': formatMessage({
          id: 'periodic-review.document-upload.banner-manual-confirm.task-3',
        }),
      }),
    };

    // Visibility conditions:
    // 1. Document collection is `pending_upload`, user has not made changes so that status can be `created`;
    // 2. Document collection is `pending_upload`, there have been changes, but BE has already changed
    //    the status to `pending_review` or `accepted`.
    if (isDocumentCollectionPendingUpload && (!hasChanges || status !== 'created')) {
      // Upload documents banner with the first tick ticked:
      if (status === 'pending_review') {
        return {
          ...commonTexts,
          'task-1': formatMessage({
            id: 'periodic-review.document-upload.banner-manual-confirm.task-1-complete',
          }),
          isTask1Completed: true,
          cta: formatMessage({
            id: 'periodic-review.document-upload.banner-manual-confirm.documents-cta',
          }),
          action: onDocumentCollectionUpload,
          bannerIllustrationSrc: '/illustrations/ill-cp-company-profile-upload-document.svg',
        };
      }

      // Upload documents banner:
      return {
        header: formatMessage({
          id: 'periodic-review.document-upload.banner-automatic-confirm.title',
        }),
        subtitle: formatMessage({
          id: 'periodic-review.document-upload.banner-automatic-confirm.subtitle',
        }),
        cta: formatMessage({ id: 'periodic-review.document-upload.banner-automatic-confirm.cta' }),
        action: onDocumentCollectionUpload,
        bannerIllustrationSrc: '/illustrations/ill-cp-company-profile-upload-document.svg',
      };
    }

    if (status === 'pending_review' && isDocumentCollectionPendingReview) {
      // Reviewing information and document collection banner:
      return {
        header: getDocumentCollectionPendingReviewHeader(),
        subtitle: getDocumentCollectionPendingReviewSubtitle(),
        ...(shouldDisplaySddEddTypeform() && {
          cta: formatMessage({
            id: 'organizations.profile.company_profile.manual-validation.cta-italy',
          }),
          href: getTypeformLink() || '',
        }),
        bannerIllustrationSrc: getDocumentCollectionPendingReviewBannerIllustrationSrc(),
      };
    }

    // Visibility conditions:
    // 1. KYB/C might start in `created` status, but after the `.save()` status is changed to `pending_review`.
    //    So, while we wait for the `.save()` to finish, we should show the same initial banner;
    // 2. This is a fallback in case the document collection process is in `pending_upload`,
    //    but the update process `hasChanges` and the status is `created`.
    if (
      (status === 'created' || status === 'pending_review') &&
      (isDocumentCollectionCreated || isDocumentCollectionPendingUpload)
    ) {
      // Confirm information banner:
      return {
        ...commonTexts,
        'task-1': formatMessage({
          id: 'periodic-review.document-upload.banner-manual-confirm.task-1',
        }),
        cta: formatMessage({
          id: 'periodic-review.document-upload.banner-manual-confirm.data-cta',
        }),
        submitTask: submitDocumentCollectionTask,
        bannerIllustrationSrc: '/illustrations/ill-cp-company-profile-review-business-info.svg',
      };
    }

    return {};
  })();

  const disclaimerText = updateProcess.legallyRequired
    ? formatMessage({ id: 'organizations.profile.company-profile.banner-confirm.error-message' })
    : formatMessage({ id: 'organizations.profile.company-profile.banner-review.error-message' });

  const handleSubmit = async (): Promise<void> => {
    try {
      if (bannerState.submitTask) {
        setIsSubmitting(true);
        await bannerState.submitTask.perform().catch(ignoreCancelation);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles['document-collection-banner']} {...props}>
      <div className={styles.banner}>
        <div className={styles.inner}>
          <p className={styles.header} data-test-header>
            {bannerState.header}
          </p>

          <div className={styles.content}>
            <p className={styles.subtitle} data-test-subtitle>
              {bannerState.subtitle}
            </p>

            {bannerState['task-1'] || bannerState['task-2'] ? (
              <ul className={styles.list}>
                {bannerState['task-1'] ? (
                  <li className={styles.item} data-test-task-1>
                    {bannerState.isTask1Completed ? (
                      <IconCheckmarkRoundedFilled aria-hidden="true" className={styles.icon} />
                    ) : (
                      <IconCheckmarkRoundedOutlined aria-hidden="true" className={styles.icon} />
                    )}
                    {bannerState['task-1']}
                  </li>
                ) : null}

                {bannerState['task-2'] ? (
                  <li className={styles.item} data-test-task-2>
                    <IconCheckmarkRoundedOutlined aria-hidden="true" className={styles.icon} />
                    {bannerState['task-2']}
                  </li>
                ) : null}

                {bannerState['task-3'] ? (
                  <li className={styles.item} data-test-task-3>
                    <IconCheckmarkRoundedOutlined aria-hidden="true" className={styles.icon} />
                    {bannerState['task-3']}
                  </li>
                ) : null}
              </ul>
            ) : null}

            {showMissingInfoDisclaimer ? (
              <Disclaimer.Inline data-test-disclaimer="" level="error">
                {disclaimerText}
              </Disclaimer.Inline>
            ) : null}

            {bannerState.cta ? (
              <>
                {bannerState.href ? (
                  <a
                    className="btn btn--primary"
                    data-test-cta
                    href={bannerState.href}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {bannerState.cta}
                  </a>
                ) : (
                  <Button
                    data-test-cta
                    isLoading={isSubmitting}
                    onPress={bannerState.submitTask ? handleSubmit : bannerState.action}
                  >
                    {bannerState.cta}
                  </Button>
                )}
              </>
            ) : null}
          </div>
        </div>

        <div className={styles.illustration}>
          {bannerState.bannerIllustrationSrc ? (
            <StaticThemedAsset
              assetPath={bannerState.bannerIllustrationSrc}
              className={styles.image}
              data-test-image
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
