import { type ReactNode } from 'react';
import { Button } from '@repo/design-system-kit';
import { useSegment } from '@repo/poly-hooks/segment';
import { useIntl } from 'react-intl';
import { RIBA_STATUS } from 'qonto/constants/riba';
import type RibaPaymentModel from 'qonto/models/riba-payment';

interface ReverseApprovalProps {
  payment: RibaPaymentModel;
  onApprove?: (id: string) => void;
  onReject?: (id: string) => void;
}

export function ReverseApproval({ payment, onApprove, onReject }: ReverseApprovalProps): ReactNode {
  const intl = useIntl();

  const segment = useSegment();

  if ((!onApprove && !onReject) || payment.status === RIBA_STATUS.PENDING) {
    return null;
  }
  const showApprove = onApprove && payment.status === RIBA_STATUS.REJECTED && payment.canUpdate;

  const approveRejectedPayment = (): void => {
    if (onApprove) {
      segment.track('riba_rejected_payment_approved');
      onApprove(payment.id);
    }
  };

  const rejectApprovedPayment = (): void => {
    if (onReject) {
      segment.track('riba_processing_payment_rejected');
      onReject(payment.id);
    }
  };

  return (
    <div>
      {showApprove ? (
        <Button
          data-test-riba-sidebar-approve-button
          onPress={approveRejectedPayment}
          variant="tertiary"
        >
          {intl.formatMessage({ id: 'riba.sidebar.switch-approval.approve-btn' })}
        </Button>
      ) : null}
      {onReject ? (
        <Button
          data-test-riba-sidebar-reject-button
          onPress={rejectApprovedPayment}
          variant="tertiary"
        >
          {intl.formatMessage({ id: 'riba.sidebar.switch-approval.reject-btn' })}
        </Button>
      ) : null}
    </div>
  );
}
