import {
  createContext,
  useCallback,
  useContext,
  useReducer,
  type Dispatch,
  type ReactNode,
} from 'react';
import type { Label, Transaction } from 'qonto/react/graphql';
import type { AttachmentStatusOption } from '../models/transaction';
import { useFetchApi } from '../hooks/use-fetch-api';
import { useOrganizationManager } from '../hooks/use-organization-manager';
import {
  updateBulkAttachmentStatus,
  updateBulkCashflowCategory,
  updateBulkVerificationStatus,
  updateOtherBulkFields,
} from '../utils/bulk/field-update-functions';
import type { LabelList as EmberLabelist } from '../models/label';

interface ContextState {
  labels: {
    updatedLabels: Record<string, string>;
    newLabels: Label[];
  };
  verificationStatus: {
    verificationStatus: Transaction['qualifiedForAccounting'] | null;
    verificationStatusChanged: boolean;
  };
  category: {
    category: Transaction['activityTag'] | null;
    categoryChanged: boolean;
  };
  cashflowCategory: {
    cashflowCategory: string | null;
    cashflowCategoryChanged: boolean;
  };
  attachmentStatus: {
    attachmentStatus: AttachmentStatusOption | null;
    attachmentStatusChanged: boolean;
  };
  requestAttachment: {
    requestAttachment: Transaction['attachmentRequested'];
  };
  organizationId: string;
}
interface LabelAction {
  field: 'labels';
  type: 'setUpdatedLabels';
  payload: [EmberLabelist, Label];
}

interface VerificationStatusAction {
  field: 'verificationStatus';
  type: 'setVerificationStatus';
  payload: Transaction['qualifiedForAccounting'];
  options: { initialStatus: Transaction['qualifiedForAccounting'] | null };
}

interface CategoryAction {
  field: 'category';
  type: 'setCategory';
  payload: Transaction['activityTag'];
}

interface CategoryAssignmentAction {
  field: 'categoryAssignment';
  type: 'setCashflowCategory';
  payload: {
    categoryId: string | null;
    initialCategoryId: string | null;
  };
}

interface AttachmentStatusAction {
  field: 'attachmentStatus';
  type: 'setAttachmentStatus';
  payload: AttachmentStatusOption;
  options: { initialStatus: AttachmentStatusOption | null };
}

interface RequestAttachmentAction {
  field: 'requestAttachment';
  type: 'setRequestAttachment';
  payload: Transaction['attachmentRequested'];
}

interface RefreshStateAction {
  type: 'refreshState';
  field: 'all';
  payload?: string[];
}

interface BulkTransactionsContextType {
  state: ContextState;
  dispatch: Dispatch<
    | LabelAction
    | VerificationStatusAction
    | CategoryAction
    | AttachmentStatusAction
    | RequestAttachmentAction
    | CategoryAssignmentAction
    | RefreshStateAction
  >;
}

const reducer = (
  state: ContextState,
  action:
    | LabelAction
    | VerificationStatusAction
    | CategoryAction
    | AttachmentStatusAction
    | RequestAttachmentAction
    | CategoryAssignmentAction
    | RefreshStateAction
): ContextState => {
  switch (action.field) {
    case 'labels':
      return {
        ...state,
        labels: {
          updatedLabels: {
            ...state.labels.updatedLabels,
            [action.payload[0].id]: action.payload[1].id,
          },
          newLabels: [
            ...state.labels.newLabels.filter(label => label.id !== action.payload[1].id),
            action.payload[1],
          ],
        },
      };
    case 'verificationStatus':
      return {
        ...state,
        verificationStatus: {
          verificationStatus: action.payload,
          verificationStatusChanged: action.options.initialStatus !== action.payload,
        },
      };
    case 'category':
      return {
        ...state,
        category: {
          category: action.payload,
          categoryChanged: true,
        },
      };
    case 'categoryAssignment':
      return {
        ...state,
        cashflowCategory: {
          cashflowCategory: action.payload.categoryId,
          cashflowCategoryChanged: action.payload.categoryId !== action.payload.initialCategoryId,
        },
      };
    case 'attachmentStatus':
      return {
        ...state,
        attachmentStatus: {
          attachmentStatus: action.payload,
          attachmentStatusChanged: action.options.initialStatus !== action.payload,
        },
      };
    case 'requestAttachment':
      return {
        ...state,
        requestAttachment: {
          requestAttachment: action.payload,
        },
      };
    case 'all': {
      const fieldsToRefresh = action.payload || [];
      return {
        ...state,
        labels: fieldsToRefresh.includes('labels')
          ? {
              updatedLabels: {},
              newLabels: [],
            }
          : state.labels,
        verificationStatus: fieldsToRefresh.includes('verificationStatus')
          ? {
              verificationStatus: state.verificationStatus.verificationStatus,
              verificationStatusChanged: false,
            }
          : state.verificationStatus,
        category: fieldsToRefresh.includes('category')
          ? {
              category: state.category.category,
              categoryChanged: false,
            }
          : state.category,
        cashflowCategory: fieldsToRefresh.includes('cashflowCategory')
          ? {
              cashflowCategory: state.cashflowCategory.cashflowCategory,
              cashflowCategoryChanged: false,
            }
          : state.cashflowCategory,
        attachmentStatus: fieldsToRefresh.includes('attachmentStatus')
          ? {
              attachmentStatus: state.attachmentStatus.attachmentStatus,
              attachmentStatusChanged: false,
            }
          : state.attachmentStatus,
        requestAttachment: fieldsToRefresh.includes('requestAttachment')
          ? {
              requestAttachment: state.requestAttachment.requestAttachment,
            }
          : state.requestAttachment,
      };
    }
    default:
      return state;
  }
};

const initialState: ContextState = {
  labels: { updatedLabels: {}, newLabels: [] },
  verificationStatus: {
    verificationStatus: false,
    verificationStatusChanged: false,
  },
  category: {
    category: null,
    categoryChanged: false,
  },
  cashflowCategory: {
    cashflowCategory: null,
    cashflowCategoryChanged: false,
  },
  attachmentStatus: {
    attachmentStatus: null,
    attachmentStatusChanged: false,
  },
  requestAttachment: {
    requestAttachment: false,
  },
  organizationId: '',
};

const BulkTransactionsContext = createContext<BulkTransactionsContextType | undefined>(undefined);

export function BulkTransactionsProvider({
  children,
  organizationId,
}: {
  children: ReactNode;
  organizationId: string;
}): ReactNode {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    organizationId,
  });

  return (
    <BulkTransactionsContext.Provider value={{ state, dispatch }}>
      {children}
    </BulkTransactionsContext.Provider>
  );
}

interface UseBulkTransactions {
  labels: {
    updatedLabels: Record<string, string>;
    newLabels: Label[];
    aggregatedUpdatedLabels: string[];
    setUpdatedLabels: (labelList: EmberLabelist, label: Label) => void;
    mutationFn: (selectedTransactions: string[]) => Promise<void>;
  };
  verificationStatus: {
    verificationStatus: Transaction['qualifiedForAccounting'] | null;
    verificationStatusChanged: boolean;
    setVerificationStatus: (
      status: Transaction['qualifiedForAccounting'],
      initialStatus: Transaction['qualifiedForAccounting'] | null
    ) => void;
    mutationFn: (selectedTransactions: string[]) => Promise<void>;
  };
  category: {
    category: Transaction['activityTag'] | null;
    categoryChanged: boolean;
    setCategory: (category: Transaction['activityTag']) => void;
    mutationFn: (selectedTransactions: string[]) => Promise<void>;
  };
  cashflowCategory: {
    cashflowCategory: string | null;
    cashflowCategoryChanged: boolean;
    setCashflowCategory: (categoryId: string | null, initialCategory: string | null) => void;
    mutationFn: (selectedTransactions: string[]) => Promise<void>;
  };
  attachmentStatus: {
    attachmentStatus: AttachmentStatusOption | null;
    attachmentStatusChanged: boolean;
    setAttachmentStatus: (
      newStatus: AttachmentStatusOption,
      initialStatus: AttachmentStatusOption | null
    ) => void;
    mutationFn: (selectedTransactions: string[]) => Promise<void>;
  };
  requestAttachment: {
    requestAttachment: Transaction['attachmentRequested'];
    setRequestAttachment: (requested: Transaction['attachmentRequested']) => void;
    mutationFn: (selectedTransactions: string[]) => Promise<void>;
  };
  refreshState: (fields: string[]) => void;
}

const useBulkTransactions = (): UseBulkTransactions => {
  const context = useContext(BulkTransactionsContext);
  if (!context) {
    throw new Error('useBulkTransactions must be used within a BulkTransactionsContext');
  }
  const fetchApi = useFetchApi();
  const {
    organization: { organizationId },
  } = useOrganizationManager();
  const { state, dispatch } = context;
  const {
    labels: { updatedLabels, newLabels },
    verificationStatus: { verificationStatus, verificationStatusChanged },
    category: { category, categoryChanged },
    attachmentStatus: { attachmentStatus, attachmentStatusChanged },
    requestAttachment: { requestAttachment },
    cashflowCategory: { cashflowCategory, cashflowCategoryChanged },
  } = state;
  const aggregatedUpdatedLabels = Object.values(updatedLabels);

  const setUpdatedLabels = useCallback(
    (labelList: EmberLabelist, label: Label) => {
      dispatch({
        type: 'setUpdatedLabels',
        payload: [labelList, label],
        field: 'labels',
      });
    },
    [dispatch]
  );

  const setVerificationStatus = useCallback(
    (
      status: Transaction['qualifiedForAccounting'],
      initialStatus: Transaction['qualifiedForAccounting'] | null
    ) => {
      dispatch({
        type: 'setVerificationStatus',
        payload: status,
        field: 'verificationStatus',
        options: { initialStatus },
      });
    },
    [dispatch]
  );

  const setRequestAttachment = useCallback(
    (requested: Transaction['attachmentRequested']) => {
      dispatch({
        type: 'setRequestAttachment',
        payload: requested,
        field: 'requestAttachment',
      });
    },
    [dispatch]
  );

  const setCategory = useCallback(
    (newCategory: Transaction['activityTag']) => {
      dispatch({ type: 'setCategory', payload: newCategory, field: 'category' });
    },
    [dispatch]
  );

  const setCashflowCategory = useCallback(
    (categoryId: string | null, initialCategoryId: string | null) => {
      dispatch({
        type: 'setCashflowCategory',
        payload: { categoryId, initialCategoryId },
        field: 'categoryAssignment',
      });
    },
    [dispatch]
  );

  const setAttachmentStatus = useCallback(
    (newStatus: AttachmentStatusOption, initialStatus: AttachmentStatusOption | null) => {
      dispatch({
        type: 'setAttachmentStatus',
        payload: newStatus,
        field: 'attachmentStatus',
        options: { initialStatus },
      });
    },
    [dispatch]
  );

  const refreshState = useCallback(
    (fields: string[]) => {
      dispatch({ type: 'refreshState', field: 'all', payload: fields });
    },
    [dispatch]
  );

  const mutateBulkUpdates = useCallback(
    (selectedTransactions: string[]) => {
      return updateOtherBulkFields(fetchApi, {
        organizationId,
        transactionIds: selectedTransactions,
        category: category ?? '',
        updatedLabelIds: aggregatedUpdatedLabels,
        requestAttachment,
      });
    },
    [fetchApi, organizationId, category, aggregatedUpdatedLabels, requestAttachment]
  );

  const mutateBulkVerificationStatus = useCallback(
    (selectedTransactions: string[]) => {
      return updateBulkVerificationStatus(fetchApi, organizationId, {
        transactionIds: selectedTransactions as [string, ...string[]],
        qualifiedForAccounting: verificationStatus === null ? false : !verificationStatus,
      });
    },
    [verificationStatus, organizationId, fetchApi]
  );

  const mutateAttachmentStatus = useCallback(
    (selectedTransactions: string[]) => {
      return updateBulkAttachmentStatus(fetchApi, organizationId, {
        transactionIds: selectedTransactions as [string, ...string[]],
        attachmentStatus,
      });
    },
    [attachmentStatus, organizationId, fetchApi]
  );

  const mutateBulkCashflowCategory = useCallback(
    (selectedTransactions: string[]) => {
      return updateBulkCashflowCategory(fetchApi, {
        transactionIds: selectedTransactions as [string, ...string[]],
        categoryId: cashflowCategory,
      });
    },
    [cashflowCategory, fetchApi]
  );

  return {
    labels: {
      updatedLabels,
      newLabels,
      aggregatedUpdatedLabels,
      setUpdatedLabels,
      mutationFn: mutateBulkUpdates,
    },
    verificationStatus: {
      verificationStatus,
      verificationStatusChanged,
      setVerificationStatus,
      mutationFn: mutateBulkVerificationStatus,
    },
    category: {
      category,
      categoryChanged,
      setCategory,
      mutationFn: mutateBulkUpdates,
    },
    cashflowCategory: {
      cashflowCategory,
      cashflowCategoryChanged,
      setCashflowCategory,
      mutationFn: mutateBulkCashflowCategory,
    },
    attachmentStatus: {
      attachmentStatus,
      attachmentStatusChanged,
      setAttachmentStatus,
      mutationFn: mutateAttachmentStatus,
    },
    requestAttachment: {
      requestAttachment,
      setRequestAttachment,
      mutationFn: mutateBulkUpdates,
    },
    refreshState,
  };
};

//  required for stubbing with sinon.js
export const bulkTransactionsManager = {
  useBulkTransactions,
};
