import { useEffect, useState, type ReactNode } from 'react';
import { Popup, Button } from '@repo/design-system-kit';
import { useEmberService, useRouter } from '@qonto/react-migration-toolkit/react/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import window from 'ember-window-mock';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import ENV from 'qonto/config/environment';
import { Illustration } from './illustration';

const isTest = ENV.environment === 'test';

export const RIBA_POPUP_STORAGE_KEY = 'ribapromopopupdismissed';

const popupDelay = ENV.environment === 'test' ? 0 : 2000;

export function RibaPromotionalPopup(): ReactNode {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const segment = useEmberService('segment');
  const { organization } = useOrganizationManager();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (window.localStorage.getItem(RIBA_POPUP_STORAGE_KEY)) {
      return;
    }

    const timeout = setTimeout(() => {
      setIsOpen(true);
      segment.track('discovery_promotional-pop-up_displayed', {
        feature: 'pay_by_riba',
      });
      window.localStorage.setItem(RIBA_POPUP_STORAGE_KEY, 'true');
    }, popupDelay);

    return () => {
      clearTimeout(timeout);
    };
  }, [segment]);

  const handleConfirm = (): void => {
    segment.track('discovery_promotional-pop-up_clicked', {
      feature: 'pay_by_riba',
    });
    void router.push(`/organizations/${organization.slug}/riba`);
  };

  const handleCancel = (): void => {
    segment.track('discovery_promotional-pop-up_closed', {
      feature: 'pay_by_riba',
    });
    setIsOpen(false);
  };

  // react-aria modal escapes the test container, we render it where it can find the modal (i.e. <main> component)
  const TEST_PORTAL_CONTAINER = document.querySelector('main') ?? undefined;

  return (
    <Popup.Root
      data-test-id="riba-promotional-popup"
      isDismissable
      isOpen={isOpen}
      portalContainer={isTest ? TEST_PORTAL_CONTAINER : undefined}
    >
      <Popup.CloseButton onPress={handleCancel} />
      <Popup.Illustration isPromotional>
        <Illustration />
      </Popup.Illustration>
      <Popup.Header>
        <Popup.Title>
          <FormattedMessage id="riba.popup.promo.title" />
        </Popup.Title>
        <Popup.Subtitle>
          <FormattedMessage id="riba.popup.promo.subtitle" />
        </Popup.Subtitle>
      </Popup.Header>
      <Popup.Footer>
        <Button
          aria-label={formatMessage({ id: 'btn.close' })}
          data-test-id="modal-cancel-button"
          onPress={handleCancel}
          variant="secondary"
        >
          <FormattedMessage id="btn.close" />
        </Button>
        <Button
          aria-label={formatMessage({ id: 'riba.popup.promo.cta' })}
          onPress={handleConfirm}
          variant="primary"
        >
          <FormattedMessage id="riba.popup.promo.cta" />
        </Button>
      </Popup.Footer>
    </Popup.Root>
  );
}
