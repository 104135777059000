import { TabList, Tabs } from '@repo/design-system-kit';
import type { ReactElement } from 'react';
import type { Key } from 'react-aria-components';
import { useIntl } from 'react-intl';
import type { PAY_LATER_COCKPIT_ROUTES } from 'qonto/constants/financing';

export type TabId = keyof typeof PAY_LATER_COCKPIT_ROUTES;

interface PayLaterCockpitTabsProps {
  activeId: TabId;
  changeTab: (id: Key) => void;
  count?: number;
}

export function PayLaterCockpitTabs({
  activeId,
  changeTab,
  count,
}: PayLaterCockpitTabsProps): ReactElement {
  const { formatMessage } = useIntl();

  const tabs = [
    {
      id: 'IN_PROGRESS',
      title: formatMessage({ id: 'financing.pay-later.cockpit.tabs.in-progress' }),
      'data-test-tabs-nav-item': 0,
      'data-test-tabs-nav-item-value': 'in-progress',
      count,
      maxCount: 99,
    },
    {
      id: 'COMPLETED',
      title: formatMessage({
        id: 'financing.pay-later.cockpit.tabs.completed',
      }),
      'data-test-tabs-nav-item': 1,
      'data-test-tabs-nav-item-value': 'completed',
    },
  ];

  return (
    <Tabs activeId={activeId} changeTab={changeTab}>
      <TabList tabs={tabs} />
    </Tabs>
  );
}
