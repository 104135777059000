import IconStatusCancelledUrl from 'qonto/static/icon/status/status_cancelled.svg';
import IconStatusProcessingUrl from 'qonto/static/icon/status/status_processing.svg';
import StatusApprovedUrl from 'qonto/static/icon/status/status_approved.svg';
import StatusWarningUrl from 'qonto/static/icon/status/status_warning.svg';
import StatusScheduledUrl from 'qonto/static/icon/status/status_scheduled.svg';
import StatusPausedUrl from 'qonto/static/icon/status/status_paused.svg';
import IconStatusProcessingSUrl from 'qonto/static/icon/status/status_processing-s.svg';
import IconStatusStopXSUrl from 'qonto/static/icon/status/status_stop-xs.svg';

export const ICON_PATHS = {
  status_cancelled: IconStatusCancelledUrl,
  status_processing: IconStatusProcessingUrl,
  status_approved: StatusApprovedUrl,
  status_warning: StatusWarningUrl,
  status_scheduled: StatusScheduledUrl,
  status_paused: StatusPausedUrl,
  'status_processing-s': IconStatusProcessingSUrl,
  'status_stop-xs': IconStatusStopXSUrl,
} as const;
