import { type ReactNode } from 'react';
import React from 'react';
import { DropdownSelector } from '@repo/design-system-kit';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';

const transactionTypeDropdownItems = [
  {
    key: 'both',
    labelId: 'cash-flow.upcoming-transactions.header.inflows-and-outflows',
    dropdownId: 'cash-flow.upcoming-transactions.header.dropdown.inflows-and-outflows',
  },
  {
    key: 'inflows',
    labelId: 'cash-flow.upcoming-transactions.header.inflows',
    dropdownId: 'cash-flow.upcoming-transactions.header.dropdown.inflows',
  },
  {
    key: 'outflows',
    labelId: 'cash-flow.upcoming-transactions.header.outflows',
    dropdownId: 'cash-flow.upcoming-transactions.header.dropdown.outflows',
  },
];

export function TransactionTypeSelector({
  onSelectionChange,
  selectedKey,
}: {
  onSelectionChange: (key: string) => void;
  selectedKey: string;
}): ReactNode {
  const segment = useEmberService('segment');
  const handleSelectionChange = (key: string): void => {
    const trackingMap: Record<string, string> = {
      both: 'inflows_outflows',
      inflows: 'inflows_only',
      outflows: 'outflows_only',
    };
    segment.track('compass_movement_selected', { movement: trackingMap[key] });
    onSelectionChange(key);
  };

  return (
    <DropdownSelector
      items={transactionTypeDropdownItems}
      onSelectionChange={handleSelectionChange}
      selectedKey={selectedKey}
    />
  );
}
