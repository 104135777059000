import type { ReactNode } from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './styles.strict-module.css';

export function SummaryPlaceholder(props: React.HTMLAttributes<HTMLDivElement>): ReactNode {
  return (
    <div aria-hidden="true" {...props}>
      {Array.from({ length: 4 }).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key -- static items
        <div className={`${styles.row} mb-16`} key={`key-${index}`}>
          <SkeletonLoader.Line width="96px" />
          <SkeletonLoader.Line width="72px" />
        </div>
      ))}

      <div className={`${styles.row} mb-16`}>
        <SkeletonLoader.Line width="96px" />
        <SkeletonLoader.Line width="315px" />
      </div>

      <SkeletonLoader.Block
        borderRadius="4px"
        className={`${styles.block} mb-16`}
        height="80px"
        width="504px"
      />

      <div className={`${styles.row} ${styles['mb-28']}`}>
        <SkeletonLoader.Line width="96px" />
        <div className={styles.fnci}>
          <SkeletonLoader.Line className="mb-8" width="96px" />
          <SkeletonLoader.Line width="184px" />
        </div>
      </div>

      <div className={styles.row}>
        <SkeletonLoader.Line width="96px" />
        <SkeletonLoader.Line width="96px" />
      </div>
    </div>
  );
}
