/* eslint-disable react/no-array-index-key -- The list is static and not meant to change dynamically */
import { type ReactNode } from 'react';
import { IconCheckmarkOutlined } from '@repo/monochrome-icons';
import type { ChooseCardLayoutFeaturesProps } from 'qonto/react/cards/components/flows/choose-card-layout/internal/choose-card-layout-props.interface';
import styles from './features.strict-module.css';

export type Feature = string | ReactNode;

interface FeaturesProps {
  features: Feature[];
  layoutFeaturesProps?: ChooseCardLayoutFeaturesProps;
}

export function Features({ features, layoutFeaturesProps, ...props }: FeaturesProps): ReactNode {
  return (
    <ul
      className={styles.list}
      ref={layoutFeaturesProps?.ref}
      style={{
        minHeight: layoutFeaturesProps?.minHeight,
      }}
      {...props}
    >
      {features.map((content, index) => {
        return (
          <li className={styles.option} data-test-card-feature={index} key={index}>
            <IconCheckmarkOutlined aria-hidden="true" className={styles.checkmark} />
            <span>{content}</span>
          </li>
        );
      })}
    </ul>
  );
}
