import React, { useEffect, useState, type ReactNode } from 'react';
import { Popup } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import { Link } from '@qonto/react-migration-toolkit/react/components';
import { useSegment } from '@repo/poly-hooks/segment';
import ENV from 'qonto/config/environment';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { E_SIGNATURE_PROMOTIONAL_POPUP_STORAGE_KEY } from 'qonto/react/receivable-invoices/constants';

const isTest = ENV.environment === 'test';

const popupDelay = isTest ? 0 : 2000;

export function ESignaturePromotionalPopup(): ReactNode {
  const [isOpen, setIsOpen] = useState(false);
  const segment = useSegment();
  const { formatMessage } = useIntl();
  const { organization } = useOrganizationManager();

  useEffect(() => {
    if (safeLocalStorage.getItem(E_SIGNATURE_PROMOTIONAL_POPUP_STORAGE_KEY) === 'true') {
      return;
    }

    const timeout = setTimeout(() => {
      setIsOpen(true);
      segment.track('discovery_promotional-pop-up_displayed', { feature: 'quotes-signature' });
    }, popupDelay);

    return () => {
      clearTimeout(timeout);
    };
  }, [segment]);

  const handleCancel = (): void => {
    segment.track('discovery_promotional-pop-up_closed', { feature: 'quotes-signature' });

    safeLocalStorage.setItem(E_SIGNATURE_PROMOTIONAL_POPUP_STORAGE_KEY, 'true');

    setIsOpen(false);
  };

  const handleConfirm = (): void => {
    safeLocalStorage.setItem(E_SIGNATURE_PROMOTIONAL_POPUP_STORAGE_KEY, 'true');

    segment.track('discovery_promotional-pop-up_clicked', {
      feature: 'quotes-signature',
    });
  };

  // react-aria modal escapes the test container, we render it where it can find the modal (i.e. <main> component)
  const TEST_PORTAL_CONTAINER =
    document.getElementById('ember-testing') ?? document.querySelector('main') ?? undefined;

  return (
    <Popup.Root
      data-test-id="quotes.e-signature.promotional-popup"
      isOpen={isOpen}
      portalContainer={isTest ? TEST_PORTAL_CONTAINER : undefined}
    >
      <Popup.CloseButton onPress={handleCancel} />
      <Popup.Illustration isPromotional>
        <img
          alt=""
          data-test-id="quotes.e-signature.promotional-popup-illustration"
          src="/illustrations/e-signature-promotional-popup/ill_pop_general_electronic_signature_mustard.svg"
        />
      </Popup.Illustration>
      <Popup.Header>
        <Popup.Title>
          {formatMessage({ id: 'quotes.e-signature.promotional-popup.title' })}
        </Popup.Title>
      </Popup.Header>
      <Popup.Subtitle>
        {formatMessage({ id: 'quotes.e-signature.promotional-popup.body' })}
      </Popup.Subtitle>
      <Popup.Footer>
        <Link
          aria-label={formatMessage({ id: 'btn.confirm' })}
          className="btn btn--primary"
          data-test-id="modal-cta-button"
          onClick={handleConfirm}
          to={`/organizations/${organization.slug}/quotes/new`}
        >
          {formatMessage({ id: 'quotes.e-signature.promotional-popup.cta' })}
        </Link>
      </Popup.Footer>
    </Popup.Root>
  );
}
