import { type ReactNode } from 'react';
import cx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { SortIcon } from 'qonto/react/components/paginated-table';
import { DEFAULT_SORT_ORDER, SORT_ORDER } from 'qonto/constants/sort';
import styles from './header.strict-module.css';

type SortField = 'tax_code' | 'execution_date' | 'amount';
type SortOrder = (typeof SORT_ORDER)[keyof typeof SORT_ORDER];
type SortFull = `${SortField}:${SortOrder}`;

interface HeaderProps {
  isCompleted?: boolean;
  sortBy?: SortFull;
  onSortByChanged?: (sort: SortFull) => void;
  shouldAnimateColumns?: boolean;
}

export function Header({
  isCompleted,
  sortBy,
  onSortByChanged,
  shouldAnimateColumns,
}: HeaderProps): ReactNode {
  const sortField = sortBy?.split(':')[0] as SortField;
  const sortOrder = sortBy?.split(':')[1] || DEFAULT_SORT_ORDER;
  const isAscending = sortOrder === SORT_ORDER.ASC;

  const getAriaSort = (columnField: string): 'none' | 'ascending' | 'descending' => {
    const isCurrentFieldActive = columnField === sortField;
    if (!isCurrentFieldActive) return 'none';
    return sortOrder === SORT_ORDER.ASC ? 'ascending' : 'descending';
  };

  const handleSortBy = (field: SortField): void => {
    let currentSortOrder: SortOrder = DEFAULT_SORT_ORDER;

    if (field === sortField) {
      currentSortOrder = sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;
    }

    onSortByChanged?.(`${field}:${currentSortOrder}`);
  };

  return (
    <tr
      className={cx(shouldAnimateColumns && styles['header-row-animated'])}
      data-test-nrc-table-header
    >
      <th
        aria-sort={getAriaSort('tax_code')}
        className={cx(
          styles['header-cell'],
          styles['header-cell-sortable'],
          styles['header-cell-name']
        )}
        data-test-nrc-table-header-form
      >
        <button
          className={cx(
            styles['header-content'],
            sortField === 'tax_code' && styles['header-content-active']
          )}
          onClick={() => {
            handleSortBy('tax_code');
          }}
          type="button"
        >
          <FormattedMessage id="aeat.table-header.form" />
          <SortIcon isActive={sortField === 'tax_code'} isAscending={isAscending} />
        </button>
      </th>

      <th
        aria-sort={getAriaSort('execution_date')}
        className={cx(styles['header-cell'], styles['header-cell-sortable'])}
        data-test-nrc-table-header-expiration-date
      >
        <button
          className={cx(
            styles['header-content'],
            sortField === 'execution_date' && styles['header-content-active']
          )}
          onClick={() => {
            handleSortBy('execution_date');
          }}
          type="button"
        >
          <FormattedMessage
            id={isCompleted ? 'aeat.table-header.payment-date' : 'aeat.table-header.due-date'}
          />
          <SortIcon isActive={sortField === 'execution_date'} isAscending={isAscending} />
        </button>
      </th>

      <th className={styles['header-cell']} data-test-nrc-table-header-status>
        <FormattedMessage id="aeat.table-header.status" />
      </th>

      <th
        aria-sort={getAriaSort('amount')}
        className={cx(styles['header-cell'], styles['header-cell-sortable'])}
        data-test-nrc-table-header-amount
      >
        <button
          className={cx(
            styles['header-content'],
            sortField === 'amount' && styles['header-content-active']
          )}
          onClick={() => {
            handleSortBy('amount');
          }}
          type="button"
        >
          <FormattedMessage id="aeat.table-header.amount" />
          <SortIcon isActive={sortField === 'amount'} isAscending={isAscending} />
        </button>
      </th>
    </tr>
  );
}
