import type { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'clsx';
import { useOrganizationNavigation } from 'qonto/react/shared/hooks/use-organization-navigation';
import { AiLoadingState } from '../ai-loading-state';
import styles from './loading-screen.strict-module.css';

export function LoadingScreen(): ReactNode {
  const { formatMessage } = useIntl();
  const organizationNavigation = useOrganizationNavigation();

  const steps = [
    { label: formatMessage({ id: 'cash-flow.first-time-experience.checkout.progress.check-1' }) },
    { label: formatMessage({ id: 'cash-flow.first-time-experience.checkout.progress.check-2' }) },
    { label: formatMessage({ id: 'cash-flow.first-time-experience.checkout.progress.check-3' }) },
    { label: formatMessage({ id: 'cash-flow.first-time-experience.checkout.progress.check-4' }) },
  ];

  const onLoadingCompletedDelay = 2000;

  const onLoadingCompleted = (): void => {
    void organizationNavigation('/forecast');
  };

  return (
    <section className={styles.sectionWrapper} data-test-loading>
      <h1 className="title-1 mb-16">
        <FormattedMessage id="cash-flow.first-time-experience.checkout.title" />
      </h1>
      <p className={cx('body-1', 'mb-32', styles.subtitle)}>
        <FormattedMessage id="cash-flow.first-time-experience.checkout.subtitle" />
      </p>
      <AiLoadingState
        data-test-loading-state=""
        onLoadingCompleted={onLoadingCompleted}
        onLoadingCompletedDelay={onLoadingCompletedDelay}
        progressBarLabel={formatMessage({
          id: 'cash-flow.first-time-experience.checkout.progress.title',
        })}
        steps={steps}
      />
    </section>
  );
}
