import { Description, Label, NumberField } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import {
  ForecastEntryVarianceDropdown,
  type ForecastEntryVarianceDropdownValue,
} from './forecast-entry-variance-dropdown';
import styles from './forecast-entry-edit-variance.strict-module.css';

interface ForecastEntryEditVarianceProps {
  value: number;
  type: ForecastEntryVarianceDropdownValue;
  onChangeValue: (value: string | null) => void;
  onChangeType: (type: ForecastEntryVarianceDropdownValue) => void;
  currencyCode?: string;
  className?: string;
}

export function ForecastEntryEditVariance({
  value,
  type,
  onChangeValue,
  onChangeType,
  currencyCode = 'EUR',
  className,
}: ForecastEntryEditVarianceProps): React.ReactNode {
  const { formatMessage } = useIntl();
  return (
    <div className={className}>
      <Label>
        {formatMessage({
          id: 'cashflow.side-panel.forecast.edit.formula.amount-variance.label',
        })}
      </Label>
      <div className={styles['variance-amount-wrapper']}>
        <NumberField
          className={styles['variance-amount']}
          data-testid="forecast-entry-variance-amount"
          label="" // Do not use native label to avoid extending the width of the container
          onChange={onChangeValue}
          placeholder="0"
          value={value}
        />
        <ForecastEntryVarianceDropdown
          currencyCode={currencyCode}
          onSelect={onChangeType}
          value={type}
        />
      </div>
      <Description>
        {formatMessage({
          id: 'cashflow.side-panel.forecast.edit.formula.amount-variance.text-helper',
        })}
      </Description>
    </div>
  );
}
