/* eslint-disable */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Any: { input: any; output: any };
};

export enum ActivityTag {
  Atm = 'ATM',
  DirectDebitHold = 'DIRECT_DEBIT_HOLD',
  Fallback = 'FALLBACK',
  Fees = 'FEES',
  Finance = 'FINANCE',
  FoodAndGrocery = 'FOOD_AND_GROCERY',
  GasStation = 'GAS_STATION',
  HardwareAndEquipment = 'HARDWARE_AND_EQUIPMENT',
  HotelAndLodging = 'HOTEL_AND_LODGING',
  Insurance = 'INSURANCE',
  ItAndElectronics = 'IT_AND_ELECTRONICS',
  LegalAndAccounting = 'LEGAL_AND_ACCOUNTING',
  Logistics = 'LOGISTICS',
  Manufacturing = 'MANUFACTURING',
  Marketing = 'MARKETING',
  OfficeRental = 'OFFICE_RENTAL',
  OfficeSupply = 'OFFICE_SUPPLY',
  OnlineService = 'ONLINE_SERVICE',
  OtherExpense = 'OTHER_EXPENSE',
  OtherIncome = 'OTHER_INCOME',
  OtherService = 'OTHER_SERVICE',
  Pending = 'PENDING',
  Refund = 'REFUND',
  RestaurantAndBar = 'RESTAURANT_AND_BAR',
  Salary = 'SALARY',
  Sales = 'SALES',
  Subscription = 'SUBSCRIPTION',
  Tax = 'TAX',
  Transport = 'TRANSPORT',
  TreasuryAndInterco = 'TREASURY_AND_INTERCO',
  Utility = 'UTILITY',
  Voucher = 'VOUCHER',
}

export type Amount = {
  __typename?: 'Amount';
  currency: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type AttachedFile = {
  __typename?: 'AttachedFile';
  contentType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  size: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type Attachment = {
  __typename?: 'Attachment';
  createdAt: Scalars['String']['output'];
  downloadUrl: Scalars['String']['output'];
  file?: Maybe<AttachedFile>;
  id: Scalars['ID']['output'];
  probativeAttachment?: Maybe<ProbativeAttachment>;
  slug: Scalars['String']['output'];
  thumbnail?: Maybe<Thumbnail>;
};

export enum AuthorizationType {
  Full = 'FULL',
  Remaining = 'REMAINING',
}

export type AvatarFile = {
  __typename?: 'AvatarFile';
  url: Scalars['String']['output'];
};

export type BankAccount = {
  __typename?: 'BankAccount';
  bic: Scalars['String']['output'];
  iban: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  logo?: Maybe<Logo>;
  name: Scalars['String']['output'];
};

export type Beneficiary = {
  __typename?: 'Beneficiary';
  accountNumber?: Maybe<Scalars['String']['output']>;
  accountType: Scalars['String']['output'];
  activityTag: Scalars['String']['output'];
  address?: Maybe<BeneficiaryAddress>;
  avatar: File;
  avatarThumb: File;
  b2b: Scalars['Boolean']['output'];
  bankName?: Maybe<Scalars['String']['output']>;
  bic?: Maybe<Scalars['String']['output']>;
  core: Scalars['Boolean']['output'];
  country?: Maybe<Scalars['String']['output']>;
  creditBankAccountId?: Maybe<Scalars['String']['output']>;
  creditorIdentifier?: Maybe<Scalars['String']['output']>;
  currency: Scalars['String']['output'];
  defaultAvatar: File;
  defaultAvatarThumb: File;
  email?: Maybe<Scalars['String']['output']>;
  fx: Scalars['Boolean']['output'];
  hidden: Scalars['Boolean']['output'];
  iban?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  labelIds: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  qontoBankAccount: Scalars['Boolean']['output'];
  routingNumber?: Maybe<Scalars['String']['output']>;
  sct: Scalars['Boolean']['output'];
  sortCode?: Maybe<Scalars['String']['output']>;
  transferTransactionsCount?: Maybe<Scalars['Int']['output']>;
  trusted: Scalars['Boolean']['output'];
  vatRate?: Maybe<Scalars['Float']['output']>;
};

export type BeneficiaryAddress = {
  __typename?: 'BeneficiaryAddress';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  firstLine?: Maybe<Scalars['String']['output']>;
  postCode?: Maybe<Scalars['String']['output']>;
};

export type BillingTransfer = {
  __typename?: 'BillingTransfer';
  amount: Amount;
  enrichmentData: BillingTransferEnrichmentData;
  id: Scalars['ID']['output'];
  movementId: Scalars['ID']['output'];
  operationSubtype: Scalars['String']['output'];
  operationType: Scalars['String']['output'];
  reference: Scalars['String']['output'];
  totalAmount: Amount;
  vatAmount: Amount;
  vatRate: Scalars['Float']['output'];
};

export type BillingTransferEnrichmentData = {
  __typename?: 'BillingTransferEnrichmentData';
  isRecurring: Scalars['Boolean']['output'];
  logo?: Maybe<Logo>;
  productName?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  recurrence?: Maybe<Scalars['String']['output']>;
};

export type Card = {
  __typename?: 'Card';
  activeDays: Array<Scalars['Int']['output']>;
  atmDailyLimit: Scalars['Float']['output'];
  atmDailyLimitOption: Scalars['Boolean']['output'];
  atmDailySpent: Scalars['Float']['output'];
  atmMonthlyLimit: Scalars['Float']['output'];
  atmMonthlySpent: Scalars['Float']['output'];
  atmOption: Scalars['Boolean']['output'];
  bankAccountId?: Maybe<Scalars['String']['output']>;
  cardDesign?: Maybe<Scalars['String']['output']>;
  cardLevel: CardLevel;
  cardType: CardType;
  categoryTags: Array<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  cvv: Scalars['String']['output'];
  discardOn?: Maybe<Scalars['String']['output']>;
  eligibleForRenewal: Scalars['Boolean']['output'];
  eligibleForUpsell: Scalars['Boolean']['output'];
  embossedName?: Maybe<Scalars['String']['output']>;
  expDate?: Maybe<Scalars['String']['output']>;
  expMonth?: Maybe<Scalars['String']['output']>;
  expYear?: Maybe<Scalars['String']['output']>;
  expirationDate?: Maybe<Scalars['String']['output']>;
  expirationDateShort?: Maybe<Scalars['String']['output']>;
  foreignOption: Scalars['Boolean']['output'];
  forwardedAt?: Maybe<Scalars['String']['output']>;
  hadOperation: Scalars['Boolean']['output'];
  hadPinOperation: Scalars['Boolean']['output'];
  holder?: Maybe<Membership>;
  holderId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isQcp: Scalars['Boolean']['output'];
  last4: Scalars['String']['output'];
  lastActivityAt?: Maybe<Scalars['String']['output']>;
  maskPan?: Maybe<Scalars['String']['output']>;
  nfcOption: Scalars['Boolean']['output'];
  nickname: Scalars['String']['output'];
  onlineOption: Scalars['Boolean']['output'];
  organizationId?: Maybe<Scalars['String']['output']>;
  parentCardSummary?: Maybe<ParentCardSummary>;
  paymentDailyLimit: Scalars['Int']['output'];
  paymentDailyLimitOption: Scalars['Boolean']['output'];
  paymentDailySpent: Scalars['Float']['output'];
  paymentLifespanLimit?: Maybe<Scalars['Int']['output']>;
  paymentLifespanSpent: Scalars['Float']['output'];
  paymentMonthlyLimit: Scalars['Int']['output'];
  paymentMonthlySpent: Scalars['Float']['output'];
  paymentTransactionLimit: Scalars['Int']['output'];
  paymentTransactionLimitOption: Scalars['Boolean']['output'];
  physical: Scalars['Boolean']['output'];
  pinSet: Scalars['Boolean']['output'];
  preExpiresAt?: Maybe<Scalars['String']['output']>;
  renewal: Scalars['Boolean']['output'];
  renewed: Scalars['Boolean']['output'];
  reordered: Scalars['Boolean']['output'];
  sequentialId?: Maybe<Scalars['Int']['output']>;
  shipToBusiness: Scalars['Boolean']['output'];
  shippedAt?: Maybe<Scalars['String']['output']>;
  status: CardStatus;
  updatedAt: Scalars['String']['output'];
  upsell: Scalars['Boolean']['output'];
  upsold: Scalars['Boolean']['output'];
};

export type CardAcquirerPayout = {
  __typename?: 'CardAcquirerPayout';
  amount: Amount;
  bookTransferMovementId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  reference: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export enum CardLevel {
  Advertising = 'ADVERTISING',
  Flash = 'FLASH',
  Metal = 'METAL',
  Plus = 'PLUS',
  Standard = 'STANDARD',
  Unknown = 'UNKNOWN',
  Virtual = 'VIRTUAL',
}

export enum CardStatus {
  Abusive = 'ABUSIVE',
  Deleted = 'DELETED',
  Discarded = 'DISCARDED',
  Expired = 'EXPIRED',
  Live = 'LIVE',
  Lost = 'LOST',
  OnHold = 'ON_HOLD',
  Ordered = 'ORDERED',
  OrderCanceled = 'ORDER_CANCELED',
  Paused = 'PAUSED',
  Pending = 'PENDING',
  PinBlocked = 'PIN_BLOCKED',
  PreExpired = 'PRE_EXPIRED',
  Received = 'RECEIVED',
  Shipped = 'SHIPPED',
  ShippedLost = 'SHIPPED_LOST',
  Stolen = 'STOLEN',
  Unknown = 'UNKNOWN',
}

export type CardTransactionStatus = {
  __typename?: 'CardTransactionStatus';
  computedStatus: Scalars['String']['output'];
  movementId: Scalars['ID']['output'];
};

export enum CardType {
  Debit = 'DEBIT',
  Prepaid = 'PREPAID',
  Unknown = 'UNKNOWN',
}

export type CashFlowCategory = {
  __typename?: 'CashFlowCategory';
  colorKey: Scalars['String']['output'];
  iconKey: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  nameKey?: Maybe<Scalars['String']['output']>;
  transactionId: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type CategoryAssignment = {
  __typename?: 'CategoryAssignment';
  id: Scalars['String']['output'];
  source: Scalars['String']['output'];
};

export type Check = {
  __typename?: 'Check';
  amount: Amount;
  canceledAt?: Maybe<Scalars['String']['output']>;
  cmc7: Cmc7;
  createdAt: Scalars['String']['output'];
  creditDelayDays: Scalars['String']['output'];
  creditExpectedAt?: Maybe<Scalars['String']['output']>;
  declinedAt?: Maybe<Scalars['String']['output']>;
  declinedReason?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  emittedDate?: Maybe<Scalars['String']['output']>;
  emitterName?: Maybe<Scalars['String']['output']>;
  enrichmentData?: Maybe<EnrichmentData>;
  id: Scalars['ID']['output'];
  initiatorId?: Maybe<Scalars['ID']['output']>;
  movementId: Scalars['ID']['output'];
  receivedAt?: Maybe<Scalars['String']['output']>;
  refundedAt?: Maybe<Scalars['String']['output']>;
  rlmc?: Maybe<Scalars['String']['output']>;
  shippingAddress: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  validatedAt?: Maybe<Scalars['String']['output']>;
};

export type Claim = {
  __typename?: 'Claim';
  id: Scalars['ID']['output'];
  status: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type Cmc7 = {
  __typename?: 'Cmc7';
  a: Scalars['String']['output'];
  b: Scalars['String']['output'];
  c: Scalars['String']['output'];
};

export type Counterparty = {
  __typename?: 'Counterparty';
  accountNumber: Scalars['String']['output'];
  bic: Scalars['String']['output'];
  iban: Scalars['String']['output'];
};

export enum DeclinedReason {
  AccountInactive = 'ACCOUNT_INACTIVE',
  CannotVerifyPin = 'CANNOT_VERIFY_PIN',
  CardholderToContactIssuer = 'CARDHOLDER_TO_CONTACT_ISSUER',
  CustomerCancellation = 'CUSTOMER_CANCELLATION',
  DirectDebitPostponed = 'DIRECT_DEBIT_POSTPONED',
  DoNotHonor = 'DO_NOT_HONOR',
  ExceedsWithdrawalAmountLimit = 'EXCEEDS_WITHDRAWAL_AMOUNT_LIMIT',
  ExceedsWithdrawalFrequencyLimit = 'EXCEEDS_WITHDRAWAL_FREQUENCY_LIMIT',
  ExpiredCard = 'EXPIRED_CARD',
  IncorrectPin = 'INCORRECT_PIN',
  InsufficientFunds = 'INSUFFICIENT_FUNDS',
  InvalidAmount = 'INVALID_AMOUNT',
  InvalidCardNumber = 'INVALID_CARD_NUMBER',
  InvalidMerchant = 'INVALID_MERCHANT',
  IssuerOrSwitchIsInoperative = 'ISSUER_OR_SWITCH_IS_INOPERATIVE',
  LostCard = 'LOST_CARD',
  MerchantNameAndCountryUnknown = 'MERCHANT_NAME_AND_COUNTRY_UNKNOWN',
  NotPermittedToCardholder = 'NOT_PERMITTED_TO_CARDHOLDER',
  NotPermittedToTerminal = 'NOT_PERMITTED_TO_TERMINAL',
  OpsCanceled = 'OPS_CANCELED',
  PinTriesExceeded = 'PIN_TRIES_EXCEEDED',
  ResponseReceivedTooLate = 'RESPONSE_RECEIVED_TOO_LATE',
  RestrictedCard = 'RESTRICTED_CARD',
  SecurityViolation = 'SECURITY_VIOLATION',
  StolenCard = 'STOLEN_CARD',
  SystemMalfunction = 'SYSTEM_MALFUNCTION',
  Timeout = 'TIMEOUT',
  UnableToRouteTransaction = 'UNABLE_TO_ROUTE_TRANSACTION',
  UserCanceled = 'USER_CANCELED',
}

export type DirectDebit = {
  __typename?: 'DirectDebit';
  amount: Scalars['Float']['output'];
  amountCents: Scalars['Int']['output'];
  amountCurrency: Scalars['String']['output'];
  emitterBic?: Maybe<Scalars['String']['output']>;
  emitterIban?: Maybe<Scalars['String']['output']>;
  emitterName?: Maybe<Scalars['String']['output']>;
  enrichmentData?: Maybe<EnrichmentData>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  executionDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  reference?: Maybe<Scalars['String']['output']>;
  refundRequestedAt?: Maybe<Scalars['String']['output']>;
  refundable: Scalars['Boolean']['output'];
  refundedAt?: Maybe<Scalars['String']['output']>;
  sepaMandateId?: Maybe<Scalars['ID']['output']>;
  sequentialId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type DirectDebitCollection = {
  __typename?: 'DirectDebitCollection';
  amount: Amount;
  collectionDate: Scalars['String']['output'];
  creditorSchemeId: Scalars['String']['output'];
  debitorBic?: Maybe<Scalars['String']['output']>;
  debitorIban: Scalars['String']['output'];
  debitorName: Scalars['String']['output'];
  executedAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  initiatorId: Scalars['ID']['output'];
  onHoldAmount?: Maybe<Amount>;
  onHoldRate?: Maybe<Scalars['String']['output']>;
  onHoldReleaseDate?: Maybe<Scalars['String']['output']>;
  rTransactionExecutedAt?: Maybe<Scalars['String']['output']>;
  rTransactionType?: Maybe<Scalars['String']['output']>;
  recurrenceType: Scalars['String']['output'];
  reference?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  statusReason?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  uniqueMandateReference: Scalars['String']['output'];
};

export type DirectDebitHold = {
  __typename?: 'DirectDebitHold';
  amount: Amount;
  debitorName: Scalars['String']['output'];
  executionDate: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  onHoldRate: Scalars['String']['output'];
  releaseDate: Scalars['String']['output'];
  releasedAt?: Maybe<Scalars['String']['output']>;
};

export type Dispute = {
  __typename?: 'Dispute';
  claim: Claim;
  transactionId: Scalars['ID']['output'];
};

export type EnrichmentData = {
  __typename?: 'EnrichmentData';
  logo?: Maybe<Logo>;
};

export type F24Payment = {
  __typename?: 'F24Payment';
  id: Scalars['ID']['output'];
  proxied: Scalars['Boolean']['output'];
};

export type File = {
  __typename?: 'File';
  fileUrl: Scalars['String']['output'];
};

export type FilterGroupOne = {
  conditional?: InputMaybe<Scalars['String']['input']>;
  expressions?: InputMaybe<Array<FilterGroupOrExpressionOne>>;
};

export type FilterGroupOrExpressionOne = {
  conditional?: InputMaybe<Scalars['String']['input']>;
  expressions?: InputMaybe<Array<FilterGroupOrExpressionTwo>>;
  operator?: InputMaybe<Scalars['String']['input']>;
  property?: InputMaybe<Scalars['String']['input']>;
  values?: InputMaybe<Array<Scalars['Any']['input']>>;
};

export type FilterGroupOrExpressionTwo = {
  conditional?: InputMaybe<Scalars['String']['input']>;
  expressions?: InputMaybe<Array<FilterGroupThree>>;
  operator?: InputMaybe<Scalars['String']['input']>;
  property?: InputMaybe<Scalars['String']['input']>;
  values?: InputMaybe<Array<Scalars['Any']['input']>>;
};

export type FilterGroupThree = {
  operator?: InputMaybe<Scalars['String']['input']>;
  property?: InputMaybe<Scalars['String']['input']>;
  values: Array<Scalars['Any']['input']>;
};

export type FinancingInstallment = {
  __typename?: 'FinancingInstallment';
  currentInstallmentNumber: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  movementId: Scalars['ID']['output'];
  totalInstallmentsNumber: Scalars['Int']['output'];
};

export type Income = {
  __typename?: 'Income';
  amount: Scalars['Float']['output'];
  amountCents: Scalars['Int']['output'];
  amountCurrency: Scalars['String']['output'];
  bankAccountId: Scalars['String']['output'];
  bic: Scalars['String']['output'];
  emitterBic: Scalars['String']['output'];
  emitterIban: Scalars['String']['output'];
  emitterIdentification: Scalars['String']['output'];
  emitterName: Scalars['String']['output'];
  enrichmentData?: Maybe<EnrichmentData>;
  iban: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  organizationId: Scalars['String']['output'];
  reference: Scalars['String']['output'];
  sequentialId?: Maybe<Scalars['Int']['output']>;
  slug: Scalars['String']['output'];
};

export type Label = {
  __typename?: 'Label';
  id: Scalars['ID']['output'];
  labelList: LabelList;
  listId: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type LabelList = {
  __typename?: 'LabelList';
  color: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  rank: Scalars['Int']['output'];
};

export type Logo = {
  __typename?: 'Logo';
  medium: Scalars['String']['output'];
  small: Scalars['String']['output'];
};

export type Membership = {
  __typename?: 'Membership';
  avatar: AvatarFile;
  avatarThumb: AvatarFile;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  role: Scalars['String']['output'];
  team?: Maybe<Team>;
  teamId: Scalars['String']['output'];
};

export type Message = {
  __typename?: 'Message';
  author?: Maybe<Participant>;
  content: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type NrcPayment = {
  __typename?: 'NrcPayment';
  id: Scalars['ID']['output'];
  movementId: Scalars['ID']['output'];
  nrc: Scalars['String']['output'];
  taxCode: Scalars['String']['output'];
  taxDescription: Scalars['String']['output'];
};

export enum OperationMethod {
  Biller = 'BILLER',
  Card = 'CARD',
  CardAcquirerPayout = 'CARD_ACQUIRER_PAYOUT',
  Cheque = 'CHEQUE',
  DirectDebit = 'DIRECT_DEBIT',
  DirectDebitHold = 'DIRECT_DEBIT_HOLD',
  Other = 'OTHER',
  PagopaPayment = 'PAGOPA_PAYMENT',
  PayLater = 'PAY_LATER',
  RibaPayment = 'RIBA_PAYMENT',
  Tax = 'TAX',
  Transfer = 'TRANSFER',
}

export enum OperationType {
  Biller = 'BILLER',
  Card = 'CARD',
  CardAcquirerPayout = 'CARD_ACQUIRER_PAYOUT',
  Cheque = 'CHEQUE',
  DirectDebit = 'DIRECT_DEBIT',
  DirectDebitCollection = 'DIRECT_DEBIT_COLLECTION',
  DirectDebitHold = 'DIRECT_DEBIT_HOLD',
  F24 = 'F24',
  FinancingInstallment = 'FINANCING_INSTALLMENT',
  Income = 'INCOME',
  NrcPayment = 'NRC_PAYMENT',
  Other = 'OTHER',
  PagopaPayment = 'PAGOPA_PAYMENT',
  PayLater = 'PAY_LATER',
  Recall = 'RECALL',
  RibaPayment = 'RIBA_PAYMENT',
  SwiftIncome = 'SWIFT_INCOME',
  Transfer = 'TRANSFER',
}

export type Pagination = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};

export type PagopaPayment = {
  __typename?: 'PagopaPayment';
  amount: Amount;
  creditorName?: Maybe<Scalars['String']['output']>;
  fiscalCode?: Maybe<Scalars['String']['output']>;
  iuv?: Maybe<Scalars['String']['output']>;
  movementId: Scalars['ID']['output'];
  noticeNumber?: Maybe<Scalars['String']['output']>;
};

export type ParentCardSummary = {
  __typename?: 'ParentCardSummary';
  id: Scalars['ID']['output'];
  last4: Scalars['String']['output'];
};

export type Participant = {
  __typename?: 'Participant';
  id: Scalars['ID']['output'];
  lastReadAt?: Maybe<Scalars['String']['output']>;
  membership: Membership;
  membershipId: Scalars['ID']['output'];
};

export type PayableInvoice = {
  __typename?: 'PayableInvoice';
  attachmentId: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  invoiceNumber?: Maybe<Scalars['String']['output']>;
};

export type ProbativeAttachment = {
  __typename?: 'ProbativeAttachment';
  downloadUrl: Scalars['String']['output'];
  fileContentType: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  fileSize: Scalars['String']['output'];
  fileUrl: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  searchThreads: Array<Thread>;
  searchTransactions: SearchTransactionsResult;
};

export type QuerySearchThreadsArgs = {
  input?: InputMaybe<ThreadsSearch>;
};

export type QuerySearchTransactionsArgs = {
  input?: InputMaybe<TransactionsSearch>;
};

export type ReceivableInvoice = {
  __typename?: 'ReceivableInvoice';
  attachmentId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  invoiceNumber: Scalars['String']['output'];
};

export type RibaPayment = {
  __typename?: 'RibaPayment';
  id: Scalars['ID']['output'];
  movementId: Scalars['ID']['output'];
  reference: Scalars['String']['output'];
  ribaNumber: Scalars['String']['output'];
};

export type SearchTransactionsMeta = {
  __typename?: 'SearchTransactionsMeta';
  currentPage: Scalars['Int']['output'];
  cursor?: Maybe<Scalars['String']['output']>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  perPage: Scalars['Int']['output'];
  prevPage?: Maybe<Scalars['Int']['output']>;
  totalCount: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type SearchTransactionsResult = {
  __typename?: 'SearchTransactionsResult';
  meta: SearchTransactionsMeta;
  transactions: Array<Transaction>;
};

export enum Side {
  Credit = 'CREDIT',
  Debit = 'DEBIT',
}

export type Sort = {
  direction?: InputMaybe<Scalars['String']['input']>;
  property?: InputMaybe<Scalars['String']['input']>;
};

export enum Status {
  Completed = 'COMPLETED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
  Reversed = 'REVERSED',
}

export type StatusHistory = {
  __typename?: 'StatusHistory';
  createdAt: Scalars['String']['output'];
  status: StatusHistoryStatus;
};

export enum StatusHistoryStatus {
  Authorized = 'AUTHORIZED',
  Settled = 'SETTLED',
}

export enum SubjectType {
  BillingTransfer = 'BILLING_TRANSFER',
  Card = 'CARD',
  CardAcquirerPayout = 'CARD_ACQUIRER_PAYOUT',
  Check = 'CHECK',
  DirectDebit = 'DIRECT_DEBIT',
  DirectDebitCollection = 'DIRECT_DEBIT_COLLECTION',
  DirectDebitHold = 'DIRECT_DEBIT_HOLD',
  F24Payment = 'F24_PAYMENT',
  FinancingIncome = 'FINANCING_INCOME',
  FinancingInstallment = 'FINANCING_INSTALLMENT',
  Income = 'INCOME',
  NrcPayment = 'NRC_PAYMENT',
  PagopaPayment = 'PAGOPA_PAYMENT',
  SwiftIncome = 'SWIFT_INCOME',
  Transfer = 'TRANSFER',
  WalletToWallet = 'WALLET_TO_WALLET',
}

export type SwiftIncome = {
  __typename?: 'SwiftIncome';
  amount: Amount;
  emitterAccountIdentifier?: Maybe<Scalars['String']['output']>;
  emitterBankIdentifier?: Maybe<Scalars['String']['output']>;
  emitterNameAddress?: Maybe<Scalars['String']['output']>;
  enrichmentData?: Maybe<EnrichmentData>;
  exchangeRate?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  localAmount: Amount;
  reference?: Maybe<Scalars['String']['output']>;
};

export type Team = {
  __typename?: 'Team';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Thread = {
  __typename?: 'Thread';
  attachedToId: Scalars['ID']['output'];
  attachedToType: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  messages: Array<Message>;
  organizationId: Scalars['ID']['output'];
  participants: Array<Participant>;
};

export type ThreadsSearch = {
  attachedToIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Thumbnail = {
  __typename?: 'Thumbnail';
  fileUrl: Scalars['String']['output'];
};

export type Transaction = {
  __typename?: 'Transaction';
  activityTag?: Maybe<ActivityTag>;
  amount: Amount;
  attachmentIds: Array<Scalars['String']['output']>;
  attachmentLost: Scalars['Boolean']['output'];
  attachmentRequested: Scalars['Boolean']['output'];
  attachmentRequestedAt?: Maybe<Scalars['String']['output']>;
  attachmentRequestedById?: Maybe<Scalars['String']['output']>;
  attachmentRequired: Scalars['Boolean']['output'];
  attachmentSuggestionIds: Array<Scalars['String']['output']>;
  attachments: Array<Attachment>;
  authorizationType?: Maybe<AuthorizationType>;
  automaticallyAddedAttachmentIds: Array<Scalars['String']['output']>;
  bankAccount?: Maybe<BankAccount>;
  bankAccountId: Scalars['String']['output'];
  billingTransfer?: Maybe<BillingTransfer>;
  billingTransferId?: Maybe<Scalars['String']['output']>;
  card?: Maybe<Card>;
  cardId?: Maybe<Scalars['String']['output']>;
  cardTransactionMovementId?: Maybe<Scalars['String']['output']>;
  cardTransactionStatus?: Maybe<CardTransactionStatus>;
  category?: Maybe<CashFlowCategory>;
  categoryAssignment?: Maybe<CategoryAssignment>;
  check?: Maybe<Check>;
  checkId?: Maybe<Scalars['String']['output']>;
  cleanCounterpartyName?: Maybe<Scalars['String']['output']>;
  counterparty?: Maybe<Counterparty>;
  counterpartyName: Scalars['String']['output'];
  declinedReason?: Maybe<DeclinedReason>;
  description?: Maybe<Scalars['String']['output']>;
  directDebit?: Maybe<DirectDebit>;
  directDebitCollection?: Maybe<DirectDebitCollection>;
  directDebitCollectionId?: Maybe<Scalars['String']['output']>;
  directDebitHold?: Maybe<DirectDebitHold>;
  directDebitHoldId?: Maybe<Scalars['String']['output']>;
  directDebitId?: Maybe<Scalars['String']['output']>;
  displayAt: Scalars['String']['output'];
  disputes: Array<Dispute>;
  emittedAt: Scalars['String']['output'];
  enrichmentData: EnrichmentData;
  financingInstallment?: Maybe<FinancingInstallment>;
  financingInstallmentId?: Maybe<Scalars['String']['output']>;
  fx: Scalars['Boolean']['output'];
  fxPaymentPurpose?: Maybe<Scalars['String']['output']>;
  fxPaymentPurposeUnstructured?: Maybe<Scalars['String']['output']>;
  fxRate?: Maybe<Scalars['Float']['output']>;
  hasAttachment: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  income?: Maybe<Income>;
  incomeId?: Maybe<Scalars['String']['output']>;
  initiator?: Maybe<Membership>;
  initiatorId?: Maybe<Scalars['String']['output']>;
  isExternalTransaction: Scalars['Boolean']['output'];
  isProxied?: Maybe<Scalars['Boolean']['output']>;
  labelIds: Array<Scalars['String']['output']>;
  labels: Array<Label>;
  localAmount?: Maybe<Amount>;
  note?: Maybe<Scalars['String']['output']>;
  nrcPayment?: Maybe<NrcPayment>;
  nrcPaymentId?: Maybe<Scalars['String']['output']>;
  operationMethod: OperationMethod;
  operationType: OperationType;
  organizationId: Scalars['String']['output'];
  pagopaPayment?: Maybe<PagopaPayment>;
  pagopaPaymentId?: Maybe<Scalars['String']['output']>;
  payableInvoices: Array<PayableInvoice>;
  providerObjectId: Scalars['String']['output'];
  qualifiedForAccounting: Scalars['Boolean']['output'];
  rawCounterpartyName?: Maybe<Scalars['String']['output']>;
  receivableInvoices: Array<ReceivableInvoice>;
  ribaPayment?: Maybe<RibaPayment>;
  ribaPaymentId?: Maybe<Scalars['String']['output']>;
  settledAt?: Maybe<Scalars['String']['output']>;
  settledBalance?: Maybe<Amount>;
  side: Side;
  slug: Scalars['String']['output'];
  status: Status;
  statusHistory: Array<StatusHistory>;
  subjectId?: Maybe<Scalars['String']['output']>;
  subjectType?: Maybe<SubjectType>;
  swiftIncome?: Maybe<SwiftIncome>;
  swiftIncomeId?: Maybe<Scalars['String']['output']>;
  thread?: Maybe<Thread>;
  transfer?: Maybe<Transfer>;
  transferId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  vat: Vat;
  walletToWallet?: Maybe<WalletToWallet>;
  walletToWalletId?: Maybe<Scalars['String']['output']>;
};

export type TransactionsSearch = {
  bankAccountIds?: InputMaybe<Array<Scalars['String']['input']>>;
  filterGroup?: InputMaybe<FilterGroupOne>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Sort>;
};

export type Transfer = {
  __typename?: 'Transfer';
  amount: Scalars['Float']['output'];
  amountCents: Scalars['Int']['output'];
  amountCurrency: Scalars['String']['output'];
  attachmentIds: Array<Scalars['String']['output']>;
  attachmentVisible: Scalars['Boolean']['output'];
  beneficiary?: Maybe<Beneficiary>;
  beneficiaryId?: Maybe<Scalars['ID']['output']>;
  bic: Scalars['String']['output'];
  cancelable: Scalars['Boolean']['output'];
  clientsSortDateLast?: Maybe<Scalars['String']['output']>;
  clientsSortDateNext?: Maybe<Scalars['String']['output']>;
  counterparty?: Maybe<TransferCounterparty>;
  createdAt: Scalars['String']['output'];
  cutoffAt?: Maybe<Scalars['String']['output']>;
  declinedReason?: Maybe<Scalars['String']['output']>;
  editable: Scalars['Boolean']['output'];
  email?: Maybe<Scalars['String']['output']>;
  enrichmentData?: Maybe<EnrichmentData>;
  fx: Scalars['Boolean']['output'];
  fxPaymentPurpose?: Maybe<Scalars['String']['output']>;
  fxPaymentPurposeUnstructured?: Maybe<Scalars['String']['output']>;
  fxRate?: Maybe<Scalars['Float']['output']>;
  fxSettlementStatus?: Maybe<Scalars['String']['output']>;
  iban: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  initiatorId?: Maybe<Scalars['ID']['output']>;
  instant: Scalars['Boolean']['output'];
  internal: Scalars['Boolean']['output'];
  internalInOrganization: Scalars['Boolean']['output'];
  labelIds: Array<Scalars['String']['output']>;
  lastRecursionDate?: Maybe<Scalars['String']['output']>;
  localAmount: Scalars['Float']['output'];
  localAmountCents: Scalars['Int']['output'];
  localAmountCurrency: Scalars['String']['output'];
  nextRecursionDate?: Maybe<Scalars['String']['output']>;
  notEditableReason?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  noteVisible: Scalars['Boolean']['output'];
  notifyByEmail: Scalars['Boolean']['output'];
  operationType: Scalars['String']['output'];
  proofPdfUrl?: Maybe<Scalars['String']['output']>;
  qontoBankAccount: Scalars['Boolean']['output'];
  reference?: Maybe<Scalars['String']['output']>;
  scheduledDate?: Maybe<Scalars['String']['output']>;
  sequentialId?: Maybe<Scalars['Int']['output']>;
  settlementDate?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  standingEndingDate?: Maybe<Scalars['String']['output']>;
  standingFrequency?: Maybe<Scalars['String']['output']>;
  standingOn: Scalars['Int']['output'];
  status: Scalars['String']['output'];
  transactionId?: Maybe<Scalars['String']['output']>;
  vatAmount?: Maybe<Scalars['String']['output']>;
  vatRate?: Maybe<Scalars['Float']['output']>;
};

export type TransferCounterparty = {
  __typename?: 'TransferCounterparty';
  accountIdentifier?: Maybe<Scalars['String']['output']>;
  accountType?: Maybe<Scalars['String']['output']>;
  bankIdentifier?: Maybe<Scalars['String']['output']>;
  branchIdentifier?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Vat = {
  __typename?: 'Vat';
  items: Array<VatItem>;
  status?: Maybe<VatStatus>;
  totalAmount: Amount;
};

export type VatItem = {
  __typename?: 'VatItem';
  amount?: Maybe<Amount>;
  amountExcludingVat?: Maybe<Amount>;
  id: Scalars['String']['output'];
  rate?: Maybe<Scalars['Float']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  transactionId: Scalars['String']['output'];
};

export enum VatStatus {
  VatAuto = 'VAT_AUTO',
  VatConfirmed = 'VAT_CONFIRMED',
  VatDetecting = 'VAT_DETECTING',
  VatDetectionError = 'VAT_DETECTION_ERROR',
  VatInheritedFromBeneficiary = 'VAT_INHERITED_FROM_BENEFICIARY',
  VatManual = 'VAT_MANUAL',
  VatNotFound = 'VAT_NOT_FOUND',
  VatOverridden = 'VAT_OVERRIDDEN',
  VatSuggested = 'VAT_SUGGESTED',
}

export type WalletToWallet = {
  __typename?: 'WalletToWallet';
  amount: Scalars['Float']['output'];
  amountCents: Scalars['Int']['output'];
  amountCurrency: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  operationSubtype?: Maybe<Scalars['String']['output']>;
  operationType: Scalars['String']['output'];
  reference?: Maybe<Scalars['String']['output']>;
  vatAmount?: Maybe<Scalars['Float']['output']>;
  vatAmountCents?: Maybe<Scalars['Int']['output']>;
  vatRate?: Maybe<Scalars['Float']['output']>;
};

export type GetTransactionByIdQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  pagination: Pagination;
  sort: Sort;
  filterGroup?: InputMaybe<FilterGroupOne>;
  bankAccountIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type GetTransactionByIdQuery = {
  __typename?: 'Query';
  searchTransactions: {
    __typename?: 'SearchTransactionsResult';
    transactions: Array<{
      __typename?: 'Transaction';
      activityTag?: ActivityTag | null;
      attachmentLost: boolean;
      attachmentRequired: boolean;
      attachmentRequestedAt?: string | null;
      automaticallyAddedAttachmentIds: Array<string>;
      note?: string | null;
      qualifiedForAccounting: boolean;
      labels: Array<{
        __typename?: 'Label';
        id: string;
        name: string;
        listId: string;
        labelList: {
          __typename?: 'LabelList';
          id: string;
          name: string;
          color: string;
          rank: number;
        };
      }>;
      attachments: Array<{
        __typename?: 'Attachment';
        slug: string;
        id: string;
        downloadUrl: string;
        createdAt: string;
        thumbnail?: { __typename?: 'Thumbnail'; fileUrl: string } | null;
        file?: {
          __typename?: 'AttachedFile';
          name: string;
          contentType: string;
          size: string;
          url: string;
        } | null;
        probativeAttachment?: {
          __typename?: 'ProbativeAttachment';
          status: string;
          fileContentType: string;
          fileName: string;
          fileSize: string;
          fileUrl: string;
          downloadUrl: string;
        } | null;
      }>;
    }>;
  };
};

export type SearchTransactionsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  pagination: Pagination;
  sort: Sort;
  filterGroup?: InputMaybe<FilterGroupOne>;
  bankAccountIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type SearchTransactionsQuery = {
  __typename?: 'Query';
  searchTransactions: {
    __typename?: 'SearchTransactionsResult';
    meta: {
      __typename?: 'SearchTransactionsMeta';
      totalCount: number;
      totalPages: number;
      currentPage: number;
      perPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
    };
    transactions: Array<{
      __typename?: 'Transaction';
      id: string;
      activityTag?: ActivityTag | null;
      counterpartyName: string;
      emittedAt: string;
      fx: boolean;
      rawCounterpartyName?: string | null;
      operationMethod: OperationMethod;
      description?: string | null;
      note?: string | null;
      settledAt?: string | null;
      side: Side;
      slug: string;
      status: Status;
      subjectType?: SubjectType | null;
      qualifiedForAccounting: boolean;
      attachmentLost: boolean;
      attachmentRequired: boolean;
      attachmentIds: Array<string>;
      attachmentSuggestionIds: Array<string>;
      automaticallyAddedAttachmentIds: Array<string>;
      attachmentRequested: boolean;
      attachmentRequestedAt?: string | null;
      disputes: Array<{
        __typename?: 'Dispute';
        claim: { __typename?: 'Claim'; id: string; status: string; type: string };
      }>;
      amount: { __typename?: 'Amount'; value: string; currency: string };
      billingTransfer?: {
        __typename?: 'BillingTransfer';
        operationSubtype: string;
        operationType: string;
      } | null;
      categoryAssignment?: { __typename?: 'CategoryAssignment'; id: string; source: string } | null;
      initiator?: {
        __typename?: 'Membership';
        id: string;
        fullName: string;
        firstName: string;
        lastName: string;
        email: string;
        role: string;
        avatar: { __typename?: 'AvatarFile'; url: string };
        avatarThumb: { __typename?: 'AvatarFile'; url: string };
        team?: { __typename?: 'Team'; id: string; name: string } | null;
      } | null;
      enrichmentData: {
        __typename?: 'EnrichmentData';
        logo?: { __typename?: 'Logo'; small: string; medium: string } | null;
      };
      localAmount?: { __typename?: 'Amount'; value: string; currency: string } | null;
      vat: {
        __typename?: 'Vat';
        status?: VatStatus | null;
        totalAmount: { __typename?: 'Amount'; value: string; currency: string };
        items: Array<{
          __typename?: 'VatItem';
          id: string;
          transactionId: string;
          rate?: number | null;
          amount?: { __typename?: 'Amount'; value: string; currency: string } | null;
          amountExcludingVat?: { __typename?: 'Amount'; value: string; currency: string } | null;
        }>;
      };
      settledBalance?: { __typename?: 'Amount'; value: string; currency: string } | null;
      statusHistory: Array<{
        __typename?: 'StatusHistory';
        status: StatusHistoryStatus;
        createdAt: string;
      }>;
      financingInstallment?: {
        __typename?: 'FinancingInstallment';
        currentInstallmentNumber: number;
        totalInstallmentsNumber: number;
      } | null;
      attachments: Array<{
        __typename?: 'Attachment';
        slug: string;
        id: string;
        downloadUrl: string;
        createdAt: string;
        thumbnail?: { __typename?: 'Thumbnail'; fileUrl: string } | null;
        file?: {
          __typename?: 'AttachedFile';
          name: string;
          contentType: string;
          size: string;
          url: string;
        } | null;
        probativeAttachment?: {
          __typename?: 'ProbativeAttachment';
          status: string;
          fileContentType: string;
          fileName: string;
          fileSize: string;
          fileUrl: string;
          downloadUrl: string;
        } | null;
      }>;
      bankAccount?: {
        __typename?: 'BankAccount';
        id: string;
        bic: string;
        iban: string;
        name: string;
        logo?: { __typename?: 'Logo'; small: string; medium: string } | null;
      } | null;
      card?: {
        __typename?: 'Card';
        id: string;
        last4: string;
        nickname: string;
        cardDesign?: string | null;
        cardType: CardType;
        holder?: {
          __typename?: 'Membership';
          id: string;
          fullName: string;
          firstName: string;
          lastName: string;
        } | null;
      } | null;
      labels: Array<{
        __typename?: 'Label';
        id: string;
        name: string;
        listId: string;
        labelList: {
          __typename?: 'LabelList';
          id: string;
          name: string;
          color: string;
          rank: number;
        };
      }>;
      thread?: {
        __typename?: 'Thread';
        id: string;
        attachedToId: string;
        attachedToType: string;
        createdAt: string;
        messages: Array<{
          __typename?: 'Message';
          id: string;
          createdAt: string;
          content: string;
          author?: {
            __typename?: 'Participant';
            id: string;
            lastReadAt?: string | null;
            membership: {
              __typename?: 'Membership';
              fullName: string;
              email: string;
              role: string;
              avatar: { __typename?: 'AvatarFile'; url: string };
              avatarThumb: { __typename?: 'AvatarFile'; url: string };
              team?: { __typename?: 'Team'; id: string; name: string } | null;
            };
          } | null;
        }>;
        participants: Array<{
          __typename?: 'Participant';
          id: string;
          lastReadAt?: string | null;
          membershipId: string;
          membership: {
            __typename?: 'Membership';
            firstName: string;
            fullName: string;
            email: string;
            role: string;
            avatar: { __typename?: 'AvatarFile'; url: string };
            avatarThumb: { __typename?: 'AvatarFile'; url: string };
            team?: { __typename?: 'Team'; id: string; name: string } | null;
          };
        }>;
      } | null;
    }>;
  };

  getTransactionById: {
    __typename?: 'SearchTransactionsResult';
    transactions: Array<{
      __typename?: 'Transaction';
      activityTag?: ActivityTag | null;
      note?: string | null;
      qualifiedForAccounting: boolean;
      labels: Array<{
        __typename?: 'Label';
        id: string;
        name: string;
        listId: string;
        labelList: {
          __typename?: 'LabelList';
          id: string;
          name: string;
          color: string;
          rank: number;
        };
      }>;
      attachments: Array<{
        __typename?: 'Attachment';
        slug: string;
        id: string;
        downloadUrl: string;
        createdAt: string;
        thumbnail?: { __typename?: 'Thumbnail'; fileUrl: string } | null;
        file?: {
          __typename?: 'AttachedFile';
          name: string;
          contentType: string;
          size: string;
          url: string;
        } | null;
        probativeAttachment?: {
          __typename?: 'ProbativeAttachment';
          status: string;
          fileContentType: string;
          fileName: string;
          fileSize: string;
          fileUrl: string;
          downloadUrl: string;
        } | null;
      }>;
    }>;
  };
};

export const GetTransactionByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTransactionById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Pagination' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Sort' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filterGroup' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'FilterGroupOne' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bankAccountIds' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sort' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pagination' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'filterGroup' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'filterGroup' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'bankAccountIds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'bankAccountIds' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'activityTag' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'attachmentLost' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'attachmentRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'attachmentRequestedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'automaticallyAddedAttachmentIds' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'qualifiedForAccounting' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'labelList' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'listId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attachments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'thumbnail' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileUrl' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'probativeAttachment' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fileContentType' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileSize' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileUrl' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTransactionByIdQuery, GetTransactionByIdQueryVariables>;
export const SearchTransactionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchTransactions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Pagination' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Sort' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filterGroup' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'FilterGroupOne' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bankAccountIds' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'search' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sort' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pagination' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'filterGroup' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'filterGroup' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'bankAccountIds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'bankAccountIds' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'perPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'nextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'prevPage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'disputes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'claim' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billingTransfer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'operationSubtype' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'operationType' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'categoryAssignment' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'initiator' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatarThumb' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'team' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'activityTag' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'counterpartyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emittedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enrichmentData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'small' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'medium' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'localAmount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'fx' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vat' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalAmount' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'transactionId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'rate' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'amount' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currency' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'amountExcludingVat' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currency' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'rawCounterpartyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'operationMethod' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'settledAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'settledBalance' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'side' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusHistory' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'subjectType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'qualifiedForAccounting' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'attachmentLost' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'attachmentRequired' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'attachmentIds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'attachmentSuggestionIds' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'automaticallyAddedAttachmentIds' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'attachmentRequested' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'attachmentRequestedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'financingInstallment' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currentInstallmentNumber' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalInstallmentsNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attachments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'thumbnail' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileUrl' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'probativeAttachment' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fileContentType' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileSize' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileUrl' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bankAccount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bic' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'iban' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'small' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'medium' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'card' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'holder' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'cardDesign' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'cardType' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'labelList' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'listId' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'thread' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'attachedToId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'attachedToType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'messages' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'lastReadAt' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'membership' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fullName' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'role' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'avatar' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'url' },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'avatarThumb' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'url' },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'team' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'id' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'name' },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'participants' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastReadAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'membershipId' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'membership' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'firstName' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'fullName' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'avatar' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'url' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'avatarThumb' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'url' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'team' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchTransactionsQuery, SearchTransactionsQueryVariables>;
