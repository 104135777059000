import type { ReactNode } from 'react';
import cx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { SortIcon } from 'qonto/react/components/paginated-table/header/sort/sort';
import {
  DEFAULT_SORT_ORDER,
  SORT_ORDER,
  type AriaSort,
  type SortOrder,
} from 'qonto/constants/sort.ts';
import styles from './styles.strict-module.css';

type SortField = 'request_type' | 'status';
type SortFull = `${SortField}:${SortOrder}`;

interface HeaderProps {
  sort?: SortFull;
  onSortChange?: (sort: SortFull) => void;
  shouldAnimateColumns?: boolean;
  isSidebarOpen?: boolean;
}

export function Header({
  sort,
  onSortChange,
  isSidebarOpen,
  shouldAnimateColumns,
  ...props
}: HeaderProps): ReactNode {
  const sortField = sort?.split(':')[0] as SortField;
  const sortOrder = sort?.split(':')[1] || DEFAULT_SORT_ORDER;
  const isAscending = sortOrder === SORT_ORDER.ASC;

  const getAriaSort = (columnField: string): AriaSort => {
    const isCurrentFieldActive = columnField === sortField;

    if (!isCurrentFieldActive) {
      return 'none';
    }

    return sortOrder === SORT_ORDER.ASC ? 'ascending' : 'descending';
  };

  const handleSortBy = (field: SortField): void => {
    let currentSortOrder: SortOrder = DEFAULT_SORT_ORDER;

    if (field === sortField) {
      currentSortOrder = sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;
    }

    return onSortChange?.(`${field}:${currentSortOrder}`);
  };

  return (
    <tr
      className={cx(
        shouldAnimateColumns && styles.animated,
        isSidebarOpen && styles['row-sidebar']
      )}
      data-test-request-table-header
      data-test-request-table-header-requester
      {...props}
    >
      <th
        aria-hidden="true"
        className={cx(styles['header-cell'], styles.empty)}
        data-test-request-table-requester-header-cell-empty
        scope="col"
      />

      <th
        aria-sort={getAriaSort('request_type')}
        className={cx(styles['header-cell'], styles['header-type'])}
        data-test-request-table-col
        data-test-request-table-requester-header-request-type
        scope="col"
      >
        <button
          className={cx(styles['header-content'], sortField === 'request_type' && styles.active)}
          onClick={() => {
            handleSortBy('request_type');
          }}
          type="button"
        >
          <FormattedMessage id="request.table.header.type" />
          <SortIcon isActive={sortField === 'request_type'} isAscending={isAscending} />
        </button>
      </th>

      <th
        className={cx(styles['header-cell'], styles['header-reason'])}
        data-test-request-table-col
        data-test-request-table-requester-header-reason
        scope="col"
      >
        <span className={cx(styles['header-content'], styles['header-text'])}>
          <FormattedMessage id="request.table.header.note" />
        </span>
      </th>

      <th
        aria-sort={getAriaSort('status')}
        className={cx(styles['header-cell'], styles['header-status'])}
        data-test-request-table-col
        data-test-request-table-requester-header-status
        scope="col"
      >
        <button
          className={cx(styles['header-content'], sortField === 'status' && styles.active)}
          onClick={() => {
            handleSortBy('status');
          }}
          type="button"
        >
          <FormattedMessage id="transfers.columns.request-status" />
          <SortIcon isActive={sortField === 'status'} isAscending={isAscending} />
        </button>
      </th>

      <th
        className={cx(styles['header-cell'], styles['header-amount'])}
        data-test-request-table-col
        data-test-request-table-requester-header-amount
        scope="col"
      >
        <span className={styles['header-content']}>
          <FormattedMessage id="transfers.columns.amount" />
        </span>
      </th>

      <th
        aria-hidden="true"
        className={cx(styles['header-cell'], styles.empty)}
        data-test-request-table-requester-header-cell-empty
        scope="col"
      />
    </tr>
  );
}
