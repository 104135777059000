import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { IconExternalOutlined } from '@repo/monochrome-icons';
import cx from 'clsx';
import styles from './styles.strict-module.css';

interface FooterProps {
  currentUser: {
    email: string;
  };
  organization: {
    legalCountry: string;
    slug: string;
  };
  page?: string;
  bucketTitle?: string;
  className?: string;
}

export function InContextIntegrationsSideDrawerFooter({
  currentUser,
  organization,
  page,
  bucketTitle,
  className,
  ...props
}: FooterProps): ReactNode {
  const { formatMessage } = useIntl();
  const segment = useEmberService('segment');

  const handleClick = (): void => {
    segment.track('ICI_suggest_integration_clicked', {
      page,
      bucket: bucketTitle,
    });
  };

  const href = formatMessage(
    { id: 'qonto-hub.connect.cards.integrations.link' },
    {
      email: currentUser.email,
      legalCountry: organization.legalCountry,
      organizationSlug: organization.slug,
    }
  );

  return (
    <div className={cx(styles.footer, className)} data-test-side-drawer-footer {...props}>
      <a
        className="btn btn--tertiary"
        data-test-link
        href={href}
        onClick={handleClick}
        rel="noopener noreferrer"
        target="_blank"
      >
        {formatMessage({ id: 'in-context-integrations.side-drawer.footer.link' })}
        <IconExternalOutlined aria-hidden="true" className="ml-8" data-test-icon />
      </a>
    </div>
  );
}
