import { type ReactElement } from 'react';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useEventCallback } from 'usehooks-ts';
import { BaseCell } from 'qonto/react/components/table-v2/cells/base-cell';
import { Status } from 'qonto/react/graphql';
import { useTransactionOperationMethodLabel } from 'qonto/react/hooks/use-transaction-operation-method';
import { cellContextManager } from 'qonto/react/contexts/cell-context';
import { DataWithIconCell } from 'qonto/react/components/table-v2/cells/data-with-icon-cell';
import { BankAccountAvatar } from 'qonto/react/components/bank-account-avatar';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { PAYMENT_METHOD_CELL_CLICK_EVENT_NAME } from 'qonto/react/constants';
import styles from './styles.strict-module.css';
import { PaymentMethodCellPopover } from './popover';

export function PaymentMethodCell(): ReactElement {
  const segment = useEmberService('segment');
  const transaction = cellContextManager.useCellContext();
  const { operationMethod, status, bankAccount, card } = transaction;
  const operationMethodLabel = useTransactionOperationMethodLabel(operationMethod);
  const { organization } = useOrganizationManager();

  const trackCellOpen = useEventCallback((isOpen: boolean): void => {
    if (isOpen) {
      segment.track(PAYMENT_METHOD_CELL_CLICK_EVENT_NAME);
    }
  });

  const isDeclined = status === Status.Declined || status === Status.Reversed;
  const { logo, name } = bankAccount ?? {};
  const { small = '', medium = '' } = logo ?? {};
  const hasExternalAccounts = organization.hasExternalAccounts;

  if (isDeclined) {
    return (
      // eslint-disable-next-line formatjs/no-literal-string-in-jsx -- empty cell
      <BaseCell data-testid="payment-method">-</BaseCell>
    );
  }

  return (
    <BaseCell
      data-testid="payment-method"
      onOpenChange={trackCellOpen}
      popoverSlot={<PaymentMethodCellPopover card={card} paymentMethod={operationMethod} />}
    >
      {bankAccount ? (
        <DataWithIconCell
          icon={
            hasExternalAccounts ? (
              <BankAccountAvatar
                bankAccount={{
                  logo: {
                    small,
                    medium,
                  },
                  name: name ?? null,
                }}
                className={styles.logo}
              />
            ) : null
          }
          subtitle={bankAccount.name}
          title={operationMethodLabel}
        />
      ) : (
        operationMethodLabel
      )}
    </BaseCell>
  );
}
