import { useIntl } from 'react-intl';
import { type ReactElement } from 'react';
import { Button } from '@repo/design-system-kit';
import { StickyPanel } from 'qonto/react/components/sticky-panel';
import { DISCLAIMER_TYPES, TRANSFER_FLOW_ORIGIN } from 'qonto/constants/transfers';
import type { OPERATION_TYPES } from 'qonto/constants/transfers';
import type TransferModel from 'qonto/models/transfer';
import { Success } from '../success';
import styles from '../styles.strict-module.css';

type ClickHandler = (
  action: string,
  data?: { transfer?: TransferModel; shouldTrack?: boolean }
) => void;

interface RegularTransferProps {
  sepaTransfer: TransferModel;
  beneficiaryName: string;
  context: {
    origin: string;
    isDedicatedFlow: boolean;
    confirmationResult?: {
      warnings?: string[];
    };
  };
  showApprovalWorkflowPromotion: boolean;
  onClickButtonPrimary: ClickHandler;
  onClickButtonSecondary: ClickHandler;
}

export function RegularTransfer({
  sepaTransfer,
  beneficiaryName,
  context,
  showApprovalWorkflowPromotion,
  onClickButtonPrimary,
  onClickButtonSecondary,
}: RegularTransferProps): ReactElement {
  const { formatMessage } = useIntl();

  const hasQontoBeneficiaryWarning = Boolean(
    context.confirmationResult?.warnings?.includes(DISCLAIMER_TYPES.QONTO_BANK_ACCOUNT)
  );

  const renderPrimaryButton = (): ReactElement => {
    if (showApprovalWorkflowPromotion) {
      return (
        <Button
          data-test-go-to-approval-workflows-button
          onPress={() => {
            onClickButtonPrimary('go-to-approval-workflows');
          }}
        >
          {formatMessage({ id: 'transfers.modals.success.supplier-invoice.sent.cta-primary' })}
        </Button>
      );
    }

    if (context.origin === TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES) {
      return (
        <Button
          data-test-back-to-supplier-invoices-button
          onPress={() => {
            onClickButtonPrimary('back-to-supplier-invoices');
          }}
        >
          {formatMessage({ id: 'transfers.modals.to-supplier-invoices-btn' })}
        </Button>
      );
    }

    return (
      <Button
        data-test-sepa-transfer-make-another-button
        onPress={() => {
          onClickButtonPrimary('make-another-transfer', { transfer: sepaTransfer });
        }}
      >
        {context.isDedicatedFlow
          ? formatMessage({ id: 'transfers.modals.finance-another-btn' })
          : formatMessage({ id: 'transfers.modals.make-another-btn' })}
      </Button>
    );
  };

  const renderSecondaryButton = (): ReactElement => {
    if (sepaTransfer.isPayLater) {
      return (
        <Button
          data-test-sepa-transfer-go-to-pay-later-section
          onPress={() => {
            onClickButtonSecondary('pay-later');
          }}
          variant="tertiary"
        >
          {formatMessage({ id: 'financing.pay-later.application-flow.success.secondary-cta' })}
        </Button>
      );
    }

    if (showApprovalWorkflowPromotion) {
      return (
        <Button
          data-test-go-to-supplier-invoices-button
          onPress={() => {
            onClickButtonSecondary('back-to-supplier-invoices', { shouldTrack: false });
          }}
          variant="tertiary"
        >
          {formatMessage({ id: 'transfers.modals.success.supplier-invoice.sent.cta-secondary' })}
        </Button>
      );
    }

    return (
      <Button
        data-test-sepa-transfer-close-button
        onPress={() => {
          onClickButtonSecondary('close');
        }}
        variant="tertiary"
      >
        {formatMessage({ id: 'btn.close' })}
      </Button>
    );
  };

  return (
    <>
      <Success
        amount={sepaTransfer.amount}
        beneficiaryName={beneficiaryName}
        currency={sepaTransfer.amountCurrency}
        isInstantTransfer={false}
        isPending={sepaTransfer.isPending}
        isQontoBeneficiary={hasQontoBeneficiaryWarning}
        isScheduled={sepaTransfer.wasScheduled}
        operationType={
          sepaTransfer.operationType as (typeof OPERATION_TYPES)[keyof typeof OPERATION_TYPES]
        }
        showApprovalWorkflowPromotion={showApprovalWorkflowPromotion}
      />

      <StickyPanel className={styles['sticky-panel']}>
        {renderPrimaryButton()}

        {renderSecondaryButton()}
      </StickyPanel>
    </>
  );
}
