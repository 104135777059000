import type { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import type { FinancingModel } from 'qonto/react/financing/api';
import { formatMoney } from 'qonto/react/shared/utils/format-money';
import styles from '../styles.strict-module.css';

export function InstallmentAmount({ financing }: { financing: FinancingModel }): ReactElement {
  const intl = useIntl();

  return (
    <p className={styles.amount} data-test-installment-amount>
      {formatMoney(Number(financing.nextInstallmentTotalAmount?.value), {
        currency: financing.nextInstallmentTotalAmount?.currency,
        locale: intl.locale,
      })}
    </p>
  );
}
