import type { CashflowPeriodRate } from 'qonto/react/models/cash-flow-period';
import type { LabelTableInterval } from '../../../models/labels-cashflow-display';
import type { CategoriesTableRow } from '../../../models/categories-table-display';
import { ForecastSelector } from '../forecast-selector/forecast-selector';
import { Divider } from '../divider';
import { ForecastEntryEmpty } from '../forecast-entry-empty';
import { ForecastEntryEditForm } from './forecast-entry-edit-form';
import { ForecastEntryDisclaimer } from './forecast-entry-disclaimer';

interface ForecastEntrylessProps extends React.HTMLAttributes<HTMLDivElement> {
  showTopForecastSelector: boolean;
  showBottomForecastSelector: boolean;
  isEditing: boolean;
  category: CategoriesTableRow;
  frequency: CashflowPeriodRate;
  interval: LabelTableInterval;
  isReadOnly: boolean;
  onCreateForecastEntry: () => void;
  isFlowSelected: boolean;
}

export function ForecastEntryless({
  showTopForecastSelector,
  showBottomForecastSelector,
  isEditing,
  category,
  frequency,
  interval,
  isReadOnly,
  onCreateForecastEntry,
  isFlowSelected,
  ...props
}: ForecastEntrylessProps): React.ReactNode {
  return (
    <section {...props}>
      {showTopForecastSelector ? (
        <>
          <ForecastSelector />
          <Divider />
        </>
      ) : null}

      {isEditing ? (
        <ForecastEntryEditForm category={category} frequency={frequency} interval={interval} />
      ) : (
        <ForecastEntryEmpty isReadOnly={isReadOnly} onCreateForecastEntry={onCreateForecastEntry} />
      )}
      <ForecastEntryDisclaimer
        category={category}
        frequency={frequency}
        isFlowSelected={isFlowSelected}
      />
      {showBottomForecastSelector ? (
        <>
          <Divider />
          <ForecastSelector />
        </>
      ) : null}
    </section>
  );
}
