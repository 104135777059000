import { type ReactNode } from 'react';
import { useFetchInfiniteTransactions } from 'qonto/react/hooks/use-fetch-infinite-transactions';
import {
  SidepanelEmptyState,
  SidepanelErrorState,
} from 'qonto/react/components/cash-flow/components/sidepanel/transactions-table/table-states';
import { useInfiniteScroll } from 'qonto/react/hooks/ui/use-infinite-scroll.ts';
import { useFetchCashflowCategories } from 'qonto/react/hooks/use-fetch-cashflow-categories';
import type { CategoriesTableRow } from '../../../models/categories-table-display';
import type { LabelTableInterval } from '../../../models/labels-cashflow-display';
import { TransactionsTable } from './transactions-table';
import { useFormattedTransactions } from './format-transactions';

export function TransactionsTableWrapper({
  bankAccounts,
  selectedInterval,
  selectedCategories,
  isFlowSelected,
  refreshChart,
}: {
  bankAccounts: string[];
  selectedInterval: LabelTableInterval | undefined;
  selectedCategories: CategoriesTableRow[];
  isFlowSelected: boolean;
  refreshChart: () => void;
}): ReactNode {
  const { data: categories } = useFetchCashflowCategories();
  const {
    data: transactionsResult,
    isLoading,
    isError,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useFetchInfiniteTransactions({
    bankAccountIds: bankAccounts,
    dateRange: {
      start: selectedInterval?.start ?? 0,
      end: selectedInterval?.end ?? 0,
    },
    categories: selectedCategories,
    isFlowSelected,
  });

  const loadMoreRef = useInfiniteScroll(isLoading, isFetchingNextPage, hasNextPage, fetchNextPage);
  const formattedTransactions = useFormattedTransactions(transactionsResult);
  const isEmpty = formattedTransactions.length === 0;

  if (isError) return <SidepanelErrorState />;
  if (!isLoading && isEmpty) {
    return <SidepanelEmptyState />;
  }

  return (
    <div>
      <TransactionsTable
        categories={categories}
        data={formattedTransactions}
        infiniteScroll={{
          isLoading: isLoading || isFetchingNextPage,
          loadMoreRef,
        }}
        refreshChart={refreshChart}
        selectedCategories={selectedCategories}
        selectedInterval={selectedInterval}
      />
    </div>
  );
}
