import { FormattedMessage } from 'react-intl';
import cx from 'clsx';
import { Button, LottiePlayer, ExternalLink } from '@repo/design-system-kit';
import { useSegment } from '@repo/poly-hooks/segment';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import styles from './account-aggregation-value-proposition.strict-module.css';

type AccountAggregationValuePropositionProps = React.HTMLAttributes<HTMLDivElement>;

export function AccountAggregationValueProposition({
  ...props
}: AccountAggregationValuePropositionProps): React.ReactElement {
  const segment = useSegment();
  const zendeskLocalization = useEmberService('zendesk-localization');
  const flowLinkManager = useEmberService('flowLinkManager');

  const handleLearnMoreClick = (): void => {
    segment.track('pay-later-faq_clicked', {
      origin: 'external_account_value_prop',
    });
  };

  const handleConnectExternalAccountClick = (): void => {
    segment.track('pay-later_external-account-value-prop_clicked');

    void flowLinkManager.transitionTo({
      name: 'external-account-import',
      stepId: 'select',
      queryParams: {
        origin: 'financing.pay-later',
      },
    });
  };

  return (
    <div className={styles.container} {...props}>
      <main className={styles.content}>
        <section className="mb-32">
          <h1 className={cx('title-2', 'mb-8')} data-testid="value-proposition-title">
            <FormattedMessage id="financing.pay-later.increase-credit.value-proposition.title" />
          </h1>
          <p className="body-2" data-testid="value-proposition-subtitle">
            <FormattedMessage id="financing.pay-later.increase-credit.value-proposition.subtitle" />
          </p>
        </section>

        <section className="mb-16">
          <h2 className={cx('title-4', 'mb-8')} data-testid="value-proposition-eligibility-title">
            <FormattedMessage id="financing.pay-later.increase-credit.value-proposition.point1.title" />
          </h2>
          <p className="body-2" data-testid="value-proposition-eligibility-subtitle">
            <FormattedMessage id="financing.pay-later.increase-credit.value-proposition.point1.subtitle" />
          </p>
        </section>

        <section className="mb-32">
          <h2
            className={cx('title-4', 'mb-8')}
            data-testid="value-proposition-increase-credit-title"
          >
            <FormattedMessage id="financing.pay-later.increase-credit.value-proposition.point2.title" />
          </h2>
          <p className="body-2" data-testid="value-proposition-increase-credit-subtitle">
            <FormattedMessage id="financing.pay-later.increase-credit.value-proposition.point2.subtitle" />
          </p>
        </section>

        <div className={styles.actions}>
          <ExternalLink
            className={cx('mr-16', styles['learn-more-cta'])}
            data-testid="value-proposition-secondary-cta"
            display="button"
            href={zendeskLocalization.getLocalizedArticle('8271688')}
            onPress={handleLearnMoreClick}
            rel="noopener noreferrer"
            target="_blank"
            variant="secondary"
          >
            <FormattedMessage id="financing.pay-later.increase-credit.value-proposition.learn-more.cta" />
          </ExternalLink>
          <Button
            data-testid="value-proposition-primary-cta"
            onPress={handleConnectExternalAccountClick}
            variant="primary"
          >
            <FormattedMessage id="financing.pay-later.increase-credit.value-proposition.connect-account.cta" />
          </Button>
        </div>
      </main>

      <div className={styles.illustration}>
        <LottiePlayer
          className={styles.lottie}
          data-testid="value-proposition-lottie"
          path="/lotties/financing/account-aggregation-value-proposition.json"
        />
      </div>
    </div>
  );
}
