import React from 'react';
import { IconCrossOutlined } from '@repo/monochrome-icons';
import { useIntl } from 'react-intl';
import { Button, type ButtonProps } from '@repo/design-system-kit';
import styles from './styles.strict-module.css';

interface CloseProps extends Omit<ButtonProps, 'children'> {
  onClose: () => void;
}

export function Close({ onClose, ...props }: CloseProps): React.ReactNode {
  const { formatMessage } = useIntl();

  return (
    <Button
      aria-label={formatMessage({ id: 'btn.close' })}
      className={styles.button}
      data-test-transaction-sidebar-close
      iconOnly
      onPress={onClose}
      variant="tertiary"
      {...props}
    >
      <IconCrossOutlined aria-hidden="true" height={12} width={12} />
    </Button>
  );
}
