import type { UpcomingTransaction } from 'qonto/react/hooks/use-upcoming-transactions';

export interface ModalProps {
  titleId: string;
  subtitleId: string;
  confirmTextId: string;
  cancelTextId: string;
}

export function getDestructiveModalProps(upcomingTransaction: UpcomingTransaction): ModalProps {
  const isManualEntry = upcomingTransaction.provider_object_type === 'manual_entry';
  const isRecurring =
    Boolean(upcomingTransaction.metadata?.recurrence?.frequency) &&
    upcomingTransaction.metadata?.recurrence?.frequency !== 'none';

  let titleId, subtitleId, confirmTextId, cancelTextId;

  if (isManualEntry) {
    if (isRecurring) {
      titleId = 'cash-flow.upcoming-transactions.remove-modal.manual-entry.recurring.title';
      subtitleId = 'cash-flow.upcoming-transactions.remove-modal.manual-entry.recurring.subtitle';
      confirmTextId =
        'cash-flow.upcoming-transactions.remove-modal.manual-entry.recurring.this-and-future-ones.cta';
      cancelTextId = 'cash-flow.upcoming-transactions.remove-modal.recurring.just-this-one.cta';
    } else {
      titleId = 'cash-flow.upcoming-transactions.remove-modal.single.title';
      subtitleId = 'cash-flow.upcoming-transactions.remove-modal.manual-entry.single.subtitle';
      confirmTextId = 'cash-flow.upcoming-transactions.remove-modal.single.remove.cta';
      cancelTextId = 'btn.cancel';
    }
    return {
      titleId,
      subtitleId,
      confirmTextId,
      cancelTextId,
    };
  }

  if (isRecurring) {
    titleId = 'cash-flow.upcoming-transactions.edit-manual-entry.recurring.modal.title';
    subtitleId = 'cash-flow.upcoming-transactions.edit-manual-entry.recurring.modal.subtitle';
    confirmTextId =
      'cash-flow.upcoming-transactions.edit-manual-entry.recurring.modal.this-and-future-ones.cta';
    cancelTextId =
      'cash-flow.upcoming-transactions.edit-manual-entry.recurring.modal.just-this-one.cta';
  } else {
    titleId = 'cash-flow.upcoming-transactions.remove-modal.single.title';
    subtitleId = 'cash-flow.upcoming-transactions.remove-modal.single.subtitle';
    confirmTextId = 'cash-flow.upcoming-transactions.remove-modal.single.remove.cta';
    cancelTextId = 'btn.cancel';
  }

  return {
    titleId,
    subtitleId,
    confirmTextId,
    cancelTextId,
  };
}
