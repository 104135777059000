import { useState } from 'react';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { useFetchApi } from 'qonto/react/hooks/use-fetch-api';
import { financingNamespace } from 'qonto/constants/hosts';

interface UseFetchSignatureRequestProps {
  isSignatureRequestLoading: boolean;
  retrievePayLaterSignatureUrl: () => Promise<SignatureRequestResult | undefined>;
}

interface SignatureRequestResult {
  url: string;
}

export function useFetchSignatureRequest(
  onError?: (error: unknown) => void
): UseFetchSignatureRequestProps {
  const [isSignatureRequestLoading, setIsSignatureRequestLoading] = useState(false);
  const { organization, membership } = useOrganizationManager();
  const fetchApi = useFetchApi();

  const retrievePayLaterSignatureUrl = async (): Promise<SignatureRequestResult | undefined> => {
    try {
      setIsSignatureRequestLoading(true);
      const response = await fetchApi(`${financingNamespace}/pay_later/signature_requests`, {
        method: 'POST',
        body: JSON.stringify({
          organization_id: organization.id,
          signer_membership_id: membership.id,
        }),
      });

      if (!response.ok) {
        throw new Error(`Status: ${response.status}`);
      }

      return (await response.json()) as SignatureRequestResult;
    } catch (error: unknown) {
      onError?.(error);
    } finally {
      setIsSignatureRequestLoading(false);
    }
  };

  return {
    isSignatureRequestLoading,
    retrievePayLaterSignatureUrl,
  };
}
