import { type ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@repo/design-system-kit';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './about.strict-module.css';

interface AboutProps {
  maxRate: number;
  onNext: () => void;
}

export function About({ maxRate, onNext }: AboutProps): ReactNode {
  const { formatNumber } = useIntl();
  const segment = useEmberService('segment');
  const zendeskLocalization = useEmberService('zendesk-localization');

  const handleNext = (): void => {
    segment.track('savings_about_cta');
    onNext();
  };

  return (
    <div className={styles.container}>
      <div className={`body-2 ${styles.content}`}>
        <h1 className="title-2 mb-8" data-test-account-savings-about-title>
          <FormattedMessage id="accounts.savings.creation.about.title" />
        </h1>
        <p className={`mb-32 ${styles.introContent}`} data-test-account-savings-about-intro>
          <FormattedMessage id="accounts.savings.creation.about.intro" />
          <a
            className="body-link"
            data-test-account-savings-about-learn-more
            href={zendeskLocalization.getLocalizedArticle(5574856)}
            onClick={() => {
              segment.track('savings_about_learn_more');
            }}
            rel="noopener noreferrer"
            target="_blank"
          >
            <FormattedMessage id="accounts.savings.creation.about.learn-more.text" />
          </a>
        </p>
        <h2 className="title-4 mb-4" data-test-account-savings-about-subtitle1>
          <FormattedMessage id="accounts.savings.creation.about.subtitle1" />
        </h2>
        <p className={styles.subDetails} data-test-account-savings-about-sub-detail1>
          <FormattedMessage
            id="accounts.savings.creation.about.sub-detail1"
            values={{
              maxRate: formatNumber(maxRate / 100, {
                style: 'percent',
                minimumFractionDigits: 3,
              }),
            }}
          />
        </p>
        <h2 className="title-4 mb-4" data-test-account-savings-about-subtitle2>
          <FormattedMessage id="accounts.savings.creation.about.subtitle2" />
        </h2>
        <p className={styles.subDetails} data-test-account-savings-about-sub-detail2>
          <FormattedMessage id="accounts.savings.creation.about.sub-detail2" />
        </p>
        <h2 className="title-4 mb-4" data-test-account-savings-about-subtitle3>
          <FormattedMessage id="accounts.savings.creation.about.subtitle3" />
        </h2>
        <p className={styles.subDetails} data-test-account-savings-about-sub-detail3>
          <FormattedMessage id="accounts.savings.creation.about.sub-detail3" />
        </p>
        <Button
          data-test-about-continue
          onPress={handleNext}
          size="large"
          stretch
          variant="primary"
        >
          <FormattedMessage id="btn.continue" />
        </Button>
      </div>
      <div className={styles.illustrationContainer}>
        <StaticThemedAsset
          alt=""
          assetPath="/illustrations/savings-accounts/wizard-about.svg"
          className={styles.img}
          data-test-account-savings-illustration
        />
      </div>
    </div>
  );
}
