import type { ReactNode } from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './placeholder.strict-module.css';

interface PlaceholderProps {
  columnsToShow?: {
    customer?: boolean;
    issueDate?: boolean;
    dueDate?: boolean;
    expiryDate?: boolean;
    status?: boolean;
    amountDue?: boolean;
  };
}

export function Placeholder({ columnsToShow, ...props }: PlaceholderProps): ReactNode {
  return (
    <tr className={styles.row} {...props}>
      <td aria-hidden="true" />
      {columnsToShow?.customer ? (
        <td className={styles.cell}>
          <div className={styles['cell-content']}>
            <SkeletonLoader.Line className="mb-8" width="152px" />
            <SkeletonLoader.Line width="104px" />
          </div>
        </td>
      ) : null}
      {columnsToShow?.issueDate ? (
        <td className={styles.cell}>
          <SkeletonLoader.Line className={styles['align-left']} width="88px" />
        </td>
      ) : null}
      {columnsToShow?.dueDate ? (
        <td className={styles.cell}>
          <SkeletonLoader.Line className={styles['align-left']} width="88px" />
        </td>
      ) : null}
      {columnsToShow?.expiryDate ? (
        <td className={styles.cell}>
          <SkeletonLoader.Line className={styles['align-left']} width="88px" />
        </td>
      ) : null}
      {columnsToShow?.status ? (
        <td className={styles.cell}>
          <SkeletonLoader.Line width="64px" />
        </td>
      ) : null}
      {columnsToShow?.amountDue ? (
        <td className={styles.cell}>
          <SkeletonLoader.Line className={styles['align-right']} width="96px" />
        </td>
      ) : null}
      <td aria-hidden="true" />
    </tr>
  );
}
