import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { importAsset } from 'qonto/helpers/import-asset';
import styles from './suggested-transactions-header.strict-module.css';

export function SuggestedTransactionsHeader(): ReactNode {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.header} data-testid="suggested-transactions-header">
      <img alt="" className={styles.icon} src={importAsset(['/icon/suggested_transactions.svg'])} />
      <p>{formatMessage({ id: 'supplier-invoices.suggested-transaction.title' })}</p>
    </div>
  );
}
