import { useState, useEffect, useMemo } from 'react';
import { useFlags } from '@qonto/react-migration-toolkit/react/hooks';
import dayjs from 'dayjs';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { DEFAULT_SEARCH_INCLUDES } from 'qonto/constants/transactions';
import type SupplierInvoiceModel from 'qonto/models/supplier-invoice';
import type { TransactionsQuery, Expression } from 'qonto/react/hooks/use-fetch-transactions';
import type { Period } from '../period-selector/period-selector';

interface UseGenerateTransactionQueryResult {
  query: TransactionsQuery;
  setSearch: (searchQuery: string) => void;
  setPeriod: (period: Period | null) => void;
  period: Period | null;
}

export function useGenerateTransactionQuery(
  invoice?: SupplierInvoiceModel
): UseGenerateTransactionQueryResult {
  const { featureBooleanApCreditNotes } = useFlags();
  const { organization } = useOrganizationManager();

  const [period, setPeriod] = useState<Period | null>(null);

  const hasAmountExpression = !(invoice?.isCreditNote && featureBooleanApCreditNotes);

  const expressions = useMemo(() => {
    if (!invoice?.attachmentId) {
      return [];
    }
    const baseExpressions: Expression[] = [
      {
        property: 'status',
        values: ['completed'],
        operator: 'in',
      },
      {
        property: 'operation_method',
        values: ['pagopa_payment'],
        operator: 'not_in',
      },
      {
        property: 'attachment_ids',
        values: [invoice.attachmentId],
        operator: 'not_in',
      },
    ];

    if (hasAmountExpression) {
      baseExpressions.push({
        property: 'amount',
        values: ['0.00'],
        operator: 'lt',
      });
    }

    if (period) {
      const periodFilter = { property: 'settled_at' };
      switch (period.key) {
        case 'this_month':
          Object.assign(periodFilter, { values: ['current_month'], operator: 'interval' });
          break;
        case 'last_month':
          Object.assign(periodFilter, { values: ['last_month'], operator: 'interval' });
          break;
        case 'custom_period':
          Object.assign(periodFilter, {
            values: [
              dayjs(period.startDate).startOf('day').valueOf(),
              dayjs(period.endDate).endOf('day').valueOf(),
            ],
            operator: 'within',
          });
          break;
      }
      baseExpressions.push(periodFilter as Expression);
    }

    return baseExpressions;
  }, [invoice?.attachmentId, hasAmountExpression, period]);

  const [query, setQuery] = useState<TransactionsQuery>({
    includes: DEFAULT_SEARCH_INCLUDES,
    filter_group: {
      conditional: 'and',
      expressions,
    },
    sort: { property: 'emitted_at', direction: 'desc' },
    pagination: { page: 1, per_page: 20 },
    search: '',
    organization_id: organization.id,
  });

  // Refresh invoice when it changes
  useEffect(() => {
    setQuery({
      includes: DEFAULT_SEARCH_INCLUDES,
      filter_group: {
        conditional: 'and',
        expressions,
      },
      sort: { property: 'emitted_at', direction: 'desc' },
      pagination: { page: 1, per_page: 20 },
      search: query.search,
      organization_id: organization.id,
    });
  }, [invoice, setQuery, expressions, organization.id, query.search]);

  const setSearch = (searchQuery: string): void => {
    setQuery(prevQuery => ({ ...prevQuery, search: searchQuery }));
  };

  return { query, setSearch, setPeriod, period };
}
