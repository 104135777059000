import { type ReactNode, useEffect, useState } from 'react';
import { bulkTransactionsManager } from 'qonto/react/contexts/bulk-transactions-context';
import { CashflowCategoryManagerMt } from 'qonto/react/components/transactions/sidebar/category/cashflow-category/components';
import { bulkSelectionManager } from 'qonto/react/contexts/bulk-selection-context';
import { bulkCashflowCategoryManager } from 'qonto/react/hooks/use-bulk-cashflow-category';
import styles from './styles.strict-module.css';

export function CashflowCategorySection(): ReactNode {
  const { cashflowCategory: initialCategory } =
    bulkCashflowCategoryManager.useBulkCashflowCategory();
  const { selection: selectedTransactionIds } = bulkSelectionManager.useBulkSelection();
  const {
    cashflowCategory: { setCashflowCategory: bulkCashflowCategoryUpdaterFn },
  } = bulkTransactionsManager.useBulkTransactions();

  const [selectedCategory, setSelectedCategory] = useState(initialCategory);

  useEffect(() => {
    setSelectedCategory(initialCategory);
  }, [initialCategory]);

  if (selectedTransactionIds.length === 0) {
    return null;
  }

  return (
    <section className={styles.section} data-testid="category-section">
      <CashflowCategoryManagerMt
        activeCategoryId={selectedCategory}
        onBulkMutate={({ categoryId }) => {
          setSelectedCategory(categoryId);
          bulkCashflowCategoryUpdaterFn(categoryId, initialCategory);
        }}
        transactionIds={selectedTransactionIds}
      />
    </section>
  );
}
