import type { ComponentPropsWithRef, ReactNode } from 'react';

export function IconStatusScheduled(props: ComponentPropsWithRef<'svg'>): ReactNode {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path fill="#9B81F6" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8" />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M8 6a.5.5 0 0 1 .5.5v1.14l1.158.386a.5.5 0 1 1-.316.948l-1.5-.5A.5.5 0 0 1 7.5 8V6.5A.5.5 0 0 1 8 6"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8"
        clipRule="evenodd"
      />
    </svg>
  );
}
