import { useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useSegment } from '@repo/poly-hooks/segment';
import { useQueryClient } from '@tanstack/react-query';
import type { UpcomingTransaction } from 'qonto/react/hooks/use-upcoming-transactions';
import { PopupDestructive } from 'qonto/react/components/popups/destructive';
import { useCancelTransfer } from 'qonto/react/components/cash-flow/hooks/use-cancel-transfer';

export const useCancelTransferHandler = (
  upcomingTransaction: UpcomingTransaction | null
): (() => Promise<void>) => {
  const queryClient = useQueryClient();
  const reactPopup = useEmberService('reactPopup');
  const { formatMessage } = useIntl();
  const segment = useSegment();
  const cancelTransfer = useCancelTransfer();
  const type = upcomingTransaction?.provider_object_type;

  return async (): Promise<void> => {
    segment.track('compass_action_clicked', {
      action: 'cancel_transfer',
      type,
    });

    if (type !== 'transfer' || !upcomingTransaction) return;

    const result = await reactPopup.open(PopupDestructive, {
      title: formatMessage({
        id: 'cash-flow.upcoming-transactions.actions.cancel-transaction.modal.title',
      }),
      subtitle: formatMessage({
        id: 'cash-flow.upcoming-transactions.actions.cancel-transaction.modal.body',
      }),
      confirmText: formatMessage({
        id: 'cash-flow.upcoming-transactions.actions.cancel-transaction.modal.confirm.cta',
      }),
      cancelText: formatMessage({
        id: 'cash-flow.upcoming-transactions.actions.cancel-transaction.modal.back.cta',
      }),
    });

    if (result === 'confirm') {
      cancelTransfer.mutate(
        {
          transferId: upcomingTransaction.provider_object_id || '',
        },
        {
          onSuccess: () => {
            void queryClient.invalidateQueries({ queryKey: ['upcoming-transactions'] });
          },
        }
      );
    }
  };
};
