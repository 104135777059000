export * from './transactions';

export type {
  TransactionCategory,
  TransactionStatus,
  StatusHistoryStatus,
  TransactionSide,
  StatusHistory,
} from './transactions';

// Columns tracking events

export const ATTACHMENT_POPOVER_CLICK_EVENT_NAME = 'tables_attachment-popover_clicked';
export const ATTACHMENT_CELL_CLICK_EVENT_NAME = 'tables_attachment-cell_clicked';
export const ATTACHMENT_CELL_DROPZONE_UPLOAD_EVENT_NAME = 'tables_attachment-drag-drop_uploaded';

export const VERIFICATION_STATUS_CELL_CLICK_EVENT_NAME = 'tables_verification-status-cell_clicked';
export const VERIFICATION_STATUS_POPOVER_CLICK_EVENT_NAME =
  'tables_verification-status-popover_clicked';

export const LABEL_CELL_CLICK_EVENT_NAME = 'tables_label-cell_clicked';
export const LABEL_POPOVER_CLICK_EVENT_NAME = 'tables_label-popover_clicked';

export const CATEGORY_CELL_CLICK_EVENT_NAME = 'tables_category-cell_clicked';
export const CATEGORY_POPOVER_CLICK_EVENT_NAME = 'tables_category-popover_clicked';

export const STATUS_CELL_CLICK_EVENT_NAME = 'tables_status-cell_clicked';

export const MEMBERSHIP_CELL_CLICK_EVENT_NAME = 'tables_membership-cell_clicked';

export const COMMENT_CELL_CLICK_EVENT_NAME = 'tables_comment-cell_clicked';

export const VAT_CELL_CLICK_EVENT_NAME = 'tables_vat-cell_clicked';
export const VAT_POPOVER_CLICK_EVENT_NAME = 'tables_vat-popover_clicked';

export const PAYMENT_METHOD_CELL_CLICK_EVENT_NAME = 'tables_payment-method-cell_clicked';
export const PAYMENT_METHOD_POPOVER_CLICK_EVENT_NAME = 'tables_payment-method-popover_clicked';

export const BULK_PANEL_SUBMIT_CLICK_EVENT_NAME = 'history_bulk_panel_apply_button_clicked';
