import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { Button, TextField } from '@repo/design-system-kit';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import type Organization from 'qonto/models/organization';
// @ts-expect-error The file needs to be migrated to TypeScript
import { ErrorInfo } from 'qonto/utils/error-info';

interface PromoCodeFormProps {
  organization: Organization; // Using any since we don't have the full Ember model type
  onPromoCodeSave: () => void;
}

interface ErrorInfoType {
  shouldSendToSentry: boolean;
  for: (error: Error) => ErrorInfoType;
}

type BasicErrorCode = 'invalid' | 'already_used' | 'reached_limit';
type RateLimitErrorCode = 'rate_limit_reached';
type ErrorCode = BasicErrorCode | RateLimitErrorCode | 'backend_error';

export function PromoCodeForm({
  organization,
  onPromoCodeSave,
  ...props
}: PromoCodeFormProps): React.ReactNode {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { formatMessage } = useIntl();
  const store = useEmberService('store');
  const toastFlashMessages = useEmberService('toastFlashMessages');
  const segment = useEmberService('segment');
  const sentry = useEmberService('sentry');

  const ERROR_MESSAGES: Record<ErrorCode, string> = {
    invalid: formatMessage({
      id: 'subscription.promo-code.form.invalid-error',
    }),
    already_used: formatMessage({
      id: 'subscription.promo-code.form.used-error',
    }),
    reached_limit: formatMessage({
      id: 'subscription.promo-code.form.reached-limit-error',
    }),
    rate_limit_reached: formatMessage({
      id: 'subscription.promo-code.form.rate-limit-reached-error',
    }),
    backend_error: formatMessage({ id: 'toasts.errors.server_error' }),
  };

  const handleErrors = (error: unknown): ErrorCode | undefined => {
    const backendErrors = error as {
      errors?:
        | { detail: BasicErrorCode }[]
        | {
            discounts: RateLimitErrorCode;
          };
    };

    if (Array.isArray(backendErrors.errors)) {
      return backendErrors.errors[0]?.detail;
    }
    if (backendErrors.errors?.discounts === 'rate_limit_reached') {
      return 'rate_limit_reached';
    }
    return 'backend_error';
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    const form = e.currentTarget;
    const formData = new FormData(e.currentTarget);
    const promoCode = formData.get('promoCode')?.toString();

    if (!promoCode?.trim()) {
      setErrorMessage(formatMessage({ id: 'subscription.promo-code.form.empty-error' }));
      return;
    }

    setIsSubmitting(true);
    let outcome;

    try {
      const discount = store.createRecord('discount', {
        organization,
        voucherCode: promoCode,
      });

      await discount.save();

      toastFlashMessages.toastSuccess(
        formatMessage({ id: 'subscription.promo-code.form.success-toast' })
      );

      setErrorMessage(undefined);
      form.reset();
      outcome = 'success';
    } catch (error: unknown) {
      const errorCode = handleErrors(error);
      const translationId = errorCode ? ERROR_MESSAGES[errorCode] : null;
      outcome = errorCode;

      // Unexpected error we send to sentry and display a generic error message
      if (errorCode === 'backend_error' || !translationId) {
        toastFlashMessages.toastError(ERROR_MESSAGES.backend_error);

        const errorInfo = (ErrorInfo as ErrorInfoType).for(error as Error);
        if (errorInfo.shouldSendToSentry) {
          sentry.captureException(error);
        }
        return;
      }

      setErrorMessage(translationId);
    } finally {
      setIsSubmitting(false);
      onPromoCodeSave();
      if (outcome) {
        segment.track('subscription_promo_clicked', { outcome });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} {...props}>
      <TextField
        autoComplete="off"
        className="mb-16"
        data-test-promo-code-form-input
        errorMessage={errorMessage}
        isInvalid={Boolean(errorMessage)}
        label={formatMessage({ id: 'subscription.promo-code.form.label' })}
        name="promoCode"
        onChange={() => {
          setErrorMessage(undefined);
        }}
        placeholder={formatMessage({ id: 'subscription.promo-code.form.placeholder' })}
      />
      <Button
        data-test-promo-code-form-cta
        isLoading={isSubmitting}
        type="submit"
        variant="primary"
      >
        {formatMessage({ id: 'subscription.promo-code.form.cta' })}
      </Button>
    </form>
  );
}
