import { type ReactNode } from 'react';
import { CardLevel } from '@repo/cards-kit/constants';
import { useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { ChooseCard } from 'qonto/react/cards/components/flows/choose-card';
import type { Feature } from 'qonto/react/receivable-invoices/components/promotions/ar-features-promo-popup';
import type { VirtualCardLimits } from 'qonto/react/cards/types/limits';
import type { CardSelectorCoreProps } from './card-selector-core-props.interface';

interface VirtualCardProps extends CardSelectorCoreProps {
  cardLimit: VirtualCardLimits;
}

export function VirtualCard({
  assetProps,
  cardLimit,
  ctaProps,
  headerProps,
  layoutProps,
}: VirtualCardProps): ReactNode {
  const abilities = useEmberService('abilities');
  const { formatMessage, formatNumber } = useIntl();

  const features = [
    formatMessage(
      {
        id: 'cards.steps.choose-card.virtual-category.virtual.features.limit',
      },
      {
        amount: formatNumber(cardLimit.payment_monthly_limit_maximum, {
          minimumFractionDigits: 0,
          currency: 'EUR',
          style: 'currency',
        }),
      }
    ),
    abilities.can('see x pay card')
      ? formatMessage({ id: 'cards.steps.choose-card.virtual-category.virtual.features.x-pay' })
      : formatMessage({
          id: 'cards.steps.choose-card.virtual-category.virtual.features.online-payments',
        }),
    formatMessage({ id: 'cards.steps.choose-card.virtual-category.virtual.features.validity' }),
  ] as Feature[];

  const subtitle = abilities.can('see x pay card')
    ? formatMessage({ id: 'cards.steps.choose-card.virtual-category.virtual.subtitle' })
    : formatMessage({
        id: 'cards.steps.choose-card.virtual-category.virtual.subtitle-no-xpay',
      });

  return (
    <ChooseCard
      assetProps={assetProps}
      cardLevel={CardLevel.Virtual}
      ctaProps={ctaProps}
      features={features}
      headerProps={{
        ...headerProps,
        subtitle,
      }}
      layoutProps={layoutProps}
    />
  );
}
