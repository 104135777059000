import { type ReactNode } from 'react';
import cx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './travel-assistance-abroad.strict-module.css';

// this allows formatMessage to process the span tag in the localization strings
const parseSpanTag = (input: string | string[]): ReactNode => {
  return <span key="a">{input}</span>;
};

interface TravelAssistanceAbroadProps extends React.HTMLAttributes<HTMLDivElement> {
  legalCountry?: string;
}

export function TravelAssistanceAbroad({
  legalCountry,
  ...props
}: TravelAssistanceAbroadProps): ReactNode {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.container} data-test-travel-assistance-abroad={legalCountry} {...props}>
      <StaticThemedAsset
        alt=""
        aria-hidden="true"
        assetPath="/icon/category/online_service-xs.svg"
        className={styles.illustration}
        data-test-insurance-abroad-illustration="online_service-xs"
        height="20"
        width="20"
      />
      <div>
        <h4 className="body-1" data-test-insurance-abroad-title>
          <FormattedMessage id="insurance.sections.assistance-abroad.need-help.title" />
        </h4>

        <p className={cx('body-1', styles.description)} data-test-insurance-abroad-description>
          {formatMessage(
            { id: 'insurance.sections.assistance-abroad.need-help.description' },
            {
              legalCountry,
              span: parseSpanTag,
            }
          )}
        </p>
      </div>
    </div>
  );
}
