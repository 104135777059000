import type { ReactNode } from 'react';
import { IconFilterOutlined } from '@repo/monochrome-icons';
import { useEventCallback } from 'usehooks-ts';
import { useRouter } from '@qonto/react-migration-toolkit/react/hooks';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { PopoverItem } from './popover-item';

export function FilterByButton({
  filterQP,
  text,
  ...props
}: {
  filterQP: Record<string, string>;
  text: string;
}): ReactNode {
  const router = useRouter();
  const { organization } = useOrganizationManager();
  const searchParams = new URLSearchParams(filterQP);

  const handlePress = useEventCallback((): void => {
    void router.push(`/organizations/${organization.slug}/transactions?${searchParams.toString()}`);
  });

  return <PopoverItem icon={<IconFilterOutlined />} onPress={handlePress} text={text} {...props} />;
}
