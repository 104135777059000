import { type ReactNode } from 'react';
import { NoAttachment } from 'qonto/react/assets/icons';
import styles from './styles.strict-module.css';

interface InvoiceUnavailablePreviewProps {
  subtitle: string;
  title: string;
}

export function InvoiceUnavailablePreview({
  subtitle,
  title,
  ...props
}: InvoiceUnavailablePreviewProps): ReactNode {
  return (
    <section
      className={styles['preview-container']}
      data-testid="invoice-unavailable-preview-section"
      {...props}
    >
      <div className={`mb-16 ${styles['icon-container']}`}>
        <NoAttachment className={styles['no-attachment-icon']} />
      </div>
      <div className={styles['preview-text']}>
        <h3 className="title-2 mb-8" data-testid="preview-title">
          {title}
        </h3>
        <p className="body-2" data-testid="preview-subtitle">
          {subtitle}
        </p>
      </div>
    </section>
  );
}
