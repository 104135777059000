import { MenuTrigger, Popover, Menu, MenuItem } from 'react-aria-components';
import { useState, type ReactNode } from 'react';
import { IconDotsOutlined, IconPenOutlined } from '@repo/monochrome-icons';
import cx from 'clsx';
import dayjs from 'dayjs';
import { Button, DropdownButton } from '@repo/design-system-kit';
import { FormattedMessage } from 'react-intl';
import { ActionsMenu as ActionsMenuComponent } from '@repo/domain-kit/cashflow';
import { TrashOutlined } from 'qonto/react/assets/icons/trash-outlined';
import type { UpcomingTransaction } from 'qonto/react/hooks/use-upcoming-transactions';
import styles from './styles.strict-module.css';
import { useUpcomingTransactionActions } from './hooks/use-upcoming-transaction-actions';
import type { MenuConfig } from './hooks/use-menu-config';

interface ActionsMenuProps {
  upcomingTransaction: UpcomingTransaction | null;
  sections: MenuConfig['sections'];
  mainAction: MenuConfig['mainAction'];
  useActionsMenu: MenuConfig['useActionsMenu'];
  handleEditUpcomingTransaction: (upcomingTransaction: UpcomingTransaction) => void;
  handleHover: (amount: string | null) => () => void;
}

export function ActionsMenu({
  upcomingTransaction,
  sections,
  mainAction,
  useActionsMenu,
  handleEditUpcomingTransaction,
  handleHover,
}: ActionsMenuProps): ReactNode {
  const { handleDelete } = useUpcomingTransactionActions(upcomingTransaction);
  const [isOpen, setIsOpen] = useState(false);
  const isOverdueInvoice = dayjs(upcomingTransaction?.due_date).isBefore(dayjs().startOf('day'));
  const shouldShowAnticipatedBalanceLine =
    upcomingTransaction?.provider_object_type === 'supplier_invoice' && isOverdueInvoice;

  return (
    <div className={isOpen ? styles.isOpened : ''}>
      <div data-dropdown-button>
        <DropdownButton
          customMenu={
            <ActionsMenuComponent
              firstSection={sections.firstSection || []}
              secondSection={sections.secondSection || []}
            />
          }
          data-testid="compass-actions-dropdown"
          items={[]}
          onHoverEnd={shouldShowAnticipatedBalanceLine ? () => handleHover(null) : undefined}
          onHoverStart={
            shouldShowAnticipatedBalanceLine
              ? () => handleHover(upcomingTransaction.amount.value)
              : undefined
          }
          onOpenChange={open => {
            setIsOpen(open);
          }}
          onPress={() => {
            mainAction?.action();
          }}
          variant="secondary"
        >
          {mainAction?.text}
        </DropdownButton>
      </div>
      <MenuTrigger>
        <Button
          data-more-button
          data-testid="compass-actions-entry-menu"
          iconOnly
          variant="tertiary"
        >
          <IconDotsOutlined height={16} width={16} />
        </Button>
        <Popover data-testid="compass-actions-popover" placement="top">
          {useActionsMenu ? (
            <ActionsMenuComponent
              firstSection={sections.firstSection || []}
              secondSection={sections.secondSection || []}
            />
          ) : (
            <Menu className={styles.menu}>
              <MenuItem
                className={styles.menuItem}
                data-testid="compass-actions-menu-edit-button"
                onAction={() => {
                  if (upcomingTransaction) {
                    handleEditUpcomingTransaction(upcomingTransaction);
                  }
                }}
              >
                <IconPenOutlined />
                <FormattedMessage id="cash-flow.upcoming-transactions.actions.edit" />
              </MenuItem>
              <MenuItem
                className={cx(styles.menuItem, styles.menuDelete)}
                data-testid="compass-actions-menu-delete-button"
                onAction={handleDelete}
              >
                <TrashOutlined />
                <FormattedMessage id="cash-flow.upcoming-transactions.actions.remove" />
              </MenuItem>
            </Menu>
          )}
        </Popover>
      </MenuTrigger>
    </div>
  );
}
