import { type ReactNode, useState } from 'react';
import cx from 'clsx';
import { Button, LottiePlayer } from '@repo/design-system-kit';
import { IconCrossOutlined } from '@repo/monochrome-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './metal-success.strict-module.css';

interface MetalSuccessProps {
  data: {
    paymentMonthlyLimit: number;
    onClose: (closeParam?: string) => void;
  };
  close?: string;
}

export function MetalSuccess({ data, close }: MetalSuccessProps): ReactNode {
  const [animationReady, setAnimationReady] = useState(false);
  const { formatMessage, formatNumber } = useIntl();

  const handleClose = (): void => {
    data.onClose(close);
  };

  const handleDisplayLottie = (): void => {
    setAnimationReady(true);
  };

  const formatPrice = (amount: number): string => {
    return formatNumber(amount, {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0,
    });
  };

  return (
    <div className={styles.background} data-test-metal-success-modal>
      <div className={styles.wrapper}>
        <LottiePlayer
          className={cx('mb-32', styles.illustration, !animationReady && styles.hidden)}
          data-test-card-metal-success-lottie=""
          loop={false}
          onDataReady={handleDisplayLottie}
          path="/lotties/success-black.json"
        />
        <h1 className="title-1 mb-16" data-test-card-metal-success-title>
          <FormattedMessage id="cards.settings.payment-limit.success.title" />
        </h1>
        <p className="body-1 mb-32" data-test-card-metal-success-subtitle>
          <FormattedMessage
            id="cards.settings.payment-limit.success.subtitle"
            values={{
              amount: formatPrice(data.paymentMonthlyLimit),
            }}
          />
        </p>
        <Button className={styles.exit} data-test-card-metal-success-exit onPress={handleClose}>
          <FormattedMessage id="cards.settings.payment-limit.success.cta" />
        </Button>
      </div>
      <Button
        aria-label={formatMessage({ id: 'a11y.buttons.close-aria-label' })}
        className={styles.close}
        data-test-card-metal-success-close
        iconOnly
        onPress={handleClose}
        size="large"
        variant="tertiary"
      >
        <IconCrossOutlined aria-hidden="true" />
      </Button>
    </div>
  );
}
