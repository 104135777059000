import { type ReactNode } from 'react';
import cx from 'clsx';
import styles from './styles.strict-module.css';

interface CheckSidebarStepProps {
  children: ReactNode;
  isNextStepComplete: boolean;
  isComplete: boolean;
  hasNextStep: boolean;
}

export function CheckSidebarStep({
  children,
  isComplete,
  hasNextStep,
  isNextStepComplete,
}: CheckSidebarStepProps): ReactNode {
  return (
    <div className={styles.step}>
      <div className={styles.container}>
        <div className={styles['dot-wrapper']}>
          <div className={cx(styles.dot, isComplete && styles.green)} />
        </div>

        {hasNextStep ? (
          <div className={cx(styles.join, isNextStepComplete && styles.green)} />
        ) : null}
      </div>

      <div className={styles.content}>{children}</div>
    </div>
  );
}
