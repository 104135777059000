import type { ReactElement, PropsWithChildren } from 'react';
import cx from 'clsx';
import { useIntl, FormattedMessage } from 'react-intl';
import { LottiePlayer } from '@repo/design-system-kit';
import { useFlags } from '@qonto/react-migration-toolkit/react/hooks';
import { useThemedAssetPath } from 'qonto/react/hooks/use-themed-asset-path';
import styles from './styles.strict-module.css';

const BACKGROUND_COLORS = ['purple', 'mint', 'mustard', 'peach'];
const backgroundColor =
  BACKGROUND_COLORS[Math.floor(Math.random() * BACKGROUND_COLORS.length)] || 'mint';

interface SigninSidepanelProps {
  showRibaPromotionalContent?: boolean;
}

interface LokalizedValues {
  de?: string;
  en: string;
  es?: string;
  fr?: string;
  it?: string;
  pt?: string;
}

interface LottieAsset {
  type: 'lottie';
  url: string | LokalizedValues;
}

interface ImageAsset {
  type: 'image';
  url: string | LokalizedValues;
}

type DynamicDiscoveryMessageColor = 'purple' | 'mint' | 'mustard' | 'peach';

interface DynamicDiscoveryMessage {
  title?: LokalizedValues;
  subtitle?: LokalizedValues;
  asset?: LottieAsset | ImageAsset;
  color?: DynamicDiscoveryMessageColor;
}

type DynamicDiscoveryMessages = DynamicDiscoveryMessage[];

function RibaPromotionalSidepanel(): ReactElement {
  return (
    <div className={cx(styles.illustration, styles.mint)}>
      <div className={styles.mainImageContainer}>
        <LottiePlayer
          className={styles.mainImage}
          data-test-signin-riba-main-image
          path="/lotties/login-split-screen/split_riba_login_mint.json"
        />
        <p className="body-1 mb-8" data-test-riba-promotion-description>
          <FormattedMessage id="riba.login-screen.body" />
        </p>
        <p className="title-2 mb-4 ml-8 mr-8" data-test-riba-promotion-title>
          <FormattedMessage id="riba.login-screen.title" />
        </p>
      </div>
    </div>
  );
}

function SidepanelLayout({ children }: PropsWithChildren): ReactElement {
  return (
    <div className={cx(styles.illustration, styles[backgroundColor])}>
      <div className={styles.mainImageContainer}>{children}</div>
    </div>
  );
}

function DynamicSidepanel({
  color,
  title,
  subtitle,
  assetUrl,
  isLottie,
}: {
  color: DynamicDiscoveryMessageColor;
  title: string;
  subtitle: string;
  assetUrl: string;
  isLottie: boolean;
}): ReactElement {
  return (
    <div className={cx(styles.illustration, styles[color])}>
      <div className={styles.mainImageContainer}>
        {isLottie ? (
          <LottiePlayer autoplay data-test-dynamic-sidepanel-lottie loop path={assetUrl} />
        ) : (
          <img
            alt=""
            className={styles.mainImage}
            data-test-dynamic-sidepanel-image
            src={assetUrl}
          />
        )}
        <p className="body-1 mb-8" data-test-dynamic-sidepanel-subtitle>
          {subtitle}
        </p>
        <p className="title-2 mb-4 ml-8 mr-8" data-test-dynamic-sidepanel-title>
          {title}
        </p>
      </div>
    </div>
  );
}

export function SigninSidepanel({
  showRibaPromotionalContent,
}: SigninSidepanelProps): ReactElement {
  const { locale } = useIntl();
  const { operationalDynamicDiscoveryMessage } = useFlags();
  const lottiePath = useThemedAssetPath('/lotties/account-aggregation-login.json');

  if (showRibaPromotionalContent) {
    return <RibaPromotionalSidepanel />;
  }

  const data = operationalDynamicDiscoveryMessage as unknown as DynamicDiscoveryMessages;

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- The data comes from the request to LD and in some cases might be missing
  if (data && Boolean(data.length)) {
    const randomDiscoveryIndex = Math.floor(Math.random() * data.length);
    const discoveryMessage = data[randomDiscoveryIndex];

    if (
      discoveryMessage?.title?.en &&
      discoveryMessage.subtitle?.en &&
      discoveryMessage.asset &&
      discoveryMessage.color
    ) {
      const { title: titleValues, subtitle: subtitleValues, asset, color } = discoveryMessage;
      const title = titleValues[locale] || titleValues.en;
      const subtitle = subtitleValues[locale] || subtitleValues.en;
      const assetUrl =
        typeof asset.url === 'string' ? asset.url : asset.url[locale] || asset.url.en;
      const isLottie = asset.type === 'lottie';

      return (
        <DynamicSidepanel
          assetUrl={assetUrl}
          color={color}
          isLottie={isLottie}
          subtitle={subtitle}
          title={title}
        />
      );
    }
  }

  return (
    <SidepanelLayout>
      <LottiePlayer autoplay data-test-sidepanel-illustration loop path={lottiePath} />
      <p className="body-1 mb-8" data-test-sidepanel-subtitle>
        <FormattedMessage id="sign_in.sidepanel.subtitle" />
      </p>
      <p className="title-2 mb-4 ml-8 mr-8" data-test-sidepanel-title>
        <FormattedMessage id="sign_in.sidepanel.title" />
      </p>
    </SidepanelLayout>
  );
}
