import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { IconTryFilledSmall } from '@repo/monochrome-icons';
import { Button } from '@repo/design-system-kit';
import styles from './styles.strict-module.css';

interface MarketingButtonProps {
  onPress: () => void;
}
export function MarketingButton({ onPress }: MarketingButtonProps): ReactNode {
  const intl = useIntl();

  return (
    <div className={styles.marketingButton}>
      <IconTryFilledSmall />
      <span className="body-2">
        {intl.formatMessage({ id: 'qonto-invoicing.upsell-page.label' })}
      </span>
      <Button onPress={onPress}>
        {intl.formatMessage({ id: 'qonto-invoicing.upsell-page.primary-cta' })}
      </Button>
    </div>
  );
}
