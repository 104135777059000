import { Label } from '@repo/design-system-kit';
import React, { useEffect, useState, type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { ListBoxItem, ListBox, Popover, Select, SelectValue, Button } from 'react-aria-components';
import { IconChevronBottomOutlined, IconChevronTopOutlined } from '@repo/monochrome-icons';
import { iconsMap } from 'qonto/react/components/transactions/sidebar/category/cashflow-category/components/category-avatar/category-avatar';
import styles from './category-details-form.strict-module.css';

interface IconSelectProps {
  categoryIconKey: string;
  categoryColorKey: string;
  onIconChange: () => void;
}
export function IconSelect({
  categoryIconKey,
  categoryColorKey,
  onIconChange,
}: IconSelectProps): ReactNode {
  const intl = useIntl();

  const [isIconSelectOpen, setIsIconSelectOpen] = useState(false);
  const [hasIconChanged, setHasIconChanged] = useState(false);
  useEffect(() => {
    if (hasIconChanged) {
      setHasIconChanged(false);
      onIconChange();
    }
  }, [hasIconChanged, onIconChange]);

  return (
    <Select
      data-testid="category-details-select-icon"
      defaultSelectedKey={categoryIconKey}
      isOpen={isIconSelectOpen}
      name="icon"
      onOpenChange={setIsIconSelectOpen}
      onSelectionChange={() => {
        setHasIconChanged(true);
      }}
    >
      <Label data-testid="category-details-select-icon-label">
        {intl.formatMessage({ id: 'categories-management.create-category.icon.label' })}
      </Label>
      <Button
        className={styles['select-icon-trigger']}
        data-selected-icon={categoryIconKey}
        data-testid="category-details-select-icon-trigger"
      >
        <div className={styles['select-trigger-content']}>
          <SelectValue className={styles['select-trigger-icon']} />
          {isIconSelectOpen ? (
            <IconChevronTopOutlined aria-hidden="true" className={styles['select-trigger-arrow']} />
          ) : (
            <IconChevronBottomOutlined
              aria-hidden="true"
              className={styles['select-trigger-arrow']}
            />
          )}
        </div>
      </Button>
      <Popover className={styles['select-icon-popover']}>
        <ListBox
          className={styles['select-icon-listbox']}
          data-testid="category-details-select-icon-listbox"
          items={iconOptions}
          key={categoryColorKey}
        >
          {iconOption => (
            <ListBoxItem
              className={styles['select-icon-listbox-item']}
              id={iconOption.id}
              style={({ isHovered }) => ({
                backgroundColor: isHovered ? `var(${categoryColorKey})` : '',
              })}
            >
              <iconOption.icon className={styles['icon-option']} />
            </ListBoxItem>
          )}
        </ListBox>
      </Popover>
    </Select>
  );
}

const iconOptions = Object.entries(iconsMap)
  .map(([key, value]) => ({
    id: key,
    icon: value,
  }))
  .filter(({ id }) => id !== 'default');
