import type { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@repo/design-system-kit';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useSegment } from '@repo/poly-hooks/segment';
import { TRANSFER_FLOW_ORIGIN } from 'qonto/constants/transfers';
// @ts-expect-error The file needs to be migrated to TypeScript
import { ErrorInfo } from 'qonto/utils/error-info';
import { useFetchSignatureRequest } from 'qonto/react/financing/hooks/use-fetch-signature-request';
import styles from './signature-warning.strict-module.css';

interface FlowContext {
  origin: string;
  signature: {
    url: string;
    status: string;
  };
}

interface SignatureWarningProps {
  context: FlowContext;
  transitionToNext: () => void;
  transitionToPrevious: () => void;
}

interface ErrorInfoType {
  shouldSendToSentry: boolean;
  for: (error: Error) => ErrorInfoType;
}

export function SignatureWarning({
  context,
  transitionToNext,
  transitionToPrevious,
}: SignatureWarningProps): ReactNode {
  const toastFlashMessages = useEmberService('toastFlashMessages');
  const segment = useSegment();
  const sentry = useEmberService('sentry');
  const { formatMessage } = useIntl();

  const onSignatureRequestError = (error: unknown): void => {
    const errorInfo = (ErrorInfo as ErrorInfoType).for(error as Error);
    if (errorInfo.shouldSendToSentry) {
      sentry.captureException(error);
    }

    toastFlashMessages.toastError(
      formatMessage({ id: 'financing.pay-later.application-flow.value-proposition.error-toast' })
    );
  };
  const { isSignatureRequestLoading, retrievePayLaterSignatureUrl } =
    useFetchSignatureRequest(onSignatureRequestError);

  const handleSignatureRequest = async (): Promise<void> => {
    const data = await retrievePayLaterSignatureUrl();
    if (data?.url) {
      context.signature.url = data.url;
      transitionToNext();
    }

    if (context.origin === TRANSFER_FLOW_ORIGIN.PAY_BY_INVOICE) {
      segment.track('pay-later-modular_sign-contract-CTA_clicked', {
        origin: 'lower_balance',
      });
    } else {
      segment.track('pay-later-modular_sign-contract-CTA_clicked', {
        origin: 'enough_balance',
      });
    }
  };

  return (
    <div className={styles.signatureWarning}>
      <h1 className="title-1 mb-32" data-test-signature-warning-title>
        <FormattedMessage id="transfers.new.sepa.pay-later.signature-warning.title" />
      </h1>
      <p className="body-1 mb-32" data-test-signature-warning-description>
        <FormattedMessage id="transfers.new.sepa.pay-later.signature-warning.description" />
      </p>
      <div className={styles.buttons}>
        <Button
          data-test-signature-warning-cancel
          onPress={transitionToPrevious}
          variant="secondary"
        >
          <FormattedMessage id="btn.cancel" />
        </Button>

        <Button
          data-test-signature-warning-sign-contract
          isLoading={isSignatureRequestLoading}
          onPress={handleSignatureRequest}
        >
          <FormattedMessage id="transfers.new.sepa.pay-later.signature-warning.primary-cta" />
        </Button>
      </div>
    </div>
  );
}
