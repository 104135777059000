/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import { useState, type ReactNode } from 'react';
import { Button, Popup, RadioButton, RadioGroup } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import { FormattedMessage } from '@repo/domain-kit/shared';
import ENV from 'qonto/config/environment';
import type { CashflowForecastEntry } from 'qonto/react/models/cash-flow-forecast-entry';
import {
  CashflowForecastDeletionScope,
  getFormulaBoundDateString,
} from 'qonto/react/models/cash-flow-forecast-formula';
import styles from './edition-scope-popup.strict-module.css';

const isTest = ENV.environment === 'test';

interface SidepanelDeletionScopePopupProps {
  forecastEntry: CashflowForecastEntry;
  isOpen: boolean;
  onPopupCancel: () => void;
  onPopupConfirm: (scope: CashflowForecastDeletionScope) => void;
}

export function SidepanelDeletionScopePopup({
  forecastEntry,
  isOpen,
  onPopupCancel,
  onPopupConfirm,
}: SidepanelDeletionScopePopupProps): ReactNode {
  const { formatMessage } = useIntl();
  const [selectedScope, setSelectedScope] = useState<CashflowForecastDeletionScope>(
    CashflowForecastDeletionScope.ThisMonth
  );

  const handleConfirm = (): void => {
    onPopupConfirm(selectedScope);
  };

  const TEST_PORTAL_CONTAINER =
    document.getElementById('ember-testing') ?? document.querySelector('main') ?? undefined;

  return (
    <Popup.Root
      className={styles['sidepanel-deletion-scope-popup']}
      data-testid="sidepanel-deletion-scope-popup"
      isOpen={isOpen}
      portalContainer={isTest ? TEST_PORTAL_CONTAINER : undefined}
    >
      <Popup.CloseButton
        data-testid="sidepanel-deletion-scope-popup-close-button"
        onPress={onPopupCancel}
      />
      <Popup.Header>
        <Popup.Title data-testid="sidepanel-deletion-scope-popup-title">
          <FormattedMessage id="cashflow.side-panel.forecast.modal.delete.title" />
        </Popup.Title>
        <Popup.Subtitle data-testid="sidepanel-deletion-scope-popup-subtitle">
          {formatMessage(
            {
              id: 'cashflow.side-panel.forecast.modal.delete.subtitle',
            },
            {
              date_range: forecastEntry.formula
                ? getFormulaBoundDateString(forecastEntry.formula)
                : '',
            }
          )}
        </Popup.Subtitle>
      </Popup.Header>
      <Popup.Body>
        <RadioGroup
          data-testid="sidepanel-deletion-scope-popup-radio-group"
          onChange={setSelectedScope}
          value={selectedScope}
        >
          <RadioButton
            data-testid="radio-this-month"
            value={CashflowForecastDeletionScope.ThisMonth}
          >
            <FormattedMessage id="cashflow.side-panel.forecast.modal.delete.this-month" />
          </RadioButton>
          <RadioButton
            data-testid="radio-this-and-future-months"
            value={CashflowForecastDeletionScope.ThisAndFutureMonths}
          >
            <FormattedMessage id="cashflow.side-panel.forecast.modal.delete.future-months" />
          </RadioButton>
          <RadioButton
            data-testid="radio-all-months"
            value={CashflowForecastDeletionScope.AllMonths}
          >
            <FormattedMessage id="cashflow.side-panel.forecast.modal.delete.all-months" />
          </RadioButton>
        </RadioGroup>
      </Popup.Body>
      <Popup.Footer>
        <Button
          aria-label="Cancel"
          data-testid="sidepanel-deletion-scope-popup-cancel-button"
          onPress={onPopupCancel}
          variant="secondary"
        >
          <FormattedMessage id="cashflow.side-panel.forecast.modal.delete.button.cancel" />
        </Button>
        <Button
          aria-label="Confirm"
          data-testid="sidepanel-deletion-scope-popup-confirm-button"
          onPress={handleConfirm}
          variant="danger"
        >
          <FormattedMessage id="cashflow.side-panel.forecast.modal.delete.button.confirm" />
        </Button>
      </Popup.Footer>
    </Popup.Root>
  );
}
