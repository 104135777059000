import { type ReactNode, type HTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';
import { LottiePlayer } from '@repo/design-system-kit';
import styles from './error.strict-module.css';

type ErrorProps = HTMLAttributes<HTMLDivElement>;

export function Error({ ...props }: ErrorProps): ReactNode {
  return (
    <div className={styles.container} data-test-side-drawer-error {...props}>
      <LottiePlayer className="mb-24" data-test-lottie="" loop path="/lotties/error-404.json" />
      <span className="title-2 mb-16" data-test-title>
        <FormattedMessage id="in-context-integrations.side-drawer.error-state.title" />
      </span>
      <p className="body-2" data-test-description>
        <FormattedMessage id="in-context-integrations.side-drawer.error-state.body" />
      </p>
    </div>
  );
}
