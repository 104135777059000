import { type ReactNode } from 'react';
import { Button } from '@repo/design-system-kit';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

export interface ActionProps {
  handleClick: () => Promise<void>;
  text?: string;
}

export function Action({ handleClick, text, ...props }: ActionProps): ReactNode {
  const [isLoading, setIsLoading] = useState(false);

  const handleClickWrapper = async (): Promise<void> => {
    setIsLoading(true);

    await handleClick();

    setIsLoading(false);
  };

  return (
    <Button
      className="mb-24"
      isLoading={isLoading}
      onPress={handleClickWrapper}
      stretch
      variant="primary"
      {...props}
    >
      {text ? text : <FormattedMessage id="cards.choose-cta.label" />}
    </Button>
  );
}
