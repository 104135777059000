import cx from 'clsx';
import type { ReactNode } from 'react';
import type { Check } from 'qonto/react/models/check';
import { CheckSidebarHeader } from './header';
import { CheckSidebarStatus } from './status';
import styles from './styles.strict-module.css';

interface CheckSidebarProps {
  check: Check;
  sidebarCloseId: string;
  closeSidebar: () => void;
}

export function CheckSidebar({
  check,
  sidebarCloseId,
  closeSidebar,
}: CheckSidebarProps): ReactNode {
  return (
    <div className="l-block__inner">
      <div className={cx(styles.content, 'scroll')}>
        <CheckSidebarHeader
          check={check}
          closeSidebar={closeSidebar}
          sidebarCloseId={sidebarCloseId}
        />
        <CheckSidebarStatus check={check} />
      </div>
    </div>
  );
}
