/* eslint-disable @typescript-eslint/non-nullable-type-assertion-style -- contradictory lint rule regarding non-null assertions */
import { AmountField, Button, Form, useToast } from '@repo/design-system-kit';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { ToggleSection } from '@repo/domain-kit/shared';
import type { DateValue } from 'react-aria';
import { parseDate } from '@internationalized/date';
import type { CashflowPeriodRate } from 'qonto/react/models/cash-flow-period';
import {
  CashflowForecastEntrySource,
  type CashflowForecastEntry,
} from 'qonto/react/models/cash-flow-forecast-entry';
import { useUpdateCashFlowForecast } from 'qonto/react/hooks/use-update-forecast-entries';
import { cashFlowSidePanelManager } from 'qonto/react/contexts/cash-flow-sidepanel-context';
import {
  CashflowForecastEditionScope,
  getFormulaBoundDate,
} from 'qonto/react/models/cash-flow-forecast-formula';
import {
  ForecastFormulaFrequency,
  type CashflowForecastFormulaUpdatable,
} from 'qonto/react/api/models/cash-flow-forecast-formula';
import type { LabelTableInterval } from '../../../models/labels-cashflow-display';
import type { CategoriesTableRow } from '../../../models/categories-table-display';
import { SidepanelEditionScopePopup } from '../components/edition-scope-popup';
import styles from './forecast-entry-edit-form.strict-module.css';
import { ForecastEntryEditRecurrence } from './forecast-entry-edit-recurrence';
import type { ForecastEntryVarianceDropdownValue as VarianceType } from './forecast-entry-variance-dropdown';

interface ForecastEntryEditFormProps {
  entry?: CashflowForecastEntry;
  frequency: CashflowPeriodRate;
  interval: LabelTableInterval;
  category: CategoriesTableRow;
}

export function ForecastEntryEditForm({
  entry,
  interval,
  category,
}: ForecastEntryEditFormProps): React.ReactNode {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { showToast } = useToast();

  const selectedIntervalStart = dayjs(interval.start);
  const entryEnd = entry?.formula ? getFormulaBoundDate(entry.formula.end) : undefined;
  const initialEndDate = entry?.formula
    ? (parseDate(
        `${entry.formula.end.year}-${String(entry.formula.end.month).padStart(2, '0')}-01`
      ) as unknown as DateValue)
    : (parseDate(
        dayjs().set('date', 1).add(4, 'year').format('YYYY-MM-DD')
      ) as unknown as DateValue);

  // Sidepanel state
  const {
    closeSidepanel,
    refreshChart,
    setForecastEditDirtyState,
    forecastEdit,
    setForecastEditVisibility,
  } = cashFlowSidePanelManager.useCashFlowSidePanel();

  // Local state
  const [amount, setAmount] = useState<string>(entry?.amount.value ?? '0.00');
  const [endDate, setEndDate] = useState<DateValue | null>(initialEndDate);
  const [frequency, setFrequency] = useState<ForecastFormulaFrequency>(
    entry?.formula?.frequencyInMonths ?? ForecastFormulaFrequency.Monthly
  );
  const [isRecurring, setIsRecurring] = useState(Boolean(entry?.formula));
  const [variance, setVariance] = useState(
    entry?.formula?.percentageIncrement ??
      (entry?.formula?.sumIncrement ? Number(entry.formula.sumIncrement.value) : 0)
  );
  const [type, setType] = useState<VarianceType>(
    entry?.formula?.percentageIncrement ? 'percentage' : 'amount'
  );

  // Mutations
  const { mutate: mutateCashFlowForecast, isPending } = useUpdateCashFlowForecast({
    onSuccess: async (_data, request) => {
      await queryClient.invalidateQueries({ queryKey: ['cashflow-timeseries'] });
      const hasFormula = request.forecast_entries.some(payloadEntry =>
        Boolean(payloadEntry.formula)
      );
      const editingMultipleEntries = request.forecast_entries.length > 1;
      const successMessage =
        hasFormula || editingMultipleEntries
          ? formatMessage({ id: 'cashflow.side-panel.forecast.edit.toast.future-months' })
          : formatMessage({ id: 'cashflow.side-panel.forecast.edit.toast.this-month' });

      showToast({
        text: successMessage,
        type: 'success',
      });
      refreshChart();
    },
    onError: () => {
      setAmount(entry?.amount.value ?? '0.00');
      showToast({
        text: formatMessage({ id: 'cash-flow.edit-forecast.error.toast' }),
        type: 'error',
      });
    },
    onSettled: () => {
      closeSidepanel(true);
    },
  });

  const handleEditionScopeCancel = (): void => {
    setAmount(entry?.amount.value ?? '0.00');
    closeSidepanel(true);
  };

  const handleEditionScopeConfirmation = (scope: CashflowForecastEditionScope): void => {
    setForecastEditVisibility(false);
    handleEditForecast(scope);
  };

  const handleEditForecast = (
    scope: CashflowForecastEditionScope = CashflowForecastEditionScope.ThisMonth
  ): void => {
    const formula: CashflowForecastFormulaUpdatable = {
      percentage_increment: type === 'percentage' ? variance : null,
      frequency_in_months: frequency,
      repeat_until: {
        month: endDate?.month ?? 0,
        year: endDate?.year ?? 0,
      },
      sum_increment:
        type === 'amount'
          ? {
              currency: entry?.amount.currency ?? 'EUR',
              value: variance.toFixed(2), // To be implemented
            }
          : null,
    };

    mutateCashFlowForecast({
      forecast_entries: [
        {
          amount: {
            valid: true,
            value: {
              currency: entry?.amount.currency ?? 'EUR',
              value: amount,
            },
          },
          category_id: category.id as string,
          month: selectedIntervalStart.month() + 1,
          year: selectedIntervalStart.year(),
          ...(entry?.formula &&
          !isRecurring &&
          scope === CashflowForecastEditionScope.ThisAndFutureMonths
            ? { disable_formula_recurrence: 'future_months' }
            : {}),
          ...(isRecurring ? { formula } : {}),
        },
      ],
    });
  };

  // Local handlers
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    // Prevent default browser page refresh.
    event.preventDefault();

    if (!forecastEdit.isDirty) {
      closeSidepanel();
      return;
    }

    const hasFutureMonths =
      entry?.source === CashflowForecastEntrySource.Formula &&
      entryEnd &&
      entryEnd.isAfter(dayjs(interval.start), 'month');

    if (hasFutureMonths && !isRecurring) {
      setForecastEditVisibility(true);
    } else {
      handleEditForecast();
    }
  };

  const handleAmountChange = (value: string): void => {
    setForecastEditDirtyState(true);
    setAmount(value);
  };

  const handleRecurrenceToggle = (checked: boolean): void => {
    setIsRecurring(checked);
    setForecastEditDirtyState(true);
  };

  const handleEndDateChange = (value: DateValue | null): void => {
    setForecastEditDirtyState(true);
    setEndDate(value);
  };

  const handleVarianceChange = (value: number, varianceType: VarianceType): void => {
    setForecastEditDirtyState(true);
    setVariance(value);
    setType(varianceType);
  };

  const handleFrequencyChange = (value: string | number): void => {
    setForecastEditDirtyState(true);
    setFrequency(value as ForecastFormulaFrequency);
  };

  return (
    <>
      <Form className={styles.form} onSubmit={handleSubmit}>
        <AmountField
          amount={Math.abs(Number(amount))}
          currency={entry?.amount.currency ?? 'EUR'}
          label={formatMessage({ id: 'cashflow.side-panel.forecast.edit.amount-input' })}
          minValue={0}
          onAmountChange={handleAmountChange}
        />
        <ToggleSection
          checked={isRecurring}
          content={
            <ForecastEntryEditRecurrence
              currencyCode={entry?.amount.currency ?? 'EUR'}
              endDate={endDate}
              frequency={frequency}
              onEndDateChange={handleEndDateChange}
              onFrequencyChange={handleFrequencyChange}
              onVarianceChange={handleVarianceChange}
              variance={variance}
              varianceType={type}
            />
          }
          onChange={handleRecurrenceToggle}
          title={formatMessage({ id: 'cashflow.side-panel.forecast.edit.formula.toggle' })}
        />
        <Button className={styles.submit} isLoading={isPending} type="submit">
          {formatMessage({ id: 'cashflow.side-panel.forecast.edit.button' })}
        </Button>
      </Form>
      {entry?.source === CashflowForecastEntrySource.Formula && entry.formula ? (
        <SidepanelEditionScopePopup
          forecastEntry={entry}
          isOpen={forecastEdit.isVisible}
          onPopupCancel={handleEditionScopeCancel}
          onPopupConfirm={handleEditionScopeConfirmation}
        />
      ) : null}
    </>
  );
}
