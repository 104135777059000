import type { SVGProps, ReactNode } from 'react';

export function CheckmarkRoundedOutlined(props: SVGProps<SVGSVGElement>): ReactNode {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_881_20476)">
        <path
          d="M8 0C3.58 0 0 3.58 0 8C0 12.42 3.58 16 8 16C12.42 16 16 12.42 16 8C16 3.58 12.42 0 8 0ZM8 15C4.14 15 1 11.86 1 8C1 4.14 4.14 1 8 1C11.86 1 15 4.14 15 8C15 11.86 11.86 15 8 15Z"
          fill="#636360"
        />
        <path
          d="M11.15 5.29L7 9.42L4.85 7.29C4.65 7.09 4.33 7.09 4.13 7.29C3.93 7.48 3.93 7.81 4.13 8L6.27 10.15L7 10.86L7.72 10.15L11.87 6C12.07 5.81 12.07 5.48 11.87 5.29C11.67 5.09 11.34 5.09 11.14 5.29H11.15Z"
          fill="#636360"
        />
      </g>
      <defs>
        <clipPath id="clip0_881_20476">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
