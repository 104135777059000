import { type ComponentPropsWithoutRef, type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import type ReceivableInvoiceModel from 'qonto/models/receivable-invoice';
import type { CustomerSnapshot } from 'qonto/react/models/customer-snapshot';
import type { BaseWidgetInvoicesProps } from './widget-invoices';
import { WidgetInvoicesItem } from './widget-invoices-item';
import styles from './styles.strict-module.css';

interface WidgetInvoicesReceivableItemsProps extends ComponentPropsWithoutRef<'ol'> {
  invoices: (Omit<ReceivableInvoiceModel, 'customerSnapshot'> & {
    customerSnapshot: CustomerSnapshot;
  })[];
  invoiceType: BaseWidgetInvoicesProps['invoiceType'];
  organizationSlug: string;
}

export function WidgetInvoicesReceivableItems({
  invoices,
  invoiceType,
  organizationSlug,
}: WidgetInvoicesReceivableItemsProps): ReactNode {
  const { formatMessage } = useIntl();
  const invoicesForPreview = invoices.slice(0, 3); // we only show 3 invoices
  return (
    <ol className={styles['invoices-list']} data-test-invoices-receivable>
      {invoicesForPreview.map(invoice => (
        <WidgetInvoicesItem
          amount={{
            value: invoice.amountDue,
            currency: invoice.currency,
          }}
          date={invoice.dueDate}
          hasDuplicate={invoice.hasDuplicates}
          id={invoice.id}
          invoiceType={invoiceType}
          key={invoice.id}
          name={invoice.customerSnapshot.name}
          organizationSlug={organizationSlug}
          overdue={invoice.overdue}
          status={
            invoice.status === 'unpaid'
              ? formatMessage({ id: 'receivable-invoices.status.unpaid' })
              : undefined
          }
        />
      ))}
    </ol>
  );
}
